<template>
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :opacity="fillOpacity"
      d="M0.842226 12.7747V11.1399L4.8689 6.94114C5.37367 6.41342 5.74651 5.92586 5.98743 5.47845C6.22834 5.03105 6.3488 4.57217 6.3488 4.10181C6.3488 3.51674 6.16524 3.07507 5.79814 2.77679C5.43103 2.47852 4.89758 2.32938 4.19779 2.32938C3.63566 2.32938 3.09648 2.43263 2.58024 2.63913C2.064 2.83415 1.55923 3.1439 1.06593 3.56836L0.343193 1.91639C0.83649 1.48046 1.44451 1.13056 2.16724 0.866703C2.90145 0.602847 3.67008 0.470918 4.47312 0.470918C5.78093 0.470918 6.78473 0.763455 7.48452 1.34853C8.18432 1.9336 8.53421 2.77106 8.53421 3.8609C8.53421 4.61805 8.3564 5.34079 8.00077 6.02911C7.64513 6.70596 7.09448 7.41723 6.3488 8.16291L2.99323 11.5185V10.9334H9.01604V12.7747H0.842226ZM11.1603 12.7747V0.642999H15.5656C16.9537 0.642999 18.1238 0.883911 19.076 1.36574C20.0397 1.83609 20.7681 2.52441 21.2614 3.4307C21.7547 4.32552 22.0014 5.41536 22.0014 6.70022C22.0014 7.97362 21.7547 9.06346 21.2614 9.96975C20.7681 10.876 20.0397 11.5701 19.076 12.0519C18.1238 12.5337 16.9537 12.7747 15.5656 12.7747H11.1603ZM13.3802 10.9334H15.4279C16.8963 10.9334 17.9862 10.5835 18.6974 9.88371C19.4087 9.18392 19.7643 8.12275 19.7643 6.70022C19.7643 5.26622 19.4087 4.20506 18.6974 3.51674C17.9862 2.81695 16.8963 2.46705 15.4279 2.46705H13.3802V10.9334Z"
      :fill="fill"
    />
  </svg>
</template>
<script setup>
const props = defineProps({
  fill: {
    type: String,
    default: "#0C0F4A",
  },
  fillOpacity: {
    type: String,
    default: "0.8",
  },
});
</script>
<script>
export default {
  name: "Map2dIcon",
};
</script>
