import state from "./state";

export default {
  propertyForm: (state: any) => {
    return state.propertyForm;
  },

  properties: (state: any) => {
    return state.properties;
  },

  totalpages: (state: any) => {
    return state.totalpages;
  },

  totalcount: (state: any) => {
    return state.totalcount;
  },

  propertyDetails: (state: any) => {
    return state.propertyDetails;
  },

  epcCategoryDataList: (state: any) => {
    return state.epcCategoryDataList;
  },

  epcDataListByCategoryId: (state: any) => {
    return state.epcDataListByCategoryId;
  },

  selectedProperties: (state: any) => {
    return state.selectedProperties;
  },

  assetRegisterForm: (state: any) => {
    return state.assetRegisterForm;
  },

  assetRegisters: (state: any) => {
    return state.assetRegisters;
  },

  assetRegisterDetails: (state: any) => {
    return state.assetRegisterDetails;
  },

  selectedAsset: (state: any) => {
    return state.selectedAsset;
  },

  propertyProjects: (state: any) => {
    return state.propertyProjects;
  },

  getPropertyMapLayers: (state: any) => {
    return state.propertyMapLayers;
  },
  getPropertySafetyCheckList: (state: any) => {
    return state.propertySafetyCheckList;
  },
  getNewPropertyProjectData: (state: any) => {
    return state.newPropertyProjectData;
  },
  getPropertyLeasesData: (state: any) => {
    return state.propertyLeasesData;
  },
  getPropertyProjectSummaryList: (state: any) => {
    return state.propertyProjectSummaryList;
  },
  getIsSiteVisitPropertyDetail: (state: any) => {
    return state.isSiteVisitPropertyDetail;
  },
  getSiteVisitProjectRefId: (state: any) => {
    return state.siteVisitProjectRefId;
  },
  getClientDetails: (state: any) => {
    return state.clientUserDetail;
  },
  getClientProperties: (state: any) => {
    return state.clientProperties;
  },
  getClientPropertiesPages: (state: any) => {
    return state.totalClientPropertiesPages;
  },
  getClientProjects: (state: any) => {
    return state.clientProjects;
  },
  getClientEstimates: (state: any) => {
    return state.clientEstimates;
  },
  getClientEstimatesPages: (state: any) => {
    return state.totalClientEstimatePages;
  },
};
