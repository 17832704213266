<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        d="M8.44586 0.910645C12.6379 0.910645 16.0488 4.31141 16.0488 8.51373C16.0488 12.7056 12.6379 16.1167 8.44586 16.1167C4.25383 16.1167 0.8429 12.7056 0.8429 8.51373C0.8429 4.31142 4.25382 0.910645 8.44586 0.910645ZM4.36695 9.15075H12.5248C12.8639 9.15075 13.1413 8.88361 13.1413 8.53431C13.1413 8.19517 12.8639 7.91774 12.5248 7.91774H4.36695C4.02782 7.91774 3.75039 8.19517 3.75039 8.53431C3.75039 8.88361 4.02781 9.15075 4.36695 9.15075Z"
        fill="#0C0F4A"
      />
      <path
        d="M15.6198 13.6479L18.7985 16.8266C19.3588 17.3875 19.3588 18.3 18.7985 18.8609C18.2366 19.4228 17.324 19.4218 16.7642 18.8609L13.5855 15.6823C14.3696 15.1189 15.0564 14.4321 15.6198 13.6479Z"
        fill="#0C0F4A"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "MapUnzoomIcon",
};
</script>
