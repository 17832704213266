export default {
  user: null,
  userStripeCustomer: [],
  userImage: null,
  userWorkStationImage: null,
  accountStatuses: null,
  isProfileVerifiable: false,
  userInsurances: [],
  userRole: null,
  userCertificates: [],
  userSpecialisms: [],
  specialismCategory: null,
  userNotifications: [],
  allSpecialismList: [],
  isRegisteredForCourse: false,
};
