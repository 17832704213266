<template>
  <svg
    width="8"
    height="16"
    viewBox="0 0 8 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.02123 14.6579L0.509527 9.13956C-0.169842 8.30861 -0.169842 6.9663 0.509527 6.13535L5.02123 0.616973C6.11867 -0.704028 8 0.233457 8 2.12973V13.1452C8 15.0628 6.11867 16.0002 5.02123 14.6579Z"
      fill="#0C0F4A"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowMenuLeft",
};
</script>
