<template>
  <CollapsibleWidgetSlot
    title="Property Audit Log"
    :hasActions="true"
    :hideDivider="true"
  >
    <template #content>
      <section class="tw-w-full tw-p-4 tw-box-border tw-border-t-solid-custom">
        <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-start">
          <div
            v-if="!timeLineData.length"
            class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
          >
            <Text variant="span">Timeline empty</Text>
          </div>
          <v-timeline
            v-if="timeLineData && timeLineData.length"
            side="end"
            density="compact"
            truncate-line="start"
            align="start"
          >
            <template v-for="(timeline, index) in timeLineData">
              <v-timeline-item
                dot-color="#FFF"
                fill-dot
                v-if="index < 4"
                :key="index"
              >
                <template v-slot:icon>
                  <img
                    :src="require(`@/assets/icons/kyp-icons/${timeline?.icon}`)"
                  />
                </template>
                <div class="tw-flex tw-flex-col tw-items-start">
                  <Text variant="p" lineHeight="20px" textAlign="left"
                    >{{ timeline.title }}
                    <Text
                      variant="p"
                      textColor="rgba(12, 15, 74, 0.54)"
                      textWeight="400"
                      whiteSpace="wrap"
                      lineHeight="20px"
                      >({{ timeline.status }})</Text
                    ></Text
                  >
                  <Text
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.54)"
                    lineHeight="20px"
                    >{{ timeline.date }}</Text
                  >
                </div>
              </v-timeline-item>
            </template>
          </v-timeline>
          <!-- <Text
            class="tw-pl-6"
            variant="span"
            textColor="#FFA500"
            v-if="timeLineData.length > 3"
            >{{ `+${timeLineData.length - 3} More` }}</Text
          > -->
        </div>
      </section>
    </template>
    <template #actions>
      <div class="tw-w-full tw-flex tw-items-center tw-justify-end">
        <div
          class="tw-flex tw-gap-0 tw-items-center tw-cursor-pointer"
          @click="onOpenLogs"
        >
          <Text variant="p" textColor="#FFA500">View Full Audit Log</Text>
          <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
        </div>
      </div>
    </template>
  </CollapsibleWidgetSlot>
</template>
<script setup>
import { onMounted, reactive, ref, onBeforeMount } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import InProgressIcon from "@/core/components/icons/InProgressIcon.vue";
import IssueRaisedIcon from "@/core/components/icons/IssueRaisedIcon.vue";
import EstimateReceivedIcon from "@/core/components/icons/EstimateReceivedIcon.vue";
import ProjectPostedIcon from "@/core/components/icons/ProjectPostedIcon.vue";
import NotStartedIcon from "@/core/components/icons/NotStartedIcon.vue";
import DraftJobsIcon from "@/core/components/icons/DraftJobsIcon.vue";
import { ISSUE_ROUTE } from "@/modules/issues/routes";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { PROPERTY_STORE } from "@/store/modules/property";
import { computed } from "vue";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import EllipsisGreen from "@/core/components/icons/EllipsisGreen.vue";
import EllipsisBlue from "@/core/components/icons/EllipsisBlue.vue";
import EllipsisDarkGreen from "@/core/components/icons/EllipsisDarkGreen.vue";

import moment from "moment";
import { AUDIT_LOG_STORE } from "@/store/modules/audit-logs";
import { USER_STORE } from "@/store/modules/user";

const inProgress = InProgressIcon;
const estimateReceived = EstimateReceivedIcon;
const notStarted = NotStartedIcon;
const draftJobs = DraftJobsIcon;
const issueRaised = IssueRaisedIcon;
const ProjectPosted = ProjectPostedIcon;

const ellipsisGreen = EllipsisGreen;
const ellipsisBlue = EllipsisBlue;
const ellipsisDarkGreen = EllipsisDarkGreen;

const emits = defineEmits(["on-open-logs"]);

const router = useRouter();
const route = useRoute();
const store = useStore();
const redirectToRaiseIssue = () => {
  router.push({ name: ISSUE_ROUTE });
};

const timeLineData = ref([]);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const propertyId = computed(() => route?.params?.propertyId);

const onOpenLogs = () => {
  emits("on-open-logs");
};

const getAuditLogsData = async (page) => {
  let params = {
    category: "project",
    // page: page,
    // pageSize: 30,
  };

  params = Object.fromEntries(
    Object.entries(params).filter((value) => (value[1] === 0 ? true : value[1]))
  );
  const response = await store.dispatch(`${AUDIT_LOG_STORE}/getAuditLogs`, {
    userId: user.value?.id,
    params: params,
  });
  if (response) {
    timeLineData.value = response.data
      .map((audit) => {
        const formattedDate = moment(audit?.dateCreated).format("DD/MM/YYYY");
        return {
          title: audit?.content,
          status: audit?.title,
          date: formattedDate,
          icon: "solar-pv-icon.svg",
          propertiesData: audit?.meta?.propertiesData,
        };
      })
      .filter((audit) =>
        audit?.propertiesData?.find(
          (property) => property?.propertyId == propertyId.value
        )
      );
  }
  if (response?.data?.length) {
    return response.data;
  } else {
    return [];
  }
};

// const items = reactive([
//   {
//     icon: inProgress,
//     title: "In progress",
//     statusId: [ProjectJobStatusEnum.IN_PROGRESS],
//   },
//   {
//     icon: issueRaised,
//     title: "Issues Raised",
//     hasButton: true,
//     statusId: ["7"],
//   },
//   {
//     icon: estimateReceived,
//     title: "Estimates received",
//     statusId: [ProjectJobStatusEnum.QUOTES_RECEIVED],
//   },
//   {
//     icon: notStarted,
//     title: "Not Started",
//     statusId: [
//       ProjectJobStatusEnum.AWAITING_QUOTES,
//       ProjectJobStatusEnum.QUOTES_RECEIVED,
//       ProjectJobStatusEnum.DRAFT_PROJECT,
//     ],
//   },
//   {
//     icon: draftJobs,
//     title: "Draft Projects",
//     statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
//   },
//   {
//     icon: ProjectPosted,
//     title: "Project Posted",
//     noBorder: true,
//     statusId: [ProjectJobStatusEnum.AWAITING_QUOTES],
//   },
// ]);

const items = reactive([
  {
    icon: inProgress,
    title: "In progress",
    statusId: [ProjectJobStatusEnum.IN_PROGRESS],
    ellipsisComponent: ellipsisGreen,
    showInPreview: true,
  },
  {
    icon: estimateReceived,
    title: "Estimates received",
    statusId: [ProjectJobStatusEnum.QUOTES_RECEIVED],
    ellipsisComponent: ellipsisBlue,
    showInPreview: true,
  },
  {
    icon: estimateReceived,
    title: "Completed",
    statusId: [ProjectJobStatusEnum.COMPLETED],
    ellipsisComponent: ellipsisDarkGreen,
    showInPreview: true,
  },
  {
    icon: issueRaised,
    title: "Issues Raised",
    hasButton: true,
    statusId: ["7"],
    showInPreview: false,
  },
  {
    icon: notStarted,
    title: "Not Started",
    statusId: [
      ProjectJobStatusEnum.AWAITING_QUOTES,
      ProjectJobStatusEnum.QUOTES_RECEIVED,
      ProjectJobStatusEnum.DRAFT_PROJECT,
    ],
    showInPreview: false,
  },
  {
    icon: draftJobs,
    title: "Draft Projects",
    statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
    showInPreview: false,
  },
  {
    icon: ProjectPosted,
    title: "Project Posted",
    noBorder: true,
    statusId: [ProjectJobStatusEnum.AWAITING_QUOTES],
    showInPreview: false,
  },
]);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const getPropertyProjectSummaryList = computed(
  () => store.getters[`${PROPERTY_STORE}/getPropertyProjectSummaryList`]
);
const getFilterProjectSummary = computed(() => {
  return items.map((item) => ({
    ...item,
    count: getPropertyProjectSummaryList.value.reduce(
      (count, project) =>
        item.statusId.includes(project.jobStatusId)
          ? count + (Number(project.projectCount) || 0)
          : count,
      0
    ),
  }));
});

onBeforeMount(async () => {
  await getAuditLogsData(1);
});

onMounted(async () => {
  // add here
});
</script>
<style lang="scss" scoped></style>
