export default {
  jobs: [],
  myJobs: [],
  draftJobs: [],
  completedJobs: [],
  rejectedJobs: [],
  draftJobsByUser: [],
  jobDetails: {},
  historicalJobs: [],
  activeHistoricalJob: null,
  videoScheduleTime: {
    startTime: "",
    endTime: "",
  },
  workFlowType: null,
  jobStatusCountsList: {},
  historicalDraftProjects: [],
  currentDraftProject: {},
  jobRedirectTabName: null,
  jobEmergencyCallOutDetails: null,
  isSiteVisitResponseNotification: false,
  totalPages: 0,
  totalJobs: 0,
  totalDraftJobs: 0,
  totalDraftPages: 0,
  totalMyJobs: 0,
  totalMyJobPages: 0,
  activeTabInJobDetailsPage: "",
};
