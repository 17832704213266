<template>
  <div class="property-detail-accordion">
    <div class="specialisms_multiselect_modal_body" v-if="!isLoading">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title>Select Service </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="specialisms_multiselect_modal_inner no-scrollbar">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel
                  v-for="(specialism, index) in listOfSpecialismsCategory"
                  :key="index"
                >
                  <v-expansion-panel-title>
                    <div class="specialisms_multiselect_modal_item_heading">
                      <v-checkbox
                        color="#4B4BFF"
                        :value="specialism.id"
                        v-model="mainCategory"
                        @click="onClickMainCategory(specialism.id)"
                      />
                      <div class="specialisms_multiselect_modal_item_title">
                        <v-icon
                          v-if="specialism.iconName"
                          class="specialisms_multiselect_modal_item_title_icon"
                        >
                          <img
                            style="width: 14px"
                            v-if="specialism.iconName"
                            :src="
                              require('@/assets/category-icons/' +
                                specialism.iconName +
                                '.svg')
                            "
                            alt="icon"
                          />
                        </v-icon>
                        <div
                          class="specialisms_multiselect_modal_item_title_text"
                        >
                          <h2>{{ specialism?.poLabel }}</h2>
                          <p>
                            {{
                              specialism.subCategories
                                .slice(0, 2)
                                .map((sub) => sub.label)
                                .join(", ")
                            }}
                            <span v-if="specialism.subCategories?.length > 2">
                              etc..</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <div
                      class="specialisms_multiselect_modal_item_content"
                      v-for="subCategories in specialism.subCategories"
                      :key="subCategories.id"
                    >
                      <v-checkbox
                        color="#4B4BFF"
                        :label="subCategories.label"
                        :value="subCategories.id"
                        v-model="selectedCategory"
                      >
                      </v-checkbox>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <CommonLoader :loading="isLoading" class="loader-container" />
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { PROJECT_STORE } from "@/store/modules/project";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { ProjectSpecialismGeneratedEnum } from "@/core/enums/ProjectsEnum";
import { WORKSTATION } from "@/store/modules/workstation";
import { debounce } from "lodash";

const store = useStore();
const selectedCategory = ref([]) as any;
const props = defineProps({
  isReview: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["onClickCloseTab", "onClickSaveSpecialism"]);
const mainCategory = ref([]) as any;
const panel = ref([]) as any;
const listOfSpecialismsCategory = computed(
  () => store.getters[`${USER_STORE}/specialismsCategory`]
);
const subCategoryList = computed(() =>
  listOfSpecialismsCategory.value.flatMap(
    (category: any) => category.subCategories
  )
);
const isLoading = ref(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const projectSpecialisms = computed(
  () => store.getters[`${PROJECT_STORE}/projectSpecialisms`]
);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const onClickMainCategory = (mainCategoryId: number) => {
  if (mainCategory.value.includes(mainCategoryId)) {
    const mainCategory = listOfSpecialismsCategory.value.find(
      (category: any) => category?.id === mainCategoryId
    );
    mainCategory.subCategories.forEach((element: any) => {
      const elementIndex = selectedCategory.value.findIndex(
        (subCategory: any) => subCategory === element.id
      );
      if (elementIndex > -1) {
        selectedCategory.value.splice(elementIndex, 1);
      }
    });
  } else {
    const mainCategory = listOfSpecialismsCategory.value.find(
      (category: any) => category?.id === mainCategoryId
    );

    mainCategory.subCategories.forEach((element: any) => {
      if (!selectedCategory.value.includes(element?.id)) {
        selectedCategory.value.push(element?.id);
      }
    });
  }
};

onMounted(async () => {
  try {
    if (!listOfSpecialismsCategory.value) {
      isLoading.value = true;
      await store.dispatch(`${USER_STORE}/getCategories`);
    }
    if (!getAllSpecialism.value.length) {
      await store.dispatch(
        `${USER_STORE}/setAllSpecialismsCategory`,
        activeUserWorkstation.value.id
      );
    }

    projectSpecialisms.value.forEach((specialism: any) => {
      selectedCategory.value.push(specialism.id);
    });
    listOfSpecialismsCategory.value.forEach(
      (specialism: any, index: number) => {
        if (specialism.subCategories.length) {
          const isSelectedAllSubCategory = specialism.subCategories.every(
            (subCategory: any) =>
              selectedCategory.value.includes(subCategory.id)
          );
          if (isSelectedAllSubCategory) {
            mainCategory.value.push(specialism.id);
            panel.value.push(index);
          }
        }
      }
    );
  } catch (error) {
    console.log();
  } finally {
    isLoading.value = false;
  }
});
const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);
const onclickSaveButton = async () => {
  try {
    if (!props.isReview) {
      const newAddedSubCategoriesData = await store.dispatch(
        `${PROJECT_STORE}/getNewAddedSubCategoryForProject`,
        {
          subCategoriesIds: selectedCategory.value,
          generatedBySpecialism: ProjectSpecialismGeneratedEnum.PROPERTY,
        }
      );
      // const newAddedSubCategoriesData = subCategoryList.value
      //   .filter((subCategory: any) =>
      //     selectedCategory.value.includes(subCategory?.id)
      //   )
      //   .map((subCategory: any) => ({
      //     ...subCategory,
      //     generatedBy: ProjectSpecialismGeneratedEnum.PROPERTY,
      //   }));
      store.dispatch(
        `${PROJECT_STORE}/setProjectSpecialisms`,
        newAddedSubCategoriesData
      );
    } else {
      emit("onClickSaveSpecialism", selectedCategory.value);
    }
  } catch (error) {
    console.log();
  } finally {
    onCloseModal();
  }
};
watch(
  () => selectedCategory.value,
  debounce(async () => {
    console.log("aaa");
    await onclickSaveButton();
  }, 350),
  { immediate: true, deep: true }
);
const onCloseModal = () => {
  return emit("onClickCloseTab");
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/onboarding/styles/onboarding.scss";
.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  width: 50px;
  height: 50px;
}

.property_details_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: calc(100dvh - 220px);
  overflow: auto;
  .historic_wrapper_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba($blueDark, 1);
    padding-bottom: 28px;
  }
}
.property-detail-accordion {
  :deep(.specialisms_multiselect_modal_body) {
    .v-expansion-panels {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;

      .v-expansion-panel {
        border-radius: 8px !important;
        overflow: hidden;
        box-shadow: none;
        margin: 0;
        border: 1px solid rgba($buttonText, 0.4);
        .v-expansion-panel__shadow {
          box-shadow: none;
        }
        .v-expansion-panel-title {
          padding: 12px 16px;
          min-height: auto;
          background-color: rgba($backgroundcolor, 1);
          .v-expansion-panel-title__overlay {
            display: none;
          }
          .specialisms_multiselect_modal_item_heading {
            width: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            gap: 12px;
            .v-checkbox {
              flex-shrink: 0;
              .v-selection-control {
                min-height: 16px;
                .v-selection-control__wrapper {
                  width: 16px;
                  height: 16px;
                  .v-selection-control__input {
                    width: 16px;
                    height: 16px;
                    .v-icon {
                      opacity: 1;
                      color: rgba($blueDark, 1);
                    }
                  }
                }
              }
              .v-input__details {
                display: none;
              }
            }
            .specialisms_multiselect_modal_item_title {
              display: grid;
              grid-template-columns: 32px 1fr;
              align-items: flex-start;
              grid-gap: 12px;
              width: 100%;
              .specialisms_multiselect_modal_item_title_icon {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                background-color: $blueDark;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 50%;
                  height: 100%;
                  object-fit: contain;
                  max-width: 100%;
                  filter: invert(100%) sepia(2%) saturate(7425%)
                    hue-rotate(152deg) brightness(109%) contrast(116%);
                }
              }
              .specialisms_multiselect_modal_item_title_text {
                @include fluidFont(12, 12, 16px);
                letter-spacing: 0.4px;
                color: rgba($blueDark, 0.4);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                h2,
                p {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                h2 {
                  @include fluidFont(16, 16, 24px);
                  font-weight: 700;
                  letter-spacing: 0.15px;
                  color: $blueDark;
                }
              }
            }
          }
        }
        .v-expansion-panel-text {
          max-height: 320px;
          overflow-y: auto;
          margin: 15px 0;
          .v-expansion-panel-text__wrapper {
            padding: 0 20px 20px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .specialisms_multiselect_modal_item_content {
              padding: 0;
              display: grid;
              grid-gap: 12px;

              .v-selection-control {
                min-height: 14px;
                --v-selection-control-size: auto;
                .v-selection-control__wrapper {
                  margin-right: 6px;
                  .v-selection-control__input {
                    width: 14px;
                    height: 14px;
                    .v-icon {
                      opacity: 1;
                      color: rgba($blueDark, 0.5);
                    }
                  }
                }
                .v-label {
                  @include fluidFont(12, 12, 16px);
                  color: rgba($blueDark, 1);
                  font-weight: 500;
                  .v-icon {
                    @include fluidFont(20, 20, 1);
                  }
                }
                .specialisms_multiselect_modal_footer {
                  display: grid;
                  grid-template-columns: repeat(2, minmax(0, 1fr));
                  grid-gap: 12px;
                  margin-top: 24px;
                  padding-top: 24px;
                  border-top: 1px solid #c5d2f2;
                  .button {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
