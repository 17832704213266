<template>
  <div class="phase-title-wrapper">
    <div class="phase-title tw-flex tw-items-center tw-gap-3 tw-box-border">
      <PhaseChip
        :phaseType="phaseType"
        :phaseNumber="phaseNumber"
        :textColor="textColor"
        :bgColor="`${textColor}1A`"
      />
      <Text
        class="phase-title__text !tw-text-xs"
        variant="p"
        :textColor="textColor"
        whiteSpace="nowrap"
        >{{ title }}</Text
      >
    </div>
  </div>
</template>

<script setup>
import Text from "@/core/uni-components/Text.vue";
import PhaseChip from "@/core/uni-components/Chips/PhaseChip";
import { computed } from "vue";
import { EstimatePhaseType } from "@/core/enums/estimateEnum";

const props = defineProps({
  title: String,
  phaseNumber: Number,
  textColor: {
    type: String,
    default: "#4A92E5",
  },
  bgColor: {
    type: String,
    default: "rgba(74, 146, 229, 0.1)",
  },
  estimate: Object,
});

const phaseType = computed(() => {
  if (props.estimate?.stageType)
    return props.estimate?.stageType === "labour" ? "Phase" : "materials";
  else
    return props.estimate?.phaseType === EstimatePhaseType.LABOUR
      ? "Phase"
      : "materials";
});
</script>

<style lang="scss" scoped>
.phase-title {
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: start;
    display: inline-block;
    @include respond(s720) {
      text-overflow: unset;
    }
  }
}
.el-collapse-item .el-icon svg {
  transform: rotate(90deg);
}
.el-collapse-item.is-active .el-icon svg {
  transform: rotate(180deg);
}
.phase-title-wrapper {
  width: 170px;
  max-width: 100%;
}
</style>
