<template>
  <div class="phase__accordion tw-w-full tw-max-w-full">
    <v-expansion-panel class="phase-received-item !tw-rounded-[10px]">
      <v-expansion-panel-title class="tw-flex tw-items-center">
        <main
          class="tw-flex tw-items-center tw-justify-between tw-max-w-full tw-w-[calc(100%_-_35px)] md:tw-flex-col md:tw-items-start md:tw-w-full"
        >
          <PhaseTitle
            :title="phase.name"
            :phaseNumber="phaseIndex + 1"
            :estimate="phase"
            :textColor="phase?.color || '#4A92E5'"
          />
          <section
            class="phase_list_summary tw-relative tw-flex tw-gap-4 tw-items-center tw-w-[calc(100%-170px)] tw-max-w-full tw-justify-end md:tw-w-full md:tw-justify-start md:tw-flex-col-reverse md:tw-items-start md:tw-gap-2"
          >
            <div class="tw-flex tw-gap-2 tw-items-center tw-pr-2 tw-flex-wrap">
              <div
                @click.stop="togglePhaseProofOfWorkModal"
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
              >
                <Text variant="span" lineHeight="16px">{{
                  phase?.estimatePhaseAttachment?.length || 0
                }}</Text>
                <Text
                  lineHeight="16px"
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{
                    phase?.estimatePhaseAttachment?.length > 1
                      ? "Attachments"
                      : "Attachment"
                  }}</Text
                >
              </div>
              <div
                @click.stop="toggleShowPhaseNoteListModal"
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
              >
                <Text variant="span" lineHeight="16px">{{
                  phase?.phaseNotes?.length || 0
                }}</Text>
                <Text
                  lineHeight="16px"
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{ phase?.phaseNotes?.length > 1 ? "Notes" : "Note" }}</Text
                >
              </div>
              <div
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
              >
                <Text variant="span" lineHeight="16px">{{
                  phase?.estimationPhaseTasks.length
                }}</Text>
                <Text
                  lineHeight="16px"
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{
                    phase?.estimationPhaseTasks.length > 1 ? "tasks" : "task"
                  }}</Text
                >
              </div>
              <div
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
                v-if="phase?.phaseType === EstimatePhaseType.LABOUR"
              >
                <Text variant="span" lineHeight="16px">{{
                  getDuration(
                    phase?.phaseStartDate,
                    phase?.phaseEndDate,
                    phase.durationType
                  )
                }}</Text>
                <Text
                  lineHeight="16px"
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{ getDurationType(phase.durationType) }}</Text
                >
              </div>
              <div
                class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
              >
                <Text variant="span" lineHeight="16px">{{
                  phaseTotal(phase)
                }}</Text>
              </div>
              <div class="tw-flex tw-gap-2 tw-items-center tw-pr-2">
                <Text variant="span">{{ phasePropertyCount }}</Text>
                <Text
                  lineHeight="16px"
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{ `Propert${phasePropertyCount > 1 ? "ies" : "y"}` }}</Text
                >
              </div>
            </div>
            <div class="tw-flex tw-items-center">
              <template
                v-for="item in phaseAssignContractorAndMembers.slice(0, 3)"
                :key="item"
              >
                <ProfileThumbnail
                  class="tw-mr-[-.5rem]"
                  mobilSize="sm"
                  size="sm"
                  :src="item.imageUrl"
                  v-if="item.imageUrl"
                />
                <ProfileThumbnail
                  v-else
                  class="tw-mr-[-.5rem]"
                  mobilSize="sm"
                  size="sm"
                  :src="item.imageUrl"
                  :showNameLogo="item.name"
                />
              </template>
              <div
                class="tw-max-w-[24px] tw-min-w-[24px] tw-max-h-[24px] text-[14] tw-min-h-[24px] tw-bg-[#DCECFE] tw-rounded-full tw-flex tw-justify-center tw-items-center tw-z-[100]"
                v-if="phaseAssignContractorAndMembers.length > 3"
              >
                <p>+{{ phaseAssignContractorAndMembers.length - 3 }}</p>
              </div>
            </div>
          </section>
        </main>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <main class="tw-flex tw-flex-col tw-w-full">
          <div v-if="phase?.phaseType === EstimatePhaseType.MATERIALS">
            <AddressItem
              v-if="phase?.estimationPhaseProperties.length"
              :showAddAction="false"
              :addressValue="
                getPropertiesFirstValue(phase?.estimationPhaseProperties)
              "
              :countValue="
                getPropertyAdditionalCount(
                  phase?.estimationPhaseProperties.length
                )
              "
              :properties="phase?.estimationPhaseProperties"
            />
            <Text variant="span" v-else>No Property</Text>
          </div>
          <section
            class="phase-received-item__task-item"
            v-for="(task, index) in phase?.estimationPhaseTasks"
            :key="index"
          >
            <div v-if="phase?.phaseType === EstimatePhaseType.LABOUR">
              <AddressItem
                v-if="task.estimationPhaseTasksProperties.length"
                :showAddAction="false"
                :addressValue="
                  getPropertiesFirstValue(task.estimationPhaseTasksProperties)
                "
                :countValue="
                  getPropertyAdditionalCount(
                    task.estimationPhaseTasksProperties.length
                  )
                "
                :properties="task.estimationPhaseTasksProperties"
              />
              <Text variant="span" v-else>No Property</Text>
            </div>
            <section class="tw-flex tw-gap-4 tw-items-center tw-pr-4">
              <div class="tw-pr-4 tw-flex tw-gap-3 tw-items-start">
                <Text variant="span">{{ task.name }}</Text>
              </div>
              <div
                v-if="phase?.phaseType === EstimatePhaseType.LABOUR"
                class="tw-pr-4 tw-flex tw-gap-3 tw-items-start"
              >
                <Text variant="span">{{
                  getDuration(
                    task?.phaseTaskStartDate,
                    task?.phaseTaskEndDate,
                    task.durationType
                  )
                }}</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{ getDurationType(task.durationType) }}</Text
                >
              </div>
              <div
                v-if="phase?.phaseType === EstimatePhaseType.MATERIALS"
                class="tw-pr-4 tw-flex tw-gap-3 tw-items-start"
              >
                <Text variant="span">{{ task?.materialUnit || 0 }}</Text>
                <Text
                  variant="span"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                  >{{ task?.materialUnit > 1 ? "Units" : "Unit" }}</Text
                >
              </div>
              <div class="tw-pr-4 tw-flex tw-gap-3 tw-items-start">
                <Text variant="span">
                  {{ CurrencyFilter.formatToCurrency(task.taskCost) }}</Text
                >
              </div>
            </section>
          </section>
        </main>
      </v-expansion-panel-text>
    </v-expansion-panel>
    <ShowPhaseNoteModal
      v-if="isShowPhaseNoteListModal"
      :isEstimateNote="true"
      :phaseDetails="phase?.phaseNotes"
      @on-close="toggleShowPhaseNoteListModal"
    />
    <ShowPhaseProofOfWorkModal
      v-if="
        isShowPhaseProofOfWorkModal && phase?.estimatePhaseAttachment?.length
      "
      :phaseDetails="phase"
      :isEstimateAttachments="true"
      :estimateId="grantEstimateDetails?.id"
      @on-close="togglePhaseProofOfWorkModal"
    />
  </div>
</template>
<script lang="ts" setup>
import moment from "moment";
import { ref, reactive, onMounted } from "vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import PhaseTitle from "@/modules/project/components/common/PhaseTitle.vue";
import AddressItem from "@/modules/project/components/common/AddressItem.vue";
import { DurationsType, EstimatePhaseType } from "@/core/enums/estimateEnum";
import CurrencyFilter from "@/filters/currency.filter";
import ShowPhaseNoteModal from "@/modules/project/modal/ShowPhaseNoteModal.vue";
import { getImageStringToImageURL } from "@/core/utils/common";

import { computed } from "vue";
import ShowPhaseProofOfWorkModal from "../../modal/ShowPhaseProofOfWorkModal.vue";

const props = defineProps(["phase", "phaseIndex", "grantEstimateDetails"]);
const isShowPhaseNoteListModal = ref(false);
const isShowPhaseProofOfWorkModal = ref(false);

const getDurationType = (durationsType: number) => {
  if (durationsType === DurationsType.DAY) {
    return "day(s)";
  }
  return "week(s)";
};

const phasePropertyCount = computed(() => {
  if (props?.phase?.phaseType === EstimatePhaseType.LABOUR) {
    const uniquePropertyIds = Array.from(
      new Set(
        (props.phase?.estimationPhaseTasks || []).flatMap(
          (task: any) =>
            task.estimationPhaseTasksProperties?.map(
              (property: any) => property?.property.id
            ) || []
        )
      )
    );
    return uniquePropertyIds?.length || 0;
  } else {
    return props.phase.estimationPhaseProperties?.length || 0;
  }
});

const phaseTotal = (phase: any) => {
  let subTotal = phase?.subTotal;
  if (phase?.phaseType === EstimatePhaseType.LABOUR) {
    subTotal = subTotal - props.grantEstimateDetails.grantDiscountTotal;
  }
  return formatCurrency(subTotal);
};

const formatCurrency = (value: any) => {
  if (!value) return `£0`;
  return CurrencyFilter.formatToCurrency(value);
};
const phaseAssignContractorAndMembers = ref<any>([]);
const getDuration = (
  taskStartDate: any,
  taskEndDate: any,
  durationType: number
) => {
  if (!taskStartDate || !taskEndDate) return 0;

  const start = moment(taskStartDate);
  const end = moment(taskEndDate);
  const duration = moment.duration(end.diff(start));

  if (durationType === DurationsType.DAY) {
    return duration.days();
  }
  return duration.weeks();
};

const getPropertyAdditionalCount = (count: number) => {
  if (count > 1) {
    return count - 1;
  }
  return;
};

const getPropertiesFirstValue = (properties: any) => {
  if (properties && properties.length) {
    return properties[0].property.address;
  }
  return "";
};
const toggleShowPhaseNoteListModal = () => {
  if (props.phase?.phaseNotes?.length)
    isShowPhaseNoteListModal.value = !isShowPhaseNoteListModal.value;
};
const togglePhaseProofOfWorkModal = () => {
  isShowPhaseProofOfWorkModal.value = !isShowPhaseProofOfWorkModal.value;
};
onMounted(async () => {
  if (props.phase?.assignPhaseWorkStation) {
    let imageUrl = await getImageStringToImageURL(
      props.phase?.assignPhaseWorkStation?.profileImage
    );
    phaseAssignContractorAndMembers.value.push({
      imageUrl,
      name: props.phase?.assignPhaseWorkStation?.name,
    });
  }
  if (props.phase?.assignTeamMembers.length) {
    props.phase.assignTeamMembers.forEach(async (element: any) => {
      let imageUrl = await getImageStringToImageURL(
        element?.userWorkstationMember?.toUser?.userPublicProfile?.profileImage
      );
      phaseAssignContractorAndMembers.value.push({
        imageUrl,
        name: `${element?.userWorkstationMember?.toUser?.firstName} ${element?.userWorkstationMember?.toUser?.lastName}`,
      });
    });
  }
});
</script>
<script lang="ts">
export default {
  name: "PhaseReceivedItem",
};
</script>
<style lang="scss" scoped>
.phase__accordion {
  .phase-received-item {
    border: 1px solid rgba($bordercolor, 1);
    :deep(.v-expansion-panel__shadow) {
      display: none;
    }
    .v-expansion-panel-title {
      box-shadow: none !important;
      padding: 8px;
      min-height: auto;
    }
  }
  :deep(.v-expansion-panel-text) {
    .v-expansion-panel-text__wrapper {
      padding: 12px 8px;
      main {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        .phase-received-item__task-item {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
        }
      }
    }
  }
}
.v-expansion-panel-title__icon {
  width: 25px;
  max-width: 100%;
  margin: 0;
}

.phase-received-item__item {
  border-right: 2px solid rgba(12, 15, 74, 0.2);
  .phase-received-item__item:last-child {
    border-right: unset;
  }
}
.v-expansion-panel-title {
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #0c0f4a14;
  overflow: hidden;
  .v-expansion-panel-title__overlay {
    opacity: 0;
  }
}

.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}
</style>
