<template>
  <GeneralDialog @on-close="onCloseModal" class="tw-w-[448px] tw-max-w-full">
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-min-h-[200px]"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full"
        >
          <v-icon>
            <img
              v-if="projectType === HistoricalProjectTypeEnum.TRADE_VERIFY"
              src="@/assets/icons/sole-trader-icon-light.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/tools-bag-icon.svg" alt="" />
          </v-icon>
          <Text variant="h4" textAlign="center" lineHeight="28px"
            >Ready to add this historic project to your log?</Text
          >
          <Text
            v-if="projectType === HistoricalProjectTypeEnum.TRADE_VERIFY"
            textColor="rgba(12, 15, 74, 0.5019607843)"
            textWeight="400"
            lineHeight="18px"
            textAlign="center"
            >Add project when you are happy with all the details provided for
            this historic Trade Verified project.</Text
          >
          <Text
            v-if="projectType === HistoricalProjectTypeEnum.DIY_PROJECT"
            textColor="rgba(12, 15, 74, 0.5019607843)"
            textWeight="400"
            lineHeight="18px"
            textAlign="center"
          >
            Add project when you are happy with all the details provided for
            this historic DIY project.</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="secondary"
          :disabled="loading"
          label="Cancel"
          :isActive="true"
          @click="onCloseModal"
        />
        <Button
          :disabled="loading"
          :isLoading="loading"
          label="confirm"
          :isActive="true"
          @click="onConfirm"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { HistoricalProjectTypeEnum } from "@/core/enums/ProjectsEnum";

const props = defineProps(["loading", "projectType"]);
const emits = defineEmits(["on-close", "on-save", "on-confirm"]);

const store = useStore();
const route = useRoute();

// const state = reactive({
//   loading: false,
// });

const onCloseModal = () => {
  emits("on-close");
};

const onConfirm = () => {
  emits("on-confirm");
};
</script>
