<template>
  <section
    class="property-member-tab tw-w-full tw-h-[70dvh] tw-flex tw-flex-col tw-gap-4 mt-4"
    :class="{ 'property-member-tab': !propertyConnections.length }"
  >
    <!--<div
      class="tw-min-w-[440px] tw-w-[440px] !tw-min-h-[198px] tw-flex tw-flex-col tw-gap-4 tw-px-2 tw-box-border tw-overflow-y-auto"
    >
      <PropertyCard />
    </div>
    <div
      class="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-start tw-gap-4 tw-px-2 tw-box-border"
    >
      <PropertyMemberFilters />
      <Text variant="span"
        >08 <Text variant="span" textWeight="400">Members</Text></Text
      >
      <div class="member-grid-container tw-grid tw-gap-4 tw-w-full">
        <Card
          v-for="item in 10"
          :key="item"
          class="!tw-min-h-[185px] !tw-max-h-[185px] !tw-w-full tw-p-4 tw-box-border"
        >
          <template #content>
            <div
              class="tw-w-full tw-h-full tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2"
            >
              <ProfileThumbnail :isStatusTypeChat="true" :isOnline="true" />
              <Text class="!tw-text-[20px]" lineHeight="18px"
                >Tom Crossman</Text
              >
              <Text variant="span" textWeight="400" lineHeight="18px"
                >Occupier</Text
              >

              <Button label="open chat" />
            </div>
          </template>
        </Card>
      </div>
    </div> -->
    <CollapsiblePropertyOccupiers
      v-if="propertyConnections && propertyConnections.length"
    />
    <CollapsiblePropertyConnection
      v-if="propertyConnections && propertyConnections.length"
    />
    <div class="empty__content md:!tw-h-auto" v-else>
      <div
        class="empty__banner md:!tw-flex md:!tw-flex-col md:!tw-items-center md:!tw-justify-center md:!tw-gap-[1.5rem]"
      >
        <div class="empty__banner__img md:!tw-w-[70%]">
          <img
            src="../../../../assets/images/member-banner.png"
            alt=""
            class="md:!tw-w-full"
          />
        </div>
        <div class="empty__banner__content md:!tw-mt-0">
          <h4>
            You don't have any<br class="!tw-hidden md:!tw-flex" />
            members yet.
          </h4>
          <p>It is a long established fact that a reader will be distracted</p>
        </div>
        <div class="empty__banner__footer tw-w-full md:!tw-mt-0">
          <v-btn
            class="button button-purple tw-w-full"
            width="100%"
            @click="onClickInviteContractor"
            >new Member</v-btn
          >
        </div>
      </div>
    </div>

    <AddConnectionModal
      v-if="isShowAddPreferredContractorModal"
      @onClickCloseTab="onClickCloseModel"
      @onRouteToConnections="onRouteToConnections"
      :defaultPropertyId="propertyId"
    />
  </section>
</template>
<script setup>
import { computed, reactive, ref, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";

import { useRoute, useRouter } from "vue-router";
import Button from "@/core/components/ui/general/Button.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PropertyCard from "@/modules/properties/components/Cards/PropertyCard.vue";
import PropertyMemberFilters from "@/modules/properties/components/PropertyMemberFilters.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import CollapsiblePropertyConnection from "../Collapsibles/CollapsiblePropertyConnection.vue";
import CollapsiblePropertyOccupiers from "../Collapsibles/CollapsiblePropertyOccupiers.vue";
import AddConnectionModal from "@/modules/connections/components/AddConnectionModal.vue";
import { CONNECTIONS } from "@/store/modules/connections";
import { USER_STORE } from "@/store/modules/user";

const route = useRoute();
const router = useRouter();
const store = useStore();

const loading = ref(false);
const connections = ref([]);
const isShowAddPreferredContractorModal = ref(false);
const onClickInviteContractor = () => {
  isShowAddPreferredContractorModal.value = true;
};
const onClickCloseModel = () => {
  isShowAddPreferredContractorModal.value = false;
};

const propertyConnections = computed(
  () => store.getters[`${CONNECTIONS}/propertyConnections`]
);

const propertyId = computed(() => {
  return parseInt(route.params?.propertyId, 10);
});
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const onRouteToConnections = async () => {
  await router.push({ name: "connections" });
};

const initialize = async () => {
  const payload = {
    userId: user.value?.id,
    propertyId: propertyId.value,
  };
  loading.value = true;
  try {
    const connectionRes = await store.dispatch(
      `${CONNECTIONS}/getConnectionsOfProperty`,
      payload
    );

    if (connectionRes) {
      connections.value = connectionRes;
      // console.log(connections.value, "connections response");
    }
  } catch (error) {
    loading.value = false;
  } finally {
    loading.value = false;
  }
};

onBeforeMount(async () => {
  await initialize();
  console.log(propertyConnections.value, "propertyConnections");
});
</script>
<style lang="scss" scoped>
.property-member-tab {
  @media screen and (max-width: 768px) {
    height: calc(100dvh - 313px);
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
  }
}
.member-grid-container {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
/*Empty Screen */
.empty__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty__banner {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    // .empty__banner__img {
    //   img {
    //     @include respond(s720) {
    //       width: 100%;
    //       max-width: 100%;
    //     }
    //   }
    // }
    .empty__banner__content {
      margin-top: 46px;
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
      .empty__banner__content__list {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 27px;
        .list__item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .v-icon {
            @include fluidFont(24, 24, 1);
            color: rgba($skyBlue, 1);
          }
          p {
            @include fluidFont(14, 14, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
    .empty__banner__footer {
      margin-top: 36px;
    }
  }
}
</style>
