import { TradeNetworkState } from "@/core/models/trade-network";

export default {
  setTradeNetworkContractor(state: TradeNetworkState, payload: Array<any>) {
    state.tradeNetworkContractorList = payload;
  },
  appendTradeNetworkContractor(state: TradeNetworkState, payload: Array<any>) {
    state.tradeNetworkContractorList.push(...payload);
  },
  setTotalPages(state: any, payload: number) {
    state.totalPages = payload;
  },
  setTradeNetworkContractorCount(state: any, payload: number) {
    state.tradeNetworkContractorCount = payload;
  },
  resetTradeNetworkContractorCount(state: any) {
    state.tradeNetworkContractorCount = 0;
  },
  setUserNetWorkContractor(state: TradeNetworkState, payload: Array<any>) {
    state.userNetworkContractorList = payload;
  },
  setUserNetworkConnections(state: TradeNetworkState, payload: Array<any>) {
    state.userNetworkConnectedList = payload;
  },
  appendUserNetworkConnections(state: TradeNetworkState, payload: Array<any>) {
    state.userNetworkConnectedList.push(...payload);
  },
  setTotalConnectionTabPages(state: any, payload: number) {
    state.totalConnectionTabPages = payload;
  },
  setFilterState(state: any, payload: any) {
    state.isClearFilter = payload;
  },
  setInviteContractorFromTradeNetwork(state: any, payload: any) {
    state.isInviteContractorFromTradeNetwork = payload;
  },
  resetInviteContractorFromTradeNetwork(state: any) {
    state.isInviteContractorFromTradeNetwork = false;
  },
  setExternalInvitedContractorList(
    state: TradeNetworkState,
    payload: Array<any>
  ) {
    state.externalInvitedContractorList = payload;
  },

  setInviteContractorProjectData(state: any, payload: any) {
    state.projectData = payload;
  },
  setIsExternalTradeRequestSuccess(state: any, payload: any) {
    state.isExternalTradeRequestSuccess = payload;
  },
};
