<template>
  <div class="workstation-trade-link" v-if="!loading && linkData">
    <div class="workstation-trade-link__logo">
      <img src="@/assets/images/logo-beta.svg" alt="" />
    </div>
    <div class="workstation-trade-link__inner">
      <div class="workstation-trade-link__header">
        <!-- <div class="workstation-trade-link__header-bg-image">
          <img src="@/assets/images/workstation-trade-link-image.png" alt="" />
        </div> -->

        <div class="workstation-trade-link__header-inner">
          <div class="workstation-trade-link__header-image">
            <img
              :src="getImagePath(userWorkStationDetails?.profileImage)"
              loading="lazy"
              alt="workstation images"
            />
          </div>
          <div class="workstation-trade-link__header-content">
            <div class="workstation-trade-link__header-title">
              <p>{{ userWorkStationDetails?.name }}</p>
            </div>
            <div class="workstation-trade-link__header-tags">
              <div class="workstation-trade-link__trade-tag">
                <p>
                  {{
                    workStationType(
                      userWorkStationDetails?.user?.userBusinessDetails?.type
                    )
                  }}
                </p>
              </div>
              <div class="workstation-trade-link__verify-tag">
                <div
                  class="tw-flex tw-items-center"
                  v-if="
                    userWorkStationDetails?.verificationStatus ===
                    UserVerificationStatusEnum.VERIFIED
                  "
                >
                  <svg
                    width="24"
                    height="25"
                    fill="none"
                    viewBox="0 0 24 25"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#a)">
                      <path
                        d="m11.19 1.86-7 3.11C3.47 5.29 3 6.01 3 6.8v4.7c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V6.8c0-.79-.47-1.51-1.19-1.83l-7-3.11c-.51-.23-1.11-.23-1.62 0Zm-1.9 14.93L6.7 14.2a.996.996 0 1 1 1.41-1.41L10 14.67l5.88-5.88a.996.996 0 1 1 1.41 1.41l-6.59 6.59a.996.996 0 0 1-1.41 0Z"
                        fill="#40AE42"
                      />
                    </g>
                    <defs>
                      <clipPath id="a">
                        <path
                          fill="#fff"
                          transform="translate(0 .5)"
                          d="M0 0h24v24H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="tw-flex tw-items-center" v-else>
                  <ErrorRedIcon />
                  <strong>Verified</strong>
                </div>

                <span> TRADE CONTRACTOR</span>
              </div>
            </div>
            <div class="workstation-trade-link__rating">
              <v-rating
                :length="5"
                :size="32"
                v-model="getAverageOfAllProjectReview"
                active-color="orange"
                color="rgba(12, 15, 74, 0.26)"
                empty-icon="mdi-star"
                readonly
              ></v-rating>
              <p>{{ getAverageOfAllProjectReview }} stars</p>
            </div>
            <div class="workstation-trade-link__check-list">
              <div
                class="workstation-trade-link__check-list-item"
                v-if="userWorkStationInsurance.length"
              >
                <svg
                  width="26"
                  height="26"
                  fill="none"
                  viewBox="0 0 26 26"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="#D7E9FF" />
                  <path
                    d="m11.27 15.689-2.484-2.412a.713.713 0 0 0-.994 0 .664.664 0 0 0 0 .964l2.974 2.888a.722.722 0 0 0 1.001 0l7.526-7.298a.664.664 0 0 0 0-.965.713.713 0 0 0-.994 0l-7.029 6.823Z"
                    fill="#0C0F4A"
                  />
                </svg>
                <p>
                  <strong>Insurance:</strong>
                  <span
                    v-for="(
                      insurance, index
                    ) in userWorkStationInsurance?.slice(0, 2)"
                    :key="insurance.id"
                  >
                    {{ insurance?.metadata?.insuranceName }}
                    <span
                      v-if="
                        index < userWorkStationInsurance.slice(0, 2).length - 1
                      "
                    >
                      &
                    </span>
                  </span>
                </p>
              </div>
              <div class="workstation-trade-link__check-list-item">
                <svg
                  width="26"
                  height="26"
                  fill="none"
                  viewBox="0 0 26 26"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="#D7E9FF" />
                  <path
                    d="m11.27 15.689-2.484-2.412a.713.713 0 0 0-.994 0 .664.664 0 0 0 0 .964l2.974 2.888a.722.722 0 0 0 1.001 0l7.526-7.298a.664.664 0 0 0 0-.965.713.713 0 0 0-.994 0l-7.029 6.823Z"
                    fill="#0C0F4A"
                  />
                </svg>
                <p><strong>Qualifications</strong> & <strong>ID</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="workstation-trade-link__body">
        <div class="workstation-trade-link__card-list">
          <div class="workstation-trade-link__card">
            <div class="workstation-trade-link__card-icon">
              <svg
                width="43"
                height="49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 43 49"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.515 18.056h14.962v.515a7.465 7.465 0 0 1-7.482 7.483 7.465 7.465 0 0 1-7.48-7.482v-.516ZM21.995.407c-2.192 0-3.956 1.414-3.956 3.173v5.88h-.73a1.281 1.281 0 0 1-1.284-1.286V4.573a10.587 10.587 0 0 0-4.594 7.936h-.918c-.97 0-1.75.78-1.75 1.75v2.045c0 .97.78 1.754 1.75 1.754h.72v.548c0 6.026 4.803 10.877 10.763 10.877 5.963 0 10.762-4.85 10.762-10.877v-.549h.72c.97 0 1.75-.783 1.75-1.753V14.26c0-.97-.78-1.75-1.75-1.75h-.92a10.593 10.593 0 0 0-4.632-7.972v3.638c0 .712-.572 1.285-1.285 1.285h-.69V3.58c0-1.759-1.765-3.173-3.956-3.173Z"
                  fill="#fff"
                />
                <path
                  d="m24.19 40.258-1.342-4.046 2.262-3.23h-6.228l2.263 3.23-1.343 4.046-6.63-8.33-7.339 3.976c-1.586.86-2.179 1.607-2.753 2.958l-1.985 4.662c-.415 1.777.458 2.45 1.258 2.697 3.4.764 13.042 2.29 19.641 2.29 6.599 0 16.24-1.526 19.644-2.29.796-.248 1.67-.921 1.255-2.697l-1.984-4.662c-.576-1.351-1.168-2.098-2.755-2.958l-7.341-3.978-6.623 8.332Z"
                  fill="#fff"
                />
              </svg>
            </div>
            <div class="workstation-trade-link__card-content">
              <p>Jobs</p>
              <h3>{{ userWorkStationDetails?.jobs }}</h3>
            </div>
          </div>
          <div class="workstation-trade-link__card">
            <div class="workstation-trade-link__card-icon">
              <svg
                width="57"
                height="56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 57 56"
              >
                <path
                  d="M8.354 9.766c.75-.546 1.434-1.161 1.912-1.912l5.668 5.668c-.683.615-1.366 1.23-1.912 1.912L8.354 9.766Zm34.624 30.732a25.272 25.272 0 0 1-1.912 1.912l5.668 5.668c.547-.751 1.161-1.434 1.912-1.912l-5.668-5.668Zm-28.956 0-5.668 5.668c.75.546 1.434 1.16 1.912 1.912l5.668-5.668a25.255 25.255 0 0 1-1.912-1.912Zm28.956-24.996 5.668-5.668c-.75-.546-1.434-1.16-1.912-1.912l-5.668 5.668c.614.547 1.297 1.23 1.912 1.912ZM4.598 8.195a4.11 4.11 0 0 0 4.097-4.097A4.11 4.11 0 0 0 4.598 0 4.11 4.11 0 0 0 .5 4.098a4.11 4.11 0 0 0 4.098 4.097Zm0 39.61A4.11 4.11 0 0 0 .5 51.902 4.11 4.11 0 0 0 4.598 56a4.11 4.11 0 0 0 4.097-4.098 4.11 4.11 0 0 0-4.097-4.097Zm47.804-39.61A4.11 4.11 0 0 0 56.5 4.098 4.11 4.11 0 0 0 52.402 0a4.11 4.11 0 0 0-4.097 4.098 4.11 4.11 0 0 0 4.097 4.097Zm0 39.61a4.11 4.11 0 0 0-4.097 4.097A4.11 4.11 0 0 0 52.402 56a4.11 4.11 0 0 0 4.098-4.098 4.11 4.11 0 0 0-4.098-4.097Zm-21.17-15.707h-5.464c-3.005 0-5.463 2.458-5.463 5.463v4.644c2.39 1.434 5.19 2.185 8.195 2.185s5.805-.82 8.195-2.185V37.56c0-3.005-2.458-5.463-5.463-5.463ZM28.5 11.61c-9.015 0-16.39 7.375-16.39 16.39 0 4.849 2.117 9.22 5.463 12.224v-2.663c0-3.688 2.459-6.83 5.805-7.854-1.024-1.229-1.707-2.731-1.707-4.439 0-3.756 3.073-6.829 6.829-6.829s6.83 3.073 6.83 6.83c0 1.707-.615 3.277-1.708 4.438 3.346 1.025 5.805 4.166 5.805 7.854v2.663A16.405 16.405 0 0 0 44.89 28c0-9.015-7.375-16.39-16.39-16.39Zm4.098 13.658a4.11 4.11 0 0 0-4.098-4.097 4.11 4.11 0 0 0-4.098 4.097 4.11 4.11 0 0 0 4.098 4.098 4.11 4.11 0 0 0 4.098-4.098Z"
                  fill="#fff"
                />
              </svg>
            </div>
            <div class="workstation-trade-link__card-content">
              <p>Connections</p>
              <h3>{{ userWorkStationDetails?.contractors }}</h3>
            </div>
          </div>
        </div>

        <div class="workstation-trade-link__services">
          <div class="workstation-trade-link__services-header">
            <p>Additional services - Book Now!</p>
          </div>
          <div class="workstation-trade-link__services-list">
            <div
              class="workstation-trade-link__services-item"
              v-for="skill in userSkills?.slice(0, 5)"
              :key="skill.id"
            >
              <v-icon
                v-if="skill.iconName"
                class="specialisms_multiselect_modal_item_title_icon"
              >
                <img
                  style="width: 14px"
                  v-if="skill.iconName"
                  :src="
                    require('@/assets/category-icons/' +
                      skill.iconName +
                      '.svg')
                  "
                  alt="icon"
                />
              </v-icon>
              <p>{{ skill?.poLabel }}</p>
            </div>
            <div v-if="!userSkills?.length">No Service Fouund</div>
          </div>
        </div>

        <div class="workstation-trade-link__gallery">
          <div
            class="workstation-trade-link__gallery-item"
            v-for="gallary in userWorkStationGallery"
            :key="gallary.id"
          >
            <img :src="getImagePath(gallary?.name)" alt="gallary images" />
          </div>
        </div>

        <div class="workstation-trade-link__payment_wallets">
          <!-- <v-btn class="apple_wallet footer-btn" @click="toggleWalletModal">
            <span class="btn_icon">
              <img src="@/assets/images/apple-wallet.png" alt="" />
            </span>
            <span class="btn_text"> Add to Apple Wallet</span>
          </v-btn> -->

          <v-btn class="apple_wallet footer-btn" @click="onClickGoogleWallet">
            <span class="btn_icon">
              <img src="@/assets/images/google-wallet.png" alt="" />
            </span>
            <span class="btn_text"> Add to Google Wallet</span>
          </v-btn>

          <v-btn
            class="button button-orange footer-btn"
            @click="onClickViewTradePassport"
            >View Trade Passport
            <!-- <svg
              width="49"
              height="48"
              fill="none"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="24.697" cy="24" r="24" fill="#fff" />
              <path
                d="M19.23 14.522a1.762 1.762 0 0 0 0 2.526l7.07 6.95-7.07 6.948a1.762 1.762 0 0 0 0 2.526c.711.698 1.859.698 2.57 0l8.364-8.221c.71-.699.71-1.827 0-2.526l-8.364-8.22c-.693-.681-1.859-.681-2.57.017Z"
                fill="#FAA500"
              />
            </svg> -->
          </v-btn>
        </div>

        <!-- <div class="workstation-trade-link__footer">
          <v-btn class="button button-orange" @click="onClickViewTradePassport"
            >View Trade Passport
            <svg
              width="49"
              height="48"
              fill="none"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="24.697" cy="24" r="24" fill="#fff" />
              <path
                d="M19.23 14.522a1.762 1.762 0 0 0 0 2.526l7.07 6.95-7.07 6.948a1.762 1.762 0 0 0 0 2.526c.711.698 1.859.698 2.57 0l8.364-8.221c.71-.699.71-1.827 0-2.526l-8.364-8.22c-.693-.681-1.859-.681-2.57.017Z"
                fill="#FAA500"
              />
            </svg>
          </v-btn>
        </div> -->
      </div>
    </div>
  </div>

  <Loader :show="loading" />
  <div v-if="!linkData && !loading">
    <div class="error__page">
      <div class="error__page__content">
        <div class="error__img">
          <img src="../../../assets/images/404-img.png" alt="" />
        </div>
        <div class="error__content">
          <h5>No Data Found</h5>
          <p>We couldn’t retrieve any data at tha moment</p>
        </div>
      </div>
    </div>
  </div>

  <PaymentWalletModal
    v-if="isOpenWalletModal"
    :getAverageOfAllProjectReview="getAverageOfAllProjectReview"
    :data="linkData"
    @on-save="toggleWalletModal"
    @on-cancel="toggleWalletModal"
  />
</template>

<script lang="ts" setup>
import Loader from "@/core/components/common/Loader.vue";
import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import {
  displayToastMessage,
  getCombineSameProjectReview,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import { TRADE_ESTIMATES_TRADE_PASSPORT } from "@/modules/project/routes";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed, ComputedRef, getCurrentInstance, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import PaymentWalletModal from "../modal/PaymentWalletModal.vue";

const loading = ref(false);
const store = useStore();
const route = useRoute();
const router = useRouter();
const internalInstance = getCurrentInstance();
const linkData = ref(null) as any;
const userWorkStationDetails = ref(null) as any;
const userWorkStationGallery = ref(null) as any;
const userSkills = ref(null);
const userWorkStationInsurance = ref(null) as any;
const userWorkStationRates = ref(null) as any;
const isOpenWalletModal = ref(false);

const toggleWalletModal = () => {
  isOpenWalletModal.value = !isOpenWalletModal.value;
};

const getImagePath = (imgUrl: string | null) => {
  if (!imgUrl)
    return require("@/assets/images/workstation-trade-link-image-2.png");

  return setDirectStoragePathToImage(imgUrl);
};
const workStationType = (businessType: string) => {
  return businessType === BusinessDetailTypeEnum.LIMITED_COMPANY
    ? "LTD Company"
    : "Sole Trader";
};

const onClickViewTradePassport = () => {
  if (!userWorkStationDetails.value?.id) return;
  store.dispatch(
    `${TRADE_ESTIMATE_STORE}/viewTradeUserAccount`,
    userWorkStationDetails.value?.id
  );
  router.push({
    name: TRADE_ESTIMATES_TRADE_PASSPORT,
  });
};

const combineSameProjectReview = computed(() => {
  return userWorkStationRates.value
    ? getCombineSameProjectReview(userWorkStationRates.value)
    : [];
});
const getAverageOfAllProjectReview = computed(() => {
  const totalAvg = combineSameProjectReview.value.reduce(
    (totalRating: number, review: any) => {
      totalRating += review.totalAvg || 0;
      return totalRating;
    },
    0
  );
  return totalAvg
    ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) / 100
    : totalAvg;
});
const onClickGoogleWallet = async () => {
  try {
    await store.dispatch(`${WORKSTATION}/getGooglePaymentWallet`, {
      userWorkStationId: userWorkStationDetails.value?.id,
    });
  } catch (error: any) {
    if (error.response) {
      const errorMessage =
        error.response.data.message ||
        "Something went wrong, please try again later.";
      displayToastMessage(internalInstance, errorMessage, "error");
    } else if (error.request) {
      displayToastMessage(
        internalInstance,
        "No response from server. Please try again later.",
        "error"
      );
    } else {
      displayToastMessage(internalInstance, `Error: ${error.message}`, "error");
    }
  } finally {
    toggleWalletModal();
  }
};
onMounted(async () => {
  try {
    loading.value = true;
    const getLinkData = await store.dispatch(
      `${WORKSTATION}/getWorkStationLinkData`,
      {
        token: route.params.token,
      }
    );
    linkData.value = getLinkData;
    userWorkStationDetails.value = linkData.value.userWorkstationDetails;
    userWorkStationGallery.value = linkData.value.userWorkStationGallery;
    userSkills.value = linkData.value.userSkills;
    userWorkStationInsurance.value =
      linkData.value.userWorkstationDetails.userInsurance;
    userWorkStationRates.value = linkData.value.userWorkStationRates;
  } catch (error) {
    linkData.value = null;
    displayToastMessage(
      internalInstance,
      "WorkStation Link not Valid!",
      "error"
    );
  } finally {
    loading.value = false;
  }
});
</script>
<style lang="scss" scoped>
.workstation-trade-link {
  background: linear-gradient(111.5deg, #f5faff 0%, #d3e9ff 100%);
  padding: 30px 50px;
  height: 100%;
  @include respond(md) {
    padding: 20px;
  }

  &__logo {
    max-width: 250px;
    width: 100%;
    margin: 30px auto;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__inner {
    max-width: 770px;
    width: 100%;
    padding: 26px;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 auto;
    border: 1px solid rgba($bordercolor, 1);
    @include respond(md) {
      max-width: 100%;
    }
  }

  &__header {
    padding: 0;
    position: relative;
    text-align: left;
    overflow: hidden;

    &-inner {
      background-color: rgba($backgroundcolor, 1);
      border-radius: 12px;
      border: 1px solid rgba($bordercolor, 1);
      padding: 26px 45px;
      display: flex;
      gap: 24px;
      align-items: flex-start;
      position: relative;
      z-index: 1;
      @include respond(sm) {
        flex-direction: column;
        padding: 20px;
      }
    }

    &-image {
      width: 125px;
      height: 125px;
      aspect-ratio: 1/1;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-title {
      @include fluidFont(40, 20, 1);
      font-weight: 600;
      margin-bottom: 12px;
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
    }
  }

  &__trade-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: rgba($blueDark, 1);
    text-transform: uppercase;
    padding: 7px 13px;
    background-color: #fddec7;
    @include fluidFont(14, 10, 1.2);
    font-weight: 700;
  }

  &__verify-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    text-transform: uppercase;
    padding: 7px 13px;
    gap: 6px;
    font-weight: 700;
    @include fluidFont(16, 10, 1);
    background-color: rgba($white, 1);
    border: 1px solid rgba($bordercolor, 1);

    strong {
      color: #40ae42;
      font-weight: 700;
    }
  }

  &__rating {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    padding: 10px;
    font-weight: 400;
    margin-top: 20px;
    background-color: rgba($white, 1);
    border: 1px solid rgba($bordercolor, 1);

    :deep(.v-rating__wrapper) {
      height: 16px;
      .v-btn {
        height: auto !important;
        width: auto !important;
        vertical-align: super !important;
        .v-btn__content {
          .v-icon {
            font-size: 14px;
          }
        }
      }
    }
    p {
      @include fluidFont(13, 13, 1.2);
      color: rgba($blueDark, 1);
      letter-spacing: 0.09px;
      font-weight: 500;
    }
  }

  &__check-list {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 14px;
    @include respond(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17.57px;

      svg {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
      }

      strong {
        font-weight: 700;
      }
    }
  }

  &__body {
    padding: 16px 0 0;
    text-align: left;
    position: relative;
    //
    //     &::before {
    //       content: "";
    //       position: absolute;
    //       bottom: 60px;
    //       left: 50%;
    //       transform: translateX(-50%);
    //       width: calc(100% - 300px);
    //       aspect-ratio: 1/1;
    //       border-radius: 100%;
    //       background: #4b4bff66;
    //       filter: blur(60px);
    //       pointer-events: none;
    //       opacity: 0.8;
    //
    //       @media (max-width: 767px) {
    //         width: calc(100% + 120px);
    //       }
    //     }
  }

  &__card-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    position: relative;
    z-index: 2;
    @include respond(sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__card {
    background: #40ae421a;
    border-radius: 14px;
    padding: 20px;
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 15px;
    align-items: center;

    &-icon {
      width: 65px;
      height: 65px;
      aspect-ratio: 1/1;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #40ae42;
      svg {
        width: 25px;
        height: 25px;
      }
    }

    &-content {
      h3 {
        @include fluidFont(36, 20, 1);
        font-weight: 500;
        margin-top: 5px;
      }
      p {
        @include fluidFont(16, 14, 1);
        font-weight: 500;
      }
    }

    &:nth-child(2) {
      background: #4b4bff0f;

      .workstation-trade-link__card-icon {
        background: #4b4bff;
      }
      //
      //       h3 {
      //         @media (max-width: 767px) {
      //           color: #4b4bff;
      //         }
      //       }
    }
  }

  &__services {
    border: 1px solid rgba($bordercolor, 1);
    border-radius: 12px;
    padding: 22px 30px 30px;
    margin-top: 16px;
    position: relative;
    z-index: 2;
    background-color: rgba($backgroundcolor, 1);

    &-header {
      text-align: center;
      @include fluidFont(14, 10, 1);
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
    }

    &-item {
      border: 1px solid rgba($bordercolor, 1);
      background-color: #fff;
      border-radius: 8px;
      padding: 12px;
      display: inline-flex;
      align-items: center;
      gap: 8px;
      @include fluidFont(16, 12, 15.05px);
      font-weight: 500;
      width: calc(33.33% - 11px);
      max-width: 100%;
      @include respond(md) {
        width: calc(50% - 8px);
      }
      @include respond(sm) {
        width: 100%;
      }
      .v-icon {
        width: 22px;
        height: 22px;
        background-color: rgba($orange, 1);
        border-radius: 100%;
        img {
          filter: invert(100%) sepia(0%) saturate(7448%) hue-rotate(78deg)
            brightness(112%) contrast(89%);
        }
        svg {
          flex-shrink: 0;
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  &__gallery {
    max-width: 100%;
    width: 100%;
    margin: 16px auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    z-index: 2;
    flex-wrap: wrap;
    @include respond(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include respond(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    &-item {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      height: 170px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__footer {
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
      margin-top: 60px;
    }

    :deep(.button) {
      width: 100%;
      height: auto;

      .v-btn__content {
        display: flex;
        align-items: center;
        gap: 18px;
        font-size: 20px !important;
        line-height: normal !important;
        text-transform: none;
        font-weight: 700;

        @media (max-width: 767px) {
          font-size: 16px !important;
          gap: 15px;
        }

        svg {
          width: 20px;
          height: 20px;
          @media (max-width: 767px) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.error__page {
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  .error__page__content {
    width: 200px;
    max-width: 100%;
    .error__img {
      width: 70px;
      height: 70px;
      max-width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .error__content {
      margin-top: 30px;
      h5 {
        @include fluidFont(20, 20, 25.1px);
        font-weight: 400;
        color: rgba(0, 38, 70, 1);
      }
      p {
        @include fluidFont(16, 16, 17.57px);
        font-weight: 400;
        color: rgba($blueDark, 1);
        margin-top: 12px;
      }
    }
  }
}

.workstation-trade-link__payment_wallets {
  text-align: center;
  margin: 32px 0 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  @include respond(md) {
    flex-wrap: wrap;
  }
  .footer-btn {
    min-height: 48px;
    background-color: #000;
    border-radius: 8px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.08px;
    letter-spacing: 0.2px;
    width: calc(33.33% - 7px);
    max-width: 100%;
    @include respond(md) {
      width: calc(50% - 9px);
    }
    @include respond(sm) {
      width: 100%;
    }
    :deep(.v-btn__content) {
      gap: 16px;
    }
  }
  .footer-btn.button-orange {
    min-height: 48px;
    background-color: rgba($orange, 1);
    border-radius: 8px;
    color: #fff;
    :deep(.v-btn__content) {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.08px;
      letter-spacing: 0.2px;
    }
  }
}
.workstation-trade-link__header-content {
  width: calc(100% - 150px);
  max-width: 100%;
  @include respond(sm) {
    width: 100%;
  }
}
</style>
