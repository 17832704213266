import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8af1704"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header md:!tw-right-[-13px] tw-z-[1]" }
const _hoisted_2 = { class: "md:!tw-flex md:!tw-flex-col md:!tw-gap-[.5rem]" }
const _hoisted_3 = { class: "carousel__img tw-m-auto tw-relative !tw-w-4/5" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "trade_passport_preview_modal_title" }
const _hoisted_6 = { class: "trade_passport_preview_modal_text" }
const _hoisted_7 = { class: "carousel-controls" }
const _hoisted_8 = { class: "right_btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!
  const _component_CommonDialog = _resolveComponent("CommonDialog")!

  return (_openBlock(), _createBlock(_component_CommonDialog, {
    isShowHeader: false,
    isShowBackButton: false,
    width: "655px",
    "max-width": "100%",
    className: ['trade_passport_preview_modal ', ' md:!tw-overflow-[unset]']
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          icon: "mdi-close",
          onClick: $setup.onClickCloseButton
        }, null, 8, ["onClick"])
      ]),
      _createVNode(_component_v_carousel, {
        height: "auto",
        class: "",
        style: {"overflow":"initial"},
        "hide-delimiter-background": "",
        "delimiter-icon": "mdi-square",
        "show-arrows": false,
        continuous: false,
        modelValue: $setup.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, i) => {
            return (_openBlock(), _createBlock(_component_v_carousel_item, { key: i }, {
              default: _withCtx(() => [
                _createVNode(_component_v_sheet, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("img", {
                          width: "90",
                          class: "tw-relative tw-z-10 !tw-object-contain",
                          src: item.src
                        }, null, 8, _hoisted_4)
                      ]),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(item?.title), 1),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(item?.description), 1)
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_7, [
        ($setup.model !== 3)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              variant: "plain",
              class: "skip_btn",
              onClick: $setup.skipcontinue
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Skip ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_btn, {
            variant: "elevated",
            class: "button button-purple-border",
            width: "auto",
            disabled: $setup.model === 0,
            onClick: $setup.onClickCloseButton
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_v_btn, {
            variant: "elevated",
            class: "button button-purple",
            width: "auto",
            onClick: $setup.handleNextClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.model === $setup.items.length - 1 ? "Continue" : "Next"), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }))
}