<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="655px"
    max-width="100%"
    class="dispute-process-modal"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <div class="modal-header">
            <div class="title">
              <h2 class="modal-title">Phases & Contractors</h2>
              <v-icon
                class="cursor-pointer"
                icon="mdi-close"
                :size="15"
                @click="onClickCloseButton"
              ></v-icon>
            </div>
            <p>
              Please help us understand which part of the project & which trade
              contractor are you referring to by selecting all that apply.
            </p>
          </div>
          <div class="modal-body">
            <div>
              <v-form
                ref="disputeForm"
                @submit.prevent
                :rules="disputeProjectFormRules"
              >
                <p>The dispute is related to:</p>
                <v-radio-group
                  v-model="disputeType"
                  :rules="disputeProjectFormRules.disputeType"
                >
                  <v-radio label="The Whole Project" value="project"></v-radio>
                  <v-radio label="Phases" value="phases"></v-radio>
                  <div v-if="disputeType === 'phases'">
                    <v-select
                      :rules="disputeProjectFormRules.validatePhases"
                      density="compact"
                      variant="outlined"
                      v-model="selectedPhases"
                      :items="estimatePhases"
                      item-title="name"
                      item-value="id"
                      label="Select Phases"
                      multiple
                      dense
                      hide-details
                    ></v-select>
                  </div>
                </v-radio-group>
              </v-form>
            </div>

            <div>
              <h3>I have concerns with the following trade contractor:</h3>

              <div class="contractor_card_list">
                <v-card
                  class="courses__card"
                  @click="toggleSelection(contractor?.id)"
                  v-for="(contractor, index) in contractorList"
                  :key="index"
                  :class="{ 'selected-card': isSelected(contractor.id) }"
                >
                  <div>
                    <p>
                      {{ contractor.name }}
                    </p>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="button button-purple-border"
              @click="onClickCloseButton"
            >
              Cancel
            </button>
            <button
              class="button button-purple"
              @click="onContinueDisputeProject"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { ref, computed, onMounted } from "vue";

const emits = defineEmits(["close", "continue"]);
const props = defineProps({
  estimateData: {
    type: Object,
    default: null,
  },
});
const disputeForm = ref(null) as any;
const selectedPhases = ref([]);
const disputeType = ref("project");
const selectedContractorIds = ref([]) as any;

const toggleSelection = (index: number) => {
  const selectedIndex = selectedContractorIds.value.indexOf(index);
  if (selectedIndex === -1) {
    selectedContractorIds.value.push(index);
  } else {
    selectedContractorIds.value.splice(selectedIndex, 1);
  }
};
const isSelected = (index: number) => {
  return selectedContractorIds.value.includes(index);
};
const disputeProjectFormRules = ref({
  disputeType: [
    (value: string) => !!value || "Please select the dispute type.",
  ],
  validatePhases: [
    (value: any[]) =>
      disputeType.value !== "phases" ||
      (value && value.length > 0) ||
      "Please select at least one phase.",
  ],
});

const estimatePhases = computed(() => {
  return props.estimateData?.estimationPhase;
});

const contractorList = computed(() => {
  const initialContractors = props.estimateData?.inviteContractors.map(
    (contractor: any) => contractor?.toContractorWorkstation
  );

  const mainContractor = props.estimateData?.workStation;

  if (
    mainContractor &&
    !initialContractors.some(
      (contractor: any) => contractor.id === mainContractor.id
    )
  ) {
    initialContractors.push(mainContractor);
    selectedContractorIds.value.push(mainContractor.id);
  }

  return initialContractors;
});

const onClickCloseButton = () => {
  emits("close");
};

const onContinueDisputeProject = async () => {
  const isFormValid = await disputeForm.value.validate();

  if (!isFormValid.valid) return;

  emits("continue", {
    phases: selectedPhases.value,
    contractorIds: selectedContractorIds.value,
  });
};
</script>
<style lang="scss" scoped>
.dispute-process-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            padding-bottom: 24px;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);
            .modal-body-text {
              @include fluidFont(14, 14, 22px);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
            .steps-list {
              margin: 24px 0;
              display: flex;
              flex-direction: column;
              gap: 16px;

              .step-item {
                display: flex;
                gap: 8px;
                align-items: flex-start;
                .step-icon {
                  margin-top: 5px;
                  input[type="checkbox"] {
                    width: 16px;
                    height: 16px;
                    accent-color: rgba(75, 75, 255, 1);
                  }
                }
                .step-details {
                  width: 100%;
                  max-width: 100%;
                  label {
                    @include fluidFont(14, 14, 16px);
                    font-weight: 700;
                    color: rgba($blueDark, 1);
                  }

                  p {
                    @include fluidFont(12, 12, 16px);
                    font-weight: 400;
                    color: rgba($blueDark, 0.5);
                  }
                }
              }

              .step-item {
                display: flex;
                gap: 8px;
                align-items: flex-start;
                .step-icon {
                  margin-top: 5px;
                  input[type="radio"] {
                    width: 16px;
                    height: 16px;
                    accent-color: rgba(75, 75, 255, 1);
                  }
                }
                .step-details {
                  width: 100%;
                  max-width: 100%;
                  label {
                    @include fluidFont(14, 14, 16px);
                    font-weight: 700;
                    color: rgba($blueDark, 1);
                  }
                  .input_field {
                    margin: 0;
                    :deep(.v-input__control) {
                      .v-field {
                        border: 1px solid #dfdfdf;
                        border-radius: 8px;
                        // background-color: #f8fbfd;
                        box-shadow: none;
                        .v-field__field {
                          .v-field__input {
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            @include fluidFont(12, 12, 1.2);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                            letter-spacing: 0.4px;
                            padding: 12px 16px;
                            top: 0;
                          }
                          textarea {
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            @include fluidFont(12, 12, 1.2);
                            font-weight: 400;
                            color: rgba($blueDark, 1);
                            letter-spacing: 0.4px;
                            min-height: 42px;
                            padding: 12px 16px;
                            mask-image: unset;
                          }
                        }
                        .v-field__outline {
                          display: none;
                        }
                      }
                    }
                  }

                  p {
                    @include fluidFont(12, 12, 16px);
                    font-weight: 400;
                    color: rgba($blueDark, 0.5);
                  }
                }
              }
            }
            .next-steps {
              @include fluidFont(14, 14, 22px);
              font-weight: 500;
              color: rgba($blueDark, 1);
              a {
                @include fluidFont(14, 14, 22px);
                font-weight: 700;
                color: rgba(51, 102, 255, 1);
                text-decoration: underline;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
</style>
