import _ from "lodash";

const projectDetails = {
  projectType: null,
  projectSubType: null,
  projectTitle: null,
  projectDescription: null,
  projectStartDate: null,
  projectEndDate: null,
};
export default {
  projectDetails,
  defaultProjectDetails: _.cloneDeep(projectDetails),
  allProjects: [],
  draftProjects: [],
  completedProjects: [],
  draftProjectList: [],
  selectedPropertiesList: [],
  propertyListState: null,
  projectRedirectTabName: null,
  projectEstimateWithEscrow: null,
  projectDispute: null,

  projectSpecialisms: [],
  projectAttachments: [],
  invitedContractors: [],

  projectRequestedList: [],
  // temporary form state
  historicalProjectForm: {
    projectType: null,
    specialisms: [],
    name: "",
    description: "",
    property: null,
    attachments: null,
    invitedContractors: [],

    projectEstimate: {
      estimateStartDate: null,
      estimates: [],
    },
  },
  projectEstimateEscrowBalance: 0,
  projectStatusCountsList: {},
  rateCategories: [],
  totalProjects: 0,
  totalPages: 0,
  totalDraftProjects: 0,
  totalDraftProjectPages: 0,
  totalCompletedProjects: 0,
  totalCompletedProjectPages: 0,
};
