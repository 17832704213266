<template>
  <v-btn
    class="reusable-button"
    :class="[buttonClass, { '!tw-capitalize': isCapitalize }]"
    :disabled="!isActive || isLoading"
    :loading="isLoading"
    variant="flat"
    :ripple="ripple"
  >
    <div
      class="tw-w-full tw-flex tw-flex-row tw-gap-2 tw-items-center tw-justify-center"
    >
      <slot name="prefix"></slot>
      {{ label }}
      <slot name="postfix"></slot>
    </div>
  </v-btn>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
  variant: {
    type: String,
    default: "primary", // variants: primary, seconday, tertiary, text, custom
  },
  label: String,
  isActive: {
    type: Boolean,
    default: true,
  },
  isLoading: Boolean,
  activeBgColor: {
    type: String,
    default: "#FAA500",
  },
  activeFontColor: {
    type: String,
    default: "#FFF",
  },
  fontSize: {
    type: String,
    default: "14px",
  },
  height: {
    type: String,
    default: "42px",
  },
  width: {
    type: String,
    default: "auto",
  },
  borderRadius: {
    type: String,
    default: "8px",
  },
  border: {
    type: String,
    default: "1px solid",
  },
  borderColor: {
    type: String,
    default: "transparent",
  },
  ripple: {
    type: Boolean,
    default: true,
  },
  isCapitalize: {
    type: Boolean,
    default: false,
  },
});

const buttonClass = computed(() => `button-${props.variant}`);
</script>
<script>
export default {
  name: "Button",
};
</script>
<style lang="scss" scoped>
:deep(.v-btn__content) {
  height: 100%;
}
.reusable-button {
  @apply tw-h-[42px] tw-max-h-[42px] tw-px-[.5rem] tw-box-border tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-justify-center
    tw-text-sm tw-font-bold tw-font-mullish tw-m-0 tw-py-0;
}

.reusable-button.v-btn--disabled {
  @apply tw-bg-[rgba(12,15,74,0.04)] tw-text-[rgba(12,15,74,0.5)] hover:tw-bg-[rgba(12,15,74,0.04)];
  cursor: no-drop !important;
}

.button-primary {
  @apply tw-bg-orange tw-border-none tw-border-0 tw-text-white tw-w-full;
}

.button-secondary {
  @apply tw-bg-white tw-border-[1px] tw-border-[#4F55F0] tw-text-accentBlue tw-w-full;
}

.button-tertiary {
  @apply tw-bg-[rgba(12,15,74,0.04)] tw-text-[rgba(12,15,74,0.5)] hover:tw-bg-[rgba(12,15,74,0.04)]
    tw-border-none tw-border-0 tw-w-full;
}
.button-blue {
  @apply tw-bg-blue-500 tw-text-white hover:tw-bg-blue-600 tw-w-full tw-border-none tw-border-0;
}

.button-text {
  @apply tw-bg-transparent tw-text-[#0C0F4A]
    tw-border-none tw-border-0 tw-w-full;
}

.button-custom {
  height: v-bind("props.height") !important;
  width: v-bind("props.width") !important;
  max-height: v-bind("props.height") !important;
  min-height: v-bind("props.height") !important;
  background: v-bind("props.activeBgColor");
  color: v-bind("props.activeFontColor");
  border: v-bind("props.border");
  border-color: v-bind("props.borderColor");
  border-radius: v-bind("props.borderRadius");
  font-size: v-bind("props.fontSize");
}
</style>
