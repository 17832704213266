<template>
  <div @scroll="onScroll" class="project-listing-container scroll-container">
    <div class="mobile__wrapper">
      <v-expansion-panels>
        <v-expansion-panel>
          <template v-slot:title>
            <img src="../../../assets/icons/filter-icon.svg" />
            <h6>All Filters</h6>
          </template>
          <v-expansion-panel-text>
            <div class="project-listing__filter">
              <v-text-field
                v-model="filter.search"
                placeholder="Search Project name, Type, Description"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
              >
                <template v-slot:prepend-inner>
                  <img src="../../../assets/icons/trade-network-search.svg" />
                </template>
              </v-text-field>
              <v-text-field
                placeholder="KT19 9JG"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                v-model="filter.postcode"
              >
                <template v-slot:prepend-inner>
                  <img src="../../../assets/icons/trade-network-location.svg" />
                </template>
              </v-text-field>
              <!-- <v-text-field
                class="input_field"
                placeholder="Miles"
                bg-color="#fff"
                variant="outlined"
                type="number"
                v-model="filter.radius"
              >
                <template v-slot:prepend-inner>
                  <img
                    src="../../../assets/icons/trade-network-right-left-arrow.svg"
                  /> </template
              ></v-text-field> -->
              <v-select
                :items="projectStatusItems"
                item-title="text"
                item-value="value"
                v-model="filter.projectJobStatus"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                placeholder="Project Status"
                clearable
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props"
                    ><img
                      class="service-icon-wrapper__icon"
                      :src="require(`@/assets/icons/${item.raw.icon}.svg`)"
                      alt="icon"
                  /></v-list-item>
                </template>
              </v-select>
              <v-select
                v-model="filter.propertyCategory"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                placeholder="Commercial / Residential"
                :items="propertyTypeItems"
                item-title="text"
                item-value="value"
                clearable
                ><template v-slot:prepend-inner>
                  <img
                    src="../../../assets/icons/trade-network-trader.svg"
                  /> </template
              ></v-select>
              <v-select
                v-model="filter.type"
                class="input_field"
                bg-color="#fff"
                variant="outlined"
                placeholder="Commercial / Residential"
                :items="jobTypes"
                item-title="text"
                item-value="value"
                ><template v-slot:prepend-inner>
                  <img
                    src="../../../assets/icons/trade-network-list.svg"
                  /> </template
              ></v-select>
            </div>
          </v-expansion-panel-text> </v-expansion-panel
      ></v-expansion-panels>
    </div>

    <div class="project__header md:!tw-mt-0">
      <div class="project-listing__filter desktop__wrapper">
        <div class="left__block">
          <v-text-field
            v-model="filter.search"
            placeholder="Search Project name, Type, Description"
            class="input_field"
            bg-color="#fff"
            variant="outlined"
          >
            <template v-slot:prepend-inner>
              <img src="../../../assets/icons/trade-network-search.svg" />
            </template>
          </v-text-field>
          <v-text-field
            placeholder="KT19 9JG"
            class="input_field small__width"
            bg-color="#fff"
            variant="outlined"
            v-model="filter.postcode"
            width="143px"
          >
            <template v-slot:prepend-inner>
              <img src="../../../assets/icons/trade-network-location.svg" />
            </template>
          </v-text-field>
          <!-- <v-text-field
            v-model="filter.radius"
            placeholder="Miles"
            class="input_field small__width"
            bg-color="#fff"
            variant="outlined"
            type="number"
            width="143px"
          >
            <template v-slot:prepend-inner>
              <img src="../../../assets/icons/distance.svg" />
            </template>
          </v-text-field> -->
        </div>
        <div class="project-listing-innertabs right__block">
          <div class="two_icons">
            <div class="right_btn" :class="{ active: !isMapView }">
              <v-btn
                prepend-icon="mdi-view-grid"
                variant="text"
                @click="onClickTileMapBtn"
              >
                Tile
              </v-btn>
            </div>
            <div class="right_btn" :class="{ active: isMapView }">
              <v-btn
                prepend-icon="mdi-map"
                variant="text"
                @click="onClickTileMapBtn"
              >
                Map
              </v-btn>
            </div>
          </div>

          <div class="sort__wrapper sort_dropdown_wrapper">
            <div class="sortby_btn">Sort by</div>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="sort__btn">
                  <img src="../../../assets/icons/sort.svg" alt="" />
                </v-btn>
              </template>

              <!-- TODO : Toggle Active Class With Item -->
              <v-list class="dropdown__sort sort_dropdown_inner">
                <v-list-item v-for="item in filterOptions" :key="item.value">
                  <v-list-item-title @click="onClickFilterOption"
                    ><v-icon :icon="item.icon" width="12" /> {{ item.label }}
                    <span class="checked__wrapper">
                      <v-icon icon="mdi-check" /> </span
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
              <DatePicker
                v-if="isShowDatePicker"
                :masks="masks"
                class="calendar__select calendar__select__custom"
                v-model.range="filter.dataRange"
                mode="date"
              />
            </v-menu>
          </div>
        </div>
      </div>
      <div class="project-listing__filter desktop__wrapper">
        <v-select
          :items="projectStatusItems"
          item-title="text"
          item-value="value"
          v-model="filter.projectJobStatus"
          class="input_field"
          bg-color="#fff"
          variant="outlined"
          placeholder="Project Status"
          clearable
          :menu-props="{ contentClass: 'icon__text__select' }"
        >
          <template v-slot:prepend-inner>
            <img src="../../../assets/icons/project-status.svg" />
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props"
              ><img
                class="service-icon-wrapper__icon"
                :src="require(`@/assets/icons/${item.raw.icon}.svg`)"
                alt="icon"
            /></v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="filter.propertyCategory"
          class="input_field"
          bg-color="#fff"
          variant="outlined"
          placeholder="Commercial / Residential"
          :items="propertyTypeItems"
          item-title="text"
          item-value="value"
          :menu-props="{ contentClass: 'icon__text__select' }"
          clearable
          ><template v-slot:prepend-inner>
            <img
              src="../../../assets/icons/trade-network-trader.svg"
            /> </template
        ></v-select>
        <v-select
          v-model="filter.type"
          class="input_field"
          bg-color="#fff"
          variant="outlined"
          placeholder="Project Type"
          :items="jobTypes"
          item-title="text"
          item-value="value"
          clearable
          :menu-props="{ contentClass: 'icon__text__select' }"
          ><template v-slot:prepend-inner>
            <img
              src="../../../assets/icons/trade-network-list.svg"
            /> </template
        ></v-select>
      </div>
    </div>
    <!-- LOADER -->
    <div
      v-if="IsLoading"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[500px]"
    >
      <Loader :show="IsLoading" />
    </div>
    <template v-else>
      <div class="top_project_listing" v-if="!isEmptyPropertiesList">
        <div class="top_list_details">
          <div>{{ getProjectCount }} Projects</div>
          |
          <div>{{ getProjectCount }} Results</div>
        </div>
      </div>
      <div class="top_project_listing_content" :class="{ show: isMapView }">
        <div class="project_main map_wrapper">
          <div class="full_container" :class="{ half_container: isMapView }">
            <ProjectCard
              v-for="item in projectListing"
              :key="item.id"
              :job="item"
              @click="onClickProject(item?.refId, item.type)"
              :highlightProject="highlightProjectIds.has(item?.id)"
            />
            <!-- v-if="isLoadingMorePage" -->
          </div>
          <div
            v-if="isLoadingMorePage"
            class="tw-absolute tw-bottom-[100px] tw-left-1/2 -tw-translate-1/2"
          >
            <Loader :show="isLoadingMorePage" />
          </div>
          <!-- <v-row>
            <v-btn
              v-if="isPaginationAvailable"
              @click="onLoadMore"
              variant="text"
              aria-label="Refresh"
              class="back_btn yellow-text"
              append-icon="mdi-chevron-right"
              >load more Properties</v-btn
            >
          </v-row> -->
        </div>

        <div
          v-if="addresses && addresses.length"
          class="map__container tw-rounded-xl tw-overflow-hidden"
        >
          <!-- <AddressMapComponent
            :latlng="[53.5809811, -0.6393335]"
            :addresses="addresses"
          /> -->

          <PropertyMapModal
            :properties="parsedPropertyList"
            @onClickMarker="highlightProject"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
// import AddressMapComponent from "@/core/components/AddressMapComponent.vue";
import Loader from "@/core/components/common/Loader.vue";
import ProjectCard from "@/modules/project/components/ProjectCard.vue";
import { getProperties } from "@/core/services/properties.service";
import PropertyMapModal from "@/modules/properties/components/Modals/PropertyMapModal.vue";
import {
  ProjectJobStatusEnum,
  ProjectListStatusType,
  ProjectTypesEnum,
  PropertyCategoryEnum,
} from "@/core/enums/ProjectsEnum";
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { onBeforeMount } from "vue";
import projectService from "@/core/services/project.service";
import { PROJECT_DETAILS_ROUTE } from "../routes";
import { filter } from "lodash";
import { watch } from "vue";
import { DatePicker } from "v-calendar";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import moment from "moment";
import JobMap from "@/modules/jobs/components/JobMap.vue";
import { parseJSON } from "@/core/utils/common";
import { PROJECT } from "@/store/modules/project";
import ProjectHighlightListItem from "@/modules/jobs/components/job-details-page/ProjectHighlightListItem.vue";

export default {
  name: "ProjectListComponent",

  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const IsLoading = ref(false);
    const isLoadingMorePage = ref(false);
    const paginationData = ref({
      currentPage: 0,
      totalPages: 0,
      totalPropertiesCount: 0,
    });
    const propertiesList = ref([]) as any;
    const addresses: any = ref([]);
    const filterOptions = ref([
      {
        label: "Posted Date",
        value: "datePosted",
        icon: "mdi-calendar-range",
      },
    ]);
    const isMapView = ref(false);
    const searchProject = ref("");
    const selectedProjectStatus = ref(null);
    const selectedPropertyType = ref(null);
    const selectedJobType = ref(null);

    const isShowDatePicker = ref(false);

    const toggleShowText = ref(null) as any;

    const masks = {
      weekdays: "WWW",
    };

    const filter = ref({
      search: null,
      projectJobStatus: null,
      type: null,
      dataRange: null,
      propertyCategory: null,
      postcode: null,
      radius: null,
      page: 1,
      limit: 20,
    });

    const prevPage = ref(filter.value.page);
    const projectStatusItems = ref([
      {
        text: "All",
        value: "",
        icon: "trade-network-list",
      },
      {
        text: "Awaiting estimates",
        value: ProjectJobStatusEnum.AWAITING_QUOTES,
        icon: "awaiting-estimates-icon",
      },
      {
        text: "Estimates received",
        value: ProjectJobStatusEnum.QUOTES_RECEIVED,
        icon: "estimates-receive-icon",
      },
      {
        text: "In progress",
        value: ProjectJobStatusEnum.IN_PROGRESS,
        icon: "in-progress-icon",
      },
      {
        text: "Cancelled",
        value: ProjectJobStatusEnum.CANCELLED,
        icon: "cancelled-icon",
      },
    ]);

    const propertyTypeItems = computed(() => {
      return [
        {
          text: "Commercial",
          value: PropertyCategoryEnum.COMMERCIAL,
        },
        { text: "Residential", value: PropertyCategoryEnum.RESIDENTIAL },
      ];
    });

    const jobTypes = ref([
      {
        text: "Emergency",
        value: ProjectTypesEnum.EMERGENCY,
      },
      {
        text: "Routine",
        value: ProjectTypesEnum.ROUTINE,
      },
      {
        text: "Standard",
        value: ProjectTypesEnum.STANDARD,
      },
      {
        text: "Video Consultation",
        value: ProjectTypesEnum.VIDEO_CONSULTATION,
      },
    ]);

    const projectListing = computed(
      () => store.getters[`${PROJECT}/getAllProjects`]
    );

    const parsedPropertyList = computed(() => {
      let list = [] as any;
      if (propertiesList.value.length && propertiesList.value) {
        list = propertiesList.value.map((property: any) => {
          const addressData = parseJSON(property.addressData);
          return {
            ...addressData,
          };
        });

        return list;
      }
      return list;
    });

    const getPropertiesListing = async (page: number) => {
      try {
        const response = await getProperties({
          page: page,
          limit: 20,
          search: "",
        });

        if (response) {
          paginationData.value.currentPage = response.page;
          paginationData.value.totalPages = response.totalPages;
          paginationData.value.totalPropertiesCount = response.totalCount;
          IsLoading.value = false;
        }

        if (response?.data?.length) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error);

        IsLoading.value = false;
      }
    };
    const totalPages = computed(
      () => store.getters[`${PROJECT}/getTotalPages`]
    );
    const onScroll = (event: Event) => {
      const container = event.target as HTMLElement;
      const tolerance = 2;
      if (
        Math.abs(
          container.scrollHeight - container.scrollTop - container.clientHeight
        ) <= tolerance
      ) {
        if (filter.value.page + 1 <= totalPages.value) {
          isLoadingMorePage.value = true;
          setTimeout(() => {
            filter.value.page += 1;
          }, 1000);
        }
      }
    };
    const normalizeData = () => {
      const addressList: any = [];

      propertiesList?.value?.length &&
        propertiesList?.value.map((item: any) => {
          let latlang = null;
          let latitude = null;
          let longitude = null;
          if (item.addressData) {
            const address = parseJSON(item.addressData);
            latlang = [address?.lat, address?.lng];
            latitude = address?.lat;
            longitude = address?.lng;
            addressList.push({
              address: item.address,
              uprn: item.uprn,
              latlang,
              id: item.id,
              postcode: item.postcode,
              latitude,
              longitude,
              name: item.address,
              description: item.address,
            });
          }
        });
      addresses.value = addressList;
    };

    onBeforeMount(async () => {
      IsLoading.value = true;
      const data = await getPropertiesListing(1);
      propertiesList.value = data;
      await store.dispatch(`${PROJECT}/fetchAllProjects`);
      console.log("projectListing ", projectListing.value);
      normalizeData();
    });

    const isPaginationAvailable = computed(() => {
      const { currentPage, totalPages } = paginationData.value;
      return currentPage < totalPages && currentPage !== 0 && totalPages !== 0;
    });

    const isEmptyPropertiesList = computed(
      () => propertiesList.value.length === 0
    );

    const onLoadMore = async () => {
      const { currentPage, totalPages } = paginationData.value;

      if (isPaginationAvailable.value) {
        const data = await getPropertiesListing(currentPage + 1);
        propertiesList.value = [...propertiesList.value, ...data];
        normalizeData();
      }
    };

    const onClickAddProperty = () => {
      ctx.emit("tooglePropertyTabs", "addProperty");
    };

    const onClickPropertyView = async (value: any) => {
      await router.push({
        name: "propertyDetails",
        params: { postCode: value },
      });
    };

    const onClickProject = (value: string, projectType: string) => {
      router.push({ name: PROJECT_DETAILS_ROUTE, params: { refId: value } });
      // if (projectType == "video consultation") {
      //   const routerData = router.resolve({
      //     name: PROJECT_DETAILS_ROUTE,
      //     params: { refId: value },
      //   });
      //   window.open(routerData.href, "_blank");
      // }
    };

    const getProjectStatus = (type: string) => {
      const projectStatus = projectStatusItems.value.find(
        (item) => item.value === type
      ) as any;
      return projectStatus?.text;
    };

    const getPostedDate = (isoDate: any) => {
      const date = new Date(isoDate);

      // Extract the day, month, and year from the Date object
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const year = date.getUTCFullYear();

      // Format the date as dd/mm/yyyy
      return `${day}/${month}/${year}`;
    };

    const onClickFilterOption = () => {
      isShowDatePicker.value = true;
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      // Extracting date components
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    const getProjectCount = computed(
      () => store.getters[`${PROJECT}/getTotalProjects`]
    );
    watch(
      () => filter.value,
      async (newValue: any) => {
        if (newValue.page == prevPage.value && newValue.page != 1) {
          newValue.page = 1;
        }
        prevPage.value = newValue.page;
        let params = {} as any;
        if (newValue.dataRange) {
          params = {
            ...newValue,
            startDate: formatDate(newValue.dataRange?.start),
            endDate: formatDate(newValue.dataRange?.end),
          } as any;
        } else {
          params = { ...newValue };
        }
        delete params[`dataRange`];

        params = Object.fromEntries(
          Object.entries(params).filter((value: any) =>
            value[1] === 0 ? true : value[1]
          )
        );
        try {
          await store.dispatch(`${PROJECT}/fetchAllProjects`, params);
        } catch (err) {
          console.log(err);
          if (isLoadingMorePage.value) isLoadingMorePage.value = false;
        } finally {
          if (isLoadingMorePage.value) isLoadingMorePage.value = false;
        }
      },
      { deep: true }
    );

    const countLines = (description: string) => {
      let words = description?.trim().split(" ");
      let maxLength = window.innerWidth < 720 ? 10 : 18;

      if (words?.length > maxLength) {
        return true;
      }

      return false;
    };

    const onClickToggleShowText = (itemId: number) => {
      if (toggleShowText.value === itemId) {
        toggleShowText.value = null;
      } else {
        toggleShowText.value = itemId;
      }
    };

    const highlightProjectIds = ref<any>(new Set());
    const highlightProject = (latlng: any) => {
      const highlightProject: any = new Set();
      projectListing.value?.map((project: any) => {
        project?.projectProperties?.map((property: any) => {
          const addressData = parseJSON(property?.property?.addressData);
          if (
            addressData?.lat === latlng?.lat &&
            addressData?.lng === latlng?.lng
          ) {
            highlightProject.add(project?.id);
          }
        });
      });
      highlightProjectIds.value = highlightProject;
    };
    const onClickTileMapBtn = () => {
      isMapView.value = !isMapView.value;
      highlightProjectIds.value = new Set();
    };
    return {
      IsLoading,
      propertiesList,
      filterOptions,
      onLoadMore,
      isPaginationAvailable,
      paginationData,
      addresses,
      isMapView,
      isEmptyPropertiesList,
      onClickAddProperty,
      onClickPropertyView,
      searchProject,
      selectedProjectStatus,
      projectStatusItems,
      selectedPropertyType,
      propertyTypeItems,
      selectedJobType,
      projectListing,
      onClickProject,
      filter,
      onScroll,
      getProjectStatus,
      jobTypes,
      getPostedDate,
      onClickFilterOption,
      masks,
      isShowDatePicker,
      getProjectCount,
      ProjectJobStatusEnum,
      countLines,
      onClickToggleShowText,
      toggleShowText,
      parsedPropertyList,
      highlightProject,
      highlightProjectIds,
      onClickTileMapBtn,
      isLoadingMorePage,
    };
  },
  components: {
    Loader,
    // AddressMapComponent,
    DatePicker,
    ProjectCard,
    PropertyMapModal,
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/project/styles/projectListing.scss";
@import "@/modules/project/styles/projectDraft.scss";
.scroll-container {
  // height: calc(100dvh - 330px);
  // overflow-y: scroll;
  height: calc(100dvh - 141px);
  padding-bottom: 4rem;
  overflow-y: auto;

  @media screen and (max-width: 1279px) {
    height: calc(100dvh - 212px);
  }

  @media screen and (max-width: 768px) {
    height: calc(100dvh - 210px);
  }

  @media screen and (max-width: 568px) {
    height: calc(100dvh - 269px);
  }
  @media screen and (max-width: 540px) {
    height: calc(100dvh - 317px);
  }
}
</style>
