<template>
  <div class="insurances-list__item tw-gap-2" :class="className">
    <div v-if="isShowLogo">
      <slot name="listItemLogo"></slot>
    </div>
    <div class="insurances-list__item-name tw-flex tw-flex-col tw-gap-1">
      <h5 class="tw-w-full md:!tw-text-[14px]">
        <slot name="listItemCardTitle"></slot>
      </h5>
      <Text variant="span"><slot name="listItemCardLabel"></slot></Text>
      <span><slot name="listItemCardText"></slot></span>
    </div>
    <div
      class="insurances-list__item-action tw-flex tw-gap-1 tw-items-center rmd:tw-gap-3"
      v-if="isShowEditButton || isShowDeleteButton"
    >
      <v-icon
        v-if="!hideEdit"
        icon="mdi-pencil"
        color="#80829F"
        @click="onClickEdit"
        size="s-small"
      ></v-icon>
      <v-icon
        icon="mdi-delete"
        color="#80829F"
        @click="onClickDelete"
        size="s-small"
      ></v-icon>
    </div>
  </div>
</template>

<script setup>
import Text from "./ui/general/Text.vue";
</script>

<script>
export default {
  props: {
    isShowEditButton: { type: Boolean, default: false },
    isShowDeleteButton: { type: Boolean, default: true },
    onClickEdit: {
      type: Function,
    },
    onClickDelete: {
      type: Function,
    },
    isShowLogo: {
      type: Boolean,
      default: false,
    },
    className: {
      type: Array,
    },
    hideEdit: Boolean,
  },
};
</script>
