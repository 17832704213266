<template>
  <GeneralDialog
    width="570px"
    class="raise_question_create_workstation_modal"
    @on-close="onCloseModal"
    headerTitle="Create Workstation"
    headerDescription="Let’s now create your workstation where you can access your properties, project and more. What would you like it to be called?."
  >
    <template #body>
      <div class="selector">
        <v-form @submit.prevent :rules="validation" ref="WorkstationFormRef">
          <div class="input__form">
            <label>Workstation Name</label>
            <v-text-field
              density="compact"
              :rules="validation.workstationName"
              placeholder="workstation name"
              v-model="workstationName"
              variant="outlined"
              class="input_field"
            ></v-text-field>
          </div>
        </v-form>
      </div>
    </template>
    <template #footer>
      <div class="modal_footer">
        <v-btn class="button button-purple-border" @click="onCloseModal"
          >Cancel</v-btn
        >
        <v-btn
          class="button button-purple"
          :disabled="!workstationName"
          @click="onConfirm"
          >Create Workstation</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { ref } from "vue";
const emits = defineEmits(["on-close", "onNext"]);
const WorkstationFormRef = ref(null);

const workstationName = ref(null);

const validation = ref({
  workstationName: [(v) => !!v || "workstation name is required"],
});

const onConfirm = async () => {
  const isFormValid = await WorkstationFormRef.value.validate();
  if (!isFormValid.valid) return;
  emits("onNext", workstationName.value);
};
const onCloseModal = () => {
  emits("on-close");
};
</script>
<style lang="scss" scoped>
.raise_question_create_workstation_modal {
  .v-overlay__content {
    margin: 0 !important;
    .general-dialog {
      .selector {
        .v-form {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .input__form {
            label {
              @include fluidFont(12, 12, 2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              margin-bottom: 8px;
            }
            .input_field {
              margin: 0;
              :deep(.v-input__control) {
                .v-field {
                  border: 1px solid rgba($bordercolor, 1);
                  border-radius: 8px;
                  overflow: hidden;
                  .v-field__field {
                    .v-field__input {
                      background-color: rgba($backgroundcolor, 1);
                      border: 0;
                      border-radius: 0;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.4px;
                      min-height: 42px;
                      padding: 0 16px;
                      top: 0;
                    }
                  }
                  .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .modal_footer {
        padding-top: 24px;
        border-top: 1px dashed rgba($bordercolor, 1);
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: center;
        .button {
          text-transform: capitalize;
          @include fluidFont(16, 16, 20px);
          font-weight: 600;
        }
      }
    }
  }
}
</style>
