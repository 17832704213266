<template>
  <div class="workstation-switch tw-relative tw-z-[9999999999] !tw-top-[10px]">
    <div
      class="workstation-switch__body tw-pt-4 tw-box-border tw-min-w-[340px] tw-max-w-[340px] tw-bg-[#F1F6FB] tw-rounded-[18px] tw-max-h-[calc(100vh-10rem)] tw-h-auto tw-flex tw-flex-col tw-gap-4 md:!tw-max-h-[calc(100vh-13rem)]"
    >
      <div class="tw-w-full tw-flex tw-items-center tw-justify-end tw-pr-4">
        <v-icon
          @click="onClose"
          icon="mdi-close"
          color="#0C0F4A"
          class="!tw-text-[20px] !tw-font-bold tw-cursor-pointer"
        ></v-icon>
      </div>
      <div
        class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto tw-pb-4 tw-px-4"
      >
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <div
            class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center"
          >
            <Text variant="p">{{
              activeUserWorkstation?.name === "Default"
                ? activeUserWorkstation?.user?.firstName +
                  " " +
                  activeUserWorkstation?.user?.lastName
                : activeUserWorkstation?.name
            }}</Text>
            <Text
              variant="span"
              textWeight="500"
              textColor="rgba(12, 15, 74, 0.8)"
              lineHeight="18px"
              >{{ workstationType }}</Text
            >
          </div>
          <div
            class="tw-relative tw-flex tw-flex-col tw-items-center tw-justify-start tw-h-[117px] tw-w-full"
          >
            <div
              class="tw-min-w-[109px] tw-max-w-[109px] tw-w-[109px] tw-h-[109px] tw-max-h-[109px] tw-rounded-lg tw-overflow-hidden"
            >
              <template v-if="activeUserWorkstation?.name === 'Default'">
                <img
                  :src="userImage"
                  v-if="userImage"
                  class="tw-w-full tw-h-full tw-object-cover"
                  alt=""
                />
                <UserProfileLogo
                  :isRounded="false"
                  v-else
                  class="tw-rounded-lg tw-overflow-hidden tw-w-full tw-h-full tw-relative"
                  :userName="`${user?.firstName} ${user?.lastName}`"
                  :mobileFontSizeBig="true"
                  :desktopFontSizeBig="true"
                />
              </template>
              <template v-else>
                <img
                  v-if="userWorkStationImage"
                  :src="userWorkStationImage"
                  class="tw-w-full tw-h-full tw-object-cover"
                  alt=""
                />
                <div
                  v-else
                  class="tw-rounded-[8px] tw-overflow-hidden tw-w-full tw-h-full tw-relative"
                >
                  <UserProfileLogo
                    :isRounded="false"
                    :desktopFontSizeBig="true"
                    :userName="
                      activeUserWorkstation?.name === 'Default'
                        ? 'Control Center'
                        : activeUserWorkstation?.name
                    "
                    :mobileFontSizeBig="true"
                  />
                </div>
              </template>
            </div>
            <img
              @click="onSettingClick"
              class="tw-cursor-pointer tw-absolute tw-bottom-0 tw-min-w-[29px] tw-h-[29px]"
              src="@/assets/icons/pencil-circle-blue.svg"
            />
          </div>
          <Text class="!tw-text-[26px]">Hi, {{ user?.firstName }}</Text>
        </div>
        <CollapsibleWidgetSlot
          :title="isCollapsed ? 'Hide Workstations' : 'Show All Worstations'"
          :hasActions="false"
          :hideDivider="false"
          :isExpand="isCollapsed"
          @set-is-expand-prop="setIsCollapsedValue($event)"
        >
          <template #content>
            <section
              class="tw-w-full tw-flex tw-flex-col tw-justify-around tw-pb-4 tw-pt-0 tw-px-4 tw-box-border"
            >
              <div
                class="ws-menu-item tw-w-full tw-flex tw-items-center tw-gap-2 tw-py-4 tw-box-border tw-border-t-dashed-custom tw-cursor-pointer"
                :class="{
                  '!tw-hidden': workstation.id === activeUserWorkstation.id,
                }"
                v-for="workstation in modifiedUserAllWorkStation"
                :key="workstation.id"
                @click="onWorkStationClick(workstation.id)"
              >
                <div
                  v-if="workstation?.name === 'Default'"
                  class="tw-w-[40px] tw-h-[40px] tw-overflow-hidden"
                >
                  <div
                    v-if="userImage"
                    class="tw-w-full tw-max-w-full tw-rounded-lg tw-overflow-hidden tw-relative"
                  >
                    <ProfileThumbnail
                      :isRounded="true"
                      :src="userImage"
                      size="mmmd"
                      mobilSize="mmmd"
                    />
                  </div>
                  <UserProfileLogo
                    v-else
                    :isRounded="false"
                    class="tw-rounded-lg tw-overflow-hidden tw-max-h-[40px] tw-max-w-[40px] tw-min-h-[40px] tw-min-w-[40px] tw-h-[40px] tw-relative"
                    :userName="`${user?.firstName} ${user?.lastName}`"
                    :desktopFontSizeMid="true"
                    :mobileFontSizeBig="true"
                  />
                </div>
                <div
                  v-else
                  class="tw-rounded-lg tw-overflow-hidden tw-max-h-[40px] tw-max-w-[40px] tw-min-h-[40px] tw-min-w-[40px] tw-h-[40px] tw-relative"
                >
                  <ProfileThumbnail
                    :isRounded="true"
                    v-if="workstation.attachmentSource"
                    :src="workstation.attachmentSource"
                    size="mmmd"
                    mobilSize="mmmd"
                  />
                  <div
                    v-else
                    class="tw-rounded-lg tw-overflow-hidden tw-max-h-[40px] tw-max-w-[40px] tw-min-h-[40px] tw-min-w-[40px] tw-h-[40px] tw-relative"
                  >
                    <WorkStationProfileLogo
                      class="workstation-profile-logo"
                      :WSName="workstation?.name"
                    />
                  </div>
                </div>
                <div class="tw-flex tw-flex-col tw-items-start">
                  <Text variant="p" lineHeight="18px">{{
                    workstation
                      ? workstation?.name === "Default"
                        ? workstation?.user?.firstName +
                          " " +
                          workstation?.user?.lastName
                        : workstation?.name
                      : ""
                  }}</Text>
                  <Text
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.8)"
                    lineHeight="18px"
                    >{{
                      workstation
                        ? workstation?.user?.userBusinessDetails?.type ==
                          BusinessDetailTypeEnum.LIMITED_COMPANY
                          ? "LTD Company Workstation"
                          : workstation?.user?.userBusinessDetails?.type ==
                            BusinessDetailTypeEnum.PROPERTY_OWNER
                          ? "Property Owner Workstation"
                          : workstation?.name === "Default"
                          ? "Control Center"
                          : "Sole Trader Workstation"
                        : ""
                    }}</Text
                  >
                </div>
              </div>
            </section>
          </template>
        </CollapsibleWidgetSlot>

        <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
          <Text
            variant="h6"
            textColor="#FFA500"
            class="!tw-underline !tw-cursor-pointer"
            @click="redirectToCreateWorkstation"
            >Create New Workstation</Text
          >
        </div>

        <Button
          @click="onClickSwitchUserType"
          variant="accent"
          :label="
            user?.role?.name === UserRolesEnum.TRADESPERSON
              ? 'Switch To Property Side'
              : 'Switch To Trade side'
          "
        >
          <template #prefix>
            <img
              v-if="user?.role?.name === UserRolesEnum.TRADESPERSON"
              class="switch-role-icon"
              src="@/assets/icons/white-filled-home-icon.svg"
              width="32"
              height="32"
            /><img
              v-else
              class="switch-role-icon"
              src="@/assets/icons/avatar-tp-icon.svg"
              width="32"
              height="32"
            />
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import Text from "./ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "./UserProfileLogo.vue";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
import {
  UserRolesEnum,
  BusinessDetailTypeEnum,
  RolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import Button from "./ui/general/Button.vue";
import { USER_STORE } from "@/store/modules/user";
import workstation, { WORKSTATION } from "@/store/modules/workstation";

const emits = defineEmits([
  "on-close",
  "on-create-ws",
  "on-switch-role",
  "on-ws-click",
  "on-settings-click",
]);
const props = defineProps([
  "isShowTradePersonWorkstationList",
  "modifiedUserAllWorkStation",
  "isShowProjectOwnerWorkstationList",
]);

const store = useStore();

const isCollapsed = ref(true);

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const userWorkStationImage = computed(
  () => store.getters[`${USER_STORE}/userWorkStationImage`]
);
const workstationType = computed(() => {
  console.log(activeUserWorkstation.value, "activeUserWorkstation.value");
  return activeUserWorkstation.value?.name === "Default"
    ? "Control Center"
    : activeUserWorkstation.value.user?.userBusinessDetails?.type ===
      BusinessDetailTypeEnum.SOLETRADER
    ? "Sole Trader Workstation"
    : activeUserWorkstation.value.user?.userBusinessDetails?.type ===
      BusinessDetailTypeEnum.LIMITED_COMPANY
    ? "LTD Company Workstation"
    : activeUserWorkstation.value.user?.userBusinessDetails?.type ===
      BusinessDetailTypeEnum.PROPERTY_OWNER
    ? "Property Owner Workstation"
    : "Control Center";
});

const setIsCollapsedValue = (event) => {
  isCollapsed.value = event;
};

const redirectToCreateWorkstation = () => {
  emits("on-create-ws");
};

const onClickSwitchUserType = () => {
  emits("on-switch-role");
};

const onClose = () => {
  emits("on-close");
};

const onWorkStationClick = (id) => {
  emits("on-ws-click", id);
};

const onSettingClick = () => {
  if (activeUserWorkstation.value?.name === "Default") {
    emits("on-settings-click", 1);
    return;
  }
  emits("on-settings-click", 2);
  return;
};
</script>
<style lang="scss" scoped>
.ws-menu-item {
  &:hover {
    background-color: rgb(210, 240, 252);
  }
}
.workstation-profile-logo {
  width: 100%;
  height: 100%;
  background-color: #4a92ef;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
