<template>
  <GeneralDialog
    :headerTitle="!isConfirmLoadTemplate ? modalHeader.title : ''"
    :headerDescription="!isConfirmLoadTemplate ? modalHeader.description : ''"
    @on-close="onCloseModal"
    style="width: 420px; max-width: 100%"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-2"
        v-if="!isConfirmLoadTemplate"
      >
        <v-form
          ref="form"
          class="tw-w-full"
          :rules="validation"
          @submit.prevent
        >
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <Text variant="span" textWeight="400">Select Template</Text>

            <v-autocomplete
              variant="outlined"
              v-model="selectedTemplate"
              :items="jobTemplates"
              item-title="name"
              item-value="meta"
              placeholder="Type or select by clicking the arrow"
              :rules="validation.template"
              required
              class="estimate_select_field"
              :menu-props="{ contentClass: 'month__selector' }"
            ></v-autocomplete>
          </div>
        </v-form>
      </div>

      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        v-else
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-w-full tw-max-w-full"
        >
          <v-icon
            icon="mdi-notebook-edit-outline"
            color="#FAA500"
            size="large"
            class="notebook__icon"
          >
          </v-icon>
          <!-- <v-icon><img src="@/assets/icons/notepad.svg" alt="" /></v-icon> -->
          <Text variant="h4" textAlign="center" lineHeight="28px"
            >Are you sure you want to load this template ?</Text
          >
        </div>
      </div>
    </template>

    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="secondary"
          label="Cancel"
          :isActive="true"
          @click="onCloseModal"
        />

        <Button
          variant="primary"
          label="Use"
          @click="onUse"
          v-if="!isConfirmLoadTemplate"
        />
        <Button
          v-if="isConfirmLoadTemplate"
          label="confirm"
          :isActive="true"
          @click="onConfirm"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { ref } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { useStore } from "vuex";
import { computed } from "vue";
import workstation, { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import { parseJSON } from "@/core/utils/common";
import moment from "moment";

const emits = defineEmits(["on-close"]);
const store = useStore();
const modalHeader = {
  title: "Save this estimate as a template",
  description: "Enter a name to use this saved template in the future",
};
const formValid = ref(false);
const form = ref(null);
const selectedTemplate = ref(null);
const isConfirmLoadTemplate = ref(false);

const onCloseModal = () => {
  emits("on-close");
};
const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];
  // if (
  //   estimates &&
  //   estimates.projectStages.length &&
  //   !estimates.projectStages[0].user
  // ) {
  //   estimates.projectStages[0].user = user.value;
  // }
  // if (
  //   estimates &&
  //   estimates.projectStages.length &&
  //   !estimates.projectStages[0].workStation
  // ) {
  //   estimates.projectStages[0].workStation = activeUserWorkstation.value;
  // }
  return estimates;
});
const jobTemplates = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getJobTemplates`]
);
const validation = ref({
  template: [(v) => !!v || "please select template "],
});
const estimateFormData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const isSubContractor = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsSubContractor`]
);
const onConfirm = () => {
  const currentDate = moment().format("YYYY-MM-DD");
  const metadata = parseJSON(selectedTemplate.value);

  let templateEstimate = JSON.parse(JSON.stringify(metadata));

  const templatePhases = templateEstimate?.projectStages.map((phase) => {
    const phaseDate = moment(phase.startDate);
    return {
      ...phase,
      user: { id: user.value?.id },
      phaseStartDate:
        phase.stageType === "labour"
          ? phaseDate.isBefore(currentDate, "day")
            ? currentDate
            : phaseDate
          : null,
      workStation: { id: activeUserWorkstation.value?.id },
    };
  });

  const estimatePhases = JSON.parse(
    JSON.stringify(estimateForm.value.projectStages)
  );
  templateEstimate.projectStages = [...estimatePhases, ...templatePhases];
  const updatePhases = JSON.parse(
    JSON.stringify(templateEstimate.projectStages)
  );
  try {
    store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimateForm`, {
      projectStages: updatePhases,
    });

    const estimateStartData = moment(
      templateEstimate?.formData.suggestedStartDate
    );
    const formData = {
      ...templateEstimate?.formData,
      id: estimateFormData.value?.id,
      suggestedStartDate: estimateStartData?.isBefore(currentDate, "day")
        ? currentDate
        : templateEstimate?.formData.suggestedStartDate,
    };

    if (formData) {
      store.dispatch(`${JOB_TEMPLATE_STORE}/setFormData`, formData);
    }
  } catch (error) {
    console.log();
  } finally {
    selectedTemplate.value = null;
    isConfirmLoadTemplate.value = false;
    emits("on-close", true);
  }
};
const onUse = async () => {
  const isFormValid = await form.value.validate();
  if (isFormValid.valid) isConfirmLoadTemplate.value = true;
};
</script>
<script>
export default {
  name: "LoadTemplateModal",
};
</script>
<style lang="scss" scoped>
.estimate_select_field {
  width: 100%;
  max-width: 100%;
  :deep(.v-input__control) {
    .v-field {
      padding: 0;
      border: 1px solid rgba($blueDark, 0.4);
      background-color: transparent;
      border-radius: 4px;
      .v-field__field {
        .v-field__input {
          min-height: 40px;
        }
      }
    }
  }
}
.notebook__icon {
  font-size: 50px;
}
</style>
