<template>
  <GeneralDialog
    width="570px"
    class="raise_question_verify_email_modal"
    @on-close="onCloseModal"
    headerTitle="Verifiy your email"
  >
    <template #body>
      <div class="general_dialog__content__title">
        <h5>Time to check your email for the code we have just sent to you.</h5>
        <p>
          iknowa has sent a code to <span>{{ user?.email }}</span>
        </p>

        <v-btn
          @click="resendEmail"
          class="button"
          variant="plain"
          append-icon="mdi-chevron-right"
        >
          Resend email
        </v-btn>

        <div class="otp__input">
          <v-otp-input
            v-model="otpValue"
            length="4"
            variant="solo-filled"
            class="input_field"
          ></v-otp-input>
          <p class="error_msg" v-if="otpErrorMessage">
            {{ otpErrorMessage }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal_footer">
        <v-btn
          class="button button-purple-border"
          :disabled="isLoading"
          @click="onCloseModal"
          >Cancel</v-btn
        >
        <v-btn
          class="button button-purple"
          :disabled="otpValue?.length !== 4"
          :loading="isLoading"
          @click="verifyEmailWithOTP"
          >verify email & continue</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { displayToastMessage } from "@/core/utils/common";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { USER_STORE } from "@/store/modules/user";
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

const otpValue = ref();
const emits = defineEmits(["onNext", "on-close"]);
const store = useStore();
const isLoading = ref(false);
const otpErrorMessage = ref();
const internalInstance = getCurrentInstance();
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const resendEmail = async () => {
  try {
    await store.dispatch(
      `${AUTHENTICATION_STORE}/resendEmailOTP`,
      user.value.email
    );
    displayToastMessage(
      internalInstance,
      "Resend Mail Successfully !",
      "success"
    );
  } catch (error) {
    console.log("error", error);
  }
};

const verifyEmailWithOTP = async () => {
  try {
    isLoading.value = true;
    const data = {
      email: user.value.email,
      code: Object.values(otpValue.value).join().replaceAll(",", ""),
    };
    await store.dispatch(`${AUTHENTICATION_STORE}/verifyCode`, data);
    nextStep();
  } catch (error) {
    otpErrorMessage.value = "Invalid Code";
    console.log("error", error);
  } finally {
    isLoading.value = false;
  }
};
const nextStep = () => {
  emits("onNext");
};
const onCloseModal = () => {
  if (isLoading.value) return;
  emits("on-close");
};
</script>

<style scoped lang="scss">
.raise_question_verify_email_modal {
  .v-overlay__content {
    margin: 0 !important;
    .general-dialog {
      .general_dialog__content {
        .general_dialog__content__title {
          h5 {
            @include fluidFont(24, 24, 30px);
            font-weight: 600;
            color: rgba($blueDark, 1);
          }
          p {
            @include fluidFont(14, 14, 18px);
            font-weight: 500;
            color: rgba($blueDark, 0.5);
            letter-spacing: 0.15px;
            margin: 16px 0;
            span {
              color: rgba($blueDark, 1);
            }
          }
          .v-btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-transform: capitalize;
            color: rgba(51, 102, 255, 1);
            @include fluidFont(16, 16, 20px);
            font-weight: 600;
          }
          .otp__input {
            margin-top: 16px;
            :deep(.v-otp-input) {
              padding: 0;
              justify-content: flex-start;

              .v-otp-input__content {
                height: 56px;
                gap: 16px;
                padding: 0;
                max-width: 272px;
              }

              .v-field {
                height: 100%;
                box-shadow: none;
                border: 1px solid rgba($bordercolor, 1);
                border-radius: 8px;
                font-weight: 500;
                overflow: hidden;
                .v-field__field {
                  .v-otp-input__field {
                    background-color: rgba($backgroundcolor, 1);
                  }
                }

                .v-field__overlay {
                  display: none;
                }
              }
            }
          }
        }
      }
      .modal_footer {
        padding-top: 24px;
        border-top: 1px dashed rgba($bordercolor, 1);
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: center;
        .button {
          text-transform: capitalize;
          @include fluidFont(16, 16, 20px);
          font-weight: 600;
        }
      }
    }
  }
}

.error_msg {
  @include fluidFont(14, 14, 1.2);
  font-weight: $fontMedium;
  letter-spacing: 0.4px;
  color: rgba(244, 84, 84, 1);
  margin: 18px 0;
}
</style>
