<template>
  <v-btn
    v-if="screenType !== NEW_HISTORIC_PROJECT_SCREEN"
    class="back__btn"
    variant="plain"
    prepend-icon="mdi-chevron-left"
    @click="backToPropertyPage"
    >Back</v-btn
  >
  <main
    v-if="filterPropertyProject?.length"
    class="property_logs tw-w-full tw-flex tw-flex-col tw-gap-4"
  >
    <section
      class="tw-w-full tw-flex tw-flex-col tw-gap-4"
      v-if="screenType === PROJECT_LIST_SCREEN"
    >
      <div
        class="right_column tw-w-full tw-flex tw-items-center tw-justify-between md:tw-flex-col md:tw-items-start md:tw-gap-4 md:tw-mt-4 sm:tw-flex-col-reverse"
      >
        <h5
          class="tw-text-[#0C0F4A] tw-text-lg tw-font-bold tw-tracking-[0.15px] tw-text-6 md:tw-text-sm md:tw-tracking-[0.25px]"
        >
          Projects Logs
        </h5>
        <!-- <Text variant="h5">Projects Log</Text> -->
        <div
          class="tw-flex tw-items-center tw-gap-3 sm:tw-flex-col sm:tw-w-full sm:tw-max-w-full"
        >
          <Button
            variant="secondary"
            class="!tw-w-auto project_button sm:!tw-max-w-full sm:!tw-w-full"
            label="Project Logs"
            @click="openSideNav"
          >
            <template #prefix>
              <InprogressBlueIcon />
            </template>
          </Button>
          <v-menu>
            <template v-slot:activator="{ props }">
              <Button
                v-if="isUserHasEditRight"
                class="!tw-w-auto sm:!tw-max-w-full sm:!tw-w-full"
                label="New Project"
                v-bind="props"
              >
                <template #prefix>
                  <v-icon icon="mdi-plus-circle"></v-icon>
                </template>
              </Button>
            </template>
            <AddProjectMenu @on-handle-action="onhandleActionMenu" />
          </v-menu>
        </div>
      </div>

      <div class="filter tw-grid tw-gap-4">
        <FilterCard
          v-for="(item, index) in ProjectLogStatus"
          :key="index"
          :data="item"
          :isActive="item.key === selectedFilter.key"
          @click="selectFilter(item)"
        />
      </div>

      <!-- PROJECT LIST SCREEN -->
      <div
        v-if="screenType === PROJECT_LIST_SCREEN && !loading"
        class="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-w-full tw-h-full tw-mt-4"
      >
        <ProjectLogFilters />
        <div
          class="tw-flex tw-flex-col tw-gap-4 tw-w-full tw-h-full tw-items-start tw-box-border"
        >
          <Text textColor="rgba(12, 15, 74, 0.5)" variant="span">
            {{ filterPropertyProject?.length }}

            <Text
              textWeight="400"
              textColor="rgba(12, 15, 74, 0.5)"
              variant="span"
              >Projects</Text
            ></Text
          >
          <!-- <div class="project-container tw-grid tw-gap-4 tw-w-full tw-h-full"> -->
          <div class="project-container">
            <template v-if="filterPropertyProject?.length">
              <ProjectCard
                v-for="project in filterPropertyProject"
                :key="project.id"
                :project="project"
                :propertyDetails="propertyDetails?.localData"
              />
            </template>
          </div>
        </div>
      </div>
    </section>
  </main>
  <main
    v-if="
      !filterPropertyProject?.length &&
      screenType !== NEW_HISTORIC_PROJECT_SCREEN
    "
    class="property_logs tw-w-full tw-flex tw-flex-col tw-gap-4 tw-h-[70dvh]"
  >
    <section
      class="tw-w-full tw-flex tw-flex-col tw-gap-8 tw-h-full md:!tw-mt-0 md:!tw-justify-center"
    >
      <div class="empty__content md:!tw-h-auto">
        <div
          class="empty__banner md:!tw-flex md:!tw-flex-col md:!tw-items-center md:!tw-justify-center md:!tw-gap-[1.5rem]"
        >
          <div class="empty__banner__img md:!tw-w-[70%]">
            <img
              src="../../../../assets/images/empty-screen.svg"
              alt=""
              class="md:!tw-w-full"
            />
          </div>
          <div class="empty__banner__content md:!tw-mt-0">
            <h4>You currently don’t have any logged projects.</h4>
            <p>Start a new or Record historic project at lightning speed.</p>
          </div>
          <div
            class="empty__banner__footer tw-flex tw-flex-col tw-gap-4 md:!tw-mt-0 tw-w-full"
          >
            <v-btn
              class="button button-purple"
              width="100%"
              @click="onhandleActionMenu('new-project')"
              >Start a new project</v-btn
            >
            <v-btn
              class="button button-purple-border"
              width="100%"
              @click="onhandleActionMenu('new-historical-project')"
              :disabled="!isUserHasEditRight"
              >Record historic project</v-btn
            >
          </div>
        </div>
      </div>
    </section>
  </main>
  <!-- PROJECT ADD SCREEN  -->
  <AddHistoricalProject
    v-if="screenType === NEW_HISTORIC_PROJECT_SCREEN && !loading"
    @on-back="toggleScreen(PROJECT_LIST_SCREEN)"
    @onHistoricProjectSaved="initialize"
  />

  <!-- PROJECT LOGS SIDE NAV -->
  <ProjectLogSidenav
    :isOpen="isOpenProjectLogSidenav"
    @on-close="closeSideNav"
  />
</template>
<script setup>
import { computed, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InprogressBlueIcon from "@/core/components/icons/InProgressBlueIcon.vue";
import FilterCard from "@/modules/properties/components/Cards/FilterCard.vue";
import ProjectLogFilters from "@/modules/properties/components/ProjectLogFilters.vue";
import ProjectCard from "@/modules/properties/components/Cards/ProjectCard.vue";
import ProjectLogSidenav from "@/modules/properties/components/ProjectLogSidenav.vue";
import AddProjectMenu from "@/modules/properties/components/AddProjectMenu.vue";
import AddHistoricalProject from "@/modules/properties/components/AddHistoricalProject.vue";
import {
  ALL,
  DRAFT_PROGRESS,
  IN_PROGRESS,
  COMPLETED,
  NOT_STARTED,
  NEW_HISTORIC_PROJECT,
  NEW_PROJECT,
  NEW_HISTORIC_PROJECT_SCREEN,
  PROJECT_LIST_SCREEN,
} from "../../constants";
import { PROJECT_ROUTE } from "@/modules/project/routes";
import { PROPERTY_STORE } from "@/store/modules/property";
import { CONNECTIONS } from "@/store/modules/connections";
import { PropertyConnectionRoleEnum } from "@/core/enums/PropertiesEnum";
import {
  HistoricProjectStatusEnum,
  ProjectJobStatusEnum,
} from "@/core/enums/ProjectsEnum";
import { PROJECT_STORE } from "@/store/modules/project";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { parseInt } from "lodash";

const store = useStore();
const route = useRoute();
const router = useRouter();

const screenType = ref(PROJECT_LIST_SCREEN);
const isOpenProjectLogSidenav = ref(false);
const ProjectLogStatus = ref([
  {
    key: ALL,
    label: "All Projects",
    statusId: ["all"],
    count: 0,
  },
  {
    key: IN_PROGRESS,
    label: "In Progress",
    statusId: [
      ProjectJobStatusEnum.IN_PROGRESS,
      ProjectJobStatusEnum.QUOTE_ACCEPTED,
    ],
    count: 0,
  },
  {
    key: DRAFT_PROGRESS,
    label: "Draft Projects",
    statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
    count: 0,
  },
  {
    key: NOT_STARTED,
    label: "Not Started",
    statusId: [
      ProjectJobStatusEnum.AWAITING_QUOTES,
      ProjectJobStatusEnum.QUOTES_RECEIVED,
      ProjectJobStatusEnum.CANCELLED,
    ],
    count: 0,
  },
  {
    key: COMPLETED,
    label: "Completed",
    statusId: [ProjectJobStatusEnum.COMPLETED],
    count: 0,
  },
]);
const modifiedProjectLogStatus = () => {
  ProjectLogStatus.value.forEach((projectType) => {
    projectType.count = projectType.statusId.includes("all")
      ? propertyProjects?.value?.length
      : propertyProjects?.value?.filter((project) =>
          projectType.statusId.includes(project?.projectJobStatus?.id)
        )?.length || 0;
  });
};
const selectedFilter = ref(ProjectLogStatus.value[0]);

const loading = ref(false);

const propertyProjects = computed(() => {
  const projectList = store.getters[`${PROPERTY_STORE}/propertyProjects`];

  return (
    projectList?.filter(
      (project) =>
        project.projectHistoricalStatus !== HistoricProjectStatusEnum.REJECTED
    ) || []
  );
});
const filterPropertyProject = computed(() =>
  selectedFilter.value.statusId.includes("all")
    ? propertyProjects.value
    : propertyProjects.value.filter((project) =>
        selectedFilter.value.statusId.includes(project?.projectJobStatus?.id)
      )
);
const propertyId = computed(() => route?.params?.propertyId);

const selectFilter = (item) => {
  selectedFilter.value = item;
};

const toggleScreen = (screen) => {
  screenType.value = screen;
};

const onhandleActionMenu = (e) => {
  if (e === NEW_PROJECT) {
    if (propertyId.value)
      store.commit(
        `${PROPERTY_STORE}/setNewPropertyProjectData`,
        parseInt(propertyId.value)
      );
    router.push({
      name: PROJECT_ROUTE,
    });
  }
  if (e === NEW_HISTORIC_PROJECT) {
    toggleScreen(NEW_HISTORIC_PROJECT_SCREEN);
  }
};

const openSideNav = () => {
  isOpenProjectLogSidenav.value = true;
};

const closeSideNav = () => {
  isOpenProjectLogSidenav.value = false;
};

const initialize = async () => {
  loading.value = true;
  const params = {
    status: -1,
  };
  const payload = {
    propertyId: propertyId.value,
    params,
  };
  try {
    await store.dispatch(`${PROPERTY_STORE}/fetchPropertyProjects`, payload);
  } catch (error) {
    loading.value = false;
  } finally {
    loading.value = false;
  }
};
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const currentPropertyConnectionRole = computed(
  () => store.getters[`${CONNECTIONS}/getPropertyConnectionRole`]
);
const isUserHasEditRight = computed(
  () =>
    currentPropertyConnectionRole.value !== PropertyConnectionRoleEnum.VIEWER
);
const backToPropertyPage = () => {
  router.push("/properties");
};
onBeforeMount(async () => {
  await initialize();
  modifiedProjectLogStatus();
});
</script>
<style lang="scss" scoped>
@import "@/modules/properties/styles/PropertyProjectLogTab.scss";
.filter {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  @include respond(sm) {
    grid-template-columns: repeat(auto-fit, minmax(164px, 1fr));
  }
}
// .project-container {
//   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
// }
.project-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-start;
}
/*Empty Screen */
.empty__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty__banner {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    @include respond(s720) {
      width: 100%;
    }
    .empty__banner__img {
      img {
        @include respond(s720) {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .empty__banner__content {
      margin-top: 10px;
      h4 {
        @include fluidFont(28, 28, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 1);
      }
      p {
        margin-top: 16px;
        @include fluidFont(14, 14, 1.3);
        font-weight: 500;
        color: rgba($blueDark, 0.5);
        letter-spacing: 0.15px;
      }
      .empty__banner__content__list {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 0 27px;
        .list__item {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          .v-icon {
            @include fluidFont(24, 24, 1);
            color: rgba($skyBlue, 1);
          }
          p {
            @include fluidFont(14, 14, 1.3);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
    .empty__banner__footer {
      margin-top: 36px;
    }
  }
}
.back__btn {
  height: auto;
  display: flex;
  padding: 0;
  opacity: 1;
  :deep(.v-btn__content) {
    color: rgba($blueDark, 1);
    text-transform: capitalize;
  }
  :deep(.v-btn__prepend) {
    margin: 0;
  }
}
</style>
