<template>
  <section
    class="tw-w-full tw-h-full tw-flex md:tw-flex-col tw-gap-4 tw-box-borders"
  >
    <div
      class="tw-w-[440px] tw-h-auto tw-flex tw-flex-col tw-gap-4 tw-px-0 rmd:tw-px-2 tw-box-border tw-overflow-y-auto md:tw-w-full tw-max-w-full"
    >
      <PropertyCard
        @on-map-full-screen="onFullScreenRequest"
        :hasOpenMapButtonInMobile="true"
      />
      <!-- <CollapsiblePropertySummary /> -->
      <CollapsiblePropertyProjects
        v-if="!isSiteVisitPropertyDetail"
        @route-to-project-logs="redirectToProjectLogs"
      />
      <!-- <CollapsiblePropertyProjectsDepracated
        v-if="!isSiteVisitPropertyDetail"
      /> -->
      <CollapsiblePropertyRecommendation />
      <!-- <CollapsiblePropertyRecommendationDepracated /> -->
      <CollapsiblePropertyProjectNotifications @on-open-logs="openSideNav" />
      <CollapsiblePropertySafetyChecks v-if="!isSiteVisitPropertyDetail" />
      <!-- <CollapsiblePropertyConnection /> -->
      <!--<CollapsiblePropertyOccupiers /> -->

      <div
        v-if="!isUserHapHeatPumpServicePlan && !isSiteVisitPropertyDetail"
        class="service-plan-card"
      >
        <h2>Does Your Property have a heat pump?</h2>
        <p>Get your annual service, from £30 per month.</p>
        <div class="service-plan-card__footer">
          <v-btn
            class="bright-blue-text"
            variant="text"
            append-icon="mdi-chevron-right"
            @click="onToggleAServicePlanModal"
          >
            Get Your Service Plan
          </v-btn>
        </div>
      </div>
    </div>
    <div
      class="tw-h-[670px] rlg:!tw-h-[calc(100vh-17rem)] tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-overflow-hidden tw-relative tw-w-[calc(100%-460px)] lg:tw-w-full"
      :class="{ 'md:!tw-hidden': !isMobileMapOpen }"
    >
      <PropertyGoogleMap
        v-if="addressData"
        @close-full-screen-mobile-map="onCloseMobileFullScreenMap"
        :addressData="addressData"
        :propertyId="propertyDetails?.localData?.id"
        :isMobileMapOpen="isMobileMapOpen"
        ref="googleMap"
      />
      <PropertyHeatServicePlanModal
        v-if="isShowServicePlanModal"
        @on-close="onToggleAServicePlanModal"
      />
    </div>
  </section>

  <!-- PROJECT LOGS SIDE NAV -->
  <ProjectLogSidenav
    :isOpen="isOpenProjectLogSidenav"
    @on-close="closeSideNav"
  />
</template>
<script setup lang="ts">
import { LatLngExpression } from "leaflet";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import PropertyHeatServicePlanModal from "@/modules/properties/components/Modals/PropertyHeatServicePlanModal.vue";
import PropertyCard from "@/modules/properties/components/Cards/PropertyCard.vue";
import CollapsiblePropertySummary from "../Collapsibles/CollapsiblePropertySummary.vue";
import CollapsiblePropertyProjects from "../Collapsibles/CollapsiblePropertyProjects.vue";
import CollapsiblePropertyRecommendation from "../Collapsibles/CollapsiblePropertyRecommendation.vue";
import CollapsiblePropertySafetyChecks from "../Collapsibles/CollapsiblePropertySafetyChecks.vue";
import CollapsiblePropertyConnection from "../Collapsibles/CollapsiblePropertyConnection.vue";
import CollapsiblePropertyOccupiers from "../Collapsibles/CollapsiblePropertyOccupiers.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import PropertyGoogleMap from "@/core/components/map/PropertyGoogleMap.vue";
import { parseJSON } from "@/core/utils/common";
import GoogleMap2 from "@/core/components/map/GoogleMap2.vue";
import { PropertySubscriptionTypeEnum } from "@/core/enums/ProjectsEnum";
import CollapsiblePropertyProjectNotifications from "../Collapsibles/CollapsiblePropertyProjectNotifications.vue";
import ProjectLogSidenav from "@/modules/properties/components/ProjectLogSidenav.vue";

// import CollapsiblePropertyProjectsDepracated from "../Collapsibles/CollapsiblePropertyProjects_depracated.vue";
import CollapsiblePropertyRecommendationDepracated from "../Collapsibles/CollapsiblePropertyRecommendation_depracated.vue";

const store = useStore();
const emits = defineEmits(["route-to-project-logs"]);
const isOpenProjectLogSidenav = ref(false);
const isMobileMapOpen = ref(false);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);
const isSiteVisitPropertyDetail = computed(
  () => store.getters[`${PROPERTY_STORE}/getIsSiteVisitPropertyDetail`]
);
const addressData = computed(() => {
  const propertyAddress = propertyDetails.value?.localData;
  return parseJSON(propertyAddress?.addressData);
});
const isShowServicePlanModal = ref(false);
const onToggleAServicePlanModal = () => {
  isShowServicePlanModal.value = !isShowServicePlanModal.value;
};

const isUserHapHeatPumpServicePlan = computed(
  () =>
    propertyDetails.value?.localData?.propertySubscriptions?.some(
      (plan: any) =>
        plan.type === PropertySubscriptionTypeEnum.HEAT_PUMP_SERVICE
    ) || null
);

const onFullScreenRequest = () => {
  isMobileMapOpen.value = true;
};

const onCloseMobileFullScreenMap = () => {
  isMobileMapOpen.value = false;
};

const openSideNav = () => {
  isOpenProjectLogSidenav.value = true;
};

const redirectToProjectLogs = () => {
  emits("route-to-project-logs");
};
const closeSideNav = () => {
  isOpenProjectLogSidenav.value = false;
};
</script>
<style lang="scss" scoped>
.service-plan-card {
  margin-top: auto;
  border-radius: 8px;
  background: linear-gradient(to right, #18142b, #514391);
  padding: 16px;
  color: $blueDark;
  @include fluidFont(12, 12, 15px);
  letter-spacing: 0.15px;

  h2 {
    @include fluidFont(16, 16, 20px);
    letter-spacing: 0.15px;
    margin-bottom: 8px;
    color: rgb($white, 1);
    text-align: left;
  }

  p {
    color: rgb($white, 1);
    text-align: left;
  }

  &__footer {
    margin-top: 20px;
    padding-top: 12px;
    border-top: 0.66px dashed rgba(134, 135, 165, 1);
    display: flex;
    justify-content: flex-end;
  }

  :deep(.v-btn) {
    padding: 0;
    height: auto;
    min-height: 1px;
    text-transform: none;
    color: rgb($orange, 1);
    font-weight: 600;

    .v-btn__overlay,
    .v-btn__underlay {
      display: none;
    }
  }
}
</style>
