<template>
  <WizardModal
    :hideFooter="true"
    :hideBackButton="true"
    :singleData="modelDetails"
    @on-close="onClickBackButton"
  >
    <template #content>
      <v-form :rules="validation" ref="vatTaxFormRef" class="">
        <div class="sole-trader-data-wrapper !tw-mt-4 rmd:!tw-mt-8">
          <div class="input-field">
            <label>VAT Number (Optional)</label>
            <v-text-field
              density="compact"
              placeholder="eg. GB123456789"
              :rules="validation.vatNumber"
              v-model="formData.vatNumber"
              variant="outlined"
              class="c-input rounded-full"
            ></v-text-field>
          </div>
          <!-- <div class="input-field">
            <label>National Insurance Number (Optional)</label>
            <v-text-field
              bg-color="rgba(12, 15, 74, 0.0509803922)"
              variant="solo"
              density="compact"
              rounded
              outlined="false"
              :rules="validation.ninNumber"
              v-model="formData.ninNumber"
              placeholder="eg. AA123456A"
              class="input_field password__input"
              :append-inner-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="() => (isShowPassword = !isShowPassword)"
              :type="isShowPassword ? 'text' : 'password'"
            ></v-text-field>
          </div> -->
          <v-btn
            class="button button-orange !tw-mt-6 rmd:!tw-mt-8"
            @click="onClickTaxVatSave"
            :loading="isLoading"
            >Save</v-btn
          >
        </div>
      </v-form>
    </template>
  </WizardModal>
</template>

<script lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import WizardModal from "@/core/components/modals/WizardModal.vue";

export default {
  components: { WizardModal },
  props: {
    mode: {
      type: String,
      default: "Add",
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const isShowPassword = ref(false);
    const isLoading = ref(false);
    const modelDetails = ref({
      id: 3,
      tab: "Tax & VAT details",
      name: "Tax&VATdetails",
      header: "Tax & VAT details",
      title: "Let’s confirm your Tax & VAT details",
      description:
        "In order to register yourself as a Sole Trader, please confirm your insurances.",
    });
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const formData = ref({
      vatNumber: "",
      ninNumber: "",
    });
    const vatTaxFormRef = ref(null) as any;
    const validation = ref({
      vatNumber: [(v: string) => !!v || "Provide a VAT Number"],
      ninNumber: [(v: string) => !!v || "Provide an NIN Number"],
    });

    onBeforeMount(() => {
      if (user.value?.userBusinessDetails?.nationalInsuranceNumber) {
        formData.value.ninNumber =
          user.value?.userBusinessDetails?.nationalInsuranceNumber;
      }
      if (user.value?.userBusinessDetails?.ukVatNumber) {
        formData.value.vatNumber = user.value?.userBusinessDetails?.ukVatNumber;
      }
    });

    const onClickBackButton = () => {
      return ctx.emit("onClickCloseTab");
    };

    const onClickTaxVatSave = async () => {
      try {
        const isFormValid = await vatTaxFormRef.value.validate();
        if (!isFormValid.valid) return;
        isLoading.value = true;
        const userBusinessDetails = user.value.userBusinessDetails;

        delete userBusinessDetails?.dateUpdated;
        delete userBusinessDetails?.dateCreated;
        delete userBusinessDetails?.ukVatNumber;
        delete userBusinessDetails?.nationalInsuranceNumber;

        const defaultPayload = {
          nationalInsuranceNumber: formData.value.ninNumber,
          ukVatNumber: formData.value.vatNumber,
        };

        const userBusinessDetailsDto = {
          ...defaultPayload,
          ...userBusinessDetails,
        };
        await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
          userId: user.value.id,
          formData: {
            ...userBusinessDetailsDto,
          },
        });
        await store.dispatch(`${USER_STORE}/initializeUserState`);
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoading.value = false;
      }

      ctx.emit("onClickCloseTab");
    };
    return {
      modelDetails,
      onClickBackButton,
      formData,
      validation,
      vatTaxFormRef,
      onClickTaxVatSave,
      isShowPassword,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/tax-and-vat/styles/TaxAndVatModel.scss";
</style>
