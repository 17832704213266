<template>
  <main class="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-w-full">
    <!-- <el-form
      class="tw-grid tw-grid-cols-5 lg:tw-grid-cols-8 tw-gap-4 tw-w-full"
      :model="
        estimateForm.projectStages[phaseIndex].projectStageTasks[itemIndex]
      "
    > -->
    <el-form
      class="tw-flex tw-gap-4 tw-items-center tw-w-full tw-justify-start tw-flex-wrap"
      :model="
        estimateForm.projectStages[phaseIndex].projectStageTasks[itemIndex]
      "
    >
      <el-form-item
        class="tw-w-[calc(40%-12px)] md:tw-w-full tw-max-w-full"
        :class="{ 'is-error': !!subTaskErrors.name }"
      >
        <NoBgInput
          v-model="
            estimateForm.projectStages[phaseIndex].projectStageTasks[itemIndex]
              .name
          "
          placeholder="Material Description"
          @change="updateMaterialPhaseTask"
        />
      </el-form-item>
      <el-form-item
        :class="{ 'is-error': !!subTaskErrors.materialUnit }"
        class="tw-w-[calc(15%-12px)] md:tw-w-[calc(50%-8px)] tw-max-w-full"
      >
        <div class="tw-flex tw-flex-row tw-w-full">
          <NoBgInput
            type="number"
            min="1"
            v-model.number="
              estimateForm.projectStages[phaseIndex].projectStageTasks[
                itemIndex
              ].materialUnit
            "
            @input="handleMaterialUnit"
            placeholder="Unit"
          />
        </div>
      </el-form-item>
      <el-form-item
        :class="{ 'is-error': !!subTaskErrors.taskCost }"
        class="tw-w-[calc(15%-12px)] md:tw-w-[calc(50%-8px)] tw-max-w-full"
      >
        <div class="tw-flex tw-items-center tw-w-full tw-gap-2">
          <Text variant="p">£</Text>
          <NoBgInput
            @input="handleSubTotalTask($event)"
            @keypress="
              numChecker(
                $event,
                estimateForm.projectStages[phaseIndex].projectStageTasks[
                  itemIndex
                ].taskCost
              )
            "
            placeholder="Unit Cost"
            v-model="
              estimateForm.projectStages[phaseIndex].projectStageTasks[
                itemIndex
              ].taskCost
            "
            :formatter="
              (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
            :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
          />
        </div>
      </el-form-item>
      <div
        class="tw-flex tw-gap-3 tw-items-center tw-w-[calc(30%-12px)] md:tw-w-[70%] tw-max-w-full"
      >
        <Text
          variant="span"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          whiteSpace="nowrap"
          >Total item cost:</Text
        >
        <LabelWithValue
          class=""
          :justifyStart="true"
          :data="formattedCurrency(totalItemCost)"
          label=""
        />
      </div>
    </el-form>
    <!-- <section class="tw-flex tw-items-center tw-justify-between tw-w-full">
      <div class="tw-flex tw-items-center tw-gap-1">
        <v-btn
          class="yellow-text tw-capitalize !tw-h-auto tw-p-0 tw-opacity-100"
          variant="plain"
          v-if="!assignPhaseMaterial"
          @click="toggleAssignPhaseModal"
          >Assign to A phase <v-icon icon="mdi-chevron-right"></v-icon
        ></v-btn>
        <label
          class="yellow-text tw-capitalize tw-text-left"
          v-else
          @click="toggleAssignPhaseModal"
          >Change Assigned Phase <v-icon icon="mdi-chevron-right"></v-icon
        ></label>
        <InfoIcon />

        <label
          class="tw-capitalize tw-text-xs tw-font-semibold tw-text-[#0C0F4A] tw-tracking-[0.4px]"
          v-if="assignPhaseMaterial"
          >{{ assignPhaseMaterial?.name }}</label
        >
      </div>
      <Button
        class="!tw-w-auto tw-text-xs tw-font-semibold tw-text-[#0C0F4A] tw-tracking-[0.4px] button-custom"
        label="Delete Material"
        variant="button-custom"
        :isCapitalize="true"
        @click="onDeleteMaterialItem"
      >
        <template #prefix>
          <TrashIcon />
        </template>
      </Button>
    </section> -->
    <SelectMaterialAssignPhaseModal
      v-if="isShowAssignPhaseModal"
      :assignPhases="assignPhases"
      :selectedAssignPhase="assignPhaseMaterial"
      @on-close="toggleAssignPhaseModal"
      @on-save="onSaveAssignPhase"
    />
  </main>
</template>
<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import NoBgInput from "@/core/components/ui/inputs/NoBgInput.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import { isNumber } from "@/core/helpers/number.helper";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { EstimatePhaseType } from "@/core/enums/estimateEnum";
import SelectMaterialAssignPhaseModal from "@/modules/jobs/components/modal/SelectMaterialAssignPhaseModal.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";

const store = useStore();
const emits = defineEmits(["on-update-material-task"]);
const durationOptions = [
  {
    value: 1,
    label: "Days",
  },
  {
    value: 2,
    label: "Weeks",
  },
];

const props = defineProps(["phaseIndex", "itemIndex", "item"]);

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);

const numChecker = (event, value) => {
  return isNumber(event, value);
};

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const onDeleteMaterialItem = async () => {
  if (!isFormReadOnly.value) {
    // Delete the task from estimateForm.projectStages
    const estimatePhaseTask = estimateForm.value.projectStages[
      props.phaseIndex
    ].projectStageTasks.find((data, idx) => idx === props.itemIndex);

    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks =
      estimateForm.value.projectStages[
        props.phaseIndex
      ].projectStageTasks.filter((x, xi) => xi !== props.itemIndex);
    try {
      await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhaseTask`, {
        estimatePhaseTaskId: estimatePhaseTask.id,
      });
    } catch (error) {
      console.log();
    }
  }
};
const validCost = computed(() => {
  const MAX_LIMIT = 99999999;
  const cost =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ].taskCost;
  if (cost > MAX_LIMIT) {
    return true;
  }
  return false;
});
const handleSubTotalTask = (value) => {
  if (!value) {
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ].taskCost = "0";
  } else {
    if (!validCost.value) updateMaterialPhaseTask();
  }
};
const handleMaterialUnit = (value) => {
  if (!value) {
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ].materialUnit = "0";
  } else {
    updateMaterialPhaseTask();
  }
};
const totalItemCost = computed(() => {
  const phase = estimateForm.value.projectStages[props.phaseIndex];

  const totalCostPerUnit =
    phase.projectStageTasks[props.itemIndex].taskCost *
    phase.projectStageTasks[props.itemIndex].materialUnit;

  phase.projectStageTasks[props.itemIndex].total = totalCostPerUnit;

  let currentTotal = phase.projectStageTasks.reduce((sum, task, index) => {
    return sum + (parseFloat(task.total) || 0);
  }, 0);
  store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseSubTotal`, {
    phaseIndex: props.phaseIndex,
    newValue: currentTotal,
  });

  return totalCostPerUnit;
});
const updateMaterialPhaseTask = () => {
  const { name, materialUnit, taskCost } =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
      props.itemIndex
    ];
  if (taskCost) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,

      field: "taskCost",
    });
  }
  if (materialUnit) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,
      field: "materialUnit",
    });
  }
  if (name) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      subTaskIndex: props.itemIndex,
      field: "name",
    });
  }
  emits("on-update-material-task");
};

const subTaskErrors = computed(() => {
  const errors =
    store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  if (props.itemIndex !== undefined) {
    return errors[props.phaseIndex]?.subErrors?.[props.itemIndex] || {};
  }
  return {};
});

const isShowAssignPhaseModal = ref(false);
const assignPhaseMaterial = ref([]);
const onSaveAssignPhase = async (payload, phase) => {
  try {
    const isAlready = assignPhaseMaterial.value?.find(
      (data) => data.id === phase?.id
    );
    if (!isAlready) {
      assignPhaseMaterial.value = phase;
      const taskId =
        estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
          props.itemIndex
        ].id;
      await tradeEstimateService.assignPhaseToTaskInEstimate(taskId, payload);
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    isShowAssignPhaseModal.value = false;
  }
};
const toggleAssignPhaseModal = () => {
  isShowAssignPhaseModal.value = !isShowAssignPhaseModal.value;
};
const assignPhases = computed(() => {
  return estimateForm.value.projectStages.filter(
    (phase) => phase.stageType === "labour"
  );
});
onMounted(() => {
  if (estimateForm.value) {
    const isExistPhase =
      estimateForm.value.projectStages[props.phaseIndex].projectStageTasks[
        props.itemIndex
      ].assignedPhaseTo;

    if (isExistPhase) {
      assignPhaseMaterial.value = isExistPhase;
    } else assignPhaseMaterial.value = null;
  }
});
</script>
<style lang="scss" scoped>
.el-form-item {
  margin: 0;
}
:deep(.el-input__wrapper) {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
:deep(.el-input__prefix) {
  position: absolute;
  right: 0;
}
.is-error input,
.is-error select {
  border-color: red;
}
.button-custom {
  width: auto !important;
  color: rgba($blueDark, 1);
  @include fluidFont(12, 12, 1.2);
  background-color: transparent;
}
</style>
