<template>
  <div
    class="tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-[0px_0px_8px_0px_#0C0F4A14] tw-max-w-[412px]"
  >
    <div class="tw-flex tw-justify-between tw-flex-end">
      <div class="tw-flex tw-gap-2 tw-items-start">
        <ProfileThumbnail :src="imageUrl" />

        <div class="header_text tw-text-left">
          <Text
            whiteSpace="nowrap"
            variant="h6"
            class="tw-block tw-text-[rgba(44, 62, 80, 0.5)] tw-overflow-ellipsis tw-overflow-hidden tw-max-w-full"
          >
            {{ getWorkstationName(visitData?.workStation?.name) }}
          </Text>

          <Text class="xs-fs-12" textColor="rgba(12, 15, 74, 0.5)">
            {{ visitData?.workStation?.workstationAccountType?.code }}
          </Text>
        </div>
      </div>

      <div
        class="tw-max-w-[94px] tw-flex tw-justify-between tw-items-center tw-p-2 tw-rounded-md tw-gap-1 tw-h-[24px] status-bagde"
        :class="getStatus"
      >
        <img :src="countDownSvg" class="tw-w-[12px] tw-h-[12px]" />
        <span class="tw-text-[12px] tw-font-[700] tw-text-white">{{
          getStatus
        }}</span>
      </div>
    </div>

    <div
      class="tw-w-full tw-h-[1px] tw-border-[0.25px] tw-border-solid tw-border-gray-100 tw-my-4"
    />
    <div class="tw-flex tw-justify-between tw-mb-2 tw-gap-2 tw-text-right">
      <span class="tw-text-[14px] tw-font-[600] tw-text-[#0C0F4A]">
        Property
      </span>
      <span
        class="tw-text-[14px] tw-font-[600] tw-text-[#4F55F0] tw-text-left tw-max-w-[200px] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap"
      >
        {{ visitData?.property?.address }}
      </span>
    </div>
    <div class="tw-flex tw-justify-between tw-mb-2 tw-gap-2">
      <span class="tw-text-[14px] tw-font-[600] tw-text-[#0C0F4A]"> Time </span>
      <span class="tw-text-[14px] tw-font-[600] tw-text-[#4F55F0]">
        {{ formatTimeRang(visitData?.startTime) }}
      </span>
    </div>
    <div class="tw-flex tw-justify-between tw-mb-2 tw-gap-2">
      <span class="tw-text-[14px] tw-font-[600] tw-text-[#0C0F4A]"> Date </span>
      <span class="tw-text-[14px] tw-font-[600] tw-text-[#4F55F0]">
        {{ visitData?.date }}
      </span>
    </div>
    <div
      class="tw-w-full tw-h-[1px] tw-border-[0.25px] tw-border-solid tw-border-gray-100 tw-my-4"
    />

    <span
      class="tw-text-[12px] tw-font-[400] tw-text-[#0C0F4ACC] tw-text-left tw-flex"
    >
      {{ visitData?.description ? visitData?.description : "" }}
    </span>
    <div
      v-if="typeData === 'Project' && requestStatus === SiteVisitStatus.DEFAULT"
    >
      <!-- <v-btn @click="openRejectModal">Reject</v-btn>
      <v-btn label="Accept" @click="updateRequest(SiteVisitStatus.ACCEPTED)"
        >Accept
      </v-btn> -->
      <div class="tw-flex tw-gap-3 tw-mt-2">
        <Button
          label="Reject"
          @click="openRejectModal"
          class="!tw-w-1/2 md:!tw-w-full button button-purple-border"
        />
        <Button
          class="button button-orange !tw-w-1/2 md:!tw-w-full"
          variant="text"
          label="Accept"
          :loading="isLoading"
          @click="updateRequest(SiteVisitStatus.ACCEPTED)"
        />
      </div>
    </div>

    <div v-if="typeData === 'Job' && requestStatus === SiteVisitStatus.DEFAULT">
      <div
        class="tw-w-full tw-h-[1px] tw-border-[0.25px] tw-border-solid tw-border-gray-100 tw-my-4"
      />
      <Button
        variant="primary"
        label="Cancel"
        :loading="isLoading"
        @click="updateRequest(SiteVisitStatus.CANCELED)"
      />
    </div>
    <div
      v-if="typeData === 'Job' && requestStatus === SiteVisitStatus.ACCEPTED"
    >
      <div
        class="tw-w-full tw-h-[1px] tw-border-[0.25px] tw-border-solid tw-border-gray-100 tw-my-4"
      />
      <Button
        variant="primary"
        label="VISIT PROPERTY"
        color="#4F55F0"
        @click="onClickVisitProperty"
      />
    </div>
    <RejectSiteRequestDialog
      v-if="isShowRejectModal"
      :isLoading="isRejectLoading"
      @on-cancel="onCancelRejectSiteModal"
      @on-confirm="onConfirmRejectSiteModal"
    />
  </div>
</template>
<script lang="ts" setup>
import countDownSvg from "@/core/uni-assets/svgs/countdown.svg";
import { SiteVisitStatus } from "@/core/enums/estimateEnum";
import moment from "moment";
import { ref } from "vue";
import { onMounted } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import Button from "./Button.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { getImageApiUrl } from "../utils/common";
import $axios from "@/core/utils/axios-api-config";
import RejectSiteRequestDialog from "./RejectSiteRequestDialog.vue";
import { useRouter } from "vue-router";
import { WORKSTATION } from "@/store/modules/workstation";
import { PROPERTY_STORE } from "@/store/modules/property";
const router = useRouter();
const requestStatus = ref(0);
const store = useStore();
const isLoading = ref(false);
const isShowRejectModal = ref(false);
const props = defineProps({
  visitData: {
    type: Object,
  },
  typeData: {
    type: String,
  },
  projectRefId: {
    type: String,
  },
});
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const isRejectLoading = ref(false);
const formatTime = (hours: number, minutes: number) => {
  return moment({ hours, minutes }).format("hh:mm A");
};

const getWorkstationName = (name: any) => {
  return name.length > 25 ? `${name.slice(0, 25)}...` : name;
};
const emits = defineEmits(["get-all-request"]);
const formatTimeRang = (timeArray: any) => {
  timeArray = JSON.parse(timeArray);
  const startTime = formatTime(timeArray[0].hours, timeArray[0].minutes);
  const endTime = formatTime(timeArray[1].hours, timeArray[1].minutes);
  return `${startTime} to ${endTime}`;
};
const getStatus = computed(() => {
  if (requestStatus.value === SiteVisitStatus.REJECTED) {
    return "Rejected";
  } else if (requestStatus.value === SiteVisitStatus.ACCEPTED) {
    return "Accepted";
  } else {
    return "Requested";
  }
});

const updateRequest = async (
  status: SiteVisitStatus,
  rejectionReason = "",
  rejectionMessage = ""
) => {
  const formData = {
    status,
    rejectionReason,
    rejectionMessage,
  } as any;
  try {
    isLoading.value = true;
    const response = await store.dispatch(
      `${JOB_TEMPLATE_STORE}/updateSiteRequest`,
      {
        siteVisitId: parseInt(props.visitData?.id),
        formData,
      }
    );

    if (response) requestStatus.value = response?.status;

    if (response && SiteVisitStatus.CANCELED == status) {
      emits("get-all-request");
    }
    isLoading.value = false;
  } catch (error) {
    console.log();
    isLoading.value = false;
  }
};
const imageUrl = ref(null);

const openRejectModal = () => {
  isShowRejectModal.value = true;
};
const onCancelRejectSiteModal = () => {
  isShowRejectModal.value = false;
};
const onConfirmRejectSiteModal = async (payload: any) => {
  isRejectLoading.value = true;
  try {
    const { rejectionReason, rejectionMessage } = payload;
    await updateRequest(
      SiteVisitStatus.REJECTED,
      rejectionReason,
      rejectionMessage
    );
    isShowRejectModal.value = false;
    isRejectLoading.value = false;
  } catch (error) {
    console.log(error);
    isRejectLoading.value = false;
  }
};

const onClickVisitProperty = async () => {
  store.commit(`${PROPERTY_STORE}/setIsSiteVisitPropertyDetail`, true);
  store.commit(
    `${PROPERTY_STORE}/setSiteVisitProjectRefId`,
    props.projectRefId
  );
  await router.push({
    name: "propertyDetails",
    params: { propertyId: props.visitData?.property?.id },
  });
};
onMounted(async () => {
  requestStatus.value = props.visitData?.status;
  try {
    const imageApiUrl = getImageApiUrl(
      props?.visitData?.workStation?.profileImage,
      true
    );
    const encoded = await $axios.get(imageApiUrl);
    imageUrl.value = encoded.publicUrl;
  } catch (error) {
    console.log();
  }
});
</script>
<style lang="scss">
.status-bagde.Rejected {
  background: red;
}

.status-bagde.Accepted {
  background: green;
}

.status-bagde.Requested {
  background: #faa500;
}
</style>
