<template>
  <GeneralDialog
    class="estimate_deposit_modal"
    :headerTitle="modalHeader.title"
    :headerDescription="modalHeader.description"
    @on-close="onCloseModal"
    width="570px"
    max-width="100%"
    padding="24px"
  >
    <template #body>
      <!-- Select Estimation Type -->
      <v-select
        v-model="selectDeposit"
        :items="options"
        item-title="label"
        item-value="value"
        variant="outlined"
        class="input_field"
        placeholder="Select"
        dense
        hide-details
        return-object
        :rules="[isRequired]"
        :menu-props="{ contentClass: 'tags_dropdown' }"
      >
      </v-select>

      <!-- Deposit Amount / Sum -->
      <Text
        variant="h6"
        textWeight="400"
        textColor="#0c0f4a"
        class="tw-mt-9 tw-inline-block"
        >Please select the deposit sum(s) you require to see to start the
        projects</Text
      >
      <div class="tw-flex tw-flex-col tw-items-start tw-mt-6 tw-gap-7">
        <v-checkbox
          @change="onCheckFullDeposit"
          v-model="isFullDepositRequired"
          color="primary"
          class="custom_checkbox"
          onselect="atLeastOneRequired"
        >
          <template v-slot:label>
            <div class="tw-flex tw-items-center tw-gap-1">
              <Text variant="h6" textWeight="500">Full deposit</Text>
              <Text variant="h6" textWeight="500">-</Text>
              <Text
                variant="h6"
                textWeight="400"
                textColor="rgba(75, 75, 255, 1)"
                >{{ currencyFilter.formatToCurrency(fullDeposit) || 0 }}</Text
              >
            </div>
          </template>
        </v-checkbox>

        <v-checkbox
          class="custom_checkbox"
          v-for="(phase, index) in list"
          :key="index"
          v-model="phase.requiredDeposit"
          :disabled="isFullDepositRequired"
          color="primary"
          onselect="atLeastOneRequired"
        >
          <template v-slot:label>
            <div class="tw-flex tw-items-center tw-gap-1">
              <Text variant="h6" textWeight="500"
                >{{ phase.stageType === MATERIAL ? "materials" : "Phase" }}
                {{ index + 1 }}</Text
              >
              <Text variant="h6" textWeight="500">-</Text>
              <Text
                variant="h6"
                textWeight="400"
                textColor="rgba(75, 75, 255, 1)"
              >
                {{
                  index === 0
                    ? totalPhaseAmount(phase?.subTotal, phase?.vat, true)
                    : totalPhaseAmount(phase?.subTotal, phase?.vat, false)
                }}
              </Text>
            </div>
          </template>
        </v-checkbox>

        <Text v-if="!isFormValid" variant="h6" textWeight="500" textColor="red"
          >Please Select one Deposit and Select Deposit Type</Text
        >
      </div>
      <div class="tw-flex tw-items-start tw-gap-2 tw-pt-9 info_wrapper">
        <v-icon class="tw-mt-2">
          <img src="../../../../assets/icons/info-grey.svg" alt="" />
        </v-icon>
        <Text
          class="tw-inline-block"
          variant="h6"
          textColor="rgba(12, 15, 74, 0.5)"
          lineHeight="24px"
          textWeight="500"
          >Asking for full deposit in advance is not advised as it might lower
          chances to win the contract</Text
        >
      </div>

      <div
        class="tw-flex tw-items-start tw-gap-2 tw-border-b tw-border-solid tw-border-[#C5D2F2] tw-border-0 info_wrapper tw-flex-col pb-3"
      >
        <v-checkbox
          v-if="
            (isEstimateLoadedModified && hasLoadedTemplate) ||
            !hasLoadedTemplate
          "
          v-model="isSaveAsTemplate"
          color="primary"
          @update:modalValue="handleTemplateModal"
        >
          <template v-slot:label>
            <div class="tw-flex tw-items-center tw-gap-1">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >Save this estimate as a template</Text
              >
            </div>
          </template>
        </v-checkbox>
        <div
          class="tw-flex tw-items-center tw-gap-1 tw-w-full tw-max-w-full"
          v-if="isSaveAsTemplate"
        >
          <v-text-field
            class="input_field"
            v-model="estimateTemplateName"
            :rules="[validationTemplateName]"
            variant="solo-filled"
            hide-details
            single-line
            flat
            label="Template Name"
          ></v-text-field>
        </div>
        <Text
          v-if="!templateValid"
          variant="h6"
          textWeight="500"
          textColor="red"
        >
          Template name is required</Text
        >
      </div>
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3 tw-mt-2"
      >
        <Button
          variant="tertiary"
          label="Cancel"
          @click="onCloseModal"
          class="button button-purple-border"
        />
        <Button
          label="submit estimate"
          :disabled="!isFormValid && !templateValid"
          @click="onConfirm"
          class="button button-orange"
        />
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { EstimateTypeForDeposit } from "@/core/enums/estimateEnum";
import { computed } from "vue";
import currencyFilter from "@/filters/currency.filter";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
const store = useStore();
const MATERIAL = "materials";
const emits = defineEmits(["on-confirm", "on-cancel", "on-close"]);
const props = defineProps([
  "phases",
  "sumTotal",
  "show",
  "estimate",
  "totalSubTotal",
  "totalVat",
  "serviceCharges",
]);

const fullDeposit = ref("");
const isFullDepositRequired = ref(false);
const isSaveAsTemplate = ref(false);
const estimateTemplateName = ref("");
const selectDeposit = ref(null);

const isEstimateLoadedModified = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateLoadedModified`]
);
const hasLoadedTemplate = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/hasLoadedTemplate`]
);

const options = ref([
  { label: "Initial Estimate", value: EstimateTypeForDeposit.Initial_Estimate },
  {
    label: "Variable Cost Estimation",
    value: EstimateTypeForDeposit.Variable_Cost_Estimation,
  },
  {
    label: "Fixed Cost Estimation",
    value: EstimateTypeForDeposit.Fixed_Cost_Estimation,
  },
]);
const list = ref([]);
const modalHeader = {
  title: "Estimation & Deposit",
  description: "Note: Deposits will be held in project escrow until release.",
};
const onCloseModal = () => {
  emits("on-close");
};

const issubmitted = ref(false);

const onConfirm = () => {
  issubmitted.value = true;
  if (!templateValid.value || !isFormValid.value) {
    return;
  }
  const requiredDepositIds = list.value
    .filter((phase) => phase.requiredDeposit)
    .map((phase) => ({ phaseId: phase.id }));

  const payload = {
    depositType: selectDeposit.value.value,
    escrowStatusUpdate: requiredDepositIds,
    isSaveEstimateTemplate: isSaveAsTemplate.value,
    templateName: estimateTemplateName.value,
  };
  emits("on-confirm", payload);
};

const onCancel = () => {
  emits("on-cancel");
};

const initialize = async () => {
  list.value = normalizePhases();
  fullDeposit.value =
    Math.round(
      (props.totalSubTotal + props.totalVat + props.serviceCharges) * 100
    ) / 100;
};

const onCheckFullDeposit = (value) => {
  list.value.forEach((phase) => {
    phase.requiredDeposit = isFullDepositRequired.value;
  });
};

const normalizePhases = () => {
  return props.estimate?.map((phase) => {
    const isMaterial = phase.stageType === MATERIAL;
    return {
      ...phase,
      isMaterial,
      requiredDeposit: false,
    };
  });
};

const isRequired = (value) => !!value || "This field is required";
const validationTemplateName = (value) =>
  !!value || "Template name is required";
const atLeastOneRequired = () => {
  const isCheckedDeposit = list.value.some((phase) => phase.requiredDeposit);

  if (isCheckedDeposit || isFullDepositRequired.value) {
    return true;
  } else {
    return false;
  }
};

const totalPhaseAmount = (amount, vat, isFirstPhase) => {
  const SERVICE_CHARGE = 0.03;
  const PHASE_FIRST_CHARGE = 5;
  let sum = 0;

  sum += amount + amount * vat + amount * SERVICE_CHARGE;
  // sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }
  return currencyFilter.formatToCurrency(sum);
};
const isFormValid = computed(() => {
  return selectDeposit.value && atLeastOneRequired();
});
const templateValid = computed(() => {
  return isSaveAsTemplate.value && issubmitted.value
    ? estimateTemplateName.value.length > 0
      ? true
      : false
    : true;
});

const handleTemplateModal = (value) => {
  if (!value) {
    estimateTemplateName.value = "";
  }
};
onMounted(() => {
  initialize();
});
</script>

<style lang="scss" scoped>
.custom_selector {
  :deep(.v-input__control) {
    .v-field {
      background-color: transparent;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 0;
      .v-field__field {
        .v-field__input {
          @include respond(s720) {
            padding-left: 0.5rem;
          }
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
          input {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;

            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.custom_checkbox {
  :deep(.v-input__control) {
    .v-selection-control {
      min-height: auto;
      gap: 8px;
      .v-selection-control__wrapper {
        height: auto;
        width: auto;
        .v-selection-control__input {
          height: auto;
          width: auto;
          .v-icon {
            @include fluidFont(18, 18, 1);
            color: rgba($SecondaryBrightBlue, 1);
            opacity: 1;
          }
          .mdi-checkbox-blank-outline {
            color: rgba($blueDark, 1);
          }
          .mdi-checkbox-marked {
            color: rgba($SecondaryBrightBlue, 1);
          }
          .v-ripple__container {
            display: none;
          }
        }
      }
    }
  }
}

.input_field {
  margin: 0;
  :deep(.v-input__control) {
    .v-field {
      border: 1px solid rgba($blueDark, 0.2);
      border-radius: 8px;
      padding: 0;
      --v-field-padding-bottom: 0;
      background-color: rgba($backgroundcolor, 1);
      .v-field__field {
        align-items: center;

        .v-field__input {
          background-color: transparent;
          border: 0;
          border-radius: 0;
          padding: 0 0 0 10px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          min-height: 42px;
          input {
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}
.estimate_deposit_modal {
  .general-dialog {
    padding: 24px !important;
  }
}

.v-checkbox {
  :deep(.v-input__control) {
    .v-selection-control {
      min-height: auto;
    }
  }
}
</style>
