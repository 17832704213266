<template>
  <svg
    width="75"
    height="74"
    viewBox="0 0 75 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_9522_73044)">
      <mask id="path-1-inside-1_9522_73044" fill="white">
        <path
          d="M2.25 37C2.25 17.67 17.92 2 37.25 2C56.58 2 72.25 17.67 72.25 37C72.25 56.33 56.58 72 37.25 72C17.92 72 2.25 56.33 2.25 37ZM61.75 37C61.75 23.469 50.781 12.5 37.25 12.5C23.719 12.5 12.75 23.469 12.75 37C12.75 50.531 23.719 61.5 37.25 61.5C50.781 61.5 61.75 50.531 61.75 37Z"
        />
      </mask>
      <path
        d="M2.25 37C2.25 17.67 17.92 2 37.25 2C56.58 2 72.25 17.67 72.25 37C72.25 56.33 56.58 72 37.25 72C17.92 72 2.25 56.33 2.25 37ZM61.75 37C61.75 23.469 50.781 12.5 37.25 12.5C23.719 12.5 12.75 23.469 12.75 37C12.75 50.531 23.719 61.5 37.25 61.5C50.781 61.5 61.75 50.531 61.75 37Z"
        fill="url(#paint0_linear_9522_73044)"
      />
      <path
        d="M2.25 37C2.25 17.67 17.92 2 37.25 2C56.58 2 72.25 17.67 72.25 37C72.25 56.33 56.58 72 37.25 72C17.92 72 2.25 56.33 2.25 37ZM61.75 37C61.75 23.469 50.781 12.5 37.25 12.5C23.719 12.5 12.75 23.469 12.75 37C12.75 50.531 23.719 61.5 37.25 61.5C50.781 61.5 61.75 50.531 61.75 37Z"
        stroke="white"
        stroke-width="2"
        mask="url(#path-1-inside-1_9522_73044)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_9522_73044"
        x="0.25"
        y="0"
        width="74"
        height="74"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0470588 0 0 0 0 0.0588235 0 0 0 0 0.290196 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_9522_73044"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9522_73044"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_9522_73044"
        x1="37.25"
        y1="2"
        x2="37.25"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9DB46E" />
        <stop offset="1" stop-color="#444E2F" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup>
// const props = defineProps({
//   fill: {
//     type: String,
//     default: "#9DCB3C",
//   },
// });
</script>

<script>
export default {
  name: "EllipsisDarkGreen",
};
</script>
