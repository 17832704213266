<template>
  <WizardModal
    :hideFooter="false"
    :hideStepper="true"
    :hasCustomActionFooter="true"
    :hideBackButton="false"
    :currentStep="2"
    :hideClose="true"
    :singleData="modelDetails"
    :showHeaderInMobile="true"
    :hideTitleAndDescription="true"
    width="704px"
    height="543px"
    @on-close="onCloseModal"
    @on-back="onBack"
  >
    <template #content>
      <main
        class="tw-overflow-y-auto tw-flex tw-flex-col tw-h-full tw-min-h-[unset] tw-justify-between !tw-mt-0 rmd:!tw-mt-4 tw-gap-4 tw-pt-0 tw-box-border"
      >
        <section class="tw-flex tw-flex-col tw-h-full tw-gap-4">
          <div class="tw-flex-row-between !tw-items-start md:!tw-flex-col">
            <div class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
              <Text variant="h4" class="md:!tw-text-[21px]" textWeight="400"
                >Do you have a Workstation?</Text
              >
              <Text variant="span" textWeight="400"
                >Select a workstation you would like to assign the course
                to.</Text
              >
            </div>
            <Text
              variant="h6"
              textColor="#4F55F0"
              class="tw-cursor-pointer"
              @click="onCreateWorkstation"
              >Create a New Workstation
              <v-icon icon="mdi-chevron-right" color="#4F55F0"></v-icon
            ></Text>
          </div>

          <div class="tw-flex tw-flex-col tw-gap-3 tw-items-start">
            <Text variant="h5">Select Workstation</Text>
            <InputSelect
              class="!tw-w-full md:!tw-w-full"
              placeholder="Select workstation"
              :items="filteredUserAllWorkStation"
              variant="outlined"
              v-model="selectedWorkstation"
              item-title="name"
              item-value="id"
              no_data_text="No verified workstation available"
            />
          </div>
          <div
            class="tw-w-full tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[#f454540d] tw-border-solid tw-border tw-border-[#f45454] tw-mt-[60px] tw-relative tw-items-start"
            v-if="isShowErrorMsg"
          >
            <img
              src="@/assets/icons/close-2.svg"
              alt=""
              @click="onClickCloseErrorMsgBanner"
              class="close-error tw-absolute tw-right-[5px] tw-top-[5px] tw-cursor-pointer tw-w-[20px] tw-h-[20px]"
            />
            <!-- <CloseIcon
              class="tw-absolute tw-right-[5px] tw-top-[5px] tw-stroke-[#fa4b00] tw-cursor-pointer tw-w-[10px] tw-h-[10px]"
            /> -->
            <ErrorRedIcon class="tw-w-[30px] tw-h-[30px]" />
            <p
              class="tw-text-sm tw-text-[#fa4b00] tw-w-[calc(100%-50px)] tw-max-w-full"
            >
              {{ selectedWorkstationName }} does not have required certificates
              to enroll in <b>{{ selectedCourse.name }}</b>
            </p>
          </div>
        </section>
      </main>
    </template>
    <template #footer-action>
      <section
        class="tw-w-full tw-flex tw-items-center tw-justify-end tw-gap-4 tw-pt-4 tw-box-border tw-border-t-solid-custom md:!tw-justify-center md:!tw-flex-col-reverse md:!tw-gap-[8px] md:!tw-pb-16"
      >
        <div
          class="tw-flex tw-items-center tw-gap-4 md:!tw-gap-4px md:!tw-flex-col md:!tw-w-full"
        >
          <Button
            class="!tw-w-auto md:!tw-w-full"
            label="back"
            @click="onBack"
            variant="secondary"
          />
          <Button
            class="!tw-w-auto md:!tw-w-full"
            label="launch workstation"
            :disabled="!selectedWorkstation"
            @click="onLaunchWorkstation"
          />
        </div>
      </section>
    </template>
  </WizardModal>
</template>
<script setup>
import { computed, ref, onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

// import CommonDialog from "@/core/components/CommonDialog.vue";
import WizardModal from "@/core/components/modals/WizardModal.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import CancelIcon from "@/core/components/icons/CancelIcon.vue";
import ListIcon from "@/core/components/icons/ListIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import InputSelect from "@/core/components/common/InputSelect.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_LAUNCH_WS,
  DASHBOARD_WITH_COURSE_ROUTE,
} from "@/modules/dashboard/routes";
import coursesService from "@/core/services/courses.service";
import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
import CloseIcon from "@/core/components/icons/CloseIcon.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const courseId = computed(() => route.params.courseId);
const courseEligibility = ref([]);

const isLoading = ref(false);
const isShowCertificateModal = ref(false);
const tcOptIn = ref(false);

const selectedWorkstation = ref(null);
const selectedWorkstationName = computed(
  () =>
    filteredUserAllWorkStation.value.find(
      (ws) => ws.id == selectedWorkstation.value
    )?.name
);
const modelDetails = {
  id: 2,
  tab: "preEnrollTerms",
  name: "preEnrollTerms",
  header: "",
};

const emits = defineEmits(["on-close", "on-back"]);
const isShowErrorMsg = ref(false);
const userAllWorkStation = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkstations`]
);

const filteredUserAllWorkStation = computed(() =>
  userAllWorkStation.value.filter((workstation) => {
    return (
      workstation.name !== "Default" && workstation.verificationStatus === "2"
    );
  })
);

const reservedSpotsData = computed(
  () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
);

const selectedCourse = ref(null);
const filteredReserveSpots = computed(() => {
  const courseId = String(route?.params?.courseId); // Convert courseId to a string

  if (reservedSpotsData.value && reservedSpotsData.value.length) {
    return reservedSpotsData.value.filter((spot) => {
      return String(spot.course?.id) === courseId && !spot.subscription;
    });
  }

  return [];
});

const onCloseModal = () => {
  return emits("on-close");
};

// const onCreateWorkstation = () => {
//   onClickBackButton();
//   store.dispatch(`${WORKSTATION}/setWsModal`, {
//     isShowWorkStationModal: true,
//   });
//   router.push({ name: DASHBOARD_ROUTE });
// };

const onBack = () => {
  emits("on-back");
};

const onCreateWorkstation = () => {
  store.dispatch(`${WORKSTATION}/setWsModal`, {
    isShowWorkStationModal: true,
    isFromCourse: true,
  });
  router.push({
    name: DASHBOARD_WITH_COURSE_ROUTE,
    params: courseId.value,
  });
  emits("on-close");
};

const onClickCloseErrorMsgBanner = () => {
  isShowErrorMsg.value = false;
};
const onLaunchWorkstation = async () => {
  const response = await store.dispatch(`${COURSES_STORE}/checkElegibility`, {
    courseId: courseId.value,
    workstationId: selectedWorkstation.value,
  });

  if (response.isEligible) {
    // if launch ws first //
    // set isFromCourse
    store.dispatch(`${WORKSTATION}/setWsModal`, {
      isFromCourse: true,
    });
    // set reservationSpot data to local store
    store.dispatch(
      `${COURSES_STORE}/setStoredReservedSpotsData`,
      filteredReserveSpots.value
    );
    router.push({
      name: DASHBOARD_WITH_COURSE_LAUNCH_WS,
      params: {
        courseId: courseId.value,
        workstationId: selectedWorkstation.value,
      },
    });
    onCloseModal();
  } else {
    const courses = await store.dispatch(`${COURSES_STORE}/getCourses`);
    selectedCourse.value = courses.data.find(
      (course) => course.id == courseId.value
    );
    isShowErrorMsg.value = true;
    setTimeout(() => {
      if (isShowErrorMsg.value) {
        isShowErrorMsg.value = false;
      }
    }, 5000);
  }
};

onBeforeMount(() => {
  //
});

onBeforeUnmount(() => {
  //   eligibility.value = null;
});
</script>
<style lang="scss" scoped>
.close-error {
  filter: invert(29%) sepia(91%) saturate(2524%) hue-rotate(12deg)
    brightness(104%) contrast(110%);
}
</style>
