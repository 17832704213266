<template>
  <div class="historic_project" v-if="!showProjectDetails">
    <div
      class="content_field tw-mb-4"
      v-if="filterHistoricProjects?.length && !isLoading"
    >
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        class="input_field"
        placeholder="Search"
        variant="outlined"
        v-model="searchValue"
      />
      <div class="sort__wrapper">
        <div class="sortby_btn">Sort by</div>

        <v-menu :close-on-content-click="false" :persistent="true">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="plain" class="sort__btn">
              <img :src="require('@/assets/icons/sort.svg')" alt="" />
            </v-btn>
          </template>
          <v-list class="dropdown__sort sort_dropdown_inner">
            <v-list-item>
              <v-radio-group v-model="sortBy">
                <v-radio
                  label="Most Recent"
                  value="ASC"
                  color="indigo"
                ></v-radio>
                <v-radio label="Oldest" value="DESC" color="indigo"></v-radio>
              </v-radio-group>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div
      class="historic_project__card_list md:!tw-mt-0"
      v-if="!isLoading && filterHistoricProjects?.length"
    >
      <v-card
        v-for="item in filterHistoricProjects"
        :key="item.id"
        class="historic_project__card__item"
      >
        <div class="card__header">
          <div class="card__header__img">
            <img
              class=""
              v-if="!contractorsImages[item?.projectCreator.id]"
              src="@/assets/images/user-placeholder.jpg"
            />
            <img
              v-else
              class=""
              :src="contractorsImages[item?.projectCreator.id]"
            />
          </div>
          <div class="right__block">
            <EstimateBadge
              :badge="getEstimateBadge(item?.project?.projectEstimation[0])"
              bgColor="#C19F5F"
            />
            <div class="heart_icon">
              <img src="../../../assets/icons/heart-icon.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="card__content">
          <div class="card__content__header">
            <h5>
              {{ item?.projectCreator?.firstName }}
              {{ item?.projectCreator?.lastName }}
            </h5>
            <div class="card__info">
              <div class="card__info__left">
                <span
                  >{{
                    BusinessDetailTypeEnum.SOLETRADER ===
                    item?.projectCreator?.userBusinessDetails?.type
                      ? "Solo Trader"
                      : "Limited Company"
                  }}
                  |
                </span>
                <span>
                  <v-icon icon="mdi-map-marker" size="x-small" />
                  {{ item?.projectCreator?.postcode || "NA" }}
                </span>
              </div>
              <div class="card__info__right">
                <v-icon icon="mdi-star" color="#FFA500" />
                <span
                  >{{ item?.ratings || 0 }}/5 ({{
                    item?.jobsCompleted
                  }}
                  Jobs)</span
                >
              </div>
            </div>
          </div>
          <div class="card__info__tags">
            <div class="card__info__tags__default">
              <v-icon icon="mdi-check-circle" color="#1fb27a" />
              <p>
                {{ item?.userWorkstation?.experience || "NA" }}
                <span>Exp.</span>
              </p>
            </div>
            <div
              class="card__info__tags__custom"
              v-if="
                item.projectCreator?.userSkills &&
                item.projectCreator?.userSkills.length
              "
            >
              <div
                class="custom_tags"
                v-for="skill in getUserSkillList(
                  item.projectCreator?.userSkills
                ).slice(0, 1)"
                :key="skill.id"
              >
                <v-icon>
                  <img
                    v-if="skill?.iconName"
                    :src="
                      require('@/assets/category-icons/' +
                        skill?.iconName +
                        '.svg')
                    "
                /></v-icon>
                <span>{{ skill?.label }}</span>
              </div>

              <div
                class="custom_tags__more"
                v-if="
                  getUserSkillList(item.projectCreator?.userSkills).length > 1
                "
              >
                <span>
                  +{{
                    getUserSkillList(item.projectCreator?.userSkills).length - 1
                  }}
                  More
                </span>
              </div>
            </div>
          </div>
          <div class="card_project_summary">
            <div class="card_project_summary__list">
              <div class="card_project_summary__list_item">
                <p>Start Date</p>
                <span> {{ formatDate(item.project?.startDate) }}</span>
              </div>
              <div class="card_project_summary__list_item">
                <p>Duration</p>
                <span>
                  {{
                    formatDuration(
                      item.project?.startDate,
                      item.project?.endDate
                    )
                  }}</span
                >
              </div>
              <div class="card_project_summary__list_item">
                <p>Cost</p>
                <span>{{
                  CurrencyFilter.formatToCurrency(
                    item?.project?.projectEstimation[0]?.totalEstimateCost
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="card__footer">
          <v-btn
            class="button button-orange"
            width="100%"
            @click="
              viewProject(item.project?.refId, item.project?.projectEstimateId)
            "
            >VIEW PROJECT</v-btn
          >
        </div>
      </v-card>
    </div>

    <div
      class="empty-screen md:!tw-pt-[24px] md:!tw-mt-0"
      v-if="!filterHistoricProjects?.length && !isLoading && !isOpenNewProject"
    >
      <div class="empty-screen__inner">
        <div
          class="empty-screen__image md:!tw-max-w-[260px] md:!tw-ml-0 md:!tw-mb-[24px]"
        >
          <img src="@/assets/images/historical__empty.png" alt="" />
        </div>
        <div class="empty-screen__header">
          <h2>You currently don't have any requests for historic projects.</h2>
          <p>Kick off your historic project with unparalleled speed.</p>
        </div>
        <div class="empty-screen__content">
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p><strong>Dispute protection</strong> as standard</p>
          </div>
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p>Deposits secured <strong>in Escrow</strong></p>
          </div>
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p>
              Verified trade contractors <strong>in your local area</strong>
            </p>
          </div>
        </div>
        <div class="empty-screen__footer">
          <v-btn class="button button-purple" @click="openNewProject" block>
            Start a new project
          </v-btn>
        </div>
      </div>
    </div>

    <Loader v-show="isLoading" class="tw-mt-[250px]" />
  </div>
  <PropertyListComponent
    @onClickBackButton="onClickBackButton"
    v-if="isOpenNewProject"
  />
</template>
<script setup lang="ts">
import { onMounted, ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { USER_STORE } from "@/store/modules/user";
import { JOBS_STORE } from "@/store/modules/jobs";
import { WORKSTATION } from "@/store/modules/workstation";
import CustomBadge from "@/core/uni-components/CustomBadge.vue";
import { HistoricProjectStatusEnum } from "@/core/enums/ProjectsEnum";
import CurrencyFilter from "@/filters/currency.filter";
import {
  getImageStringToImageURL,
  getUserSubSkillList,
} from "@/core/utils/common";
import Text from "@/core/uni-components/Text.vue";
import InputField from "@/core/uni-components/InputField.vue";
import Button from "@/core/uni-components/Button.vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { EstimateTypeForDeposit } from "@/core/enums/estimateEnum";
import { HISTORIC_PROJECT_DETAILS_ROUTE } from "../routes";
import PropertyListComponent from "./PropertyListComponent.vue";
import Loader from "@/core/components/common/Loader.vue";

const props = defineProps(["toggleTabList"]);
const showBanner = ref(true);
const isLoading = ref(true);
const contractorsImages: any = ref({});
const refId = ref() as any;
const estimateId = ref() as any;
const showProjectDetails = ref(false);
const searchValue = ref("");
const sortBy = ref("DESC");
const store = useStore();
const router = useRouter();
const isOpenNewProject = ref(false);

const viewProject = (refId: number, estimateId: number) => {
  router.push({
    name: HISTORIC_PROJECT_DETAILS_ROUTE,
    params: { refId, estimateId },
  });
};

const closeProject = () => {
  refId.value = null;
  estimateId.value = null;
  showProjectDetails.value = false;
  props.toggleTabList(true);
};

const historicProjects = computed(() => {
  return (
    store.getters[`${JOBS_STORE}/getHistoricalJobs`].filter(
      (obj: any) =>
        obj.project.projectHistoricalStatus == HistoricProjectStatusEnum.PENDING
    ) || []
  );
});

const filterHistoricProjects = computed(() => {
  let projects = historicProjects.value.filter((project: any) =>
    project.userWorkstation?.name
      ?.toLowerCase()
      .includes(searchValue.value?.toLowerCase())
  );
  if (sortBy.value == "ASC") projects.reverse();
  return projects;
});

watchEffect(async () => {
  await setProfileImageUrl(historicProjects.value);
});

async function setProfileImageUrl(proj: any) {
  for (const { projectCreator } of proj) {
    if (projectCreator?.userPublicProfile?.profileImage) {
      contractorsImages.value[projectCreator.id] =
        await getImageStringToImageURL(
          projectCreator.userPublicProfile.profileImage
        );
    }
  }
}

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);

const getUserSkillList = (userSkill: any) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};

const formatDate = (startDate: string) => {
  if (!startDate) {
    return "N/A";
  }
  const date = new Date(startDate);
  const options = { day: "2-digit", month: "long", year: "numeric" } as any;
  return date.toLocaleDateString("en-GB", options);
};

const formatDuration = (sDate: any, eDate: any) => {
  if (!sDate || !eDate) {
    return 0;
  }

  const startDate = moment(sDate) as any;
  const endDate = moment(eDate) as any;

  const totalDays = endDate.diff(startDate, "days");

  if (totalDays % 7 === 0) {
    const totalWeeks = totalDays / 7;
    return `${totalWeeks} week${totalWeeks > 1 ? "s" : ""}`;
  }

  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
};
const getEstimateBadge = (selectedEstimate: any) => {
  if (selectedEstimate?.depositType === EstimateTypeForDeposit.Initial_Estimate)
    return "Initial Estimate";
  else if (
    selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Fixed_Cost_Estimation
  )
    return "Fixed Cost Estimation";
  else if (
    selectedEstimate?.depositType ===
    EstimateTypeForDeposit.Variable_Cost_Estimation
  )
    return "Variable Cost Estimation";
  else return "";
};

onMounted(async () => {
  isLoading.value = true;
  try {
    if (!getAllSpecialism.value.length) {
      await store.dispatch(
        `${USER_STORE}/setAllSpecialismsCategory`,
        activeUserWorkstation.value.id
      );
    }
    await store.dispatch(`${JOBS_STORE}/fetchHistoricalProjects`);
    console.log("historicProjects", historicProjects.value);
  } catch (error) {
    console.log("error", error);
  } finally {
    isLoading.value = false;
  }
});
const openNewProject = () => {
  isOpenNewProject.value = true;
};
const onClickBackButton = () => {
  isOpenNewProject.value = false;
};
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
}

.close-banner {
  position: absolute;
  top: 10px;
  right: 10px;
}
.historic_project {
  .project_banner {
    width: 100%;
    padding: 16px;
    text-align: left;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    border-radius: 8px;
    margin: 24px 0 0;
    background-color: rgba($white, 1);
    position: relative;
    .project_banner__header {
      display: flex;
      gap: 8px;
      align-items: center;
      h5 {
        @include fluidFont(16, 16, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
        letter-spacing: 0.15px;
      }
    }
    .project_banner__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond(s720) {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 22px 0 0;
      }
      .left__block {
        @include respond(s720) {
          margin-top: 22px;
        }
        h4 {
          @include fluidFont(20, 20, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
        p {
          margin-top: 8px;
          @include fluidFont(14, 14, 1.2);
          font-weight: 500;
          color: rgba($blueDark, 0.5);
          letter-spacing: 0.15px;
          max-width: 500px;
        }
      }
      .right__block {
        margin-right: 150px;
        @include respond(md) {
          margin: 0;
        }
        @include respond(s720) {
          margin: 22px 0 0;
        }
        .close_icon {
          position: absolute;
          right: 16px;
          top: 16px;
          cursor: pointer;
          color: rgba($blueDark, 0.5);
        }
      }
    }
  }
  .content_field {
    margin: 24px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input_field {
      margin: 0;
      width: 100%;
      max-width: 216px;
      :deep(.v-input__control) {
        .v-field {
          border: 1px solid rgba($blueDark, 0.2);
          border-radius: 8px;
          padding: 12px 16px;
          .v-field__field {
            .v-field__input {
              background-color: transparent;
              border: 0;
              border-radius: 0;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              top: 0;
              padding: 0;
              min-height: auto;
              &::placeholder {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
              }
            }
          }
          .v-field__prepend-inner {
            height: 16px;
            .v-icon {
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
    }
  }
  .historic_project__card_list {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    flex-wrap: wrap;
    .v-card {
      width: calc(33.33% - 11px);
      max-width: 100%;
      padding: 20px 16px 0;
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border-radius: 8px;
      @include respond(s1024) {
        width: calc(50% - 8px);
      }
      @include respond(s720) {
        width: 100%;
      }
      .card__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        .card__header__img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .right__block {
          display: flex;
          gap: 16px;
          align-items: center;
          .heart_icon {
            width: 30px;
            height: 30px;
            box-shadow: 0px 0px 7.5px 0px #0c0f4a14;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .card__content {
        text-align: left;
        width: 100%;
        max-width: 100%;
        .card__content__header {
          padding-bottom: 8px;
          border-bottom: 1px solid rgba($cardBorder, 1);
          h5 {
            @include fluidFont(20, 20, 1.3);
            font-weight: 700;
            color: rgba($blueDark, 1);
          }
          .card__info {
            display: inline-flex;
            gap: 12px;
            align-items: center;
            .card__info__left {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              span {
                @include fluidFont(12, 12, 1.3);
                font-weight: 600;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
                .v-icon {
                  @include fluidFont(10, 10, 1.3);
                }
              }
            }
            .card__info__right {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
              .v-icon {
                @include fluidFont(16, 16, 1);
              }
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
              }
            }
          }
        }
        .card__info__tags {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 0;
          border-bottom: 1px solid rgba($cardBorder, 1);
          @include respond(s1800) {
            flex-wrap: wrap;
          }
          @include respond(s1024) {
            flex-wrap: nowrap;
          }
          @include respond(md) {
            flex-wrap: wrap;
          }
          @include respond(s720) {
            flex-wrap: nowrap;
          }
          @include respond(sm) {
            flex-wrap: wrap;
          }
          .card__info__tags__default {
            padding: 4px 8px;
            display: inline-flex;
            align-items: center;
            gap: 4px;
            background-color: rgba($blueDark, 0.04);
            border-radius: 8px;
            width: 100px;
            max-width: 100%;
            p {
              @include fluidFont(12, 12, 2);
              font-weight: 700;
              color: rgba($success, 1);
              letter-spacing: 0.15px;
              span {
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
            }
          }
          .card__info__tags__custom {
            display: flex;
            gap: 8px;
            align-items: center;
            width: calc(100% - 100px);
            max-width: 100%;
            @include respond(s1800) {
              width: 100%;
              flex-wrap: wrap;
            }
            @include respond(s1024) {
              width: calc(100% - 100px);
              flex-wrap: nowrap;
            }
            @include respond(md) {
              width: 100%;
              flex-wrap: wrap;
            }
            @include respond(s720) {
              width: calc(100% - 100px);
            }
            @include respond(sm) {
              width: 100%;
              flex-wrap: nowrap;
            }
            .custom_tags {
              display: flex;
              gap: 4px;
              align-items: center;
              background-color: rgba($blueDark, 0.04);
              border-radius: 8px;
              padding: 4px 8px;
              // width: 33.33%;
              .v-icon {
                width: 16px;
                height: 16px;
                border-radius: 100%;
                overflow: hidden;
                background-color: rgba($blueDark, 1);
                img {
                  filter: invert(99%) sepia(0%) saturate(3735%)
                    hue-rotate(87deg) brightness(119%) contrast(100%);
                  width: 10px;
                }
              }
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                display: inline-block;
                width: calc(100% - 25px);
                max-width: 100px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @include respond(sm) {
                  max-width: 70px;
                }
              }
            }
            .custom_tags__more {
              display: inline-flex;
              gap: 4px;
              align-items: center;
              background-color: rgba($blueDark, 0.04);
              border-radius: 8px;
              padding: 4px 8px;
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                display: inline-block;
                width: 100%;
                max-width: 100%;
                white-space: nowrap;
              }
            }
          }
        }
        .card_project_summary {
          padding: 8px 0;
          border-bottom: 1px solid rgba($cardBorder, 1);
          .card_project_summary__list {
            display: flex;
            align-items: center;
            .card_project_summary__list_item {
              width: 33.33%;
              max-width: 100%;
              text-align: center;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                right: 0;
                border-right: 1px solid rgba($cardBorder, 1);
                width: 1px;
                height: 100%;
              }
              &:last-child {
                &::before {
                  border: none;
                }
              }
              p {
                @include fluidFont(12, 12, 2);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.15px;
              }
              span {
                @include fluidFont(14, 14, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
            }
          }
        }
      }
      .card__footer {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.sort__wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .sort__btn {
    padding: 0;
    background-color: rgba($blueDark, 0.05);
    border-radius: 100%;
    width: 24px;
    min-width: 24px;
    height: 24px !important;
    box-shadow: none;
    .v-ripple__container {
      display: none !important;
    }
  }
}

.sort_dropdown_wrapper {
  position: relative;
}

.v-menu > .v-overlay__content > .v-list.sort_dropdown_inner {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.0784313725);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  height: unset;
  overflow: visible;
  margin-top: 24px;
  width: 185px;
  max-width: 100%;
  margin-left: auto;

  &:before {
    right: 5px;
    top: -23px;
    height: 30px;
    width: 40px;
    position: absolute;
    pointer-events: none;
    margin-top: 0;
    content: "";
    background-image: url(../../../assets/icons/triangle.svg);
    // filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(219deg)
    //   brightness(102%) contrast(101%);
  }

  .v-list-item {
    padding: 0;
    border-radius: 8px !important;
    min-height: auto;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    :deep(.v-list-item__content) {
      .v-input {
        .v-input__control {
          .v-selection-control-group {
            gap: 8px;
            .v-selection-control {
              --v-selection-control-size: auto;
              gap: 8px;
              .v-label {
                @include fluidFont(16, 16, 1.3);
                color: rgba($blueDark, 1);
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .v-list-item-title {
      @include fluidFont(14, 14, 1);
      color: #0c0f4a;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: background-color 0.3s ease, color 0.3s ease;

      .v-icon {
        color: #4f55f0;
        font-size: 16px;
      }
    }
  }
}

/* Empty Screen */

.empty-screen {
  padding: 50px 0;
  color: $blueDark;

  &__inner {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }

  &__image {
    max-width: 372px;
    width: 100%;
    margin: 0 auto 46px;

    @media (max-width: 767px) {
      max-width: 278px;
      margin: 0 0 24px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    @include respond(s720) {
      margin: 0 auto;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 36px;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.15px;
    font-weight: 500;
    color: rgba($blueDark, 0.5);

    @media (max-width: 767px) {
      text-align: left;
    }

    h2 {
      @include fluidFont(32, 28, 30px);
      color: $blueDark;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }

  &__content {
    padding: 0 28px;
    text-align: left;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.4px;
    display: grid;
    grid-gap: 8px;

    @media (max-width: 767px) {
      padding: 0;
    }

    &-item {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 8px;
      align-items: center;

      .mdi {
        @include fluidFont(24, 24, 1);
        color: rgba($skyBlue, 1);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &__footer {
    margin-top: 36px;
  }
}
</style>
