<template>
  <main class="template-page-main">
    <div class="estimate__template md:!tw-mt-4" v-if="!isShowTemplate">
      <div class="estimate__header">
        <div class="title">
          <h4>Template</h4>

          <v-btn
            prepend-icon="mdi-plus-circle"
            class="button button-orange"
            @click="toggleShowTemplate"
          >
            New Template
          </v-btn>
        </div>
        <p>
          To reuse a template, simply select it from the list when creating a
          new job estimate. You can also manage your templates by editing or
          deleting them as needed.
        </p>
      </div>
      <div class="estimate__content">
        <div class="estimate__count">
          <p>
            {{
              jobTemplates && jobTemplates?.length ? jobTemplates?.length : 0
            }}
          </p>
          <span>Template</span>
        </div>
        <div
          class="estiamte__table md:!tw-hidden"
          v-if="!loading && jobTemplates && jobTemplates.length"
        >
          <v-table density="compact">
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Last Created By</th>
                <th>Last Edited On</th>
                <th>Edited last date & Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="template in jobTemplates" :key="template?.id">
                <td scope="row">{{ template?.name }}</td>
                <td>
                  <div class="estimate__profile">
                    <div class="estimate__profile__img">
                      <img
                        v-if="userWorkStationImage"
                        :src="userWorkStationImage"
                        alt="userWorkStationImage"
                      />
                      <img
                        v-if="!userWorkStationImage"
                        :src="require('@/assets/images/avatar-info-image.png')"
                        alt="userWorkStationImage"
                      />
                    </div>
                    <p>{{ template?.createdByUserWorkstation?.name }}</p>
                  </div>
                </td>
                <td>
                  <div class="estimate__profile">
                    <div class="estimate__profile__img">
                      <img
                        v-if="userWorkStationImage"
                        :src="userWorkStationImage"
                        alt="userWorkStationImage"
                      />
                      <img
                        v-if="!userWorkStationImage"
                        :src="require('@/assets/images/avatar-info-image.png')"
                        alt="userWorkStationImage"
                      />
                    </div>
                    <p>{{ template?.updatedByUserWorkstation?.name }}</p>
                  </div>
                </td>
                <td>
                  <span>
                    {{
                      moment(template?.dateUpdated).format(
                        "DD MMM-YYYY, hh:mm A"
                      )
                    }}
                  </span>
                </td>
                <td>
                  <div class="estiamte_btns">
                    <v-btn
                      icon="mdi-pencil"
                      class="edit__wrapper"
                      @click="updateTemplate(template)"
                    ></v-btn>
                    <!-- <v-btn
                      icon="mdi-delete"
                      class="delete__wrapper"
                      @click="removeTemplate(template?.id)"
                    ></v-btn> -->
                    <v-btn
                      icon="mdi-delete"
                      class="delete__wrapper"
                      @click="onConfirmDelete(template?.id)"
                    ></v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
          <div v-if="!jobTemplates?.length" class="empty_templates">
            <span>Not Found Templates</span>
          </div>
        </div>
        <div
          class="template-wrapper tw-w-full tw-grid tw-gap-4 tw-pt-4 rmd:!tw-hidden"
          v-if="!loading && jobTemplates && jobTemplates.length"
        >
          <div
            v-for="template in jobTemplates"
            :key="template?.id"
            class="template-item tw-w-full tw-rounded-lg tw-p-4 tw-box-border tw-bg-white tw-flex tw-flex-col tw-gap-4"
          >
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="p" textColor="rgba(12, 15, 74, 0.5)"
                >Template Name</Text
              >
              <Text variant="span">{{ template?.name }}</Text>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="p" textColor="rgba(12, 15, 74, 0.5)"
                >Last Created By</Text
              >
              <div class="tw-flex tw-items-center tw-gap-2">
                <!-- image here -->
                <ProfileThumbnail
                  :isRounded="false"
                  v-if="userWorkStationImage"
                  :src="userWorkStationImage"
                  size="mmd"
                />
                <ProfileThumbnail
                  :isRounded="false"
                  v-if="!userWorkStationImage"
                  :src="require('@/assets/images/avatar-info-image.png')"
                  size="mmd"
                />
                <Text variant="span">{{
                  template?.createdByUserWorkstation?.name
                }}</Text>
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="p" textColor="rgba(12, 15, 74, 0.5)"
                >Last Edited On</Text
              >
              <div class="tw-flex tw-items-center tw-gap-2">
                <!-- image here -->
                <ProfileThumbnail
                  :isRounded="false"
                  v-if="userWorkStationImage"
                  :src="userWorkStationImage"
                  size="mmd"
                />
                <ProfileThumbnail
                  :isRounded="false"
                  v-if="!userWorkStationImage"
                  :src="require('@/assets/images/avatar-info-image.png')"
                  size="mmd"
                />
                <Text variant="span">{{
                  template?.updatedByUserWorkstation?.name
                }}</Text>
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="p" textColor="rgba(12, 15, 74, 0.5)"
                >Edited last date & Time</Text
              >
              <Text variant="span">{{
                moment(template?.dateUpdated).format("DD MMM-YYYY, hh:mm A")
              }}</Text>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <Text variant="p" textColor="rgba(12, 15, 74, 0.5)">Action</Text>
              <div class="tw-flex tw-gap-2 tw-items-center">
                <!-- icons here -->
                <v-btn
                  icon="mdi-pencil"
                  class="edit__wrapper tw-bg-[#4a92e5]"
                  @click="updateTemplate(template)"
                ></v-btn>
                <v-btn
                  icon="mdi-delete"
                  class="delete__wrapper tw-bg-[#f45454]"
                  @click="onConfirmDelete(template?.id)"
                ></v-btn>
              </div>
            </div>
          </div>
        </div>
        <CommonLoader v-if="loading" :loading="loading" />
      </div>
    </div>
    <main
      v-else
      class="tw-h-full tw-w-full"
      :class="{
        // 'tw-grid tw-grid-cols-2 lg:tw-grid-cols-1': isShowTemplate,
        'tw-h-full tw-w-full tw-grid tw-grid-cols-3 lg:tw-grid-cols-1':
          isShowTemplate,
      }"
    >
      <SandBoxEstimateScreen
        :estimateForm="estimateForm"
        :templateData="templateData"
        :isFirstTimeCreatingEstimate="isFirstTimeCreatingEstimate"
        @on-save-template="onToggleSaveTemplateModal"
        @on-load-template="onToggleLoadTemplateModal"
        @on-update-template="toggleUpdateTemplateModal"
        @on-back="onBack"
      />
      <section class="tw-box-border lg:tw-pl-0 md:tw-pt-4 tw-pl-6">
        <SandBoxEstimateSummaryCard
          :templateData="templateData"
          :isFirstTimeCreatingEstimate="isFirstTimeCreatingEstimate"
          @on-save-template="onToggleSaveTemplateModal"
          @on-edit-template="onEditTemplate"
        />
      </section>

      <SaveTemplateModal
        v-if="saveTemplateModal"
        @on-save="onSaveTemplate"
        @on-close="onToggleSaveTemplateModal"
      />
      <LoadTemplateModal
        v-if="loadTemplateModal"
        @on-close="onToggleLoadTemplateModal"
      />
    </main>

    <GeneralDialog
      v-if="isUpdateTemplateModel && templateData"
      headerTitle="Update estimate a template."
      headerDescription="Update Estimate Template  a name."
      @on-close="toggleUpdateTemplateModal"
    >
      <template #body>
        <div class="tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-2">
          <v-form
            :rules="validation"
            ref="templateNameFormRef"
            class="tw-w-full"
            @submit.prevent
          >
            <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <Text variant="span" textWeight="400">Template Name</Text>
              <v-text-field
                density="compact"
                :rules="validation.templateName"
                v-model="templateData.name"
                variant="outlined"
                class="tw-rounded-[8px]"
              ></v-text-field>
            </div>
          </v-form>
        </div>
      </template>
      <template #footer>
        <div
          class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
        >
          <Button
            variant="secondary"
            label="Save Estimate Template"
            @click="onSave"
          />
        </div>
      </template>
    </GeneralDialog>

    <ConfirmEstimateTemplateDeleteModal
      v-if="confirmDeleteModal"
      @on-close="onCloseConfirmDeleteModal"
      @on-confirm="removeTemplate(seletectedTemplateId)"
    />
  </main>
</template>
<script setup>
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import moment from "moment";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import SandBoxEstimateScreen from "../components/screens/SandBoxEstimateScreen.vue";
import SandBoxEstimateSummaryCard from "../components/estimates/SandBoxEstimateSummaryCard.vue";
import SaveTemplateModal from "../components/modal/SaveTemplateModal.vue";
import LoadTemplateModal from "../components/modal/LoadTemplateModal.vue";
import { onBeforeUnmount } from "vue";
import { ElMessage } from "element-plus";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { reactive } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import ConfirmEstimateTemplateDeleteModal from "../components/modal/ConfirmEstimateTemplateDeleteModal.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();
const userWorkStationImage = computed(
  () => store.getters[`${USER_STORE}/userWorkStationImage`]
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const emits = defineEmits(["on-save-template", "on-back"]);
const props = defineProps({
  isFirstTimeCreatingEstimate: { type: Boolean, default: false },
});
const jobTemplates = ref([]);
const getJobTemplates = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getJobTemplates`]
);
const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];
  if (estimates && estimates.projectStages.length) {
    if (
      estimates &&
      estimates.projectStages.length &&
      !estimates.projectStages[0].user
    ) {
      estimates.projectStages[0].user = {
        id: user.value?.id,
      };
    }
    if (
      estimates &&
      estimates.projectStages.length &&
      !estimates.projectStages[0].workStation
    ) {
      estimates.projectStages[0].workStation = {
        id: activeUserWorkstation.value?.id,
      };
    }
  }
  return estimates;
});
const saveTemplateModal = ref(false);
const loadTemplateModal = ref(false);
const templateData = ref(null);
const isUpdateTemplateModel = ref(false);
const loading = ref(false);
const toggleUpdateTemplateModal = () => {
  isUpdateTemplateModel.value = !isUpdateTemplateModel.value;
};
const state = reactive({
  form: {
    templateName: "",
  },
});

const confirmDeleteModal = ref(false);
const seletectedTemplateId = ref(null);
const loadingDelete = ref(false);

const templateNameFormRef = ref(null);
const validation = ref({
  templateName: [(v) => !!v || "Template name is required"],
});
const onToggleSaveTemplateModal = () => {
  saveTemplateModal.value = !saveTemplateModal.value;
};

const onCloseConfirmDeleteModal = () => {
  confirmDeleteModal.value = false;
};

const onOpenConfirmDeleteModal = () => {
  confirmDeleteModal.value = true;
};

const onConfirmDelete = (id) => {
  seletectedTemplateId.value = id;
  if (seletectedTemplateId.value) {
    onOpenConfirmDeleteModal();
  }
};

const onSaveTemplate = async () => {
  toggleShowTemplate();
  await store.dispatch(`${JOB_TEMPLATE_STORE}/getMyJobTemplates`);
  onToggleSaveTemplateModal();
  jobTemplates.value = getJobTemplates.value;
};
const onToggleLoadTemplateModal = () => {
  loadTemplateModal.value = !loadTemplateModal.value;
};
const isShowTemplate = ref(props.isFirstTimeCreatingEstimate);
const toggleShowTemplate = () => {
  isShowTemplate.value = !isShowTemplate.value;
};
const onBack = () => {
  templateData.value = null;
  store.dispatch(`${JOB_TEMPLATE_STORE}/resetFormAndData`);
  if (props.isFirstTimeCreatingEstimate) {
    emits("on-back");
  } else {
    toggleShowTemplate();
  }
};
// watch(
//   jobTemplates,
//   async (newValue, oldValue) => {
//     await store.dispatch(`${JOB_TEMPLATE_STORE}/getMyJobTemplates`);
//     jobTemplates.value = getJobTemplates.value;
//   },
//   { deep: true, immediate: true }
// );
const removeTemplate = async (templateId) => {
  try {
    loadingDelete.value = true;
    await store.dispatch(
      `${JOB_TEMPLATE_STORE}/deleteMyJobTemplate`,
      templateId
    );
    jobTemplates.value = jobTemplates.value.filter(
      (template) => template.id !== templateId
    );
  } catch (error) {
    console.error(error);
  } finally {
    seletectedTemplateId.value = null;
    loadingDelete.value = false;
    onCloseConfirmDeleteModal();
  }
};
const updateTemplate = (template) => {
  templateData.value = template;
  toggleShowTemplate();
};

const estimateFormData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const onEditTemplate = async () => {
  try {
    const estimateData = estimateForm.value;
    const metaData = estimateData.projectStages.map((phase) => {
      const tasks = phase.projectStageTasks.map((task) => {
        return {
          ...task,
          id: null,
          estimationPhaseTasksProperties: [],
        };
      });
      return {
        ...phase,
        id: null,
        attachments: [],
        estimationPhaseProperties: [],
        assignPhaseWorkStation: null,
        acceptedBy: null,
        projectStageTasks: tasks,
        user: null,
        workStation: null,
      };
    });

    const formData = {
      name: templateData.value?.name,
      meta: { projectStages: metaData, formData: estimateFormData.value },
    };
    await store.dispatch(`${JOB_TEMPLATE_STORE}/updateMyJobTemplate`, {
      id: templateData.value?.id,
      formData,
    });
    ElMessage.success("updated Template");
  } catch (error) {
    console.log(error);
  } finally {
    toggleShowTemplate();
  }
};
onMounted(async () => {
  loading.value = true;
  await store.dispatch(`${JOB_TEMPLATE_STORE}/getMyJobTemplates`);
  jobTemplates.value = getJobTemplates.value;
  if (jobTemplates.value) {
    loading.value = false;
  }
});
onBeforeUnmount(() => {
  if (!props.isFirstTimeCreatingEstimate) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/resetFormAndData`);
  }
});
</script>

<style lang="scss" scoped>
@import "@/modules/jobs/styles/EstimateTemplate.scss";

.template-page-main {
  padding-right: 1rem;
}

.template-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.template-item {
  border: 1px solid rgba($bordercolor, 1);
  box-shadow: none !important;
}

:deep(.mdi-pencil) {
  color: white;
}

:deep(.mdi-delete) {
  color: white;
}
</style>
