export enum UserMessagesRoomStatusEnum {
  PENDING = "pending",
  REJECTED = "rejected",
  ACCEPTED = "accepted",
  COMPLETED = "completed",
}

export enum UserMessagesGroupTypeEnum {
  USER = 1,
  WORKSTATION = 0,
}

export enum RoomPanel {
  MEMBERS = 0,
  PROJECT_CHAT = 1,
  GROUP_CHAT = 2,
  WORKSTATION_CHAT = 3,
}

export enum ChatTypeEnum {
  DISPUTE_PROJECT = "dispute_project",
  PROJECT = "project",
}
