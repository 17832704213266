<template>
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3108 14.6579L7.8225 9.13956C8.50187 8.30861 8.50187 6.9663 7.8225 6.13535L3.3108 0.616973C2.21336 -0.704028 0.332031 0.233457 0.332031 2.12973V13.1452C0.332031 15.0628 2.21336 16.0002 3.3108 14.6579Z"
      fill="#0C0F4A"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowMenuRight",
};
</script>
