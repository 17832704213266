<template>
  <main
    class="dashboard-sec !tw-flex !tw-flex-col tw-gap-6 tw-pb-8 tw-box-border rmd:tw-gap-6 tw-relative md:!tw-pb-16"
    :class="{
      '!tw-items-center !tw-justify-center md:!tw-gap-0':
        isUserWorkstationIsDefault,
    }"
  >
    <!-- KYC/KYB NOTICE For Solo trader -->
    <div
      v-if="
        (getVerificationStatus.statusClass === 'info' &&
          !isUserWorkstationIsDefault &&
          !isWorkstationLoading &&
          activeUserWorkstation?.verificationStatus !==
            UserVerificationStatusEnum.VERIFIED &&
          accountStatuses?.forms?.insurance !== 0 &&
          accountStatuses?.forms?.certification !== 0 &&
          userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
          accountStatuses?.forms?.skills !== 0 &&
          getUserWorkStationFieldAccess) ||
        (activeUserWorkstation?.verificationStatus ===
          UserVerificationStatusEnum.VERIFIED &&
        userBusinessType === BusinessDetailTypeEnum.SOLETRADER
          ? activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED
          : false)
      "
      class="tw-w-full tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
    >
      <NoticeIcon />
      <Text
        v-if="
          !activeUserWorkstation?.user?.userBusinessDetails
            ?.residencyPostcode &&
          userBusinessType === BusinessDetailTypeEnum.SOLETRADER
        "
        variant="span"
        textColor="#0C0F4A"
        >Action Required: Add Business Address.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickAddBusinessAddressModal"
          >Manage Address</Text
        ></Text
      >
      <Text
        v-if="
          activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          activeUserWorkstation?.user?.userBusinessDetails?.residencyPostcode &&
          userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
          activeUserWorkstation?.verificationStatus !==
            UserVerificationStatusEnum.PENDING_VERIFICATION
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete ID Verification.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickIDVerificationModal"
          >Get Verified</Text
        ></Text
      >

      <Text
        v-if="
          (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
            ? activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode
            : activeUserWorkstation?.user?.userBusinessDetails
                ?.residencyPostcode) &&
          (activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION ||
            activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.VERIFIED) &&
          !isUserProfileVerified
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete Your Trade Passport.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickManageTradePassportPreviewModal"
          >Manage Trade Passport</Text
        ></Text
      >
      <Text
        v-if="
          activeUserWorkstation?.verificationStatus ==
            UserVerificationStatusEnum.NOT_ADDED &&
          isUserProfileVerified &&
          activeUserWorkstation?.user?.userBusinessDetails?.residencyPostcode &&
          activeUserWorkstation?.user?.userKYCVerificationStatus !==
            UserVerificationStatusEnum.NOT_ADDED
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete Workstation Verification.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickSubmitVerificationPreviewModal"
          >Get Verified</Text
        ></Text
      >
      <Text
        v-if="
          activeUserWorkstation?.verificationStatus ==
            UserVerificationStatusEnum.PENDING_VERIFICATION &&
          isUserProfileVerified &&
          activeUserWorkstation?.user?.userBusinessDetails?.residencyPostcode
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Verification Pending.
      </Text>
    </div>

    <!-- KYC/KYB NOTICE For Limited Company -->

    <div
      v-if="
        (getVerificationStatus.statusClass === 'info' &&
          !isUserWorkstationIsDefault &&
          !isWorkstationLoading &&
          activeUserWorkstation?.verificationStatus !==
            UserVerificationStatusEnum.VERIFIED &&
          accountStatuses?.forms?.insurance !== 0 &&
          accountStatuses?.forms?.certification !== 0 &&
          userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
          accountStatuses?.forms?.skills !== 0 &&
          getUserWorkStationFieldAccess) ||
        (activeUserWorkstation?.verificationStatus ===
          UserVerificationStatusEnum.VERIFIED &&
          userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
          !WorkStationSiltVerificationStatus)
      "
      class="tw-w-full tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
    >
      <NoticeIcon />
      <Text
        v-if="
          !activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode &&
          userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
        "
        variant="span"
        textColor="#0C0F4A"
        >Action Required: Add Business Address.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickAddBusinessAddressModal"
          >Manage Address</Text
        ></Text
      >

      <Text
        v-if="
          !WorkStationSiltVerificationStatus &&
          activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode &&
          userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
          userWorkStationStatus !==
            UserVerificationStatusEnum.PENDING_VERIFICATION
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete ID & Business Verification.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickIDVerificationModal"
          >Get Verified</Text
        ></Text
      >
      {{ WorkStationSiltVerificationStatus }}
      <Text
        v-if="
          (WorkStationSiltVerificationStatus &&
          userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
            ? activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode
            : activeUserWorkstation?.user?.userBusinessDetails
                ?.residencyPostcode) &&
          (activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION ||
            activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.VERIFIED) &&
          !isUserProfileVerified
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete Your Trade Passport.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickManageTradePassportPreviewModal"
          >Manage Trade Passport</Text
        ></Text
      >
      <Text
        v-if="
          !isUserWorkstationIsDefault &&
          isUserVerifiedKYCAndKYB &&
          isUserProfileVerified &&
          (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
            ? activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode
            : activeUserWorkstation?.user?.userBusinessDetails
                ?.residencyPostcode) &&
          userWorkStationStatus === UserVerificationStatusEnum.NOT_ADDED
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Complete Workstation Verification.
        <Text
          class="tw-cursor-pointer tw-underline"
          variant="span"
          textColor="#4B4BFF"
          @click="onClickSubmitVerificationPreviewModal"
          >Get Verified</Text
        ></Text
      >
      <Text
        v-if="
          !isUserWorkstationIsDefault &&
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION &&
          isUserProfileVerified &&
          (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
            ? activeUserWorkstation?.user?.userBusinessDetails?.businessPostcode
            : activeUserWorkstation?.user?.userBusinessDetails
                ?.residencyPostcode)
        "
        variant="span"
        textColor="#FA4B00"
        >Action Required: Verification Pending.
      </Text>
    </div>

    <template
      v-if="
        !isUserWorkstationIsDefault &&
        coursesWithRemainingSlots?.length &&
        activeUserWorkstation?.verificationStatus ===
          UserVerificationStatusEnum.VERIFIED &&
        !userInsurenceExperiedStatus &&
        !isWorkstationLoading
      "
    >
      <div
        v-for="courses in coursesWithRemainingSlots.slice(0, 1)"
        :key="courses?.id"
        class="note__wrapper"
      >
        <v-icon icon="mdi-information" />
        <p>
          assign members to the available spots for the
          {{ courses?.course?.name }}.
          <a @click.prevent="toggleAssignCourseSlotToMemberModal(courses?.id)"
            >Assign members</a
          >
        </p>
      </div>
    </template>

    <!-- LOADER -->
    <div
      v-if="isWorkstationLoading"
      class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[600px] md:!tw-mt-[301px]"
    >
      <Loader :show="isWorkstationLoading" />
    </div>

    <!-- BANNER -->

    <div class="profile__header">
      <Card
        v-if="!isUserWorkstationIsDefault && !isWorkstationLoading"
        :withBorder="true"
        :isRoundedxl="true"
        class="!tw-max-h-[unset] tw-w-full !tw-py-6 !tw-px-4 rmd:!tw-py-8 rmd:!tw-px-6 tw-box-border tw-relative"
      >
        <template #content>
          <main class="tw-w-full">
            <!-- PROFILE DETAILS BANNER -->
            <WorkStationVerificationLock
              v-if="
                userWorkStationStatus ===
                  UserVerificationStatusEnum.PENDING_VERIFICATION &&
                !isWorkstationLoading
              "
            />
            <section
              v-if="!isUserWorkstationIsDefault"
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-4 tw-justify-start rmd:tw-flex-row rmd:tw-justify-between"
            >
              <div
                class="tw-flex tw-flex-col tw-gap-5 tw-items-start md:!tw-w-full"
              >
                <div
                  class="tw-w-full tw-flex tw-items-center tw-gap-3 rmd:tw-gap-2"
                >
                  <ProfileThumbnail
                    :isRounded="true"
                    :desktopFontSizeBig="true"
                    class="ws-profile-logo"
                    size="xllg"
                    v-if="userWorkStationImage"
                    :src="userWorkStationImage"
                    :isVerified="
                      activeUserWorkstation?.verificationStatus ===
                      UserVerificationStatusEnum.VERIFIED
                    "
                    :showVerificationIcon="true"
                    :userKycStatus="userVerifyStatus"
                  />
                  <div
                    v-else
                    class="tw-rounded-full tw-max-h-[104px] tw-max-w-[104px] tw-min-h-[104px] tw-min-w-[104px] tw-h-[104px] tw-relative"
                  >
                    <ChipState
                      bgColor="#FFF"
                      :hasShadow="true"
                      class="tw-absolute tw-top-[-.3rem] tw-right-[-.4rem] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
                      v-if="userVerifyStatus != 0"
                    >
                      <template #prefix>
                        <el-tooltip effect="light" placement="bottom-start">
                          <template #content
                            ><div
                              class="tw-max-w-[200px]"
                              v-html="toolTipProfile"
                            ></div
                          ></template>
                          <CheckGreenShield v-if="userVerifyStatus == 2" />
                          <InfoIconRed v-else :color="`#faa500`" />
                        </el-tooltip>
                      </template>
                    </ChipState>

                    <UserProfileLogo
                      class="ws-profile-logo !tw-w-full !tw-h-full"
                      :desktopFontSizeBig="true"
                      :isRounded="false"
                      :mobileFontSizeBig="true"
                    />
                  </div>
                  <div class="tw-flex tw-flex-col tw-items-start">
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <Text class="rmd:!tw-text-2xl" variant="h6">{{
                        activeUserWorkstation?.name
                      }}</Text>
                      <!-- <ChipState
                        class="tw-border-solid tw-border-[1px] tw-border-[#0C0F4A] tw-cursor-pointer tw-h-[25px] md:tw-hidden"
                        label="VIEW TRADE PASSPORT"
                        textColor="#0C0F4A"
                        :isDense="true"
                        @click="onClickManageTradePassport"
                      ></ChipState> -->
                    </div>
                    <div
                      class="tw-flex tw-items-start tw-gap-0 rmd:tw-gap-1"
                      v-if="
                        getWorkstationAddress(
                          activeUserWorkstation?.user?.userBusinessDetails?.type
                        ) &&
                        getWorkstationPostcode(
                          activeUserWorkstation?.user?.userBusinessDetails?.type
                        )
                      "
                    >
                      <Text
                        class="!tw-text-[10px] md:!tw-text-[11px] !tw-leading-4 !tw-mt-1.5"
                        textWeight="400"
                        textColor="#0C0F4A"
                        whiteSpace="pre-wrap"
                        >{{
                          getWorkstationAddress(
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.type
                          )
                        }}</Text
                      >
                      <Postcode
                        :value="
                          getWorkstationPostcode(
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.type
                          )
                        "
                      />
                    </div>
                    <div
                      v-else-if="getUserWorkStationFieldAccess"
                      @click="onClickAddress"
                      class="tw-flex tw-gap-1 tw-items-center tw-cursor-pointer"
                    >
                      <Text textColor="#FFA500" variant="p">+</Text>
                      <Text textColor="#FFA500" variant="span"
                        >Add Address</Text
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="view-rating-box md:!tw-gap-[4px] !tw-justify-between md:!tw-max-w-[unset] md:!tw-w-full md:!tw-p-[.5rem]"
                >
                  <div
                    class="tw-flex tw-items-center tw-gap-[8px] md:!tw-gap-[4px]"
                  >
                    <v-rating
                      active-color="orange"
                      v-model="getAverageOfAllProjectReview"
                      color="rgba(12, 15, 74, 0.26)"
                      empty-icon="mdi-star"
                      readonly
                    ></v-rating>
                    <p class="md:!tw-text-[12px]">
                      {{ getAverageOfAllProjectReview }} out of 5
                    </p>
                  </div>

                  <v-btn
                    class="button button-orange"
                    append-icon="mdi-chevron-right"
                    @click="onClickViewRatings"
                    >view Ratings
                  </v-btn>
                </div>
              </div>

              <div>
                <ChipState
                  class="tw-border-solid tw-border-[1px] tw-border-[#0C0F4A] tw-cursor-pointer tw-h-[25px] tw-hidden md:tw-block md:tw-ml-auto"
                  label="VIEW TRADE PASSPORT"
                  textColor="#0C0F4A"
                  :isDense="true"
                  @click="onClickManageTradePassport"
                >
                </ChipState>
              </div>
              <div
                class="tw-flex tw-flex-col tw-w-full tw-items-center tw-justify-between tw-relative rsm:tw-items-end rsm:tw-w-[auto] md:tw-hidden tw-min-h-[130px] tw-gap-8"
              >
                <div class="custom__chip">
                  <img src="../../../assets/icons/user.svg" alt="" />
                  <span>{{
                    activeWorkStationType(user?.userBusinessDetails?.type)
                  }}</span>
                </div>

                <!-- Slit Verification -->

                <SiltRequiredChip
                  v-if="
                    !WorkStationSiltVerificationStatus &&
                    userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
                  "
                />

                <!-- <SiltPendingChip
                  v-if="
                    WorkStationSiltVerificationStatus &&
                    getVerificationStatus.statusClass !== 'success' &&
                    userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
                  "
                  :label="getVerificationStatus.label"
                />

                <SiltVerifiedChip
                  v-if="
                    WorkStationSiltVerificationStatus &&
                    getVerificationStatus.statusClass === 'success' &&
                    userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
                  "
                  :label="getVerificationStatus.label"
                />
-->

                <ProfileVerificationStatusChip
                  v-if="
                    userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
                    userVerifyStatus === UserVerificationStatusEnum.NOT_ADDED
                  "
                  class="tw-hidden rmd:tw-flex"
                  :variant="userWorkstationStatusByWord"
                />

                <!-- <v-btn
                  append-icon="mdi-chevron-right"
                  variant="plain"
                  class="blue-text"
                  height="auto"
                  @click="onClickManageTradePassport"
                >
                  Manage Trade Passport</v-btn
                > -->

                <Button
                  class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                  variant="custom"
                  @click="onClickManageTradePassport"
                  label="Manage Trade Passport"
                  activeBgColor="transparent"
                  width="auto"
                  :isCapitalize="true"
                  :fontWeight="700"
                >
                  <template #postfix>
                    <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                  </template>
                </Button>

                <!-- <TradePassportActionChip
                class="!tw-w-full"
                @click="onClickManageTradePassport"
              />
              <Text
                v-if="
                  userWorkStationStatus === UserVerificationStatusEnum.VERIFIED
                "
                class="!tw-text-[10px] tw-cursor-pointer rmd:tw-absolute rmd:tw-right-0 rmd:tw-bottom-[-1.8rem]"
                textColor="#000"
                whiteSpace="nowrap"
                >Looking To Upskill?
                <Text
                  @click="navigateToCourse"
                  class="!tw-text-[10px]"
                  textColor="#FFA500"
                  whiteSpace="nowrap"
                  >Explore Our Courses</Text
                ></Text
              > -->
              </div>
            </section>
          </main>
        </template>
      </Card>
    </div>
    <!-- CONTROL CENTER -->
    <section
      v-if="
        isUserWorkstationIsDefault &&
        activeUserWorkstation &&
        !isWorkstationLoading
      "
      class="tw-flex tw-flex-col tw-gap-6 rlg:!tw-gap-8 tw-max-w-[1332px] rlg:tw-mt-4"
    >
      <!-- CREATE WORKSTATION BANNER -->
      <!-- <Card bgColor="#EFFFEA" class="!tw-rounded-[16px]">
        <template #content>
          <div
            class="tw-w-full tw-px-12 tw-py-8 tw-box-border tw-flex tw-items-center tw-gap-10 md:!tw-flex-col md:!tw-gap-4 md:!tw-px-[1.2rem]"
          >
            <img
              class="tw-w-auto"
              :src="require('@/assets/images/tp-banner.svg')"
              alt="iknowa banner"
            />
            <div class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-start">
              <div class="tw-flex tw-items-center tw-gap-2">
                <img
                  class="!tw-w-[24px] !tw-h-[22px]"
                  :src="require('@/assets/images/star.svg')"
                  alt="iknowa banner"
                />
                <Text
                  v-if="
                    filteredUserAllWorkStation &&
                    filteredUserAllWorkStation.length
                  "
                  class="!tw-text-[20px] rlg:!tw-text-[32px] rlg:!tw-leading-[28px]"
                  lineHeight="25px"
                  >Create a new trade workstation</Text
                >
                <Text
                  v-else
                  class="!tw-text-[20px] rlg:!tw-text-[32px] rlg:!tw-leading-[28px]"
                  lineHeight="25px"
                  >Create your trade <br class="tw-flex rlg:!tw-hidden" />
                  workstation to get started</Text
                >
              </div>
              <Text
                v-if="
                  filteredUserAllWorkStation &&
                  filteredUserAllWorkStation.length
                "
                textWeight="500"
                lineHeight="24px"
                class="!tw-text-[13px] rlg:!tw-text-[18px]"
                >Do you have multiple organisations or would you like to join
                forces with others. iknowa enables you to do that, with
                workstations.</Text
              >
              <div
                v-else
                class="tw-flex tw-flex-col tw-gap-2 tw-items-start rlg:!tw-gap-4"
              >
                <div class="tw-flex tw-items-center tw-gap-2">
                  <img
                    :src="require('@/assets/images/blue-circle-outline.svg')"
                    alt="iknowa banner"
                  />
                  <Text
                    textWeight="500"
                    lineHeight="18px"
                    class="!tw-text-[13px] rlg:!tw-text-[18px]"
                    >Access Subsidised Upskilling Courses</Text
                  >
                </div>
                <div class="tw-flex tw-items-center tw-gap-2">
                  <img
                    :src="require('@/assets/images/blue-circle-outline.svg')"
                    alt="iknowa banner"
                  />
                  <Text
                    textWeight="500"
                    lineHeight="18px"
                    class="!tw-text-[13px] rlg:!tw-text-[18px]"
                    >Find & Estimate on New Jobs</Text
                  >
                </div>
                <div class="tw-flex tw-items-center tw-gap-2">
                  <img
                    :src="require('@/assets/images/blue-circle-outline.svg')"
                    alt="iknowa banner"
                  />
                  <Text
                    textWeight="500"
                    lineHeight="18px"
                    class="!tw-text-[13px] rlg:!tw-text-[18px]"
                    >Collaborate With Your Team or Other Trades</Text
                  >
                </div>
              </div>

              <Button
                class="!tw-w-auto"
                :label="
                  filteredUserAllWorkStation &&
                  filteredUserAllWorkStation.lenght
                    ? 'CREATE A new WORKSTATION'
                    : 'CREATE A TRADE WORKSTATION'
                "
                variant="accent"
                @click="onClickToggleTradesWorkStation"
              ></Button>
              <Text
                variant="span"
                textWeight="400"
                textColor="rgba(12, 15, 74, 0.5)"
                lineHeight="18px"
                >Starting from £7.99 + VAT / monthly</Text
              >
            </div>
          </div>
        </template>
      </Card> -->
      <Text
        variant="h4"
        class="tw-mb-4 tw-capitalize rmd:!tw-text-[32px] md:!tw-mb-0"
        >Hello, {{ user?.firstName }}</Text
      >
      <!-- MANAGE WORKSTATIONS -->
      <Card
        class="manage-ws-card !tw-max-h-[unset] tw-w-full tw-box-border md:tw-p-6 tw-p-4"
        :withBorder="true"
        bgColor="rgba(241, 246, 251, 0.50)"
        :class="{
          '!tw-px-8 !tw-py-6 md:!tw-px-4':
            !filteredUserAllWorkStation.length && !WorkStationInvitation.length,
        }"
        :isRoundedxl="
          !filteredUserAllWorkStation.length && !WorkStationInvitation.length
        "
      >
        <template #content>
          <main
            class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
          >
            <section
              v-if="
                filteredUserAllWorkStation.length ||
                WorkStationInvitation.length
              "
              class="tw-w-full tw-flex tw-items-center tw-justify-between tw-pb-4 tw-box-border"
            >
              <div
                class="tw-flex tw-items-center tw-gap-4 md:!tw-flex-col md:!tw-items-start md:!tw-gap-[.5rem] md:!tw-w-full"
              >
                <Text class="!tw-text-[20px] rlg:!tw-text-[28px]"
                  >Launch a Workstation</Text
                >

                <!-- <InputSelect
                  class="tw-max-w-full !tw-w-[300px] md:!tw-w-full"
                  placeholder="Select an email"
                  density="compact"
                  v-model="selectedEmailFilter"
                  :items="acceptedEmailsWithPrimaryEmail"
                  variant="outlined"
                  item-title="aliasEmail"
                  item-value="aliasEmail"
                  return-object
                /> -->
              </div>
            </section>
            <!-- WORKSTATION FILLED STATE -->
            <section
              class="tw-w-full tw-flex tw-flex-col tw-gap-8 tw-pt-5 tw-box-border tw-overflow-auto no-scrollbar"
              v-if="
                filteredUserAllWorkStation.length > 0 ||
                WorkStationInvitation.length > 0
              "
            >
              <!-- workstation invitation -->
              <div
                v-if="WorkStationInvitation && WorkStationInvitation.length > 0"
                class="tw-flex tw-flex-col tw-gap-4 tw-border-b tw-border-0 tw-border-dashed tw-border-[#C5D2F2] tw-pb-5"
              >
                <div
                  v-for="(member, index) in WorkStationInvitation"
                  :key="index"
                  class="tw-justify-between tw-flex-col ws-items-bg tw-w-full tw-flex tw-items-start tw-p-4 tw-rounded-[6px]"
                >
                  <div
                    class="md:tw-border-0 md:tw-pb-3 md:tw-border-b md:tw-border-dashed md:tw-border-[#8687A5] tw-w-full"
                  >
                    <div
                      class="chip-state tw-w-fit tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                    >
                      {{
                        member?.userRole?.id === 2
                          ? "Property owner"
                          : member?.userRole?.id === 3
                          ? "Sole trader"
                          : ""
                      }}
                    </div>
                  </div>
                  <div
                    class="tw-w-full tw-flex tw-items-center md:tw-items-start tw-gap-2 rmd:!tw-items-start tw-mt-3"
                  >
                    <!-- profile -->
                    <img
                      class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-min-w-[56px] tw-h-[56px] tw-relative tw-object-cover"
                      v-if="InviteMemberImage"
                      :src="InviteMemberImage"
                    />

                    <WorkStationProfileLogo
                      class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                      v-else
                      :WSName="member?.userWorkstation?.name"
                    />
                    <!-- details -->
                    <div
                      class="tw-w-full tw-flex md:tw-flex-col md:tw-items-start tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap"
                    >
                      <div class="tw-flex tw-flex-col tw-item-start">
                        <div
                          class="tw-flex tw-items-center tw-gap-2 md:tw-flex-col-reverse md:tw-items-start"
                        >
                          <Text variant="h6">{{
                            member?.userWorkstation?.name
                          }}</Text>
                          <div class="tw-flex tw-gap-2 tw-items-center">
                            <div
                              class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                            >
                              {{ member?.role }}
                            </div>
                          </div>

                          <!-- <SignInChip /> -->
                        </div>
                        <div
                          v-if="member?.tags"
                          class="tag-wrapper !tw-flex tw-items-center tw-justify-start tw-gap-[.5rem] md:!tw-flex-wrap tw-mt-2"
                        >
                          <div
                            class="tw-flex"
                            v-for="tag in parseJSON(member?.tags)"
                            :key="tag"
                          >
                            <div
                              class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                            >
                              {{ tag }}
                            </div>
                          </div>
                        </div>
                        <div class="tw-flex tw-items-start tw-flex-col">
                          <div
                            class="tw-flex tw-items-start tw-flex-col tw-gap-2"
                          >
                            <Text
                              variant="span"
                              textWeight="600"
                              textColor="rgba(12, 15, 74, 0.5)"
                              >{{ member?.user?.email }}</Text
                            >
                            <!-- <Text
                          variant="span"
                          textWeight="600"
                          textColor="#4B4BFF"
                          >+2 more</Text
                        > -->
                          </div>
                          <Text
                            v-if="member?.message"
                            variant="span"
                            textWeight="600"
                            textColor="rgba(12, 15, 74, 0.5)"
                            >{{ member?.message }}</Text
                          >

                          <Text
                            class="!tw-text-[10px] md:!tw-text-[11px] !tw-leading-none !tw-text-darkBlue/50"
                            textWeight="400"
                            >Last signed in:
                            {{
                              member?.lastLoginAt
                                ? formatDate(member?.lastLoginAt, "fullDate")
                                : "Not Signed"
                            }}</Text
                          >
                        </div>
                      </div>

                      <div
                        class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                        v-if="
                          member?.invitationStatus ===
                          MemberInvitationStatusEnum.PENDING
                        "
                      >
                        <v-btn
                          class="tw-px-[8px] tw-py-[6px] md:tw-py-[10px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                          @click="onOpenConfirmRejectModal(member?.id)"
                        >
                          <InfoIconWhite />
                          <span class="tw-ml-[3px]">Reject</span>
                        </v-btn>
                        <v-btn
                          :loading="
                            acceptInvitationLoader &&
                            acceptInvitationWorkstationId === member?.token
                          "
                          class="tw-px-[8px] tw-py-[6px] md:tw-py-[10px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#1FB27A] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                          @click="onClickAcceptWSInvitation(member?.token)"
                        >
                          <CheckIconWhite />
                          <span class="tw-ml-[3px]">Accept</span>
                        </v-btn>
                        <!-- <AcceptChip /> -->
                        <!-- <MyWorkstationChip /> -->
                      </div>
                      <div
                        class="tw-flex tw-items-center tw-gap-2"
                        v-if="
                          member?.invitationStatus ===
                          MemberInvitationStatusEnum.ACCEPTED
                        "
                      >
                        <NotificationIcon />
                        <v-btn
                          class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                          @click="
                            onWorkStationClick(member?.userWorkstation?.id)
                          "
                        >
                          launch
                          <LunchIcon class="tw-ml-2" />
                        </v-btn>
                        <v-btn
                          class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                          @click="onClickRejectWSInvitation(member?.id)"
                        >
                          Delete
                          <TrashIcon
                            class="tw-ml-2"
                            fill="#FFF"
                            fillOpacity="1"
                          />
                        </v-btn>
                        <div class="tw-flex tw-items-center tw-gap-2">
                          <DeleteActionChip
                            @click="onClickRejectWSInvitation(member?.id)"
                          />
                        </div>
                        <!-- <MyWorkstationChip /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- User Workstation -->
              <div
                class="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-justify-between"
              >
                <template v-for="(member, index) in filteredUserAllWorkStation">
                  <div
                    class="tw-justify-between tw-flex-col ws-items-bg tw-w-full tw-flex tw-items-start tw-p-4 tw-rounded-[6px]"
                    v-if="index < numberOfWsShowed"
                    :key="index"
                  >
                    <div
                      class="tw-flex tw-items-center tw-w-full tw-justify-between md:tw-border-0 md:tw-pb-3 md:tw-border-b md:tw-border-dashed md:tw-border-[#8687A5] s430:tw-gap-2"
                    >
                      <div
                        class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                      >
                        {{
                          member?.userRole?.id === 2
                            ? "Property owner"
                            : member?.userRole?.id === 3
                            ? "Sole trader"
                            : ""
                        }}
                      </div>
                      <div
                        class="tw-items-center tw-gap-2 tw-flex-wrap tw-relative md:tw-flex tw-hidden"
                      >
                        <NotificationIcon />
                        <span
                          v-if="workStationNotificationCount[member?.id]"
                          class="notification__count tw-absolute tw-top-0 tw-left-7 tw-w-[18px] tw-h-[18px] tw-bg-[#FFA500] tw-rounded-full tw-text-center tw-flex tw-items-center tw-justify-center"
                        >
                          <Text variant="xsmall">
                            {{
                              workStationNotificationCount[member?.id] || 0
                            }}</Text
                          >
                        </span>

                        <v-btn
                          class="tw-px-4 md:tw-px-2 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm md:tw-text-[11px] !tw-h-[auto] tw-leading-5"
                          @click="onWorkStationClick(member?.id)"
                        >
                          launch
                          <LunchIcon class="tw-ml-2" />
                        </v-btn>
                        <v-btn
                          :loading="
                            deleteInvitedWorkstationLoader &&
                            deleteInvitedWorkstationId === member?.memberId
                          "
                          v-if="member?.isInvited"
                          class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5 md:tw-hidden"
                          @click="onClickRejectWSInvitation(member?.memberId)"
                        >
                          Delete
                          <TrashIcon
                            class="tw-ml-2"
                            fill="#FFF"
                            fillOpacity="1"
                          />
                        </v-btn>
                        <!-- <AcceptChip /> -->
                        <!-- <MyWorkstationChip /> -->
                      </div>
                    </div>

                    <div
                      class="tw-w-full tw-flex tw-items-center md:tw-items-start tw-gap-2 rmd:!tw-items-start tw-mt-3"
                    >
                      <!-- profile -->
                      <!-- profile -->
                      <img
                        v-if="member?.imageUrl"
                        :src="member?.imageUrl"
                        class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-min-w-[56px] tw-h-[56px] tw-relative tw-object-cover"
                      />
                      <WorkStationProfileLogo
                        class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-h-[56px] tw-relative"
                        v-else
                        :WSName="member?.name"
                      />
                      <!-- details -->
                      <div
                        class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap tw-w-[calc(100%_-_64px)] md:!tw-flex-col md:!tw-items-start"
                      >
                        <div
                          class="tw-flex tw-flex-col tw-item-start tw-gap-[2px]"
                        >
                          <div
                            class="tw-flex tw-items-center tw-gap-2 md:tw-flex-col-reverse md:tw-items-start"
                          >
                            <Text variant="h6" class="!tw-capitalize">{{
                              member?.name
                            }}</Text>
                            <div
                              v-if="member?.role"
                              class="chip-state tw-px-[.5rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize !tw-py-0"
                            >
                              <Text variant="p" textWeight="400">{{
                                member?.role
                              }}</Text>
                            </div>
                            <div
                              v-else
                              class="chip-state tw-px-[.5rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize !tw-py-0"
                            >
                              <Text variant="p" textWeight="400">Admin</Text>
                            </div>

                            <!-- <SignInChip /> -->
                          </div>
                          <div
                            v-if="member?.tags"
                            class="tag-wrapper !tw-flex tw-items-center tw-justify-start tw-gap-[.5rem] md:!tw-flex-wrap tw-mt-2"
                          >
                            <div
                              class="tw-flex"
                              v-for="tag in parseJSON(member?.tags)"
                              :key="tag"
                            >
                              <div
                                class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                              >
                                {{ tag }}
                              </div>
                            </div>
                          </div>
                          <div
                            class="tw-h-full tw-flex tw-items-start tw-flex-col tw-justify-between tw-gap-[3px]"
                          >
                            <div class="tw-flex tw-items-center">
                              <!-- <div
                                v-for="items in 6"
                                :key="items"
                                class="tw-mr-[-3px] tw-rounded-[2px] tw-border-white tw-border-[1px] tw-border-solid tw-min-w-[16px] tw-w-[16px] tw-h-[16px] tw-overflow-hidden tw-flex tw-items-center tw-justify-center"
                              >
                                <img
                                  class="tw-w-full tw-h-full tw-object-cover tw-rounded-[2px]"
                                  :src="
                                    require('@/assets/images/sample-avatar-2.png')
                                  "
                                  alt="iknowa banner"
                                />
                              </div>
                              <div
                                class="tw-border-[rgba(255,255,255,1)] tw-border-[0.5px] tw-mr-[-3px] tw-rounded-[4px] tw-min-w-[16px] tw-w-[16px] tw-h-[16px] tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-bg-white"
                              >
                                <Text
                                  variant="xsmall"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >+4</Text
                                >
                              </div> -->
                              <!-- <Text
                                  variant="span"
                                  textWeight="600"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >{{
                                    processedEmail(member?.user?.email)
                                  }}</Text
                                > -->
                            </div>
                            <Text
                              v-if="member?.message"
                              variant="span"
                              textWeight="600"
                              textColor="rgba(12, 15, 74, 0.5)"
                              >{{ member?.message }}</Text
                            >

                            <Text
                              class="!tw-text-[10px] md:!tw-text-[11px] !tw-leading-none !tw-text-darkBlue/50"
                              textWeight="400"
                              >Last signed in:
                              {{
                                member?.lastLoginAt
                                  ? formatDate(member?.lastLoginAt, "fullDate")
                                  : "Not Signed"
                              }}</Text
                            >
                          </div>
                        </div>

                        <div
                          class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap tw-relative md:tw-hidden"
                        >
                          <NotificationIcon />
                          <span
                            v-if="workStationNotificationCount[member?.id]"
                            class="notification__count tw-absolute tw-top-0 tw-left-7 tw-w-[18px] tw-h-[18px] tw-bg-[#FFA500] tw-rounded-full tw-text-center tw-flex tw-items-center tw-justify-center"
                          >
                            <Text variant="xsmall">
                              {{
                                workStationNotificationCount[member?.id] || 0
                              }}</Text
                            >
                          </span>

                          <v-btn
                            class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                            @click="onWorkStationClick(member?.id)"
                          >
                            launch
                            <LunchIcon class="tw-ml-2" />
                          </v-btn>
                          <v-btn
                            :loading="
                              deleteInvitedWorkstationLoader &&
                              deleteInvitedWorkstationId === member?.memberId
                            "
                            v-if="member?.isInvited"
                            class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                            @click="onClickRejectWSInvitation(member?.memberId)"
                          >
                            Delete
                            <TrashIcon
                              class="tw-ml-2"
                              fill="#FFF"
                              fillOpacity="1"
                            />
                          </v-btn>
                          <!-- <AcceptChip /> -->
                          <!-- <MyWorkstationChip /> -->
                        </div>
                      </div>
                    </div>
                    <div class="tw-hidden md:tw-block md:tw-mt-3 md:tw-ml-auto">
                      <v-btn
                        :loading="
                          deleteInvitedWorkstationLoader &&
                          deleteInvitedWorkstationId === member?.memberId
                        "
                        v-if="member?.isInvited"
                        class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                        @click="onClickRejectWSInvitation(member?.memberId)"
                      >
                        Delete
                        <TrashIcon
                          class="tw-ml-2"
                          fill="#FFF"
                          fillOpacity="1"
                        />
                      </v-btn>
                    </div>
                  </div>
                </template>
              </div>

              <!-- Show more -->
              <div
                class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom s540:!tw-flex-col s540:!tw-items-start"
              >
                <div
                  class="tw-flex tw-items-center"
                  v-if="filteredUserAllWorkStation?.length > 3"
                >
                  <div
                    class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                    @click="toggleShowWs"
                    v-if="!isShowAllws"
                  >
                    <Text
                      textWeight="600"
                      variant="h6"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >See
                      {{ `${filteredUserAllWorkStation?.length - 3}` }} more
                      workstation</Text
                    >
                    <v-icon
                      icon="mdi-chevron-down"
                      color="rgba(12, 15, 74, 0.5)"
                    ></v-icon>
                  </div>
                  <div
                    class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                    @click="toggleShowWs"
                    v-if="isShowAllws"
                  >
                    <Text
                      textWeight="600"
                      variant="p"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >See less workstation</Text
                    >
                    <v-icon
                      icon="mdi-chevron-up"
                      color="rgba(12, 15, 74, 0.5)"
                    ></v-icon>
                  </div>
                </div>
                <Button
                  class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                  variant="custom"
                  @click="onClickToggleTradesWorkStation"
                  label="Create a new workstation"
                  activeBgColor="transparent"
                  width="auto"
                  :isCapitalize="true"
                  :fontWeight="700"
                >
                  <template #postfix>
                    <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                  </template>
                </Button>
              </div>
            </section>
            <!-- WORKSTATION EMPTY STATE -->
            <section
              v-else
              class="tw-w-full tw-flex tw-items-center tw-gap-6 lg:!tw-flex-col"
            >
              <div
                class="tw-flex tw-items-start tw-flex-col tw-gap-6 lg:!tw-w-full"
              >
                <Text
                  variant="h3"
                  class="md:!tw-text-[20px]"
                  whiteSpace="nowrap"
                  >Getting started with iknowa</Text
                >
                <div
                  class="tw-flex tw-items-center tw-gap-6 lg:!tw-flex-col lg:!tw-w-full lg:!tw-items-start"
                >
                  <div
                    class="tw-min-w-[292px] tw-w-full sign-up-empty-state-banner tw-px-6 tw-py-4 tw-box-border tw-flex tw-flex-col tw-items-start tw-gap-2 tw-bg-[rgba(197,210,242,0.24)] tw-rounded-lg"
                  >
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <v-icon icon="mdi-check-circle" color="#264FD5"></v-icon>
                      <Text variant="span" textColor="#264FD5">Completed</Text>
                    </div>
                    <Text variant="h5" whiteSpace="nowrap"
                      >Sign up to iknowa</Text
                    >
                    <Text variant="h6" textWeight="400"
                      >You’ve just signed up to<br />
                      iknowa via a link from NWG.</Text
                    >
                  </div>
                  <img
                    class="tw-hidden rlg:!tw-flex"
                    :src="require('@/assets/images/up-next-desktop.svg')"
                    alt=""
                  />
                  <img
                    class="tw-flex rlg:!tw-hidden"
                    :src="require('@/assets/images/up-next-mobile.svg')"
                    alt=""
                  />
                </div>
              </div>
              <div
                class="tw-w-full tw-flex-col tw-bg-white tw-border-solid-default tw-px-8 tw-box-border tw-py-6 tw-flex tw-items-start tw-gap-6 tw-rounded-[16px] rxl:!tw-flex-row"
              >
                <img
                  class="tw-w-[200px]"
                  :src="require('@/assets/images/tp-banner.svg')"
                  alt="iknowa banner"
                />
                <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
                  <Text variant="h5">Create your trade workstation</Text>
                  <Text variant="h6" textWeight="400"
                    >Unlock access to subsidised upskilling courses, estimate
                    new job opportunities, and seamlessly collaborate.</Text
                  >
                  <Button
                    class="!tw-w-auto"
                    :label="
                      filteredUserAllWorkStation &&
                      filteredUserAllWorkStation.lenght
                        ? 'CREATE A new WORKSTATION'
                        : 'CREATE A TRADE WORKSTATION'
                    "
                    variant="accent"
                    @click="onClickToggleTradesWorkStation"
                  ></Button>
                  <Text
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.5)"
                    lineHeight="18px"
                    >Starting from £7.99 + VAT / monthly</Text
                  >
                </div>
              </div>
            </section>
            <!-- <section v-else class="tw-w-full tw-mt-4">
              <div
                class="ws-items-bg tw-w-full tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-[6px]"
              >
                <div class="tw-flex tw-items-center tw-gap-2">
                  <img
                    class="tw-min-w-[66px] tw-w-[66px] tw-h-[66px]"
                    :src="require('@/assets/images/empty-ws-thumbnail.png')"
                  />
                  <div class="tw-flex tw-flex-col tw-gap-2 tw-items-start">
                    <div class="tw-flex tw-items-center tw-gap-[4px]">
                      <Text variant="h5">[Workstation Name]</Text>
                      <div
                        class="chip-state tw-px-[.5rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize !tw-py-0"
                      >
                        <Text variant="p" textWeight="400">Admin</Text>
                      </div>
                    </div>
                    <Text variant="h6" textWeight="400"
                      >toby.sparks@gmail.com</Text
                    >
                  </div>
                </div>
                <div class="tw-flex tw-items-center tw-gap-4">
                  <div
                    class="notify-icon tw-rounded-full tw-flex tw-items-center tw-justify-center tw-w-[34px] tw-min-w-[34px] tw-h-[34px] tw-bg-white"
                  >
                    <v-icon
                      icon="mdi-bell"
                      color="#0C0F4A"
                      class="!tw-text-[16px]"
                    ></v-icon>
                  </div>
                  <Button
                    class="!tw-w-auto"
                    label="Create a Workstation"
                    variant="accent"
                    @click="onClickToggleTradesWorkStation"
                    ><template #postfix> <LunchIcon /> </template
                  ></Button>
                </div>
              </div>
            </section> -->
          </main>
        </template>
      </Card>

      <!-- NEW CONTROL CENTER CONTENT DESIGN -->
      <div class="tw-w-full tw-flex tw-gap-8 md:!tw-gap-6 lg:!tw-flex-col">
        <!-- FIRST COLUMN -->
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-8 md:!tw-gap-6">
          <!-- COURSE CARD -->
          <Card
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <section
                class="tw-flex tw-flex-col tw-items-start tw-justify-between"
              >
                <div class="tw-flex tw-flex-col tw-items-start tw-gap-6">
                  <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
                    <img
                      class="!tw-w-[239px] tw-pb-4"
                      :src="require('@/assets/images/courses-welcome.png')"
                    />
                    <Text class="!tw-text-[22px]"
                      >Access Government subsidised Up-skilling courses</Text
                    >
                    <Text variant="h6" textWeight="400"
                      >We’re serious about increasing the number of qualified
                      installers.</Text
                    >
                  </div>
                  <div
                    class="tw-w-full tw-flex tw-flex-col-reverse tw-justify-end tw-items-center tw-gap-4 tw-box-border tw-pb-4 rmd:!tw-flex-row-reverse"
                  >
                    <CommonLoader
                      v-if="getCourseloading"
                      :loading="getCourseloading"
                      class="loader-container"
                    />
                    <template v-else>
                      <Card
                        v-for="items in coursesWithExtraInfo"
                        :key="items?.id"
                        :withBorder="true"
                        :isRoundedxl="true"
                        class="!tw-rounded-[16px] tw-px-4 tw-py-5 tw-box-border !tw-min-h-[332px] !tw-h-[332px] !tw-w-full"
                      >
                        <template #content>
                          <div
                            class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-3 tw-justify-between"
                          >
                            <div
                              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-3"
                            >
                              <div
                                class="tw-w-full tw-h-full tw-max-h-[100px] tw-h-[100px] tw-min-h-[100px] tw-rounded-[6px] tw-overflow-hidden"
                              >
                                <img
                                  class="tw-w-full tw-h-full tw-object-cover"
                                  :src="
                                    require('@/assets/images/course/' +
                                      items?.thumbnail)
                                  "
                                />
                              </div>
                              <Text variant="h5">{{ items?.name }}</Text>
                              <Text
                                v-if="
                                  items?.title ===
                                  'Solar PV, Battery Storage & EV Charging Level 3'
                                "
                                variant="span"
                                textWeight="400"
                                textColor="rgba(12, 15, 74, 0.5)"
                                lineHeight="18px"
                                >Designed for experienced Electrical<br
                                  class="md:!tw-hidden"
                                />
                                Engineers.</Text
                              >
                              <Text
                                v-else
                                variant="span"
                                textWeight="400"
                                textColor="rgba(12, 15, 74, 0.5)"
                                lineHeight="18px"
                                >Designed for experienced Plumbing & Heating
                                Engineers.</Text
                              >
                              <div
                                class="tw-flex tw-items-center tw-gap-2 tw-justify-start"
                              >
                                <MapMarkerBlueIcon
                                  class="tw-min-w-[11px] tw-min-h-[13px]"
                                />
                                <Text
                                  variant="span"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >In-Person & E-Learning</Text
                                >
                              </div>
                            </div>
                            <Button
                              variant="secondary"
                              label="view course details"
                              @click="viewCourseDetails(items?.id)"
                            ></Button>
                          </div>
                        </template>
                      </Card>
                    </template>
                  </div>
                </div>
                <!-- <div
                  class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                >
                  <Button
                    class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                    variant="custom"
                    @click="onClickToggleTradesWorkStation"
                    label="Explore up-skilling courses"
                    activeBgColor="transparent"
                    width="auto"
                    :isCapitalize="true"
                    :fontWeight="700"
                  >
                    <template #postfix>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </template>
                  </Button>
                </div> -->
              </section>
            </template>
          </Card>
          <!-- MANAGE EMAIL CARD -->
          <Card
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <section
                class="tw-flex tw-flex-col tw-items-start tw-justify-between"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-6 tw-pb-4"
                >
                  <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
                    <Text class="!tw-text-[22px]">Manage emails</Text>
                  </div>
                  <div
                    class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-4"
                  >
                    <div
                      class="tw-w-full tw-flex tw-items-start tw-justify-between tw-border-0 tw-pb-2"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <div
                          class="tw-w-auto tw-px-2 tw-box-border tw-rounded-[4px] tw-bg-[rgba(79,85,240,0.1)] tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-[#4F55F0]"
                        >
                          <Text
                            variant="xsmall"
                            textWeight="700"
                            textColor="#4F55F0"
                            >Primary</Text
                          >
                        </div>
                        <Text variant="p" textWeight="400">{{
                          userEmail
                        }}</Text>
                      </div>
                    </div>
                    <div
                      class="tw-w-full tw-flex tw-items-start tw-justify-between tw-border-0 tw-pb-2 !tw-gap-[.3rem] s540:!tw-flex-col"
                      v-for="email in manageEmailList"
                      :key="email.id"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <ApproveChip
                          v-if="email?.invitationStatus === 'accepted'"
                        />
                        <PendingChip
                          v-if="email?.invitationStatus === 'pending'"
                        />
                        <Text variant="p" textWeight="400">{{
                          email?.aliasEmail
                        }}</Text>
                      </div>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <DeleteActionChip @click="onClickDeleteEmail(email)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                >
                  <Button
                    class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                    variant="custom"
                    @click="toggleAddEmailModal(true)"
                    label="Add Email Address"
                    activeBgColor="transparent"
                    width="auto"
                    :isCapitalize="true"
                    :fontWeight="700"
                  >
                    <template #postfix>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </template>
                  </Button>
                </div>
              </section>
            </template>
          </Card>
        </div>
        <!-- SECOND COLUMN -->
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-8 md:!tw-gap-6">
          <!-- UPCOMING EVENTS CARD -->
          <Card
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <section
                class="tw-flex tw-items-start tw-gap-8 md:!tw-gap-6 md:!tw-flex-col"
              >
                <img
                  class="!tw-w-[124px]"
                  :src="require('@/assets/images/upcoming-events.svg')"
                />
                <section
                  class="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-between"
                >
                  <div class="tw-flex tw-flex-col tw-items-start tw-gap-6">
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
                      <Text class="!tw-text-[22px]"
                        >Stay tuned for more events in 2025</Text
                      >
                      <Text variant="h6" textWeight="400"
                        >Join us and connect with peers, gain insights from
                        experts, and take steps towards advancing your career in
                        the trade industry.</Text
                      >
                    </div>
                    <div
                      class="tw-flex tw-w-full tw-items-start tw-gap-4 !tw-hidden"
                    >
                      <Card
                        :withBorder="true"
                        :isRoundedxl="true"
                        class="!tw-rounded-[16px] tw-px-4 tw-py-5 tw-box-border !tw-min-h-[332px] !tw-w-full"
                      >
                        <template #content>
                          <div
                            class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-3 tw-justify-between"
                          >
                            <div
                              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-3"
                            >
                              <div
                                class="tw-w-full tw-h-full tw-max-h-[80px] tw-h-[80px] tw-min-h-[80px] tw-rounded-[6px] tw-overflow-hidden tw-flex tw-items-center"
                              >
                                <img
                                  class="tw-w-full tw-h-full tw-object-cover"
                                  :src="
                                    require('@/assets/images/iknowa-google-event.jpg')
                                  "
                                />
                              </div>
                              <Text variant="h5"
                                >iknowa ‘Trade Community’ Event 2024</Text
                              >
                              <Text
                                variant="span"
                                textWeight="400"
                                textColor="rgba(12, 15, 74, 0.5)"
                                lineHeight="18px"
                                >iknowa and Google present an exclusive Trade
                                Community event, uniting the UK's top retrofit
                                professionals and industry leaders.
                              </Text>
                              <div
                                class="tw-flex tw-flex-col tw-gap-0 tw-items-start"
                              >
                                <div
                                  class="tw-flex tw-items-center tw-gap-2 tw-justify-start"
                                >
                                  <CalendarIcon
                                    class="tw-min-w-[11px] tw-min-h-[13px]"
                                  />
                                  <Text
                                    variant="span"
                                    textColor="rgba(12, 15, 74, 0.5)"
                                    >December 10, 2024</Text
                                  >
                                </div>
                                <div
                                  class="tw-flex tw-items-center tw-gap-2 tw-justify-start"
                                >
                                  <MapMarkerBlueIcon
                                    class="tw-min-w-[11px] tw-min-h-[13px]"
                                  />
                                  <Text
                                    variant="span"
                                    textColor="rgba(12, 15, 74, 0.5)"
                                    >In-Person & E-Learning</Text
                                  >
                                </div>
                                <div
                                  class="tw-flex tw-items-center tw-gap-2 tw-justify-start"
                                >
                                  <MapMarkerYellowIcon
                                    class="tw-min-w-[11px] tw-min-h-[13px]"
                                  />
                                  <Text
                                    variant="span"
                                    textColor="rgba(12, 15, 74, 0.5)"
                                    >London, W12 BM0</Text
                                  >
                                </div>
                              </div>
                            </div>
                            <Button
                              variant="secondary"
                              label="join event"
                              @click="onClickViewGoogleEvent"
                            ></Button>
                          </div>
                        </template>
                      </Card>
                    </div>
                  </div>
                  <!-- <div
                    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                  >
                    <Button
                      class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                      variant="custom"
                      @click="onClickBenefitsButton"
                      label="Explore our events"
                      activeBgColor="transparent"
                      width="auto"
                      :isCapitalize="true"
                      :fontWeight="700"
                    >
                      <template #postfix>
                        <v-icon
                          icon="mdi-chevron-right"
                          color="#FFA500"
                        ></v-icon>
                      </template>
                    </Button>
                  </div> -->
                </section>
              </section>
            </template>
          </Card>
          <!-- IKNOWA BENEFITS CARD -->
          <Card
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <section
                class="tw-flex tw-items-center tw-gap-8 md:!tw-gap-6 md:!tw-flex-col md:tw-items-start"
              >
                <img
                  class="!tw-w-[124px]"
                  :src="require('@/assets/images/iknowa-benefits.png')"
                />
                <section
                  class="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-between"
                >
                  <div
                    class="tw-flex tw-flex-col tw-items-start tw-gap-6 tw-pb-2"
                  >
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
                      <Text class="!tw-text-[22px]"
                        >Learn more about iknowa</Text
                      >
                      <Text variant="h6" textWeight="400"
                        >Explore everything that the iknowa can do.</Text
                      >
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-items-start tw-gap-4"
                    ></div>
                  </div>
                  <div
                    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                  >
                    <Button
                      class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                      variant="custom"
                      @click="onClickBenefitsButton"
                      label="Explore iknowa benefits"
                      activeBgColor="transparent"
                      width="auto"
                      :isCapitalize="true"
                      :fontWeight="700"
                    >
                      <template #postfix>
                        <v-icon
                          icon="mdi-chevron-right"
                          color="#FFA500"
                        ></v-icon>
                      </template>
                    </Button>
                  </div>
                </section>
              </section>
            </template>
          </Card>
        </div>
      </div>
    </section>

    <!-- SOLETRADER OR COMPANY DASHBOARD -->
    <section
      v-if="!isUserWorkstationIsDefault && !isWorkstationLoading"
      class="workstation-dashboard tw-flex tw-flex-col tw-gap-6 tw-w-full tw-h-full"
    >
      <div
        class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-gap-6 tw-box-border rlg:tw-grid-cols-2"
      >
        <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
          <div
            v-if="
              activeUserWorkstation?.verificationStatus !==
                UserVerificationStatusEnum.VERIFIED &&
              accountStatuses?.forms?.insurance !== 0 &&
              accountStatuses?.forms?.certification !== 0 &&
              accountStatuses?.forms?.skills !== 0
            "
            @click.capture="handleClick"
          >
            <v-col
              class="dashboard-setting-up__right tw-w-full !tw-p-0 tw-relative tw-border-solid-default !tw-rounded-[16px] tw-overflow-hidden"
            >
              <WorkStationVerificationProgress
                v-if="
                  userWorkStationStatus ===
                    UserVerificationStatusEnum.PENDING_VERIFICATION &&
                  !isWorkstationLoading
                "
              />
              <v-card
                class="custom-card tw-relative"
                :class="{
                  'fixed-height':
                    userWorkStationStatus ===
                    UserVerificationStatusEnum.VERIFIED,
                }"
              >
                <div
                  class="icon-title !tw-flex !tw-flex-col !tw-items-start tw-gap-2"
                >
                  <div class="icon-title__inner">
                    <v-icon icon="mdi-star" />
                    <p class="common-title">
                      Setting Up Your iknowa Workstation
                    </p>
                  </div>
                  <Text
                    variant="p"
                    textColor="rgba(12, 15, 74, 0.5)"
                    textWeight="400"
                    >Complete the below steps to activate your
                    workstation!</Text
                  >
                </div>

                <div class="progress-wrap">
                  <div class="progress-wrap-title">
                    <p class="common-title">Your iknowa account setup</p>
                    <span> {{ workstationSetupProgressNew }} % </span>
                  </div>
                  <v-progress-linear
                    color="#40AE42"
                    :model-value="workstationSetupProgressNew"
                    height="8"
                    rounded
                  />
                </div>

                <div class="progress-list">
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: !isUserWorkstationIsDefault,
                    }"
                  >
                    <div class="progress-list__counter">
                      <span>1</span>
                    </div>
                    <div class="progress-list__text">
                      <p class="common-title">Create a Trade Workstation</p>
                      <span
                        >Access document storage, create calendar events &
                        invite others.</span
                      >
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: isUserAddBusinessAddress,
                    }"
                    @click="onClickAddBusinessAddressModal"
                  >
                    <div
                      class="progress-list__counter"
                      :class="{
                        '!tw-bg-[#0C0F4A]': !isUserAddBusinessAddress,
                      }"
                    >
                      <span>2</span>
                    </div>
                    <div class="progress-list__text">
                      <p
                        class="common-title"
                        :class="{
                          '!tw-text-[#4B4BFF]': !isUserAddBusinessAddress,
                        }"
                      >
                        Add Business Address
                      </p>
                      <span>Build trust and legitimacy for your business.</span>
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed:
                        WorkStationSiltVerificationStatus &&
                        ['SUCCESS', 'MANUAL_REVIEW'].includes(
                          WorkStationSiltVerificationStatus?.status
                        ),
                    }"
                    @click="onClickIDVerificationModal"
                    v-if="
                      userBusinessType ===
                      BusinessDetailTypeEnum.LIMITED_COMPANY
                    "
                  >
                    <div
                      class="progress-list__counter"
                      :class="{
                        '!tw-bg-[#0C0F4A]': !(
                          WorkStationSiltVerificationStatus &&
                          ['SUCCESS', 'MANUAL_REVIEW'].includes(
                            WorkStationSiltVerificationStatus?.status
                          )
                        ),
                      }"
                    >
                      <span>3</span>
                    </div>
                    <div class="progress-list__text">
                      <p
                        class="common-title"
                        :class="{
                          '!tw-text-[#4B4BFF]': !(
                            WorkStationSiltVerificationStatus &&
                            ['SUCCESS', 'MANUAL_REVIEW'].includes(
                              WorkStationSiltVerificationStatus?.status
                            )
                          ),
                        }"
                      >
                        Complete ID & Business Verification
                      </p>
                      <span>Increase clients trust & win more Jobs</span>
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: [
                        UserVerificationStatusEnum.VERIFIED,
                        UserVerificationStatusEnum.PENDING_VERIFICATION,
                      ].includes(
                        activeUserWorkstation?.user?.userKYCVerificationStatus
                      ),
                    }"
                    @click="onClickIDVerificationModal"
                    v-else
                  >
                    <div
                      class="progress-list__counter"
                      :class="{
                        '!tw-bg-[#0C0F4A]': ![
                          UserVerificationStatusEnum.VERIFIED,
                          UserVerificationStatusEnum.PENDING_VERIFICATION,
                        ].includes(
                          activeUserWorkstation?.user?.userKYCVerificationStatus
                        ),
                      }"
                    >
                      <span>3</span>
                    </div>
                    <div class="progress-list__text">
                      <p
                        class="common-title"
                        :class="{
                          '!tw-text-[#4B4BFF]': ![
                            UserVerificationStatusEnum.VERIFIED,
                            UserVerificationStatusEnum.PENDING_VERIFICATION,
                          ].includes(
                            activeUserWorkstation?.user
                              ?.userKYCVerificationStatus
                          ),
                        }"
                      >
                        Complete ID Verification
                      </p>
                      <span>Increase clients trust & win more Jobs</span>
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed: isUserProfileVerified,
                    }"
                    @click="onClickManageTradePassportPreviewModal"
                  >
                    <div
                      class="progress-list__counter"
                      :class="{ '!tw-bg-[#0C0F4A]': !isUserProfileVerified }"
                    >
                      <span>4 </span>
                    </div>
                    <div class="progress-list__text">
                      <p
                        class="common-title"
                        :class="{
                          '!tw-text-[#4B4BFF]': !isUserProfileVerified,
                        }"
                      >
                        Complete Your Trade Passport
                      </p>
                      <span
                        >Add Specialisms, Certificates, Insurances and other
                        details.</span
                      >
                    </div>
                  </div>
                  <div
                    class="progress-list__item"
                    :class="{
                      completed:
                        userWorkStationStatus ===
                          UserVerificationStatusEnum.PENDING_VERIFICATION ||
                        userWorkStationStatus ===
                          UserVerificationStatusEnum.VERIFIED,
                    }"
                    @click="onClickSubmitVerificationPreviewModal"
                  >
                    <div
                      class="progress-list__counter"
                      :class="{
                        '!tw-bg-[#0C0F4A]': !(
                          userWorkStationStatus ===
                            UserVerificationStatusEnum.PENDING_VERIFICATION ||
                          userWorkStationStatus ===
                            UserVerificationStatusEnum.VERIFIED
                        ),
                      }"
                    >
                      <span>5</span>
                    </div>
                    <div class="progress-list__text">
                      <p
                        class="common-title"
                        :class="{
                          '!tw-text-[#4B4BFF]': !(
                            userWorkStationStatus ===
                              UserVerificationStatusEnum.PENDING_VERIFICATION ||
                            userWorkStationStatus ===
                              UserVerificationStatusEnum.VERIFIED
                          ),
                        }"
                      >
                        Submit Your Workstation for Verification
                      </p>
                      <span>Access courses, jobs & trade network.</span>
                    </div>
                  </div>
                  <div
                    class="tw-w-full tw-mt-4 tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center rmd:tw-flex-row rmd:tw-justify-between"
                  >
                    <Button
                      :isLoading="onSubmitVerification"
                      v-if="
                        userWorkStationStatus !==
                        UserVerificationStatusEnum.VERIFIED
                      "
                      :isActive="
                        isUserProfileVerified &&
                        userWorkStationStatus !==
                          UserVerificationStatusEnum.PENDING_VERIFICATION &&
                        isUserAddBusinessAddress
                      "
                      :label="
                        userWorkStationStatus ===
                        UserVerificationStatusEnum.PENDING_VERIFICATION
                          ? 'profile verification submitted'
                          : 'submit for verification'
                      "
                      class="!tw-w-[auto] !tw-max-w-[auto] md:!tw-w-full"
                      @click="onClickSubmitVerification"
                    ></Button>
                  </div>
                </div>
              </v-card>
            </v-col>
          </div>
          <JobsOverviewWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-if="
              !(
                activeUserWorkstation?.verificationStatus !==
                  UserVerificationStatusEnum.VERIFIED &&
                accountStatuses?.forms?.insurance !== 0 &&
                accountStatuses?.forms?.certification !== 0 &&
                accountStatuses?.forms?.skills !== 0
              )
            "
          />
          <TeamWidget
            className="!tw-min-h-[229px]"
            :getUserWorkStationFieldAccess="isUserWorkStationAdmin"
            :propMembers="members"
            @get-members="getTeamMember"
          />
          <TradeNetworkWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
          />
        </div>
        <div
          class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 no-scroll tw-max-h-[1100px]"
        >
          <JobsOverviewWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-if="
              activeUserWorkstation?.verificationStatus !==
                UserVerificationStatusEnum.VERIFIED &&
              accountStatuses?.forms?.insurance !== 0 &&
              accountStatuses?.forms?.certification !== 0 &&
              accountStatuses?.forms?.skills !== 0
            "
          />

          <CalendarEventWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-if="
              activeUserWorkstation?.verificationStatus !==
                UserVerificationStatusEnum.VERIFIED &&
              accountStatuses?.forms?.insurance !== 0 &&
              accountStatuses?.forms?.certification !== 0 &&
              accountStatuses?.forms?.skills !== 0
            "
            :userWorkStationImage="userWorkStationImage"
            :calenderAttribute="calenderAttribute"
            className="tw-max-h-[758px] tw-overflow-hidden tw-rounded-lg tw-h-full"
          />
          <CalendarEventWidget
            :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
            v-else
            :userWorkStationImage="userWorkStationImage"
            :calenderAttribute="calenderAttribute"
            className="!tw-h-full"
          />
        </div>
      </div>
    </section>
    <!-- <WorkStationVerificationLock
      v-if="
        userWorkStationStatus ===
          UserVerificationStatusEnum.PENDING_VERIFICATION &&
        !isWorkstationLoading
      "
    /> -->
    <v-dialog
      width="350px"
      v-model="launchInvitedWorkstation"
      class="modal_box action-modal"
      persistent
    >
      <div class="modal_wrapper">
        <main class="main">
          <div>
            <div class="loading-dots">
              <span class="dot dot-red"></span>
              <span class="dot dot-blue"></span>
              <span class="dot dot-green"></span>
            </div>
            <p class="switching-text">{{ switchingText }}</p>
            <v-stepper
              class="onboard-stepper"
              v-model="currentSelectedTab.name"
            >
              <v-stepper-header class="onboard-stepper__header">
                <v-stepper-item
                  class="onboard-stepper__item"
                  v-for="(step, index) in tabListItems"
                  :key="index"
                  :value="step.name"
                ></v-stepper-item>
              </v-stepper-header>
            </v-stepper>
          </div>
        </main>
      </div>
    </v-dialog>
  </main>

  <AddEmailModal
    v-if="isShowAddEmailModal"
    @on-close="toggleAddEmailModal($event)"
    @onClickAdd="onClickAddEmail"
  />
  <EmailConformModal
    v-if="isShowAddEmailConformModal"
    :modalType="emailConfirmModalType"
    :manageEmail="manageEmail"
    @on-close="toggleAddEmailConfirmModal"
  />

  <v-dialog
    v-model="isShowWorkStationModal"
    width="968px"
    class="custom__modal__box soletrader__modal__box"
    height="836px"
    max-width="100%"
    persistent
  >
    <div class="previous_btn">
      <v-btn
        variant="text"
        aria-label="Refresh"
        class="back_btn"
        prepend-icon="mdi-chevron-left"
        @click="onClickToggleTradesWorkStation"
        >back</v-btn
      >
    </div>
    <main class="main">
      <header class="custom__modal__header">
        <img
          class="open-logo"
          src="../../../assets/images/logo/iknowa-logo-v4.svg"
        />
        <h4>Business Setup</h4>
      </header>
      <div class="custom__modal__content">
        <div
          class="custom__modal__heading tw-flex tw-flex-col tw-gap-3 rmd:tw-gap-4"
        >
          <div class="custom__modal__head">
            Great job, <span>{{ userName }}</span> <br />
            You are a step closer in creating your Trade Workstation!
          </div>
          <Text
            variant="h6"
            textWeight="400"
            textColor="rgba(12, 15, 74, 0.5)"
            lineHeight="18px"
            >So we can personalise your Workstation, please tell us what type of
            business do you have.</Text
          >
        </div>
        <div class="custom__modal__card">
          <v-card @click="onClickSoleTrader()" class="modal_card">
            <div class="icon_btn">
              <v-icon
                ><img src="../../../assets/icons/connections.svg"
              /></v-icon>
            </div>
            <div class="modal_content">
              <h4>Sole Trader</h4>
              <p>Trading as Individual</p>
            </div>
          </v-card>
          <v-card @click="onClickLimitedCompany()" class="modal_card">
            <div class="icon_btn">
              <v-icon
                ><img src="../../../assets/icons/building-filled-icon.svg"
              /></v-icon>
            </div>
            <div class="modal_content">
              <h4>Limited Company</h4>
              <p>Registered on Companies House</p>
            </div>
          </v-card>
        </div>
        <!-- </main> -->
      </div>
    </main>
  </v-dialog>
  <SoleTraderWorkStationComponent
    v-if="isShowSoleTraderWorkStation"
    @onClickCloseTab="onClickCloseSoloTrader"
    @onLaunchTpWorkstation="onWorkStationClick($event)"
  />
  <LimitedCompanyWorkStationComponent
    v-if="isShowLimitedCompanyWorkStation"
    @onClickCloseTab="onClickCloseLimitedCompany"
    @onLaunchTpWorkstation="onWorkStationClick($event)"
  />
  <InsuranceModel
    v-if="openedModel === modelName.INSURANCE"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditInsurance="isOuterEditInsurance"
    :userInsuranceData="userInsuranceData"
    @clearUserInsuranceData="clearData"
  />

  <!-- <EditWorkStationDetailsPopup
    v-if="openedModel === modelName.ADDRESSMODAL"
    @closeEditWorkStationModal="onClickCloseModel"
  /> -->
  <CertificationModal
    v-if="openedModel === modelName.CERTIFICATION"
    :userCertificateData="userCertificateData"
    @onClickCloseTab="onClickCloseModel"
    @clearUserCertificateData="clearData"
  />
  <TaxAndVatModel
    v-if="openedModel === modelName.TAXANDVAT"
    :mode="taxModalMode"
    @onClickCloseTab="onClickCloseModel"
  />
  <InviteMemberForm
    v-if="openedModel === modelName.INVITEFORM"
    :onCloseForm="onClickCloseModel"
  />
  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :onClickCloseTab="onClickCloseModel"
  />

  <AddAddressModal
    v-if="openedModel === modelName.ADDRESSMODAL"
    @on-close-success="onClickCloseModel"
    @on-close="onClickCloseModel"
  />
  <AddUserRatesModal
    v-if="openedModel === modelName.RATEMODAL"
    @closeUpdateUserRatesModal="onClickCloseModel"
  />
  <!-- <SpecialismModel
    v-if="openedModel === modelName.SPECIALISM"
    @onClickCloseTab="onClickCloseModel"
  /> -->

  <SpecialismMultiSelectModel
    v-if="openedModel === modelName.SPECIALISM"
    @onClickCancel="onClickCloseModel"
  />
  <CreateTradeWSModal
    v-if="openedModel === 'CreateTradeWSModal'"
    @onClickCloseTab="onClickCloseModel"
  />
  <WelcomeTradePersonPopup
    v-if="
      user.loginAttempt === 1 &&
      isShowWelcomeTradePersonPopup &&
      !route.query.courseReservation
    "
    @onClickExploreButton="onClickExploreButton"
  ></WelcomeTradePersonPopup>
  <!-- <KYCStatusModal
    v-if="statusModal.KYC === currentStatusModalName"
    :onContinue="onClickCloseModel"
    :kycStatus="currentModalStatus"
  /> -->

  <TradePersonBenefitsModal
    v-if="isShowTradePersonBenefitsModal"
    @onClickClosePreviewModalButton="onClickClosePreviewModalButton"
  />

  <AddBusinessAddressPreviewModal
    v-if="
      openedModel === modelName.ADDBUSINESSADDRESSMODAL ||
      (!isUserWorkstationIsDefault &&
        showVerificationPrompType === BUSINESS_ADDRESS &&
        !closeAllVerificationPopupPrompt &&
        !isShowLimitedCompanyWorkStation &&
        !isShowSoleTraderWorkStation &&
        !isWorkstationLoading &&
        !loaderBuffer)
    "
    @onClickCloseTab="onClickCloseModel"
    @onClickCloseSuccessTab="onClickCloseSuccessModal"
  />

  <CompleteIDVerificationPreviewModal
    v-if="
      openedModel === modelName.VERIFYPREVIEWMODAL ||
      (!isUserWorkstationIsDefault &&
        showVerificationPrompType === ID_VERIFICATION &&
        !closeAllVerificationPopupPrompt &&
        !isShowLimitedCompanyWorkStation &&
        !isShowSoleTraderWorkStation &&
        !isWorkstationLoading &&
        !loaderBuffer)
    "
    @onClickCloseTab="onClickCloseModel"
  />

  <TradePassportPreviewModal
    v-if="
      openedModel === modelName.TRADEPASSPORTPREVIEWMODAL ||
      (!isUserWorkstationIsDefault &&
        showVerificationPrompType === TRADE_PASSPORT &&
        !closeAllVerificationPopupPrompt &&
        !isShowLimitedCompanyWorkStation &&
        !isShowSoleTraderWorkStation &&
        !isWorkstationLoading &&
        !loaderBuffer)
    "
    @onClickCloseTab="onClickCloseModel"
  />

  <WorkstationForVerificationPreviewModal
    v-if="
      openedModel === modelName.WORKSTATIONVERIFYPREVIEWMODAL ||
      (!isUserWorkstationIsDefault &&
        showVerificationPrompType === SUBMIT_WS &&
        !closeAllVerificationPopupPrompt &&
        !isShowLimitedCompanyWorkStation &&
        !isShowSoleTraderWorkStation &&
        !isWorkstationLoading &&
        !loaderBuffer)
    "
    @onClickCloseTab="onClickCloseModel"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    :type="confirmDeleteType"
    :label="confirmDeleteLabel"
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
  ></ConfirmDeleteModal>

  <BuySpotConfirmation
    v-if="isShowBuySpotConfirmationModal"
    @on-close="onCloseIsShowBuySpotConfirmationModal"
  />

  <UpgradeSubscriptionModal
    v-if="isShowUpgradeSubscriptionModal"
    @on-close="onCloseIsShowUpgradeSubscriptionModal"
    @on-upgrade-success="onCourseUpdateSuccess"
    @on-upgrade-fail="onCourseUpdateFailed"
  />

  <PaymentStatusModal
    v-if="isOpenPaymentStatusModal"
    :onContinue="onClosePamentStatusModal"
    :paymentStatus="pamentStatus"
    :planType="paymentType"
  />

  <HasSpotNotice
    v-if="isShowHasSpotNotice"
    @on-close="onCloseHasSpotNoticeModal"
  />

  <HasReservedSpotNotice
    v-if="isShowHasSpotReservedNotice"
    @on-close="onCloseHasSpotReserveNoticeModal"
  />

  <AssignCourseSlotToMemberModal
    :selectedUserCourseIdForAssign="selectedUserCourseIdForAssignMember"
    @onClickCloseButton="toggleAssignCourseSlotToMemberModal"
    @memberInvited="getTeamMember"
    v-if="isOpenAssignCourseSlotToMemberModal"
  />

  <TpSubscriptionModal
    v-if="isShowUpgradePremiumModal && !isPropertyOwner"
    @onClickCloseTab="closeUpgradePremiumModal"
    @onSuccessPayment="handleSubscriptionSuccess"
  />

  <SuccessWorkstationCreationModal
    v-if="getWsModalStates.isShowSuccessWsCreationModal"
    @on-close="onCloseSuccessWorkstationCreationModal"
  />

  <RejectConfirmationModal
    v-if="rejectConfirmationModal"
    @on-close="onCloseConfirmRejectModal"
    @on-reject="onClickRejectWSInvitation(invitationId)"
  />
</template>

<script lang="ts">
import {
  computed,
  onBeforeMount,
  ref,
  onUnmounted,
  watch,
  onMounted,
  getCurrentInstance,
} from "vue";
import PendingChip from "./Chips/PendingChip.vue";
import {
  MemberInvitationStatusEnum,
  PaymentPlanType,
  RolesEnum,
  UserWorkstationVerificationType,
} from "@/core/enums/RolesEnum";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import SoleTraderWorkStationComponent from "@/modules/workstation/soletrader/components/SoleTraderWorkStationComponent.vue";
import LimitedCompanyWorkStationComponent from "@/modules/workstation/limitedcompany/components/LimitedCompanyWorkStationComponent.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { WORKSTATION } from "@/store/modules/workstation";
import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
import CertificationModal from "@/modules/certification/components/CertificationModel.vue";
import TaxAndVatModel from "@/modules/tax-and-vat/components/TaxAndVatModel.vue";
// import DashBoardWelcome from "@/core/components/DashBoardWelcome.vue";
import ItemListCard from "@/core/components/ItemListCard.vue";
import InviteMemberForm from "@/core/components/InviteMemberForm.vue";
import WorkStationVerificationLock from "@/core/components/WorkStationVerificationLock.vue";
import WorkStationVerificationProgress from "@/core/components/WorkStationVerificationProgress.vue";
// import ToolTip from "@/core/components/common/ToolTip.vue";
import InfoIconRed from "@/core/components/icons/InfoIconRed.vue";

import {
  AUTH_SESSION_EXPIRY,
  AUTH_USER,
  CRYPTO_SECRET_KEY,
} from "@/core/constants";
import CryptoJS from "crypto-js";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
// import AddressDetailsComponentModal from "@/modules/profile/components/AddressDetailsComponent.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { JOBS_ROUTE } from "@/modules/jobs/routes";
import { COURSE_ROUTE } from "@/modules/courses/routes";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
// import SpecialismModel from "@/modules/Specialism/components/SpecialismModel.vue";
import SpecialismMultiSelectModel from "@/modules/Specialism/components/SpecialismMultiSelectModel.vue";
import { decryptModalData } from "@/core/helpers/workstation.helper";
import WelcomeTradePersonPopup from "@/modules/dashboard/components/WelcomeTradePersonPopup.vue";
import {
  getCombineSameProjectReview,
  getLocalStorageWithExpiry,
  isAliased,
  isExpiredDate,
  setDirectStoragePathToImage,
  setLocalStorageWithExpiry,
} from "@/core/utils/common";
import { parseJSON } from "@/core/utils/common";
import CreateTradeWSModal from "@/modules/dashboard/components/CreateTradeWSModal.vue";

import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
// import MyWorkstationChip from "./Chips/MyWorkstationChip.vue";
// import SignInChip from "./Chips/SignInChip.vue";
// import MemberChip from "./Chips/MemberChip.vue";
// import DirectorChip from "./Chips/DirectorChip.vue";
import LunchIcon from "@/core/components/icons/LunchIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
// import InfoIconWhite from "@/core/components/icons/InfoIconWhite.vue";
// import CheckIconWhite from "@/core/components/icons/CheckIconWhite.vue";
// import AcceptChip from "./Chips/AcceptChip.vue";
// import RejectChip from "./Chips/RejectChip.vue";
import ApproveChip from "./Chips/ApproveChip.vue";
// import AddEmailActionChip from "./Chips/AddEmailActionChip.vue";
import DeleteActionChip from "./Chips/DeleteActionChip.vue";
import AddEmailModal from "./Modals/AddEmailModal.vue";
import EmailConformModal from "./Modals/EmailConformModal.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import ProfileVerificationStatusChip from "./Chips/ProfileVerificationStatusChip.vue";
// import TradePassportActionChip from "./Chips/TradePassportActionChip.vue";
import SpecialismItem from "@/core/components/common/SpecialismItem.vue";

// WIDGETS
import JobsOverviewWidget from "./Widgets/JobsOverviewWidget.vue";
import TeamWidget from "./Widgets/TeamWidget.vue";
import TradeNetworkWidget from "./Widgets/TradeNetworkWidget.vue";
import CalendarEventWidget from "./Widgets/CalendarEventWidget.vue";

// Cards
// import SpecialismsCard from "./Cards/SpecialismsCard.vue";
// import InsuranceCard from "./Cards/InsuranceCard.vue";
// import TaxDetailsCard from "./Cards/TaxDetailsCard.vue";
// import CertificateCard from "./Cards/CertificateCard.vue";
// import RatesCard from "./Cards/RatesCard.vue";

// Modals
import AddAddressModal from "@/modules/dashboard/components/Modals/AddAddressModal.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";

import Loader from "@/core/components/common/Loader.vue";
import NoticeIcon from "@/core/components/icons/NoticeIcon.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CheckGreenShield from "@/core/components/icons/CheckGreenShield.vue";
// import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
// import SiltPendingChip from "./Chips/SiltPendingChip.vue";
// import SiltVerifiedChip from "./Chips/SiltVerifiedChip.vue";
import SiltRequiredChip from "./Chips/SiltRequiredChip.vue";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { getImageApiUrl, getImageStringToImageURL } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_LAUNCH_WS,
} from "@/modules/dashboard/routes";
// import KYCStatusModal from "@/core/components/KYCStatusModal.vue";
import manageEmailService from "@/core/services/manage-email.service";
import TradePersonBenefitsModal from "@/modules/dashboard/components/TradePersonBenefitsModal.vue";
import AddUserRatesModal from "@/modules/trade-passport/components/AddUserRatesModal.vue";
// import EditWorkStationDetailsPopup from "@/modules/trade-passport/components/EditWorkStationDetailsPopup.vue";

import AddBusinessAddressPreviewModal from "@/modules/dashboard/components/Modals/AddBusinessAddressPreviewModal.vue";
import CompleteIDVerificationPreviewModal from "@/modules/dashboard/components/Modals/CompleteIDVerificationPreviewModal.vue";
import TradePassportPreviewModal from "@/modules/dashboard/components/Modals/TradePassportPreviewModal.vue";
import WorkstationForVerificationPreviewModal from "@/modules/dashboard/components/Modals/WorkstationForVerificationPreviewModal.vue";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
import { UserNotificationType } from "@/core/enums";
// import InputSelect from "@/core/components/common/InputSelect.vue";
// import WhiteMarker from "@/core/components/icons/WhiteMarker.vue";
// import CircularCurrencyPound from "@/core/components/icons/CircularCurrencyPound.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import CommonLoader from "@/core/components/CommonLoader.vue";
import UpgradeSubscriptionModal from "./Modals/UpgradeSubscriptionModal.vue";
import PaymentStatusModal from "@/core/components/PaymentStatusModal.vue";
import BuySpotConfirmation from "./Modals/BuySpotConfirmation.vue";
import HasSpotNotice from "@/modules/dashboard/components/Modals/HasSpotNotice.vue";
import NotificationIcon from "@/core/components/icons/NotificationIcon.vue";

import {
  airSourceHeatPumpData,
  greenSkillsData,
} from "@/modules/courses/data/courseDetails";
import UserService from "@/core/services/user.service";
import AssignCourseSlotToMemberModal from "./Modals/AssignCourseSlotToMemberModal.vue";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import { reviewRatingState } from "@/core/models/project-rating";
import HasReservedSpotNotice from "@/modules/dashboard/components/Modals/HasReservedSpotNotice.vue";
import MapMarkerBlueIcon from "@/core/components/icons/MapMarkerBlueIcon.vue";
import MapMarkerYellowIcon from "@/core/components/icons/MapMarkerYellowIcon.vue";
import CalendarIcon from "@/core/components/icons/CalendarIcon.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { UserWorkstationSubStatusEnum } from "@/core/enums";
import TpSubscriptionModal from "@/modules/workstation/soletrader/components/TpSubscriptionModal.vue";
import SuccessWorkstationCreationModal from "@/modules/workstation/components/SuccessWorkstationCreationModal.vue";
import coursesService from "@/core/services/courses.service";
import RejectConfirmationModal from "./Modals/RejectConfirmationModal.vue";

export default {
  components: {
    SoleTraderWorkStationComponent,
    LimitedCompanyWorkStationComponent,
    InsuranceModel,
    CertificationModal,
    TaxAndVatModel,
    // DashBoardWelcome,
    // ItemListCard,
    InviteMemberForm,
    WorkStationVerificationLock,
    WorkstationVerificationModal,
    // AddressDetailsComponentModal,
    UserProfileLogo,
    // SpecialismModel,
    SpecialismMultiSelectModel,
    WelcomeTradePersonPopup,
    CreateTradeWSModal,
    TradePersonBenefitsModal,
    Card,
    Button,
    Text,
    ProfileThumbnail,
    // MyWorkstationChip,
    // SignInChip,
    // MemberChip,
    // DirectorChip,
    LunchIcon,
    TrashIcon,
    // InfoIconWhite,
    // CheckIconWhite,
    // AcceptChip,
    // RejectChip,
    DeleteActionChip,
    // AddEmailActionChip,
    ApproveChip,
    PendingChip,
    AddEmailModal,
    EmailConformModal,
    Postcode,
    ProfileVerificationStatusChip,
    // TradePassportActionChip,
    JobsOverviewWidget,
    TeamWidget,
    TradeNetworkWidget,
    // SpecialismsCard,
    // InsuranceCard,
    // RatesCard,
    // CertificateCard,
    // SpecialismItem,
    CalendarEventWidget,
    // KYCStatusModal,
    Loader,
    AddAddressModal,
    NoticeIcon,
    // SiltPendingChip,
    // SiltVerifiedChip,
    SiltRequiredChip,
    // ErrorRedIcon,
    CheckGreenShield,
    InfoIconRed,
    ChipState,
    ConfirmDeleteModal,
    AddUserRatesModal,
    // EditWorkStationDetailsPopup,
    AddBusinessAddressPreviewModal,
    CompleteIDVerificationPreviewModal,
    TradePassportPreviewModal,
    WorkstationForVerificationPreviewModal,
    WorkStationProfileLogo,
    // InputSelect,
    // CircularCurrencyPound,
    // WhiteMarker,
    CommonLoader,
    UpgradeSubscriptionModal,
    PaymentStatusModal,
    BuySpotConfirmation,
    HasSpotNotice,
    NotificationIcon,
    AssignCourseSlotToMemberModal,
    WorkStationVerificationProgress,
    HasReservedSpotNotice,
    MapMarkerBlueIcon,
    MapMarkerYellowIcon,
    CalendarIcon,
    TpSubscriptionModal,
    SuccessWorkstationCreationModal,
    // ToolTip,
    RejectConfirmationModal,
  },
  setup() {
    const BUSINESS_ADDRESS = "business address";
    const ID_VERIFICATION = "id verification";
    const TRADE_PASSPORT = "trade passport";
    const SUBMIT_WS = "submit ws";

    const internalInstance = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const InviteMemberImage = ref();

    const teamMemberLoading = ref(false);
    const members = ref([]) as any;

    const statusModal = { PAYMENT: "payment", KYC: "kyc" };
    const currentStatusModalName = ref("");
    const currentModalStatus = ref("");

    const siltVerificationModal = ref(false);
    const isShowTradePersonBenefitsModal = ref(false);

    const selectedEmailFilter = ref(null) as any;

    const isShowHasSpotReservedNotice = ref(false);

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteLabel = ref("");
    const confirmDeleteType = ref("");
    const confirmDeleteId = ref(null) as any;
    const confirmDeleteCertificateData = ref({
      type: "certificate",
      label: "You're about to delete your certification.",
    });
    const confirmDeleteInsuranceData = ref({
      type: "insurance",
      label: "You're about to delete your insurance.",
    });

    const rejectConfirmationModal = ref(false);
    const invitationId = ref(null) as any;

    // verification popups
    const toShowVerificationPopupPrompt = ref(BUSINESS_ADDRESS);
    const closeAllVerificationPopupPrompt = ref(false);

    // courses
    const getCourseloading = ref(false);
    const listOfCourse = ref([]) as any;
    const coursesWithExtraInfo = ref([]) as any;
    const detailsItems = ref();
    const isOpenAssignCourseSlotToMemberModal = ref(false);
    const isShowWelcomeTradePersonPopup = ref(false);
    const isShowPassword = ref(false);
    const isShowPaymentPlanModal = ref(false);
    const modelName = {
      INSURANCE: "insurance",
      CERTIFICATION: "certification",
      TAXANDVAT: "tax&vat",
      INVITEFORM: "inviteForm",
      VERIFYMODAL: "verifyModal",
      ADDRESSMODAL: "addressModal",
      SPECIALISM: "SpecialismsModal",
      RATEMODAL: "Hourly&RateModal",
      EDITWORKSTATION: "editWorkstation",
      ADDBUSINESSADDRESSMODAL: "addBusinessAddress",
      VERIFYPREVIEWMODAL: "verifyPreviewModal",
      TRADEPASSPORTPREVIEWMODAL: "tradePassportPreview",
      WORKSTATIONVERIFYPREVIEWMODAL: "workstationVerifyPreview",
    };
    const memberPermissions = ref([
      { id: 1, label: "Director", name: "Admin" },
      { id: 2, label: "Admin", name: "Admin" },
      { id: 3, label: "Edit", name: "Edit" },
      { id: 4, label: "View", name: "View" },
    ]);
    const userWorkStationImage = computed(
      () => store.getters[`${USER_STORE}/userWorkStationImage`]
    );
    const isWorkstationLoading = computed(
      () => store.getters[`${WORKSTATION}/loadingWorkstation`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );

    const getWsModalStates = computed(
      () => store.getters[`${WORKSTATION}/getWsModal`]
    );
    const isUserWorkStationAdmin = computed(
      () => store.getters[`${WORKSTATION}/isUserWorkStationAdmin`]
    );
    const userNotifications = computed(
      () => store.getters[`${USER_STORE}/userNotifications`]
    );

    const isSetPaymentPlanForCourse = computed(() =>
      localStorage.getItem("isSetPaymentPlanForCourse")
    );

    const toolTipProfile = computed(() => {
      if (userVerifyStatus.value == 2)
        return ` <h3 style='margin: 0; color: #40ae42;'>
              KYC Verified
          </h3>
            <p style='margin: 5px 0 0;'> Your identity has been successfully verified! Your account is now fully secure and ready to access all features. Thank you for completing the verification process. </p>
            `;
      else
        return `<h3 style='margin: 0; color: #faa500;'>
            KYC Pending
          </h3>
          <p style='margin: 5px 0 0;'>
            Your identity verification is pending. This step ensures the security of your account, protecting it from fraud and unauthorized access.
          </p>`;
    });
    const openedModel = ref("");
    const taxModalMode = ref("Add");

    const isShowAllws = ref(false);

    const isShowUpgradeSubscriptionModal = ref(false);
    const isShowBuySpotConfirmationModal = ref(false);
    const isShowHasSpotNotice = ref(false);

    const onSubmitVerification = ref(false);

    const tradePassportPanel = ref([0]);
    const WorkStationInvitation = ref([]) as any;
    const isWorkstationEmpty = ref(false);
    const isShowAddEmailModal = ref(false);
    const isShowAddEmailConformModal = ref(false);
    const manageEmailList: any = ref([]);
    const acceptedEmailList: any = ref([]);
    const acceptedEmailsWithPrimaryEmail: any = ref([]);
    const emailConfirmModalType = ref("");
    const manageEmail = ref() as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userFullName = computed(() => {
      return `${user.value?.firstName} ${user.value?.lastName}`;
    });
    const userName = computed(() => {
      return `${user.value?.firstName}`;
    });
    const userEmail = computed(() => {
      return `${user.value?.email}`;
    });

    const isRegisteredForCourse = computed(
      () => store.getters[`${USER_STORE}/isRegisteredForCourse`]
    );

    const courseId = computed(
      () => store.getters[`${COURSES_STORE}/getCourseId`]
    );
    const getCourseEventToBeCreated = computed(
      () => store.getters[`${COURSES_STORE}/getCourseEventToBeCreated`]
    );

    const isSubmitWsForVerification = computed(
      () => store.getters[`${WORKSTATION}/getIsSubmitWsForVerification`]
    );
    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );
    const launchInvitedWorkstation = ref(false);
    const tabListItems = [
      {
        tab: "step1",
        name: "Switching Account...",
      },
      {
        tab: "step2",
        name: "Fetching Account Data...",
      },
      {
        tab: "step3",
        name: "Loading Account...",
      },
    ];

    const switchingText = ref("Switching Account...");
    const currentSelectedTab = ref(tabListItems[0]);

    const onCloseConfirmRejectModal = () => {
      invitationId.value = null;
      rejectConfirmationModal.value = false;
    };

    const onOpenConfirmRejectModal = (invitationID: number) => {
      invitationId.value = invitationID;
      rejectConfirmationModal.value = true;
    };

    const onOpenIsShowUpgradeSubscriptionModal = () => {
      isShowUpgradeSubscriptionModal.value = true;
    };

    const onCloseIsShowUpgradeSubscriptionModal = async () => {
      const courseReservation = parseJSON(
        localStorage.getItem("courseReservationDetails")
      );
      const filteredData = reservedSpotsData.value.filter(
        (data: any) =>
          data.course.id == parseInt(courseReservation.courseId, 10) &&
          data.subscription == null
      );
      if (isSetPaymentPlanForCourse.value && !filteredData.length) {
        await deleteUserCourse();
      }
      store.dispatch(`${COURSES_STORE}/setStoredReservedSpotsData`, null);
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isFromCourse: false,
      });
      router.push({ name: DASHBOARD_ROUTE });
      isShowUpgradeSubscriptionModal.value = false;
    };

    const onOpenIsShowBuySpotConfirmationModal = () => {
      isShowBuySpotConfirmationModal.value = true;
    };

    const coursesWithRemainingSlots = computed(
      () => store.getters[`${COURSES_STORE}/getCoursesWithRemainingSlots`]
    );
    const onCloseIsShowBuySpotConfirmationModal = () => {
      store.dispatch(`${COURSES_STORE}/setStoredReservedSpotsData`, null);
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isFromCourse: false,
      });
      router.push({ name: DASHBOARD_ROUTE });
      isShowBuySpotConfirmationModal.value = false;
    };

    const onCloseSuccessWorkstationCreationModal = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: false,
        isShowSoleTraderWorkStation: false,
        isShowLimitedCompanyWorkStation: false,
        activeSoleTraderWorkStationModal: 1,
        activeLimitedCompanyWorkStationModal: 1,
        isShowSuccessWsCreationModal: false,
      });
    };

    const toggleAddEmailModal = (event: boolean) => {
      isShowAddEmailModal.value = event;
    };
    const toggleAddEmailConfirmModal = (status = false) => {
      isShowAddEmailConformModal.value = !isShowAddEmailConformModal.value;
      if (status) {
        manageEmailList.value = manageEmailList.value.filter(
          (data: any) => data.id !== manageEmail.value?.id
        );
        manageEmail.value = "";
      }
    };

    const workStationNotificationCount = computed(() => {
      const countObj: any = {};
      userNotifications.value.forEach((notification: any) => {
        countObj[notification?.userWorkstationId] =
          (countObj[notification?.userWorkstationId] || 0) + 1;
      });
      return countObj;
    });
    const setManageEmailList = async () => {
      try {
        const response: any = await manageEmailService.getEmailList(
          user.value?.id
        );
        manageEmailList.value = response?.aliases;
        acceptedEmailList.value = manageEmailList.value.filter(
          (email: any) => email.invitationStatus === "accepted"
        );
        const primaryEmail = {
          isPrimary: true,
          aliasEmail: response.email,
          props: { subtitle: "Primary" },
        };
        acceptedEmailsWithPrimaryEmail.value = [
          primaryEmail,
          ...acceptedEmailList.value,
        ];
        // selectedEmailFilter.value = primaryEmail;
      } catch (error) {
        console.error("get email list error", error);
      }
    };
    const onClickDeleteEmail = (data: any) => {
      manageEmail.value = data;
      emailConfirmModalType.value = "delete";
      toggleAddEmailConfirmModal();
    };
    const onClickAddEmail = (addEmailData: object) => {
      manageEmailList.value.push(addEmailData);
      isShowAddEmailModal.value = false;
      emailConfirmModalType.value = "success";
      toggleAddEmailConfirmModal();
    };

    const assignMyselfDirector = () => {
      console.log("assignMyselfDirector");
    };
    const onChangeMemberPermission = async (role: any, member: any) => {
      try {
        await store.dispatch(`${WORKSTATION}/updateUserWorkstationMembers`, {
          id: member.id,
          userId: user.value.id,
          role: role.value,
        });
        store.dispatch(`${WORKSTATION}/setUserWorkstationMembers`, {
          userId: user.value.id,
        });
      } catch (error) {
        console.log();
      }
    };

    const userWorkstationMembers = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstationMembers`]
    );

    const openSiltVerification = () => {
      siltVerificationModal.value = true;
    };
    const closeSiltVerification = () => {
      siltVerificationModal.value = false;
    };
    const checkIfHasWorkstationInvitation = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.wsmt) {
        try {
          const cleanToken = params.wsmt.replace("wsmt=", "");
          const token = CryptoJS.AES.decrypt(
            cleanToken,
            CRYPTO_SECRET_KEY
          ).toString(CryptoJS.enc.Utf8);

          await store.dispatch(`${WORKSTATION}/acceptUserWorkstationMember`, {
            userId: user.value.id,
            key: token,
          });
        } catch (error) {
          console.log();
        }
      }
    };

    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trade WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;

    const onClickToggleTradesWorkStation = () => {
      router.push({
        path: `/create-workstation/trade-owner`,
      });
      // store.dispatch(`${WORKSTATION}/setWsModal`, {
      //   isShowWorkStationModal: !isShowWorkStationModal.value,
      // });
    };

    const onClickSoleTrader = () => {
      if (!isFromCourse.value) {
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          isShowWorkStationModal: false,
          isShowSoleTraderWorkStation: true,
          isFromCourse: false,
        });
      } else {
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          isShowWorkStationModal: false,
          isShowSoleTraderWorkStation: true,
          isFromCourse: true,
        });
      }
    };
    const isFromCourse = computed(
      () => store.getters[`${WORKSTATION}/isFromCourse`]
    );
    const onClickLimitedCompany = () => {
      if (!isFromCourse.value) {
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          isShowWorkStationModal: false,
          isShowLimitedCompanyWorkStation: true,
          isFromCourse: false,
        });
      } else {
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          isShowWorkStationModal: false,
          isShowLimitedCompanyWorkStation: true,
          isFromCourse: true,
        });
      }
    };

    const onClickCloseSoloTrader = () => {
      // console.log("runneedd hereeeee");
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: true,
        isShowSoleTraderWorkStation: false,
        isFromCourse: false,
      });
    };

    const onClickCloseLimitedCompany = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: true,
        isShowLimitedCompanyWorkStation: false,
        isFromCourse: false,
      });
    };

    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );

    const filteredUserAllWorkStation = computed(() => {
      const workstations = userAllWorkStation.value.filter(
        (workstation: any) => workstation.name !== "Default"
      );

      if (selectedEmailFilter.value && selectedEmailFilter.value.isPrimary) {
        const filteredWsByPrimaryEmail = workstations.filter(
          (workstation: any) => !workstation?.aliasEmail
        );
        return filteredWsByPrimaryEmail;
      }
      if (selectedEmailFilter.value && !selectedEmailFilter.value.isPrimary) {
        const filteredWsBySelectedEmailAlias = workstations.filter(
          (workstation: any) =>
            workstation?.aliasEmail?.aliasEmail ===
            selectedEmailFilter.value.aliasEmail
        );
        return filteredWsBySelectedEmailAlias;
      }
      return workstations;
    });

    const numberOfWsShowed = computed(() => {
      if (isShowAllws.value) {
        return filteredUserAllWorkStation.value.length;
      }
      return 3;
    });

    const isShowWorkStationModal = computed(
      () => store.getters[`${WORKSTATION}/getWorkStationModelState`]
    );

    const isShowSoleTraderWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getSoleTraderWorkStationState`]
    );
    const isShowLimitedCompanyWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getLimitedCompanyWorkStationState`]
    );
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const accountStatuses = computed(
      () => store.getters[`${USER_STORE}/accountStatuses`]
    );

    const reservedSpotsData = computed(
      () => store.getters[`${COURSES_STORE}/reservedSpotsData`]
    );

    const storedReservedSpotsData = computed(
      () => store.getters[`${COURSES_STORE}/storedReservedSpotsData`]
    );

    const filteredReserveSpots = computed(() => {
      const courseReservation = parseJSON(
        localStorage.getItem("courseReservationDetails")
      );
      const courseId = route?.params?.courseId || courseReservation?.courseId;

      if (
        storedReservedSpotsData.value &&
        storedReservedSpotsData.value.length
      ) {
        // Filter spots by courseId and and no subscription
        return storedReservedSpotsData.value.filter((spot: any) => {
          return spot.course?.id == courseId && !spot.subscription;
        });
      }

      return [];
    });

    const onClickOpenModel = (modalName: string, mode = "") => {
      if (activeUserWorkstation.value?.name !== "Default") {
        if (modalName === modelName.TAXANDVAT && mode !== "") {
          taxModalMode.value = mode;
        }
        openedModel.value = modalName;
        isOuterEditInsurance.value = false;
        userCertificateData.value = null;
      } else {
        openedModel.value = "CreateTradeWSModal";
      }
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      closeAllVerificationPopupPrompt.value = true;
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    };

    const onClickCloseSuccessModal = async () => {
      openedModel.value = "";
      closeAllVerificationPopupPrompt.value = false;
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    };

    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );
    const isOuterEditInsurance = ref(false);
    const userInsuranceData = ref(null) as any;
    const userCertificateData = ref(null) as any;
    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const onEditInsurance = async (insurance: object) => {
      userInsuranceData.value = insurance;
      isOuterEditInsurance.value = true;
      openedModel.value = modelName.INSURANCE;
    };
    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    const userWorkStationStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const userVerifyStatus = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]?.user
          ?.userKYCVerificationStatus
    );

    const userWorkstationStatusByWord = computed(() => {
      if (userVerifyStatus.value === UserVerificationStatusEnum.NOT_ADDED) {
        return "required";
      }
      if (userVerifyStatus.value === UserVerificationStatusEnum.VERIFIED) {
        return "verified";
      }
      if (
        userVerifyStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        return "pending";
      }
      return "";
    });

    const isUserWorkstationIsDefault = computed(
      () =>
        activeUserWorkstation.value?.name === "Default" &&
        activeUserWorkstation.value?.status === "active"
    );

    const isUserAddedCompanyInformation = computed(
      () => userInsurances.value?.length
    );

    const workstationSetupProgress = computed(() => {
      if (
        !isUserWorkstationIsDefault.value &&
        isUserProfileVerified.value &&
        isUserTaxDetailsAdded.value
      ) {
        return 66;
      }
      if (!isUserWorkstationIsDefault.value) {
        return 33;
      }
      return 0;
    });

    const workstationSetupProgressNew = computed(() => {
      let score = 0;
      if (userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED) {
        score += 20;
      }
      if (!isUserWorkstationIsDefault.value && isUserProfileVerified.value) {
        score += 20;
      }
      if (
        !isUserWorkstationIsDefault.value &&
        (activeUserWorkstation.value?.user?.userKYCVerificationStatus ===
          UserVerificationStatusEnum.VERIFIED ||
          activeUserWorkstation.value?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION)
      ) {
        score += 20;
      }
      if (!isUserWorkstationIsDefault.value && isUserAddBusinessAddress.value) {
        score += 20;
      }
      if (!isUserWorkstationIsDefault.value) {
        score += 20;
      }
      return score;
    });

    watch(
      () => isUserWorkstationIsDefault.value,
      (newVal) => {
        if (newVal) {
          console.log("runneddd");
          getWorkStationInvitation();
        }
      }
    );

    // watch(
    //   () => selectedEmailFilter.value,
    //   (newVal) => {
    //     if (newVal) {
    //       console.log(newVal, "selectedEmailFilter");
    //     }
    //   },
    //   { immediate: true, deep: true }
    // );

    const onCloseHasSpotNoticeModal = async () => {
      isShowHasSpotNotice.value = false;
      await router.push({ name: DASHBOARD_ROUTE });
    };

    const onOpenHasSpotNoticeModal = async () => {
      isShowHasSpotNotice.value = true;
    };

    const onCloseHasSpotReserveNoticeModal = async () => {
      isShowHasSpotReservedNotice.value = false;
      await router.push({ name: DASHBOARD_ROUTE });
    };

    const onOpenHasSpotReserveNoticeModal = async () => {
      isShowHasSpotReservedNotice.value = true;
    };

    const toggleShowWs = () => {
      isShowAllws.value = !isShowAllws.value;
    };

    const onDeleteItem = (id: any, type: string, label: string) => {
      confirmDeleteId.value = id;
      confirmDeleteLabel.value = label;
      confirmDeleteType.value = type;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteId.value = null;
      confirmDeleteLabel.value = "";
      confirmDeleteType.value = "";
      isOpenConfirmDelete.value = false;
    };

    const deleteCertificate = async (certificateId: number) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificateId,
        });
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async (e: any) => {
      if (e === confirmDeleteCertificateData.value.type) {
        await deleteCertificate(confirmDeleteId.value);
        await onCloseConfirmDelete();
      }
      if (e === confirmDeleteInsuranceData.value.type) {
        await onDeleteInsurance(confirmDeleteId.value);
        await onCloseConfirmDelete();
      }
    };

    const isShowUpgradePremiumModal = ref(false);

    const isPropertyOwner = computed(() => {
      return user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER;
    });

    const closeUpgradePremiumModal = async () => {
      if (isSubmitWsForVerification.value) {
        store.commit(`${WORKSTATION}/setIsSubmitWsForVerification`, false);
      }
      const courseReservation = parseJSON(
        localStorage.getItem("courseReservationDetails")
      );
      const filteredData = reservedSpotsData.value?.filter(
        (data: any) =>
          data.course.id == parseInt(courseReservation.courseId, 10) &&
          data.subscription == null
      );
      if (isSetPaymentPlanForCourse.value && !filteredData.length) {
        await deleteUserCourse();
      }
      isShowUpgradePremiumModal.value = false;
    };

    const defaultLoadingOfAllData = ref(false);

    const onClickSubmitVerification = async () => {
      if (
        !activeUserWorkstation.value?.subscription ||
        activeUserWorkstation.value?.subStatus !==
          UserWorkstationSubStatusEnum.SUCCEEDED
      ) {
        isShowUpgradePremiumModal.value = true;
      } else {
        defaultLoadingOfAllData.value = true;
        if (isUserProfileVerified.value) {
          try {
            onSubmitVerification.value = true;
            const data = {
              userId: user.value?.id,
              userWorkstationId: activeUserWorkstation.value?.id,
              formData: {
                verificationStatus:
                  UserVerificationStatusEnum.PENDING_VERIFICATION,
              },
            };
            await store.dispatch(
              `${WORKSTATION}/updateUserWorkstationStatus`,
              data
            );
          } catch (err) {
            console.log(err);
          } finally {
            onSubmitVerification.value = false;
          }
        }
      }
    };
    const redirectToCourseDetails = () => {
      router.push({
        name: "courseDetails",
        params: {
          courseId: courseId.value,
          variableName: "course",
        },
      });
    };
    // NOTE: Must be isUserProfileVerifiable
    const isUserProfileVerified = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );

    const isUserAddBusinessAddress = computed(() => {
      if (
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
      ) {
        return user.value.userBusinessDetails.businessPostcode;
      } else {
        return user.value.userBusinessDetails?.residencyPostcode;
      }
    });

    const userBusinessType = computed(
      () => user.value?.userBusinessDetails?.type
    );
    const handleSubscriptionSuccess = async () => {
      isSetPaymentPlanForCourse.value
        ? await onClickCheckSubscriptionPlan()
        : await onClickSubmitVerification();
    };
    //calenderData
    const masks = {
      weekdays: "WWW",
    };
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const calenderAttribute = [
      {
        key: 1,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Lunch with mom.",
          class: "bg-red-600 text-white",
        },
        dot: true,
        popover: {
          label: "Lunch with mom.",
          class: "bg-red-600 text-white",
        },
        dates: new Date(year, month, 1),
      },
      {
        key: 2,
        dot: true,
        highlight: true,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        popover: {
          label: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 2),
      },
      {
        key: 3,
        dot: true,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        popover: {
          label: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 13),
      },
      {
        key: 4,
        dot: true,
        description: "Take Noah to basketball practice.",
        customData: {
          title: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        popover: {
          label: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 23),
      },
    ];

    const isUserTaxDetailsAdded = computed(
      () =>
        user.value?.userBusinessDetails?.taxReferenceNumber &&
        user.value?.userBusinessDetails?.ukVatNumber
    );

    const WorkStationSiltVerificationStatus = computed(
      () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
    );
    const getVerificationStatus = computed(() => {
      const statusString =
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
          ? "KYB"
          : "KYC";

      const verificationStatus: any = {
        ERROR: {
          label: `${statusString} Not Verified`,
          icon: "mdi-information",
          statusClass: "info",
        },
        PENDING: {
          label: `${statusString} Verification Pending`,
          icon: "mdi-clock-time-eight",
          statusClass: "pending",
        },
        SUCCESS: {
          label: `${statusString} Verified`,
          icon: "mdi-check-circle",
          statusClass: "success",
        },
      };
      if (WorkStationSiltVerificationStatus.value) {
        const status = WorkStationSiltVerificationStatus?.value?.status;
        return verificationStatus[status] || verificationStatus.ERROR;
      } else {
        return verificationStatus.ERROR;
      }
    });

    const showVerificationPrompType = computed(() => {
      toShowVerificationPopupPrompt.value = "";
      return "";
    });

    // watch(
    //   () => showVerificationPrompType.value,
    //   (newVal) => {
    //     if (newVal) {
    //       console.log(newVal, "showVerificationPrompType new value");
    //     }
    //   },
    //   { immediate: true, deep: true }
    // );

    const getWorkstationPostcode = (type: string) => {
      if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
        return user.value?.userBusinessDetails?.businessPostcode;
      }
      return user.value?.userBusinessDetails?.residencyPostcode;
    };

    const getWorkstationAddress = (type: string) => {
      if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
        return user.value?.userBusinessDetails?.businessAddress;
      }
      return user.value?.userBusinessDetails?.residencyAddressLine;
    };

    const onClickTaxVatDeleteButton = async (filedKey: string) => {
      try {
        await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
          userId: user.value.id,
          formData: {
            taxReferenceNumber:
              user.value?.userBusinessDetails?.taxReferenceNumber,
            type: user.value?.userBusinessDetails?.type,
            userVerificationStatus:
              user.value?.userBusinessDetails?.userVerificationStatus,
            ukVatNumber: user.value?.userBusinessDetails?.ukVatNumber,
            ...{ [`${filedKey}`]: null },
          },
        });
      } catch (error) {
        console.log();
      } finally {
        await store.dispatch(`${USER_STORE}/initializeUserState`);
      }
    };

    const onClickUpdateProfile = () => {
      router.push("/profile");
    };

    const showTradeName = computed(() => {
      if (
        activeUserWorkstation.value &&
        activeUserWorkstation.value?.name === "Default"
      ) {
        return userFullName.value;
      } else {
        return activeUserWorkstation.value?.name;
      }
    });

    const onClickAddress = () => {
      // if (user.value?.address) return;
      openedModel.value = modelName.ADDRESSMODAL;
    };

    const onClickTradePassport = () => {
      router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };

    const navigateToJobs = () => {
      router.push({ name: JOBS_ROUTE });
    };

    const navigateToCourse = () => {
      router.push({ name: COURSE_ROUTE });
    };

    const onOpenTPSubscriptionModal = () => {
      isShowUpgradePremiumModal.value = true;
      console.log(isShowUpgradePremiumModal.value, "premium modal");
    };

    const onReserveSpot = async (courseId: any, selectedDates: any) => {
      // gather required data
      const userId = user.value.id;
      const courseReserveData = selectedDates.map((date: any) => {
        return {
          courseId: parseInt(courseId, 10),
          courseScheduleId: date.id,
          selectedSpotCount: date.spots,
        };
      });

      const request = {
        userId,
        courseReserveData,
      };

      // reserve spot
      try {
        const response = await store.dispatch(
          `${COURSES_STORE}/reserveSpot`,
          request
        );
        if (response && response.length) {
          await fetchReservedSpots();
          // Navigate to course details page
          if (isUserWorkstationIsDefault.value) {
            router.push({
              name: "courseDetails",
              params: {
                courseId: courseId,
                variableName: "course",
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
        onOpenHasSpotReserveNoticeModal();
      } finally {
        isShowWelcomeTradePersonPopup.value = false;
        localStorage.setItem("isShowWelcomePopup", JSON.stringify(true));
      }
    };

    const onClickExploreButton = async () => {
      // let courseReservation = null;
      // if (route.query.courseReservation) {
      //   courseReservation = parseJSON(
      //     String(route.query.courseReservation) || "{}"
      //   );
      // } else {
      //   console.log("no route");
      // }
      // if (courseReservation) {
      //   await onReserveSpot(
      //     courseReservation.courseId,
      //     courseReservation.selectedDates
      //   );
      // } else {
      //   isShowWelcomeTradePersonPopup.value = false;
      //   localStorage.setItem("isShowWelcomePopup", JSON.stringify(true));
      // }
      isShowWelcomeTradePersonPopup.value = false;
      localStorage.setItem("isShowWelcomePopup", JSON.stringify(true));
    };

    const onEditCertificate = (certificate: any) => {
      openedModel.value = modelName.CERTIFICATION;
      userCertificateData.value = certificate;
    };

    const userAddressPostCode = computed(() => {
      if (
        activeUserWorkstation.value?.workstationAccountType?.code ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
      ) {
        return user.value?.userBusinessDetails?.businessPostcode;
      } else {
        return user.value?.userBusinessDetails?.residencyPostcode;
      }
    });
    const workstationAddress = (type: string) => {
      return type === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? user.value?.userBusinessDetails?.businessAddress
        : user.value?.userBusinessDetails?.residencyAddressLookup
        ? user.value?.userBusinessDetails?.residencyAddressLookup
        : `${user.value?.userBusinessDetails?.residencyAddressLine} ${user.value?.userBusinessDetails?.residencyTown} ${user.value?.userBusinessDetails?.residencyPostcode}`;
    };

    const acceptInvitationLoader = ref(false);
    const acceptInvitationWorkstationId = ref<null | string>(null);
    const onClickAcceptWSInvitation = async (token: string) => {
      if (
        user.value?.userKYCVerificationStatus ===
        UserVerificationStatusEnum.VERIFIED
      ) {
        try {
          acceptInvitationLoader.value = true;
          acceptInvitationWorkstationId.value = token;
          await store.dispatch(`${USER_STORE}/acceptWorkStationInvitation`, {
            userId: user.value?.id,
            key: token,
          });
          getWorkStationInvitation();
          await store.dispatch(
            `${WORKSTATION}/getUserWorkstations`,
            user.value?.id
          );
        } catch (err) {
          console.log();
        } finally {
          acceptInvitationLoader.value = false;
          acceptInvitationWorkstationId.value = null;
        }
      } else {
        openedModel.value = "verifyModal";
      }
    };

    const deleteInvitedWorkstationLoader = ref(false);
    const deleteInvitedWorkstationId = ref<null | number>(null);

    const onClickRejectWSInvitation = async (id: number) => {
      try {
        deleteInvitedWorkstationLoader.value = true;
        deleteInvitedWorkstationId.value = id;
        const response = await store.dispatch(
          `${MEMBER_STORE}/deleteMemberDetails`,
          {
            userId: user.value?.id,
            userMemberId: id,
          }
        );
        getWorkStationInvitation();
        await store.dispatch(
          `${WORKSTATION}/getUserWorkstations`,
          user.value?.id
        );
      } catch (err) {
        console.log();
      } finally {
        deleteInvitedWorkstationLoader.value = false;
        deleteInvitedWorkstationId.value = null;
        onCloseConfirmRejectModal();
      }
    };

    const getWorkStationInvitation = async () => {
      try {
        const response = await store.dispatch(
          `${USER_STORE}/getWorkStationInvitation`,
          user.value?.id
        );
        WorkStationInvitation.value = response;
        const image = setDirectStoragePathToImage(
          response[0]?.userWorkstation?.profileImage
        );
        InviteMemberImage.value = image;
      } catch (err) {
        console.log();
      } finally {
        //
      }
    };

    const onWorkStationClick = async (
      workstationId: number,
      resetData = true
    ) => {
      try {
        const wsIdParam = String(route.params?.workstationId);
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, true);
        if (!workstationId) return;
        const workstation = filteredUserAllWorkStation.value.find(
          (workstation: any) => workstation?.id === workstationId
        );
        if (workstation?.isInvited) {
          await store.dispatch(`${WORKSTATION}/isFirstTimeLaunchWorkStation`, {
            userId: user.value?.id,
            memberId: workstation?.memberId,
          });
        }
        if (workstation?.isInvited && !workstation?.isSameRole) {
          await UserService.activateWorkstation(
            user.value?.id,
            workstation?.id
          );
          onClickSwitchUserType();
          return;
        }
        if (resetData && wsIdParam === "undefined" && !isFromCourse.value) {
          await router.push({ name: DASHBOARD_ROUTE });
          await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
            userId: user.value?.id,
            userWorkstationId: workstationId,
          });
        }
        if (resetData && wsIdParam && isFromCourse.value) {
          await router.push({ name: DASHBOARD_WITH_COURSE_LAUNCH_WS });
          await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
            userId: user.value?.id,
            userWorkstationId: workstationId,
          });
        }
        const workstationVerification = await store.dispatch(
          `${WORKSTATION}/getWorkstationVerificationData`,
          workstationId
        );
        if (
          workstationVerification &&
          workstationVerification?.response?.id &&
          ["KYC", "KYB"].includes(workstationVerification?.verificationType)
        ) {
          await store.dispatch(
            `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
            {
              id: workstationVerification.response.id,
              verificationType: workstationVerification?.verificationType,
            }
          );
        } else {
          await store.dispatch(
            `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
            null
          );
        }
      } catch (error) {
        await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
        console.log();
      } finally {
        if (resetData) {
          await store.dispatch(`${USER_STORE}/initializeUserState`);
          await store.dispatch(`${USER_STORE}/getAccountStatuses`);
          await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

          await store.dispatch(
            `${USER_STORE}/setUserCertificates`,
            user.value?.id
          );
          await store.dispatch(
            `${USER_STORE}/setUserInsurances`,
            user.value?.id
          );
          await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
          await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
        }
      }
    };

    const onClickSwitchUserType = async () => {
      let updatedUser: any;
      // Commented For Now
      try {
        launchInvitedWorkstation.value = true;
        updatedUser = await store.dispatch(`${USER_STORE}/selectAccount`, {
          userId: user.value?.id,
          roleId: RolesEnum.PROJECT_OWNER,
          email: user.value?.email,
        });
        const authUser = getLocalStorageWithExpiry(AUTH_USER);
        const newUserState = await store.dispatch(
          `${USER_STORE}/getUser`,
          updatedUser?.id
        );
        authUser.userId = newUserState.id;
        authUser.roleId = RolesEnum.TRADESPERSON;
        authUser.aliased = isAliased(newUserState.email);
        // eslint-disable-next-line no-undef
        setLocalStorageWithExpiry(authUser, AUTH_USER, AUTH_SESSION_EXPIRY);
        setTimeout(async () => {
          switchingText.value = "Switching Account...";
          currentSelectedTab.value = tabListItems[0];
          store.commit(`${USER_STORE}/resestStore`);
        }, 1000);
      } catch (error) {
        console.log();
      } finally {
        setTimeout(() => {
          switchingText.value = "Fetching Account Data...";
          currentSelectedTab.value = tabListItems[1];
        }, 2000);
        setTimeout(async () => {
          switchingText.value = "Loading Account...";
          currentSelectedTab.value = tabListItems[2];
          launchInvitedWorkstation.value = false;
          await store.dispatch(`${USER_STORE}/saveUserState`, updatedUser);
          await store.dispatch(`${USER_STORE}/initializeUserState`);
          await router.push({ name: DASHBOARD_ROUTE });
          // Reload the page after navigating to the dashboard
          window.location.reload();
        }, 5000);
      }
    };

    const getCheckoutUserKYC = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.silt_user_id) return;
        const soloTraderVerificationData = await store.dispatch(
          `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
          {
            id: params.silt_user_id,
            verificationType: UserWorkstationVerificationType.KYC,
          }
        );
        await store.dispatch(
          `${WORKSTATION}/saveWorkstationVerificationData`,
          soloTraderVerificationData
        );
        currentStatusModalName.value = statusModal.KYC;
        currentModalStatus.value = soloTraderVerificationData?.status;
        openedModel.value = "";
        await store.dispatch(`${USER_STORE}/initializeUserState`);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          currentStatusModalName.value = statusModal.KYC;
          openedModel.value = "";
          currentModalStatus.value = "ERROR";
          return;
        }
      }
    };

    const onClickManageTradePassport = () => {
      if (
        userWorkStationStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        console.log("Workstation Locked");
      } else {
        router.push({ name: TRADE_PASSPOSRT_ROUTE });
      }
    };

    const clearData = () => {
      userCertificateData.value = null;
      userInsuranceData.value = null;
    };

    const formatDate = (dateString: any, format: string) => {
      const date = new Date(dateString);

      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      switch (format) {
        case "fullDate": {
          const day = date.getUTCDate();
          const month = date.getUTCMonth();
          const year = date.getUTCFullYear();
          return `${day} ${monthsOfYear[month]}, ${year}`;
        }
        default: {
          throw new Error("Invalid format type");
        }
      }
    };

    const onClickBenefitsButton = () => {
      isShowTradePersonBenefitsModal.value = true;
    };

    const onClickClosePreviewModalButton = async () => {
      isShowTradePersonBenefitsModal.value = false;
    };
    const onClickAddBusinessAddressModal = () => {
      if (!isUserAddBusinessAddress.value) {
        openedModel.value = modelName.ADDBUSINESSADDRESSMODAL;
      }
    };

    const onClickIDVerificationModal = () => {
      if (isUserVerifiedKYCAndKYB.value) return;
      openedModel.value = modelName.VERIFYMODAL;
    };

    const onClickManageTradePassportPreviewModal = () => {
      if (
        userWorkStationStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        console.log("Workstation Locked");
      } else {
        openedModel.value = modelName.TRADEPASSPORTPREVIEWMODAL;
      }
    };
    const isUserVerifiedKYCAndKYB = computed(() =>
      userBusinessType.value === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? WorkStationSiltVerificationStatus.value &&
          ["SUCCESS", "MANUAL_REVIEW"].includes(
            WorkStationSiltVerificationStatus.value?.status
          )
        : [
            UserVerificationStatusEnum.PENDING_VERIFICATION,
            UserVerificationStatusEnum.VERIFIED,
          ].includes(
            activeUserWorkstation.value?.user?.userKYCVerificationStatus
          )
    );
    const onClickSubmitVerificationPreviewModal = () => {
      if (
        isUserProfileVerified.value &&
        activeUserWorkstation.value?.verificationStatus ===
          UserVerificationStatusEnum.NOT_ADDED &&
        isUserVerifiedKYCAndKYB.value
      ) {
        openedModel.value = modelName.WORKSTATIONVERIFYPREVIEWMODAL;
      }
    };

    const checkWelcomePopup = () => {
      const isShowWelcomePopup = parseJSON(
        localStorage.getItem("isShowWelcomePopup")
      );
      const isShowingPaymentModal = parseJSON(
        window.localStorage.getItem("IsSetPaymentModal")
      );
      if (isShowWelcomePopup) {
        if (isShowingPaymentModal) {
          isShowWelcomeTradePersonPopup.value = false;
        } else {
          isShowWelcomeTradePersonPopup.value = true;
        }
      } else {
        isShowWelcomeTradePersonPopup.value = false;
      }
    };

    // const viewCourseDetails = async (courseID: number) => {
    //   // const course = coursesWithExtraInfo.value.find(
    //   //   (item: any) => item.id === courseID
    //   // );
    //   router.push({
    //     name: "courseDetails",
    //     params: { courseId: courseID, variableName: "course" },
    //   });
    // };

    const getCourseData = async () => {
      try {
        getCourseloading.value = true;
        let response = await store.dispatch(`${COURSES_STORE}/getCourses`);
        listOfCourse.value = response.data;
        coursesWithExtraInfo.value = response.data.map((course: any) => {
          if (course.id === 1) {
            return {
              ...course,
              ...airSourceHeatPumpData,
            };
          }
          if (course.id === 2) {
            return {
              ...course,
              ...greenSkillsData,
            };
          }
        });
      } catch (error) {
        console.log("error:", error);
      } finally {
        getCourseloading.value = false;
      }
    };
    const viewCourseDetails = async (courseID: number) => {
      const course = coursesWithExtraInfo.value.find(
        (item: any) => item.id === courseID
      );
      if (course) {
        await store.dispatch(`${COURSES_STORE}/saveCourseDetails`, course);
        router.push({
          name: "courseDetails",
          params: { courseId: courseID, variableName: "course" },
        });
      } else {
        console.error(`Course with ID ${courseID} not found.`);
      }
    };

    const isOpenPaymentStatusModal = ref(false);
    const pamentStatus = ref("");
    const paymentType = ref(null) as any;

    const onCourseUpdateSuccess = (event: any) => {
      console.log("course update success called");
      isOpenPaymentStatusModal.value = true;
      pamentStatus.value = "paid";

      store.dispatch(`${COURSES_STORE}/getCoursesWithRemainingSlots`, {
        userId: user.value.id,
      });
      if (event === "quarterly") {
        paymentType.value = 3; // 3 = 3 months
      } else {
        paymentType.value = 2; // 2 = 1 year
      }
      displayToast(
        "Reserved spot(s) have been successfully assigned to this workstation.",
        "success"
      );
      onCloseIsShowUpgradeSubscriptionModal();
    };

    const onCourseUpdateFailed = (event: any) => {
      isOpenPaymentStatusModal.value = true;
      pamentStatus.value = "failed";

      if (event === "quarterly") {
        paymentType.value = 3; // 3 = 3 months
      } else {
        paymentType.value = 2; // 2 = 1 year
      }
    };

    const displayToast = (message: any, type: any) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClosePamentStatusModal = async () => {
      pamentStatus.value = "";
      paymentType.value = null;
      isOpenPaymentStatusModal.value = false;
      displayToast(
        "Reserved spot(s) have been successfully assigned to this workstation.",
        "success"
      );
    };

    const onClickViewGoogleEvent = () => {
      const link = "https://qc6kd2k4jjk.typeform.com/to/Z37e81Id";
      window.open(link, "_blank");
    };

    const onClickViewRatings = () => {
      store.dispatch(
        `${REVIEW_RATING_STORE}/setTradePassportRedirect`,
        "Reviews"
      );
      router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };
    const onUpgrade = async () => {
      // gather payload

      console.log("filteredReserveSpots", filteredReserveSpots.value);
      if (filteredReserveSpots.value && filteredReserveSpots.value.length) {
        const parsedSubscription = parseJSON(
          activeUserWorkstation.value.subscription
        );
        const userId = user.value.id;
        const wsIdParam = String(
          route.params?.workstationId || activeUserWorkstation.value.id
        );
        const userCourseIds = filteredReserveSpots.value.map((spot: any) => {
          return spot.id;
        });

        let planType = null;

        if (parsedSubscription?.planType) {
          planType = parsedSubscription?.planType;
        } else {
          planType = parseInt(parsedSubscription.metadata.planType, 10);
        }

        const payload = {
          userCourseIds,
          workStationId: parseInt(wsIdParam, 10),
          planType,
        };

        try {
          const response = await store.dispatch(
            `${COURSES_STORE}/courseUpgradeSubscription`,
            {
              userId,
              payload,
            }
          );
          if (response) {
            const stringifyReserveCourseData = JSON.stringify(
              filteredReserveSpots.value
            );
            await store.dispatch(
              `${COURSES_STORE}/setCourseEventToBeCreated`,
              stringifyReserveCourseData
            );
            // open success modal
            displayToast(
              "Reserved spot(s) have been successfully assigned to this workstation.",
              "success"
            );
            store.dispatch(`${WORKSTATION}/setWsModal`, {
              isFromCourse: false,
            });
          }
        } catch (error) {
          console.log(error);
          // open error modal
          displayToast(
            "Error assigning reserved spot(s) to this workstation.",
            "error"
          );
        } finally {
          //
        }
      }
    };

    const getTeamMember = async () => {
      try {
        teamMemberLoading.value = true;
        const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
          userId: user.value?.id,
        });

        members.value = await Promise.all(
          response.map(async (member: any) => {
            if (member?.toUserId) {
              if (member?.toUser?.userPublicProfile) {
                member.userProfileImageUrl = await getImageStringToImageURL(
                  member?.toUser?.userPublicProfile?.profileImage
                );
              } else {
                member.userProfileImageUrl = null;
              }
            } else {
              member.userProfileImageUrl = null;
            }
            return member;
          })
        );
      } catch (err) {
        console.log();
      } finally {
        teamMemberLoading.value = false;
      }
    };

    const getCheckoutSession = async () => {
      console.log("run checkout session 4");
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params?.session_id) {
          onOpenTPSubscriptionModal();
          defaultLoadingOfAllData.value = false;
          return;
        } else {
          defaultLoadingOfAllData.value = true;
        }
      } catch (error) {
        console.log();
      }
    };

    const initialilze = async () => {
      if (defaultLoadingOfAllData.value) {
        const wsIdParam = String(route.params?.workstationId);
        closeAllVerificationPopupPrompt.value = false;
        if (
          isFromCourse.value &&
          wsIdParam &&
          storedReservedSpotsData.value &&
          storedReservedSpotsData.value.length
        ) {
          await onWorkStationClick(parseInt(wsIdParam, 10));
          const parsedSubscription = parseJSON(
            activeUserWorkstation.value.subscription
          );
          // console.log(parsedSubscription, "parsedSubscription");
          // if 3 month subscription
          if (
            parseInt(parsedSubscription.metadata.planType, 10) ===
              PaymentPlanType.MONTHLY &&
            parsedSubscription.regularMonthlySubscription
          ) {
            onOpenIsShowBuySpotConfirmationModal();
          }
          // if yearly subscription
          if (
            parseInt(parsedSubscription.metadata.planType, 10) ===
            PaymentPlanType.YEARLY
          ) {
            await onUpgrade();
          }
          // if 1 month subscription
          if (
            parseInt(parsedSubscription.metadata.planType, 10) ===
              PaymentPlanType.MONTHLY &&
            !parsedSubscription.regularMonthlySubscription
          ) {
            onOpenIsShowUpgradeSubscriptionModal();
          }
        }
        await checkWelcomePopup();
        getCheckoutUserKYC();
        try {
          checkIfHasWorkstationInvitation();
          getWorkStationInvitation();
          await getTeamMember();
          if (user.value?.id) {
            const data = decryptModalData();
            if (data) {
              store.dispatch(`${WORKSTATION}/setWsModal`, data);
            }
            await store.dispatch(`${USER_STORE}/getAccountStatuses`);

            if (!userInsurances.value?.length) {
              await store.dispatch(
                `${USER_STORE}/setUserInsurances`,
                user.value?.id
              );
            }
            if (!userCertificates.value?.length) {
              await store.dispatch(
                `${USER_STORE}/setUserCertificates`,
                user.value?.id
              );
            }

            if (!userSpecialisms.value?.length) {
              await store.dispatch(
                `${USER_STORE}/getUserSkills`,
                user.value.id
              );
            }
            await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

            await store.dispatch(`${WORKSTATION}/setUserWorkstationMembers`, {
              userId: user.value.id,
            });

            // if (!isUserWorkstationIsDefault.value) {
            await store.dispatch(
              `${COURSES_STORE}/getCoursesWithRemainingSlots`,
              {
                userId: user.value.id,
              }
            );
            // }

            setManageEmailList();
          }
        } catch (error) {
          console.log("error", error);
        }
        // await getCheckoutSession();
        // store.dispatch(`${TRADE_NETWORK}/getUserNetWorkContractor`, {
        //   activeUserWorkstationId: activeUserWorkstation.value?.id,
        // });
        if (activeUserWorkstation.value?.id) {
          store.dispatch(`${TRADE_NETWORK}/getUserNetWorkContractor`, {
            activeUserWorkstationId: activeUserWorkstation.value?.id,
          });
        } else {
          const defaultWs = userAllWorkStation.value.find(
            (workstation: any) => workstation.name == "Default"
          );
          if (defaultWs?.id) {
            await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
              userId: user.value?.id,
              userWorkstationId: defaultWs?.id,
            });
          }
        }

        if (isUserWorkstationIsDefault.value) {
          let courseReservation = null;
          if (route.query.courseReservation) {
            courseReservation = parseJSON(
              String(route.query.courseReservation) || "{}"
            );
          } else {
            console.log("no route");
          }
          if (courseReservation) {
            await onReserveSpot(
              courseReservation.courseId,
              courseReservation.selectedDates
            );
          }
        } else {
          await fetchReservedSpots();
        }
      }
    };

    onBeforeMount(async () => {
      await getCheckoutSession();
      // await initialilze
      if (isRegisteredForCourse.value) {
        redirectToCourseDetails();
      }
      if (route.query.courseReservation) {
        const courseReservationDetails = parseJSON(
          String(route.query.courseReservation)
        );
        localStorage.setItem(
          "courseReservationDetails",
          JSON.stringify(courseReservationDetails)
        );
        const courseReservation = parseJSON(
          localStorage.getItem("courseReservationDetails")
        );
        const reservedData = await store.dispatch(
          `${COURSES_STORE}/fetchReservedSpots`,
          user.value.id
        );
        let filteredReservedData = reservedData.data.filter(
          (spot: any) =>
            spot.course.id == parseInt(courseReservation.courseId, 10)
        );
        if (!filteredReservedData.length) {
          await onReserveSpot(
            courseReservation?.courseId,
            courseReservation?.selectedDates
          );
          const updateReservedSpotData = await store.dispatch(
            `${COURSES_STORE}/fetchReservedSpots`,
            user.value.id
          );
          filteredReservedData = updateReservedSpotData.data.filter(
            (spot: any) =>
              spot.course.id == parseInt(courseReservation.courseId, 10)
          );

          store.dispatch(
            `${COURSES_STORE}/setStoredReservedSpotsData`,
            filteredReservedData
          );
        }
      }
    });

    watch(
      () => defaultLoadingOfAllData.value,
      async (newValue) => {
        if (newValue) {
          console.log(newValue, "defaultLoadingOfAllData runedd");
          await initialilze();
        }
      },
      { immediate: true, deep: true }
    );

    const handleClick = (event: any) => {
      if (!getUserWorkStationFieldAccess.value) {
        event?.preventDefault();
        event?.stopPropagation();
      }
    };

    const checkCourseReservationInReservedSpots = (
      reservedSpots: any,
      courseReservation: any
    ) => {
      // Extract the courseId from the courseReservation
      const courseReservationId = courseReservation.courseId;

      // Use Array.prototype.some() to check if at least one courseId matches

      if (isUserWorkstationIsDefault.value) {
        return reservedSpots.some(
          (spot: any) => spot.course.id === Number(courseReservationId)
        );
      } else {
        return reservedSpots.some(
          (spot: any) =>
            spot.course.id === Number(courseReservationId) &&
            spot.subscription !== null
        );
      }
    };

    const onClosePaymentPlanModal = () => {
      isShowPaymentPlanModal.value = true;
    };

    const onClickCheckSubscriptionPlan = async () => {
      const parsedSubscription = parseJSON(
        activeUserWorkstation.value.subscription
      );
      try {
        const courseReservation = parseJSON(
          localStorage.getItem("courseReservationDetails")
        );
        const updateReservedSpotData = await store.dispatch(
          `${COURSES_STORE}/fetchReservedSpots`,
          user.value.id
        );
        const filteredReservedSpot = updateReservedSpotData.data.filter(
          (data: any) =>
            data.course.id == parseInt(courseReservation.courseId, 10)
        );
        store.dispatch(
          `${COURSES_STORE}/setStoredReservedSpotsData`,
          filteredReservedSpot
        );

        if (
          parseInt(parsedSubscription.metadata.planType, 10) ===
            PaymentPlanType.MONTHLY &&
          parsedSubscription.regularMonthlySubscription
        ) {
          onOpenIsShowBuySpotConfirmationModal();
        }
        // if yearly subscription
        if (
          parseInt(parsedSubscription.metadata.planType, 10) ===
          PaymentPlanType.YEARLY
        ) {
          await onUpgrade();
        }
        // if 1 month subscription
        if (
          parseInt(parsedSubscription.metadata.planType, 10) ==
            PaymentPlanType.MONTHLY &&
          !parsedSubscription.regularMonthlySubscription
        ) {
          onOpenIsShowUpgradeSubscriptionModal();
        }
      } catch (e) {
        console.error(e);
      }
    };
    const fetchReservedSpots = async () => {
      const userId = user.value.id;
      let courseReservation = null;
      if (route.query.courseReservation) {
        courseReservation = parseJSON(
          String(route.query.courseReservation) || "{}"
        );
      } else {
        console.log("no route");
      }
      try {
        await store.dispatch(`${COURSES_STORE}/fetchReservedSpots`, userId);
      } catch (error) {
        console.log(error);
      } finally {
        if (
          courseReservation &&
          reservedSpotsData.value &&
          reservedSpotsData.value.length
        ) {
          // if courseRervation course type includes in reserveSpotsData
          const hasCourse = await checkCourseReservationInReservedSpots(
            reservedSpotsData.value,
            courseReservation
          );

          if (hasCourse) {
            onOpenHasSpotNoticeModal();
          }
          if (
            !activeUserWorkstation.value?.subscription ||
            activeUserWorkstation.value?.subStatus !==
              UserWorkstationSubStatusEnum.SUCCEEDED
          ) {
            localStorage.setItem(
              "isSetPaymentPlanForCourse",
              JSON.stringify(true)
            );

            isShowUpgradePremiumModal.value = true;
          } else if (activeUserWorkstation.value?.subscription) {
            const parsedSubscription = parseJSON(
              activeUserWorkstation.value.subscription
            );
            if (
              parseInt(parsedSubscription.metadata.planType, 10) ===
                PaymentPlanType.MONTHLY &&
              parsedSubscription.regularMonthlySubscription
            ) {
              onOpenIsShowBuySpotConfirmationModal();
            }
            // if yearly subscription
            if (
              parseInt(parsedSubscription.metadata.planType, 10) ===
              PaymentPlanType.YEARLY
            ) {
              const filteredReservedSpot = reservedSpotsData.value.filter(
                (data: any) =>
                  data.course.id == parseInt(courseReservation.courseId, 10)
              );

              store.dispatch(
                `${COURSES_STORE}/setStoredReservedSpotsData`,
                filteredReservedSpot
              );
              await onUpgrade();
            }
            // if 1 month subscription
            if (
              parseInt(parsedSubscription.metadata.planType, 10) ===
                PaymentPlanType.MONTHLY &&
              !parsedSubscription.regularMonthlySubscription
            ) {
              onOpenIsShowUpgradeSubscriptionModal();
            }
          } else {
            await onReserveSpot(
              courseReservation.courseId,
              courseReservation.selectedDates
            );
          }
        }
      }
    };

    watch(
      () => activeUserWorkstation.value,
      async (newValue) => {
        if (newValue) {
          closeAllVerificationPopupPrompt.value = false;
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => isSubmitWsForVerification.value,
      (newVal) => {
        if (newVal) {
          isShowUpgradePremiumModal.value = true;
        }
      },
      { immediate: true, deep: true }
    );
    const getAllProjectReviewList = computed<
      reviewRatingState["allProjectReviewList"]
    >(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

    const combineSameProjectReview = computed(() =>
      getCombineSameProjectReview(getAllProjectReviewList.value)
    );
    const isWsDirectlySubmittedForVerification = computed(
      () =>
        store.getters[`${WORKSTATION}/getIsWsDirectlySubmittedForVerification`]
    );
    const getAverageOfAllProjectReview = computed(() => {
      const totalAvg = combineSameProjectReview.value.reduce(
        (totalRating: number, review: any) => {
          totalRating += review.totalAvg || 0;
          return totalRating;
        },
        0
      );
      return totalAvg
        ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) /
            100
        : totalAvg;
    });
    const loaderBuffer = ref(true);
    onMounted(async () => {
      await getCourseData();
      detailsItems.value = await store.getters[
        `${COURSES_STORE}/courseDetails`
      ];
      await store.dispatch(
        `${REVIEW_RATING_STORE}/getAllProjectReviewList`,
        ProjectRatingTypeEnum.TRADER_OWNER
      );
      setTimeout(() => {
        loaderBuffer.value = false;
      }, 2000);
    });

    onUnmounted(() => {
      if (isSubmitWsForVerification.value) {
        store.commit(`${WORKSTATION}/setIsSubmitWsForVerification`, false);
      }
      if (isWsDirectlySubmittedForVerification.value) {
        store.commit(
          `${WORKSTATION}/setIsWsDirectlySubmittedForVerification`,
          false
        );
      }
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        isShowWorkStationModal: false,
        isShowSoleTraderWorkStation: false,
        isShowLimitedCompanyWorkStation: false,
        isFromCourse: false,
      });
    });
    const deleteUserCourse = async () => {
      const courseReservation = parseJSON(
        localStorage.getItem("courseReservationDetails")
      );
      try {
        const filteredCourse = reservedSpotsData.value.filter(
          (data: any) =>
            data.course.id == parseInt(courseReservation.courseId, 10)
        );
        await coursesService.deleteUserCourse(
          user.value.id,
          filteredCourse[0]?.id
        );
        router.push({ name: DASHBOARD_ROUTE });
      } catch (error) {
        console.log(error);
      }
    };
    const userInsurenceExperiedStatus = computed(
      () => store.getters[`${INSURANCE_STORE}/getIsInsuranceExpired`]
    );
    const selectedUserCourseIdForAssignMember = ref(null);
    const toggleAssignCourseSlotToMemberModal = (userCourseId = null) => {
      selectedUserCourseIdForAssignMember.value = userCourseId;
      isOpenAssignCourseSlotToMemberModal.value =
        !isOpenAssignCourseSlotToMemberModal.value;
    };
    const activeWorkStationType = (businessType: string) => {
      return businessType === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? "LTD Company"
        : "Sole Trader";
    };
    return {
      toolTipProfile,
      onClickToggleTradesWorkStation,
      isShowWorkStationModal,
      isShowSoleTraderWorkStation,
      onClickSoleTrader,
      onClickCloseSoloTrader,
      isShowLimitedCompanyWorkStation,
      onClickLimitedCompany,
      user,
      openedModel,
      modelName,
      onClickOpenModel,
      onClickCloseModel,
      userWorkStationImage,
      userFullName,
      userName,
      userEmail,
      dashBoardWelcomeButton,
      onClickCloseLimitedCompany,
      userInsurances,
      onDeleteInsurance,
      onEditInsurance,
      isOuterEditInsurance,
      userInsuranceData,
      isUserProfileVerified,
      userBusinessType,
      isUserWorkstationIsDefault,
      userWorkStationStatus,
      UserVerificationStatusEnum,
      onClickSubmitVerification,
      userCertificates,
      deleteCertificate,
      BusinessDetailTypeEnum,
      isUserAddedCompanyInformation,
      workstationSetupProgress,
      workstationSetupProgressNew,
      activeUserWorkstation,
      memberPermissions,
      assignMyselfDirector,
      onChangeMemberPermission,
      userWorkstationMembers,
      calenderAttribute,
      masks,
      isUserTaxDetailsAdded,
      WorkStationSiltVerificationStatus,
      getVerificationStatus,
      onClickTaxVatDeleteButton,
      onClickUpdateProfile,
      showTradeName,
      onClickAddress,
      onClickTradePassport,
      navigateToJobs,
      navigateToCourse,
      isShowWelcomeTradePersonPopup,
      onClickExploreButton,
      isExpiredDate,
      onEditCertificate,
      userCertificateData,
      isShowPassword,
      userAddressPostCode,
      workstationAddress,
      isWorkstationEmpty,
      WorkStationInvitation,
      toggleAddEmailModal,
      isShowAddEmailModal,
      userWorkstationStatusByWord,
      tradePassportPanel,
      getWorkstationAddress,
      getWorkstationPostcode,
      onClickAcceptWSInvitation,
      InviteMemberImage,
      onClickRejectWSInvitation,
      MemberInvitationStatusEnum,
      onWorkStationClick,
      statusModal,
      currentStatusModalName,
      currentModalStatus,
      taxModalMode,
      onClickManageTradePassport,
      isWorkstationLoading,
      clearData,
      openSiltVerification,
      closeSiltVerification,
      siltVerificationModal,
      userAllWorkStation,
      onClickAddEmail,
      toggleAddEmailConfirmModal,
      isShowAddEmailConformModal,
      emailConfirmModalType,
      manageEmail,
      manageEmailList,
      onClickDeleteEmail,
      isOpenConfirmDelete,
      onDeleteItem,
      confirmDeleteInsuranceData,
      confirmDeleteCertificateData,
      confirmDeleteType,
      confirmDeleteLabel,
      onCloseConfirmDelete,
      deleteItem,
      formatDate,
      onClickBenefitsButton,
      isShowTradePersonBenefitsModal,
      onClickClosePreviewModalButton,
      accountStatuses,
      isUserAddBusinessAddress,
      onClickAddBusinessAddressModal,
      onClickIDVerificationModal,
      onClickManageTradePassportPreviewModal,
      onClickSubmitVerificationPreviewModal,
      filteredUserAllWorkStation,
      getUserWorkStationFieldAccess,
      isUserWorkStationAdmin,
      handleClick,
      parseJSON,
      acceptInvitationLoader,
      deleteInvitedWorkstationLoader,
      deleteInvitedWorkstationId,
      acceptInvitationWorkstationId,
      numberOfWsShowed,
      isShowAllws,
      toggleShowWs,
      viewCourseDetails,
      coursesWithExtraInfo,
      getCourseloading,
      onCloseIsShowUpgradeSubscriptionModal,
      onOpenIsShowUpgradeSubscriptionModal,
      isShowUpgradeSubscriptionModal,

      isOpenPaymentStatusModal,
      onClosePamentStatusModal,
      onCourseUpdateFailed,
      onCourseUpdateSuccess,
      paymentType,
      pamentStatus,

      onCloseIsShowBuySpotConfirmationModal,
      onOpenIsShowBuySpotConfirmationModal,
      isShowBuySpotConfirmationModal,
      acceptedEmailList,
      launchInvitedWorkstation,
      switchingText,
      currentSelectedTab,
      tabListItems,
      isShowHasSpotNotice,
      onOpenHasSpotNoticeModal,
      onCloseHasSpotNoticeModal,
      acceptedEmailsWithPrimaryEmail,
      selectedEmailFilter,
      userNotifications,
      workStationNotificationCount,

      onSubmitVerification,
      toggleAssignCourseSlotToMemberModal,
      isOpenAssignCourseSlotToMemberModal,
      coursesWithRemainingSlots,
      selectedUserCourseIdForAssignMember,
      userInsurenceExperiedStatus,
      activeWorkStationType,
      teamMemberLoading,
      members,
      getTeamMember,
      onClickViewRatings,
      getAverageOfAllProjectReview,
      isUserVerifiedKYCAndKYB,
      route,

      isShowHasSpotReservedNotice,
      onCloseHasSpotReserveNoticeModal,
      onOpenHasSpotReserveNoticeModal,

      toShowVerificationPopupPrompt,
      showVerificationPrompType,
      TRADE_PASSPORT,
      ID_VERIFICATION,
      BUSINESS_ADDRESS,
      SUBMIT_WS,
      closeAllVerificationPopupPrompt,
      onClickCloseSuccessModal,
      onClickViewGoogleEvent,
      loaderBuffer,
      isShowPaymentPlanModal,
      isShowUpgradePremiumModal,
      closeUpgradePremiumModal,
      isPropertyOwner,
      getWsModalStates,
      onClosePaymentPlanModal,
      onClickCheckSubscriptionPlan,
      onCloseSuccessWorkstationCreationModal,
      onOpenTPSubscriptionModal,
      isSetPaymentPlanForCourse,
      deleteUserCourse,
      handleSubscriptionSuccess,
      userVerifyStatus,
      rejectConfirmationModal,
      onOpenConfirmRejectModal,
      onCloseConfirmRejectModal,
      invitationId,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/dashboard/styles/SoleTraderDashboard.scss";
@import "@/modules/trade-passport/styles/TradePassport.scss";
@import "@/modules/courses/styles/courses.scss";

.button-orange {
  :deep(.v-btn__content) {
    font-size: 12px;
  }

  :deep(.v-btn__append) {
    i {
      font-size: 14px;
    }
  }
}

.tag-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.sign-up-empty-state-banner {
  border: 1px solid rgba($bordercolor, 1);
}

.workstation-details-card {
  :deep(.v-expansion-panel__shadow) {
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
  }
}

.workstation-dashboard {
  &__expansion-panel {
    :deep(.v-expansion-panel__shadow) {
      box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
    }
  }
}

.profile-logo {
  border-radius: 10px;
  background-color: #4a92ef;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ws-profile-logo {
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ws-items-bg {
  // background: linear-gradient(180deg, #f3f3f6 0%, #fefefe 100%);
  background: white;
  border: 1px solid rgba($bordercolor, 1);
}

.notify-icon {
  box-shadow: 0px 0px 5px 0px rgba(12, 15, 74, 0.2);
}

.action-modal .modal_wrapper {
  width: 100%;

  border-radius: 30px;
}

.action-modal .modal_wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      111.5deg,
      rgba(245, 250, 255, 0.3) 0%,
      rgba(211, 233, 255, 0.3) 100%
    ) !important;
}

.loading-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  animation: loading-animation 3.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 160px;
  background-color: $white;
  margin: 0 auto;
  box-shadow: 1px 1px 5px #f5f5f5;
}

@keyframes loading-animation {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

.dot {
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
  position: absolute;
}

.dot-red {
  background-color: $orange;
  width: 33px;
  height: 33px;
  top: 80px;
  left: 35px;
}

.dot-blue {
  background-color: $PrimaryBlue;
  width: 33px;
  height: 33px;
  border-radius: 40px;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dot-green {
  background-color: #c6d2f5;
  width: 33px;
  height: 33px;
  border-radius: 40px;
  top: 80px;
  right: 35px;
}

.switching-text {
  text-align: center;
  color: $PrimaryBlue;
  margin-top: 3rem;
}

.progress-bar {
  background-color: #ddd;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  background-color: #4caf50;
  height: 100%;
  width: 0;
  transition: width 5s linear;
}

.note__wrapper {
  background-color: rgba(240, 147, 13, 1);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  gap: 6px;
  align-items: center;

  p {
    @include fluidFont(12, 12, 1.3);
    font-weight: 500;
    color: rgba($white, 1);
    letter-spacing: 0.25px;
    text-transform: capitalize;

    a {
      text-decoration: underline;
      font-weight: 600;
      color: rgba($white, 1);
      color: rgba($white, 1);
      cursor: pointer;
    }
  }

  .v-icon {
    color: rgba($white, 1);
    @include fluidFont(16, 16, 1.3);
  }
}

.view-rating-box {
  padding: 12px 16px;
  background-color: #f3f3f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  flex-wrap: wrap;

  .v-rating {
    :deep(.v-rating__wrapper) {
      .v-rating__item {
        .v-btn {
          width: auto;
          height: auto;
        }
      }
    }
  }

  p {
    @include fluidFont(16, 16, 1.2);
    font-weight: 700;
    letter-spacing: 0.12px;
    color: rgba($blueDark, 1);
  }

  .v-btn {
    padding: 5px 8px;
    min-height: auto;
    display: flex;
    text-transform: capitalize;
    height: auto;
    margin: 0;
  }
}

.custom__chip {
  display: flex;
  gap: 4px;
  align-items: center;
  @include fluidFont(12, 12, 2);
  font-weight: 700;
  color: rgba($blueDark, 1);
  text-transform: uppercase;
  background-color: rgba(253, 222, 199, 1);
  border-radius: 8px;
  padding: 2px 9px;
  img {
    width: 12px;
    height: 12px;
  }
}

.blue-text {
  opacity: 1;
  padding: 0;
  text-transform: capitalize;
}
:deep(.el-popper.is-dark) {
  z-index: 9999;
}
.tooltip__wrapper {
  .v-icon {
    @include fluidFont(16, 16, 1);
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
