<template>
  <Card
    class="!tw-min-h-[unset]"
    :class="{ '!tw-bg-transparent': noBg }"
    bgColor="[#f8fbfd]"
    :noShadow="true"
  >
    <template #content>
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-[1rem] tw-py-1 tw-box-border tw-w-full tw-h-full"
        :class="{
          '!tw-flex-col !tw-py-2': flexColumn,
          '!tw-flex-col-reverse !tw-py-2': flexColumnReverse,
          '!tw-px-0 !tw-py-0': noBg,
          'tw-justify-center': justifyCenter,
          '!tw-justify-start tw-gap-1': justifyStart,
        }"
      >
        <div class="tw-flex tw-gap-2 tw-items-center">
          <Text
            :variant="allFontBold ? 'p' : 'span'"
            :textWeight="allFontBold ? '700' : '400'"
            :textColor="allFontBold ? '#0C0F4A' : 'rgba(12, 15, 74, 0.5)'"
            whiteSpace="nowrap"
            >{{ label }}</Text
          >
          <Text
            v-if="subLabel"
            :variant="allFontBold ? 'p' : 'span'"
            :textWeight="allFontBold ? '400' : '700'"
            :textColor="allFontBold ? 'rgba(12, 15, 74, 0.5)' : '#0C0F4A'"
            whiteSpace="nowrap"
            >{{ subLabel }}</Text
          >
          <slot name="postfix"></slot>
        </div>
        <Text
          :variant="allFontBold || dataFontBold ? 'p' : 'span'"
          textWeight="700"
          textColor="#0C0F4A"
          whiteSpace="nowrap"
        >
          {{ data }}
        </Text>
      </div>
    </template>
  </Card>
</template>
<script setup>
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";

const props = defineProps({
  label: {
    type: String,
    default: "Label",
  },
  subLabel: {
    type: String,
    default: "",
  },
  data: {
    type: String,
    default: "Data",
  },
  noBg: Boolean,
  flexColumn: Boolean,
  justifyCenter: Boolean,
  justifyStart: Boolean,
  flexColumnReverse: Boolean,
  allFontBold: Boolean,
  dataFontBold: Boolean,
});
</script>
<script>
export default {
  name: "LabelWithValue",
};
</script>
<style lang="scss" scoped></style>
