import { CourseState, CourseDetails } from "@/core/models/courses";

export default {
  setCourseDetails(state: CourseState, payload: CourseDetails) {
    state.courses = payload;
  },
  setSelectedCourseDetails(state: CourseState, payload: CourseDetails) {
    state.courseDetails = payload;
  },
  setEnrollCourseDetails(state: CourseState, payload: CourseDetails) {
    state.enrollCourses = payload;
  },
  setSelectedDates(state: any, payload: any) {
    state.selectedDates = payload;
  },
  setTotalReserveSpotAmount(state: any, payload: any) {
    state.totalReserveSpotAmount = payload;
  },
  setSpotReservedDateStarted(state: any, payload: any) {
    state.spotReservedDateStarted = payload;
  },

  setReservedSpotsData(state: any, payload: any) {
    state.reservedSpotsData = payload;
  },

  setStoredReservedSpotsData(state: any, payload: any) {
    state.storedReservedSpotsData = payload;
  },

  setCourseUpgradeResponse(state: any, payload: any) {
    state.courseUpgradeResponse = payload;
  },
  setCoursesWithRemainingSlots(state: any, payload: any) {
    state.coursesWithRemainingSlots = payload;
  },
  setAssignedCourses(state: any, payload: any) {
    state.assignedCourses = payload;
  },

  setCourseEventToBeCreated(state: any, payload: any) {
    state.courseEventToBeCreated = payload;
  },
  setCourseModuleRedirect(state: any, payload: any) {
    state.courseModuleRedirect = payload;
  },
  resetCourseModuleRedirect(state: any) {
    state.courseModuleRedirect = null;
  },
  setCourseDetailsFromLandingPage(state: any, payload: any) {
    state.courseDetailsFromLandingPage = payload;
  },
  setCourseId(state: any, payload: any) {
    state.courseId = payload;
  },
  setIsSetPaymentPlanForCourse(state: any, payload: any) {
    state.isSetPaymentPlanForCourse = payload;
  },
};
