<template>
  <CommonDialog
    v-if="!isShowSuccessFullyAccepted"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['select_workstation_modal']"
    width="670px"
    max-width="100%"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>Select Workstations</h4>
          <v-btn
            icon="mdi-close"
            @click="onClickCloseButton"
            variant="text"
          ></v-btn>
        </div>
        <div class="modal_form">
          <p
            v-if="
              acceptInvitationDetails?.type ===
              UserNotificationType.PROPERTY_USER_CONNECTION
            "
          >
            Select one or multiple workstations to accept the connection for the
            property owners.
          </p>
          <p v-else>
            Select one or multiple workstations to accept the preferred
            contractor for the property owners.
          </p>
          <p>
            Choosing multiple workstations enables trade contractors to receive
            leads and work with their friends or colleagues.
          </p>
          <!-- LOADER -->
          <div
            v-if="loadingImages"
            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
          >
            <Loader :show="loadingImages" />
          </div>
          <div v-else class="workstation__list__content">
            <v-text-field
              v-model="searchTerm"
              prepend-inner-icon="mdi-magnify"
              density="compact"
              placeholder="Search Workstation Names"
              variant="outlined"
              hide-details
              single-line
            ></v-text-field>
            <div class="workstation__results">
              <p>
                <span> {{ filteredWorkstations?.length }}</span>
                Results |
              </p>
              <p>
                <span>{{ getSelectedWorkstationIds?.length }}</span>
                Selected
              </p>
            </div>
            <div class="workstation__companies__list">
              <v-card
                class="workstation__companies-item"
                v-for="(workstation, index) in filteredWorkstations"
                :key="index"
                :class="{ selected: workstation.selected }"
                @click="handleSelectionChange(workstation)"
              >
                <v-skeleton-loader
                  type="list-item-avatar"
                  v-if="loadingImages"
                ></v-skeleton-loader>
                <div v-else class="workstation__companies-item__content">
                  <ProfileThumbnail
                    class="company-img"
                    v-if="workstation.attachmentSource"
                    :src="workstation.attachmentSource"
                  />
                  <div v-else class="company-img">
                    <UserProfileLogo :userName="workstation?.name" />
                  </div>
                  <div class="company-text">
                    <p>
                      {{
                        workstation
                          ? workstation?.name === "Default"
                            ? workstation?.user?.firstName +
                              " " +
                              workstation?.user?.lastName
                            : workstation?.name
                          : ""
                      }}
                    </p>
                    <span>
                      {{
                        workstation
                          ? workstation?.user?.userBusinessDetails?.type ==
                            BusinessDetailTypeEnum.LIMITED_COMPANY
                            ? "LTD Company Workstation"
                            : workstation?.name === "Default"
                            ? "Personal Account"
                            : "Sole Trader Workstation"
                          : ""
                      }}
                    </span>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <div class="modal_content_footer">
          <v-btn @click="onClickCloseButton" class="button button-purple-border"
            >CANCEL</v-btn
          >
          {{ getSelectedWorkstationIds.value }}
          <v-btn
            @click="onClickSaveButton"
            :loading="isLoading"
            :disabled="!getSelectedWorkstationIds?.length"
            class="button button-orange"
            >Done</v-btn
          >
        </div>
      </div>
    </template>
  </CommonDialog>
  <CommonDialog
    v-if="isShowSuccessFullyAccepted"
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['accepted_workstation_modal']"
    width="570px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <v-btn
            icon="mdi-close"
            @click="onClickCloseButton"
            variant="text"
          ></v-btn>
        </div>
        <div class="modal_form">
          <div class="modal_img">
            <img src="@/assets/icons/congratulations.svg" />
          </div>
          <h3>Congratulations!</h3>
          <p>
            The preferred contractor for the property owners has been
            successfully accepted at the selected workstation(s). You are all
            set to collaborate and work together.
          </p>
        </div>

        <v-btn @click="onClickDoneButton" class="button button-orange"
          >Done</v-btn
        >
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { useStore } from "vuex";
import { computed, ref, onBeforeMount, getCurrentInstance } from "vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import $axios from "@/core/utils/axios-api-config";
import {
  getImageApiUrl,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import Loader from "@/core/components/common/Loader.vue";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { UserNotificationType } from "@/core/enums";
import { CONNECTIONS } from "@/store/modules/connections";
import Notification from "@/core/services/notification.service";

export default {
  components: {
    CommonDialog,
    ProfileThumbnail,
    UserProfileLogo,
    Loader,
  },
  emits: ["closeMultiSelectWorkstationModal"],
  props: {
    acceptInvitationDetails: {
      type: Object,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const loadingImages = ref(false);
    const isShowSuccessFullyAccepted = ref(false);
    const modifiedUserAllWorkStation = ref([]) as any;
    const searchTerm = ref("");
    const isLoading = ref(false);
    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );

    const filteredWorkstations = computed(() => {
      if (searchTerm.value.length <= 1) {
        return modifiedUserAllWorkStation.value.filter(
          (workStation: any) => workStation.name.toLowerCase() !== "default"
        );
      }
      return modifiedUserAllWorkStation.value.filter(
        (workStation: any) =>
          workStation.name.toLowerCase() !== "default" &&
          workStation.name
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase())
      );
    });

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onClickSaveButton = async () => {
      try {
        isLoading.value = true;
        const acceptData = {
          userId: user.value?.id,
          tradeUserConnectionId: props.acceptInvitationDetails?.id,
          data: {
            key: props.acceptInvitationDetails?.token,
            workstationIds: getSelectedWorkstationIds.value,
          },
        };

        const acceptPreferredTradeUserConnection =
          props.acceptInvitationDetails.type ===
          UserNotificationType.TRADE_USER_CONNECTION
            ? await store.dispatch(
                `${PREFERRED_CONTRACTORS}/acceptPreferredTradeUserConnection`,
                acceptData
              )
            : props.acceptInvitationDetails.type ===
              UserNotificationType.PROPERTY_USER_CONNECTION
            ? await store.dispatch(
                `${CONNECTIONS}/acceptPropertyUserConnection`,
                acceptData
              )
            : null;
        if (acceptPreferredTradeUserConnection) {
          // isShowSuccessFullyAccepted.value = true;
          if (props.acceptInvitationDetails.notificationId)
            await Notification.deleteNotification(
              props.acceptInvitationDetails.notificationId
            );
          displayToast("Accepted Successfully", "success");
          onClickCloseButton();
        } else {
          onClickCloseButton();
        }
        isLoading.value = false;
      } catch (error) {
        console.log("error", error);
        isLoading.value = false;
      }
    };

    const onClickDoneButton = async () => {
      ctx.emit(
        "closeMultiSelectWorkstationModal",
        props.acceptInvitationDetails.type
      );
    };

    const onClickCloseButton = () => {
      ctx.emit(
        "closeMultiSelectWorkstationModal",
        props.acceptInvitationDetails.type
      );
    };

    const getSelectedWorkstationIds = computed(() =>
      modifiedUserAllWorkStation.value
        ?.filter((workstation: any) => workstation["selected"])
        ?.map((workstation: any) => workstation.id)
    );

    const handleSelectionChange = (workStation: any) => {
      const selectedWorkStation = modifiedUserAllWorkStation.value?.find(
        (item: any) => item.id === workStation.id
      );
      selectedWorkStation["selected"] = !selectedWorkStation["selected"];
    };

    const getWorkstationProfile = async (profileImage: any) => {
      loadingImages.value = true;
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      } finally {
        loadingImages.value = false;
      }
    };

    const modifyAllUserWorkstation = async () => {
      loadingImages.value = true;
      modifiedUserAllWorkStation.value = [];
      if (!userAllWorkStation.value && !userAllWorkStation.value.length) {
        loadingImages.value = false;
        return [];
      }
      try {
        const attachmentPromises = userAllWorkStation.value.map(
          (workstation: any) => {
            const { profileImage } = workstation;
            return getWorkstationProfile(profileImage);
          }
        );
        const attachmentSources = await Promise.all(attachmentPromises);

        userAllWorkStation.value.forEach((workstation: any, index: number) => {
          modifiedUserAllWorkStation.value.push({
            ...workstation,
            attachmentSource: attachmentSources[index],
          });
        });
        for (const contractor of modifiedUserAllWorkStation.value) {
          if (contractor.profileImage !== null) {
            const imageUrl = setDirectStoragePathToImage(
              contractor.profileImage
            );
            contractor.publicURL = imageUrl;
          }
        }
      } catch (error) {
        loadingImages.value = false;
      } finally {
        loadingImages.value = false;
      }
    };

    onBeforeMount(async () => {
      modifyAllUserWorkstation();
    });

    return {
      user,
      onClickSaveButton,
      onClickCloseButton,
      userAllWorkStation,
      handleSelectionChange,
      loadingImages,
      modifiedUserAllWorkStation,
      filteredWorkstations,
      searchTerm,
      activeUserWorkstation,
      BusinessDetailTypeEnum,
      isShowSuccessFullyAccepted,
      onClickDoneButton,
      getSelectedWorkstationIds,
      UserNotificationType,
      isLoading,
    };
  },
};
</script>
