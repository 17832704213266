<template>
  <Card class="tw-py-5 tw-px-4 tw-max-w-full">
    <template #content>
      <main class="tw-flex tw-flex-col tw-gap-5 tw-h-full">
        <section
          class="tw-flex tw-items-center tw-justify-between lg:tw-flex-col lg:tw-gap-4 2xl:tw-items-start"
        >
          <div
            class="tw-flex tw-gap-4 tw-items-center 2xl:tw-flex-col 2xl:tw-items-start"
          >
            <Text variant="h5" whiteSpace="nowrap">Your Estimate Summary</Text>
          </div>
        </section>

        <section
          class="tw-flex tw-flex-col tw-gap-4 tw-h-full tw-justify-between tw-pb-4"
          v-if="getTradeEstimateDetails"
        >
          <div class="tw-flex tw-flex-col tw-gap-4 tw-h-full">
            <EstimateSummaryData
              :selectedEstimate="getTradeEstimateDetails"
              :isReadOnly="true"
            />

            <div
              v-for="(phase, index) in getTradeEstimateDetails.estimationPhase"
              :key="index"
              class="phase__wrapper"
            >
              <main
                class="tw-flex tw-items-center tw-flex-col tw-max-w-full md:tw-flex-col md:tw-items-start md:tw-w-full tw-gap-3"
              >
                <PhaseTitle
                  :title="phase.name"
                  :phaseNumber="index + 1"
                  :estimate="phase"
                  :textColor="phase?.color || '#4A92E5'"
                  class="estimate_phase_title"
                />
                <section
                  class="phase_list_summary tw-relative tw-flex tw-gap-4 tw-items-center tw-w-full tw-max-w-full tw-justify-start md:tw-w-full md:tw-justify-start md:tw-flex-col-reverse md:tw-items-start md:tw-gap-2"
                >
                  <div
                    class="tw-flex tw-gap-2 tw-items-center tw-pr-2 tw-flex-wrap"
                  >
                    <div
                      class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
                    >
                      <Text variant="span" lineHeight="16px">{{
                        phase?.estimationPhaseTasks.length
                      }}</Text>
                      <Text
                        lineHeight="16px"
                        variant="span"
                        textWeight="400"
                        textColor="rgba(12, 15, 74, 0.5)"
                        >{{
                          phase?.estimationPhaseTasks.length > 1
                            ? "tasks"
                            : "task"
                        }}</Text
                      >
                    </div>
                    <div
                      class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
                      v-if="phase?.phaseType === EstimatePhaseType.LABOUR"
                    >
                      <Text variant="span" lineHeight="16px">{{
                        getDuration(
                          phase?.phaseStartDate,
                          phase?.phaseEndDate,
                          phase.durationType
                        )
                      }}</Text>
                      <Text
                        lineHeight="16px"
                        variant="span"
                        textWeight="400"
                        textColor="rgba(12, 15, 74, 0.5)"
                        >{{ getDurationType(phase.durationType) }}</Text
                      >
                    </div>
                    <div
                      class="phase-received-item__item tw-flex tw-gap-2 tw-items-start tw-pr-2"
                    >
                      <Text variant="span" lineHeight="16px">{{
                        phaseTotal(phase)
                      }}</Text>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </div>
          <section class="tw-flex tw-flex-col tw-gap-4">
            <v-divider :thickness="2"></v-divider>
            <EstimateReceivedTotal
              :totalSubTotal="totalSubTotal"
              :totalVat="totalVat"
              :serviceCharges="serviceCharges"
              :grantName="getTradeEstimateDetails?.grantName"
              :grantAmount="getTradeEstimateDetails?.grantAmount"
            />
          </section>
        </section>
      </main>
    </template>
  </Card>
</template>

<script setup>
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text";
import EstimateSummaryData from "@/modules/jobs/components/estimates/EstimateSummaryData.vue";
import EstimateReceivedTotal from "@/modules/project/components/common/EstimateReceivedTotal";
import { computed } from "vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { useStore } from "vuex";
import { ref } from "vue";
import PhaseTitle from "@/modules/project/components/common/PhaseTitle.vue";
import moment from "moment";
import { EstimatePhaseType, DurationsType } from "@/core/enums/estimateEnum";
import CurrencyFilter from "@/filters/currency.filter";

const props = defineProps(["selectedEstimate"]);
const store = useStore();
const getTradeEstimateDetails = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/getTradeEstimateDetails`]
);
const totalPhaseTasks = computed(
  () =>
    getTradeEstimateDetails.value.estimationPhase.filter(
      (phase) => phase.phaseType === EstimatePhaseType.LABOUR
    ).length
);

const grantEstimateDetails = computed(() => {
  const grantAmount = getTradeEstimateDetails.value.grantAmount;

  return {
    id: getTradeEstimateDetails.value.id,
    grantType: getTradeEstimateDetails.value.grantType,
    grantName: getTradeEstimateDetails.value.grantName,
    grantAmount: getTradeEstimateDetails.value.grantAmount,
    lockState: getTradeEstimateDetails.value.lockState,
    suggestedStartDate: getTradeEstimateDetails.value.suggestedStartDate,
    agreeTermsConsumerDisclosure:
      getTradeEstimateDetails.value.agreeTermsConsumerDisclosure,
    depositType: getTradeEstimateDetails.value.depositType,
    totalEstimateCost: getTradeEstimateDetails.value.totalEstimateCost,

    grantDiscountTotal: parseFloat(grantAmount / totalPhaseTasks.value).toFixed(
      2
    ),
  };
});

const totalSubTotal = computed(() => {
  const sum = getTradeEstimateDetails.value.estimationPhase.reduce(
    (sum, phase) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );

  return Math.round(sum * 100) / 100;
});

const totalVat = computed(() => {
  const sum = getTradeEstimateDetails.value.estimationPhase.reduce(
    (sum, phase, index) => {
      const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
      return sum + roundedTotalVat;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  // 5 pound
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee =
    getTradeEstimateDetails.value.estimationPhase.reduce(
      (sum, phase, index) => {
        const roundedTotalWithServiceFee = parseFloat(
          phase.subTotal * serviceFeePercentage
        );
        return sum + roundedTotalWithServiceFee;
      },
      0
    );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});
const getDurationType = (durationsType) => {
  if (durationsType === DurationsType.DAY) {
    return "day(s)";
  }
  return "week(s)";
};
const getDuration = (taskStartDate, taskEndDate, durationType) => {
  if (!taskStartDate || !taskEndDate) return 0;

  const start = moment(taskStartDate);
  const end = moment(taskEndDate);
  const duration = moment.duration(end.diff(start));

  if (durationType === DurationsType.DAY) {
    return duration.days();
  }
  return duration.weeks();
};

const phaseTotal = (phase) => {
  let subTotal = phase?.subTotal;
  if (phase?.phaseType === EstimatePhaseType.LABOUR) {
    subTotal = subTotal - grantEstimateDetails?.value?.grantDiscountTotal;
  }
  return formatCurrency(subTotal);
};
const formatCurrency = (value) => {
  if (!value) return `£0`;
  return CurrencyFilter.formatToCurrency(value);
};
</script>

<style lang="scss" scoped>
.v-expansion-panels {
  gap: 16px;
}
:deep(.v-expansion-panels):not(.v-expansion-panels--variant-accordion)
  > :first-child:not(:last-child):not(.v-expansion-panel--active):not(
    .v-expansion-panel--before-active
  ) {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  box-shadow: 0px 0px 8px 0px #0c0f4a14 !important;
}
.estimate_phase_title {
  width: 100%;
  max-width: 100%;
}
.phase__wrapper {
  padding: 8px;
  border: 1px solid rgba($bordercolor, 1);
  border-radius: 8px;
  background-color: rgba($white, 1);
}
.phase_list_summary {
  .phase-received-item__item {
    border-right: 1px solid rgba($blueDark, 0.5);
    &:last-child {
      border-right: none;
    }
  }
}

.estimate__content {
  .v-expansion-panels {
    .phase__accordion {
      box-shadow: none !important;
      .v-expansion-panel {
        .v-expansion-panel-title {
          padding: 8px;
          border: 1px solid rgba($bordercolor, 1);
          background-color: rgba($white, 1);
        }
      }
    }
  }
}
</style>
