<template>
  <GeneralDialog
    width="675px"
    class="contract\_assign\_modal"
    headerTitle="Please sign the project service agreement to proceed."
    headerDescription="For this project to kick-off both sides will need to sign the project service agreement to protect"
  >
    <template #body>
      <h3 class="text-center">Project Service Agreement</h3>
      <h5 class="text-center text-orange">
        {{
          new Date().toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
        }}
      </h5>
      <h4>General Platform Usage</h4>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <h4>1. Updated: September</h4>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <br />
      <br />
      <h5>Digital Signature</h5>
      <v-form :rules="validation" ref="sigFormRef" class="tw-flex">
        <v-text-field
          placeholder="Enter Signature"
          v-model="signername"
          :rules="validation.name"
          variant="outlined"
        ></v-text-field>
      </v-form>
    </template>
    <template #footer>
      <div class="tw-flex ml-4">
        <Button
          label="Cancel"
          width="20%"
          class="mx-4"
          variant="custom"
          activeBgColor="white"
          activeFontColor="blue"
          borderColor="blue"
          @click="onCloseModal"
        />
        <Button
          label="I have read and agree with the terms"
          variant="custom"
          activeBgColor="blue"
          activeFontColor="white"
          width="60%"
          @click="onConfirm"
          :disabled="!isFormValid"
        />
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import { computed, ref } from "vue";
const signername = ref(null);
const sigFormRef = ref(null);
const emits = defineEmits(["on-close", "on-confirm"]);
const isFormValid = computed(() => {
  return signername.value !== null;
});

const validation = ref({
  name: [
    (v) => !!v || "signature is required.",
    (v) => v.length <= 255 || "signature is too long",
  ],
});

const onConfirm = async () => {
  const isFormValid = await sigFormRef.value.validate();
  if (!isFormValid.valid) return;
  emits("on-confirm");
};
const onCloseModal = () => {
  emits("on-close");
};
</script>

<style></style>
