import { Commit } from "vuex";
import TradeNetwork from "@/core/services/trade-network.service";
import { InviteMember } from "@/core/models/manage-member";
import { setDirectStoragePathToImage } from "@/core/utils/common";
export default {
  async getTradeNetworkContractorList(
    { commit }: { commit: Commit },
    payload: { activeUserWorkstationId: number; params: any }
  ) {
    try {
      const response: any = await TradeNetwork.getTradeWorkStationContractor(
        payload.activeUserWorkstationId,
        payload.params || {}
      );

      for (const contractor of response.data) {
        contractor.publicURL = setDirectStoragePathToImage(
          contractor.profileImage
        );
      }
      if (response?.page == 1) {
        commit("setTradeNetworkContractor", response?.data);
        commit("setTotalPages", response?.totalPages);
        commit("setTradeNetworkContractorCount", response?.totalCount);
      } else {
        commit("appendTradeNetworkContractor", response?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  },
  async getUserNetWorkContractor(
    { commit }: { commit: Commit },
    payload: { activeUserWorkstationId: number; params: any }
  ) {
    try {
      const userTradeNetworkContractor =
        await TradeNetwork.getUserNetWorkContractor(
          payload.activeUserWorkstationId,
          payload.params || {}
        );
      commit("setUserNetWorkContractor", userTradeNetworkContractor);
    } catch (error) {
      console.log("error", error);
    }
  },
  async setUserNetworkConnections(
    { commit }: { commit: Commit },
    payload: { activeUserWorkstationId: number; params: any }
  ) {
    try {
      const response: any = await TradeNetwork.getAllContractorWithOutCategory(
        payload.activeUserWorkstationId,
        payload.params || {}
      );
      for (const contractor of response?.data) {
        if (contractor.receiverWorkstation)
          contractor.receiverWorkstation["profileImageUrl"] =
            setDirectStoragePathToImage(
              contractor?.receiverWorkstation?.profileImage
            );
      }

      if (!response?.page || response?.page == 1) {
        commit("setUserNetworkConnections", response?.data);
        commit("setTotalConnectionTabPages", response?.totalPages);
        commit("setAllUserConectionContractorCount", response?.totalCount);
      } else {
        commit("appendUserNetworkConnections", response?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  },
  async inviteExternalContractor(
    { state, commit }: any,
    payload: { members: InviteMember }
  ) {
    return TradeNetwork.inviteExternalContractor(payload.members).then(
      (response) => {
        return response;
      }
    );
  },
  async getExternalInvitedContractorList({ commit }: { commit: Commit }) {
    try {
      const contractorsConnectionList =
        await TradeNetwork.getExternalInvitedContractorList();
      commit("setExternalInvitedContractorList", contractorsConnectionList);
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getExternalInvitedContractorForNotification({
    commit,
  }: {
    commit: Commit;
  }) {
    try {
      const contractorsConnectionList =
        await TradeNetwork.getExternalInvitedContractorForNotification();
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async rejectExternalContractorInvitation(
    { commit }: { commit: Commit },
    payload: { rowId: number }
  ) {
    try {
      const contractorsConnectionList =
        await TradeNetwork.rejectExternalContractorInvitation(payload.rowId);
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async acceptExternalContractorInvitation(
    { commit }: { commit: Commit },
    payload: { rowId: number; token: string }
  ) {
    try {
      const { rowId, token } = payload;
      const response = await TradeNetwork.acceptExternalContractorInvitation(
        rowId,
        {
          key: token,
        }
      );
      return response;
    } catch (error: any) {
      console.log("error", error);
      return error?.response;
    }
  },

  setInviteContractorFromTradeNetwork(
    { commit }: { commit: Commit },
    payload: boolean
  ) {
    console.log("payload", payload);
    commit("setInviteContractorFromTradeNetwork", payload);
  },
  resetInviteContractorFromTradeNetwork({ commit }: { commit: Commit }) {
    commit("resetInviteContractorFromTradeNetwork");
  },

  setInviteContractorProjectData({ commit }: { commit: Commit }, payload: any) {
    commit("setInviteContractorProjectData", payload.projectData);
  },
};
