<template>
  <div class="analytics__wrapper tw-pb-12">
    <div class="analytics__header md:!tw-flex-col">
      <div class="header__date rmd:!tw-w-[400px]">
        <el-date-picker
          v-if="categoryFilterValue === 'Monthly'"
          v-model="dateValue"
          type="daterange"
          range-separator="-"
          value-format="YYYY-MM-DD"
          start-placeholder="Start date"
          end-placeholder="End date"
          size="large"
        />
        <el-date-picker
          v-if="categoryFilterValue === 'Yearly'"
          v-model="dateValue"
          value-format="YYYY-MM-DD"
          type="monthrange"
          size="large"
          range-separator="|"
          start-placeholder="Start month"
          end-placeholder="End month"
        />
        <v-select
          :items="filterItems"
          v-model="categoryFilterValue"
          item-title="range"
          item-value="range"
          variant="outlined"
          placeholder="Monthly"
          bg-color="#fff"
          class="input_field"
          type="select"
        ></v-select>
      </div>
      <div class="tw-flex tw-items-center tw-gap-4">
        <div class="icon_btn">
          <v-btn class="button" variant="outlined" disabled>
            <v-icon>
              <img src="../../../assets/icons/filter-icon.svg" alt="" /></v-icon
            >Filter</v-btn
          >
        </div>
        <div class="icon_btn">
          <v-btn class="button" variant="outlined" disabled>
            <v-icon>
              <img src="../../../assets/icons/download-icon.svg"
            /></v-icon>
            Export
          </v-btn>
        </div>
      </div>
    </div>
    <EstimatesJobsRatingCardsLayout v-if="!loadingData" />
    <RevenueCardsLayout v-if="!loadingData" />
    <ServiceCardsLayout v-if="!loadingData" />
    <TeamPerformanceCard v-if="!loadingData" />
    <Loader :show="loadingData" />
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";

import EstimatesJobsRatingCardsLayout from "../components/EstimatesJobsRatingCardsLayout.vue";
import RevenueCardsLayout from "../components/RevenueCardsLayout.vue";
import ServiceCardsLayout from "../components/ServiceCardsLayout.vue";
import TeamPerformanceCard from "../components/TeamPerformanceCard.vue";
import { ANALYTICS_STORE } from "@/store/modules/analytics";
import InputSelect from "@/core/uni-components/InputSelect.vue";
import Loader from "@/core/components/common/Loader.vue";

const store = useStore();

const dateValue = ref(null);
const categoryFilterValue = ref("Monthly");
const filterItems = ref([{ range: "Monthly" }, { range: "Yearly" }]);
const loadingData = ref(false);

const fetchTradeAnalytics = async () => {
  loadingData.value = true;
  let startDate = "2020-01-01"; // default date range
  let endDate = "2025-12-31"; // default date range
  let params = {};
  if (dateValue.value) {
    startDate = dateValue.value[0];
    endDate = dateValue.value[1];
  }

  params = {
    startDate,
    endDate,
  };

  try {
    await store.dispatch(`${ANALYTICS_STORE}/fetchTradeAnalytics`, params);
    await store.dispatch(`${ANALYTICS_STORE}/fetchServiceRevenue`, params);
    await store.dispatch(`${ANALYTICS_STORE}/fetchTeamPerformance`, params);
  } catch (error) {
    console.log(error);
  } finally {
    loadingData.value = false;
  }
};

watch(
  () => dateValue.value,
  async (newValue) => {
    if (newValue) {
      console.log("runned");
      await fetchTradeAnalytics();
    }
  },

  { immediate: true, deep: true }
);

onBeforeMount(async () => {
  await fetchTradeAnalytics();
});
</script>

<style lang="scss" scoped>
.analytics__wrapper {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  .analytics__header {
    width: 100%;
    max-width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    .header__date {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      overflow: hidden;
      :deep(.el-date-editor) {
        height: auto !important;
        width: 100%;
        gap: 0.7rem;
        padding: 10px 16px;
        box-shadow: none;
        .el-range-input {
          height: auto;
          @include fluidFont(14, 14, 1);
          color: rgba($blueDark, 0.5);
          font-weight: 600;
          letter-spacing: 0.25px;
        }
        .el-range-separator {
          line-height: 1;
        }
      }
      .input_field {
        width: 40%;
        max-width: 100%;
        :deep(.v-input__control) {
          .v-field {
            padding: 7px 0;
            .v-field__field {
              align-items: center;
              .v-field__input {
                min-height: auto;
                padding: 0;
                .v-select__selection {
                  .v-select__selection-text {
                    @include fluidFont(14, 14, 1);
                    color: rgba($blueDark, 0.5);
                    font-weight: 600;
                    letter-spacing: 0.25px;
                  }
                }
              }
            }
            .v-field__outline {
              display: none;
            }
          }
        }
      }
    }
    .icon_btn {
      .button {
        background-color: rgba($white, 1);
        border: 1px solid rgba($buttonText, 0.4);
        :deep(.v-btn__content) {
          gap: 4px;
          @include fluidFont(14, 14, 1);
          color: rgba($blueDark, 0.5);
          font-weight: 600;
          letter-spacing: 0.25px;
          text-transform: capitalize;
          .v-icon {
            img {
              filter: invert(58%) sepia(12%) saturate(661%) hue-rotate(198deg)
                brightness(87%) contrast(84%);
            }
          }
        }
      }
    }
  }
}
.el-popper .el-picker-panel {
  background-color: red;
}
</style>
