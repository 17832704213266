<template>
  <GeneralDialog
    class="tw-max-w-[450px]"
    @on-close="onCloseModal"
    width="460px"
    max-width="100%"
    borderRadius="20px"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <ContractSignYellowIcon />
        <Text
          variant="h4"
          textAlign="center"
          lineHeight="28px"
          class="tw-max-w-[80%]"
          >Please confirm that you are happy to submit estimate</Text
        >
        <v-checkbox
          v-if="
            (isEstimateLoadedModified && hasLoadedTemplate) ||
            !hasLoadedTemplate
          "
          @change="onCheckFullDeposit"
          v-model="onSaveAsTemplate"
          color="primary"
          value="1"
        >
          <template v-slot:label>
            <div class="tw-flex tw-items-center tw-gap-1">
              <Text
                variant="p"
                textWeight="500"
                textColor="rgba(12, 15, 74, 0.5)"
                >Save this estimate as a template</Text
              >
            </div>
          </template>
        </v-checkbox>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="tertiary"
          :disabled="loading"
          label="Cancel"
          @click="onCloseModal"
        />
        <Button label="Confirm" :isLoading="loading" @click="onSave" />
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <Text
          class="tw-max-w-[80%]"
          variant="h6"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          textAlign="center"
          >All estimates need to follow
          <span class="!tw-text-[orange]">our Terms & Conditions</span></Text
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ContractSignYellowIcon from "@/core/components/icons/ContractSignYellowIcon.vue";

const emits = defineEmits(["on-close"]);

const onCloseModal = () => {
  emits("on-close");
};
</script>

<script>
import { defineComponent } from "@vue/runtime-core";
import { mapGetters } from "vuex";

import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";

export default defineComponent({
  name: "confirm-create-template-modal",
  emits: ["close", "on-save"],
  components: {},
  data() {
    return {
      onSaveAsTemplate: false,
    };
  },

  props: {
    show: Boolean,
    loading: Boolean,
  },

  computed: {
    ...mapGetters(JOB_TEMPLATE_STORE, [
      "estimateForm",
      "selectedPhaseIndex",
      "templateName",
      "showCreateTemplateConfirmationModal",
      "showSubmitEstimateConfirmationModal",
      "isFormReady",
      "formData",
      "selectDepositModal",
      "isEstimateLoadedModified",
      "hasLoadedTemplate",
    ]),
  },

  methods: {
    cancel() {
      this.$emit("close");
    },

    onSave() {
      this.$emit("on-save", true);
    },
  },
});
</script>
