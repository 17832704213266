<template>
  <div class="tw-relative">
    <WorkStationVerificationLock
      v-if="
        activeUserWorkstation?.verificationStatus ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      "
    />
    <GeneralWidgetLayoutSlot
      class="!tw-min-h-[340px] !tw-max-h-[340px]"
      :title="`Trade Network (${
        allUserConnection?.length ? allUserConnection?.length : '0'
      })`"
      buttonLabel="Manage Preferred Trades"
      :hideFooter="!allUserConnection.length"
      @on-action-click="onOpenTradeNetwork"
      :withBorder="true"
      :isRoundedxl="true"
    >
      <template #header-rigth-content>
        <ToolTip
          description="The Trade Network connects property owners with qualified trade professionals for seamless project management and high-quality service."
        />
      </template>
      <template #content>
        <main
          class="tw-w-full tw-h-full tw-flex tw-flex-col tw-max-h-[203px] tw-overflow-auto tw-min-h-[200px]"
        >
          <template v-if="allUserConnection.length">
            <div
              class="tradenetwork__card tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-4 tw-box-border md:!tw-flex-col md:!tw-gap-[.5rem] md:!tw-items-start"
              :class="{
                'tw-border-b-solid-custom': index === 0,
              }"
              v-for="(item, index) in allUserConnection.slice(0, 2)"
              :key="index"
            >
              <div class="tw-flex tw-items-center tw-gap-4">
                <ProfileThumbnail
                  v-if="item?.receiverWorkstation?.publicURL"
                  :src="item?.receiverWorkstation?.publicURL"
                  :alt="item.receiverWorkstation?.name"
                  :showVerificationIcon="false"
                  :isVerified="true"
                />
                <WorkStationProfileLogo
                  class="trade_profile"
                  v-else
                  :WSName="item.receiverWorkstation?.name"
                />
                <div class="tw-flex tw-flex-col tw-items-start">
                  <Text variant="p">{{ item.receiverWorkstation?.name }}</Text>
                  <div class="tw-flex tw-gap-2">
                    <div
                      class="tw-flex tw-items-center tw-gap-2"
                      v-for="skill in item.receiverWorkstation?.userSkill?.slice(
                        0,
                        2
                      )"
                      :key="skill?.id"
                    >
                      <SpecialismChip
                        :label="skill?.category?.tpLabel"
                        :iconName="skill?.category?.iconName"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tw-flex tw-flex-col tw-gap-2 tw-items-start md:!tw-flex-row md:!tw-gap-2 md:!tw-w-full md:!tw-justify-between"
              >
                <ChipState
                  :hasCustomLabel="true"
                  bgColor="#FFF"
                  :hasShadow="true"
                  :isDense="true"
                >
                  <template #prefix>
                    <CheckGreen
                      class="tw-max-h-4 tw-max-w-4 tw-min-h-4 tw-min-w-4"
                    />
                  </template>
                  <template #custom-label>
                    <Text variant="span" textColor="#1FB27A">
                      {{
                        (item?.receiverWorkstation?.receivedInvites?.length ||
                          0) +
                        (item?.receiverWorkstation?.sentInvites?.length || 0)
                      }}
                      <Text variant="span" textWeight="400"
                        >Collaborative Projects</Text
                      >
                    </Text>
                  </template>
                </ChipState>
                <TradePassportActionChip
                  @click="
                    onClickManageTradePassport(item?.receiverWorkstation?.id)
                  "
                />
              </div>
            </div>
          </template>
          <section
            v-else
            class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3"
          >
            <img
              :src="require(`@/assets/images/trade-network-empty-state.png`)"
            />
            <Text variant="p" textWeight="600" textColor="rgba(12, 15, 74, 0.5)"
              >Your Trade Network will appear here</Text
            >
            <Button
              v-if="getUserWorkStationFieldAccess"
              variant="accent"
              label="Add Trade"
              class="!tw-w-auto"
              @click="onOpenTradeNetwork"
            />
          </section>
        </main>
      </template>
    </GeneralWidgetLayoutSlot>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import WorkStationVerificationLock from "@/core/components/WorkStationVerificationLock.vue";
import Button from "@/core/components/ui/general/Button.vue";
import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import SpecialismChip from "./../Chips/SpecialismChip.vue";
import TradePassportActionChip from "./../Chips/TradePassportActionChip.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import { TRADE_NETWORK_ROUTE } from "@/modules/trade-network/routes";
import $axios from "@/core/utils/axios-api-config";
import { useStore } from "vuex";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import {
  getImageApiUrl,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import ToolTip from "@/core/components/common/ToolTip.vue";
import { UserVerificationStatusEnum } from "@/core/enums/RolesEnum";
import { TRADE_ESTIMATES_TRADE_PASSPORT } from "@/modules/project/routes";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
const router = useRouter();
const store = useStore();

const props = defineProps({
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
});
const sampleLength = ["1", "2", "3", "4"];
const onOpenTradeNetwork = () => {
  router.push({ name: TRADE_NETWORK_ROUTE });
};
const allUserConnection = ref([]);
const params = ref({
  page: 1,
  limit: 10,
});

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const contractorBasedOnCategories = computed(() => {
  const contractorList =
    store.getters[`${TRADE_NETWORK}/getUserNetworkConnectedList`];
  allUserConnection.value = JSON.parse(JSON.stringify(contractorList))
    .map((contractor) => contractor?.userNetworkRequest)
    .flat();
  return contractorList;
});

const onClickManageTradePassport = (workstationId) => {
  if (!workstationId) return;
  store.dispatch(`${TRADE_ESTIMATE_STORE}/viewTradeUserAccount`, workstationId);
  router.push({
    name: TRADE_ESTIMATES_TRADE_PASSPORT,
  });
};

onMounted(async () => {
  await store.dispatch(`${TRADE_NETWORK}/setUserNetworkConnections`, {
    activeUserWorkstationId: activeUserWorkstation.value?.id,
    params: {
      page: params.value.page,
      limit: params.value.limit,
    },
  });

  allUserConnection.value = [];

  allUserConnection.value = JSON.parse(
    JSON.stringify(contractorBasedOnCategories.value)
  );
  for (const contractor of allUserConnection.value) {
    if (contractor?.receiverWorkstation?.profileImage !== null) {
      const imageUrl = setDirectStoragePathToImage(
        contractor?.receiverWorkstation?.profileImage
      );
      contractor.receiverWorkstation.publicURL = imageUrl;
    }
  }
});
</script>
<style lang="scss" scoped>
.tradenetwork__card:last-child {
  border: none !important;
}
.workstation-profile-logo {
  width: 100%;
  height: 100%;
  background-color: #4a92ef;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(74, 146, 239);
  width: 40px;
  height: 40px;
  margin-bottom: 0;
  border-radius: 100%;
  min-width: 40px;
}
.trade_profile {
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  color: #fff;
  width: 52px;
  height: 52px;
  border-radius: 8px;
  overflow: hidden;
}
</style>
