<template>
  <CommonDialog
    v-model="onCloseModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="848px"
    max-width="100%"
    persistent
    :className="['contract_assign_modal']"
  >
    <template v-slot:body>
      <div class="header">
        <!-- group name -->
        <h2>Phase # notes</h2>
        <div v-if="message" class="alert-box">
          {{ message }}
        </div>
        <v-icon
          icon="mdi-close"
          class="close__icon"
          @click="onCloseModal"
        ></v-icon>
      </div>
      <div class="modal__content">
        <v-expansion-panels v-if="phaseTasks.length">
          <v-expansion-panel v-for="(task, index) in phaseTasks" :key="task.id">
            <v-expansion-panel-title v-if="task?.phaseNotes?.length">
              <div class="contract_assign_modal_item_title">
                <h2>Note {{ index + 1 }}</h2>
                <div class="contract_assign_modal_tag">
                  <p>{{ task.name }}</p>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text v-if="task?.phaseNotes?.length">
              <div class="contract_assign_modal_item_content">
                <div
                  class="contract_assign_modal_info"
                  v-for="notes in task.phaseNotes"
                  :key="notes?.id"
                >
                  <div class="contract_assign_modal_info_header">
                    <p>
                      {{ notes?.note }}
                    </p>

                    <div class="contract_assign_modal_info_actions">
                      <v-icon
                        icon="mdi-pencil"
                        class="action-icon"
                        v-if="!isPropertyOwner"
                        @click="onEditNote(notes)"
                      />
                      <v-icon
                        icon="mdi-delete"
                        class="action-icon"
                        v-if="!isPropertyOwner"
                        @click="toggleShowDeleteModal(notes)"
                      />
                    </div>
                  </div>
                  <div class="contract_assign_modal_info_footer">
                    <div class="contract_assign_modal_info_author">
                      <div class="contract_assign_modal_info_author_image">
                        <img
                          v-if="notes?.user?.profileImageUrl"
                          :src="notes?.user?.profileImageUrl"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/images/contractor-profile.png"
                          alt=""
                        />
                      </div>
                      <p>
                        {{
                          notes?.user
                            ? `${notes.user.firstName} ${notes.user.lastName}`
                            : "Toby Sparks"
                        }}
                      </p>
                    </div>
                    <div class="contract_assign_modal_info_date">
                      <p>{{ formatDate(notes?.dateCreated) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div
        class="contract_assign_modal_footer"
        :class="{ 'show-add-btn': isShowAddNoteBtn }"
      >
        <Button
          v-if="isShowAddNoteBtn"
          label="+Add Note"
          variant="elevated"
          class="button button-orange"
          @click="onAddNote"
        />
        <Button
          label="Cancel"
          variant="elevated"
          class="button button-purple-border"
          @click="onCloseModal"
        />
      </div>
    </template>
  </CommonDialog>
  <ConfirmPhaseTaskDeleteModal
    v-if="isShowDeleteModal"
    message="Are you sure you would like to delete this Task note?"
    @on-close="toggleShowDeleteModal"
    @on-confirm="onDeleteNote"
    :Loadingvalue="Loading"
  />
</template>

<script lang="ts">
import { computed, ref } from "vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import moment from "moment";
import ConfirmPhaseTaskDeleteModal from "@/modules/jobs/components/modal/ConfirmPhaseTaskDeleteModal.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import { USER_STORE } from "@/store/modules/user";

export default {
  components: {
    CommonDialog,
    Button,
    ConfirmPhaseTaskDeleteModal,
  },
  emits: ["on-action", "on-close", "on-edit", "on-delete"],
  props: {
    phaseDetails: { type: Object, default: null },
    isEstimateNote: { type: Boolean, default: false },
    isShowAddNoteBtn: { type: Boolean, default: false },
    message: { type: String, default: null },
  },

  setup(props: any, ctx: any) {
    const store = useStore();
    const isShowDeleteModal = ref(false);
    const selectedNote = ref(null) as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isPropertyOwner = computed(() => {
      return user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER;
    });
    const Loading = ref(false);
    const onCloseModal = () => {
      ctx.emit("on-close");
    };
    const onAddNote = () => {
      ctx.emit("on-action", "add-note");
    };
    const onEditNote = (note: any) => {
      ctx.emit("on-edit", note);
    };

    const toggleShowDeleteModal = (note: any) => {
      if (note) {
        selectedNote.value = note;
      }
      isShowDeleteModal.value = !isShowDeleteModal.value;
    };

    const onDeleteNote = async () => {
      try {
        Loading.value = true;
        if (!selectedNote.value || !selectedNote.value.phase?.id) return;
        await tradeEstimateService.deleteEstimatePhaseTaskNote(
          selectedNote.value?.id
        );
        await store.dispatch(
          `${JOB_TEMPLATE_STORE}/setEstimatePhaseNotes`,
          selectedNote.value.phase?.id
        );
        ctx.emit("on-delete");
      } catch (error) {
        console.log("error", error);
      } finally {
        Loading.value = false;
        isShowDeleteModal.value = false;
      }
    };

    const phaseTasks = computed(() => {
      if (props.isEstimateNote) {
        const groupedByTaskOrPhase = props.phaseDetails.reduce(
          (acc: any, note: any) => {
            const key = note.task
              ? `task_${note.task.id}`
              : `phase_${note.phase.id}`;

            if (!acc[key]) {
              acc[key] = {
                ...(note.task ? note.task : note.phase),
                phaseNotes: [],
              };
            }

            acc[key].phaseNotes.push(note);

            return acc;
          },
          {} as { [key: string]: { id: number; name: string; phaseNotes: [] } }
        );
        return Object.values(groupedByTaskOrPhase);
      } else {
        return props.phaseDetails.estimationPhaseTasks;
      }
    });

    const formatDate = (date: any) =>
      moment(date).format("DD, MMMM YYYY | hh:mm A");
    return {
      formatDate,
      onCloseModal,
      phaseTasks,
      onAddNote,
      onEditNote,
      onDeleteNote,
      isShowDeleteModal,
      toggleShowDeleteModal,
      Loading,
      isPropertyOwner,
    };
  },
};
</script>
<style scoped lang="scss">
.contract_assign_modal_info_actions {
  display: flex;
  gap: 8px;
  align-items: center;
  .v-icon {
    background-color: rgba($blueDark, 1);
    border-radius: 100%;
    color: rgba($white, 1);
    @include fluidFont(16, 16, 1);
    width: 30px;
    height: 30px;
  }
}
.contract_assign_modal_info_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.alert-box {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #4caf50; /* Green for success */
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
</style>
