<template>
  <div class="trade_passport_wrapper">
    <v-tabs
      @update:modelValue="onTabChange"
      class="tab-list"
      align-tabs="center"
      stacked
      v-model="activeTab"
    >
      <v-tab
        v-for="item in tradeItems"
        :key="item.name"
        :value="item.name"
        :disabled="isItemDisable(item?.name)"
      >
        <v-icon :icon="item.icon" />
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <div v-if="isLoading && activeTab === 'Overview'">
      <Loader :show="isLoading" />
    </div>
    <div v-if="activeTab === 'Overview' && !isLoading" class="overview">
      <!-- <div
        v-if="
          isUserProfileVerified &&
          userWorkStationStatus === UserVerificationStatusEnum.NOT_ADDED &&
          isUserAddBusinessAddress &&
          !isUserProprtyOwner &&
          getUserWorkStationFieldAccess
        "
        class="tw-w-full tw-flex tw-mb-2 tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
      >
        <NoticeIcon />
        <Text variant="span" textColor="#0C0F4A"
          >Next Action for workstation setup:
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickSubmitVerification"
            >Submit Your Workstation for Verification</Text
          ></Text
        >
      </div> -->
      <!-- KYC/KYB NOTICE For Solo trader -->
      <div
        v-if="
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          ((getVerificationStatus.statusClass === 'info' &&
            !isLoading &&
            activeUserWorkstation?.verificationStatus !==
              UserVerificationStatusEnum.VERIFIED &&
            accountStatuses?.forms?.insurance !== 0 &&
            accountStatuses?.forms?.certification !== 0 &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
            accountStatuses?.forms?.skills !== 0 &&
            getUserWorkStationFieldAccess) ||
            (activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.VERIFIED &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER
              ? activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.NOT_ADDED
              : false))
        "
        class="tw-w-full tw-mb-3 tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
      >
        <NoticeIcon />

        <Text
          v-if="
            !activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER
          "
          variant="span"
          textColor="#0C0F4A"
          >Action Required: Add Business Address.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickAddBusinessAddressModal"
            >Manage Address</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.NOT_ADDED &&
            activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            userBusinessType === BusinessDetailTypeEnum.SOLETRADER &&
            activeUserWorkstation?.verificationStatus !==
              UserVerificationStatusEnum.PENDING_VERIFICATION
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Complete ID Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickIDVerificationModal"
            >Get Verified</Text
          ></Text
        >

        <Text
          v-if="
            (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            (activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION ||
              activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.VERIFIED) &&
            !isUserProfileVerified
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Complete Your Trade Passport.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            >Manage Trade Passport</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.verificationStatus ==
              UserVerificationStatusEnum.NOT_ADDED &&
            isUserProfileVerified &&
            activeUserWorkstation?.user?.userBusinessDetails
              ?.residencyPostcode &&
            activeUserWorkstation?.user?.userKYCVerificationStatus !==
              UserVerificationStatusEnum.NOT_ADDED
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Complete Workstation Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickSubmitVerification"
            >Get Verified</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.verificationStatus ==
              UserVerificationStatusEnum.PENDING_VERIFICATION &&
            isUserProfileVerified &&
            activeUserWorkstation?.user?.userBusinessDetails?.residencyPostcode
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Verification Pending.
        </Text>
      </div>
      <!-- div completed Here -->
      <!-- KYC/KYB NOTICE For Limited Company -->

      <div
        v-if="
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          ((getVerificationStatus.statusClass === 'info' &&
            !isLoading &&
            activeUserWorkstation?.verificationStatus !==
              UserVerificationStatusEnum.VERIFIED &&
            accountStatuses?.forms?.insurance !== 0 &&
            accountStatuses?.forms?.certification !== 0 &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
            accountStatuses?.forms?.skills !== 0 &&
            getUserWorkStationFieldAccess) ||
            (activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.VERIFIED &&
              userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
              !WorkStationSiltVerificationStatus))
        "
        class="tw-w-full tw-mb-3 tw-flex tw-gap-3 tw-rounded-[10px] tw-p-3 tw-box-border tw-bg-[rgba(255,165,0,0.2)] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
      >
        <NoticeIcon />
        <Text
          v-if="
            !activeUserWorkstation?.user?.userBusinessDetails
              ?.businessPostcode &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
          "
          variant="span"
          textColor="#0C0F4A"
          >Action Required: Add Business Address.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickAddBusinessAddressModal"
            >Manage Address</Text
          ></Text
        >

        <Text
          v-if="
            !WorkStationSiltVerificationStatus &&
            activeUserWorkstation?.user?.userBusinessDetails
              ?.businessPostcode &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY &&
            userWorkStationStatus !==
              UserVerificationStatusEnum.PENDING_VERIFICATION
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Complete ID & Business Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickIDVerificationModal"
            >Get Verified</Text
          ></Text
        >
        {{ WorkStationSiltVerificationStatus }}
        <Text
          v-if="
            (WorkStationSiltVerificationStatus &&
            userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            (activeUserWorkstation?.user?.userKYCVerificationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION ||
              activeUserWorkstation?.user?.userKYCVerificationStatus ===
                UserVerificationStatusEnum.VERIFIED) &&
            !isUserProfileVerified
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Complete Your Trade Passport.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickManageTradePassportPreviewModal"
            >Manage Trade Passport</Text
          ></Text
        >
        <Text
          v-if="
            isUserVerifiedKYCAndKYB &&
            isUserProfileVerified &&
            (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode) &&
            userWorkStationStatus === UserVerificationStatusEnum.NOT_ADDED
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Complete Workstation Verification.
          <Text
            class="tw-cursor-pointer tw-underline"
            variant="span"
            textColor="#4B4BFF"
            @click="onClickSubmitVerification"
            >Get Verified</Text
          ></Text
        >
        <Text
          v-if="
            activeUserWorkstation?.verificationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION &&
            isUserProfileVerified &&
            (userBusinessType === BusinessDetailTypeEnum.LIMITED_COMPANY
              ? activeUserWorkstation?.user?.userBusinessDetails
                  ?.businessPostcode
              : activeUserWorkstation?.user?.userBusinessDetails
                  ?.residencyPostcode)
          "
          variant="span"
          textColor="#FA4B00"
          >Action Required: Verification Pending.
        </Text>
      </div>

      <div
        class="btn-import-workshop"
        v-if="
          isUserProprtyOwner &&
          userAllWorkStation?.length > 2 &&
          getUserWorkStationFieldAccess
        "
      >
        <div class="back-btn" v-if="isUserProprtyOwner">
          <BackButton
            :label="
              isShowRates ? 'Back to Your Estimates' : 'Back To Dashboard'
            "
            @click="onClickBackEstimateButton"
          />
        </div>
      </div>

      <Card
        class="tw-p-5 !tw-max-h-[unset] !tw-mb-5"
        v-if="
          !isShowRates &&
          activeUserWorkstation?.verificationStatus ===
            UserVerificationStatusEnum.VERIFIED &&
          (getIsOpenTradePassportasViewMode
            ? true
            : activeUserWorkstation?.workstationLinkTree) &&
          shouldShowBanner
        "
      >
        <template #content>
          <WorkStationLinkTreeWidget
            :isViewMode="getIsOpenTradePassportasViewMode"
          />
        </template>
      </Card>

      <!-- TRADE PASSPORT GENERAL DETAILS -->
      <Card class="tw-p-5 !tw-max-h-[unset]">
        <template #content>
          <section
            class="tw-grid tw-w-full tw-gap-5 tw-min-h-[235px]"
            :class="isShowRates ? 'rlg:tw-grid-cols-1' : 'rlg:tw-grid-cols-2'"
          >
            <!-- Workstation details card -->
            <Card class="!tw-h-full tw-p-4 tw-w-full" :withBorder="true">
              <template #content>
                <div class="tw-h-full tw-flex-col-between">
                  <div class="tw-h-full tw-flex tw-items-center tw-gap-3">
                    <div>
                      <div
                        class="tw-relative"
                        v-if="
                          userWorkStationImage || contractorworkStationImage
                        "
                      >
                        <ProfileThumbnail
                          :isRounded="false"
                          :desktopFontSizeBig="true"
                          size="xllg"
                          mobilSize="xlg"
                          :isOnline="true"
                          :src="
                            isUserProprtyOwner
                              ? contractorworkStationImage
                              : userWorkStationImage
                          "
                        />
                        <ChipState
                          bgColor="#FFF"
                          :hasShadow="true"
                          class="tw-absolute tw-top-[-.5rem] tw-right-[-.5rem] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
                        >
                          <template #prefix>
                            <div>
                              <CheckGreenShield
                                v-if="
                                  activeUserWorkstation?.verificationStatus ===
                                  UserVerificationStatusEnum.VERIFIED
                                "
                              />
                              <ErrorRedIcon v-else />
                            </div>
                          </template>
                        </ChipState>
                      </div>

                      <div
                        v-else
                        class="tw-rounded-full tw-max-h-[104px] tw-max-w-[104px] tw-min-h-[104px] tw-min-w-[104px] tw-h-[48px] tw-relative"
                      >
                        <ChipState
                          bgColor="#FFF"
                          :hasShadow="true"
                          class="tw-absolute tw-top-[-.5rem] tw-right-[-.5rem] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
                        >
                          <template #prefix>
                            <div>
                              <CheckGreenShield
                                v-if="
                                  activeUserWorkstation?.verificationStatus ===
                                  UserVerificationStatusEnum.VERIFIED
                                "
                              />
                              <ErrorRedIcon v-else />
                            </div>
                          </template>
                        </ChipState>
                        <UserProfileLogo
                          class="!tw-rounded-full"
                          :desktopFontSizeBig="true"
                          :mobileFontSizeBig="true"
                          :isRounded="false"
                        />
                      </div>
                    </div>

                    <div class="tw-flex tw-flex-col tw-gap-1 tw-items-start">
                      <div
                        class="tw-flex-col tw-flex tw-gap-2 tw-flex-start rsm:tw-items-center rsm:tw-flex-row"
                      >
                        <Text
                          class="rsm:!tw-text-[22px]"
                          variant="h6"
                          textAlign="left"
                          >{{ activeUserWorkstation?.name }}
                        </Text>
                        <ChipState
                          class="!tw-w-[90px]"
                          bgColor="rgba(249, 130, 40, 0.26)"
                          :label="
                            activeWorkStationType(
                              user?.userBusinessDetails?.type
                            )
                          "
                        ></ChipState>
                      </div>
                      <div
                        class="tw-hidden tw-items-center tw-gap-1 rsm:!tw-flex"
                      >
                        <Text
                          class="tw-w-[60%]"
                          variant="p"
                          textWeight="500"
                          textAlign="left"
                          >{{
                            workstationAddress(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            ) !== "null null"
                              ? workstationAddress(
                                  activeUserWorkstation?.user
                                    ?.userBusinessDetails?.type
                                )
                              : ""
                          }}</Text
                        >
                        <el-divider
                          v-if="
                            workstationPostcode(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            ) !== null
                          "
                          direction="vertical"
                        ></el-divider>
                        <Postcode
                          v-if="
                            workstationPostcode(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            ) !== null
                          "
                          :value="
                            workstationPostcode(
                              activeUserWorkstation?.user?.userBusinessDetails
                                ?.type
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-flex tw-flex-col tw-items-start tw-gap-1 rsm:!tw-hidden"
                  >
                    <Text variant="p" textWeight="500" textAlign="left">{{
                      workstationAddress(
                        activeUserWorkstation?.user?.userBusinessDetails?.type
                      ) !== "null null"
                        ? workstationAddress(
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.type
                          )
                        : ""
                    }}</Text>
                    <Postcode
                      v-if="
                        workstationPostcode(
                          activeUserWorkstation?.user?.userBusinessDetails?.type
                        ) !== null
                      "
                      :value="
                        workstationPostcode(
                          activeUserWorkstation?.user?.userBusinessDetails?.type
                        )
                      "
                    />
                  </div>
                  <div class="view-rating-box">
                    <v-rating
                      active-color="orange"
                      v-model="getAverageOfAllProjectReview"
                      color="rgba(12, 15, 74, 0.26)"
                      empty-icon="mdi-star"
                      readonly
                    ></v-rating>
                    <p>{{ getAverageOfAllProjectReview }} out of 5</p>
                    <v-btn
                      class="button button-orange"
                      append-icon="mdi-chevron-right"
                      @click="onClickViewRatings"
                      >view Ratings
                    </v-btn>
                  </div>
                  <div
                    class="tw-border-t-solid-custom tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
                  >
                    <div
                      v-if="
                        getUserWorkStationFieldAccess && !isUserProprtyOwner
                      "
                      class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                      @click="onClickEditWorkStation"
                    >
                      <Text variant="p" textColor="#FFA500"
                        >Edit Workstation</Text
                      >
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </div>
                  </div>
                </div>
              </template>
            </Card>

            <!-- Rate details card -->

            <Card
              class="!tw-h-full tw-p-4"
              :withBorder="true"
              v-if="!isShowRates"
            >
              <template #content>
                <div class="tw-h-full tw-flex-col-between">
                  <div class="tw-h-full tw-flex tw-flex-col tw-gap-4">
                    <div class="tw-flex-row-between">
                      <Text variant="h5">Rates</Text>
                      <span class="icon-title-info">
                        <ToolTip
                          description="Displaying Hourly Rate, Emergency Call Out Fee, and Video Consultation Rate."
                        />
                      </span>
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-gap-4 tw-w-full rmd:!tw-flex-row tw-items-center"
                    >
                      <div
                        class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-bg-[#F1F6FB] tw-py-5 tw-px-6 tw-box-border tw-rounded-[8px]"
                      >
                        <div class="tw-flex tw-items-center tw-gap-2">
                          <img
                            :src="require('@/assets/images/circle-money.svg')"
                            alt=""
                          />
                          <Text variant="span">Regular</Text>
                        </div>
                        <div
                          class="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-justify-center"
                        >
                          <div
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2"
                          >
                            <Text variant="h6">{{
                              CurrencyFilter.formatToCurrency(
                                activeUserWorkstation?.hourlyRate?.minRate
                              )
                            }}</Text>
                            <Text variant="span" textWeight="400">Hour</Text>
                          </div>
                          <div
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2"
                          >
                            <Text variant="h6">{{
                              CurrencyFilter.formatToCurrency(
                                activeUserWorkstation?.dayRate?.minRate
                              )
                            }}</Text>
                            <Text variant="span" textWeight="400">Day</Text>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-bg-[#F1F6FB] tw-py-5 tw-px-6 tw-box-border tw-rounded-[8px]"
                      >
                        <div class="tw-flex tw-items-center tw-gap-2">
                          <v-icon
                            icon="mdi-alert-circle"
                            color="#0C0F4A"
                          ></v-icon>
                          <Text variant="span">Emergency</Text>
                        </div>
                        <div
                          class="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-justify-center"
                        >
                          <div
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2"
                          >
                            <Text variant="h6">{{
                              CurrencyFilter.formatToCurrency(
                                activeUserWorkstation?.emergencyCallOutFee
                              )
                            }}</Text>
                            <Text variant="span" textWeight="400"
                              >Callout Fee</Text
                            >
                          </div>
                          <div
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2"
                          >
                            <Text variant="h6">{{
                              CurrencyFilter.formatToCurrency(
                                activeUserWorkstation?.emergencyCallOutRate
                              )
                            }}</Text>
                            <Text variant="span" textWeight="400">Hourly</Text>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-bg-[#F1F6FB] tw-py-5 tw-px-6 tw-box-border tw-rounded-[8px]"
                      >
                        <div class="tw-flex tw-items-center tw-gap-2">
                          <img
                            :src="
                              require('@/assets/images/circle-construction.svg')
                            "
                            alt=""
                          />
                          <Text variant="span">Consultation</Text>
                        </div>
                        <div
                          class="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-justify-center"
                        >
                          <div
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2"
                          >
                            <Text variant="h6">{{
                              CurrencyFilter.formatToCurrency(
                                activeUserWorkstation?.videoConsulationFee
                              )
                            }}</Text>
                            <Text variant="span" textWeight="400">Video</Text>
                          </div>
                          <div
                            class="tw-flex tw-items-center tw-justify-between tw-gap-2"
                          >
                            <Text variant="h6">{{
                              CurrencyFilter.formatToCurrency(
                                activeUserWorkstation?.inpersonVideoFees
                              )
                            }}</Text>
                            <Text variant="span" textWeight="400"
                              >In-Person</Text
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-border-t-solid-custom tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
                  >
                    <div
                      v-if="
                        getUserWorkStationFieldAccess && !isUserProprtyOwner
                      "
                      class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                      @click="onClickUpdateUserRates"
                    >
                      <Text variant="p" textColor="#FFA500">Edit Rates</Text>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </section>
        </template>
      </Card>

      <v-row class="trade-passport-row row-1">
        <!-- Your Trade Specialisms -->
        <div class="second w-4 tw-h-[570px]">
          <v-card class="custom-card specialisms-card">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Services</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Displaying Your Specialisms in which you can  manage your trade specialism."
                />
              </span>
            </div>

            <div
              class="tw-flex tw-gap-4 tw-h-full !tw-overflow-y-auto md:!tw-pr-[.2rem]"
            >
              <div
                class="card-services tw-grid tw-w-full tw-gap-4 tw-flex-wrap !tw-pb-4 md:tw-gap-2"
                v-if="filterUserSpecialisms?.length && !specialismsLoading"
              >
                <template v-for="(skill, index) in filterUserSpecialisms">
                  <SpecialismItemTwo
                    class="card-service-icon-list-item"
                    :class="{
                      '!tw-max-h-[136px] ': filterUserSpecialisms?.length < 7,
                    }"
                    v-if="index < 8"
                    :key="index"
                    :label="skill.label"
                    :iconName="skill.iconName"
                  />
                </template>
                <div
                  v-if="filterUserSpecialisms?.length > 8"
                  class="tw-bg-[rgba(255,165,0,0.1)] tw-w-full tw-rounded-lg tw-py-3 tw-px-4 tw-box-border tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-border-[1px] tw-border-[#FFA500] tw-border-solid tw-h-[136px]"
                >
                  <div
                    class="tw-bg-[#FFA500] tw-rounded-full tw-min-h-[40px] tw-min-w-[40px] tw-flex tw-items-center tw-justify-center"
                  >
                    <Text class="!tw-text-[18px]" textColor="#FFF">{{
                      `${filterUserSpecialisms?.length - 8}+`
                    }}</Text>
                  </div>
                  <Text variant="span">More</Text>
                </div>
              </div>
              <div
                v-if="specialismsLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-if="!filterUserSpecialisms?.length && !specialismsLoading"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/specialisms-empty.png')"
                    alt="icon"
                    class="tw-h-full tw-w-full tw-object-contain"
                  />
                </div>

                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                >
                  {{
                    isUserProprtyOwner
                      ? "Services will appear here"
                      : "Your Services will appear here"
                  }}
                </Text>

                <Button
                  v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
                  @click="onClickManageSpecialism"
                  variant="accent"
                  label="Add Services"
                  class="!tw-w-[auto]"
                />
              </div>
            </div>
            <div
              v-if="filterUserSpecialisms?.length"
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end tw-mt-[auto]"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickManageSpecialism"
              >
                <Text v-if="isUserProprtyOwner" variant="p" textColor="#FFA500"
                  >View Services Details</Text
                >
                <Text v-else variant="p" textColor="#FFA500"
                  >Manage Services</Text
                >
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
          </v-card>
        </div>

        <div class="second w-4 !tw-h-[570px]">
          <v-card class="custom-card insurance_wrapper">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Insurance</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Displaying Your Insurances in which you can  manage your trade Insurances."
                />
              </span>
            </div>

            <div class="tw-h-full insurance">
              <div
                class="insurances-list"
                v-if="userInsurances?.length && !insuranceLoading"
              >
                <template
                  v-for="insurance in userInsurances.slice(0, 2)"
                  :key="insurance.id"
                >
                  <GreyItemCard>
                    <template #status>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PendingChip
                          class="tw-w-[100px]"
                          v-if="
                            insurance?.userVerificationStatus ===
                              UserVerificationStatusEnum.PENDING_VERIFICATION &&
                            !isExpiredDate(insurance?.metadata?.expiryDate)
                          "
                        />

                        <AcceptChip
                          class="tw-w-[100px]"
                          label="Accepted"
                          v-if="
                            insurance?.userVerificationStatus ===
                              UserVerificationStatusEnum.VERIFIED &&
                            !isExpiredDate(insurance?.metadata?.expiryDate) &&
                            !isUserProprtyOwner
                          "
                        />
                        <AcceptChip
                          class="tw-w-[100px]"
                          label="Verified"
                          v-if="
                            insurance?.userVerificationStatus ===
                              UserVerificationStatusEnum.VERIFIED &&
                            !isExpiredDate(insurance?.metadata?.expiryDate) &&
                            isUserProprtyOwner
                          "
                        />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Rejected"
                          v-if="
                            insurance?.userVerificationStatus ===
                              UserVerificationStatusEnum.REJECTED &&
                            !isExpiredDate(insurance?.metadata?.expiryDate)
                          "
                        />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Expired"
                          v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                        />
                      </div>
                    </template>
                    <template #action>
                      <div
                        class="tw-flex tw-gap-2 tw-items-center"
                        v-if="
                          getUserWorkStationFieldAccess && !isUserProprtyOwner
                        "
                      >
                        <PencilCircularBlueIcon
                          @click="onEditInsurance(insurance)"
                          class="!tw-min-h-[30px] !tw-min-w-[30px] tw-cursor-pointer"
                        />
                        <TrashCircularGrayIcon
                          class="tw-cursor-pointer"
                          @click="
                            onDeleteItem(
                              [],
                              insurance.id,
                              confirmDeleteInsuranceData.type,
                              confirmDeleteInsuranceData.label
                            )
                          "
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
                        <Text variant="h6">{{
                          insurance?.metadata?.insuranceName ||
                          insurance?.insurance?.name
                        }}</Text>
                        <Text variant="p" textWeight="400"
                          >Coverage: £{{
                            formatSum(insurance?.metadata?.sum)
                          }}</Text
                        >
                        <Text variant="p" textWeight="400"
                          >Expiry:
                          {{
                            formatDate(insurance?.metadata?.expiryDate)
                          }}</Text
                        >
                      </div>
                    </template>
                  </GreyItemCard>
                </template>
                <div
                  class="tw-w-full tw-flex tw-justify-end"
                  v-if="userInsurances?.length > 2"
                >
                  <div
                    class="tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-rounded-[20px] tw-bg-[rgba(12,15,74,0.05)] tw-flex tw-items-center tw-justify-center tw-py-1 tw-px-2 tw-box-border"
                  >
                    <Text variant="p" textColor="rgba(12,15,74,0.5)"
                      >+{{ userInsurances?.length - 2 }} more Insurance(s)</Text
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="insuranceLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>

              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-if="!userInsurances?.length && !insuranceLoading"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/insurance-empty-state.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                >
                  {{
                    isUserProprtyOwner
                      ? "Insurance details appear here"
                      : "Your Insurance details will appear here"
                  }}
                </Text>
                <Button
                  v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
                  @click="onClickAddInsurance"
                  variant="accent"
                  label="Add Insurances"
                  class="!tw-w-[auto]"
                />
              </div>
            </div>
            <div
              v-if="userInsurances?.length"
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickAddInsurance"
              >
                <Text v-if="isUserProprtyOwner" variant="p" textColor="#FFA500"
                  >View Insurance Details</Text
                >
                <Text v-else variant="p" textColor="#FFA500"
                  >Manage Insurance</Text
                >
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
          </v-card>
        </div>

        <!-- Your Certifications -->
        <div class="second w-4 !tw-h-[570px]">
          <v-card class="custom-card certificate_wrapper">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Certifications</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Displaying Your Certifications in which you can  manage your trade Certifications."
                />
              </span>
            </div>

            <div
              class="tw-h-full certificates tw-flex tw-justify-between tw-items-center tw-flex-col tw-overflow-auto"
            >
              <div
                class="card__list tw-flex tw-flex-col tw-gap-4 tw-pb-4 tw-w-full tw-max-w-full"
                v-if="userCertificates?.length && !certificateLoading"
              >
                <template
                  v-for="certificate in userCertificates.slice(0, 2)"
                  :key="certificate?.id"
                >
                  <GreyItemCard>
                    <template #status>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <PendingChip
                          class="tw-w-[100px]"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.PENDING_VERIFICATION
                          "
                        />
                        <AcceptChip
                          class="tw-w-[100px]"
                          label="Accepted"
                          v-if="
                            certificate?.userVerificationStatus ===
                              UserVerificationStatusEnum.VERIFIED &&
                            !isUserProprtyOwner
                          "
                        />
                        <AcceptChip
                          class="tw-w-[100px]"
                          label="Verified"
                          v-if="
                            certificate?.userVerificationStatus ===
                              UserVerificationStatusEnum.VERIFIED &&
                            isUserProprtyOwner
                          "
                        />
                        <RejectChip
                          class="tw-w-[100px]"
                          label="Rejected"
                          v-if="
                            certificate?.userVerificationStatus ===
                            UserVerificationStatusEnum.REJECTED
                          "
                        />
                      </div>
                    </template>
                    <template #action>
                      <div
                        class="tw-flex tw-gap-2 tw-items-center"
                        v-if="
                          getUserWorkStationFieldAccess && !isUserProprtyOwner
                        "
                      >
                        <PencilCircularBlueIcon
                          @click="onEditCertificate(certificate)"
                          class="!tw-min-h-[30px] !tw-min-w-[30px] tw-cursor-pointer"
                        />
                        <TrashCircularGrayIcon
                          class="tw-cursor-pointer"
                          @click="
                            onDeleteItem(
                              certificate.assignedTo,
                              certificate.id,
                              confirmDeleteCertificateData.type,
                              confirmDeleteCertificateData.label
                            )
                          "
                        />
                      </div>
                    </template>
                    <template #content>
                      <div class="tw-w-full tw-flex tw-flex-col tw-items-start">
                        <div
                          class="tw-w-full tw-flex tw-items-start tw-gap-2 tw-pb-4 tw-box-border"
                        >
                          <ServiceIconWrapper
                            bgColor="#0C0F4A"
                            filter="invert(100%)
                      brightness(100%) saturate(0%)"
                          />
                          <div class="tw-flex tw-flex-col tw-items-start">
                            <h6
                              class="tw-text-base tw-font-bold tw-leading-[18px] tw-tracking-[0.4px] tw-text-[#0C0F4A]"
                            >
                              {{ certificate?.certificate?.name }}
                            </h6>

                            <Text
                              variant="p"
                              textWeight="400"
                              v-if="certificate?.metadata?.registrationNumber"
                            >
                              Membership No:
                              {{
                                certificate?.metadata?.registrationNumber
                              }}</Text
                            >
                          </div>
                        </div>
                        <div
                          v-if="certificate?.attachment?.length"
                          class="tw-pt-2 tw-box-border tw-border-t-dashed-custom tw-w-full tw-flex tw-gap-2 tw-items-center tw-justify-start"
                        >
                          <PdfIconGray />
                          <p
                            class="tw-text-sm tw-font-bold tw-leading-[18px] tw-tracking-[0.4px] tw-text-[#0C0F4A] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-max-w-[360px]"
                          >
                            {{ certificate?.attachment[0]?.originalName }}
                          </p>
                          <!-- <Text variant="p" textWeight="400">
                            {{ certificate?.attachment[0]?.originalName }}</Text
                          > -->
                        </div>
                      </div>
                    </template>
                  </GreyItemCard>
                </template>
                <div
                  class="tw-w-full tw-flex tw-justify-end"
                  v-if="userCertificates?.length > 2"
                >
                  <div
                    class="tw-border-solid tw-border-[1px] tw-border-[rgba(12,15,74,0.1)] tw-rounded-[20px] tw-bg-[rgba(12,15,74,0.05)] tw-flex tw-items-center tw-justify-center tw-py-1 tw-px-2 tw-box-border"
                  >
                    <Text variant="p" textColor="rgba(12,15,74,0.5)"
                      >+{{ userCertificates?.length - 2 }} more
                      Certificate(s)</Text
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="certificateLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-if="!userCertificates?.length && !certificateLoading"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/certificates-empty.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                >
                  {{
                    isUserProprtyOwner
                      ? "Certificates will appear here"
                      : "Your Certificates will appear here"
                  }}
                </Text>
                <Button
                  v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
                  @click="onClickManageCertifications"
                  variant="accent"
                  label="Add Certificates"
                  class="!tw-w-[auto]"
                />
              </div>
            </div>
            <div
              v-if="userCertificates?.length"
              class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
            >
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                @click="onClickManageCertifications"
              >
                <Text v-if="isUserProprtyOwner" variant="p" textColor="#FFA500"
                  >View Certificates Details</Text
                >
                <Text v-else variant="p" textColor="#FFA500"
                  >Manage Certificates</Text
                >
                <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>

      <!-- Description -->
      <v-row class="trade-passport-row row-1">
        <div class="second tw-w-full">
          <v-card class="custom-card description_wrapper">
            <div class="icon-title tw-m-0">
              <div class="icon-title__inner">
                <p class="common-title">Description</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Showing your Description and you can edit your Description"
                />
              </span>
            </div>

            <div>
              <div class="editor_text" v-if="userDescriptionData">
                <span v-html="userDescriptionData"></span>
              </div>
              <div
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4"
                v-else
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/description-empty.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                >
                  {{
                    isUserProprtyOwner
                      ? "Description will appear here"
                      : "Your Description will appear here"
                  }}</Text
                >
                <Button
                  v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
                  @click="onEditDescription(userDescriptionData)"
                  variant="accent"
                  label="Add Description"
                  class="!tw-w-[auto]"
                />
              </div>

              <div
                v-if="
                  userDescriptionData &&
                  getUserWorkStationFieldAccess &&
                  !isUserProprtyOwner
                "
                class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end"
              >
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                  @click="onEditDescription(userDescriptionData)"
                >
                  <Text variant="p" textColor="#FFA500">{{
                    userDescriptionData ? "Edit Description" : "Add Description"
                  }}</Text>
                  <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>

      <!-- Gallery -->
      <v-row class="trade-passport-row row-1 md:!tw-pb-16 md:!tw-box-border">
        <div class="second !tw-min-h-[300px] tw-w-full">
          <v-card class="custom-card description_wrapper">
            <div class="icon-title">
              <div class="icon-title__inner">
                <p class="common-title">Gallery</p>
              </div>
              <span class="icon-title-info">
                <ToolTip
                  description="Your Gallery photos are shows here.and you can  manage your gallery"
                />
              </span>
            </div>

            <div
              class="tw-flex tw-flex-col tw-justify-between tw-items-start tw-h-full gallery"
            >
              <div
                class="gallery-content-container tw-gap-4 tw-grid tw-w-full tw-pb-4 tw-max-h-[342px] tw-overflow-auto tw-min-h-[170px]"
                v-if="fileList && fileList?.length && !state.galleryIsLoading"
              >
                <template v-for="(file, index) in fileList" :key="index">
                  <div class="gallery_item tw-relative">
                    <TrashCircularWhiteIcon
                      v-if="getUserWorkStationFieldAccess"
                      @click="onOpenDeleteConfirmationModal(file)"
                      class="tw-absolute tw-right-3 tw-top-3 tw-z-50 tw-cursor-pointer"
                    />
                    <div
                      v-if="file.docType === 'gallery'"
                      @click="onOpenPhotoPreviewModal(file)"
                      class="tw-flex tw-items-center tw-justify-center tw-min-h-[160px] tw-max-h-[160px] tw-overflow-hidden tw-rounded-lg tw-relative tw-bg-slate-200"
                    >
                      <v-icon
                        @click="onOpenVideo(file)"
                        v-if="file.fileType === 'video/mp4'"
                        icon="mdi-play-circle"
                        color="#264FD5"
                        class="tw-absolute tw-right-[45%] tw-top-[45%] tw-z-50 tw-cursor-pointer"
                      ></v-icon>

                      <img
                        v-if="
                          file.attachmentSource && file.fileType === 'video/mp4'
                        "
                        class="tw-cursor-pointer"
                        :src="require(`@/assets/images/iknowa-thumbnail.png`)"
                      />
                      <v-img
                        v-else-if="
                          file.attachmentSource && file.fileType !== 'video/mp4'
                        "
                        class="tw-w-full tw-h-full tw-cursor-pointer"
                        :class="{
                          'tw-hidden':
                            file?.fileType !== 'image/jpeg' &&
                            file?.fileType !== 'image/png',
                        }"
                        aspect-ratio="16/9"
                        cover
                        :src="file?.attachmentSource"
                      ></v-img>
                      <v-img
                        v-else
                        class="tw-cursor-pointer"
                        :class="{
                          'tw-hidden':
                            file?.fileType !== 'image/jpeg' &&
                            file?.fileType !== 'image/png',
                        }"
                        :width="60"
                        :height="60"
                        :src="
                          require(`@/assets/images/project-placeholder.png`)
                        "
                      ></v-img>
                    </div>
                  </div>
                </template>
              </div>
              <div
                v-if="!fileList?.length && !state.galleryIsLoading"
                class="tw-w-full tw-h-full tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4 tw-pb-4"
              >
                <div class="card__empty_img tw-w-[100px] tw-h-[100px]">
                  <img
                    :src="require('@/assets/images/gallery-empty.png')"
                    alt="icon"
                    class="tw-w-full tw-h-full tw-object-contain"
                  />
                </div>
                <Text
                  variants="p"
                  textWeight="400"
                  textColor="rgba(12, 15, 74, 0.5)"
                >
                  {{
                    isUserProprtyOwner
                      ? "photos will appear here"
                      : "Your photos will appear here"
                  }}
                </Text>
                <Button
                  v-if="getUserWorkStationFieldAccess && !isUserProprtyOwner"
                  variant="accent"
                  label="Upload Photos"
                  class="!tw-w-[auto]"
                  @click="onClickManageGallery"
                />
              </div>
              <div
                v-if="state.galleryIsLoading"
                class="tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div
                v-if="userDescriptionData"
                class="tw-border-0 tw-border-[#C5D2F2] tw-border-t tw-border-dashed tw-pt-4 tw-box-border tw-w-full tw-flex tw-items-center tw-justify-end tw-mt-[auto]"
              >
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer"
                  @click="onClickManageGallery"
                >
                  <Text variant="p" textColor="#FFA500">{{
                    userDescriptionData ? "Manage Gallery" : "Upload Photos"
                  }}</Text>
                  <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>
    </div>
  </div>
  <v-alert
    v-if="state.alert"
    class="tw-absolute tw-bottom-[1rem] tw-right-0"
    transition="slide-y-transition"
    :title="state.alertConfig.title"
    :text="state.alertConfig.text"
    :type="state.alertType === 'success' ? state.alertType : 'error'"
  >
  </v-alert>

  <TradePassportCertification v-if="activeTab === 'Certifications'" />
  <TradePassportInsurance v-if="activeTab === 'Insurances'" />
  <TradePassportSpecialism
    v-if="activeTab === 'Services'"
    class="tw-relative"
  />
  <TradePassportGallery
    @onChangeGallery="onChangeGallery"
    v-if="activeTab === 'Gallery'"
  />
  <TradePassportJobs v-if="activeTab === 'Jobs'" />
  <TradePassportReviews v-if="activeTab === 'Reviews'" />
  <!-- <CertificationModal
    v-if="openedModel === modelName.CERTIFICATION"
    :userCertificateData="userCertificateData"
    @onClickCloseTab="onClickCloseModel"
  /> -->
  <CertificateEditModal
    v-if="openedModel === modelName.CERTIFICATION"
    @onSaveData="onClickUpdateCertificate"
    @onClickCloseTab="onClickCloseModel"
    :userCertificateData="userCertificateData"
  />
  <!-- <InsuranceModel
    v-if="openedModel === modelName.INSURANCE"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditInsurance="isOuterEditInsurance"
    :userInsuranceData="userInsuranceData"
  /> -->

  <AddInsuranceModal
    v-if="openedModel === modelName.INSURANCE"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditInsurance="isOuterEditInsurance"
    :userInsuranceData="userInsuranceData"
  />
  <DescriptionEditorComponent
    v-if="openedModel === modelName.DESCRIPTION"
    @onClickCloseTab="onClickCloseModel"
    :isOuterEditDescription="isOuterEditDescription"
    :userDescriptionData="userDescriptionData"
    @userDescriptionData="userDescription"
    :HeaderTittle="HeaderTittle"
  />
  <TaxAndVatModel
    v-if="openedModel === modelName.TAXANDVAT"
    @onClickCloseTab="onClickCloseModel"
    mode="Edit"
  />
  <EditWorkStationDetailsPopup
    :postCode="
      workstationPostcode(
        activeUserWorkstation?.user?.userBusinessDetails?.type
      )
    "
    :workstationAddressValue="
      workstationAddress(
        activeUserWorkstation?.user?.userBusinessDetails?.type
      ) !== 'null null'
        ? workstationAddress(
            activeUserWorkstation?.user?.userBusinessDetails?.type
          ) || undefined
        : undefined
    "
    v-if="isShowEditWorkStationModal"
    @closeEditWorkStationModal="closeEditWorkStationModal"
  />
  <AddUserRatesModal
    v-if="isShowUserRateModal"
    @closeUpdateUserRatesModal="closeUpdateUserRatesModal"
  />
  <AddUserExperienceModal
    v-if="isShowUserExperienceModal"
    @closeUpdateUserExperienceModal="closeUpdateUserExperienceModal"
  />
  <TeamMemberModal
    v-if="openedModel === modelName.TEAMMEMBERS"
    @onClickCloseTab="onClickCloseModel"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    :type="confirmDeleteType"
    :label="confirmDeleteLabel"
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
  ></ConfirmDeleteModal>
  <ImportWorkStationModal
    v-if="isShowImportWorkStation"
    @onCloseImportWorkstationModal="onCloseImportWorkstationModal"
  />
  <SelectTeamMemberModal
    v-if="isShowSelectTeamMember"
    @onCloseModal="onCloseSelectTeam"
    @onClickContinue="onClickContinueSelectTeamMember"
  />
  <AssignModal
    v-if="isShowAssignModal"
    @onCloseModal="onCloseAssignModal"
    @onContinueButtonClick="onContinueButtonClick"
  />
  <ConfirmAssignModal
    v-if="isShowConfirmAssignModal"
    @onCloseConfirmModal="onCloseConfirmAssignModal"
    @onclickNo="onClickNoConfirmAssignModal"
  />

  <DocumentDeleteConfirmationModal
    v-if="modals.deleteConfirmation"
    @on-close="onCloseDeleteConfirmationModal"
    :selectedDocument="state.selectedDocument"
    deleteType="files"
    :isGallery="true"
    @on-success="onSuccess('delete')"
    @on-success-gallery="
      getTradePassportGallery($event, true, state.selectedDocument?.id)
    "
  />

  <GalleryPhotoPreview
    v-if="modals.photoPreview"
    :file="state.selectedDocument"
    @on-close="onClosePhotoPreviewModal"
  />

  <FreezeInsuranceExpired
    v-if="
      userInsurenceExperiedStatus &&
      isShowFreezeModal &&
      isWorkStationVerified &&
      !isUserProprtyOwner
    "
    @on-close="onCloseFreezeModal"
  />
  <AddBusinessAddressPreviewModal
    v-if="openedModel === modelName.ADDBUSINESSADDRESSMODAL"
    @onClickCloseTab="onClickCloseModel"
    @onClickCloseSuccessTab="onClickCloseSuccessModal"
  />

  <CompleteIDVerificationPreviewModal
    v-if="openedModel === modelName.VERIFYPREVIEWMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <TradePassportPreviewModal
    v-if="openedModel === modelName.TRADEPASSPORTPREVIEWMODAL"
    @onClickCloseTab="onClickCloseModel"
  />

  <WorkstationForVerificationPreviewModal
    v-if="openedModel === modelName.WORKSTATIONVERIFYPREVIEWMODAL"
    @onClickCloseTab="onClickCloseWsVerificationPreviewModel"
  />

  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :onClickCloseTab="onClickCloseModel"
  />
</template>

<script lang="ts">
import {
  PaymentPlanType,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { useRoute, useRouter } from "vue-router";
import { USER_STORE } from "@/store/modules/user";
import UserService from "@/core/services/user.service";
import { WORKSTATION } from "@/store/modules/workstation";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import { onMounted, watch, computed, ref, reactive, onUnmounted } from "vue";
import { useStore } from "vuex";
import TradePassportCertification from "../components/TradePassportCertification.vue";
import TradePassportInsurance from "../components/TradePassportInsurance.vue";
import TradePassportSpecialism from "../components/TradePassportSpecialism.vue";
import TradePassportJobs from "../components/TradePassportJobs.vue";
import TradePassportReviews from "../components/TradePassportReviews.vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
// import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
// import ItemListCard from "@/core/components/ItemListCard.vue";
import DescriptionEditorComponent from "@/modules/dashboard/components/DescriptionEditorComponent.vue";
import { COURSE_ROUTE } from "@/modules/courses/routes";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import TaxAndVatModel from "@/modules/tax-and-vat/components/TaxAndVatModel.vue";
import {
  getImageStringToImageURL,
  isExpiredDate,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import EditWorkStationDetailsPopup from "@/modules/trade-passport/components/EditWorkStationDetailsPopup.vue";
import AddUserRatesModal from "@/modules/trade-passport/components/AddUserRatesModal.vue";
// import CertificationModal from "@/modules/certification/components/CertificationModel.vue";
import AddUserExperienceModal from "@/modules/trade-passport/components/AddUserExperienceModal.vue";
import TeamMemberModal from "@/modules/trade-passport/components/TeamMemberModal.vue";
// import SpecialismItem from "@/core/components/common/SpecialismItem.vue";
import SpecialismItemTwo from "@/core/components/common/SpecialismItemTwo.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { MEMBER_STORE } from "@/store/modules/manage-member";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import ImportWorkStationModal from "@/modules/trade-passport/components/ImportWorkstationModal.vue";
import SelectTeamMemberModal from "@/modules/trade-passport/components/SelectTeamMemberModal.vue";
import AssignModal from "@/modules/trade-passport/components/AssignModal.vue";
import ConfirmAssignModal from "@/modules/trade-passport/components/ConfirmAssignModal.vue";
import Card from "@/core/components/ui/general/Card.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CheckGreenShield from "@/core/components/icons/CheckGreenShield.vue";
import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import CurrencyFilter from "@/filters/currency.filter";
import GreyItemCard from "@/modules/trade-passport/components/cards/GreyItemCard.vue";
import PencilCircularBlueIcon from "@/core/components/icons/PencilCircularBlueIcon.vue";
import TrashCircularGrayIcon from "@/core/components/icons/TrashCircularGrayIcon.vue";
import ServiceIconWrapper from "@/core/components/common/ServiceIconWrapper.vue";
import PdfIconGray from "@/core/components/icons/PdfIconGray.vue";
import TradePassportGallery from "@/modules/trade-passport/components/TradePassportGallery.vue";
import DocumentDeleteConfirmationModal from "@/modules/documents/components/modal/DocumentDeleteConfirmationModal.vue";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import TrashCircularWhiteIcon from "@/core/components/icons/TrashCircularWhiteIcon.vue";
import $axios from "@/core/utils/axios-api-config";
import { getImageApiUrl } from "@/core/utils/common";
import GalleryPhotoPreview from "@/modules/trade-passport/components/modals/GalleryPhotoPreview.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { UserRolesEnum } from "@/core/enums/RolesEnum";
import ToolTip from "@/core/components/common/ToolTip.vue";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import BackButton from "@/core/components/common/BackButton.vue";
import insurancesService from "@/core/services/insurances.service";
import FreezeInsuranceExpired from "@/modules/trade-passport/components/modals/FreezeInsuranceExpired.vue";
import Loader from "@/core/components/common/Loader.vue";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import NoticeIcon from "@/core/components/icons/NoticeIcon.vue";
import { reviewRatingState } from "@/core/models/project-rating";
import { getCombineSameProjectReview } from "@/core/utils/common";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import WorkStationLinkTreeWidget from "@/modules/dashboard/components/Widgets/WorkStationLinkTreeWidget.vue";
import AddInsuranceModal from "@/modules/insurance/components/AddInsuranceModal.vue";
import CertificateEditModal from "@/modules/certification/components/CertificateEditModal.vue";
import WorkstationForVerificationPreviewModal from "@/modules/dashboard/components/Modals/WorkstationForVerificationPreviewModal.vue";
import TradePassportPreviewModal from "@/modules/dashboard/components/Modals/TradePassportPreviewModal.vue";
import CompleteIDVerificationPreviewModal from "@/modules/dashboard/components/Modals/CompleteIDVerificationPreviewModal.vue";
import AddBusinessAddressPreviewModal from "@/modules/dashboard/components/Modals/AddBusinessAddressPreviewModal.vue";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
export default {
  components: {
    // DashBoardWelcome,
    TradePassportCertification,
    TradePassportInsurance,
    TradePassportSpecialism,
    // InsuranceModel,
    // ItemListCard,
    DescriptionEditorComponent,
    UserProfileLogo,
    TaxAndVatModel,
    EditWorkStationDetailsPopup,
    AddUserRatesModal,
    // CertificationModal,
    AddUserExperienceModal,
    TeamMemberModal,
    // SpecialismItem,
    SpecialismItemTwo,
    Button,
    Text,
    NoticeIcon,
    // CommonChip,
    AcceptChip,
    RejectChip,
    PendingChip,
    ProfileThumbnail,
    ConfirmDeleteModal,
    ImportWorkStationModal,
    SelectTeamMemberModal,
    AssignModal,
    ConfirmAssignModal,
    Card,
    ChipState,
    CheckGreenShield,
    ErrorRedIcon,
    Postcode,
    GreyItemCard,
    TrashCircularGrayIcon,
    PencilCircularBlueIcon,
    ServiceIconWrapper,
    PdfIconGray,
    TradePassportGallery,
    DocumentDeleteConfirmationModal,
    TrashCircularWhiteIcon,
    GalleryPhotoPreview,
    ToolTip,
    BackButton,
    TradePassportJobs,
    TradePassportReviews,
    FreezeInsuranceExpired,
    Loader,
    WorkStationLinkTreeWidget,
    AddInsuranceModal,
    CertificateEditModal,
    //dashboard verification and setup modals
    WorkstationForVerificationPreviewModal,
    TradePassportPreviewModal,
    CompleteIDVerificationPreviewModal,
    AddBusinessAddressPreviewModal,
    WorkstationVerificationModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const activeTab = ref("Overview");
    console.log("activeTab");
    const openedModel = ref("");
    const modelName = {
      INSURANCE: "insurance",
      DESCRIPTION: "description",
      TAXANDVAT: "tax&vat",
      CERTIFICATION: "certification",
      TEAMMEMBERS: "TeamMember",
      ADDBUSINESSADDRESSMODAL: "addBusinessAddress",
      VERIFYPREVIEWMODAL: "verifyPreviewModal",
      TRADEPASSPORTPREVIEWMODAL: "tradePassportPreview",
      WORKSTATIONVERIFYPREVIEWMODAL: "workstationVerifyPreview",
      VERIFYMODAL: "verifyModal",
    };
    const initializing = ref(false);
    const isShowWorkStatonLinkTreeStatus = ref(false);
    const insuranceLoading = ref(false);
    const specialismsLoading = ref(false);
    const certificateLoading = ref(false);
    const isOuterEditInsurance = ref(false);
    const userInsuranceData = ref(null) as any;
    const isOuterEditDescription = ref(false);
    const userDescriptionData = ref(null) as any;
    const HeaderTittle = ref("");
    const teamMemberList = ref();
    const modifiedMembers = ref([]) as any;
    const loadingImages = ref(false);

    const isShowAssignModal = ref(false);

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteLabel = ref("");
    const confirmDeleteType = ref("");
    const confirmDeleteId = ref(null) as any;
    const isAvailable = ref();
    const isLoading = ref(false);
    const confirmDeleteCertificateData = ref({
      type: "certificate",
      label: "You're about to delete your certification.",
    });
    const confirmDeleteInsuranceData = ref({
      type: "insurance",
      label: "You're about to delete your insurance.",
    });

    const isShowUserRateModal = ref(false);
    const isShowFreezeModal = ref(true);
    const isShowUserExperienceModal = ref(false);

    const userCertificateData = ref(null) as any;

    const nationalInsuranceNumber = ref("");
    const isShowPassword = ref(false);

    const isShowImportWorkStation = ref(false);

    const isShowSelectTeamMember = ref(false);

    const isShowConfirmAssignModal = ref(false);

    const confirmDeleteAssignedMembers = ref(null) as any;

    const isSameWorkstaion = ref(false);

    const fileList = ref([]) as any;

    const tradeItems = ref([
      { name: "Overview", icon: "mdi-home" },
      { name: "Services", icon: "mdi-tools" },
      { name: "Certifications", icon: "mdi-file-table" },
      // { name: "Companies", icon: "mdi-text-box" },
      { name: "Insurances", icon: "mdi-shield-home" },
      { name: "Jobs", icon: "mdi-account-tie" },
      { name: "Reviews", icon: "mdi-star" },
      { name: "Gallery", icon: "mdi-image-multiple-outline" },
      // { name: "Recommendations", icon: "mdi-star-circle-outline" },
    ]);

    const preOpenTabs = ref([
      "Overview",
      "Services",
      "Certifications",
      "Insurances",
      "Gallery",
      "Jobs",
      "Reviews",
    ]);

    const modals = reactive({
      deleteConfirmation: false,
      photoPreview: false,
    });
    const state = reactive({
      selectedDocument: null as any,
      galleryIsLoading: false,

      alert: false,
      alertType: "",
      actionType: "",
      alertConfig: {
        text: "",
        title: "",
      },
    });
    const allUserConnection = ref([]);
    const shouldShowBanner = computed(() => {
      if (route.path === "/trade-passport") {
        return true;
      }

      if (
        route.path === "/trade-estimates/trade-passport" &&
        isShowWorkStatonLinkTreeStatus.value &&
        !isSameWorkstaion.value
      ) {
        return true;
      }

      return false;
    });

    const isShowWorkStationLinkTree = computed(() => {
      if (
        activeUserWorkstation.value.id ==
        contractorTradePassportWorkstationId.value
      ) {
        return false;
      }
      const isWorkstationIdPresent = allUserConnection.value.some(
        (connection: any) =>
          connection.receiverWorkstation?.id ===
          contractorTradePassportWorkstationId.value
      );
      return !isWorkstationIdPresent;
    });

    const userAllWorkStation = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkstations`]
    );
    const accountStatuses = computed(
      () => store.getters[`${USER_STORE}/accountStatuses`]
    );

    const userWorkStationStatusInsurance = computed(
      () =>
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus
    );

    const isWorkStationVerified = computed(() => {
      return (
        userWorkStationStatusInsurance.value ===
        UserVerificationStatusEnum.VERIFIED
      );
    });

    const getIsOpenTradePassportasViewMode = computed(
      () =>
        store.getters[`${TRADE_ESTIMATE_STORE}/isOpenTradePassportasViewMode`]
    );
    const isShowRates = computed(
      () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
    );

    const isUserProprtyOwner = computed(
      () =>
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER ||
        getIsOpenTradePassportasViewMode.value
    );

    const isShowEditWorkStationModal = ref(false);
    const contractorworkStationImage = ref("");
    const userWorkStationImage = computed(() => {
      if (!isUserProprtyOwner.value) {
        return store.getters[`${USER_STORE}/userWorkStationImage`];
      } else {
        return "";
      }
    });

    const activeUserWorkstation = computed(() => {
      if (isUserProprtyOwner.value) {
        return store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
        ];
      } else {
        return store.getters[`${WORKSTATION}/activeUserWorkstation`];
      }
    });
    const userInsurenceExperiedStatus = computed(
      () => store.getters[`${INSURANCE_STORE}/getIsInsuranceExpired`]
    );

    const userWorkStationStatus = computed(() => {
      if (isUserProprtyOwner.value) {
        return store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`
        ]?.verificationStatus;
      } else {
        return store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus;
      }
    });

    const isItemDisable = (itemName: string) => {
      if (preOpenTabs.value.includes(itemName)) {
        return false;
      } else if (
        userWorkStationStatus.value !== UserVerificationStatusEnum.VERIFIED
      ) {
        return true;
      } else {
        return false;
      }
    };
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );
    const userBusinessType = computed(
      () => user.value?.userBusinessDetails?.type
    );
    const isUserVerifiedKYCAndKYB = computed(() =>
      userBusinessType.value === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? WorkStationSiltVerificationStatus.value &&
          ["SUCCESS", "MANUAL_REVIEW"].includes(
            WorkStationSiltVerificationStatus.value?.status
          )
        : [
            UserVerificationStatusEnum.PENDING_VERIFICATION,
            UserVerificationStatusEnum.VERIFIED,
          ].includes(
            activeUserWorkstation.value?.user?.userKYCVerificationStatus
          )
    );
    const userCertificates = computed(
      () => store.getters[`${USER_STORE}/userCertificates`]
    );

    const userFullName = computed(() => {
      return `${user.value?.firstName} ${user.value?.lastName}`;
    });
    const userSpecialisms = computed(
      () => store.getters[`${USER_STORE}/userSpecialisms`]
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    const isUserWorkStationAdmin = computed(
      () => store.getters[`${WORKSTATION}/isUserWorkStationAdmin`]
    );
    const filterUserSpecialisms = computed(() => {
      const ids = [] as any;
      const categoryList = [] as any;
      for (const item of userSpecialisms.value || []) {
        const { category, subCategories } = item as any;
        const categoryId = category.id;
        const label = category.poLabel ? category.tpLabel : "";
        const iconName = category.iconName;

        if (!ids.includes(categoryId)) {
          ids.push(categoryId);
          categoryList.push({ id: categoryId, label, iconName, subCategories });
        } else {
          categoryList
            .find((value: any) => value.id === categoryId)
            ?.subCategories.push(...subCategories);
        }
      }

      return categoryList || [];
    });

    const hasData = computed(() => {
      if (
        (activeUserWorkstation?.value?.user?.userBusinessDetails
          ?.nationalInsuranceNumber !== null ||
          activeUserWorkstation?.value?.user?.userBusinessDetails
            ?.nationalInsuranceNumber !== "") &&
        activeUserWorkstation?.value?.user?.userBusinessDetails?.ukVatNumber !==
          null &&
        activeUserWorkstation?.value?.user?.userBusinessDetails?.ukVatNumber !==
          ""
      ) {
        return true;
      }
      return false;
    });

    watch(
      () => user,
      (newUserDetails, old) => {
        nationalInsuranceNumber.value =
          user?.value.userBusinessDetails?.nationalInsuranceNumber;
      },
      { deep: true, immediate: true }
    );

    const updateAvailableStatus = async () => {
      try {
        if (activeUserWorkstation.value?.id) {
          await UserService.updateUserWorkstation(
            user.value?.id,
            activeUserWorkstation.value?.id,
            {
              isAvailable: isAvailable.value,
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    watch(
      () => isAvailable.value,
      (newValue, old) => {
        if (!isUserProprtyOwner.value) {
          updateAvailableStatus();
        }
      },
      { deep: true, immediate: true }
    );

    const onCloseFreezeModal = () => {
      isShowFreezeModal.value = false;
    };
    const onOpenDeleteConfirmationModal = (document: any) => {
      state.selectedDocument = document;
      modals.deleteConfirmation = true;
    };

    const onCloseDeleteConfirmationModal = () => {
      state.selectedDocument = null;
      modals.deleteConfirmation = false;
    };

    const onOpenPhotoPreviewModal = (file: any) => {
      if (file?.fileType === "video/mp4") return;
      state.selectedDocument = file;
      modals.photoPreview = true;
    };

    const onClosePhotoPreviewModal = () => {
      state.selectedDocument = null;
      modals.photoPreview = false;
    };

    const onOpenVideo = (file: any) => {
      // Check if the file has an attachmentSource property
      if (file && file.attachmentSource) {
        // Open the video in a new tab
        window.open(file.attachmentSource, "_blank");
      } else {
        console.error("Invalid file or missing attachmentSource");
      }
    };

    const getTradePassportGallery = async (
      event = {} as any,
      isActionDelete = false,
      id = null as any
    ) => {
      state.galleryIsLoading = true;
      try {
        if (!isActionDelete) {
          const userId = user.value.id;
          if (isUserProprtyOwner.value) {
            fileList.value = await store.dispatch(
              `${DOCUMENTS_STORE}/getContractorTradePassportGallery`,
              {
                workStationId: contractorTradePassportWorkstationId.value,
                userId: user.value.id,
              }
            );
          } else {
            fileList.value = await store.dispatch(
              `${DOCUMENTS_STORE}/getTradePassportGallery`,
              userId
            );
          }

          fileList.value = fileList.value.map((file: any) => {
            return { ...file, name: file.originalName, attachment: file.name };
          });

          const attachmentPromises = fileList.value.map(
            async (currentFile: any) => {
              const { attachment } = currentFile;
              const imageUrl = setDirectStoragePathToImage(attachment);

              return { ...currentFile, attachmentSource: imageUrl };
            }
          );

          // Wait for all attachment promises to resolve
          fileList.value = await Promise.all(attachmentPromises);
        } else {
          if (fileList.value?.length) {
            fileList.value = fileList.value.filter(
              (file: any) => file.id !== id
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        state.galleryIsLoading = false;
      }
    };

    const onSuccess = async (event: any) => {
      state.alertType = "success";
      state.actionType = event;
      state.alert = true;
      state.alertConfig = {
        text:
          state.actionType === "edit"
            ? "Edited Successfully"
            : "Deleted Successfully",
        title: "Successful",
      };
    };

    const hideAlert = () => {
      setTimeout(() => {
        state.alert = false;
      }, 3000);
    };

    watch(
      () => state.alert,
      (newValue, oldValue) => {
        if (newValue) {
          hideAlert();
        }
      },
      { deep: true, immediate: true }
    );

    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
        if (userInsurances.value.length === 0 && isWorkStationVerified.value) {
          const responce: any = await insurancesService.isInsuranceExpired(
            user.value.id
          );

          store.commit(
            `${INSURANCE_STORE}/setInsuranceExpired`,
            responce.isFreezed
          );
        }
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const onEditInsurance = async (insurance: object) => {
      userInsuranceData.value = insurance;
      isOuterEditInsurance.value = true;
      openedModel.value = modelName.INSURANCE;
    };

    const onEditDescription = async (description: string) => {
      userDescriptionData.value = description;
      isOuterEditDescription.value = true;
      HeaderTittle.value = userDescriptionData.value ? "Edit" : "Add";
      openedModel.value = modelName.DESCRIPTION;
    };

    const onClickCloseModel = async () => {
      openedModel.value = "";
      getTeamMember();
    };

    const onClickUpdateCertificate = async () => {
      await store.getters[`${USER_STORE}/userCertificates`];
      onClickCloseModel();
      userCertificateData.value = null;
    };

    const userDescription = async (value: any) => {
      userDescriptionData.value = value;
      await store.dispatch(`${WORKSTATION}/activateWorkstationDescription`, {
        userId: user.value?.id,
        userWorkstationId: activeUserWorkstation.value?.id,
        description: userDescriptionData.value,
      });
    };

    const onClickManageSpecialism = () => {
      activeTab.value = "Services";
    };

    const onClickAddInsurance = () => {
      activeTab.value = "Insurances";
    };

    const onClickManageCertifications = () => {
      activeTab.value = "Certifications";
    };
    const onClickManageGallery = () => {
      activeTab.value = "Gallery";
    };

    watch(
      () => activeUserWorkstation.value,
      (newValue, oldValue) => {
        userDescriptionData.value = activeUserWorkstation.value?.description;
        isAvailable.value = activeUserWorkstation.value?.isAvailable;
      },
      { immediate: true }
    );

    const formatNumberWithCommas = (number: any) => {
      const numericValue =
        typeof number === "number" ? number : parseFloat(number);
      return numericValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const navigateToCourse = () => {
      router.push({ name: COURSE_ROUTE });
    };
    const onClickEditVatAndTax = () => {
      openedModel.value = modelName.TAXANDVAT;
    };

    const activeWorkStationType = (businessType: string) => {
      if (isUserProprtyOwner.value) {
        return activeUserWorkstation.value?.user?.userBusinessDetails?.type ===
          BusinessDetailTypeEnum.LIMITED_COMPANY
          ? "LTD Company"
          : "Sole Trader";
      }
      return businessType === BusinessDetailTypeEnum.LIMITED_COMPANY
        ? "LTD Company"
        : "Sole Trader";
    };

    const workstationCreatedDate = (date: any) => {
      const wsDate = new Date(date);

      const day = wsDate.getDate();
      const month = wsDate.toLocaleString("en-US", { month: "long" });
      const year = wsDate.getFullYear();

      return `${day} ${month} ${year}`;
    };

    const workstationAddress = (type: string) => {
      if (activeUserWorkstation.value?.user) {
        let address: string;
        if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
          const businessAddress =
            activeUserWorkstation.value?.user?.userBusinessDetails?.businessAddress?.replace(
              activeUserWorkstation.value?.user?.userBusinessDetails
                ?.businessPostcode,
              ""
            );
          address = businessAddress?.replace(/,([^,]*)$/, ".$1");
        } else if (
          activeUserWorkstation.value?.user?.userBusinessDetails
            ?.residencyAddressLookup
        ) {
          const residencyAddressLookup =
            activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLookup?.replace(
              activeUserWorkstation.value?.user?.userBusinessDetails
                ?.residencyPostcode,
              ""
            );
          address = residencyAddressLookup?.replace(/,([^,]*)$/, ".$1");
        } else {
          address = `${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyAddressLine} ${activeUserWorkstation.value?.user?.userBusinessDetails?.residencyTown}`;
        }
        return address;
      } else {
        return null;
      }
    };

    const workstationPostcode = (type: string) => {
      if (activeUserWorkstation.value?.user) {
        return type === BusinessDetailTypeEnum.LIMITED_COMPANY
          ? activeUserWorkstation.value?.user?.userBusinessDetails
              ?.businessPostcode
          : activeUserWorkstation.value?.user?.userBusinessDetails
              ?.residencyPostcode;
      } else {
        return null;
      }
    };

    const onClickEditWorkStation = () => {
      isShowEditWorkStationModal.value = true;
    };

    const closeEditWorkStationModal = async () => {
      isShowEditWorkStationModal.value = false;
      await store.dispatch(`${USER_STORE}/initializeUserState`);
    };

    const onClickUpdateUserRates = () => {
      isShowUserRateModal.value = true;
    };

    const closeUpdateUserRatesModal = () => {
      isShowUserRateModal.value = false;
    };

    const activeWorkStationSubscription = computed(
      () => activeUserWorkstation.value?.subscription
    );

    const getWorkStationPlanType = computed(() => {
      if (
        activeWorkStationSubscription.value?.metadata?.planType ==
        PaymentPlanType.MONTHLY
      ) {
        return "Monthly";
      } else if (
        activeWorkStationSubscription.value?.metadata?.planType ==
        PaymentPlanType.YEARLY
      ) {
        return "Yearly";
      } else {
        return "";
      }
    });

    const onDeleteItem = (
      assignedTo: any,
      id: any,
      type: string,
      label: string
    ) => {
      confirmDeleteId.value = id;
      confirmDeleteLabel.value = label;
      confirmDeleteType.value = type;
      isOpenConfirmDelete.value = true;
      confirmDeleteAssignedMembers.value = assignedTo.map(
        (member: any) => member.id
      );
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteId.value = null;
      confirmDeleteLabel.value = "";
      confirmDeleteType.value = "";
      isOpenConfirmDelete.value = false;
    };

    const deleteCertificate = async (certificateId: number, members: any) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificateId,
        });

        // Delete related Members
        let payload = {
          teamMemberIds: members,
        };

        if (members?.length) {
          await store.dispatch(
            `${USER_STORE}/removeCertificateRelatedMembers`,
            {
              userId: user?.value?.id,
              teamMemberIds: payload,
            }
          );
        }

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async (e: any) => {
      if (e === confirmDeleteCertificateData.value.type) {
        await deleteCertificate(
          confirmDeleteId.value,
          confirmDeleteAssignedMembers.value
        );
        await onCloseConfirmDelete();
      }
      if (e === confirmDeleteInsuranceData.value.type) {
        await onDeleteInsurance(confirmDeleteId.value);
        await onCloseConfirmDelete();
      }
    };

    const onClickManageTeam = () => {
      openedModel.value = modelName.TEAMMEMBERS;
    };

    const onEditCertificate = (certificate: any) => {
      openedModel.value = modelName.CERTIFICATION;
      userCertificateData.value = certificate;
    };

    const subscriptionType = (type: any) => {
      let plan = "";
      if (type) {
        if (type.subscription) {
          const subscription = JSON.parse(type?.subscription);
          const planType = subscription.metadata.planType;

          if (planType === PaymentPlanType.MONTHLY.toString()) {
            plan = "Monthly";
          }
          if (planType === PaymentPlanType.YEARLY.toString()) {
            plan = "Yearly";
          }
        }
      }

      return plan;
    };

    const onClickUpdateUserExperience = () => {
      isShowUserExperienceModal.value = true;
    };
    const closeUpdateUserExperienceModal = () => {
      isShowUserExperienceModal.value = false;
    };

    const getTeamMember = async () => {
      try {
        const projectDataResponse = await store.dispatch(
          `${MEMBER_STORE}/getTeamMember`,
          {
            userId: user.value?.id,
          }
        );
        teamMemberList.value = projectDataResponse;
      } catch (err) {
        console.log();
      }
    };

    const getWorkstationProfile = async (profileImage: string) => {
      if (!profileImage) {
        return null;
      }
      try {
        const imagePath = profileImage;
        const response = await store.dispatch(
          `${USER_STORE}/getImage`,
          imagePath
        );
        if (response) {
          return response;
        }
        return null;
      } catch (error) {
        return null;
      }
    };

    const modifyMembersList = async () => {
      loadingImages.value = true;
      if (!teamMemberList.value && !teamMemberList.value?.length) {
        loadingImages.value = false;
        return;
      }
      try {
        const defaultMember = teamMemberList.value[0];
        const { profileImage } = defaultMember.userWorkstation;

        const source = await getWorkstationProfile(profileImage);

        teamMemberList.value.forEach((member: any, index: number) => {
          if (index === 0) {
            modifiedMembers.value.push({
              ...member,
              attachmentSource: source,
            });
          } else {
            modifiedMembers.value.push({
              ...member,
            });
          }
        });
      } catch (error) {
        loadingImages.value = false;
      } finally {
        loadingImages.value = false;
      }
    };

    const formatSum = (sum: any) => {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(sum);
    };

    const importWorkstationData = () => {
      isShowImportWorkStation.value = true;
    };

    const contractorTradePassportWorkstationId = computed(
      () =>
        store.getters[
          `${TRADE_ESTIMATE_STORE}/getContractorTradePassportWorkstationId`
        ]
    );

    const getInitialData = async () => {
      try {
        isLoading.value = true;
        if (isUserProprtyOwner.value) {
          if (user.value && contractorTradePassportWorkstationId.value) {
            await store.dispatch(
              `${TRADE_ESTIMATE_STORE}/getContractorTradePassportDetail`,
              {
                workStationId: contractorTradePassportWorkstationId.value,
                userId: user.value.id,
              }
            );
            await store
              .dispatch(`${USER_STORE}/setContractorInsurances`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                insuranceLoading.value = false;
              });

            await store
              .dispatch(`${USER_STORE}/getContractorSkills`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                specialismsLoading.value = false;
              });

            await store
              .dispatch(`${USER_STORE}/setContractorCertificates`, {
                userId: user.value.id,
                workStationId: contractorTradePassportWorkstationId.value,
              })
              .then(() => {
                certificateLoading.value = false;
              });

            contractorworkStationImage.value = await getImageStringToImageURL(
              activeUserWorkstation.value?.profileImage
            );

            // nationalInsuranceNumber.value =
            //   user?.value.userBusinessDetails?.nationalInsuranceNumber;
            await getTradePassportGallery();
          } else {
            router.go(-1);
          }
        } else {
          // initializing.value = true;
          // certificateLoading.value = true;
          // specialismsLoading.value = true;
          // insuranceLoading.value = true;
          state.galleryIsLoading = true;
          if (user.value) {
            await getTeamMember();
            await modifyMembersList();
            if (!userInsurances.value?.length) {
              await store
                .dispatch(`${USER_STORE}/setUserInsurances`, user.value?.id)
                .then(() => {
                  insuranceLoading.value = false;
                });
            }
            if (!userSpecialisms.value?.length) {
              await store
                .dispatch(`${USER_STORE}/getUserSkills`, user.value.id)
                .then(() => {
                  specialismsLoading.value = false;
                });
            }
            if (!userCertificates.value?.length) {
              await store
                .dispatch(`${USER_STORE}/setUserCertificates`, user.value?.id)
                .then(() => {
                  certificateLoading.value = false;
                });
            }
            nationalInsuranceNumber.value =
              user?.value.userBusinessDetails?.nationalInsuranceNumber;
            await getTradePassportGallery();
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
        initializing.value = false;
      }
    };

    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const onCloseImportWorkstationModal = () => {
      isShowImportWorkStation.value = false;
    };

    const onClickAssignData = () => {
      isShowSelectTeamMember.value = true;
    };

    const onCloseSelectTeam = () => {
      isShowSelectTeamMember.value = false;
    };

    const onClickContinueSelectTeamMember = () => {
      isShowSelectTeamMember.value = false;
      isShowAssignModal.value = true;
    };

    const onCloseAssignModal = () => {
      isShowSelectTeamMember.value = true;
      isShowAssignModal.value = false;
    };

    const onContinueButtonClick = () => {
      isShowAssignModal.value = false;
      isShowConfirmAssignModal.value = true;
    };

    const onCloseConfirmAssignModal = () => {
      isShowConfirmAssignModal.value = false;
    };

    const onClickNoConfirmAssignModal = () => {
      isShowAssignModal.value = true;
      isShowConfirmAssignModal.value = false;
    };

    const onClickBackEstimateButton = () => {
      router.go(-1);
    };
    const onChangeGallery = async () => {
      await getTradePassportGallery();
    };
    const WorkStationSiltVerificationStatus = computed(
      () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
    );
    const getVerificationStatus = computed(() => {
      const statusString =
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
          ? "KYB"
          : "KYC";

      const verificationStatus: any = {
        ERROR: {
          label: `${statusString} Not Verified`,
          icon: "mdi-information",
          statusClass: "info",
        },
        PENDING: {
          label: `${statusString} Verification Pending`,
          icon: "mdi-clock-time-eight",
          statusClass: "pending",
        },
        SUCCESS: {
          label: `${statusString} Verified`,
          icon: "mdi-check-circle",
          statusClass: "success",
        },
      };
      if (WorkStationSiltVerificationStatus.value) {
        const status = WorkStationSiltVerificationStatus?.value?.status;
        return verificationStatus[status] || verificationStatus.ERROR;
      } else {
        return verificationStatus.ERROR;
      }
    });

    const isUserProfileVerified = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );
    const isSubmitWsForVerification = computed(
      () => store.getters[`${WORKSTATION}/getIsSubmitWsForVerification`]
    );
    const isWsDirectlySubmittedForVerification = computed(
      () =>
        store.getters[`${WORKSTATION}/getIsWsDirectlySubmittedForVerification`]
    );
    const isUserAddBusinessAddress = computed(() => {
      if (
        user.value?.userBusinessDetails?.type ===
        BusinessDetailTypeEnum.LIMITED_COMPANY
      ) {
        return user.value.userBusinessDetails.businessPostcode;
      } else {
        return user.value.userBusinessDetails.residencyPostcode;
      }
    });

    const getAllProjectReviewList = computed<
      reviewRatingState["allProjectReviewList"]
    >(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

    const combineSameProjectReview = computed(() =>
      getCombineSameProjectReview(getAllProjectReviewList.value)
    );
    const getAverageOfAllProjectReview = computed(() => {
      const totalAvg = combineSameProjectReview.value.reduce(
        (totalRating: number, review: any) => {
          totalRating += review.totalAvg || 0;
          return totalRating;
        },
        0
      );
      return totalAvg
        ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) /
            100
        : totalAvg;
    });

    const onClickViewRatings = () => {
      store.dispatch(
        `${REVIEW_RATING_STORE}/setTradePassportRedirect`,
        "Reviews"
      );
      router.push({ name: TRADE_PASSPOSRT_ROUTE });
    };

    const onClickSubmitVerification = () => {
      openedModel.value = modelName.WORKSTATIONVERIFYPREVIEWMODAL;
    };

    const onClickCloseWsVerificationPreviewModel = () => {
      onClickCloseModel();
      if (
        isSubmitWsForVerification.value ||
        isWsDirectlySubmittedForVerification.value
      ) {
        store.commit(
          `${WORKSTATION}/setIsWsDirectlySubmittedForVerification`,
          false
        );
        router.push({ name: DASHBOARD_ROUTE });
      }
    };
    const onTabChange = async (val: any) => {
      if (
        val == "Overview" &&
        userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED
      ) {
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      }
    };

    const onClickAddBusinessAddressModal = () => {
      if (!isUserAddBusinessAddress.value) {
        openedModel.value = modelName.ADDBUSINESSADDRESSMODAL;
      }
    };

    const onClickIDVerificationModal = () => {
      if (isUserVerifiedKYCAndKYB.value) return;
      openedModel.value = modelName.VERIFYMODAL;
    };

    const onClickManageTradePassportPreviewModal = () => {
      if (
        userWorkStationStatus.value ===
        UserVerificationStatusEnum.PENDING_VERIFICATION
      ) {
        console.log("Workstation Locked");
      } else {
        openedModel.value = modelName.TRADEPASSPORTPREVIEWMODAL;
      }
    };

    const onClickSubmitVerificationPreviewModal = () => {
      if (
        isUserProfileVerified.value &&
        activeUserWorkstation.value?.verificationStatus ===
          UserVerificationStatusEnum.NOT_ADDED &&
        isUserVerifiedKYCAndKYB.value
      ) {
        openedModel.value = modelName.WORKSTATIONVERIFYPREVIEWMODAL;
      }
    };
    const onClickCloseSuccessModal = async () => {
      openedModel.value = "";
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    };
    onMounted(async () => {
      try {
        allUserConnection.value =
          store.getters[`${TRADE_NETWORK}/getUserNetworkConnectedList`];

        await store.dispatch(
          `${REVIEW_RATING_STORE}/resetAllProjectReviewList`
        );
        const activeTabState =
          store.getters[`${REVIEW_RATING_STORE}/getTradePassportRedirect`];
        if (activeTabState) {
          activeTab.value = activeTabState;
          store.dispatch(`${REVIEW_RATING_STORE}/resetTradePassportRedirect`);
        }
        await getInitialData();
        const res = await store.dispatch(
          `${WORKSTATION}/getWorkStationLinkTreeBannerStatus`,
          {
            workStationId: activeUserWorkstation?.value?.id,
          }
        );
        isShowWorkStatonLinkTreeStatus.value = res?.status;
        isSameWorkstaion.value = res?.isSame;

        if (
          userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED
        ) {
          await store.dispatch(`${USER_STORE}/getAccountStatuses`);
        }
      } catch (error) {
        console.error("Eroro While Loading Data", error);
      }
    });

    onUnmounted(async () => {
      store.dispatch(`${TRADE_ESTIMATE_STORE}/resetTradeUserAccount`);
    });
    return {
      UserVerificationStatusEnum,
      userWorkStationStatus,
      tradeItems,
      isItemDisable,
      activeTab,
      filterUserSpecialisms,
      activeUserWorkstation,
      user,
      BusinessDetailTypeEnum,
      userInsurances,
      onDeleteInsurance,
      onEditInsurance,
      openedModel,
      isOuterEditInsurance,
      userInsuranceData,
      modelName,
      onClickCloseModel,
      onEditDescription,
      userDescriptionData,
      isOuterEditDescription,
      HeaderTittle,
      userDescription,
      userFullName,
      userWorkStationImage,
      onClickManageSpecialism,
      onClickAddInsurance,
      onClickManageCertifications,
      userCertificates,
      navigateToCourse,
      onClickEditVatAndTax,
      isExpiredDate,
      activeWorkStationType,
      workstationCreatedDate,
      workstationAddress,
      workstationPostcode,
      isShowEditWorkStationModal,
      onClickEditWorkStation,
      closeEditWorkStationModal,
      onClickUpdateUserRates,
      isShowUserRateModal,
      closeUpdateUserRatesModal,
      activeWorkStationSubscription,
      getWorkStationPlanType,
      userCertificateData,
      onEditCertificate,
      deleteCertificate,
      isShowUserExperienceModal,
      closeUpdateUserExperienceModal,
      onClickUpdateUserExperience,
      subscriptionType,
      onClickManageTeam,
      teamMemberList,
      chipLabel,
      chipColor,
      hasData,
      isShowPassword,
      nationalInsuranceNumber,
      modifiedMembers,
      loadingImages,
      formatSum,
      isOpenConfirmDelete,
      onDeleteItem,
      confirmDeleteInsuranceData,
      confirmDeleteCertificateData,
      confirmDeleteType,
      confirmDeleteLabel,
      onCloseConfirmDelete,
      deleteItem,
      importWorkstationData,
      isShowImportWorkStation,
      onCloseImportWorkstationModal,
      isAvailable,
      onClickAssignData,
      isShowSelectTeamMember,
      onCloseSelectTeam,
      onClickContinueSelectTeamMember,
      isShowAssignModal,
      onCloseAssignModal,
      onContinueButtonClick,
      isShowConfirmAssignModal,
      onCloseConfirmAssignModal,
      onClickNoConfirmAssignModal,
      userAllWorkStation,
      getUserWorkStationFieldAccess,
      isUserWorkStationAdmin,
      formatNumberWithCommas,
      formatDate,
      CurrencyFilter,
      onClickManageGallery,
      state,
      modals,
      onCloseDeleteConfirmationModal,
      onOpenDeleteConfirmationModal,
      fileList,
      initializing,
      certificateLoading,
      specialismsLoading,
      insuranceLoading,
      onSuccess,
      getTradePassportGallery,
      onClosePhotoPreviewModal,
      onOpenPhotoPreviewModal,
      onOpenVideo,
      contractorworkStationImage,
      isUserProprtyOwner,
      getIsOpenTradePassportasViewMode,
      onClickBackEstimateButton,
      onChangeGallery,
      userInsurenceExperiedStatus,
      isShowFreezeModal,
      onCloseFreezeModal,
      isWorkStationVerified,
      isLoading,
      isUserProfileVerified,
      isUserAddBusinessAddress,
      onClickSubmitVerification,
      onTabChange,
      getAverageOfAllProjectReview,
      onClickViewRatings,
      isShowRates,
      onClickUpdateCertificate,
      WorkStationSiltVerificationStatus,
      getVerificationStatus,
      accountStatuses,
      userBusinessType,
      isUserVerifiedKYCAndKYB,
      onClickAddBusinessAddressModal,
      onClickIDVerificationModal,
      onClickManageTradePassportPreviewModal,
      onClickSubmitVerificationPreviewModal,
      onClickCloseSuccessModal,
      onClickCloseWsVerificationPreviewModel,
      allUserConnection,
      isShowWorkStationLinkTree,
      isShowWorkStatonLinkTreeStatus,
      shouldShowBanner,
      isSameWorkstaion,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";

.gallery-content-container {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.back-btn {
  margin-right: auto;
}

.card-services {
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(120px, 1fr));
}

.view-rating-box {
  padding: 12px 16px;
  background-color: #f3f3f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  flex-wrap: wrap;

  .v-rating {
    :deep(.v-rating__wrapper) {
      .v-rating__item {
        .v-btn {
          width: auto;
          height: auto;
        }
      }
    }
  }

  p {
    @include fluidFont(16, 16, 1.2);
    font-weight: 700;
    letter-spacing: 0.12px;
    color: rgba($blueDark, 1);
  }

  .v-btn {
    padding: 5px 8px;
    min-height: auto;
    display: flex;
    text-transform: capitalize;
    height: auto;
    margin: 0;
  }
}
</style>
