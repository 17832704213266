<template>
  <div class="tooltip__wrapper">
    <el-tooltip :effect="effect" :placement="direction">
      <template #content
        ><div class="tw-max-w-[200px]" v-html="description"></div
      ></template>
      <v-icon :icon="icon" :color="iconColor" />
    </el-tooltip>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useDisplay } from "vuetify";

const props = defineProps({
  description: {
    type: String,
    required: true,
  },
  direction: {
    type: String,
    default: "top",
  },
  effect: {
    type: String,
    default: "dark",
  },
  maxWidth: {
    type: Number,
    default: 160,
  },
  icon: {
    type: String,
    default: "mdi-information-variant-circle-outline",
  },
  iconColor: {
    type: String,
    default: "",
  },
});
const vuetify = useDisplay();
const isMobileView = computed(() => vuetify.smAndDown.value);
</script>

<style lang="scss">
:deep(.el-popper.is-dark) {
  z-index: 9999;
  background: rgba($blueDark, 1) !important;
}
.tooltip__wrapper {
  height: 16px;
  .v-icon {
    @include fluidFont(16, 16, 1);
    cursor: pointer;
    vertical-align: super;
  }
}
</style>
