import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["profile-logo", {
      '!tw-rounded-lg': !$props.isRounded,
      'tw-rounded-[50%]': $props.isRounded,
      'rlg:!tw-text-[16px]': $props.desktopFontSizeMid,
      'rlg:!tw-text-[24px]': $props.desktopFontSizeBig,
      'rlg:!tw-text-[10px]': !$props.desktopFontSizeBig,
      'md:!tw-text-[16px]': $props.mobileFontSizeBig,
      'md:!tw-text-[10px]': !$props.mobileFontSizeBig,
    }]),
    style: _normalizeStyle({ backgroundColor: $setup.logoBackgroundColor })
  }, _toDisplayString($setup.userNameLogoChar), 7))
}