<template>
  <div class="service__widget">
    <AnalyticsWidgetLayout
      class="service__card"
      title="Revenue by Service"
      :hideToolTip="true"
      headerIcon="revenueService"
      :borderBottom="true"
    >
      <template #content>
        <div class="service__wrapper">
          <div class="left__block">
            <div
              v-if="!revenueandJobServiceData.totalRevenue"
              class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center tw-rounded-[16px] tw-overflow-hidden tw-min-h-[350px] tw-bg-white"
            >
              <img src="@/assets/images/analytics-empty-icon.png" />
              <Text
                textWeight="500"
                variant="p"
                textColor="rgba(12, 15, 74, 0.5)"
                lineHeight="18px"
                >Revenue by service will show here<br />
                when you complete jobs.</Text
              >
            </div>
            <div class="service__list" v-else>
              <ul>
                <li
                  v-for="serviceName in Object.keys(
                    revenueandJobServiceData.serviceRevenue
                  )"
                  :key="serviceName"
                >
                  <div class="inner__left__block">
                    <!-- <div class="service__icon">
                      <img src="../../../assets/icons/gas.svg" alt="" />
                    </div> -->
                    <Text
                      variant="p"
                      textWeight="600"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >{{ serviceName }}</Text
                    >
                  </div>
                  <div class="inner__right__block">
                    <Text variant="p">
                      {{
                        CurrencyFilter.formatToCurrency(
                          revenueandJobServiceData.serviceRevenue[
                            serviceName
                          ].revenue.toLocaleString()
                        )
                      }}
                    </Text>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="right__block">
            <div
              id="revenueByServiceChart"
              v-show="revenueandJobServiceData.totalRevenue"
            ></div>
            <div
              id="revenueByServiceChartEmptyState"
              v-show="!revenueandJobServiceData.totalRevenue"
            ></div>
          </div>
        </div>
      </template>
    </AnalyticsWidgetLayout>
    <AnalyticsWidgetLayout
      class="service__card"
      title="Job Duration by Service"
      :hideToolTip="true"
      headerIcon="jobService"
      :borderBottom="true"
    >
      <template #content>
        <div class="service__wrapper">
          <div class="left__block">
            <div
              v-if="!revenueandJobServiceData.totalDaysWorked"
              class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center tw-rounded-[16px] tw-overflow-hidden tw-min-h-[350px] tw-bg-white"
            >
              <img src="@/assets/images/analytics-empty-icon.png" />
              <Text
                textWeight="500"
                variant="p"
                textColor="rgba(12, 15, 74, 0.5)"
                lineHeight="18px"
                >Job duration by service will show here<br />
                when you complete jobs.</Text
              >
            </div>
            <div class="service__list" v-else>
              <ul>
                <li
                  v-for="serviceName in Object.keys(
                    revenueandJobServiceData.serviceRevenue
                  )"
                  :key="serviceName"
                >
                  <div class="inner__left__block">
                    <!-- <div class="service__icon">
                      <img src="../../../assets/icons/gas.svg" alt="" />
                    </div> -->
                    <Text
                      variant="p"
                      textWeight="600"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >{{ serviceName }}</Text
                    >
                  </div>
                  <div class="inner__right__block">
                    <Text variant="p"
                      >{{
                        revenueandJobServiceData.serviceRevenue[serviceName]
                          .days
                      }}
                      Days</Text
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="right__block">
            <div
              id="jobDurationByServiceChart"
              v-show="revenueandJobServiceData.totalDaysWorked"
            ></div>
            <div
              id="jobDurationByServiceChartEmptyState"
              v-show="!revenueandJobServiceData.totalDaysWorked"
            ></div>
          </div>
        </div>
      </template>
    </AnalyticsWidgetLayout>
  </div>
</template>

<script lang="ts" setup>
import AnalyticsWidgetLayout from "@/core/components/slots/AnalyticsWidgetLayout.vue";
import { computed, onMounted } from "vue";
import Highcharts from "highcharts";
import { useStore } from "vuex";
import { ANALYTICS_STORE } from "@/store/modules/analytics";
import CurrencyFilter from "@/filters/currency.filter";
import Text from "@/core/components/ui/general/Text.vue";
import { fa } from "element-plus/es/locale";

const store = useStore();

const revenueByServiceData = computed(
  () => store.getters[`${ANALYTICS_STORE}/getRevenueByService`]
);
const jobDurationByServiceData = computed(
  () => store.getters[`${ANALYTICS_STORE}/getJobDurationByService`]
);

const revenueandJobServiceData = computed(
  () => store.getters[`${ANALYTICS_STORE}/getRevenueAndJobService`]
);

// Helper function to generate a random vibrant color
const getRandomVibrantColor = () => {
  const hue = Math.floor(Math.random() * 360); // Random hue
  const saturation = 70 + Math.random() * 20; // High saturation (70-90%)
  const lightness = 50 + Math.random() * 10; // Medium lightness (50-60%)
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

// Default colors for the first four entries
const defaultColors = ["#FFA500", "#8D877B", "#166766", "#4F55F0"];

const getServiceRevenueData = () => {
  // const svgIconMap = {
  //   Architectural: "architecturalChart",
  //   Electrical: "electricalChart",
  //   Insulation: "insulationChart",
  //   "Gas & Heating": "gasChart",
  //   // Add any other mappings needed for service names
  // };

  // Transform the serviceRevenue object into the expected data array format
  return Object.entries(revenueandJobServiceData.value.serviceRevenue).map(
    ([serviceName, serviceData], index) => ({
      name: serviceName,
      y: serviceData.revenue,
      color: defaultColors[index] || getRandomVibrantColor(), // Use random vibrant color
      // svgIcon: svgIconMap[serviceName] || "defaultChartIcon", // Default icon if not in map
    })
  );
};

const jobDurationByServiceChartOptions = computed(() => {
  return {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        innerSize: "75%", // This creates the donut effect
        dataLabels: {
          enabled: true,
          useHTML: true,
          distance: 10, // Distance from the pie slice
          formatter: function () {
            // Return custom HTML content
            // return `<img src="${require(`@/assets/icons/${
            //   this.point.svgIcon + ".svg"
            // }`)}" style="width: 35px; height: 35px;" />`;
            return `<span>${this.point.name}</span>`;
          },
          align: "left", // Align the label (can be 'left', 'center', 'right')
          verticalAlign: "bottom",
        },
        borderRadius: 0,
      },
    },
    series: [
      {
        name: "Share",
        data: getServiceRevenueData(),
      },
    ],
    subtitle: {
      text: `<div ><p>Total</p><h5>${revenueandJobServiceData.value?.totalDaysWorked} Days</h5></div>`,
      useHTML: true,
      verticalAlign: "middle",
      align: "center",
    },
  };
});

const jobDurationByServiceChartOptionsEmptyState = computed(() => {
  return {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      useHTML: true, // Enable HTML for custom formatting
      pointFormat: "<b>0</b>", // Custom format
      style: {
        color: "#333333", // Tooltip text color
        fontSize: "14px",
        fontWeight: "500", // Tooltip text size
      },
    },
    plotOptions: {
      pie: {
        innerSize: "75%", // This creates the donut effect
        dataLabels: {
          enabled: false,
          useHTML: true,
          distance: 10, // Distance from the pie slice
          formatter: function () {
            // Return custom HTML content
            return `<img src="${require(`@/assets/icons/${
              this.point?.svgIcon + ".svg"
            }`)}" style="width: 35px; height: 35px;" />`;
          },
          align: "left", // Align the label (can be 'left', 'center', 'right')
          verticalAlign: "bottom",
        },
        borderRadius: 0,
      },
    },
    series: [
      {
        name: "Share",
        data: [
          {
            name: "",
            y: 25,
            color: "#AEAEAE",
            svgIcon: "",
          },
          {
            name: "",
            y: 25,
            color: "#5F5F5F",
            svgIcon: "",
          },
          {
            name: "",
            y: 25,
            color: "#AEAEAE",
            svgIcon: "",
          },
          {
            name: "",
            y: 25,
            color: "#5F5F5F",
            svgIcon: "",
          },
        ],
      },
    ],
    subtitle: {
      text: `<div ><p>Total</p><h5>${revenueandJobServiceData.value?.totalDaysWorked} Days</h5></div>`,
      useHTML: true,
      verticalAlign: "middle",
      align: "center",
    },
  };
});

const revenueByServiceChartOption = computed(() => {
  return {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        innerSize: "75%", // This creates the donut effect
        dataLabels: {
          enabled: true,
          useHTML: true,
          distance: 10, // Distance from the pie slice
          formatter: function () {
            // Return custom HTML content
            // return `<img src="${require(`@/assets/icons/${
            //   this.point.svgIcon + ".svg"
            // }`)}" style="width: 35px; height: 35px;" />`;
            return `<span>${this.point.name}</span>`;
          },
          align: "left", // Align the label (can be 'left', 'center', 'right')
          verticalAlign: "top",
        },
        borderRadius: 0,
      },
    },
    series: [
      {
        name: "Share",
        data: getServiceRevenueData(),
      },
    ],
    subtitle: {
      text: `<div ><p>Total</p><h5>${CurrencyFilter.formatToCurrency(
        revenueandJobServiceData.value?.totalRevenue
      )}</h5></div>`,
      useHTML: true,
      verticalAlign: "middle",
      align: "center",
    },
  };
});

const revenueByServiceChartOptionEmptyState = computed(() => {
  return {
    chart: {
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      useHTML: true, // Enable HTML for custom formatting
      pointFormat: "<b>£0</b>", // Custom format
      style: {
        color: "#333333", // Tooltip text color
        fontSize: "14px",
        fontWeight: "500", // Tooltip text size
      },
    },
    plotOptions: {
      pie: {
        innerSize: "75%", // This creates the donut effect
        dataLabels: {
          enabled: false,
          useHTML: true,
          distance: 10, // Distance from the pie slice
          formatter: function () {
            // Return custom HTML content
            return `<img src="${require(`@/assets/icons/${
              this.point?.svgIcon + ".svg"
            }`)}" style="width: 35px; height: 35px;" />`;
          },
          align: "left", // Align the label (can be 'left', 'center', 'right')
          verticalAlign: "top",
        },
        borderRadius: 0,
      },
    },
    series: [
      {
        name: "Share",
        data: [
          {
            name: "",
            y: 25,
            color: "#AEAEAE",
            svgIcon: "",
          },
          {
            name: "",
            y: 25,
            color: "#5F5F5F",
            svgIcon: "",
          },
          {
            name: "",
            y: 25,
            color: "#AEAEAE",
            svgIcon: "",
          },
          {
            name: "",
            y: 25,
            color: "#5F5F5F",
            svgIcon: "",
          },
        ],
      },
    ],
    subtitle: {
      text: `<div ><p>Total</p><h5>${CurrencyFilter.formatToCurrency(
        revenueandJobServiceData.value?.totalRevenue
      )}</h5></div>`,
      useHTML: true,
      verticalAlign: "middle",
      align: "center",
    },
  };
});

onMounted(() => {
  console.log(revenueandJobServiceData.value, "revenueandJobServiceData");
  Highcharts.chart("revenueByServiceChart", revenueByServiceChartOption.value);
  Highcharts.chart(
    "revenueByServiceChartEmptyState",
    revenueByServiceChartOptionEmptyState.value
  );
  Highcharts.chart(
    "jobDurationByServiceChart",
    jobDurationByServiceChartOptions.value
  );
  Highcharts.chart(
    "jobDurationByServiceChartEmptyState",
    jobDurationByServiceChartOptionsEmptyState.value
  );
});
</script>

<style lang="scss" scoped>
.service__widget {
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 16px;
  @include respond(s1024) {
    flex-direction: column;
  }
  .service__card {
    width: calc(50% - 8px);
    max-width: 100%;
    @include respond(s1024) {
      width: 100%;
    }
    .analytics_card__content {
      .card__content {
        flex-direction: row;
        .service__wrapper {
          width: 100%;
          max-width: 100%;
          display: flex;
          align-items: center;
          gap: 58px;
          background: white;
          padding: 1rem;
          box-sizing: border-box;
          border-radius: 16px;
          @include respond(xl) {
            flex-direction: column-reverse;
            gap: 16px;
          }
          .left__block {
            width: 100%;
            max-width: 100%;
            @include respond(xl) {
              width: 100%;
            }
            .service__list {
              ul {
                li {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 12px 0;
                  border-bottom: 1px dashed rgba(134, 135, 165, 1);
                  &:last-child {
                    border: none;
                  }
                  .inner__left__block {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    .service__icon {
                      width: 18px;
                      height: 18px;
                      img {
                        width: 100%;
                        height: 100%;
                        filter: invert(8%) sepia(82%) saturate(2370%)
                          hue-rotate(228deg) brightness(91%) contrast(105%);
                        opacity: 0.5;
                      }
                    }
                    p {
                      @include fluidFont(14, 14, 1.2);
                      color: rgba($blueDark, 0.5);
                      letter-spacing: 0.25px;
                      font-weight: 600;
                      text-align: left;
                    }
                  }
                  .inner__right__block {
                    p {
                      @include fluidFont(14, 14, 1.2);
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.25px;
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
          .right__block {
            width: 100%;
            max-width: 100%;
            @include respond(xl) {
              width: 100%;
            }
            :deep(.highcharts-container) {
              .highcharts-subtitle {
                text-align: center;
                p {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 500;
                  color: rgba($blueDark, 0.5);
                  letter-spacing: 0.25px;
                }
                h5 {
                  @include fluidFont(20, 20, 1.3);
                  font-weight: 700;
                  color: rgba($blueDark, 1);
                  letter-spacing: 0.25px;
                }
              }
            }
          }
        }
      }
    }
  }
}

:deep(.highcharts-credits) {
  display: none !important;
}
</style>
