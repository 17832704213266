<template>
  <GeneralDialog
    class="tw-max-w-full !tw-w-[570px] !tw-max-w-full md:tw-w-[340px]"
    @on-close="onCancelModal"
    @on-back="onClickBackButton"
  >
    <template #body>
      <Text variant="h5" lineHeight="28px" textWeight="400"
        >Reject Site Visit</Text
      >
      <v-form
        ref="form"
        v-model="formValid"
        class="tw-max-w-full tw-mt-6 tw-flex tw-flex-col tw-gap-6"
        @submit.prevent="onConfirm"
      >
        <div class="custom_input_field">
          <Text variant="span" textWeight="400" class="tw-mb-2">
            Please select reason of rejection
          </Text>
          <v-select
            variant="outlined"
            class="custom_selector"
            v-model="selectedRejectTypes"
            :items="typeList"
            item-title="label"
            item-value="value"
            placeholder="Select of Type"
            :rules="[(v) => !!v || 'Type is required']"
            :menu-props="{ contentClass: 'custom__text__selector' }"
            required
          ></v-select>
          <Text
            variant="span"
            textWeight="400"
            class="tw-mb-2"
            v-if="isCustomReason"
          >
            Please specify your reason
          </Text>
          <v-text-field
            class="custom_selector"
            v-if="isCustomReason"
            v-model="customReason"
            :rules="[(v) => !!v || 'Custom reason is required']"
            required
          ></v-text-field>
        </div>

        <div
          class="custom_input_field tw-pb-6 tw-rounded-none tw-border-[#C5D2F2] tw-border-0 tw-border-b tw-border-solid"
        >
          <Text variant="span" textWeight="400" class="tw-mb-2">
            Message (optional)
          </Text>
          <v-textarea
            v-model="message"
            placeholder="Message"
            required
            variant="outlined"
            class="custom_selector"
          ></v-textarea>
        </div>
        <div class="tw-flex tw-gap-3">
          <Button
            variant="secondary"
            label="cancel"
            class="button !tw-w-1/2 md:!tw-w-full"
            @click="onCancelModal"
          />
          <Button
            variant="primary"
            label="confirm"
            type="submit"
            :loading="props.isLoading"
            class="button button-orange !tw-w-1/2 md:!tw-w-full"
          />
        </div>
      </v-form>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/uni-components/Button.vue";
import Text from "@/core/uni-components/Text.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { watch } from "vue";

const store = useStore();
const emits = defineEmits(["on-cancel", "on-confirm"]);
const selectedRejectTypes = ref(null);
const message = ref("");
const formValid = ref(false);
const onClickBackButton = () => {
  selectedRejectTypes.value = null;
  message.value = "";
};

const typeList = ref([
  {
    label: "Routine Inspection",
    value: "routine_inspection",
  },
  {
    label: "Complaint Investigation",
    value: "complaint_investigation",
  },
  {
    label: "Follow-up Visit",
    value: "follow_up_visit",
  },
  { label: "Others", value: "others" },
]);
const customReason = ref("");
const isCustomReason = computed(() => selectedRejectTypes.value === "others");

watch(selectedRejectTypes.value, (newValue) => {
  if (newValue !== "others") {
    customReason.value = "";
  }
});
const onCancelModal = () => {
  emits("on-cancel");
};

const props = defineProps({
  projectId: Number,
  projectEstimateId: Number,
  isLoading: { default: false, type: Boolean },
});

const onConfirm = async () => {
  if (formValid.value) {
    const payload = {
      rejectionReason: isCustomReason.value
        ? customReason.value
        : selectedRejectTypes.value,
      rejectionMessage: message.value,
    };

    emits("on-confirm", payload);
  }
};
</script>
<style lang="scss" scoped>
.custom_selector {
  :deep(.v-input__control) {
    .v-field {
      background-color: transparent;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 0;
      .v-field__field {
        .v-field__input {
          min-height: 40px;
          padding: 12px 16px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          opacity: 1;
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
          input {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.date_selector {
  :deep(.dp__input_wrap) {
    .dp__input {
      min-height: 40px;
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      padding: 12px 30px;
      border-radius: 8px;
    }
    &::placeholder {
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
      opacity: 1;
    }
  }
}
</style>
