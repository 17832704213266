<template>
  <CommonDialog
    :HeaderTittle="modalDetails.header"
    :title="modalDetails.title"
    :description="modalDetails.description"
    :isShowBackButton="false"
  >
    <template #body>
      <main
        v-if="eligibility"
        class="tw-flex tw-flex-col tw-h-full tw-min-h-[400px] tw-justify-between tw-mt-4 tw-gap-4 tw-overflow-y-auto"
      >
        <section class="tw-flex tw-flex-col tw-h-full tw-gap-4">
          <div class="tw-flex tw-flex-col tw-gap-0 tw-items-start">
            <div class="tw-flex tw-items-center tw-gap-2">
              <CheckGreen
                class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:tw-w-4 rmd:tw-h-4"
              />
              <Text class="rmd:!text-sm" variant="span" textWeight="500"
                >Certificates that are approved by the admin.</Text
              >
            </div>
            <div class="tw-flex tw-items-center tw-gap-2">
              <CancelIcon
                class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:tw-w-4 rmd:tw-h-4"
              />
              <Text class="rmd:!text-sm" variant="span" textWeight="500"
                >Certificates that are not approved / uploaded.</Text
              >
            </div>
          </div>

          <div
            class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-justify-start tw-pb-4 tw-box-border tw-border-b-solid-custom"
          >
            <div
              class="tw-flex tw-items-center tw-justify-between tw-w-full tw-bg-[rgba(75,75,255,0.1)] tw-rounded-lg tw-p-4 tw-box-border"
            >
              <Text
                v-if="eligibility?.isEligible"
                class="rmd:!tw-text-base"
                variant="xsmall"
                textWeight="600"
                >🥳 You are eligible to enroll the course.</Text
              >
              <div v-else class="tw-flex tw-items-center tw-gap-2">
                <v-icon
                  icon="mdi-close-circle"
                  color="rgba(12, 15, 74, 0.36)"
                  size="x-small"
                ></v-icon>
                <Text
                  class="rmd:!tw-text-base rmd:!tw-leading-5"
                  variant="xsmall"
                  textWeight="600"
                  >You do not meet the requirements to enroll for this course.
                  with a button to upload the courses
                </Text>
              </div>
              <ChipState
                class="tw-cursor-pointer"
                bgColor="#FAA500"
                :label="eligibility?.isEligible ? 'Enroll' : 'Upload'"
                @click="onButtonClick(eligibility.isEligible)"
                textColor="#FFF"
              ></ChipState>
            </div>
            <Text class="rmd:!text-base" variant="span" textWeight="600"
              >Certificates</Text
            >
          </div>
          <div
            class="tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-start"
          >
            <Text
              class="rmd:!text-sm"
              variant="span"
              textWeight="500"
              v-html="messageBasedOnCourse"
            ></Text>
            <div
              class="tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-2 tw-items-start"
            >
              <div
                v-for="(eligibility, index) in eligibility.data"
                :key="index"
                class="tw-w-full tw-flex tw-items-center tw-justify-between tw-gap-2 tw-border-b tw-border-0 tw-border-dashed tw-border-[#0C0F4A8a] tw-pb-3 last:tw-border-0"
              >
                <div class="tw-flex tw-gap-2 tw-items-center">
                  <ListIcon
                    class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:tw-w-4 rmd:tw-h-4"
                  />
                  <Text
                    class="rmd:!text-base"
                    variant="xsmall"
                    whiteSpace="wrap"
                    >{{ eligibility.name }}</Text
                  >
                </div>
                <CheckGreen
                  class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:tw-w-4 rmd:tw-h-4"
                  v-if="eligibility.isEligible"
                />
                <CancelIcon
                  class="tw-w-3 tw-h-3 tw-min-w-3 tw-min-h-3 rmd:tw-w-4 rmd:tw-h-4"
                  v-else
                />
              </div>
            </div>
          </div>
        </section>
        <section class="tw-flex tw-items-start tw-flex-col tw-w-full tw-gap-4">
          <Button
            label="close"
            @click="onClickBackButton"
            class="rmd:!tw-w-[400px]"
          />
        </section>
      </main>
    </template>
  </CommonDialog>
  <CertificationModel
    v-if="isShowCertificateModal"
    @onClickCloseTab="onClickCloseModel"
  />
</template>
<script setup>
import { ref, onBeforeMount, onBeforeUnmount, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CertificationModel from "@/modules/certification/components/CertificationModel.vue";

import CommonDialog from "@/core/components/CommonDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import CancelIcon from "@/core/components/icons/CancelIcon.vue";
import ListIcon from "@/core/components/icons/ListIcon.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import { WORKSTATION } from "@/store/modules/workstation";

const store = useStore();
const route = useRoute();
const router = useRouter();
const eligibility = ref(null);

const isLoading = ref(false);
const isShowCertificateModal = ref(false);

const modalDetails = {
  header: "Eligibility",
  title: "Check eligibility",
  description:
    "please check and confirm if you are eligible for this course or not. Add additional certification in-case you need to match the requirement.",
};

const emits = defineEmits(["on-close"]);

const infoMessages = ref([
  {
    courseId: 1,
    message:
      "For the user to be eligible for the course to enroll must have at least 1 of <b>below six certificates</b>",
  },
  {
    courseId: 2,
    message:
      "For the user to be eligible for the course to enroll must have <b>18th editions</b> certificate and also need any 1 of <b>below remain three</b>",
  },
]);

const messageBasedOnCourse = computed(() => {
  const courseId = parseInt(route?.params?.courseId, 10);
  return infoMessages.value.find((message) => message.courseId == courseId)
    .message;
});
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const onClickBackButton = () => {
  return emits("on-close");
};

const getCheckElegibility = async () => {
  const courseId = parseInt(route?.params?.courseId, 10);
  try {
    isLoading.value = true;

    let response = await store.dispatch(`${COURSES_STORE}/checkElegibility`, {
      courseId,
      workstationId: activeUserWorkstation.value?.id,
    });

    if (response.data) {
      eligibility.value = response;
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const onButtonClick = (isEligible) => {
  if (isEligible) {
    onClickBackButton();
    console.log("enroll");
  } else {
    onClickBackButton();
    router.push({ name: TRADE_PASSPOSRT_ROUTE });
    console.log("upload");
  }
};

const onClickCloseModel = () => {
  isShowCertificateModal.value = false;
};

onBeforeMount(() => {
  getCheckElegibility();
});

onBeforeUnmount(() => {
  eligibility.value = null;
});
</script>
<style lang="scss" scoped></style>
