<template>
  <InvitationSuccessComponent
    v-if="isShowInvitationsSentModal"
    @onClose="onClickCloseButton"
    :members="form.members"
  />
  <CommonDialog
    v-else
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['add_contractor_dialog']"
    width="700px"
    max-width="100%"
  >
    <template v-slot:body>
      <div class="header">
        <h4>
          Invite a {{ isClientModule ? "clients" : "contractor" }} to iknowa
          platform
        </h4>
        <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
      </div>
      <div class="modal__content">
        <!-- <div class="modal__content_header_note">
          Each added member costs £9.99/month. <span> Learn More. </span>
        </div> -->
        <div class="modal__inner__content tw-w-full">
          <v-form ref="emailFormRef" @submit.prevent class="tw-w-full">
            <div
              v-for="(member, index) in form.members"
              :key="index"
              class="tw-w-full invite_members_form"
              :class="{ 'no-tags': !isShowTag }"
            >
              <div class="input_field email_address">
                <label>Email Addresses</label>
                <v-text-field
                  type="email"
                  density="compact"
                  v-model="member.email"
                  :rules="validation.email"
                  variant="outlined"
                  class="c-input"
                  placeholder="Email address"
                ></v-text-field>
              </div>
              <div v-if="isShowTag" class="input_field tags_wrapper">
                <label>Tags</label>
                <v-select
                  class="c-select"
                  v-model="member.tags"
                  :items="Tags"
                  :rules="validation.tags"
                  placeholder="Select Tags"
                  multiple
                  variant="outlined"
                  :menu-props="{ contentClass: 'tags_dropdown' }"
                  :menu="false"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      title="Select All"
                      @click="toggle(member)"
                      class="list__item"
                    >
                      <template v-slot:prepend>
                        <v-checkbox-btn
                          :color="
                            likesSomeTag(member) ? 'indigo-darken-4' : undefined
                          "
                          :indeterminate="
                            likesSomeTag(member) && !likesAllTags(member)
                          "
                          :model-value="likesAllTags(member)"
                        ></v-checkbox-btn>
                      </template>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item>
                    <v-list-item>
                      <v-text-field
                        v-model="customTag"
                        label="Add custom tag"
                        variant="outlined"
                        class="inner_input"
                      ></v-text-field>
                      <v-btn
                        @click="addCustomTag(member)"
                        class="button button-orange"
                        >Add</v-btn
                      >
                    </v-list-item>
                  </template>
                </v-select>
              </div>

              <v-btn
                v-if="form.members.length > 1"
                icon="mdi-close"
                @click="onRemoveEmail(index)"
                class="remove_btn"
                variant="plain"
              ></v-btn>
            </div>
            <v-btn
              variant="text"
              class="button button-orange add_btn"
              @click="onAddEmail"
            >
              +ADD
            </v-btn>
            <div class="input_field message_wrapper">
              <label>Message (optional):</label>
              <v-textarea
                v-model="memberMessage"
                variant="outlined"
              ></v-textarea>
            </div>
          </v-form>
        </div>
      </div>
      <div class="modal__footer">
        <v-btn class="button button-purple-border" @click="onClickCloseButton"
          >cancel</v-btn
        >
        <v-btn
          class="button button-orange"
          @click="onSendInvitation"
          :loading="isShowLoadingSendInvitation"
          :disabled="!isFormValid || !hasAtLeastOneMember"
          >send invitation</v-btn
        >
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { computed, ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { USER_STORE } from "@/store/modules/user";
import InvitationSuccessComponent from "@/core/components/InvitationSuccessComponent.vue";
import { useRoute, useRouter } from "vue-router";
import { CLIENTS_ROUTE } from "@/modules/clients/routes";

export default {
  components: {
    CommonDialog,
    InvitationSuccessComponent,
  },
  props: {
    isShowTag: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const memberMessage = ref("");
    const isShowLoadingSendInvitation = ref(false);
    const isShowInvitationsSentModal = ref(false);
    const selectedTags = ref([]) as any;
    const route = useRoute();
    const isClientModule = computed(() => route?.name === CLIENTS_ROUTE);
    const Tags = computed(() =>
      isClientModule.value
        ? ["Home Owner", "Ltd Company", "Occupier"]
        : ["Trade Contactor", "Manager", "Assistant"]
    );
    const customTag = ref("");
    const form = reactive({
      members: [{ email: "", tags: [] }],
    });
    const emailFormRef = ref(null) as any;
    const validation = ref({
      email: [
        (v: any) => !!v || "Email is required",
        (v: any) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Invalid email. Try again.",
        (v: any) => !isEmailDuplicate(v) || "Email is already used",
      ],
      tags: [(v: any) => v.length > 0 || "At least one tag is required"],
    });

    const isEmailDuplicate = (email: string) => {
      const emailValues = form.members.map((emailObj) => emailObj.email);
      const emailCount = emailValues.filter((e) => e === email).length;
      return emailCount > 1;
    };

    const onAddEmail = (e: any) => {
      e.preventDefault();
      form.members.push({ email: "", tags: [] });
    };

    const onRemoveEmail = (index: number) => {
      form.members.splice(index, 1);
    };

    const likesAllTags = (member: any) => {
      return member.tags.length === Tags.value.length;
    };

    const likesSomeTag = (member: any) => {
      return member.tags.length > 0;
    };

    const toggle = (member: any) => {
      if (likesAllTags(member)) {
        member.tags = [];
      } else {
        member.tags = Tags.value.slice();
      }
    };

    const addCustomTag = (member: any) => {
      if (customTag.value && !member.tags.includes(customTag.value)) {
        member.tags.push(customTag.value);
        customTag.value = "";
      }
    };

    const onSendInvitation = async () => {
      try {
        isShowLoadingSendInvitation.value = true;
        const processedForm = form.members.map((member: any) => {
          if (!props.isShowTag) {
            delete member?.tags;
          }
          const processedMember = {
            ...member,
          };
          if (props.isShowTag) {
            processedMember["tags"] = JSON.stringify(member.tags);
          }
          if (memberMessage.value) {
            processedMember.message = memberMessage.value;
          }

          return processedMember;
        });
        const response = await store.dispatch(
          `${PREFERRED_CONTRACTORS}/inviteContractors`,
          {
            members: { members: processedForm },
            userId: user.value?.id,
          }
        );
        if (response) {
          isShowInvitationsSentModal.value = true;
          let params: any = {
            status: "requested",
          };
          await store.dispatch(
            `${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`,
            {
              userId: user.value?.id,
              params: params,
            }
          );
        }
        ctx.emit("redirectToRequest");
      } catch (err) {
        console.log();
      } finally {
        isShowLoadingSendInvitation.value = false;
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickCloseTab");
    };

    // const isFormValid = computed(() => {
    //   return form.members.every((member) =>
    //     validation.value.email.every((rule) => rule(member.email) === true) &&
    //     props.isShowTag
    //       ? validation.value.tags.every((rule) => rule(member.tags) === true)
    //       : true
    //   );
    // });
    const isFormValid = computed(() => {
      return form.members.every((member) => {
        const emailValid = validation.value.email.every(
          (rule) => rule(member.email) === true
        );
        const tagsValid = props.isShowTag
          ? validation.value.tags.every((rule) => rule(member.tags) === true)
          : true;
        return emailValid && tagsValid;
      });
    });

    const hasAtLeastOneMember = computed(() => {
      return form.members.some((member) => member.email.trim() !== "");
    });

    return {
      onClickCloseButton,
      validation,
      emailFormRef,
      form,
      onAddEmail,
      onSendInvitation,
      onRemoveEmail,
      selectedTags,
      Tags,
      toggle,
      likesAllTags,
      likesSomeTag,
      customTag,
      addCustomTag,
      isShowInvitationsSentModal,
      user,
      memberMessage,
      isShowLoadingSendInvitation,
      isFormValid,
      hasAtLeastOneMember,
      isClientModule,
    };
  },
};
</script>
<style lang="scss" scoped>
.no-tags {
  .email_address {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
