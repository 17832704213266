<template>
  <CollapsibleWidgetSlot title="Recommended Projects">
    <template #content>
      <section
        class="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-box-border tw-border-t-solid-custom"
      >
        <Button
          v-if="isSiteVisitPropertyDetail"
          @click="onClickShowRecommendedProjectModal"
          variant="custom"
          label="Add Recommended Project"
          width="100%"
          height="31px"
          activeBgColor="#4F55F0"
          activeFontColor="#FFF"
          fontSize="11px"
        />
        <template v-if="recommendations && recommendations.length">
          <v-expansion-panels
            v-for="(recommendation, index) in recommendations"
            :key="index"
          >
            <v-expansion-panel
              :expand="isExpand"
              class="collapsible-recommendation !tw-rounded-[10px]"
            >
              <v-expansion-panel-title>
                <div
                  class="!tw-w-full tw-flex tw-items-center tw-justify-between"
                >
                  <div class="tw-flex tw-items-center tw-gap-1">
                    <img
                      src="@/assets/icons/recommendation-icons/home-rounded-square-yellow.svg"
                    />

                    <Text variant="span" class="ellipsis-text">
                      {{
                        recommendation?.improvement_summary_text ||
                        (recommendation?.recommendation
                          ? recommendation?.recommendation.slice(0, 20) + "..."
                          : recommendation?.improvement_descr_text.slice(
                              0,
                              20
                            ) + "...")
                      }}
                    </Text>
                  </div>
                  <div class="tw-flex tw-items-center tw-gap-1 tw-mr-2">
                    <StatusChip
                      :bgColor="
                        recommendation.priority
                          ? getPriorityColor(recommendation.priority)
                          : '#F45454'
                      "
                      :label="
                        recommendation?.priority
                          ? getPriorityText(recommendation?.priority)
                          : 'High Priority'
                      "
                      :isDense="true"
                    />
                  </div>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text
                class="tw-border-t-2 tw-px-[.7rem] tw-pb-[.7rem] tw-pt-0 tw-box-border"
              >
                <div
                  class="tw-w-full tw-rounded-lg tw-bg-white tw-flex tw-px-[.7rem] tw-py-[.8rem] tw-box-border tw-flex-col tw-justify-between tw-items-start tw-gap-2"
                >
                  <div class="tw-flex tw-w-full tw-flex-col tw-items-start">
                    <Text variant="span" lineHeight="18px">
                      {{ recommendation?.improvement_summary_text }}</Text
                    >
                    <Text variant="xsmall" textWeight="400" lineHeight="18px">{{
                      recommendation?.recommendation
                        ? recommendation?.recommendation
                        : recommendation?.improvement_descr_text
                    }}</Text>
                  </div>

                  <div
                    class="tw-w-full tw-flex tw-items-start tw-justify-between tw-pt-2 tw-border-t-dashed-custom recommendation__card__footer"
                  >
                    <!-- <div class="chat__box">
                      <img src="@/assets/icons/sidebar-icons/chat.svg" />
                    </div> -->
                    <div></div>
                    <v-btn
                      variant="text"
                      @click="createProject(recommendation, index)"
                      :loading="loading && activeIndex === index"
                      >Post This Project <v-icon icon="mdi-chevron-right"
                    /></v-btn>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <template v-for="(recommendation, index) in recommendations">
            <div
              v-if="index < 3"
              :key="index"
              class="tw-bg-[#F1F6FB] tw-rounded-lg tw-p-2 tw-box-border tw-flex tw-items-center tw-justify-between"
            >
              <div class="tw-flex tw-items-center tw-gap-1">
                <img
                  src="@/assets/icons/recommendation-icons/home-rounded-square-yellow.svg"
                />
                <Text variant="span">Air Source Heat Pump</Text>
              </div>
              <div class="tw-flex tw-items-center tw-gap-1">
                <StatusChip label="High Priority" :isDense="true" />
                <v-icon icon="mdi-chevron-right" color="#0C0F4A"></v-icon>
              </div>
            </div> -->
          <!-- <div
              class="tw-bg-[#F1F6FB] tw-rounded-lg tw-p-2 tw-box-border tw-flex tw-items-center tw-justify-between"
            >
              <div class="tw-flex tw-items-center tw-gap-1">
                <img
                  src="@/assets/icons/recommendation-icons/speedometer-rounded-square-violet.svg"
                />
                <Text variant="span">Cavity Wall Insulation</Text>
              </div>
              <div class="tw-flex tw-items-center tw-gap-1">
                <StatusChip
                  label="Low Priority"
                  :isDense="true"
                  bgColor="#4A92E5"
                />
                <v-icon icon="mdi-chevron-right" color="#0C0F4A"></v-icon>
              </div>
            </div>
            <div
              class="tw-bg-[#F1F6FB] tw-rounded-lg tw-p-2 tw-box-border tw-flex tw-items-center tw-justify-between"
            >
              <div class="tw-flex tw-items-center tw-gap-1">
                <img
                  src="@/assets/icons/recommendation-icons/tp-rounded-square-olive.svg"
                />
                <Text variant="span">Window Replacement</Text>
              </div>
              <div class="tw-flex tw-items-center tw-gap-1">
                <StatusChip
                  label="Med Priority"
                  :isDense="true"
                  bgColor="#FFA500"
                />
                <v-icon icon="mdi-chevron-right" color="#0C0F4A"></v-icon>
              </div>
            </div> -->
          <!-- </template> -->
        </template>
        <!-- <template v-if="recommendations && recommendations.length">
          <div
            class="recommendation__card"
            v-for="(recommendation, index) in recommendations"
            :key="index"
          >
            <div class="recommendation__card__header">
              <div class="property__text__box">
                <v-icon icon="mdi-home"></v-icon>PROPERTY
                <img src="@/assets/icons/info-icon-transparent.svg" />
              </div>
              <div
                class="priority__text__box"
                :class="
                  recommendation.priority
                    ? getPriorityColor(recommendation.priority)
                    : 'red'
                "
              >
                <v-icon icon="mdi-information"></v-icon
                >{{
                  recommendation?.priority
                    ? getPriorityText(recommendation?.priority)
                    : "Hight Priority"
                }}
              </div>
            </div>
            <div class="recommendation__card__main">
              <div class="recommendation__card__main__title">
                {{ recommendation?.improvement_summary_text }}
              </div>

              <div class="recommendation__card__main__subtitle">
                {{
                  recommendation?.recommendation
                    ? recommendation?.recommendation
                    : recommendation?.improvement_descr_text
                }}
              </div>
            </div>
            <div
              v-if="!isSiteVisitPropertyDetail"
              class="recommendation__card__footer"
            >
              <v-btn
                variant="text"
                @click="createProject(recommendation, index)"
                :loading="loading && activeIndex === index"
                >Post This Project <v-icon icon="mdi-chevron-right"
              /></v-btn>
            </div>
          </div>
        </template> -->
        <div
          v-else
          class="tw-w-full tw-h-[150px] tw-flex tw-items-center tw-justify-center"
        >
          <Text variant="p">No Recommendations found</Text>
        </div>
      </section>
    </template>
    <!-- <template #actions>
      <div class="tw-w-full tw-flex tw-items-center tw-justify-end">
        <div class="tw-flex tw-gap-0 tw-items-center tw-cursor-pointer">
          <Text variant="p" textColor="#FFA500">View All</Text>
          <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
        </div>
      </div>
    </template> -->
  </CollapsibleWidgetSlot>

  <AddRecommendedProjectModal
    v-if="isShowAddRecommendedProjectModal"
    :propertyId="propertyId"
    @on-close="onCloseModal"
  />
</template>
<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import { PROPERTY_STORE } from "@/store/modules/property";
import { PROJECT_STORE } from "@/store/modules/project";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import { useRouter } from "vue-router";
import { PROJECT_DETAILS_ROUTE } from "@/modules/project/routes";
import Button from "@/core/components/ui/general/Button.vue";
// import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import StatusChip from "@/modules/dashboard/components/Chips/StatusChip.vue";
import AddRecommendedProjectModal from "@/core/components/modals/AddRecommendedProjectModal.vue";

const router = useRouter();
const store = useStore();

const recommendations = ref([]);
const loading = ref(false);
const activeIndex = ref(null);
const isShowAddRecommendedProjectModal = ref(false);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);
const isSiteVisitPropertyDetail = computed(
  () => store.getters[`${PROPERTY_STORE}/getIsSiteVisitPropertyDetail`]
);
const propertyId = computed(() => {
  const data = store.getters[`${PROPERTY_STORE}/propertyDetails`];
  return data?.localData?.id;
});

const onClickShowRecommendedProjectModal = () => {
  isShowAddRecommendedProjectModal.value = true;
};
const onCloseModal = () => {
  isShowAddRecommendedProjectModal.value = false;
};

const getRecommendations = () => {
  const details = propertyDetails.value;
  const data = details?.localData;
  if (!data) return;

  if (data.epcDomestic) {
    recommendations.value = data.epcDomestic?.recommendations || [];
    console.log(recommendations.value, "recommendations.value");
  }
};
const getPriorityText = (priority) => {
  switch (priority) {
    case 1:
      return "Low Priority";
    case 2:
      return "Medium Priority";
    case 3:
      return "High Priority";
  }
};

const getPriorityColor = (priority) => {
  switch (priority) {
    case 1:
      return "#4A92E5";
    case 2:
      return "#FFA500";
    case 3:
      return "#F45454";
  }
};
onMounted(() => {
  const details = propertyDetails.value;

  if (details) {
    getRecommendations();
  }
});

const createProject = async (recommendation, index) => {
  try {
    loading.value = true;
    activeIndex.value = index;
    const propertiesData = [
      {
        propertyId: propertyDetails.value?.localData.id,
      },
    ];
    const createProjectResponce = await store.dispatch(
      `${PROJECT_STORE}/createProject`,
      {
        propertiesData: propertiesData,
        projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
        type: "standard",
        subType: "flexible",
        description: recommendation?.recommendation
          ? recommendation?.recommendation
          : recommendation?.improvement_descr_text,
        name: recommendation?.improvement_summary_text || null,
        attachments: [],
        siteVisitStatus: false,
        isAddSpecialismFromGemini: true,
        projectFrom: "recommendation",
      }
    );

    router.push({
      name: PROJECT_DETAILS_ROUTE,
      params: { refId: createProjectResponce.refId },
    });
  } catch (error) {
    console.log("error while creating project", error);
  } finally {
    loading.value = false;
  }
};
</script>
<style lang="scss" scoped>
.collapsible-recommendation {
  background: #f1f6fb;

  :deep(.v-expansion-panel-title) {
    padding: 0 0.7rem;
    min-height: 45px;
  }
  :deep(.v-expansion-panel__shadow) {
    // box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
    box-shadow: none;
  }
  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }
}
.recommendation__card__footer {
  .chat__box {
    cursor: pointer;
    background-color: rgba(12, 15, 74, 0.1);
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
      @include fluidFont(14, 14, 1);
    }
  }

  .v-btn {
    color: #ffa500;
    margin-left: auto;
    display: flex;
    padding: 0;
    opacity: 1;
    font-weight: 600;
    @include fluidFont(12, 12, 20px);
    height: auto;
    min-height: 1px;
    text-transform: none;
    letter-spacing: 0.13px;

    :deep(.v-btn__overlay),
    :deep(.v-btn__underlay) {
      display: none !important;
    }
  }
}
.ellipsis-text {
  display: -webkit-box;
  -webkit-box-orient: vertical; /* Sets the box orientation */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis */
  -webkit-line-clamp: 1;
}
.recommendation__card {
  background-color: rgba($cardBorder, 1);
  padding: 10px;
  border-radius: 4px;
  .recommendation__card__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 0 0 9px 0;
    .property__text__box {
      background-color: rgba($orange, 1);
      color: rgba($white, 1);
      gap: 4px;
      border-radius: 4px;
      padding: 2px 8px;
      display: inline-flex;
      align-items: center;
      @include fluidFont(12, 12, 1);
      .v-icon {
        color: rgba($white, 1);
        @include fluidFont(14, 14, 1);
      }
      img {
        width: 15px;
        height: 15px;
        filter: invert(100%) sepia(0%) saturate(7439%) hue-rotate(30deg)
          brightness(106%) contrast(114%);
      }
    }
    .priority__text__box {
      background-color: rgba($errorRed, 1);
      color: rgba($white, 1);
      gap: 4px;
      border-radius: 4px;
      padding: 2px 8px;
      display: inline-flex;
      align-items: center;
      @include fluidFont(12, 12, 1);
      .v-icon {
        color: rgba($white, 1);
        @include fluidFont(14, 14, 1);
      }
    }
  }
  .priority__text__box.red {
    background-color: rgba($errorRed, 1);
  }
  .priority__text__box.green {
    background-color: rgba($PrimaryGreen, 1);
  }
  .priority__text__box.yellow {
    background-color: rgba($orange, 1);
  }
  .recommendation__card__main {
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 0 0 9px 0;
    margin-top: 5px;
    .recommendation__card__main__title {
      @include fluidFont(12, 12, 2);
      font-weight: $fontSemiBold;
      letter-spacing: 0.25px;
      color: rgba($blueDark, 1);
      text-align: left;
      line-height: $line24;
    }
    .recommendation__card__main__subtitle {
      @include fluidFont(12, 12, 2);
      font-weight: $fontMedium;
      letter-spacing: 0.25px;
      color: rgba($blueDark, 1);
      text-align: left;
      line-height: $line16;
    }
  }
  .recommendation__card__footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .chat__box {
      background-color: rgba($buttonText, 0.5);
      gap: 4px;
      border-radius: 4px;
      padding: 2px 8px;
      display: flex;
      width: 30px;
      img {
        @include fluidFont(14, 14, 1);
      }
    }

    .v-btn {
      color: #ffa500;
      margin-left: auto;
      display: flex;
      padding: 0;
      opacity: 1;
      font-weight: 600;
      @include fluidFont(12, 12, 20px);
      height: auto;
      min-height: 1px;
      text-transform: none;
      letter-spacing: 0.13px;

      :deep(.v-btn__overlay),
      :deep(.v-btn__underlay) {
        display: none !important;
      }
    }
  }
}
</style>
