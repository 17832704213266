<template>
  <commonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="1010px"
    height="auto"
    max-width="100%"
    persistent
    :className="['edit__propertyList__modal']"
  >
    <template v-slot:body>
      <v-icon
        class="close__icon"
        icon="mdi-close"
        @click="oncloseModal"
      ></v-icon>

      <div class="custom__modal__heading">
        <h3>Properties</h3>
      </div>
      <div
        class="modal___content"
        v-if="propertiesList && propertiesList?.length && !isLoading"
      >
        <div class="property__cards">
          <div class="courses__card__list">
            <v-card
              class="courses__card"
              :class="{
                'selected-card': currentSelectedProperty?.id === property?.id,
              }"
              @click="toggleSelection(property)"
              v-for="(property, index) in propertiesList"
              :key="index"
            >
              <div class="courses__card__img">
                <img
                  class=""
                  cover
                  :src="
                    require('@/assets/images/solar-pv-installation-hero.jpg')
                  "
                />
              </div>
              <div class="courses__card__prefix">
                <div class="left__prefix">
                  <v-icon>
                    <img src="@/assets/icons/user.svg" alt="" />
                  </v-icon>
                  <p>{{ getPropertyType(property) }}</p>
                </div>
                <div class="right__prefix">
                  <v-icon icon="mdi-map-marker"></v-icon>
                  <p>{{ property?.postcode }}</p>
                </div>
              </div>
              <div class="courses__card__text">
                <h5 class="courses__card__title">
                  {{ property?.address }}
                </h5>
              </div>
            </v-card>
          </div>
          <div class="load__wrapper">
            <label
              v-if="isPaginationAvailable"
              @click="onLoadMore"
              class="yellow-text"
            >
              Load More Properties<v-icon icon="mdi-chevron-right"
            /></label>
          </div>
        </div>

        <div class="footer__button">
          <v-btn class="button button-purple-border" @click="oncloseModal">
            cancel
          </v-btn>
          <v-btn class="button button-orange" @click="onClickPropertyModalSave">
            save
          </v-btn>
        </div>
      </div>
    </template>
  </commonDialog>
</template>

<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import { onMounted, ref, computed } from "vue";
import { getProperties } from "@/core/services/properties.service";
import { getImageStringToImageURL, parseJSON } from "@/core/utils/common";
import { useStore } from "vuex";
import { PROPERTY_STORE } from "@/store/modules/property";
const emits = defineEmits("on-close");
const props = defineProps({
  propertyDetails: {
    type: Object,
  },
});
const paginationData = ref({
  currentPage: 0,
  totalPages: 0,
  totalPropertiesCount: 0,
});
const propertiesList = ref([]);
const isLoading = ref(false);
const store = useStore();
const selectedIndices = ref([]);
const currentSelectedProperty = ref(null);
const getPropertiesListing = async (page, limit) => {
  try {
    const response = await getProperties({
      page: page,
      limit: limit,
      search: "",
    });

    if (response) {
      paginationData.value.currentPage = response.page;
      paginationData.value.totalPages = response.totalPages;
      paginationData.value.totalPropertiesCount = response.totalCount;
    }

    if (response?.data?.length) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};
const oncloseModal = () => {
  emits("on-close");
};
const isPaginationAvailable = computed(() => {
  const { totalPropertiesCount } = paginationData.value;
  return totalPropertiesCount !== propertiesList.value.length;
});

const onLoadMore = async () => {
  const { currentPage, totalPages, totalPropertiesCount } =
    paginationData.value;
  if (isPaginationAvailable.value) {
    const data = await getPropertiesListing(
      currentPage,
      totalPropertiesCount + 6
    );
    propertiesList.value = [...data];
  }
};
// const isSelected = (index) => {
//   return selectedIndices.value.includes(index);
// };
// const toggleSelection = (propertyId) => {
//   const selectedIndex = selectedIndices.value.indexOf(propertyId);
//   if (selectedIndex === -1) {
//     selectedIndices.value.push(propertyId);
//   } else {
//     selectedIndices.value.splice(selectedIndex, 1);
//   }
// };
const toggleSelection = (property) => {
  currentSelectedProperty.value = property;
};
const onClickPropertyModalSave = async () => {
  const property = currentSelectedProperty.value;
  const data = {
    property,
    publicUrl:
      property?.propertyImage &&
      (await getImageStringToImageURL(property?.propertyImage)),
  };

  store.dispatch(`${PROPERTY_STORE}/setPropertyDetails`, {
    localData: data,
  });

  oncloseModal();
};
const getPropertyType = (property) => {
  return property?.propertyType
    ? property?.propertyType?.trim()
    : typeof property?.epcData === "string"
    ? parseJSON(property?.epcData)
      ? parseJSON(property?.epcData)["property-type"]
      : "NA"
    : property?.epcData
    ? property?.epcData["property-type"]
    : "NA";
};

const setPropertyList = async () => {
  try {
    isLoading.value = true;
    const data = await getPropertiesListing(1, 6);
    propertiesList.value = data;
  } catch (error) {
    console.log("");
  } finally {
    isLoading.value = false;
  }
};
onMounted(async () => {
  await setPropertyList();
  if (props.propertyDetails?.id) {
    currentSelectedProperty.value = props.propertyDetails;
  }
});
</script>
<script>
export default {
  name: "AddPropertyModal",
};
</script>

<style></style>
