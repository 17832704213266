<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        d="M8.41657 0.910645C12.6086 0.910645 16.0195 4.31141 16.0195 8.51373C16.0195 12.7056 12.6086 16.1167 8.41657 16.1167C4.22453 16.1167 0.813603 12.7056 0.813603 8.51373C0.813603 4.31142 4.22452 0.910645 8.41657 0.910645ZM4.33766 9.15075H7.8V12.6235C7.8 12.9625 8.07743 13.2399 8.41657 13.2399C8.7557 13.2399 9.03313 12.9625 9.03313 12.6235V9.15075H12.4955C12.8346 9.15075 13.112 8.88361 13.112 8.53431C13.112 8.19517 12.8346 7.91774 12.4955 7.91774H9.03313V4.45539C9.03313 4.11626 8.7557 3.83883 8.41657 3.83883C8.07743 3.83883 7.8 4.11626 7.8 4.45539V7.91774H4.33766C3.99852 7.91774 3.72109 8.19517 3.72109 8.53431C3.72109 8.88361 3.99852 9.15075 4.33766 9.15075Z"
        fill="#0C0F4A"
      />
      <path
        d="M15.5905 13.6479L18.7692 16.8266C19.3295 17.3875 19.3295 18.3 18.7692 18.8609C18.2073 19.4228 17.2947 19.4218 16.7349 18.8609L13.5562 15.6823C14.3403 15.1189 15.0271 14.4321 15.5905 13.6479Z"
        fill="#0C0F4A"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "MapZoomIcon",
};
</script>
