export const CREATE_WORKSTATION_PROPERTY_OWNER_ROUTE =
  "createWorkstationPropertyOwner";
export const CREATE_WORKSTATION_TRADE_OWNER_ROUTE =
  "createWorkstationTradeOwner";

export default [
  {
    path: "/create-workstation/property-owner",
    name: CREATE_WORKSTATION_PROPERTY_OWNER_ROUTE,
    component: () =>
      import(
        `@/modules/workstation/create-workstation/pages/CreateWorkStationPropertyOwnerPage.vue`
      ),
    meta: {
      requiresAuth: true,
      layout: "Authlayout",
    },
  },
  {
    path: "/create-workstation/trade-owner",
    name: CREATE_WORKSTATION_TRADE_OWNER_ROUTE,
    component: () =>
      import(
        `@/modules/workstation/create-workstation/pages/CreateWorkStationTradeOwnerPage.vue`
      ),
    meta: {
      requiresAuth: true,
      layout: "Authlayout",
    },
  },
];
