<template>
  <GeneralDialog
    @on-close="onCloseModal"
    borderRadius="30px"
    :paddingLarge="true"
    width="400px"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-w-full"
        >
          <TrashRedIcon />
          <Text variant="h4" textWeight="600">Delete the {{ type }}?</Text>
          <Text variant="p" textWeight="400"
            >The {{ type }} will be permanently deleted.</Text
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          label="Cancel"
          variant="tertiary"
          :disabled="state.loading"
          @click="onCloseModal"
        />
        <Button
          :disabled="state.loading"
          :isLoading="state.loading"
          label="Delete"
          :isActive="true"
          @click="onDelete"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import TrashRedIcon from "@/core/components/icons/TrashRedIcon.vue";
import documentConstants from "@/modules/documents/contants";
import { DOCUMENTS_STORE } from "@/store/modules/documents";
import { USER_STORE } from "@/store/modules/user";

const emits = defineEmits(["on-close", "on-success", "on-success-gallery"]);
const props = defineProps(["selectedDocument", "deleteType", "isGallery"]);

const store = useStore();
const route = useRoute();

const state = reactive({
  loading: false,
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const openedFolder = computed(
  () => store.getters[`${DOCUMENTS_STORE}/openedFolder`]
);
const fileList = computed(() => store.getters[`${DOCUMENTS_STORE}/fileList`]);
const folderList = computed(
  () => store.getters[`${DOCUMENTS_STORE}/folderList`]
);

const isTypeFolder = computed(
  () => props.deleteType === documentConstants.FOLDERS
);

const type = computed(() => {
  return isTypeFolder.value ? "Folder" : "File";
});

const setFolderList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFolderList`, params);
};
const setFileList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setFileList`, params);
};
const setDocumentList = async (params) => {
  await store.dispatch(`${DOCUMENTS_STORE}/setDocumentList`, params);
};

const getUserDocumentCalculatedSize = async (params) => {
  return await store.dispatch(
    `${DOCUMENTS_STORE}/getUserDocumentCalculatedSize`,
    params
  );
};

const getFolderList = async (options = {}) => {
  const { id: userId } = user.value;
  const params = { userId };
  const { parentId } = options;
  const hasParentParam = parentId || null;
  let folders = folderList.value;
  let files = fileList.value;
  const documentPromises = [];

  if (Object.keys(options).length) {
    params.options = options;
  }

  try {
    const userDocuments = await store.dispatch(
      `${DOCUMENTS_STORE}/getUserDocuments`,
      params
    );
    if (isTypeFolder.value) {
      folders = userDocuments.filter(
        (userDocument) =>
          userDocument.isDir === true &&
          userDocument.parentId === hasParentParam
      );

      folders.forEach((folderItem) => {
        documentPromises.push(
          getUserDocumentCalculatedSize({
            userId,
            userDocumentId: folderItem.id,
          })
        );
      });

      // Wait for all promises to resolve
      await Promise.all(documentPromises);

      if (documentPromises.length) {
        const documentFolderSizes = await Promise.all(documentPromises);
        folders = folders.map((item) => {
          const findFolder = documentFolderSizes.find((x) => x.id === item.id);

          return { ...item, ...findFolder };
        });
      }
    } else {
      files = userDocuments.filter(
        (userDocument) =>
          !userDocument.isDir && userDocument.parentId === hasParentParam
      );
      files = files.map((file) => {
        return { ...file, name: file.originalName, attachment: file.name };
      });
    }
  } catch (error) {
    const formError = "Error getting document list. Please try again.";
    // Note: Add notification logic
    console.log(formError);
  } finally {
    setFolderList(folders);
    setFileList(files);
    setDocumentList([...folders, ...files]);
  }
};

const onDelete = async () => {
  state.loading = true;
  const { id, name, parentId, isDir } = props.selectedDocument;
  const { id: userId } = user.value;

  const params = {
    userId,
    formData: {
      isDir,
      attachments: [{ id, attachment: name }], // TD: to support multiple
    },
  };
  const docParams = {};

  if (parentId) {
    docParams.parentId = parentId;
  }

  try {
    await store.dispatch(`${DOCUMENTS_STORE}/deleteUserDocuments`, params);
    await getFolderList(docParams);
    console.log("delete called 3");
    if (props.isGallery) {
      console.log("delete called 8");
      emits("on-success-gallery", docParams);
    }
    console.log("delete called 4");
    emits("on-success", "delete");
  } catch (error) {
    console.log("delete called 6");

    if (error?.response?.data?.statusCode === 403) {
      console.log("delete called 7");
      emits("on-success", "notDelete");
    }
  } finally {
    console.log("delete called 5");
    state.loading = false;
    onCloseModal();
  }
};

const onCloseModal = () => {
  emits("on-close");
};
</script>
