import { ProjectPhaseType } from "@/core/enums/ProjectsEnum";
import jobTemplateService from "@/core/services/job-template.service";
import ProjectEstimationService from "@/core/services/project-estimation.service";
import {
  filterSubContracts,
  getSubContractorPhases,
  getParentContractorPhases,
  getRandomColor,
  getTotalDuration,
  getCurrentConTractorPhases,
  getImageStringToImageURL,
} from "@/core/utils/common";
import { Commit, Dispatch } from "vuex";
import workstation, { WORKSTATION } from "../workstation";
import { USER_STORE } from "../user";
import estimationService from "@/core/services/estimation.service";
import tradeEstimateService from "@/core/services/trade-estimate.service";
import {
  AgreeTermsConsumerDisclosure,
  ContractorRequestStatusEnum,
} from "@/core/enums/estimateEnum";
import estimate from "@/core/constants/estimate";

export default {
  getMyJobTemplates({ commit }: { commit: Commit }, payload: any) {
    return jobTemplateService
      .getMyJobTemplates()
      .then((response) => {
        commit("setJobsTemplates", response?.data);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getJobTemplate(context: any, id: number) {
    return jobTemplateService
      .getJobTemplate(id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async createMyJobTemplate(context: any, formData: any) {
    try {
      await jobTemplateService.createMyJobTemplate(formData);
    } catch (error) {
      console.log(error);
    }
  },

  async updateMyJobTemplate(context: any, options: any) {
    const { id, formData } = options;
    try {
      return await jobTemplateService.updateMyJobTemplate(id, formData);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteMyJobTemplate(context: any, id: number) {
    try {
      return await jobTemplateService.deleteMyJobTemplate(id);
    } catch (error) {
      console.log("error ", error);
    }
  },
  setEstimateForm({ commit, rootGetters }: any, value: any) {
    commit("setEstimateForm", value);
  },

  setSelectedPhaseIndex({ commit }: any, value: any) {
    commit("setSelectedPhaseIndex", value);
  },

  setTemplateName({ commit }: any, value: any) {
    commit("setTemplateName", value);
  },

  setSelectedJobTemplate({ commit }: any, value: any) {
    commit("setSelectedJobTemplate", value);
  },

  addProjectStage({
    commit,
    rootGetters,
  }: {
    commit: Commit;
    rootGetters: any;
  }) {
    const newProjectStage = {
      id: null,
      name: "",
      stageType: "labour",
      totalDuration: 0,
      durationType: 1,
      stageCost: 0,
      projectStageTasks: [],
      isNew: true,
      isUsePhaseTotal: null,
      requiredDeposit: false,
      vat: 0,
      subTotal: "0",
      subTotalDisplay: "",
      note: "",
      startDate: null,
      color: getRandomColor(),
      phaseOrder: 0,
      attachments: [],
      user: {
        id: rootGetters[`${USER_STORE}/user`]?.id,
        firstName: rootGetters[`${USER_STORE}/user`]?.firstName,
        lastName: rootGetters[`${USER_STORE}/user`]?.lastName,
      },
      workStation: {
        id: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id,
        name: rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.name,
        profileImage:
          rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.profileImage,
      },
      grantAmount: 0,
      isPercentage: false,
      isGranted: false,
    };
    commit("addProjectStageMutation", newProjectStage);
  },

  setShowPhaseUploadAttachmentModal({ commit }: any, value: any) {
    commit("setShowPhaseUploadAttachmentModal", value);
  },

  setShowTaskUploadAttachmentModal({ commit }: any, value: any) {
    commit("setShowTaskUploadAttachmentModal", value);
  },

  setShowCreateTemplateConfirmationModal({ commit }: any, value: any) {
    commit("setShowCreateTemplateConfirmationModal", value);
  },

  setShowUseTemplateModal({ commit }: any, value: any) {
    commit("setShowUseTemplateModal", value);
  },

  setShowSubmitEstimateConfirmationModal({ commit }: any, value: any) {
    commit("setShowSubmitEstimateConfirmationModal", value);
  },

  setIsOnEstimateForm({ commit }: any, value: any) {
    commit("setIsOnEstimateForm", value);
  },

  setConfirmDeleteModal({ commit }: any, value: any) {
    commit("setConfirmDeleteModal", value);
  },

  setIsFormReady({ commit }: any, value: any) {
    commit("setIsFormReady", value);
  },

  setConfirmLoadTemplateModal({ commit }: any, value: any) {
    commit("setConfirmLoadTemplateModal", value);
  },

  setSelectedTemplateId({ commit }: any, value: any) {
    commit("setSelectedTemplateId", value);
  },

  setFormData({ commit }: any, value: any) {
    commit("setFormData", value);
  },

  setSelectDepositModal({ commit }: any, value: any) {
    commit("setSelectDepositModal", value);
  },

  setIsTemplateCreate({ commit }: any, value: any) {
    commit("setIsTemplateCreate", value);
  },

  setIsEstimateLoadedModified({ commit }: any, value: any) {
    commit("setIsEstimateLoadedModified", value);
  },
  setIsEstimateSubmit({ commit }: any, value: any) {
    commit("setIsEstimateSubmit", value);
  },

  setHasLoadedTemplate({ commit }: any, value: any) {
    commit("setHasLoadedTemplate", value);
  },

  setLoadedEstimate({ commit }: any, value: any) {
    commit("setLoadedEstimate", value);
  },

  setIsFormReadOnly({ commit }: any, value: any) {
    commit("setIsFormReadOnly", value);
  },

  setLockTemplateModifiedChecker({ commit }: any, value: any) {
    commit("setLockTemplateModifiedChecker", value);
  },

  setSelectedAssignMembers({ commit }: any, value: any) {
    commit("setSelectedAssignMembers", value);
  },

  setAssignMembersModal({ commit }: any, value: any) {
    commit("setAssignMembersModal", value);
  },

  setAssignMembers({ commit }: any, value: any) {
    commit("setAssignMembers", value);
  },

  setMemberList({ commit }: any, value: any) {
    commit("setMemberList", value);
  },

  setShowUpdateEstimateConfirmationModal({ commit }: any, value: any) {
    commit("setShowUpdateEstimateConfirmationModal", value);
  },
  setSuggestedStartDateIntially({ commit }: any) {
    commit("setSuggestedStartDateIntially");
  },

  async dragUpdateProjectStages({ commit }: any, updatedPhasess: any) {
    commit("dragUpdateProjectStages", updatedPhasess);
  },

  async submitProjectEstimate(
    {
      commit,
      state,
      dispatch,
    }: { commit: Commit; state: any; dispatch: Dispatch },
    payload: any
  ) {
    const response = (await ProjectEstimationService.createProjectEstimation(
      payload
    )) as any;

    if (response) {
      const formData = {
        ...state.formData,
        id: response.id,
      };
      dispatch("setFormData", formData);

      const estimateForm = {
        projectStages: [],
      } as any;
      if (
        response?.estimationPhaseData &&
        response?.estimationPhaseData.length
      ) {
        response?.estimationPhaseData.map((estimate: any, index: number) => {
          let estimateIndex = state.estimateForm.projectStages.findIndex(
            (data: any) => data.id === estimate.id
          );
          if (estimateIndex === -1) {
            estimateIndex = index;
          }

          const updateEstimateRecord = {
            ...state.estimateForm.projectStages[estimateIndex],
            id: estimate?.id,
            // projectStageTasks: [],
            estimationPhaseProperties:
              estimate?.estimationPhaseProperties &&
              estimate?.estimationPhaseProperties?.length
                ? estimate.estimationPhaseProperties.map((entry: any) => {
                    return {
                      id: entry.id || null,
                      propertyId: entry.property.id,
                    };
                  })
                : [],
          };

          if (
            estimate.estimationPhaseTasks &&
            estimate.estimationPhaseTasks.length
          ) {
            updateEstimateRecord.projectStageTasks =
              estimate.estimationPhaseTasks.map((task: any, index: number) => {
                let estimateTaskIndex = state.estimateForm.projectStages[
                  estimateIndex
                ].projectStageTasks.findIndex(
                  (data: any) => data.id === task.id
                );

                if (estimateTaskIndex === -1) {
                  estimateTaskIndex = index;
                }

                const phaseTaskRecord = {
                  ...state.estimateForm.projectStages[estimateIndex]
                    .projectStageTasks[index],
                  id: task?.id,
                  estimationPhaseTasksProperties:
                    task.estimationPhaseTasksProperties &&
                    task.estimationPhaseTasksProperties.length
                      ? task.estimationPhaseTasksProperties.map(
                          (entry: any) => {
                            return {
                              id: entry.id || null,
                              propertyId: entry.property.id,
                            };
                          }
                        )
                      : [],
                };

                return phaseTaskRecord;
                // updateEstimateRecord.projectStageTasks.push(phaseTaskRecord);
              });
            const currentTaskLength =
              state.estimateForm.projectStages[estimateIndex].projectStageTasks
                .length;
            if (
              currentTaskLength > updateEstimateRecord.projectStageTasks.length
            ) {
              updateEstimateRecord.projectStageTasks = [
                ...updateEstimateRecord.projectStageTasks,
                ...state.estimateForm.projectStages[
                  estimateIndex
                ].projectStageTasks.slice(
                  updateEstimateRecord.projectStageTasks.length
                ),
              ];
            }
          }

          estimateForm.projectStages.push(updateEstimateRecord);
        });
      }

      dispatch("setEstimateForm", estimateForm);
    }
    commit("setHoldEstimateAddTaskApi", false);
    return null;
  },
  async removeEstimationPhase({ commit }: { commit: Commit }, payload: any) {
    const { estimateId } = payload;
    if (estimateId) {
      return await ProjectEstimationService.deleteEstimationTask(estimateId);
    } else {
      return null;
    }
  },
  async removeEstimationPhaseTask(
    { commit }: { commit: Commit },
    payload: any
  ) {
    const { estimatePhaseTaskId } = payload;
    return await ProjectEstimationService.deleteEstimationPhaseTask(
      estimatePhaseTaskId
    );
  },

  async removeEstimationProperty({ commit }: { commit: Commit }, payload: any) {
    const { estimatePropertyId } = payload;
    return await ProjectEstimationService.deleteEstimationProperty(
      estimatePropertyId
    );
  },
  setEstimatePhaseCost({ commit }: { commit: Commit }, payload: any) {
    const { phaseIndex, newValue } = payload;
    commit("setEstimatePhaseCost", { phaseIndex, newValue });
  },

  async getProjectEstimateByProjectId(
    {
      commit,
      rootGetters,
      dispatch,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    payload: { projectId: number; filterStatus: boolean }
  ) {
    const response =
      (await ProjectEstimationService.getProjectEstimateByProjectId(
        payload.projectId,
        payload.filterStatus
      )) as any;
    if (response.length) {
      // response[0]?.agreeTermsConsumerDisclosure ===
      // AgreeTermsConsumerDisclosure.REJECTED ||
      if (
        response[0]?.agreeTermsConsumerDisclosure ===
        AgreeTermsConsumerDisclosure.NOT_ADDED
      ) {
        dispatch("setLockTemplateModifiedChecker", false);
      } else dispatch("setLockTemplateModifiedChecker", true);

      dispatch("setFormData", {
        suggestedStartDate: response[0]?.suggestedStartDate,
        grantType: response[0]?.grantType,
        grantName: response[0]?.grantName,
        grantAmount: response[0]?.grantAmount,
        id: response[0]?.id,
      });

      commit("setInvitedContractorsList", response[0]?.inviteContractors);
      commit("setTotalPhases", response[0]?.estimationPhase);
      commit("setEstimateResponse", response[0]);

      const estimateForm = {
        projectStages: [],
      } as any;

      const currentUserWorkStationId =
        rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id;

      const subContractorPhases = getSubContractorPhases(
        currentUserWorkStationId,
        response[0]?.inviteContractors,
        response[0]?.estimationPhase
      );
      const parentContractorPhases = getParentContractorPhases(
        currentUserWorkStationId,
        response[0]?.inviteContractors,
        response[0]?.estimationPhase
      );
      const currentConTractorPhases = getCurrentConTractorPhases(
        currentUserWorkStationId,
        response[0]?.estimationPhase
      );

      commit("setSubContractorsPhases", subContractorPhases);
      commit("setparentContractorPhases", parentContractorPhases);

      const finalArray = [
        ...subContractorPhases,
        ...parentContractorPhases,
        ...currentConTractorPhases,
      ];
      const estimationPhase = finalArray;

      estimationPhase.forEach((phase: any) => {
        const estimatePhaseRecord = {
          id: phase?.id,
          stageType:
            phase.phaseType === ProjectPhaseType.LABOUR
              ? "labour"
              : "materials",
          subTotal: phase?.subTotal,
          phaseOrder: phase?.phaseOrder,
          phaseStatus: phase?.phaseStatus,
          name: phase?.name,
          durationType: phase?.durationType,
          startDate: phase?.phaseStartDate,
          totalDuration: getTotalDuration(
            phase?.phaseStartDate,
            phase?.phaseEndDate,
            phase?.durationType
          ),
          vat: phase?.vat,
          projectStageTasks: [] as object[],
          color: phase?.color,
          estimationPhaseProperties: [] as any,
          assignPhaseWorkStation: phase?.assignPhaseWorkStation,
          user: phase?.user,
          workStation: phase?.workStation,
          attachments: phase?.estimatePhaseAttachment,
          acceptedBy: phase?.acceptedBy,
          grantAmount: phase?.grantAmount,
          isPercentage: phase?.isPercentage,
          isGranted: phase?.isGranted,
        };
        phase?.estimationPhaseTasks.forEach((subTask: any) => {
          const estimatePhaseSubTaskRecords = {
            id: subTask?.id,
            name: subTask?.name,
            taskCost: subTask?.taskCost && subTask?.taskCost.toString(),
            durationType: subTask?.durationType,
            duration: getTotalDuration(
              subTask?.phaseTaskStartDate,
              subTask?.phaseTaskEndDate,
              subTask?.durationType
            ),
            materialUnit: subTask?.materialUnit,
            estimationPhaseTasksProperties: [] as any,
            assignedPhaseTo: subTask?.assignedPhaseTo,
          };
          subTask.estimationPhaseTasksProperties.forEach((property: any) => {
            estimatePhaseSubTaskRecords?.estimationPhaseTasksProperties.push({
              id: property.id,
              propertyId: property.property.id,
            });
          });
          estimatePhaseRecord.projectStageTasks.push(
            estimatePhaseSubTaskRecords
          );
        });
        estimateForm.projectStages.push(estimatePhaseRecord);
        phase.estimationPhaseProperties.forEach((property: any) => {
          estimatePhaseRecord?.estimationPhaseProperties?.push({
            id: property.id,
            propertyId: property.property.id,
          });
        });
      });
      dispatch("setEstimateForm", estimateForm);
    } else {
      dispatch("resetFormAndData");
    }

    return response;
  },
  async getProjectEstimateByEstimateId(
    {
      commit,
      dispatch,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    estimationId: number
  ) {
    const response =
      (await ProjectEstimationService.getProjectEstimateByEstimationId(
        estimationId
      )) as any;

    if (response) {
      // response.agreeTermsConsumerDisclosure ===
      //   AgreeTermsConsumerDisclosure.REJECTED ||
      if (
        response?.agreeTermsConsumerDisclosure ===
        AgreeTermsConsumerDisclosure.NOT_ADDED
      ) {
        dispatch("setLockTemplateModifiedChecker", false);
      } else dispatch("setLockTemplateModifiedChecker", true);

      commit("setInvitedContractorsList", response?.inviteContractors);
      commit("setTotalPhases", response?.estimationPhase);
      commit("setEstimateResponse", response);

      dispatch("setFormData", {
        suggestedStartDate: response?.suggestedStartDate,
        grantType: response?.grantType,
        grantName: response?.grantName,
        grantAmount: response?.grantAmount,
        project: response?.project,
        id: response?.id,
      });

      const estimateForm = {
        projectStages: [],
      } as any;

      const currentUserWorkStationId =
        rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id;

      const subContractorPhases = getSubContractorPhases(
        currentUserWorkStationId,
        response?.inviteContractors,
        response?.estimationPhase
      );
      const parentContractorPhases = getParentContractorPhases(
        currentUserWorkStationId,
        response?.inviteContractors,
        response?.estimationPhase
      );
      const currentConTractorPhases = getCurrentConTractorPhases(
        currentUserWorkStationId,
        response?.estimationPhase
      );
      commit("setSubContractorsPhases", subContractorPhases);
      commit("setparentContractorPhases", parentContractorPhases);

      const finalArray = [
        ...subContractorPhases,
        ...parentContractorPhases,
        ...currentConTractorPhases,
      ];
      const estimationPhase = finalArray;

      estimationPhase.forEach((phase: any) => {
        const estimatePhaseRecord = {
          id: phase?.id,
          stageType:
            phase.phaseType === ProjectPhaseType.LABOUR
              ? "labour"
              : "materials",
          subTotal: phase?.subTotal,
          phaseOrder: phase?.phaseOrder,
          name: phase?.name,
          durationType: phase?.durationType,
          startDate: phase?.phaseStartDate,
          totalDuration: getTotalDuration(
            phase?.phaseStartDate,
            phase?.phaseEndDate,
            phase?.durationType
          ),
          vat: phase?.vat,
          user: phase?.user,
          workStation: phase?.workStation,
          projectStageTasks: [] as object[],
          properties: [],
          color: phase?.color,
          assignPhaseWorkStation: phase?.assignPhaseWorkStation,
          phaseStatus: phase?.phaseStatus,
          acceptedBy: phase?.acceptedBy,
          grantAmount: phase?.grantAmount,
          isPercentage: phase?.isPercentage,
          isGranted: phase?.isGranted,
        };
        phase?.estimationPhaseTasks.forEach((subTask: any) => {
          const estimatePhaseSubTaskRecords = {
            id: subTask?.id,
            name: subTask?.name,
            taskCost: subTask?.taskCost,
            durationType: subTask?.durationType,
            materialUnit: subTask?.materialUnit,
            duration: getTotalDuration(
              subTask?.phaseTaskStartDate,
              subTask?.phaseTaskEndDate,
              subTask?.durationType
            ),
            estimationPhaseTasksProperties: [] as any,
            assignedPhaseTo: subTask?.assignedPhaseTo,
          };
          subTask.estimationPhaseTasksProperties.forEach((property: any) => {
            estimatePhaseSubTaskRecords?.estimationPhaseTasksProperties.push({
              propertyId: property.property.id,
            });
          });
          estimatePhaseRecord.projectStageTasks.push(
            estimatePhaseSubTaskRecords
          );
        });
        estimateForm.projectStages.push(estimatePhaseRecord);
      });

      dispatch("setEstimateForm", estimateForm);
    } else {
      dispatch("resetFormAndData");
    }

    return response;
  },
  async getProjectInvitation(
    {
      commit,
      dispatch,
      rootGetters,
    }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
    payload: any
  ) {
    try {
      const response = (await estimationService.getProjectInvitation(
        payload
      )) as any;
      if (response.length) {
        const filterRes = response.filter(
          (invite: any) =>
            invite.requestStatus !== ContractorRequestStatusEnum.REJECTED
        );
        if (filterRes.length) {
          commit("setSubContractor", true);
        } else {
          commit("setSubContractor", false);
        }

        commit("setProjectEstimationInvitation", response);
      } else {
        commit("setSubContractor", false);
        dispatch("resetFormAndData");
      }
      return response;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  },
  setEstimatePhaseSubTotal({ commit }: { commit: Commit }, payload: any) {
    commit("setEstimatePhaseSubTotal", payload);
  },
  setEstimatePhaseDate({ commit }: { commit: Commit }, payload: any) {
    commit("setEstimatePhaseDate", payload);
  },
  setEstimatePhase({ commit }: { commit: Commit }, payload: any) {
    commit("setEstimatePhase", payload);
  },
  setPhaseTaskProperties({ commit }: { commit: Commit }, payload: any) {
    commit("setPhaseTaskProperties", payload);
  },
  setPhaseProperties({ commit }: { commit: Commit }, payload: any) {
    commit("setPhaseProperties", payload);
  },

  setPhaseAttachments({ commit }: { commit: Commit }, payload: any) {
    commit("setPhaseAttachments", payload);
  },

  resetFormAndData({ commit, getters }: any) {
    commit("setEstimateForm", getters.defaultEstimateForm);
    const formData = {
      suggestedStartDate: null,
      grantType: null,
      grantName: "",
      grantAmount: 0,
      project: null,
      id: null,
    };
    commit("setFormData", formData);
    commit("clearAllErrors", true);
    commit("setInvitedContractorsList", []);
    commit("setTotalPhases", []);
  },
  setProjectStage({ commit }: { commit: Commit }, payload: any) {
    commit("setProjectStage", payload);
  },
  async setSiteRequest({ commit }: { commit: Commit }, payload: any) {
    const { projectEstimateId, projectId, formData } = payload;
    const response = await tradeEstimateService.visitSiteRequest(
      projectEstimateId,
      projectId,
      formData
    );
    return response;
  },

  async setSiteRequestWithoutEstimate(
    { commit }: { commit: Commit },
    payload: any
  ) {
    const { projectId, formData } = payload;
    const response = await tradeEstimateService.visitSiteRequestWithoutEstimate(
      projectId,
      formData
    );
    return response;
  },

  async getSiteRequestAll({ commit }: { commit: Commit }, payload: any) {
    const { projectId, status, wsID } = payload;
    const response = await tradeEstimateService.getSiteRequest(
      projectId,
      status,
      wsID
    );
    return response;
  },
  async updateSiteRequest({ commit }: { commit: Commit }, payload: any) {
    const { siteVisitId, formData } = payload;
    const response = await tradeEstimateService.updateSiteRequest(
      siteVisitId,
      formData
    );
    return response;
  },

  setSubmitProjectEstimation({ commit }: any, value: any) {
    commit("setSubmitProjectEstimation", value);
  },

  validateForm(
    { state, commit, rootGetters }: any,
    payload: { isDIYProjectType: false }
  ) {
    const isValid = { flag: true, errorMessage: "" };
    if (!state.formData?.suggestedStartDate) {
      commit("setFormError", {
        field: "startDate",
        value: true,
      });
      isValid.flag = false;
      isValid.errorMessage = "Project Estimate Start Date required";
      return isValid;
    } else {
      commit("setFormError", {
        field: "startDate",
        value: false,
      });
    }

    if (!state.formData?.totalCost && payload?.isDIYProjectType) {
      commit("setFormError", {
        field: "totalCost",
        value: true,
      });

      isValid.flag = false;
      isValid.errorMessage = "totalCost required";
      return isValid;
    }
    const grantAmount = state.formData?.grantAmount;
    const totalLabourTask = state.estimateForm.projectStages.filter(
      (phase: any) => phase.stageType === "labour"
    ).length;

    for (
      let phaseIndex = 0;
      phaseIndex < state.estimateForm.projectStages.length;
      phaseIndex++
    ) {
      const stage = state.estimateForm.projectStages[phaseIndex];

      // Check if the active user workstation matches the phase's workstation
      if (
        (stage?.assignPhaseWorkStation?.id &&
          stage?.assignPhaseWorkStation?.id ===
            rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id) ||
        (!stage?.assignPhaseWorkStation?.id &&
          stage?.workStation?.id ===
            rootGetters[`${WORKSTATION}/activeUserWorkstation`]?.id)
      ) {
        // Set validation rules based on project type
        const validationRules = payload?.isDIYProjectType
          ? [
              { field: "name", message: "Name is required" },
              { field: "name", message: "Name is too long" },
            ]
          : [
              { field: "name", message: "Name is required" },
              { field: "name", message: "Name is too long" },
              { field: "startDate", message: "Start date is required" },
              { field: "totalDuration", message: "Total duration is required" },
              { field: "subTotal", message: "Subtotal is required" },
            ];

        // Iterate through validation rules for the stage
        for (let i = 0; i < validationRules.length; i++) {
          const { field, message } = validationRules[i];

          if (field === "subTotal" || field === "totalDuration")
            stage[field] = parseFloat(stage[field]);
          if (stage.stageType === "labour") {
            if (!stage[field]) {
              if (field === "name" && !stage[field]) {
                commit("setError", {
                  phaseIndex,
                  field,
                  message: "Name is required",
                });
                isValid.errorMessage = "Name is required";
                isValid.flag = false;
                return isValid;
              } else {
                //subTotal phases
                if (field === "subTotal" && stage.projectStageTasks.length) {
                  continue;
                } else {
                  commit("setError", { phaseIndex, field, message });
                  isValid.errorMessage = message;
                  isValid.flag = false;
                  return isValid;
                }
              }
            } else {
              if (field === "name" && stage[field].length >= 255) {
                commit("setError", {
                  phaseIndex,
                  field,
                  message,
                });

                isValid.flag = false;
                isValid.errorMessage = "Name is too long";
                return isValid;
              } else {
                commit("setError", { phaseIndex, field, message: "" });
              }

              if (field === "subTotal" && grantAmount) {
                //grant Amount Fix
                const phaseSubTotal = parseFloat(stage.subTotal);
                const remainingAmount =
                  phaseSubTotal - grantAmount / totalLabourTask;

                if (remainingAmount < 0) {
                  // isValid.flag = false;
                  // isValid.errorMessage = "Grant Amount is not valid";
                  commit("setFormError", {
                    field: "subTotalValidError",
                    value: true,
                  });

                  commit("setError", {
                    phaseIndex,
                    field,
                    message: `The calculated amount for phase '${
                      stage?.name || "Unnamed Phase"
                    }' is negative.`,
                  });

                  isValid.flag = false;
                  isValid.errorMessage = `The calculated amount for phase '${
                    stage?.name || "Unnamed Phase"
                  }' is negative.`;
                  return isValid;
                } else {
                  commit("setError", {
                    phaseIndex,
                    field,
                    message: "",
                  });
                }
              }
            }
          } else {
            if (
              field === "name" ||
              field === "startDate" ||
              field === "totalDuration"
            ) {
              continue;
            }

            if (field === "subTotal") {
              if (!stage[field]) {
                if (stage.projectStageTasks.length) {
                  continue;
                }
                commit("setError", { phaseIndex, field, message });
                isValid.flag = false;
                isValid.errorMessage = message;
              }
              return isValid;
            } else {
              commit("setError", { phaseIndex, field, message: message });
            }
          }
        }

        for (
          let subTaskIndex = 0;
          subTaskIndex < stage.projectStageTasks.length;
          subTaskIndex++
        ) {
          const subTask = stage.projectStageTasks[subTaskIndex];

          const subTaskValidationRules = payload?.isDIYProjectType
            ? [
                { field: "name", message: "Subtask name is required" },
                { field: "name", message: "Subtask name is too long" },
              ]
            : [
                { field: "name", message: "Subtask name is required" },
                { field: "name", message: "Subtask name is too long" },
                { field: "taskCost", message: "Amount is required" },
                { field: "duration", message: "Duration is required" },
                { field: "materialUnit", message: "Material unit is required" },
              ];

          // Iterate through the validation rules for the subtask
          for (let j = 0; j < subTaskValidationRules.length; j++) {
            const { field, message } = subTaskValidationRules[j];

            if (
              field === "taskCost" ||
              field === "duration" ||
              field === "materialUnit"
            )
              subTask[field] = parseFloat(subTask[field] ? subTask[field] : 0);
            if (stage.stageType === "labour") {
              if (
                !["materialUnit", "duration"].includes(field) &&
                !subTask[field]
              ) {
                if (field === "name" && !subTask[field]) {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message: "Subtask name is required",
                  });
                  isValid.errorMessage = "Subtask name is required";
                } else {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message,
                  });
                  isValid.errorMessage = message;
                }

                isValid.flag = false;
                return isValid;
              } else {
                if (field === "name" && subTask[field].length >= 255) {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message,
                  });

                  isValid.flag = false;
                  isValid.errorMessage = message;
                  return isValid;
                } else {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message: "",
                  });
                }
              }
              if (field === "duration") {
                if (subTask[field]) {
                  let mainTaskDays = 0;
                  if (stage.durationType === estimate.WEEKS) {
                    mainTaskDays = stage.totalDuration * 7;
                  } else {
                    mainTaskDays = stage.totalDuration;
                  }
                  if (parseFloat(subTask[field]) > mainTaskDays) {
                    commit("setError", {
                      phaseIndex,
                      subTaskIndex,
                      field,
                      message:
                        "Your task durations are too long. Please keep the total within the specified limit.",
                    });
                    isValid.flag = false;
                    isValid.errorMessage =
                      "Your task durations are too long. Please keep the total within the specified limit.";
                    return isValid;
                  } else {
                    commit("setError", {
                      phaseIndex,
                      subTaskIndex,
                      field,
                      message: "",
                    });
                  }
                } else {
                  commit("setError", {
                    phaseIndex,
                    subTaskIndex,
                    field,
                    message,
                  });

                  isValid.flag = false;
                  isValid.errorMessage = message;

                  return isValid;
                }
              }
            } else {
              if (!subTask[field] && field !== "duration") {
                commit("setError", {
                  phaseIndex,
                  subTaskIndex,
                  field,
                  message,
                });
                isValid.errorMessage = message;
                isValid.flag = false;
                return isValid;
              } else {
                commit("setError", {
                  phaseIndex,
                  subTaskIndex,
                  field,
                  message: "",
                });
              }
            }
          }
        }
      }
    }

    return isValid;
  },
  clearErrorField({ commit }: any, { phaseIndex, subTaskIndex, field }: any) {
    commit("clearErrors", { phaseIndex, subTaskIndex, field });
  },
  setError({ commit }: any, { phaseIndex, subTaskIndex, field, message }: any) {
    commit("setError", { phaseIndex, subTaskIndex, field, message });
  },

  setFormError({ commit }: any, { field, value }: any) {
    commit("setFormError", { field, value });
  },
  async updateEstimatePhase({ commit }: { commit: Commit }, payload: any) {
    const { estimateId, phaseId, status } = payload;
    try {
      await ProjectEstimationService.updateEstimatePhase(estimateId, phaseId, {
        status,
      });
    } catch (error) {
      console.log();
    }
  },

  resetErrorFields({ commit }: { commit: Commit }) {
    commit("clearAllErrors", true);
  },

  async setEstimatePhaseNotes({ commit }: { commit: Commit }, phaseId: number) {
    try {
      if (!phaseId) return [];
      const response: any = await tradeEstimateService.getEstimatePhaseTaskNote(
        phaseId
      );
      for (const note of response) {
        note.user["profileImageUrl"] = await getImageStringToImageURL(
          note?.user?.userPublicProfile?.profileImage
        );
      }
      commit("setEstimatePhaseNotes", { phaseId, data: response });
      return response;
    } catch (error) {
      return [];
    }
  },

  async clearJobTemplateResponse({ commit, rootState }: any) {
    commit("clearJobTemplateResponse", rootState);
  },
};
