<template>
  <section class="estimate-form__wrapper">
    <el-form
      :model="estimateForm.projectStages[phaseIndex]"
      class="estimation__form"
    >
      <div class="estimate__header">
        <div class="estimate__header__inner">
          <el-form-item class="estimate__selector">
            <NoBgSelect
              :disabled="isFormReadOnly"
              v-model="estimateForm.projectStages[phaseIndex].stageType"
              @change="onChangeStageType"
              class=""
            >
              <template #option>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </template>
            </NoBgSelect>
            <div class="tooltip__wrapper">
              <v-tooltip
                max-width="242px"
                activator="parent"
                location="bottom"
                class="estimate_tooltip"
              >
                Task type
              </v-tooltip>
              <InfoIcon />
            </div>
          </el-form-item>
          <el-form-item
            class="estimate__field"
            :class="{ 'is-error': !!errors.name }"
          >
            <NoBgInput
              :readonly="isFormReadOnly"
              v-model="estimateForm.projectStages[phaseIndex].name"
              placeholder="Phase Name e.g Preparation"
              @change="updateLabourPhaseEstimation"
            />
          </el-form-item>
        </div>
        <div v-if="!isDIYProjectType" class="estimate__phase">
          <!-- <div class="left_block"> -->
          <el-form-item
            :class="{ 'is-error': !!errors.startDate }"
            class="estimate__phase__date"
          >
            <NoBgDatePicker
              :readonly="isFormReadOnly"
              v-model="estimateForm.projectStages[phaseIndex].startDate"
              placeholder="Phase start date"
              :startDate="formData.suggestedStartDate || null"
              :isHistoricPhase="true"
              @change="updateLabourPhaseEstimation"
            />
          </el-form-item>
          <el-form-item
            :class="{ 'is-error': !!errors.totalDuration }"
            class="estimate__phase__days"
          >
            <NoBgInput
              type="number"
              :readonly="isFormReadOnly"
              placeholder="Duration"
              min="0"
              v-model="estimateForm.projectStages[phaseIndex].totalDuration"
              @input="updateLabourPhaseEstimation"
              :formatter="
                (value) => {
                  if (value === '' || value === null) return '0';
                  return `${value}`.replace(/^0+/, '') || '0';
                }
              "
              :parser="(value) => value.replace(/^0+/, '') || '0'"
            />
            <NoBgSelect
              :disabled="isFormReadOnly"
              v-model="estimateForm.projectStages[phaseIndex].durationType"
              class=""
              @change="updateLabourPhaseEstimation"
            >
              <template #option>
                <el-option
                  v-for="item in durationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </template>
            </NoBgSelect>
          </el-form-item>
          <!-- </div>
          <div class="right_block"> -->
          <el-form-item
            class="estimate__phase__subtotal"
            :class="{ 'is-error': !!errors.subTotal }"
          >
            <div class="phase__subtotal">
              <Text variant="p" class="tw-w-[30px]">£ &nbsp;</Text>
              <NoBgInput
                placeholder="£ Phase subtotal"
                :class="{ errormsg: validCost }"
                @keypress="
                  numChecker(
                    $event,
                    estimateForm.projectStages[phaseIndex].subTotal
                  )
                "
                v-model="estimateForm.projectStages[phaseIndex].subTotal"
                @input="handleSubTotal($event)"
                :readonly="isFormReadOnly"
                :formatter="
                  (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                :parser="(value) => value.replace(/^0+(?=\d)|\$|,/g, '')"
                :disabled="isAddNewTask"
              />
            </div>
          </el-form-item>
          <!-- <el-form-item class="estimate__phase__tax">
              <NoBgInput
                :readonly="true"
                placeholder="VAT"
                v-model="state.vatComputation"
                @change="updateLabourPhaseEstimation"
              />
              <NoBgSelect
                :disabled="isFormReadOnly"
                v-model="estimateForm.projectStages[phaseIndex].vat"
                class=""
                @change="handlePhaseVAT"
              >
                <template #option>
                  <el-option
                    v-for="item in vatOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </template>
              </NoBgSelect>
            </el-form-item> -->
          <!-- </div> -->
        </div>
        <el-form-item v-if="!isDIYProjectType" class="phase__cost__wrapper">
          <label>Total phase cost:</label>
          <div class="phase__cost">
            <Text variant="p"
              >{{ formattedCurrency(totalPhaseEstimateCost) }}
            </Text>
          </div>
        </el-form-item>
      </div></el-form
    >
    <div class="estimate__subtask">
      <div class="estimate__subtask__title">
        <h6>Detailed Tasks</h6>
        <div class="tooltip__wrapper">
          <v-tooltip
            max-width="242px"
            activator="parent"
            location="bottom"
            class="estimate_tooltip"
          >
            Please describe details of any tasks (steps) involved with your
            project.
          </v-tooltip>
          <InfoIcon />
        </div>
      </div>
      <HistoricalTaskForm
        v-for="(task, ii) in estimateForm.projectStages[phaseIndex]
          .projectStageTasks"
        :taskIndex="ii"
        :key="ii"
        :phaseIndex="phaseIndex"
        :isDIYProjectType="isDIYProjectType"
        :task="task"
        @on-update-labour-task="updateLabourPhaseEstimation"
      />
    </div>
    <div class="">
      <Button
        variant="custom"
        :isCapitalize="true"
        :label="taskActionButtonLabel"
        class=""
        :ripple="false"
        @click="addTask"
        fontSize="12px"
        fontWeight="400"
        letterSpacing="0"
        activeBgColor="#FFF"
        activeFontColor="#0C0F4A"
        width="auto"
        min-height="auto"
        height="auto"
      >
        <template #prefix>
          <PlusFilledYellowIcon />
        </template>
      </Button>
    </div>
  </section>
  <ConfirmPhaseTotal
    v-if="isShowConfirmModal"
    @on-close="onCloseModal"
    @on-confirm="onConfirmModal"
  />
</template>
<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import PlusFilledYellowIcon from "@/core/components/icons/PlusFilledYellowIcon.vue";
import NoBgInput from "@/core/components/ui/inputs/NoBgInput.vue";
import NoBgSelect from "@/core/components/ui/inputs/NoBgSelect.vue";
import NoBgDatePicker from "@/core/components/ui/inputs/NoBgDatePicker.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import TaskForm from "@/modules/jobs/components/estimates/form/TaskForm.vue";
import HistoricalTaskForm from "@/modules/jobs/components/estimates/form/HistoricalTaskForm.vue";

import estimate from "@/core/constants/estimate";
import { isNumber } from "@/core/helpers/number.helper";
import CurrencyFilter from "@/filters/currency.filter";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { getRandomColor } from "@/core/utils/common";
import moment from "moment";
import workstation, { WORKSTATION } from "@/store/modules/workstation";
import { USER_STORE } from "@/store/modules/user";
import ConfirmPhaseTotal from "@/modules/jobs/components/modal/ConfirmPhaseTotal.vue";
import { ElMessage } from "element-plus";

const store = useStore();

const props = defineProps({
  phaseIndex: Number,
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
});

const MAX_LIMIT = 99999999;
const options = [
  {
    value: estimate.LABOUR,
    label: "Labour",
  },
  {
    value: estimate.MATERIAL,
    label: "Materials",
  },
];

const durationOptions = [
  {
    value: estimate.DAYS,
    label: "Days",
  },
  {
    value: estimate.WEEKS,
    label: "Weeks",
  },
];

const vatOptions = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 0.05,
    label: "5%",
  },
  {
    value: 0.2,
    label: "20%",
  },
];

const state = reactive({
  vatComputation: null,
});

const isShowConfirmModal = ref(false);
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);

const isFormReadOnly = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isFormReadOnly`]
);

const taskActionButtonLabel = computed(() => {
  return estimateForm.value.projectStages[props.phaseIndex].projectStageTasks
    .length
    ? "Add another task"
    : "Add task";
});

const isAddNewTask = computed(() => {
  return estimateForm.value.projectStages[props.phaseIndex].projectStageTasks
    .length;
});
const numChecker = (event, value) => {
  return isNumber(event, value);
};

const formattedCurrency = (value) => {
  return CurrencyFilter.formatToCurrency(value);
};

const totalPhaseEstimateCost = computed(() => {
  const phase = estimateForm.value.projectStages[props.phaseIndex];
  const newValue = phase.vat
    ? parseFloat(phase.subTotal) + parseFloat(phase.subTotal) * phase.vat
    : phase.subTotal;
  return newValue;
});

const handlePhaseVAT = () => {
  updateLabourPhaseEstimation();
};
const addTask = async () => {
  if (!isFormReadOnly.value) {
    const tasks =
      estimateForm.value.projectStages[props.phaseIndex].projectStageTasks;
    if (!tasks.length) {
      isShowConfirmModal.value = true;
      return;
    }

    tasks.push({
      id: null,
      name: "",
      amount: "0",
      amountToDisplay: "",
      duration: "0",
      durationType: 1,
      total: 0,
      startDate: null,
      endDate: null,
      note: "",
      taskCost: "0",
      estimationPhaseTasksProperties: [],
    });

    updateLabourPhaseEstimation();
  }
};

const onDeletePhase = async (id) => {
  if (id) {
    try {
      await store.dispatch(`${JOB_TEMPLATE_STORE}/removeEstimationPhase`, {
        estimateId: id,
      });
    } catch (error) {
      console.log();
    }
  }
};
const calculateEndDate = (durationType, totalDuration, startDate) => {
  let duration = parseInt(totalDuration);
  if (duration < 1 || !startDate) return;
  const endDate = moment(new Date(startDate))
    .add(totalDuration, durationType === 1 ? "days" : "weeks")
    .format("YYYY-MM-DD");

  estimateForm.value.projectStages[props.phaseIndex].endDate = endDate;
};
const updateLabourPhaseEstimation = async () => {
  const { name, durationType, startDate, totalDuration, subTotal } =
    estimateForm.value.projectStages[props.phaseIndex];

  if (name) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "name",
    });
  }

  if (startDate) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "startDate",
    });
  }
  if (!formData.value.suggestedStartDate && startDate) {
    estimateForm.value.projectStages[props.phaseIndex].startDate = null;
    ElMessage.error("Please First Select Proposed Project Start Date");
  }

  if (totalDuration) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "totalDuration",
    });
  }
  if (subTotal) {
    await store.dispatch(`${JOB_TEMPLATE_STORE}/clearErrorField`, {
      phaseIndex: props.phaseIndex,
      field: "subTotal",
    });
  }
  calculateEndDate(durationType, totalDuration, startDate);
};
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const onChangeStageType = async (value) => {
  const optionType = value == "labour" ? "labour" : "materials";
  const projectStage = {
    id: null,
    name: "",
    stageType: optionType,
    totalDuration: 0,
    durationType: 1,
    stageCost: 0,
    projectStageTasks: [],
    requiredDeposit: false,
    vat: 0,
    subTotal: "0",
    subTotalDisplay: "",
    note: "",
    startDate: null,
    phaseOrder: estimateForm.value.projectStages[props.phaseIndex].phaseOrder,
    endDate: null,
    estimationPhaseProperties: [],
    color: getRandomColor(),
    attachments: [],
    user: { id: user.value?.id },
    workStation: { id: activeUserWorkstation.value?.id },
  };

  onDeletePhase(estimateForm.value.projectStages[props.phaseIndex]?.id);
  await store.dispatch(`${JOB_TEMPLATE_STORE}/setProjectStage`, {
    phaseIndex: props.phaseIndex,
    defaultProjectStageFormValue: projectStage,
  });
};
const handleSubTotal = (value) => {
  if (value) {
    if (!validCost.value) {
      estimateForm.value.projectStages[props.phaseIndex].subTotal = value;
      updateLabourPhaseEstimation();
    }
  } else {
    estimateForm.value.projectStages[props.phaseIndex].subTotal = "0";
  }
};

const validCost = computed(() => {
  const cost = estimateForm.value.projectStages[props.phaseIndex].subTotal;
  if (cost > MAX_LIMIT) {
    return true;
  }
  return false;
});

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const errors = computed(() => {
  if (
    !store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`][
      props.phaseIndex
    ]
  ) {
    return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
  }
  return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`][
    props.phaseIndex
  ];
});

const onCloseModal = () => {
  isShowConfirmModal.value = false;
};
const onConfirmModal = async () => {
  const tasks =
    estimateForm.value.projectStages[props.phaseIndex].projectStageTasks;
  estimateForm.value.projectStages[props.phaseIndex].subTotal = "0";
  await store.dispatch(`${JOB_TEMPLATE_STORE}/setEstimatePhaseCost`, {
    phaseIndex: props.phaseIndex,
    newValue: 0,
  });

  tasks.push({
    id: null,
    name: "",
    amount: "0",
    amountToDisplay: "",
    duration: "0",
    durationType: 1,
    total: 0,
    startDate: null,
    endDate: null,
    note: "",
    taskCost: "0",
    estimationPhaseTasksProperties: [],
  });

  updateLabourPhaseEstimation();

  onCloseModal();
};
onMounted(() => {
  estimateForm.value.projectStages[props.phaseIndex].phaseOrder =
    props.phaseIndex;

  // isShowConfirmModal.value = isAddNewTask.value ? false : true;
});
</script>
<style lang="scss" scoped>
.estimate-form__wrapper {
  padding: 16px 24px;
  background-color: rgba($white, 1);
  .estimation__form {
    padding: 0 0 24px;
    border-bottom: 1px solid rgba($blueDark, 0.05);
    .estimate__header {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      flex-direction: column;
      .estimate__header__inner {
        display: flex;
        align-items: center;
        gap: 32px;
        @include respond(md) {
          gap: 16px;
          width: 100%;
          max-width: 100%;
        }
        .el-form-item {
          margin: 0;
          :deep(.el-form-item__content) {
            border-bottom: 1px solid rgba($blueDark, 0.2);
            .el-input {
              .el-input__wrapper {
                .el-input__inner {
                  @include fluidFont(12, 12, 2.3);
                  font-weight: 500;
                  color: rgba($blueDark, 1);
                  &::placeholder {
                    @include fluidFont(12, 12, 2.3);
                    font-weight: 500;
                    color: rgba($blueDark, 0.5);
                  }
                }
              }
            }
          }
        }
        .estimate__selector {
          width: 100px;
          max-width: 100%;
          :deep(.el-form-item__content) {
            .el-select {
              .el-select__wrapper {
                .el-select__selection {
                  .el-select__selected-item {
                    text-align: left;
                  }
                }
                .el-select__suffix {
                  .el-icon {
                    color: rgba($orange, 1);
                  }
                }
              }
            }
          }
        }
        .estimate__field {
          width: 250px;
          max-width: 100%;
          @include respond(md) {
            width: 60%;
            max-width: 100%;
          }
        }
      }
      .estimate__phase {
        display: flex;
        gap: 48px;
        align-items: center;
        max-width: 100%;
        @include respond(s720) {
          gap: 28px;
          flex-wrap: wrap;
        }
        .estimate__phase__date {
          width: 140px;
          max-width: 100%;
          @include respond(sm) {
            width: calc(50% - 16px);
          }
        }
        .estimate__phase__days {
          width: 140px;
          max-width: 100%;
          @include respond(sm) {
            width: calc(50% - 16px);
          }
        }
        .estimate__phase__subtotal {
          width: 140px;
          max-width: 100%;
          @include respond(sm) {
            width: calc(50% - 16px);
          }
          .phase__subtotal {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .estimate__subtask {
    padding-top: 24px;
    .estimate__subtask__title {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px dashed rgba($blueDark, 0.2);
      margin-bottom: 16px;
      h6 {
        @include fluidFont(12, 12, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
      .tooltip__wrapper {
        cursor: pointer;
      }
    }
  }
}

.el-form-item {
  margin: 0;
  :deep(.el-form-item__content) {
    border-bottom: 1px solid rgba($blueDark, 0.2);
    flex-wrap: nowrap;
    gap: 8px;
    label {
      @include fluidFont(12, 12, 2.3);
      font-weight: 500;
      color: rgba($blueDark, 1);
      white-space: nowrap;
    }
    .phase__cost {
      display: flex;
      align-items: center;
      background-color: rgba($blueDark, 0.05);
      border-radius: 6px;
      padding: 8px 16px;
      width: 100%;
      max-width: 100%;
    }

    /* el input and selector fields */

    .el-input {
      .el-input__wrapper {
        background-color: transparent;
        box-shadow: none;
        .el-input__prefix {
          .el-input__prefix-inner {
            .el-icon {
              color: rgba($orange, 1);
              margin-left: -40px;
            }
          }
        }

        .el-input__inner {
          @include fluidFont(12, 12, 2.3);
          font-weight: 500;
          color: rgba($blueDark, 1);
          &::placeholder {
            @include fluidFont(12, 12, 2.3);
            font-weight: 500;
            color: rgba($blueDark, 0.5);
          }
        }
      }
    }
    .el-select {
      .el-select__wrapper {
        .el-select__selection {
          .el-select__selected-item {
            text-align: right;
          }
        }
        .el-select__suffix {
          .el-icon {
            color: rgba($orange, 1);
          }
        }
      }
    }
  }
}
:deep(.el-input__suffix) {
  .el-input__suffix-inner {
    .clear-icon {
      margin: 0;
      margin-left: 16px;
      //display: none;
    }
  }
}
.phase__cost__wrapper {
  width: 25%;
  max-width: 100%;
  @include respond(s720) {
    width: 100%;
  }
  :deep(.el-form-item__content) {
    border: none;
  }
}

:deep(.el-form-item.is-error) .el-select__wrapper {
  box-shadow: none;
}
.is-error {
  border: 1px solid rgba($errorRed, 1);
}

.is-error input,
.is-error select {
  border-color: red;
}

// .estimate__phase__date,
// .estimate__phase__days,
// .estimate__phase__subtotal {
//   width: 140px;
// }
// .phase__subtotal {
//   display: flex;
//   align-items: center;
// }
</style>
