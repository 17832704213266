import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-678024c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project_content" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_tabs, {
      class: "tab-list",
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
      "align-tabs": "center",
      stacked: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectTabList, (tabItem) => {
          return (_openBlock(), _createBlock(_component_v_tab, {
            key: tabItem.name,
            value: tabItem.name
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: require('@/assets/icons/' + tabItem?.icon + '.svg'),
                alt: ""
              }, null, 8, _hoisted_2),
              _createTextVNode(" " + _toDisplayString(tabItem.label), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_window, {
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTab) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectTabList, (tabValue) => {
          return (_openBlock(), _createBlock(_component_v_window_item, {
            key: tabValue.name,
            value: tabValue.name
          }, {
            default: _withCtx(() => [
              (_ctx.selectedTab === tabValue.name)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(tabValue.component), { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}