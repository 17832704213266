<template>
  <div
    class="inner-element tw-h-[100dvh] tw-p-6 tw-py-8 tw-pb-10 rsm:tw-p-8 tw-flex tw-flex-col"
  >
    <div class="tw-mb-8 rsm:tw-mb-10 tw-text-left">
      <a @click="gotoLoginPage" class="tw-max-w-[178px] tw-w-full tw-block">
        <img
          src="@/assets/images/iknowa-logo.svg"
          alt="Logo"
          class="tw-w-full tw-h-auto tw-object-contain"
        />
      </a>
    </div>
    <div
      class="tw-flex tw-items-center tw-flex-auto tw-justify-center tw-w-full tw-text-[#0C0F4A]"
    >
      <TradeCompanySelect
        v-if="currentScreen === screenTypes.SELECT_WS_TYPE"
        :userName="allUserDetails.userName"
        header="What type of trade business do you operate?"
        @onContinue="onWorkstationTypeSelect"
        :selectionOptions="businessType"
      />
      <SingleInputField
        v-if="currentInputField"
        v-model="allUserDetails[currentInputField.model]"
        :heading="currentInputField.heading"
        :label="currentInputField.label"
        :rules="currentInputField.rules"
        :isLoading="isLoading"
        @onNext="onNextClick(currentInputField.type)"
        :isDisable="
          inviteUserMemberEmail && currentInputField.type === screenTypes.EMAIL
        "
      />
      <OtpFrom
        v-if="currentScreen === screenTypes.OTP"
        @onNext="onNextClick(screenTypes.OTP)"
      />
      <CreatePasswordForm
        v-if="currentScreen === screenTypes.PASSWORD"
        @onNext="onNextClick(screenTypes.PASSWORD)"
        :loading="isLoading"
      />
      <TermAndCondition
        v-if="currentScreen === screenTypes.TERM_CONDITION"
        @onNext="onNextClick(screenTypes.TERM_CONDITION)"
      />
      <PaymentPlan
        v-if="currentScreen === screenTypes.PAYMENT"
        @onNext="onNextClick(screenTypes.PAYMENT)"
        :userDetails="allUserDetails"
      />
      <PropertySuccessModal
        v-if="isShowSuccessModal"
        @close="toggleIsShowSuccessModal"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import TradeCompanySelect from "@/modules/get-start-boarding/components/TradeCompanySelect.vue";
import SingleInputField from "@/modules/get-start-boarding/components/SingleInputField.vue";
import OtpFrom from "@/modules/get-start-boarding/components/OtpFrom.vue";
import CreatePasswordForm from "@/modules/get-start-boarding/components/CreatePasswordForm.vue";
import TermAndCondition from "@/modules/get-start-boarding/components/TermAndCondition.vue";
import PaymentPlan from "@/modules/get-start-boarding/components/PaymentPlan.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import {
  BusinessDetailTypeEnum,
  RolesEnum,
  UserRolesEnum,
  WorkstationAccountTypeEnum,
  PropertyOwnerWorkstationType,
} from "@/core/enums/RolesEnum";
import { ref } from "vue";
import {
  deleteAllCookies,
  displayToastMessage,
  parseJSON,
} from "@/core/utils/common";
import { getCurrentInstance } from "vue";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import {
  DASHBOARD_ROUTE,
  DASHBOARD_WITH_COURSE_LAUNCH_WS,
} from "@/modules/dashboard/routes";
import { WORKSTATION } from "@/store/modules/workstation";
import PropertySuccessModal from "@/core/components/common/PropertySuccessModal.vue";
import SoloTraderIcon from "@/core/components/icons/SoloTraderIcon.vue";
import LimitedCompanyIcon from "@/core/components/icons/LimitedCompanyIcon.vue";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import UserService from "@/core/services/user.service";
import {
  getCombineSameProjectReview,
  getLocalStorageWithExpiry,
  isAliased,
  isExpiredDate,
  setDirectStoragePathToImage,
  setLocalStorageWithExpiry,
} from "@/core/utils/common";
import {
  AUTH_SESSION_EXPIRY,
  AUTH_USER,
  CRYPTO_SECRET_KEY,
} from "@/core/constants";

const screenTypes = {
  USER_NAME: "userName",
  SELECT_WS_TYPE: "selectWsType",
  SOLO_TRADER: "soloTrader",
  LIMITED_COMPANY: "limitedCompany",
  PROPERTY_OWNER: "propertyOwner",
  EMAIL: "email",
  OTP: "otp",
  PASSWORD: "password",
  TERM_CONDITION: "termAndCondition",
  PAYMENT: "payment",
  REGISTRATION_NUMBER: "registrationNumber",
};

const businessType = [
  {
    id: 1,
    component: SoloTraderIcon,
    title: "Sole Trader",
    description: "Trading as Individual",
    value: BusinessDetailTypeEnum.SOLETRADER,
  },
  {
    id: 2,
    component: LimitedCompanyIcon,
    title: "Limited Company",
    description: "Registered on Companies House",
    value: BusinessDetailTypeEnum.LIMITED_COMPANY,
  },
];
const rules = {
  email: [
    (v) => !!v?.trim() || "Email is required",
    (v) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Invalid email. Try again.",
  ],
  company: [
    (v) => !!v?.trim() || "Company Name is required",
    (v) =>
      /^[a-zA-Z0-9&.,'’\- ]+$/.test(v.trim()) ||
      "Invalid format. Only letters, numbers, spaces, and & . , ' - are allowed.",
    (v) =>
      v.trim().length <= 100 || "Company Name must be less than 100 characters",
  ],
  workstation: [
    (v) => !!v?.trim() || "Workstation Name is required",
    (v) =>
      !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(v) ||
      "Workstation Name cannot include an email address",
    (v) =>
      /^[a-zA-Z0-9-_ ]+$/.test(v.trim()) ||
      "Invalid format. Only letters, numbers, spaces, hyphens, and underscores are allowed.",
    (v) =>
      v.trim().length <= 100 ||
      "Workstation Name must be less than 100 characters",
  ],
  userName: [
    (v) => !!v?.trim() || "First Name is required",
    (v) =>
      /^[a-zA-Z]+[a-zA-Z0-9\s'.,-]*$/.test(v) ||
      "Invalid format. Must start with a letter and can include numbers (not at the beginning), spaces, and valid special characters.",
    (v) => v.length <= 20 || "First Name must be less than 20 characters",
  ],
  registrationNo: [(v) => !!v || "Registration Number is Required."],
};

const inputFields = [
  {
    type: screenTypes.USER_NAME,
    model: "userName",
    heading: "Let's get you started! 🚀 Enter your first name below.",
    label: "First Name",
    rules: rules.userName,
  },
  {
    type: screenTypes.SOLO_TRADER,
    model: "companyName",
    heading: "What is your sole trader business called ?",
    label: "Business Name",
    rules: rules.company,
  },
  {
    type: screenTypes.LIMITED_COMPANY,
    model: "companyName",
    heading: "What is your limited company called ?",
    label: "Company Name",
    rules: rules.company,
  },
  {
    type: screenTypes.PROPERTY_OWNER,
    model: "companyName",
    heading: "What would you like your workstation to be called?",
    label: "Workstation Name",
    rules: rules.workstation,
  },
  {
    type: screenTypes.EMAIL,
    model: "email",
    heading: "Please enter your email to create your workstation.",
    label: "Enter Your Email Address",
    rules: rules.email,
  },
  {
    type: screenTypes.REGISTRATION_NUMBER,
    model: "registrationNo",
    heading: "Enter Your Company's Registration Number",
    label: "Registration Number",
    rules: rules.registrationNo,
  },
];
const route = useRoute();
const store = useStore();
const router = useRouter();
const allUserDetails = ref({
  companyName: "",
  email: "",
  userName: "",
  userRole: "",
  workstationType: "",
  registrationNo: "",
});
const internalInstance = getCurrentInstance();
const isLoading = ref(false);
const isShowSuccessModal = ref(false);
const createdWorkstation = ref();
const currentScreen = ref();
const isShowIDCheckModal = ref(false);
const HeaderTittle = ref("");
const isShowNextButton = ref(false);
const workstationType = ref();

const currentInputField = computed(() =>
  inputFields.find((input) => input.type === currentScreen.value)
);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const startBoardingData = computed(() => {
  const startBoarding = route.query.startBoarding;
  return startBoarding ? parseJSON(decodeURIComponent(startBoarding)) : {};
});
const inviteUserMemberEmail = computed(() => route.query.memberUserEmail);
const inviteUserRoleId = computed(() => Number(route.query.userType));

const user = computed(() => store.getters[`${USER_STORE}/user`]);

const userAllWorkStation = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkstations`]
);

const onWorkstationTypeSelect = (type) => {
  allUserDetails.value.workstationType = type;
  if (!type) return;
  currentScreen.value =
    type === BusinessDetailTypeEnum.PROPERTY_OWNER
      ? screenTypes.PROPERTY_OWNER
      : type === BusinessDetailTypeEnum.SOLETRADER
      ? screenTypes.SOLO_TRADER
      : screenTypes.LIMITED_COMPANY;
};

const onNextClick = async (type) => {
  if (type === screenTypes.USER_NAME && allUserDetails.value.userName) {
    currentScreen.value = screenTypes.EMAIL;
  } else if (
    [
      screenTypes.LIMITED_COMPANY,
      screenTypes.SOLO_TRADER,
      screenTypes.PROPERTY_OWNER,
    ].includes(type) &&
    allUserDetails.value.companyName
  ) {
    if (type == screenTypes.LIMITED_COMPANY) {
      currentScreen.value = screenTypes.REGISTRATION_NUMBER;
    } else {
      currentScreen.value = screenTypes.EMAIL;
    }
  } else if (type == screenTypes.REGISTRATION_NUMBER) {
    currentScreen.value = screenTypes.EMAIL;
  } else if (type === screenTypes.EMAIL && allUserDetails.value.email) {
    const goToNextStep = await onVerifyEmailId();
    if (goToNextStep) {
      const isRegister = await registerUser();
      if (isRegister) currentScreen.value = screenTypes.OTP;
    }
  } else if (type === screenTypes.OTP) {
    currentScreen.value = screenTypes.PASSWORD;
  } else if (type === screenTypes.PASSWORD) {
    if (inviteUserMemberEmail.value) {
      router.push({ name: DASHBOARD_ROUTE });
      localStorage.removeItem("newAuthenticationFlowDetails");
    } else if (allUserDetails.value.userRole === RolesEnum.PROJECT_OWNER) {
      await createPropertyWorkstation();
    } else if (allUserDetails.value.userRole === RolesEnum.TRADESPERSON) {
      // currentScreen.value = screenTypes.TERM_CONDITION;
      // currentScreen.value = screenTypes.PAYMENT;
      // call create ws function
      await creatTpWorkstation();
    }
  }
  // else if (type === screenTypes.TERM_CONDITION) {
  //   currentScreen.value = screenTypes.PAYMENT;
  // }
};
const onVerifyEmailId = async () => {
  try {
    isLoading.value = true;
    await store.dispatch(`${AUTHENTICATION_STORE}/verifyEmail`, {
      email: allUserDetails.value.email.toLowerCase(),
    });
    return true;
  } catch (error) {
    if (error?.response?.data?.statusCode == 409) {
      displayToastMessage(
        internalInstance,
        error?.response?.data?.message ||
          "something went wrong! please Try Again.",
        "error"
      );
    }
    return false;
  } finally {
    isLoading.value = false;
  }
};

const registerUser = async () => {
  try {
    isLoading.value = true;
    const userData = await store.dispatch(
      `${AUTHENTICATION_STORE}/newRegistration`,
      {
        firstName: allUserDetails.value.userName,
        lastName: " ",
        marketingOptIn: false,
        tcOptIn: false,
        email: allUserDetails.value.email,
        roleId: allUserDetails.value.userRole,
      }
    );
    await store.dispatch(`${USER_STORE}/saveUserState`, userData);

    await store.dispatch(
      `${USER_STORE}/saveUserRole`,
      allUserDetails.value.userRole === RolesEnum.PROJECT_OWNER
        ? UserRolesEnum.PROPERTY_OWNER
        : UserRolesEnum.TRADESPERSON
    );
    return true;
  } catch (error) {
    displayToastMessage(
      internalInstance,
      "something went wrong! please Try Again.",
      "error"
    );
    return false;
  } finally {
    isLoading.value = false;
  }
};

const filteredUserAllWorkStation = computed(() => {
  const workstations = userAllWorkStation.value.filter(
    (workstation) => workstation.name !== "Default"
  );
  return workstations;
});
const isFromCourse = computed(
  () => store.getters[`${WORKSTATION}/isFromCourse`]
);

const onWorkStationClick = async (workstationId, resetData = true) => {
  try {
    const wsIdParam = String(route.params?.workstationId);
    await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, true);
    if (!workstationId) return;
    const workstation = filteredUserAllWorkStation.value.find(
      (workstation) => workstation?.id === workstationId
    );
    if (workstation?.isInvited) {
      await store.dispatch(`${WORKSTATION}/isFirstTimeLaunchWorkStation`, {
        userId: user.value?.id,
        memberId: workstation?.memberId,
      });
    }
    if (resetData && wsIdParam === "undefined" && !isFromCourse.value) {
      await router.push({ name: DASHBOARD_ROUTE });
      await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
        userId: user.value?.id,
        userWorkstationId: workstationId,
      });
    }
    if (resetData && wsIdParam && isFromCourse.value) {
      await router.push({ name: DASHBOARD_WITH_COURSE_LAUNCH_WS });
      await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
        userId: user.value?.id,
        userWorkstationId: workstationId,
      });
    }
    const workstationVerification = await store.dispatch(
      `${WORKSTATION}/getWorkstationVerificationData`,
      workstationId
    );
    if (
      workstationVerification &&
      workstationVerification?.response?.id &&
      ["KYC", "KYB"].includes(workstationVerification?.verificationType)
    ) {
      await store.dispatch(
        `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
        {
          id: workstationVerification.response.id,
          verificationType: workstationVerification?.verificationType,
        }
      );
    } else {
      await store.dispatch(
        `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
        null
      );
    }
  } catch (error) {
    await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
    console.log();
  } finally {
    if (resetData) {
      await store.dispatch(`${USER_STORE}/initializeUserState`);
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);

      await store.dispatch(`${USER_STORE}/setUserCertificates`, user.value?.id);
      await store.dispatch(`${USER_STORE}/setUserInsurances`, user.value?.id);
      await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
      await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
    }
  }
};

const createPropertyWorkstation = async () => {
  try {
    isLoading.value = true;
    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: { hasBusinessType: true },
    });

    createdWorkstation.value = await store.dispatch(
      `${WORKSTATION}/createUserWorkstation`,
      {
        userId: user.value?.id,
        userWorkstation: {
          name: allUserDetails.value.companyName,
          companyName: allUserDetails.value.companyName,
          businessType: BusinessDetailTypeEnum.PROPERTY_OWNER,
          workstationAccountType: WorkstationAccountTypeEnum.HOMEOWNER,
        },
      }
    );

    await store.dispatch(`${WORKSTATION}/updateUserWorkstation`, {
      userId: user.value?.id,
      userWorkstationId: createdWorkstation.value.id,
      payload: {
        profileImage: null,
      },
    });

    await store.dispatch(`${USER_STORE}/initializeUserState`);
    await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
      userId: user.value?.id,
      userWorkstationId: createdWorkstation.value?.id,
    });
    toggleIsShowSuccessModal();
    localStorage.removeItem("newAuthenticationFlowDetails");
    setWorkStationType();
    await store.dispatch(`${WORKSTATION}/setIsShowKycModel`, true);
    await router.push({ name: DASHBOARD_ROUTE });
  } catch (error) {
    console.log("create property ws error", error);
  } finally {
    isLoading.value = false;
  }
};

const creatTpWorkstation = async () => {
  try {
    console.log(
      allUserDetails.value.companyName,
      "allUserDetails.value.companyName"
    );
    console.log(allUserDetails.value.userName, "allUserDetails.value.userName");
    isLoading.value = true;
    const companyName =
      allUserDetails.value.companyName ||
      allUserDetails.value.userName + "workstation";
    const registrationNo = allUserDetails.value.registrationNo || null;
    workstationType.value =
      allUserDetails.value.workstationType ===
      BusinessDetailTypeEnum.LIMITED_COMPANY
        ? BusinessDetailTypeEnum.LIMITED_COMPANY
        : BusinessDetailTypeEnum.SOLETRADER;

    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: { hasBusinessType: true },
    });

    createdWorkstation.value = await store.dispatch(
      `${WORKSTATION}/createUserWorkstation`,
      {
        userId: user.value?.id,
        userWorkstation: {
          name: companyName,
          companyName,
          businessType: workstationType.value,
          workstationAccountType: workstationType.value,
          companyRegistrationNo: registrationNo,
        },
      }
    );

    // createdWorkstation.value = await store.dispatch(
    //   `${WORKSTATION}/updateUserWorkstation`,
    //   {
    //     userId: user.value?.id,
    //     userWorkstationId: createdWorkstation.value.id,
    //     payload: {
    //       profileImage: null,
    //     },
    //   }
    // );

    await store.dispatch(`${WORKSTATION}/getUserWorkstations`, user.value?.id);

    await store.dispatch(`${USER_STORE}/initializeUserState`);

    await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
      userId: user.value.id,
      formData: {
        businessName: companyName,
        type: UserRolesEnum.TRADESPERSON,
        userVerificationStatus: "1",
      },
    });
    await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
      userId: user.value?.id,
      userWorkstationId: createdWorkstation.value?.id,
    });
    // if (selectedSubscriptionType.value === PaymentPlanType.YEARLY) {
    //   await initializeStripePayment(false, false, 95.9);
    // } else if (selectedSubscriptionType.value === PaymentPlanType.MONTHLY) {
    //   await initializeStripePayment(false, false, 9.99);
    // }
    // await store.dispatch(`${WORKSTATION}/updateUserWorkstation`, {
    //   userId: user.value?.id,
    //   userWorkstationId: createdWorkstation.value.id,
    //   payload: {
    //     profileImage: null,
    //   },
    // });
    await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
    await store.dispatch(`${WORKSTATION}/setWsModal`, {
      createdWorkstationId: createdWorkstation.value.id,
      activeLimitedCompanyWorkStationModal: 1,
      activeSoleTraderWorkStationModal: 1,
      isShowWorkStationModal: false,
      isShowLimitedCompanyWorkStation: false,
      isShowSoleTraderWorkStation: false,
      isShowSuccessWsCreationModal: true,
      // isShowLimitedCompanyWorkStation:
      //   workstationType.value === BusinessDetailTypeEnum.LIMITED_COMPANY,
      // isShowSoleTraderWorkStation:
      //   workstationType.value === BusinessDetailTypeEnum.SOLETRADER,
    });
  } catch (error) {
    console.log("error while adding workstation", error);
  } finally {
    isLoading.value = false;
    await router.push({ name: DASHBOARD_ROUTE });
    localStorage.setItem("isShowWelcomePopup", JSON.stringify(false));
    localStorage.removeItem("newAuthenticationFlowDetails");
    await onWorkStationClick(createdWorkstation.value?.id);
    await store.dispatch(`${WORKSTATION}/setWsModal`, {
      isShowSuccessWsCreationModal: true,
    });
  }
};

const toggleIsShowSuccessModal = () => {
  isShowSuccessModal.value = !isShowSuccessModal.value;
};
const onSaveLocalStorage = () => {
  localStorage.setItem(
    "newAuthenticationFlowDetails",
    JSON.stringify({
      allUserDetails: allUserDetails.value,
      currentScreen: currentScreen.value,
      user: user.value,
    })
  );
};

const setNewFlowData = () => {
  let newAuthenticationFlowDetails = localStorage.getItem(
    "newAuthenticationFlowDetails"
  );
  newAuthenticationFlowDetails = parseJSON(newAuthenticationFlowDetails);
  if (
    newAuthenticationFlowDetails?.currentScreen &&
    newAuthenticationFlowDetails?.allUserDetails
  ) {
    allUserDetails.value = newAuthenticationFlowDetails.allUserDetails;
    currentScreen.value = newAuthenticationFlowDetails.currentScreen;

    store.dispatch(
      `${USER_STORE}/saveUserState`,
      newAuthenticationFlowDetails?.user || null
    );
  } else {
    deleteAllCookies();
    localStorage.clear();
    if (inviteUserMemberEmail.value) {
      allUserDetails.value.userRole = inviteUserRoleId.value;
      allUserDetails.value.email = inviteUserMemberEmail.value;
      currentScreen.value = screenTypes.USER_NAME;
    } else {
      allUserDetails.value.userName = startBoardingData.value.userName;
      allUserDetails.value.userRole = startBoardingData.value.role;
      if (allUserDetails.value.userRole === RolesEnum.PROJECT_OWNER) {
        onWorkstationTypeSelect(BusinessDetailTypeEnum.PROPERTY_OWNER);
      } else {
        currentScreen.value = screenTypes.SELECT_WS_TYPE;
      }
    }
  }
  onSaveLocalStorage();
};

const setWorkStationType = async () => {
  const selectedTypeData = {
    userId: user.value.id,
    userWorkstationId: activeUserWorkstation.value?.id,
    verificationType: PropertyOwnerWorkstationType.INDIVIDUAL,
  };
  try {
    const response = await store.dispatch(
      `${USER_STORE}/updatePropertyOwnerWSType`,
      selectedTypeData
    );
  } catch (err) {
    console.log();
  }
};
const gotoLoginPage = () => {
  router.push({ name: LOGIN_ROUTE });
};
const onClickCloseIDCheckButton = () => {
  router.push({ name: DASHBOARD_ROUTE });
};
const onClickKycTypeIdVerification = async () => {
  try {
    isLoading.value = true;
    const currentDomain = window.location.origin;
    const payload = {
      callback: `${currentDomain}/dashboard`,
      vendor_data: `${user.value.id}-${activeUserWorkstation.value.id}`,
    };

    const response = await store.dispatch(
      `${WORKSTATION}/sendUserKYCProcessMail`,
      payload
    );
    console.log("Reposne is ", response);
    const redirectUrl = response?.url;
    if (redirectUrl) {
      window.location.href = redirectUrl;
      isLoading.value = false;
    } else {
      isLoading.value = false;
    }
  } catch (err) {
    isLoading.value = false;
  }
};

watch(
  () => currentScreen.value,
  () => {
    onSaveLocalStorage();
  },
  { deep: true }
);

onBeforeMount(() => {
  setNewFlowData();
});
</script>

<style scoped>
.inner-element {
  overflow: auto;
}

.inner-element::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
