<template>
  <v-dialog width="auto" v-model="state.modal" persistent>
    <main
      class="general-dialog tw-w-full tw-flex tw-flex-col tw-gap-6 tw-justify-between tw-bg-white tw-p-6 tw-box-border tw-rounded-[8px] tw-overflow-auto tw-max-w-full"
      :style="{
        width: width,
        height: height,
        minHeight: minHeight,
        borderRadius: borderRadius,
        maxWidth: maxWidth,
      }"
      :class="{ '!tw-p-8': paddingLarge }"
    >
      <!-- :style="{ width: width }" -->
      <section
        class="tw-absolute tw-top-[-2rem] tw-left-0"
        v-if="showBackButton"
      >
        <ChipState
          class="tw-justify-start !tw-p-0 tw-cursor-pointer"
          label="Back"
          textColor="rgba(255, 255, 255, 1)"
          @click="onBack"
        >
          <template #prefix>
            <v-icon
              icon="mdi-chevron-left"
              color="rgba(255, 255, 255, 1)"
            ></v-icon>
          </template>
        </ChipState>
      </section>
      <section
        :class="{ 'tw-flex': isShowHeader || isShowCloseButton }"
        class="general-dialog__header tw-gap-2 tw-items-start tw-justify-between tw-w-full"
      >
        <div
          class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-2 tw-text-left tw-justify-start md:!tw-w-[70%]"
          v-if="isShowHeader"
        >
          <Text
            v-if="isTitleStrong"
            class="tw-mr-auto md:!tw-w-full md:!tw-truncate"
            variant="h4"
            >{{ headerTitle }}</Text
          >
          <Text
            v-else
            variant="h5"
            textWeight="600"
            class="tw-mr-auto md:!tw-w-full md:!tw-truncate"
            :xsMobileFont="headerTitleFontSize"
            lineHeight="16px"
            >{{ headerTitle }}</Text
          >
          <Text
            variant="span"
            textWeight="400"
            :textColor="descriptionColor"
            lineHeight="16px"
            >{{ headerDescription }}</Text
          >
        </div>
        <v-icon
          class="cursor-pointer"
          v-if="isShowCloseButton"
          icon="mdi-close"
          :size="15"
          @click="onCloseModal"
        ></v-icon>
      </section>
      <section class="general_dialog__content">
        <slot name="body"></slot>
      </section>
      <!-- <v-divider></v-divider> -->
      <slot name="footer"></slot>
    </main>
  </v-dialog>
</template>

<script setup>
import { reactive } from "vue";
import Text from "@/core/components/ui/general/Text.vue";
import ChipState from "@/core/components/common/ChipState.vue";

const emits = defineEmits(["on-close", "on-back"]);

const props = defineProps({
  isShowHeader: {
    type: Boolean,
    default: true,
  },
  isShowCloseButton: {
    type: Boolean,
    default: true,
  },
  headerTitle: String,
  headerDescription: String,
  headerTitleFontSize: Number,
  descriptionColor: {
    type: String,
    default: "rgba(12, 15, 74, 0.5)",
  },
  isTitleStrong: Boolean,
  showBackButton: Boolean,
  width: {
    type: String,
    default: "unset",
  },
  height: {
    type: String,
    default: "unset",
  },
  minHeight: {
    type: String,
    default: "unset",
  },
  borderRadius: {
    type: String,
    default: "8px",
  },
  maxWidth: {
    type: String,
    default: "100%",
  },
  paddingLarge: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  modal: true,
});

const onCloseModal = () => {
  emits("on-close");
};

const onBack = () => {
  emits("on-back");
};
</script>

<style lang="scss" scoped>
// :deep(.v-dialog > .v-overlay__content) {
//   max-height: calc(100% - 120px) !important;
// }
// :deep(.v-dialog) {
//   max-height: calc(100% - 120px) !important;
// }
:deep(.v-overlay__content) {
  max-height: calc(100% - 120px) !important;
}
// .v-dialog {
//   max-height: calc(100% - 120px) !important;
// }
// .v-overlay__content {
//   max-height: calc(100% - 100px) !important;
// }
.general-dialog {
  max-width: v-bind("props.maxWidth");
}
</style>
