<template>
  <section class="property_details_wrapper no-scrollbar">
    <div>
      <v-btn
        v-if="currentSelectedTab.id !== 4"
        class="back_button"
        @click="onBack"
        variant="tonal"
        prepend-icon="mdi-chevron-left"
      >
        {{ currentSelectedTab.backBtnLabel }}</v-btn
      >
      <div v-if="screenType !== PREVIEW_SCREEN" class="historic_wrapper_header">
        <div class="left_block">
          <h5>
            <span class="count_wrapper">1</span>{{ currentSelectedTab?.tab }}
          </h5>
          <p>{{ currentSelectedTab?.info }}</p>
        </div>
        <div class="right_block">
          <v-btn
            :disabled="!isFormValid || isSaveDraftloading"
            @click="onNext"
            class="button button-orange"
            >Continue</v-btn
          >
          <v-btn
            v-if="!isTradeSideUser"
            :disabled="!isFormValid || isSaveDraftloading"
            class="save-btn bright-blue-text"
            @click="saveHistoricalProjectToDraft"
            variant="text"
            :loading="isSaveDraftloading"
          >
            save project to draft
          </v-btn>
        </div>
      </div>
    </div>
    <div class="historic_wrapper_content" v-if="screenType === DEFAULT">
      <div class="historic_wrapper_content_item">
        <h4>Historic Project Type</h4>
        <p>Please select what type of historic project it was.</p>

        <div class="historic_content_item_title">
          <h6>Project Type</h6>
          <v-select
            :items="filterProjectType"
            class="custom_input_field"
            placeholder="Select Project Type"
            density="compact"
            item-value="value"
            item-title="label"
            :model-value="historicalProjectForm.projectType"
            variant="outlined"
            :menu-props="{ contentClass: 'project_type__selector' }"
            ><template v-slot:item="{ props, item }">
              <v-list-item
                ><v-radio-group v-model="historicalProjectForm.projectType">
                  <v-radio
                    color="#4b4bff"
                    v-bind="props"
                    :label="item.raw.label"
                    :value="item.raw.value"
                  ></v-radio>

                  <ToolTip
                    :description="
                      item.raw.value === HistoricalProjectTypeEnum.TRADE_VERIFY
                        ? 'This project has been verified by a professional. You may include additional details if available.'
                        : 'DIY - This project was done by yourself. Include before/after images, costs, and materials used.'
                    " /></v-radio-group
              ></v-list-item> </template
          ></v-select>
        </div>
      </div>

      <div class="historic_wrapper_content_item text__editor">
        <v-text-field
          class="projecttitle_select"
          placeholder="Project Title/ Name"
          v-model="historicalProjectForm.title"
          variant="underlined"
          density="compact"
          :rules="validation.projectTitle"
        >
          <template v-slot:append-inner>
            <div @click="onClickTitleKrystalIcon">
              <img src="@/assets/images/krystal-purple.svg" alt="" />
            </div>
          </template>
        </v-text-field>
        <div class="custom_editor">
          <div
            v-show="showButtonForGeminiModel"
            id="quill-cursor-box"
            class="temp-box desktop__cursor"
          ></div>
          <v-menu
            v-model="geminiModalMenu"
            activator="#quill-cursor-box"
            transition="scale-transition"
            target="cursor"
            location="bottom"
            :close-on-content-click="false"
            :close-on-back="false"
            class="desktop__cursor"
          >
            <GeminiModel
              @click.stop
              @appendJobDescription="appendJobDescription"
              @onClose="onCloseHandler"
            />
          </v-menu>
          <div
            v-show="showButtonForGeminiModel"
            id="quill-cursor-box-mobile"
            class="temp-box mobile__cursor"
            @click="showGeminiModelForMobile = !showGeminiModelForMobile"
          ></div>
          <quillEditor
            v-model:value="historicalProjectForm.description"
            :options="state.editorOption"
            :disabled="state.disabled"
            @change="onEditorChange($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady"
            class="editor_ql"
          />
        </div>
      </div>

      <div class="historic_wrapper_content_item">
        <h4>
          Services
          <ToolTip
            description="Services are optional for DIY but are required for the Trade Verified Historic Projects."
          />
        </h4>
        <p>
          Add any services that were part of the project (e.g. Electrical etc.)
        </p>
        <div class="historic_content_item_title">
          <h6>Select Services</h6>
          <div class="tw-mt-4">
            <PropertySpecialismDropDownModal />
          </div>
        </div>
      </div>
      <div class="historic_wrapper_content_item">
        <h4>Upload files <ToolTip description="Upload Media" /></h4>
        <p>
          Add any before & after images, material or labour costs/ receipts or
          anything else that was used for this project.
        </p>

        <div
          class="historic_content_media_upload"
          :class="{ 'tw-flex-col !tw-items-start': projectAttachments.length }"
        >
          <p class="no-dox" v-if="!projectAttachments.length">
            No documents added
          </p>

          <Button
            label="Add Documents"
            variant="tertiary"
            background="transparent"
            class="!tw-w-auto !tw-capitalize !tw-text-xs tw-tracking-normal !tw-p-0 !tw-h-auto !tw-min-h-auto !tw-font-normal !tw-text-[#0C0F4A] !tw-bg-transparent"
            @click="onClickToggleUploadAttachmentUpload"
            ><template #prefix
              ><v-icon
                icon="mdi-plus-circle"
                color="#FAA500"
              ></v-icon></template
          ></Button>
          <div
            v-for="(attachment, index) in projectAttachments"
            :key="index"
            class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-border-b-solid-custom tw-pb-4 tw-box-border"
          >
            <v-img
              class="tw-rounded-lg"
              :class="{
                'tw-hidden':
                  attachment?.uploadedImages?.imageData.type ===
                    'application/pdf' ||
                  attachment?.uploadedImages?.imageData.type ===
                    'application/msword' ||
                  !attachment?.type?.includes('image'),
              }"
              :width="156"
              :height="88"
              aspect-ratio="16/9"
              cover
              :src="attachment?.uploadedImages?.url"
            ></v-img>
            <div class="tw-flex-row-between">
              <Text variant="span">{{ attachment?.uploadedImages?.name }}</Text>
              <img
                class="tw-cursor-pointer"
                @click="removeImageFromAttachments(index)"
                src="@/assets/icons/delete.svg"
              />
            </div>
          </div>
        </div>
      </div>

      <Button
        label="next"
        class="!tw-max-w-full !tw-hidden lg:!tw-hidden md:!tw-block"
        @click="onNext"
        :isActive="isFormValid"
      />
    </div>

    <PropertyEstimateScreen
      v-if="screenType === ESTIMATE_SCREEN"
      :historicalProjectForm="historicalProjectForm"
      :isTradeSideUser="isTradeSideUser"
      :propertyDetails="propertyDetails?.localData"
      @onNext="onNext"
    />
    <PropertyTradeNetworkScreen
      v-if="screenType === TRADE_NETWORK_SCREEN"
      :inviteContractors="historicalProjectForm.invitedContractors"
      :selectedClient="selectedClient"
      :propertyDetails="propertyDetails?.localData"
      @on-select-contractor="onSelectContractors"
      :workflowType="workflowType"
      @onNext="onNext"
    />
    <PropertyPreviewScreen
      v-if="screenType === PREVIEW_SCREEN"
      :propertyDetails="propertyDetails?.localData"
      :historicalProjectForm="historicalProjectForm"
      @on-back="onBack"
      @on-save-record="onClickSaveRecord"
      @on-edit-details="onClickEditDetailsSave"
      @on-select-contractor="onSelectContractors"
      :workflowType="workflowType"
      @on-save-draft="saveHistoricalProjectToDraft"
      :isTradeSideUser="isTradeSideUser"
      :isSaveDraftloading="isSaveDraftloading"
    />
  </section>

  <!-- MODALS -->
  <PropertySpecialismModal
    v-if="isShowSpecialismModal"
    @onClickCloseTab="onClickToggleSpecialismForm"
  />

  <ConfirmSaveHistoricalProjectModal
    v-if="isConfirmSaveModal"
    :loading="isLoading"
    @on-close="onClickToggleConfirmSave"
    @on-confirm="onSaveProject"
    :projectType="historicalProjectForm.projectType"
  />

  <HistoricalProjectSavingStepsModal
    v-if="isPostLoadingModal"
    @on-success="onSaveSuccess"
  />

  <UploadProjectAttachmentsModal
    v-if="isShowProjectAttachmentUploadModal"
    :projectAttachments="projectAttachments"
    :isPropertyOwner="true"
    @on-close="onClickToggleUploadAttachmentUpload"
  />

  <ConfirmInviteContractorsModal
    v-if="isShowConfirmContractorInviteModal"
    :projectType="historicalProjectForm.projectType"
    :historicalProjectForm="historicalProjectForm"
    @on-close="onClickToggleConfirmContractorInviteModal"
    @on-confirm="onConfirmInvite"
    @on-cancel="onCancelProjectType"
    @onConfirmProjectType="onConfirmProjectType"
  />
  <GeminiModelForMobileView
    v-if="showGeminiModelForMobile"
    @appendJobDescription="appendJobDescription"
    @onClose="onCloseHandler"
  />

  <v-stepper class="onboard-stepper" v-model="currentSelectedTab.id">
    <v-stepper-header class="onboard-stepper__header">
      <v-stepper-item
        class="onboard-stepper__item"
        v-for="step in modelDetails"
        :key="step.id"
        :title="step.tab"
        :value="step.id"
        :style="{
          display:
            historicalProjectForm.projectType ==
              HistoricalProjectTypeEnum.DIY_PROJECT && step.id == 3
              ? 'none'
              : 'flex',
        }"
      ></v-stepper-item>
    </v-stepper-header>
  </v-stepper>
</template>
<script lang="ts" setup>
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  reactive,
  ref,
} from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import Button from "@/core/components/ui/general/Button.vue";
import PropertySpecialismDropDownModal from "@/modules/Specialism/components/PropertySpecialismDropDownModal.vue";
import PropertySpecialismModal from "@/modules/Specialism/components/PropertySpecialismModal.vue";
import ConfirmSaveHistoricalProjectModal from "@/modules/properties/components/Modals/ConfirmSaveHistoricalProjectModal.vue";
import HistoricalProjectSavingStepsModal from "@/modules/properties/components/Modals/HistoricalProjectSavingStepsModal.vue";
import PropertyEstimateScreen from "./PropertyEstimateScreen.vue";
import PropertyTradeNetworkScreen from "./PropertyTradeNetworkScreen.vue";
import PropertyPreviewScreen from "@/modules/properties/components/PropertyPreviewScreen.vue";
import UploadProjectAttachmentsModal from "./Modals/UploadProjectAttachmentsModal.vue";
import ConfirmInviteContractorsModal from "@/modules/properties/components/Modals/ConfirmInviteContractorsModal.vue";
import { PROJECT_STORE } from "@/store/modules/project";
import { quillEditor } from "vue3-quill";
import { ElMessage } from "element-plus";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { PROPERTY_STORE } from "@/store/modules/property";
import historicalProjectService from "@/core/services/historical-project.service";
import {
  HistoricalProjectTypeEnum,
  HistoricalWorkFlowTypeEnum,
} from "@/core/enums/ProjectsEnum";
import moment from "moment";
import { useDisplay } from "vuetify";
import { onUpdated } from "vue";
import GeminiModel from "@/modules/project/components/common/GeminiModel.vue";
import GeminiModelForMobileView from "@/modules/project/components/common/GeminiModelForMobileView.vue";
import { watch } from "vue";
import ToolTip from "@/core/components/common/ToolTip.vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import {
  displayToastMessage,
  isValidDate,
  parseJSON,
} from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";

const DEFAULT = "default";
const ESTIMATE_SCREEN = "estimate-screen";
const TRADE_NETWORK_SCREEN = "trade-network-screen";
const PREVIEW_SCREEN = "preview-screen";

const store = useStore();
const props = defineProps({
  workflowType: { type: String, default: HistoricalWorkFlowTypeEnum.PROPERTY },
  selectedClient: {
    type: Object,
    default: null,
  },
  property: {
    type: Object,
    default: null,
  },

  isHistoricalDraftProjectFlow: {
    type: Boolean,
    default: false,
  },
  isTradeSideUser: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["on-back", "onHistoricProjectSaved"]);
const screenType = ref(DEFAULT);
const isPostLoadingModal = ref(false);
const isLoading = ref(false);
const internalInstance = getCurrentInstance();
const isConfirmSaveModal = ref(false);
const isShowSpecialismModal = ref(false);
const isShowProjectAttachmentUploadModal = ref(false);
const isShowConfirmContractorInviteModal = ref(false);
const cloneAttachmentDataList: any = ref([]);

const state = reactive({
  content: "",
  _content: "",
  editorOption: {
    placeholder: "Describe your project here",
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ align: [] }],
        ["clean"],
      ],
    },
    wordCounter: {
      container: "#counter",
      unit: "word",
    },
    // more options
  },
  disabled: false,
});
const modelDetails = ref([
  {
    id: 1,
    tab: "Historic Project Specifics",
    info: "Please try to explain your project in more detail.",
    backBtnLabel: "Back To Properties",
  },
  {
    id: 2,
    tab: "Cost Details",
    info: "Please provide a details of your project cost.",
    backBtnLabel: "Back To Historic Project Specifics",
  },
  {
    id: 3,
    tab: "Invite Contractors",
    info: "Please provide details about who has worked with you on the project.",
    backBtnLabel: "Back To Estimate Details",
  },
  {
    id: 4,
    tab: "Project Review",
    backBtnLabel: "Back",
  },
]);
const validation = ref({
  projectTitle: [
    (v: any) => {
      if (v) {
        return v?.length <= 255 || "Project title is too long..";
      } else {
        return true;
      }
    },
  ],
});
const projectSpecialisms = computed(
  () => store.getters[`${PROJECT_STORE}/projectSpecialisms`]
);

const ProjectForm = computed(
  () => store.getters[`${PROJECT_STORE}/ProjectForm`]
);

const historicalProjectForm = ref({
  projectType: null,
  specialisms: [],
  title: "",
  description: "",
  property: props.property,
  attachments: [] as any,
  invitedContractors: [] as any[],

  projectEstimate: {
    estimateStartDate: null,
    estimates: [],
  },
});
const projectTypes = [
  {
    label: "Trade Verified",
    value: HistoricalProjectTypeEnum.TRADE_VERIFY,
  },
  {
    label: "DIY Project",
    value: HistoricalProjectTypeEnum.DIY_PROJECT,
  },
];
const filterProjectType = computed(() =>
  props.workflowType === HistoricalWorkFlowTypeEnum.CLIENT
    ? [
        {
          label: "Trade Verified",
          value: HistoricalProjectTypeEnum.TRADE_VERIFY,
        },
      ]
    : projectTypes
);
const projectAttachments = computed(
  () => store.getters[`${PROJECT_STORE}/projectAttachments`]
);
const isDisableNextButton = computed(() => {
  if (historicalProjectForm.value.title?.length >= 255) {
    return false;
  }
  return true;
});

const isFormValid = computed(() => {
  let isValid = false;
  //  projectAttachments.value.length &&
  if (
    historicalProjectForm.value.projectType &&
    historicalProjectForm.value.title &&
    historicalProjectForm.value.description &&
    isDisableNextButton.value
  ) {
    isValid = true;
  }
  if (screenType.value === TRADE_NETWORK_SCREEN) {
    if (
      !historicalProjectForm.value.invitedContractors.length &&
      historicalProjectForm.value.projectType ===
        HistoricalProjectTypeEnum.DIY_PROJECT
    ) {
      isValid = true;
    }
    if (
      !historicalProjectForm.value.invitedContractors.length &&
      historicalProjectForm.value.projectType ===
        HistoricalProjectTypeEnum.TRADE_VERIFY
    ) {
      isValid = false;
    }
  }

  return isValid;
});

const onClickToggleSpecialismForm = () => {
  isShowSpecialismModal.value = !isShowSpecialismModal.value;
};

const onClickToggleConfirmSave = () => {
  isConfirmSaveModal.value = !isConfirmSaveModal.value;
};

const onClickToggleUploadAttachmentUpload = () => {
  isShowProjectAttachmentUploadModal.value =
    !isShowProjectAttachmentUploadModal.value;
  if (!isShowProjectAttachmentUploadModal.value) {
    historicalProjectForm.value.attachments = projectAttachments.value;
  }
};

const onClickToggleConfirmContractorInviteModal = () => {
  isShowConfirmContractorInviteModal.value =
    !isShowConfirmContractorInviteModal.value;
};

const onClickTogglePostLoadingModal = () => {
  isPostLoadingModal.value = !isPostLoadingModal.value;
};

const onConfirmInvite = () => {
  if (screenType.value === TRADE_NETWORK_SCREEN) {
    screenType.value = PREVIEW_SCREEN;
    onClickToggleConfirmContractorInviteModal();
    return;
  }
};

const onBack = () => {
  if (screenType.value === DEFAULT) {
    currentSelectedTab.value = modelDetails.value[0];
    emit("on-back");
    return;
  }
  if (screenType.value === ESTIMATE_SCREEN) {
    currentSelectedTab.value = modelDetails.value[0];
    screenType.value = DEFAULT;
    return;
  }
  if (screenType.value === TRADE_NETWORK_SCREEN) {
    currentSelectedTab.value = modelDetails.value[1];
    screenType.value = ESTIMATE_SCREEN;
    historicalProjectForm.value.invitedContractors = [];
    return;
  }
  if (screenType.value === PREVIEW_SCREEN) {
    if (props.workflowType !== HistoricalWorkFlowTypeEnum.CLIENT) {
      historicalProjectForm.value.invitedContractors = [];
    }
    if (
      historicalProjectForm.value.projectType ==
      HistoricalProjectTypeEnum.DIY_PROJECT
    ) {
      screenType.value = ESTIMATE_SCREEN;
      currentSelectedTab.value = modelDetails.value[1];
    } else {
      screenType.value = TRADE_NETWORK_SCREEN;
      currentSelectedTab.value = modelDetails.value[2];
    }
    return;
  }
};

const formErrors = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);

const currentSelectedTab = ref(modelDetails.value[0]);
const estimateErrors = computed(() => {
  return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
});
const customErrors = () => {
  const errors = estimateErrors.value;
  if (formErrors.value.startDate) {
    return ElMessage.error(`Project start Date required.`);
  }

  if (errors) {
    for (let key in errors) {
      if (key !== "subErrors") {
        const mainErrorObj = errors[key];
        for (let errorKey in mainErrorObj) {
          if (
            typeof mainErrorObj[errorKey] !== "object" &&
            mainErrorObj[errorKey]
          ) {
            ElMessage.error(`${mainErrorObj[errorKey]}`);
            return;
          }
        }
      }
    }

    for (let key in errors) {
      if (errors[key].subErrors) {
        const subErrors = errors[key].subErrors;
        for (let subKey in subErrors) {
          const subErrorObj = subErrors[subKey];
          for (let errorKey in subErrorObj) {
            if (subErrorObj[errorKey]) {
              return ElMessage.error(subErrorObj[errorKey]);
            }
          }
        }
      }
    }
  }
};
const onNext = async () => {
  showButtonForGeminiModel.value = false;
  if (!isFormValid.value) return;
  if (screenType.value === DEFAULT) {
    screenType.value = ESTIMATE_SCREEN;
    currentSelectedTab.value = modelDetails.value[1];

    await store.dispatch(`${JOB_TEMPLATE_STORE}/resetErrorFields`);
    return;
  } else if (screenType.value === ESTIMATE_SCREEN) {
    const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`, {
      isDIYProjectType:
        historicalProjectForm.value.projectType ==
        HistoricalProjectTypeEnum.DIY_PROJECT,
    });
    if (isValid?.flag) {
      if (
        historicalProjectForm.value.projectType ==
        HistoricalProjectTypeEnum.DIY_PROJECT
      ) {
        screenType.value = PREVIEW_SCREEN;
        currentSelectedTab.value = modelDetails.value[3];
      } else {
        currentSelectedTab.value = modelDetails.value[2];
        screenType.value = TRADE_NETWORK_SCREEN;
      }
      return;
    } else {
      customErrors();
    }
  } else if (screenType.value === TRADE_NETWORK_SCREEN) {
    if (props.workflowType === HistoricalWorkFlowTypeEnum.CLIENT) {
      screenType.value = PREVIEW_SCREEN;
      currentSelectedTab.value = modelDetails.value[3];
      return;
    }
    if (historicalProjectForm.value.invitedContractors.length) {
      onClickToggleConfirmContractorInviteModal();
      currentSelectedTab.value = modelDetails.value[3];
      return;
    }
  }
};

const removeImageFromAttachments = (index: number) => {
  const attachments = projectAttachments.value;
  attachments.splice(index, 1);

  store.dispatch(`${PROJECT_STORE}/setProjectAttachments`, attachments);
};

const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];

  return estimates;
});
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const customerTotal = computed(() => {
  const total: any =
    totalSubTotal.value + totalVat.value + serviceCharges.value;
  return Math.round(parseFloat(total) * 100) / 100;
});

const totalSubTotal = computed(() => {
  const sum = estimateForm.value.projectStages.reduce(
    (sum: any, phase: any) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );

  return Math.round(sum * 100) / 100;
});

const totalVat = computed(() => {
  const sum = estimateForm.value.projectStages.reduce(
    (sum: any, phase: any) => {
      const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
      return sum + roundedTotalVat;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee = estimateForm.value.projectStages.reduce(
    (sum: any, phase: any) => {
      const roundedTotalWithServiceFee = parseFloat(
        phase.subTotal * serviceFeePercentage
      );
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});
const projectEstimateEndDate = computed(() =>
  estimateForm.value.projectStages
    .filter((phase: any) => phase.stageType === "labour")
    .reduce((endDate: any, phase: any) => {
      return (endDate = moment(endDate).isAfter(moment(phase?.endDate))
        ? moment(endDate).format("YYYY-MM-DD")
        : moment(phase?.endDate).format("YYYY-MM-DD"));
    }, "")
);
const projectEstimatePayload = () => {
  let estimationPhaseData = [] as any;
  if (estimateForm.value) {
    estimateForm.value.projectStages.map((estimate: any, index: number) => {
      const estimateRecord = {
        phaseName: estimate.name,
        phaseType: estimate.stageType === "labour" ? 1 : 2,
        durationType: estimate.durationType,
        phaseStartDate: estimate.startDate,
        phaseEndDate: estimate.endDate,
        vat: estimate.vat,
        subTotal:
          typeof estimate.subTotal === "number"
            ? estimate.subTotal.toString()
            : estimate.subTotal,
        phaseOrder: estimate.phaseOrder,
        estimationPhaseTasks: [],
        estimationPhaseProperties: estimate.estimationPhaseProperties,
        color: estimate.color,
        user: { id: estimate?.user?.id },
        workStation: { id: estimate?.workStation?.id },
        estimatePhaseAttachment: estimate.attachments.map((data: any) => {
          return data.uploadedImages.id;
        }),
      };

      if (estimate?.projectStageTasks && estimate?.projectStageTasks?.length) {
        estimate.projectStageTasks.map((task: any) => {
          const estimateTask: any = {
            name: task.name,
            phaseTaskStartDate: task.startDate,
            phaseTaskEndDate: task.endDate,
            durationType: task.durationType,
            taskCost:
              typeof task.taskCost === "number"
                ? task.taskCost.toString()
                : task.taskCost,
            estimationPhaseTasksProperties: task.estimationPhaseTasksProperties,
            materialUnit: task.materialUnit?.toString(),
          } as any;

          estimateRecord.estimationPhaseTasks.push(estimateTask);
        });
      }
      estimationPhaseData.push(estimateRecord);
    });
  }
  const payload = {
    id: formData?.value.id,
    grantType: formData?.value.grantType,
    grantName: formData?.value.grantName,
    grantAmount: formData?.value.grantAmount,
    totalEstimateCost:
      historicalProjectForm.value.projectType ==
      HistoricalProjectTypeEnum.DIY_PROJECT
        ? formData.value?.totalCost || 0
        : customerTotal.value,
    suggestedStartDate: formData?.value.suggestedStartDate,
    estimationPhaseData,
  };
  return payload;
};

const onSaveProject = async () => {
  try {
    isLoading.value = true;
    historicalProjectForm.value.attachments =
      await uploadHistoricalAttachment();
    const filterInviteMembers =
      historicalProjectForm.value?.invitedContractors.map((member: any) => {
        return { email: member?.email, userId: member?.toUser?.id };
      });

    const projectSpecialismsIds = projectSpecialisms.value.map((data: any) => {
      return data.id;
    });

    const payload = {
      updateProjectId: getCurrentHistoricalDraftProject.value?.id || null,
      propertyId: propertyDetails.value?.localData.id,
      projectJobStatusId: 0,
      name: historicalProjectForm.value?.title,
      description: historicalProjectForm.value?.description,
      startDate: formData.value.suggestedStartDate,
      endDate: isValidDate(projectEstimateEndDate.value)
        ? projectEstimateEndDate.value
        : formData.value.suggestedStartDate,
      historicalProjectType: historicalProjectForm.value?.projectType,
      inviteMembers: filterInviteMembers,
      attachments: historicalProjectForm.value?.attachments,
      projectEstimate: projectEstimatePayload(),
      categories: projectSpecialismsIds,
      workflowType: props.workflowType,
    };
    if (
      historicalProjectForm.value.projectType ==
      HistoricalProjectTypeEnum.DIY_PROJECT
    ) {
      delete payload.endDate;
    }
    const formDataObj = new FormData();
    estimateForm.value?.projectStages?.map((stage: any) => {
      stage.attachments?.forEach((attachment: any, index: number) => {
        formDataObj.append(
          `${attachment.uploadedImages.id}`,
          attachment.uploadedImages.imageData
        );
      });
    });
    formDataObj.append("projectData", JSON.stringify(payload));
    await historicalProjectService.createHistoricalProject(formDataObj);
    onClickTogglePostLoadingModal();
  } catch (error) {
    console.log("error", error);
  } finally {
    resetHistoricalData();
    isLoading.value = false;
    onClickToggleConfirmSave();
  }
};
const getCurrentHistoricalDraftProject = computed(
  () => store.getters[`${JOBS_STORE}/getCurrentHistoricalDraftProject`]
);
const getUploadedAttachment = async () => {
  try {
    if (!projectAttachments.value?.length) return;
    const formData = new FormData();
    projectAttachments.value.forEach((attachment: any) => {
      if (attachment?.uploadedImages?.imageData)
        formData.append(`files`, attachment.uploadedImages.imageData);
    });
    const uploadMultipleImage = await store.dispatch(
      `${USER_STORE}/uploadMultipleImage`,
      formData
    );

    return uploadMultipleImage;
  } catch (error) {
    console.log("error", error);
  }
};
const isSaveDraftloading = ref(false);
const saveHistoricalProjectToDraft = async () => {
  try {
    if (!isFormValid.value) return;
    isSaveDraftloading.value = true;
    historicalProjectForm.value.attachments = projectAttachments.value;
    const filterInviteMembers =
      historicalProjectForm.value?.invitedContractors.map((member: any) => {
        return { email: member?.email, userId: member?.toUser?.id };
      });

    const projectSpecialismsIds = projectSpecialisms.value.map((data: any) => {
      return data.id;
    });

    const payload = {
      propertyId: propertyDetails.value?.localData.id,
      projectJobStatusId: 0,
      name: historicalProjectForm.value?.title,
      description: historicalProjectForm.value?.description,
      startDate: formData.value.suggestedStartDate,
      endDate: isValidDate(projectEstimateEndDate.value)
        ? projectEstimateEndDate.value
        : formData.value.suggestedStartDate,
      historicalProjectType: historicalProjectForm.value?.projectType,
      inviteMembers: filterInviteMembers,
      attachments: historicalProjectForm.value?.attachments,
      projectEstimate: projectEstimatePayload(),
      categories: projectSpecialismsIds,
      workflowType: props.workflowType,
    };
    if (
      historicalProjectForm.value.projectType ==
      HistoricalProjectTypeEnum.DIY_PROJECT
    ) {
      delete payload.endDate;
    }
    const uploadedAttachment = await getUploadedAttachment();
    const draftProjectPayload = {
      updateProjectId: getCurrentHistoricalDraftProject.value?.id || null,
      propertyId: payload.propertyId,
      name: payload.name,
      description: payload.description,
      historicalProjectType: payload.historicalProjectType,
      draftHistoricalProjectData: JSON.stringify({
        estimateForm: estimateForm.value,
        formData: formData.value,
        historicalProjectForm: historicalProjectForm.value,
        projectSpecialisms: projectSpecialisms.value,
        uploadedAttachment,
      }),
    };

    await store.dispatch(
      `${JOBS_STORE}/saveDraftHistoricalProjects`,
      draftProjectPayload
    );
    displayToastMessage(
      internalInstance,
      `Save Project To Draft Successfully !`,
      "success"
    );
  } catch (error) {
    console.log("save draft project error", error);
  } finally {
    isSaveDraftloading.value = false;
  }
};
const resetHistoricalData = () => {
  // empty Attachments
  store.dispatch(`${PROJECT_STORE}/setProjectAttachments`, []);
  // empty specialisms
  store.dispatch(`${PROJECT_STORE}/setProjectSpecialisms`, []);
  store.dispatch(`${JOB_TEMPLATE_STORE}/resetFormAndData`);
};
const onConfirmProjectType = (type: HistoricalProjectTypeEnum) => {
  if (type === HistoricalProjectTypeEnum.TRADE_VERIFY) {
    historicalProjectForm.value.projectType =
      HistoricalProjectTypeEnum.DIY_PROJECT as any;
  } else {
    historicalProjectForm.value.projectType =
      HistoricalProjectTypeEnum.TRADE_VERIFY as any;
  }
  onClickToggleConfirmContractorInviteModal();
};
const onCancelProjectType = () => {
  onConfirmInvite();
  historicalProjectForm.value.projectType =
    HistoricalProjectTypeEnum.DIY_PROJECT as any;
};
const onSaveSuccess = () => {
  onClickTogglePostLoadingModal();
  emit("on-back", true);
  emit("onHistoricProjectSaved");
};

const onSelectContractors = (selectedContractors: any) => {
  historicalProjectForm.value.invitedContractors = selectedContractors;
};
const propertyProjects = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyProjects`]
);
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const onClickEditDetailsSave = (details: any) => {
  if (details.title) {
    historicalProjectForm.value.title = details.title;
  }
  if (details.description) {
    historicalProjectForm.value.description = details.description;
  }
};
const specialismCategoryClass = (val: any) => {
  if (val.toLowerCase() === "krystal") {
    return "krystal";
  }
  if (val.toLowerCase() === "property") {
    return "property";
  }
  if (val.toLowerCase() === "trade") {
    return "trade";
  }
};
const uploadHistoricalAttachment = async () => {
  if (projectAttachments.value.length) {
    try {
      const formData = new FormData();
      const newAttachment = [];
      projectAttachments.value.forEach((attachment: any) => {
        if (!attachment?.isUploaded) {
          formData.append(`files`, attachment.uploadedImages.imageData);
          newAttachment.push(attachment);
        }
      });
      const uploadMultipleImage = await store.dispatch(
        `${USER_STORE}/uploadMultipleImage`,
        formData
      );
      const alreadyUploadedAttachment = projectAttachments.value.filter(
        (attachment: any) => attachment?.isUploaded
      );
      const attachmentCategoryData: any = [];

      for (const attachment of cloneAttachmentDataList.value) {
        const existCategory = attachmentCategoryData.find(
          (category: any) => category.label === attachment.userCategoryName
        );
        if (existCategory) {
          attachmentCategoryData.push(existCategory);
        } else {
          const response = await store.dispatch(
            `${PROJECT_STORE}/attachmentCategory`,
            {
              label: attachment.userCategoryName,
              value: attachment.userCategoryName,
            }
          );
          attachmentCategoryData.push(response);
        }
      }

      const attachments: any = [
        ...uploadMultipleImage,
        ...alreadyUploadedAttachment,
      ].map((item, index) => {
        const attachment = projectAttachments.value.find(
          (attachment: any) =>
            attachment.uploadedImages.name === item.originalName
        );
        const category = attachmentCategoryData.find(
          (category: any) => category.label === attachment.userCategoryName
        );
        return {
          publicUrl: item.publicUrl,
          attachment: item.attachment,
          originalName: item.originalName,
          propertyIds: [
            {
              propertyId: propertyDetails.value?.localData.id,
              isDeleted: false,
            },
          ],
          attachmentCategoryId: category?.id,
          isDeleted: false,
        };
      });
      return attachments;
    } catch (error) {
      console.log();
      return [];
    }
  }
};
const onClickSaveRecord = (payload: any) => {
  cloneAttachmentDataList.value = payload;
  onClickToggleConfirmSave();
};

const showButtonForGeminiModel = ref(false);
const vuetify = useDisplay();
const geminiModalMenu = ref(false);
const quillInstance = ref();
const quillCursorIndex = ref(0);
const isTitleKrystalIconClick = ref(false);
const showGeminiModel = ref(false);
const showGeminiModelForMobile = ref(false);
const isMobileView = computed(() => vuetify.smAndDown.value);
const onEditorReady = (quill: any) => {
  quillInstance.value = quill;
};

onUpdated(() => {
  if (currentSelectedTab.value?.id !== 1) return;
  const toolbarElement = document.querySelector(".ql-toolbar") as HTMLElement;
  if (!toolbarElement) return;
  const topPadding = toolbarElement?.offsetHeight || 63;
  if (isMobileView.value) {
    const cursorBox: any = document.getElementById(
      "quill-cursor-box-mobile"
    ) as HTMLElement;
    if (cursorBox) cursorBox.style.top = `${topPadding + 12 + 67}px`;
  } else {
    const cursorBox: any = document.getElementById("quill-cursor-box");
    if (cursorBox) cursorBox.style.top = `${topPadding + 12 + 67}px`;
  }
});

const onEditorChange = ({
  quill,
  html,
  text,
}: {
  quill: any;
  html: string;
  text: string;
}) => {
  try {
    quillInstance.value = quill;
    showButtonForGeminiModel.value = false;

    const range = quill.getSelection();

    const [clientX, clientY]: any = getCaretCoordinates(quill, range?.index);

    const toolbarElement = document.querySelector(".ql-toolbar") as HTMLElement;
    const topPadding = toolbarElement.offsetHeight;

    if (isMobileView.value) {
      const cursorBox: any = document.getElementById("quill-cursor-box-mobile");
      if (cursorBox) {
        cursorBox.style.top = `${clientY + topPadding + 67}px`;
        cursorBox.style.left = `${clientX + 10}px`;
      }
    } else {
      const cursorBox: any = document.getElementById("quill-cursor-box");
      if (cursorBox) {
        cursorBox.style.top = `${clientY + topPadding + 67}px`;
        cursorBox.style.left = `${clientX + 10}px`;
      }
    }

    state._content = html;
    quillCursorIndex.value = range?.index;
    showButtonForGeminiModel.value = true;
  } catch (error) {
    console.log(error);
  }
};

const getCaretCoordinates = (quill: any, index: any) => {
  try {
    const editorBounds = quill?.container.getBoundingClientRect();
    const range = document.createRange();
    const leaf = quill?.getLeaf(index)[0];
    const node = leaf?.domNode;
    const offset = index - leaf?.offset(quill.root);

    range.setStart(node, offset);
    range.setEnd(node, offset);

    const rect = range.getBoundingClientRect();
    return [rect.left - editorBounds?.left, rect.top - editorBounds?.top];
  } catch (error) {
    console.log(error);
  }
};
const appendJobDescription = (description: string) => {
  if (isTitleKrystalIconClick.value) {
    historicalProjectForm.value.title = description.replaceAll("\n", " ");
    isTitleKrystalIconClick.value = false;
  } else {
    quillInstance.value.focus();
    quillInstance.value?.insertText(
      quillCursorIndex.value,
      description.replaceAll("\n", " ")
    );
  }
  onCloseHandler();
};
const onCloseHandler = () => {
  geminiModalMenu.value = false;
  showGeminiModel.value = false;
  showGeminiModelForMobile.value = false;
  quillInstance?.value?.focus();
};

const onEditorFocus = (quill: any) => {
  try {
    showButtonForGeminiModel.value = true;
    quillInstance.value = quill;
    const range = quill.getSelection();
    quill.insertText(range?.index, "");
  } catch (error) {
    console.log(error);
  }
};
const onClickTitleKrystalIcon = () => {
  isTitleKrystalIconClick.value = true;
  showGeminiModelForMobile.value = true;
};

watch(
  () => isMobileView.value,
  () => {
    showButtonForGeminiModel.value = false;
  }
);

onBeforeMount(() => {
  if (props.isHistoricalDraftProjectFlow) {
    const draftHistoricalProjectData = parseJSON(
      getCurrentHistoricalDraftProject.value?.draftHistoricalProjectData
    );
    if (draftHistoricalProjectData?.historicalProjectForm) {
      historicalProjectForm.value =
        draftHistoricalProjectData.historicalProjectForm;
    }
  } else {
    if (props.selectedClient)
      historicalProjectForm.value.invitedContractors = [props.selectedClient];

    resetHistoricalData();
  }
});
</script>
<style lang="scss" scoped>
.property_details_wrapper {
  text-align: left;
  height: calc(100dvh - 230px);
  overflow: auto;
  margin: 32px 0 0;

  @include respond(xlg) {
    height: calc(100dvh - 300px);
  }

  .back_button {
    text-transform: capitalize !important;
    padding: 3px 8px;
    background-color: rgba($white, 1);
    border-radius: 50px;
    display: flex;
    height: auto;

    &:hover {
      .overlay {
        opacity: 0;
      }
    }

    :deep(.v-btn__underlay) {
      background: rgba($white, 1);
      opacity: 0;
    }

    :deep(.v-btn__prepend) {
      margin: 0;
    }

    :deep(.v-btn__content) {
      @include fluidFont(14, 14, 1.2);
      font-weight: 600;
      color: rgba($blueDark, 1);
      letter-spacing: 0.4px;
    }

    .v-ripple__container {
      display: none;
    }
  }

  .historic_wrapper_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed rgba($blueDark, 0.5);
    padding-bottom: 16px;
    margin-top: 16px;

    @include respond(s720) {
      flex-direction: column;
      gap: 16px;
    }

    .left_block {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h5 {
        display: flex;
        align-items: center;
        gap: 8px;
        @include fluidFont(17, 17, 1.3);
        font-weight: 700;
        color: rgba($PrimaryBlue, 1);

        .count_wrapper {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background-color: rgba($PrimaryBlue, 1);
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba($white, 1);
          @include fluidFont(10, 10, 1.3);
        }
      }

      p {
        @include fluidFont(25, 25, 1.3);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
    }

    .right_block {
      width: 342px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include respond(s720) {
        width: 100%;
        max-width: 100%;
      }

      .button {
        width: 100%;
        max-width: 100%;
      }

      .bright-blue-text {
        height: auto;
        @include fluidFont(12, 12, 2);
        font-weight: 600;
        letter-spacing: 0.15px;
        color: rgba($info, 1);
      }
    }
  }

  .historic_wrapper_content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 70%;
    max-width: 100%;
    margin-top: 20px;
    text-align: left;

    @include respond(s720) {
      width: 100%;
    }

    .historic_wrapper_content_item {
      padding-bottom: 16px;
      border-bottom: 1px dashed rgba($blueDark, 1);

      h4 {
        @include fluidFont(25, 25, 1.2);
        font-weight: 400;
        letter-spacing: 0.15px;
        color: rgba($blueDark, 1);
        display: flex;
        gap: 4px;
        align-items: center;
      }

      p {
        @include fluidFont(14, 14, 1.4);
        font-weight: 500;
        letter-spacing: 0.15px;
        color: rgba($blueDark, 0.5);
        margin-top: 10px;
      }

      .historic_content_item_title {
        margin-top: 22px;

        h6 {
          @include fluidFont(16, 16, 1.4);
          font-weight: 600;
          letter-spacing: 0.25px;
          color: rgba($blueDark, 1);
        }

        .custom_input_field {
          width: 100%;
          max-width: 100%;
          margin-top: 6px;

          :deep(.v-input__control) {
            .v-field {
              padding: 0;
              border-radius: 8px;
              background-color: rgba($backgroundcolor, 1);
              border: 1px solid rgba($bordercolor, 1);

              .v-field__field {
                .v-field__input {
                  .v-select__selection {
                    .v-select__selection-text {
                      @include fluidFont(14, 14, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.4px;
                    }
                  }

                  input {
                    @include fluidFont(14, 14, 1.2);
                    font-weight: 400;
                    letter-spacing: 0.4px;
                    color: rgba($blueDark, 1);
                    top: 13px;

                    &::placeholder {
                      @include fluidFont(14, 14, 1.2);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      color: rgba($blueDark, 1);
                      top: 13px;
                    }
                  }
                }
              }

              .v-field__outline {
                display: none;
              }
            }
          }
        }
      }

      .projecttitle_select {
        padding-bottom: 22px;

        :deep(.v-input__control) {
          .v-field {
            .v-field__field {
              .v-field__input {
                @include fluidFont(36, 28, 3);
                font-weight: 600;
                color: rgba($blueDark, 1);
                height: 55px;
                max-height: 45px;
              }
            }
          }
        }

        :deep(.v-input__details) {
          .v-messages {
            .v-messages__message {
              text-align: left;
            }
          }
        }
      }

      .custom_editor {
        border: 1px solid rgba($bordercolor, 1);
        border-radius: 8px;
        overflow: hidden;
        :deep(.ql-toolbar) {
          border: none;
          background-color: rgba($backgroundcolor, 1);
          text-align: left;
          padding: 1.2rem;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(12, 15, 74, 0.2);
        }

        .editor_ql {
          background-color: rgba($backgroundcolor, 1);
          height: 170px;
          border: none;
          @include respond(s720) {
            height: 140px;
          }
        }
      }
    }
  }
}

.historic_content_media_upload {
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba($backgroundcolor, 1);
  border-radius: 12px;
  border: 1px solid rgba($bordercolor, 1);
  padding: 9px 16px;
  margin: 16px 0 0;

  .no-dox {
    margin: 0 !important;
  }

  .icon_text_button {
    @include fluidFont(12, 12, 1.3);
    font-weight: 400;
    color: rgba($blueDark, 1);
    text-transform: capitalize;
    padding: 0;
    height: auto;

    :deep(.v-btn__prepend) {
      margin: 0;

      .v-icon {
        color: rgba($orange, 1);
      }
    }
  }
}

.temp-box {
  position: absolute;
  top: 76px;
  left: 22px;
  z-index: 1000;
  height: 32px;
  width: 32px;
  box-shadow: var(
    --elevation-200-canvas,
    0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 5px 8px rgba(0, 0, 0, 0.3)
  );
  border-radius: 0px 16px 16px 16px;
  background: #0d99ff;
  background-image: url(../../../assets/icons/krystal.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.desktop__cursor,
.desktop__wrapper {
  display: block;

  @include respond(s720) {
    display: none;
  }
}

.mobile__cursor,
.mobile__wrapper {
  display: none;

  @include respond(s720) {
    display: block;
  }
}

.text__editor {
  position: relative;
}
.property_details_wrapper:has(.estimate-gant-chart) .property_estimate_content {
  display: block;
}
.property_details_wrapper:has(.estimate-gant-chart)
  .property_estimate_content
  .left_block {
  width: 100%;
}
</style>
