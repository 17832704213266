<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.26"
      d="M4.28214 17.7285C3.82665 17.9607 3.3098 17.5538 3.40184 17.0343L4.38126 11.4877L0.224039 7.55231C-0.164188 7.1841 0.0375957 6.511 0.557986 6.43829L6.33774 5.62213L8.91491 0.548078C9.14738 0.0907449 9.77633 0.0907449 10.0088 0.548078L12.586 5.62213L18.3657 6.43829C18.8861 6.511 19.0879 7.1841 18.6985 7.55231L14.5424 11.4877L15.5219 17.0343C15.6139 17.5538 15.0971 17.9607 14.6416 17.7285L9.46008 15.0831L4.28214 17.7285Z"
      fill="#0C0F4A"
    />
  </svg>
</template>
<script>
export default {
  name: "EmptyStarIcon",
};
</script>
