<template>
  <CommonDialog
    v-model="isShowSuccessfullModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    persistent
    :className="['solo-trader__last__step__box', 'workstation_create_modal']"
  >
    <template v-slot:body>
      <div class="tw-flex tw-flex-col tw-gap-4 rmd:tw-gap-10">
        <div>
          <div class="header-title">
            Great job,
            <span>
              {{
                activeUserWorkstation?.user?.firstName ||
                activeUserWorkstation?.name
              }}</span
            >
          </div>
          <div class="header-sub-title">
            You have successfully created your Trade Workstation.
          </div>
        </div>
        <div class="custom__modal__img">
          <img
            src="../../../assets/images/ws-creation-success-image.png"
            alt="Create Workstation"
          />
        </div>
        <Text variant="h6" textWeight="400" textColor="rgba(12, 15, 74, 0.5)"
          >Time to take your business sky high and supercharge your skills!
          Start by completing your Trade Passport.</Text
        >
      </div>
      <!-- <v-btn
        class="button button-orange !tw-mt-[16px] rmd:!tw-mt-[24px]"
        size="large"
        block
        @click="onClickSoloTraderDashboard"
      >
        continue 🚀
      </v-btn> -->
      <Button
        class="!tw-mt-[16px] rmd:!tw-mt-[24px]"
        @click="onClickSoloTraderDashboard"
        label="continue 🚀"
      ></Button>
    </template>
  </CommonDialog>
</template>
<script setup>
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import CommonDialog from "@/core/components/CommonDialog.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";

const internalInstance = getCurrentInstance();

const store = useStore();

const emits = defineEmits(["on-close"]);
const isShowSuccessfullModal = ref(true);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const displayToast = async (message, type) => {
  if (
    internalInstance &&
    internalInstance.appContext.config.globalProperties.$notify
  ) {
    internalInstance.appContext.config.globalProperties.$notify[type]({
      message,
    });
  }
};

const onClickSoloTraderDashboard = async () => {
  store.dispatch(`${WORKSTATION}/setWsModal`, {
    isShowWorkStationModal: false,
    isShowSoleTraderWorkStation: false,
    isShowLimitedCompanyWorkStation: false,
    activeSoleTraderWorkStationModal: 1,
    activeLimitedCompanyWorkStationModal: 1,
  });
  //   await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
  //   const success_payment_with_reserve_spots = window.localStorage.getItem(
  //     "success_payment_with_reserve_spots_data"
  //   );

  //   if (success_payment_with_reserve_spots !== "null") {
  //     await displayToast(
  //       "Reserved spot(s) have been successfully assigned to this workstation.",
  //       "success"
  //     );
  //     setTimeout(() => {
  //       window.localStorage.removeItem("success_payment_with_reserve_spots_data");
  //     }, 500);
  //   }
  emits("on-close");
};
</script>
<style lang="scss" scoped>
@import "@/modules/workstation/soletrader/styles/SoleTraderWorkStationComponent.scss";
</style>
