<template>
  <GeneralDialog class="tw-max-w-[450px]" @on-close="onCloseModal">
    <template #body>
      <div>
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <Text variant="h4" textAlign="center" lineHeight="28px">
            Dispute Confirmation
          </Text>
          <Text
            class="tw-max-w-[80%] tw-mt-6"
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="center"
          >
            Are you sure you want to close the dispute?
          </Text>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button variant="secondary" label="cancel" @click="onCloseModal" />
        <Button variant="blue" label="confirm" @click="onConfirmModal" />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import GeneralDialog from "@/core/uni-components/GeneralDialog.vue";
import Button from "@/core/uni-components/Button.vue";
import Text from "@/core/uni-components/Text.vue";
import { ref } from "vue";

const emits = defineEmits(["on-close", "on-proceedCloseDispute"]);

const onCloseModal = () => {
  emits("on-close");
};
const onConfirmModal = () => {
  emits("on-proceedCloseDispute");
};
</script>
