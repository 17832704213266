export default {
  tradeNetworkContractorList: [],
  userNetworkContractorList: [],
  userNetworkConnectedList: [],
  isClearFilter: false,
  isInviteContractorFromTradeNetwork: false,
  externalInvitedContractorList: [],
  projectData: {},
  totalPages: 0,
  totalConnectionTabPages: 0,
  tradeNetworkContractorCount: 0,
  allUserConectionContractorCount: 0,
  isExternalTradeRequestSuccess: false,
};
