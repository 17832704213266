<template>
  <GeneralDialog
    class="accept_project_estimate_modal"
    @on-close="onCloseModal"
    width="460px"
    max-width="100%"
  >
    <template #body>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <ContractAcceptSignYellowIcon />
        <Text
          variant="h4"
          textAlign="center"
          lineHeight="28px"
          class="tw-max-w-[70%] md:tw-max-w-full"
          >Are you happy to accept the estimate?</Text
        >
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
      >
        <Text
          class="tw-max-w-[80%] tw-mt-6"
          variant="h6"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          textAlign="center"
          >All estimates need to follow our
          <span class="!tw-text-[orange]">Terms & Conditions</span></Text
        >
      </div>
    </template>
    <template #footer>
      <div class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3">
        <Button
          variant="tertiary"
          :disabled="props.isLoading"
          label="Cancel"
          letterSpacing="1.25px"
          @click="onCloseModal"
          class="button button-purple-border"
        />
        <Button
          label="Accept"
          :isLoading="props.isLoading"
          @click="onConfirm"
          letterSpacing="1.25px"
          class="button button-orange"
        />
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ContractSignYellowIcon from "@/core/components/icons/ContractSignYellowIcon.vue";
import { defineEmits } from "vue";
const emits = defineEmits(["on-close", "on-save"]);
const props = defineProps({
  isLoading: {
    default: false,
    type: Boolean,
  },
});
const onCloseModal = () => {
  emits("on-close");
};

const onConfirm = () => {
  emits("on-save");
};
</script>
