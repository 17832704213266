<template>
  <CollapsibleWidgetSlot
    title="Projects"
    :hasActions="true"
    :hideDivider="true"
  >
    <template #content>
      <section
        class="tw-w-full tw-flex tw-justify-around tw-gap-4 tw-py-4 tw-px-8 tw-box-border tw-border-t-solid-custom"
      >
        <div
          v-for="(item, index) in getFilterProjectSummary"
          :key="index"
          class="tw-flex tw-flex-col tw-gap-0 tw-items-center tw-justify-center"
          :class="{ '!tw-hidden': !item.showInPreview }"
        >
          <div
            class="tw-min-w-[70px] tw-w-[70px] tw-h-[70px] tw-relative tw-flex tw-items-center tw-justify-center"
          >
            <component
              :is="item.ellipsisComponent"
              class="tw-absolute tw-w-full tw-h-full tw-top-0 tw-bottom-0 tw-left-0"
            />
            <Text variant="h4">{{ item.count }}</Text>
          </div>
          <Text variant="span" textWeight="700">{{ item.title }}</Text>
        </div>
      </section>
    </template>
    <template #actions>
      <div class="tw-w-full tw-flex tw-items-center tw-justify-end">
        <div class="tw-flex tw-gap-0 tw-items-center tw-cursor-pointer">
          <Text variant="p" textColor="#FFA500" @click="onClickViewAll"
            >View All</Text
          >
          <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
        </div>
      </div>
    </template>
  </CollapsibleWidgetSlot>
</template>
<script setup>
import { onMounted, reactive } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CollapsibleWidgetSlot from "@/core/components/slots/CollapsibleWidgetSlot.vue";
import InProgressIcon from "@/core/components/icons/InProgressIcon.vue";
import IssueRaisedIcon from "@/core/components/icons/IssueRaisedIcon.vue";
import EstimateReceivedIcon from "@/core/components/icons/EstimateReceivedIcon.vue";
import ProjectPostedIcon from "@/core/components/icons/ProjectPostedIcon.vue";
import NotStartedIcon from "@/core/components/icons/NotStartedIcon.vue";
import DraftJobsIcon from "@/core/components/icons/DraftJobsIcon.vue";
import { ISSUE_ROUTE } from "@/modules/issues/routes";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { PROPERTY_STORE } from "@/store/modules/property";
import { computed } from "vue";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import EllipsisGreen from "@/core/components/icons/EllipsisGreen.vue";
import EllipsisBlue from "@/core/components/icons/EllipsisBlue.vue";
import EllipsisDarkGreen from "@/core/components/icons/EllipsisDarkGreen.vue";

const inProgress = InProgressIcon;
const estimateReceived = EstimateReceivedIcon;
const notStarted = NotStartedIcon;
const draftJobs = DraftJobsIcon;
const issueRaised = IssueRaisedIcon;
const ProjectPosted = ProjectPostedIcon;

const ellipsisGreen = EllipsisGreen;
const ellipsisBlue = EllipsisBlue;
const ellipsisDarkGreen = EllipsisDarkGreen;

const router = useRouter();
const store = useStore();
const redirectToRaiseIssue = () => {
  router.push({ name: ISSUE_ROUTE });
};

const emits = defineEmits(["route-to-project-logs"]);
// const items = reactive([
//   {
//     icon: inProgress,
//     title: "In progress",
//     statusId: [ProjectJobStatusEnum.IN_PROGRESS],
//   },
//   {
//     icon: issueRaised,
//     title: "Issues Raised",
//     hasButton: true,
//     statusId: ["7"],
//   },
//   {
//     icon: estimateReceived,
//     title: "Estimates received",
//     statusId: [ProjectJobStatusEnum.QUOTES_RECEIVED],
//   },
//   {
//     icon: notStarted,
//     title: "Not Started",
//     statusId: [
//       ProjectJobStatusEnum.AWAITING_QUOTES,
//       ProjectJobStatusEnum.QUOTES_RECEIVED,
//       ProjectJobStatusEnum.DRAFT_PROJECT,
//     ],
//   },
//   {
//     icon: draftJobs,
//     title: "Draft Projects",
//     statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
//   },
//   {
//     icon: ProjectPosted,
//     title: "Project Posted",
//     noBorder: true,
//     statusId: [ProjectJobStatusEnum.AWAITING_QUOTES],
//   },
// ]);

const items = reactive([
  {
    icon: inProgress,
    title: "In progress",
    statusId: [
      ProjectJobStatusEnum.IN_PROGRESS,
      ProjectJobStatusEnum.QUOTE_ACCEPTED,
    ],
    ellipsisComponent: ellipsisGreen,
    showInPreview: true,
  },
  {
    icon: estimateReceived,
    title: "Estimates received",
    statusId: [ProjectJobStatusEnum.QUOTES_RECEIVED],
    ellipsisComponent: ellipsisBlue,
    showInPreview: true,
  },
  {
    icon: estimateReceived,
    title: "Completed",
    statusId: [ProjectJobStatusEnum.COMPLETED],
    ellipsisComponent: ellipsisDarkGreen,
    showInPreview: true,
  },
  {
    icon: issueRaised,
    title: "Issues Raised",
    hasButton: true,
    statusId: ["7"],
    showInPreview: false,
  },
  {
    icon: notStarted,
    title: "Not Started",
    statusId: [
      ProjectJobStatusEnum.AWAITING_QUOTES,
      ProjectJobStatusEnum.QUOTES_RECEIVED,
      ProjectJobStatusEnum.DRAFT_PROJECT,
    ],
    showInPreview: false,
  },
  {
    icon: draftJobs,
    title: "Draft Projects",
    statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
    showInPreview: false,
  },
  {
    icon: ProjectPosted,
    title: "Project Posted",
    noBorder: true,
    statusId: [ProjectJobStatusEnum.AWAITING_QUOTES],
    showInPreview: false,
  },
]);

const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]?.localData
);

const getPropertyProjectSummaryList = computed(
  () => store.getters[`${PROPERTY_STORE}/getPropertyProjectSummaryList`]
);
const getFilterProjectSummary = computed(() => {
  return items.map((item) => ({
    ...item,
    count: getPropertyProjectSummaryList.value.reduce(
      (count, project) =>
        item.statusId.includes(project.jobStatusId)
          ? count + (Number(project.projectCount) || 0)
          : count,
      0
    ),
  }));
});

const onClickViewAll = () => {
  emits("route-to-project-logs");
};
onMounted(async () => {
  if (propertyDetails.value?.id) {
    await store.dispatch(
      `${PROPERTY_STORE}/setPropertyProjectSummary`,

      propertyDetails.value.id
    );
  }
});
</script>
<style lang="scss" scoped></style>
