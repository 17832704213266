<template>
  <div class="top_content md:!tw-mt-0">
    <v-btn
      variant="text"
      aria-label="Refresh"
      class="button button-transparent back_btn"
      prepend-icon="mdi-chevron-left"
      @click="onClickBackButton"
      >{{ getBackButtonLabel }}</v-btn
    >
  </div>
  <div
    class="step__third md:!tw-gap-4 md:!tw-h-[calc(100%-3rem)] property-select-start"
    :class="{ 'project-specifics-box': currentSelectedTab.id === 4 }"
  >
    <div
      v-if="!isShowProjectReview"
      class="timeline__wrapper push-changes testing-start md:!tw-h-[unset]"
    >
      <div class="timeline__left">
        <div>
          <div
            v-if="
              [
                ProjectTypesEnum.STANDARD,
                ProjectTypesEnum.ROUTINE,
                ProjectTypesEnum.VIDEO_CONSULTATION,
              ].includes(userProjectType) && currentSelectedTab.id === 3
            "
            class="routine__timeline"
          >
            <div class="">
              <div class="property_badge">
                <div class="notification">
                  {{ currentSelectedTab.id }}
                </div>
                <div class="property_badge_text">
                  {{ currentSelectedTab.name }}
                </div>
              </div>

              <div class="main_titlesection md:!tw-mt-[2px]">
                <h3
                  class="md:!tw-text-[18px]"
                  v-if="
                    [
                      ProjectTypesEnum.STANDARD,
                      ProjectTypesEnum.VIDEO_CONSULTATION,
                    ].includes(userProjectType)
                  "
                >
                  When are you looking to start this project:
                </h3>
                <h3 v-else class="md:!tw-text-[18px]">
                  How often do you need this project to occur?
                </h3>
              </div>

              <div class="project-steps-content">
                <div
                  class="project-service-list third_step routine__timeline__step"
                >
                  <div class="project-service-list-content">
                    <div class="project-service-list-card">
                      <v-card
                        class="project-service-item"
                        v-for="item in getProjectTimelineList"
                        :key="item.id"
                        :class="{ selected: item.selected }"
                        @click="toggleSelectionType(item.name)"
                      >
                        <div class="project-service-item__text">
                          <h6>{{ item.name }}</h6>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- <div class="footer__btn">
                    <v-btn
                      aria-label="Refresh"
                      class="button button-purple"
                      @click="onClickToggleContinue"
                      :disabled="!isProjectTypeSelected"
                      >continue</v-btn
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="editor__wrapper"
          v-if="isShowJobSpecifics && currentSelectedTab.id == 4"
        >
          <div class="property_badge">
            <div class="notification">
              {{ currentSelectedTab.id }}
            </div>
            <div class="property_badge_text">
              {{ currentSelectedTab.name }}
            </div>
          </div>

          <div class="main_titlesection md:!tw-mt-[2px]">
            <h3 class="md:!tw-text-[18px]">{{ currentSelectedTab.title }}</h3>
          </div>

          <div class="text__editor tw-pt-4">
            <v-text-field
              class="projecttitle_select md:!tw-text-[16px] md:!tw-pt-0"
              placeholder="Project Title/ Name"
              v-model="projectTitle"
              variant="underlined"
              density="compact"
              :rules="validation.projectTitle"
            >
              <template v-slot:append-inner>
                <div @click="onClickTitleKrystalIcon">
                  <img src="@/assets/images/krystal-purple.svg" alt="" />
                </div>
              </template>
            </v-text-field>
            <div class="custom_editor">
              <!-- <div
                v-show="showButtonForGeminiModel"
                id="quill-cursor-box"
                class="temp-box desktop__cursor"
              ></div>
              <v-menu
                v-model="geminiModalMenu"
                activator="#quill-cursor-box"
                transition="scale-transition"
                target="cursor"
                location="bottom"
                :close-on-content-click="false"
                :close-on-back="false"
                class="desktop__cursor"
              >
                <GeminiModel
                  @click.stop
                  @appendJobDescription="appendJobDescription"
                  @onClose="onCloseHandler"
                />
              </v-menu> -->
              <div
                v-show="showButtonForGeminiModel"
                id="quill-cursor-box-mobile"
                class="temp-box mobile__cursor"
                @click="showGeminiModelForMobile = !showGeminiModelForMobile"
              ></div>
              <quillEditor
                v-model:value="state.content"
                :options="state.editorOption"
                :disabled="state.disabled"
                @change="onEditorChange($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady"
                class="editor_ql"
              />

              <div class="gemini-model-wrapper">
                <v-btn
                  v-if="!isMobileView"
                  @click="showGeminiModelForMobile = !showGeminiModelForMobile"
                  class="button button-purple krystal-trigger desktop-model"
                  append-icon="mdi-chevron-right"
                >
                  <img src="../../../assets/images/krystal-purple.svg" alt="" />
                  KRYSTAl
                </v-btn>

                <!-- <v-btn
                  class="button button-purple krystal-trigger mobile-model"
                  @click="showGeminiModelForMobile = !showGeminiModelForMobile"
                >
                  <img src="../../../assets/images/krystal.svg" alt="" />
                  KRYSTAl</v-btn
                > -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="attachment__wrapper"
          v-if="isShowAttachments && currentSelectedTab.id == 5"
        >
          <div class="property_badge">
            <div class="notification">
              {{ currentSelectedTab.id }}
            </div>
            <div class="property_badge_text">
              {{ currentSelectedTab.name }}
            </div>
          </div>

          <div class="main_titlesection md:!tw-mt-[2px]">
            <h3 class="md:!tw-text-[18px]">{{ currentSelectedTab.title }}</h3>
          </div>

          <div class="attachment__wrapper__upload">
            <div class="left_upload_img md:!tw-mb-0">
              <div class="upload__area">
                <h4>
                  Upload images and files.
                  <span>(Optional)</span>
                </h4>
                <div class="upload__section md:!tw-h-[100px]">
                  <p class="tw-hidden">
                    Attachments such as images, design ideas and documents help
                    better understand the project and provide increasingly
                    accurate estimates.
                  </p>
                  <div
                    class="uploade-wrapper custom-file-upload !tw-h-[250px] md:!tw-h-full"
                  >
                    <v-file-input
                      class="upload_file"
                      label="Drag and drop documents or Browse"
                      accept="image/png, image/jpeg, image/jpg"
                      variant="solo-filled"
                      v-model="selectedFile"
                      :class="{ 'added-img': imageUrl }"
                      @change="onFileChange"
                      @click:clear="clearImageUrl"
                      multiple
                    >
                      <v-icon
                        icon="mdi mdi-file-image-box"
                        class="label-img"
                      ></v-icon>
                    </v-file-input>
                    <v-img
                      v-if="imageUrl"
                      class="uploaded-img"
                      :width="200"
                      aspect-ratio="16/9"
                      cover
                      :src="imageUrl"
                    ></v-img>
                    <p class="error_msg" v-if="errorMessage">
                      {{ errorMessage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ProjectAttachmentListComponent
              class="preview-img"
              v-if="attachmentDataList?.length"
              :attachmentList="attachmentDataList"
              :properties="userSelectedProperties"
              :isPreviewFlexCol="false"
              @on-delete="clearImageUrl"
            />
          </div>
        </div>
      </div>

      <div class="section_wrapper timeline__right">
        <div class="tab__area__right__block">
          <div
            class="timeline__right__footer"
            v-if="workflowType === PropertyWorkflowTypeEnum.PROPERTY"
          >
            <v-btn
              class="button button-purple-border"
              size="large"
              block
              @click="onInviteTeamMember"
            >
              invite team member to project
            </v-btn>
          </div>
          <label class="yellow-text"
            >Load Project Template <v-icon icon="mdi-chevron-right"></v-icon
          ></label>
          <v-card class="tab__area__right__block__card third_step">
            <div class="content">
              <h4 class="title">Project Overview</h4>
            </div>
            <div class="tab__area__price">
              <ul>
                <li v-if="![3].includes(currentSelectedTab.id)">
                  <div class="tab__area__item">Project Name</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">{{ projectTitle }}</p>
                  </div>
                </li>
                <li>
                  <div class="tab__area__item">Job Type</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">{{ userProjectType }}</p>
                  </div>
                </li>
                <li
                  v-if="
                    [
                      ProjectTypesEnum.STANDARD,
                      ProjectTypesEnum.ROUTINE,
                      ProjectTypesEnum.VIDEO_CONSULTATION,
                    ].includes(userProjectType)
                  "
                >
                  <div class="tab__area__item">Preferred Start</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">{{ selectedTimelineType?.name }}</p>
                  </div>
                </li>

                <h6 class="subtitle">Property Information</h6>
                <li>
                  <div class="tab__area__item">Properties</div>
                  <div class="tab__area__item__price yellow-text">
                    <p class="yellow-text">
                      {{ userSelectedProperties?.length }}
                    </p>
                  </div>
                </li>
                <li
                  v-for="(item, index) in userSelectedProperties.slice(0, 3)"
                  :key="index"
                >
                  <div class="tab__area__item">Property Address</div>
                  <div class="tab__area__sub__items">
                    <div class="tab__area__item__price yellow-text">
                      <p class="yellow-text">{{ item.address }}</p>
                    </div>
                  </div>
                </li>
                <p
                  id="view-more-property"
                  v-if="userSelectedProperties?.length - 3 > 0"
                >
                  +{{ userSelectedProperties?.length - 3 }} More
                </p>
                <v-menu
                  target="cursor"
                  transition="slide-y-reverse-transition"
                  activator="#view-more-property"
                  width="350px"
                  class="custom_popup"
                >
                  <div class="popup__content">
                    <div class="title">
                      <h5>Properties Addresses</h5>
                      <img src="@/assets/images/close.svg" alt="" />
                    </div>
                    <ul>
                      <li
                        v-for="(item, index) in userSelectedProperties"
                        :key="index"
                      >
                        <div class="tab__area__item">Property Address</div>
                        <div class="tab__area__sub__items">
                          <div class="tab__area__item__price yellow-text">
                            <p class="yellow-text">{{ item.address }}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </v-menu>
              </ul>
            </div>
          </v-card>

          <div class="selected-info" v-if="!isShowAttachments">
            <v-btn
              aria-label="Refresh"
              class="button button-orange"
              @click="onClickSideContinueButton"
              :disabled="isSideContinueBtnDisable"
              >continue</v-btn
            >

            <v-btn
              v-if="currentSelectedTab.id == 4"
              class="save-btn"
              variant="text"
              @click="saveForDraftProject"
              :loading="isShowLoadingOnScreen"
            >
              save project for later
            </v-btn>
          </div>
          <div v-if="isShowAttachments && currentSelectedTab.id == 5">
            <div class="selected-info preview-project-btn">
              <v-btn
                aria-label="Refresh"
                class="button button-orange"
                @click="onClickPostProject(false)"
                :disabled="!isAttachmentDataValid || loading"
                :loading="loading"
                >PREVIEW PROJECT</v-btn
              >
            </div>
            <div
              v-if="workflowType === PropertyWorkflowTypeEnum.PROPERTY"
              class="project-live-btn"
              :class="{
                'tw-justify-center md:!tw-mb-4': currentSelectedTab.id === 5,
              }"
            >
              <span> OR&nbsp; </span>
              <v-btn
                variant="text"
                class="save-btn"
                :class="{ disable: !isAttachmentDataValid }"
                :disabled="!isAttachmentDataValid || loading"
                @click="onClickSiteVisit"
              >
                Post Project Live
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <InviteTeamMember
      @onCloseModal="onCloseModal"
      v-if="iShowInviteTeamMemberModal"
    />

    <v-stepper
      v-if="!isShowProjectReview"
      class="onboard-stepper"
      v-model="currentSelectedTab.id"
    >
      <v-stepper-header class="onboard-stepper__header">
        <v-stepper-item
          class="onboard-stepper__item"
          v-for="step in getStepperList"
          :key="step.id"
          :title="step.tab"
          :value="step.id"
          :class="{ done: currentSelectedTab.id > step.id }"
        ></v-stepper-item>
      </v-stepper-header>
    </v-stepper>
  </div>
  <!-- Only One Scrren While Posting Project Start -->
  <!-- <v-dialog
    width="420px"
    v-model="isPostLoadingModal"
    class="modal_box action-modal map-bg mod-map-modal"
    persistent
  >
    <div class="modal_wrapper tw-w-full">
      <main
        class="main !tw-min-w-[420px] !tw-max-w-[420px] !tw-min-h-[320px] !tw-max-h-[320px]"
      >
        <div class="tw-w-full">
          <p class="switching-text tw-pb-4" v-html="switchingText"></p>

          <v-btn
            class="button button-purple"
            @click="onClickContinuePostProject"
            block
            :loading="isPostLoadingModal"
            :disabled="isPostLoadingModal"
          >
            continue
          </v-btn>
        </div>
      </main>
    </div>
  </v-dialog> -->
  <!-- Only One Scrren While Posting Project End -->

  <v-dialog
    width="420px"
    v-model="isPostLoadingModal"
    class="modal_box action-modal map-bg mod-map-modal"
    persistent
  >
    <div class="modal_wrapper tw-w-full">
      <main
        class="main !tw-min-w-[420px] !tw-max-w-[420px] !tw-min-h-[320px] !tw-max-h-[320px]"
      >
        <div class="tw-w-full">
          <p class="switching-text tw-pb-4" v-html="switchingText"></p>
          <div class="loading-dots">
            <img
              class="tw-max-h-[176px] tw-min-h-[176px]"
              cover
              :src="require('@/assets/images/' + switchingImage)"
            />
          </div>
          <v-stepper class="onboard-stepper" v-model="projectPostTab.name">
            <v-stepper-header class="onboard-stepper__header">
              <v-stepper-item
                class="onboard-stepper__item"
                v-for="(step, index) in projectPostLoadingSteps"
                :key="index"
                :value="step.name"
              ></v-stepper-item>
            </v-stepper-header>
          </v-stepper>
          <v-btn
            class="button button-purple"
            @click="onClickContinuePostProject"
            block
            v-if="projectPostTab.name === projectPostLoadingSteps[2].name"
          >
            continue
          </v-btn>
        </div>
      </main>
    </div>
  </v-dialog>
  <SiteVisitModel
    :returnStatus="true"
    @onClickBack="onClickSiteVisit"
    @onClickPostProject="onClickPostLiveProject"
    v-if="isShowSiteVisitModal"
  />
  <div v-if="showGeminiModelForMobile">
    <GeminiModelForMobileView
      @appendJobDescription="appendJobDescription"
      @onClose="onCloseHandler"
      :isTitleKrystalIconClick="isTitleKrystalIconClick"
    />
  </div>
</template>

<script lang="ts">
import {
  ref,
  onBeforeMount,
  computed,
  reactive,
  onUpdated,
  getCurrentInstance,
  watch,
} from "vue";
import { useStore } from "vuex";
import { PROJECT_STORE } from "@/store/modules/project";
import { quillEditor } from "vue3-quill";
import { PROJECT } from "@/store/modules/project";
import {
  ProjectJobStatusEnum,
  ProjectSubTypesEnum,
  ProjectTypesEnum,
} from "@/core/enums/ProjectsEnum";
import { USER_STORE } from "@/store/modules/user";
import { useRouter } from "vue-router";
import InviteTeamMember from "./InviteTeamMember.vue";
import SiteVisitModel from "./SiteVisitModel.vue";
import GeminiModelForMobileView from "./common/GeminiModelForMobileView.vue";
import { useDisplay } from "vuetify";
import { PropertyWorkflowTypeEnum } from "@/core/enums/PropertiesEnum";
import { JOB_DETAILS_ROUTE } from "@/modules/jobs/routes";
import { JOBS_STORE } from "@/store/modules/jobs";
import { PROJECT_ROUTE } from "../routes";
// import GeminiModel from "@/modules/project/components/common/GeminiModel.vue";
import ProjectAttachmentListComponent from "@/modules/project/components/common/ProjectAttachmentListComponent.vue";

export default {
  components: {
    quillEditor,
    InviteTeamMember,
    GeminiModelForMobileView,
    // GeminiModel,
    SiteVisitModel,
    ProjectAttachmentListComponent,
  },
  props: {
    workflowType: {
      type: String,
      default: PropertyWorkflowTypeEnum.PROPERTY,
    },
    selectedClient: {
      type: Object,
      default: null,
    },
    projectRefData: {
      type: Array,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const userProjectType: any = ref("");
    const isShowJobSpecifics = ref(false);
    const isShowAttachments = ref(false);
    const userSelectedProperties = ref();
    const imageUrl = ref("");
    const errorMessage = ref("");
    const uploadedImages = ref(null) as any;
    const attachmentDataList = ref([]) as any;
    const projectTitle = ref("");
    const userSelectedPropertiesId = ref();
    const loading = ref(false);
    const isShowLoadingOnScreen = ref(false);
    const iShowInviteTeamMemberModal = ref(false);
    const projectRefId = ref("");
    const dataRange = ref();
    const router = useRouter();
    const geminiModalMenu = ref(false);
    const vuetify = useDisplay();

    const isDatePickerDisable = ref();
    const internalInstance = getCurrentInstance();
    const userCategory = ["Floorplan", "Garden", "Materials", "Maps"];

    const isShowProjectReview = ref(false);
    const showGeminiModel = ref(false);
    const showGeminiModelForMobile = ref(false);
    const isMobileView = computed(() => vuetify.smAndDown.value);
    const siteVisit = ref(false);
    const isTitleKrystalIconClick = ref();
    const validation = ref({
      projectTitle: [
        (v: any) => {
          if (v) {
            return v?.length <= 255 || "Project title is too long..";
          } else {
            return "Project name is required";
          }
        },
      ],
    });
    const selectedFile = ref([]);
    onBeforeMount(() => {
      userProjectType.value =
        store.getters[`${PROJECT_STORE}/projectDetails`]?.projectType;
      if (userProjectType.value == ProjectTypesEnum.EMERGENCY) {
        isShowJobSpecifics.value = true;
        currentSelectedTab.value = modelDetails.value[3];
      }

      userSelectedProperties.value =
        store.getters[`${PROJECT_STORE}/selectedProperties`];
      standardTypeList.value.forEach((item: any) => {
        if (item.name == props.projectRefData?.subType) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });

      routineTypeList.value.forEach((item: any) => {
        if (item.name == props.projectRefData?.subType) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });

      if (props.projectRefData?.projectAttachmentProperties) {
        attachmentDataList.value =
          props.projectRefData?.projectAttachmentProperties.map(
            (item: any) => ({
              uploadedImages: {
                url: item[0]?.attachmentUrl,
                name: item[0]?.projectAttechment?.originalName,
              },
              propertyId: [item[0]?.property?.id],
              userCategoryName: item[0]?.projectAttachmentsCategory?.value,
            })
          );
      }
    });
    const onlyOne = ref(true);
    onUpdated(() => {
      if (
        isShowJobSpecifics.value &&
        currentSelectedTab.value.id == 4 &&
        onlyOne.value
      ) {
        const toolbarElement = document.querySelector(
          ".ql-toolbar"
        ) as HTMLElement;
        const topPadding = toolbarElement.offsetHeight;
        if (isMobileView.value) {
          const cursorBox: any = document.getElementById(
            "quill-cursor-box-mobile"
          ) as HTMLElement;
          if (cursorBox) cursorBox.style.top = `${topPadding + 12}px`;
        } else {
          const cursorBox: any = document.getElementById("quill-cursor-box");
          if (cursorBox) cursorBox.style.top = `${topPadding + 12}px`;
        }
        onlyOne.value = false;
      }
    });

    const ProjectDetails = computed(
      () => store.getters[`${PROJECT_STORE}/projectDetails`]
    );
    projectTitle.value = ProjectDetails.value?.projectTitle;

    const modelDetails = ref([
      {
        id: 1,
        tab: "Property",
        name: "Property",
        title: "Please select the property / properties this project is for ",
      },
      {
        id: 2,
        tab: "Project Type",
        name: "Project Type",
        title: "Please select a project type, best suited for you.",
      },
      {
        id: 3,
        tab: "Timelines",
        name: "Timelines",
        title: "Let us know the timelines you’re looking for",
      },
      {
        id: 4,
        tab: "Project Specifics",
        name: "project Specifics",
        title: "Please try to explain your project in more detail.",
      },
      {
        id: 5,
        tab: "Attachments",
        name: "Attachments",
        title: "Add any relevant images and documents.",
      },
    ]);

    const isShowTimelineTab = computed(() =>
      [
        ProjectTypesEnum.ROUTINE,
        ProjectTypesEnum.STANDARD,
        ProjectTypesEnum.VIDEO_CONSULTATION,
      ].includes(
        userProjectType.value ? userProjectType.value.toLowerCase() : ""
      )
    );
    const getStepperList = computed(() =>
      isShowTimelineTab.value
        ? modelDetails.value
        : modelDetails.value.filter((item: any) => item.id !== 3)
    );
    const backButtonLabelList = [
      { currentTabId: 1, label: "Back To All Projects" },
      { currentTabId: 2, label: "Back To Properties" },
      { currentTabId: 3, label: "Back To Project Type" },
      {
        currentTabId: 4,
        label: "Back To Project Type",
      },
      { currentTabId: 5, label: "Back To project Specifics" },
    ];
    const getBackButtonLabel = computed(() =>
      currentSelectedTab.value?.id === 4 && isShowTimelineTab.value
        ? "Back To Timelines"
        : backButtonLabelList[currentSelectedTab.value?.id - 1]?.label || "back"
    );

    const currentSelectedTab = ref(modelDetails.value[2]);

    const standardTypeList = ref([
      {
        id: 1,
        name: ProjectSubTypesEnum.FLEXIBLE,
      },
      {
        id: 2,
        name: ProjectSubTypesEnum.WITHIN_1_MONTH,
      },
      {
        id: 3,
        name: ProjectSubTypesEnum.WITHIN_3_MONTH,
      },
      {
        id: 4,
        name: ProjectSubTypesEnum.WITHIN_12_MONTH_OR_MORE,
      },
    ]) as any;

    const routineTypeList = ref([
      {
        id: 1,
        name: ProjectSubTypesEnum.WEEKLY,
      },
      {
        id: 2,
        name: ProjectSubTypesEnum.FORTNIGHTLY,
      },
      {
        id: 3,
        name: ProjectSubTypesEnum.MONTHLY,
      },
      {
        id: 4,
        name: ProjectSubTypesEnum.YEARLY,
      },
    ]) as any;
    const getProjectTimelineList = computed(() =>
      ProjectTypesEnum.ROUTINE == userProjectType.value
        ? routineTypeList.value
        : standardTypeList.value
    );
    const isFlexibleTypeSelected = computed(() => {
      return standardTypeList.value.find(
        (item: any) =>
          item.selected && item.name === ProjectSubTypesEnum.FLEXIBLE
      );
    });
    const isProjectTypeSelected = computed(() =>
      getProjectTimelineList.value.some((item: any) => item.selected)
    );

    const isAttachmentDataValid = computed(() => {
      if (!attachmentDataList.value || attachmentDataList.value.length === 0) {
        return true;
      }

      return attachmentDataList.value.every(
        (attachment: { propertyId: null; userCategoryName: null }) =>
          attachment.propertyId !== null
      );
    });

    const toggleSelectionType = (name: string) => {
      getProjectTimelineList.value.forEach((item: any) => {
        if (item.name === name) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    };

    const calenderAttribute = [{}];

    const onClickBackButton = () => {
      if (currentSelectedTab.value.id == 5) {
        currentSelectedTab.value = modelDetails.value[3];
        isShowJobSpecifics.value = true;
        isShowAttachments.value = false;
      } else if (currentSelectedTab.value.id == 4) {
        if (userProjectType.value == ProjectTypesEnum.EMERGENCY) {
          ctx.emit("onClickBackButtonType");
        }
        isShowJobSpecifics.value = false;
        isShowAttachments.value = false;
        currentSelectedTab.value = modelDetails.value[2];
      } else {
        ctx.emit("onClickBackButtonType");
      }
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);

      // Extracting date components
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };
    const selectedTimelineType = computed(() =>
      [ProjectTypesEnum.STANDARD, ProjectTypesEnum.VIDEO_CONSULTATION].includes(
        userProjectType.value
      )
        ? standardTypeList.value.find((item: any) => item.selected)
        : userProjectType.value === ProjectTypesEnum.ROUTINE
        ? routineTypeList.value.find((item: any) => item.selected)
        : null
    );
    const onClickToggleContinue = () => {
      store.dispatch(`${PROJECT}/projectDetails`, {
        projectSubType: selectedTimelineType.value?.name,
        projectStartDate: dataRange.value?.start
          ? formatDate(dataRange.value?.start)
          : null,
        projectEndDate: dataRange.value?.end
          ? formatDate(dataRange.value?.end)
          : null,
      });
      currentSelectedTab.value = modelDetails.value[3];
      isShowJobSpecifics.value = true;
    };

    const onClickToggleContinueJobDetails = () => {
      store.dispatch(`${PROJECT}/projectDetails`, {
        projectTitle: projectTitle.value,
        projectDescription: state.content,
      });
      showButtonForGeminiModel.value = false;
      isShowJobSpecifics.value = false;
      currentSelectedTab.value = modelDetails.value[4];
      isShowAttachments.value = true;
    };

    const state = reactive({
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Describe your project here",
        modules: {},
      },
      disabled: false,
    });

    state.content = ProjectDetails.value?.projectDescription;

    const onEditorReady = (quill: any) => {
      quillInstance.value = quill;
    };
    const quillInstance = ref();
    const quillCursorIndex = ref(0);
    const onEditorFocus = (quill: any) => {
      try {
        showButtonForGeminiModel.value = true;
        quillInstance.value = quill;
        const range = quill.getSelection();
        quill.insertText(range?.index, "");
      } catch (error) {
        console.log(error);
      }
    };

    const showButtonForGeminiModel = ref(false);
    let hideBtn: any;
    const onEditorChange = ({
      quill,
      html,
      text,
    }: {
      quill: any;
      html: string;
      text: string;
    }) => {
      try {
        quillInstance.value = quill;
        showButtonForGeminiModel.value = false;

        const range = quill.getSelection();

        const [clientX, clientY]: any = getCaretCoordinates(
          quill,
          range?.index
        );

        const toolbarElement = document.querySelector(
          ".ql-toolbar"
        ) as HTMLElement;
        const topPadding = toolbarElement.offsetHeight;
        if (isMobileView.value) {
          const cursorBox: any = document.getElementById(
            "quill-cursor-box-mobile"
          );
          if (cursorBox) {
            cursorBox.style.top = `${clientY + topPadding}px`;
            cursorBox.style.left = `${clientX + 10}px`;
          }
        } else {
          const cursorBox: any = document.getElementById("quill-cursor-box");
          if (cursorBox) {
            cursorBox.style.top = `${clientY + topPadding}px`;
            cursorBox.style.left = `${clientX + 10}px`;
          }
        }

        state._content = html;
        quillCursorIndex.value = range?.index;
        showButtonForGeminiModel.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    const getCaretCoordinates = (quill: any, index: any) => {
      try {
        const editorBounds = quill?.container.getBoundingClientRect();
        const range = document.createRange();
        const leaf = quill?.getLeaf(index)[0];
        const node = leaf?.domNode;
        const offset = index - leaf?.offset(quill.root);

        range.setStart(node, offset);
        range.setEnd(node, offset);

        const rect = range.getBoundingClientRect();
        return [rect.left - editorBounds?.left, rect.top - editorBounds?.top];
      } catch (error) {
        console.log(error);
      }
    };
    const saveForDraftProject = async () => {
      let projectTilteForValidate = projectTitle?.value?.trim();
      if (!projectTilteForValidate) {
        displayToast("Project title is required", "error");
        return false;
      }
      try {
        let attachments = [];
        let propertiesData = [];
        isShowLoadingOnScreen.value = true;
        store.commit(`${PROJECT}/setDraftProjectList`, [
          { type: userProjectType.value },
        ]);
        // await store.dispatch(`${PROJECT_STORE}/updateProject`, {
        //   data: { projectStatus: ProjectStatusType.DRAFT_PROJECT },
        //   projectId: ProjectData.value?.id,
        // });
        if (attachmentDataList.value.length) {
          const formData = new FormData();
          attachmentDataList.value.forEach((attachment: any) => {
            formData.append(`files`, attachment.uploadedImages.imageData);
          });
          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          const attachmentCategoryData: any = [];

          for (const attachment of attachmentDataList.value) {
            const existCategory = attachmentCategoryData.find(
              (category: any) => category.label === attachment.userCategoryName
            );
            if (existCategory) {
              attachmentCategoryData.push(existCategory);
            } else {
              const response = await store.dispatch(
                `${PROJECT_STORE}/attachmentCategory`,
                {
                  label: attachment.userCategoryName,
                  value: attachment.userCategoryName,
                }
              );
              attachmentCategoryData.push(response);
            }
          }

          const selectedProperties =
            store.getters[`${PROJECT_STORE}/selectedProperties`];
          propertiesData = selectedProperties.map((property: { id: any }) => ({
            propertyId: property.id,
          }));
          const userPropertiesData = attachmentDataList.value.map(
            (item: any) => {
              return item.propertyId.map((property: any) => {
                return {
                  propertyId: property,
                  isDeleted: false,
                };
              });
            }
          );

          attachments = uploadMultipleImage.map((item: any, index: number) => ({
            publicUrl: item.publicUrl,
            attachment: item.attachment,
            originalName: item.originalName,
            propertyIds: userPropertiesData[index],
            attechmentCategoryId: attachmentCategoryData[index]?.id,
            isDeleted: false,
          }));
        }

        const createProject = await store.dispatch(
          `${PROJECT_STORE}/createProject`,
          {
            propertiesData: propertiesData,
            projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
            type: ProjectDetails.value?.projectType,
            subType: ProjectDetails.value?.projectSubType,
            description: state.content,
            name: projectTitle.value,
            startDate: ProjectDetails.value?.projectStartDate,
            endDate: ProjectDetails.value?.projectEndDate,
            inviteMembers: ProjectDetails.value?.inviteMembers,
            attachments: attachments,
            siteVisitStatus: siteVisit.value,
            isAddSpecialismFromGemini: true,
          }
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        isShowLoadingOnScreen.value = false;
        ctx.emit("onClickSaveLater");
      }
    };

    const onFileChange = async (event: any) => {
      const fileInput = event.target;
      errorMessage.value = "";
      if (fileInput.files && fileInput.files.length > 0) {
        Array.from(fileInput.files).forEach((file: any) => {
          if (file.type.includes("image")) {
            // Check image size limit (200MB)
            if (file.size <= 200 * 1024 * 1024) {
              imageUrl.value = URL.createObjectURL(file);
              uploadedImages.value = {
                url: imageUrl.value,
                name: file.name,
                imageData: file,
              };
              addPropertyImage();
            } else {
              errorMessage.value = "Image size exceeds the limit (200MB).";
              // Handle error message or display notification
            }
          } else if (file.type.includes("video")) {
            // Check video size limit (20MB)
            if (file.size <= 10 * 1024 * 1024) {
              uploadedImages.value = {
                type: "video",
                name: file.name,
                imageData: file,
              };
              addPropertyImage();
              // Process video file
            } else {
              errorMessage.value = "Video size exceeds the limit (20MB).";
              // Handle error message or display notification
            }
          } else {
            // Allow other file types without size validation
            imageUrl.value = URL.createObjectURL(file);
            uploadedImages.value = {
              url: imageUrl.value,
              name: file.name,
              imageData: file,
            };
            addPropertyImage();
          }
        });
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
      errorMessage.value = "";
    };

    const addPropertyImage = () => {
      attachmentDataList.value.push({
        uploadedImages: { ...uploadedImages.value },
        propertyId:
          userSelectedProperties.value?.length > 1
            ? null
            : [userSelectedProperties.value[0]?.id],
        userCategoryName: null,
      });
      uploadedImages.value = null;
      selectedFile.value = [];
      imageUrl.value = "";
    };

    const removeImageFromAttachments = (index: number) => {
      attachmentDataList.value.splice(index, 1);
      clearImageUrl();
    };

    const isDisableJobDetailsContinue = computed(() => {
      if (projectTitle.value?.length >= 255) {
        return false;
      }
      return state.content && projectTitle.value;
    });

    const onClickPostProject = async (isProjectLive = false) => {
      try {
        loading.value = true;
        if (props.isEditMode) {
          const formData = new FormData();
          attachmentDataList.value.forEach((attachment: any) => {
            formData.append(`files`, attachment.uploadedImages.imageData);
          });
          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          const attachmentCategoryData = await Promise.all(
            attachmentDataList.value.map(async (attachment: any) =>
              attachment.userCategoryName
                ? await store.dispatch(`${PROJECT_STORE}/attachmentCategory`, {
                    label: attachment.userCategoryName,
                    value: attachment.userCategoryName,
                  })
                : null
            )
          );
          const propertiesData = props.projectRefData?.projectProperties.map(
            (property: any) => ({
              id: property.id,
              propertyId: property?.property?.id,
              isDeleted: false,
            })
          );
          const userPropertiesData = attachmentDataList.value.map(
            (item: any) => {
              return item.propertyId.map((property: any) => {
                return {
                  propertyId: property,
                  isDeleted: false,
                };
              });
            }
          );

          const attachments = uploadMultipleImage.map(
            (item: any, index: number) => ({
              publicUrl: item.publicUrl,
              attachment: item.attachment,
              originalName: item.originalName,
              propertyIds: userPropertiesData[index],
              attechmentCategoryId: attachmentCategoryData[index]?.id || null,
              isDeleted: false,
            })
          );
          const createProject = await store.dispatch(
            `${PROJECT_STORE}/updateProject`,
            {
              projectId: props.projectRefData?.id,
              data: {
                propertiesData: propertiesData,
                projectJobStatusId: ProjectJobStatusEnum.DRAFT_PROJECT,
                type: ProjectDetails.value?.projectType,
                subType: ProjectDetails.value?.projectSubType,
                description: ProjectDetails.value?.projectDescription,
                name: ProjectDetails.value?.projectTitle,
                attachments: attachments,
                siteVisitStatus: siteVisit.value,
              },
            }
          );
          if (createProject) {
            projectRefId.value = createProject?.refId;
            isShowProjectReview.value = true;
          }
        } else {
          const formData = new FormData();
          attachmentDataList.value.forEach((attachment: any) => {
            formData.append(`files`, attachment.uploadedImages.imageData);
          });
          const uploadMultipleImage = await store.dispatch(
            `${USER_STORE}/uploadMultipleImage`,
            formData
          );

          const attachmentCategoryData: any = [];

          for (const attachment of attachmentDataList.value) {
            const existCategory = attachmentCategoryData.find(
              (category: any) =>
                category?.label === attachment?.userCategoryName
            );
            if (existCategory) {
              attachmentCategoryData.push(existCategory);
            } else {
              const response = attachment.userCategoryName
                ? await store.dispatch(`${PROJECT_STORE}/attachmentCategory`, {
                    label: attachment.userCategoryName,
                    value: attachment.userCategoryName,
                  })
                : null;
              attachmentCategoryData.push(response);
            }
          }

          const selectedProperties =
            store.getters[`${PROJECT_STORE}/selectedProperties`];
          const propertiesData = selectedProperties.map(
            (property: { id: any }) => ({
              propertyId: property.id,
            })
          );
          const userPropertiesData = attachmentDataList.value.map(
            (item: any) => {
              return item.propertyId.map((property: any) => {
                return {
                  propertyId: property,
                  isDeleted: false,
                };
              });
            }
          );

          const attachments = uploadMultipleImage.map(
            (item: any, index: number) => ({
              publicUrl: item.publicUrl,
              attachment: item.attachment,
              originalName: item.originalName,
              propertyIds: userPropertiesData[index],
              attechmentCategoryId: attachmentCategoryData[index]?.id || null,
              isDeleted: false,
            })
          );

          // Create project Property Side
          let payload = {
            propertiesData: propertiesData,
            projectJobStatusId: isProjectLive
              ? ProjectJobStatusEnum.AWAITING_QUOTES
              : ProjectJobStatusEnum.DRAFT_PROJECT,
            // inviteTeamMember:[]
            type: ProjectDetails.value?.projectType,
            subType: ProjectDetails.value?.projectSubType,
            description: ProjectDetails.value?.projectDescription,
            name: ProjectDetails.value?.projectTitle,
            startDate: ProjectDetails.value?.projectStartDate,
            endDate: ProjectDetails.value?.projectEndDate,
            // inviteMembers: ProjectDetails.value?.inviteMembers,
            attachments: attachments,
            siteVisitStatus: siteVisit.value,
            isAddSpecialismFromGemini: true,
          } as any;

          if (props.workflowType === PropertyWorkflowTypeEnum.RAISE_CLIENT) {
            // Raise Project logic
            payload = {
              ...payload,
              createdUserId: props.selectedClient?.toUser?.id,
              createdWorkStationId: props.selectedClient?.userWorkstation?.id,
              projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
            };
            const createProject = await store.dispatch(
              `${PROJECT_STORE}/createRaiseProject`,
              payload
            );

            if (createProject) {
              projectRefId.value = createProject?.refId;
              store.commit(`${JOBS_STORE}/setJobDetails`, {});
              router.push({
                name: JOB_DETAILS_ROUTE,
                params: { refId: projectRefId.value },
              });
            }
          } else {
            const createProject = await store.dispatch(
              `${PROJECT_STORE}/createProject`,
              payload
            );
            if (createProject) {
              // isShowProjectReview.value = true;
              if (isProjectLive) {
                return createProject;
              } else {
                projectRefId.value = createProject?.refId;

                router.push({
                  name: "projectDetails",
                  params: { refId: projectRefId.value },
                });
              }
            }
          }
        }
      } catch (error: any) {
        console.log("error", error);
        displayToast(error?.response?.data?.message, "error");
      } finally {
        loading.value = false;
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };

    const onInviteTeamMember = () => {
      iShowInviteTeamMemberModal.value = true;
    };

    const onCloseModal = () => {
      iShowInviteTeamMemberModal.value = false;
    };

    const appendJobDescription = (description: string) => {
      if (isTitleKrystalIconClick.value) {
        projectTitle.value = description.replaceAll("\n", " ");
        isTitleKrystalIconClick.value = false;
      } else {
        quillInstance.value.focus();
        quillInstance.value?.insertText(
          quillCursorIndex.value,
          description.replaceAll("\n", " ")
        );
      }
      onCloseHandler();
    };
    const onCloseHandler = () => {
      geminiModalMenu.value = false;
      showGeminiModel.value = false;
      showGeminiModelForMobile.value = false;
      quillInstance.value.focus();
      isTitleKrystalIconClick.value = false;
    };

    // post project live functionality
    const isShowSiteVisitModal = ref(false);
    const isPostLoadingModal = ref(false);
    const projectPostLoadingSteps = [
      {
        tab: "step1",
        name: "Applying your project dispute protection...",
      },
      {
        tab: "step2",
        name: "Posting Project to 1,735 trades companies....",
      },
      {
        tab: "step3",
        name: "Your Project has successfully been posted!",
      },
    ];
    const projectPostTab = ref(projectPostLoadingSteps[0]);

    const switchingText = ref(
      `Applying your project <br> <strong>dispute protection...</strong>`
    );
    const switchingImage = ref("postlive-project-stage1.png");

    const onClickSiteVisit = async () => {
      isShowSiteVisitModal.value = !isShowSiteVisitModal.value;
    };

    const randomNumber = computed(() => {
      const min = 350;
      return Math.floor(Math.random() * (1000 - min + 1)) + min;
    });

    const delay = (ms: any) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    const onClickPostLiveProject = async (siteVisitStatus = false) => {
      try {
        onClickSiteVisit();
        siteVisit.value = siteVisitStatus;
        isPostLoadingModal.value = true;
        const projectDetails = await onClickPostProject(true);

        // If want One Scrren Comment this secction Start
        switchingText.value =
          "Applying your project <br> <strong>dispute protection...</strong>";
        switchingImage.value = "postlive-project-stage1.png";
        projectPostTab.value = projectPostLoadingSteps[0];

        // Second step: Wait for 2 seconds
        await delay(2000);
        switchingText.value = `Posting your project to <br> <strong>${randomNumber.value} </strong>trade businesses...`;
        switchingImage.value = "postlive-project-stage2.png";
        projectPostTab.value = projectPostLoadingSteps[1];

        // Third step: Wait for 5 seconds
        await delay(5000);
        switchingText.value =
          "Amazing job...🚀 Your project is now</strong> LIVE </strong>on iknowa!";
        switchingImage.value = "postlive-project-stage3.png";
        projectPostTab.value = projectPostLoadingSteps[2];

        // If want One Scrren Comment this secction End
        await store.dispatch(
          `${PROJECT_STORE}/setProjectRedirectTabName`,
          "active-project"
        );
        router.push({
          name: "projectDetails",
          params: { refId: projectDetails?.refId },
        });
      } catch (error) {
        console.log("error : ", error);
      }
    };
    const onClickContinuePostProject = () => {
      displayToast("Your Project Added In Active Projects.", "success");
      isPostLoadingModal.value = false;
      router.push({ name: PROJECT_ROUTE });
    };
    const onClickTitleKrystalIcon = () => {
      isTitleKrystalIconClick.value = true;
      showGeminiModelForMobile.value = true;
    };

    watch(
      () => isMobileView.value,
      () => {
        showButtonForGeminiModel.value = false;
      }
    );
    const onClickSideContinueButton = () => {
      if (currentSelectedTab.value?.id === 3 && isShowTimelineTab.value) {
        onClickToggleContinue();
      } else if (
        isShowJobSpecifics.value &&
        currentSelectedTab.value?.id === 4
      ) {
        onClickToggleContinueJobDetails();
      }
    };
    const isSideContinueBtnDisable = computed(() =>
      currentSelectedTab.value?.id === 3 && isShowTimelineTab.value
        ? !isProjectTypeSelected.value
        : currentSelectedTab.value?.id === 4 && isShowJobSpecifics.value
        ? !isDisableJobDetailsContinue.value
        : false
    );
    return {
      modelDetails,
      dataRange,
      currentSelectedTab,
      userProjectType,
      standardTypeList,
      isProjectTypeSelected,
      toggleSelectionType,
      calenderAttribute,
      onClickBackButton,
      routineTypeList,
      onClickToggleContinue,
      isShowJobSpecifics,
      onClickToggleContinueJobDetails,
      isShowAttachments,
      state,
      onEditorReady,
      onEditorFocus,
      onEditorChange,
      saveForDraftProject,
      userSelectedProperties,
      imageUrl,
      onFileChange,
      clearImageUrl,
      addPropertyImage,
      attachmentDataList,
      removeImageFromAttachments,
      userCategory,
      uploadedImages,
      errorMessage,
      isShowProjectReview,
      projectTitle,
      onClickPostProject,
      ProjectTypesEnum,
      isDisableJobDetailsContinue,
      userSelectedPropertiesId,
      loading,
      projectRefId,
      isAttachmentDataValid,
      ProjectDetails,
      isShowLoadingOnScreen,
      onInviteTeamMember,
      iShowInviteTeamMemberModal,
      onCloseModal,
      selectedFile,
      showGeminiModel,
      appendJobDescription,
      showGeminiModelForMobile,
      onCloseHandler,
      showButtonForGeminiModel,
      geminiModalMenu,
      PropertyWorkflowTypeEnum,
      isDatePickerDisable,
      isFlexibleTypeSelected,
      validation,
      isShowSiteVisitModal,
      onClickSiteVisit,
      isPostLoadingModal,
      switchingImage,
      switchingText,
      projectPostTab,
      projectPostLoadingSteps,
      onClickContinuePostProject,
      onClickPostLiveProject,
      selectedTimelineType,
      getProjectTimelineList,
      getStepperList,
      onClickTitleKrystalIcon,
      isMobileView,
      getBackButtonLabel,
      isTitleKrystalIconClick,
      onClickSideContinueButton,
      isSideContinueBtnDisable,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/project/styles/projectType.scss";
.temp-box {
  position: absolute;
  top: 76px;
  left: 22px;
  z-index: 1000;
  height: 32px;
  width: 32px;
  box-shadow: var(
    --elevation-200-canvas,
    0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 5px 8px rgba(0, 0, 0, 0.3)
  );
  border-radius: 0px 16px 16px 16px;
  background: #0d99ff;
  background-image: url(../../../assets/icons/krystal.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.desktop__cursor,
.desktop__wrapper {
  display: block;
  @include respond(s720) {
    display: none;
  }
}
.mobile__cursor,
.mobile__wrapper {
  display: none;
  @include respond(s720) {
    display: block;
  }
}
.calendar_container {
  position: relative;

  .calendar_container_empty {
    @include fluidFont(16, 16, 1.3);
    font-weight: 500;
    letter-spacing: 0.15px;
    color: #4f55f0;
    text-align: center;
  }
}

.disable-calender {
  pointer-events: none;
}
.calendar_current_day {
  position: absolute;
  right: 30px;
  top: 7px;
  z-index: 1;
  p {
    @include fluidFont(16, 16, 1.2);
    color: rgba($blueDark, 1);
    font-weight: 700;
  }
}
.upload__area {
  width: 100% !important;
  text-align: left;
}

:deep(.v-field__input) {
  padding-bottom: 0.5rem !important;
  font-size: 24px !important;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: rgba($backgroundcolor, 1);
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}
:deep(.disable-calender) button {
  pointer-events: none;
}
.disable-calender .calendar_current_day p,
:deep(.disable-calender)
  .calendar__select
  .vc-pane-container
  .vc-pane-layout
  .vc-pane
  .vc-header
  .vc-title-wrapper
  .vc-title
  span,
:deep(.disable-calender)
  .calendar__select
  .vc-pane-container
  .vc-pane-layout
  .vc-pane
  .vc-weeks
  .vc-week
  .vc-day
  .vc-day-content {
  color: rgba($blueDark, 0.5) !important;
}
.save-btn {
  height: auto;
  @include fluidFont(14, 14, 1.3);
  font-weight: 500;
  letter-spacing: 0.15px;
  color: rgba(74, 146, 229, 1);
  padding: 0;
  opacity: 1;
  @include respond(s720) {
    width: auto;
    max-width: 100%;
  }
}
.project-live-btn {
  margin: 18px 0 0;
  text-align: left;
  @include fluidFont(14, 14, 1.3);
  font-weight: 500;
  letter-spacing: 0.15px;
  color: rgba($blueDark, 0.5);
  display: flex;
  align-items: center;
  @include respond(s720) {
    justify-content: center;
  }
}
.selected-info {
  flex-direction: column;
  margin: 16px auto 0;
}
.timeline__right__footer {
  padding-bottom: 20px;
  border: none;
  padding-top: 0;
}
.tab__area__right__block {
  .yellow-text {
    margin: 0 auto 16px;
  }
}
.tab__area__item__price {
  text-align: left !important;
  margin: 0 !important;
}

.tab__area__price {
  ul {
    li {
      gap: 24px;
      // justify-content: unset !important;
    }
  }
}
.timeline__right {
  padding: 0;
}

.preview-img {
  margin-top: 20px;
  position: relative;
}
.preview-img:before {
  content: "";
  position: absolute;
  border-top: 1px dashed #8687a5;
  width: 100%;
  top: -20px;
  left: 0;
}
#view-more-property {
  text-align: right;
  text-decoration: underline;
  color: blue;
}
</style>
