<template>
  <GeneralDialog
    width="570px"
    class="raise_question_create_password_modal"
    @on-close="onCloseModal"
    headerTitle="Create Password"
  >
    <template #body>
      <div class="general_dialog__content__title">
        <h5>Let’s create a password to your account.</h5>
        <v-form>
          <div class="input__form">
            <label>Password</label>
            <v-text-field
              class="input_field"
              variant="outlined"
              density="compact"
              label="Password"
              v-model="userForm.password"
              :rules="validation.password"
              :append-inner-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="() => (isShowPassword = !isShowPassword)"
              :type="isShowPassword ? 'text' : 'password'"
            />
          </div>
          <div class="input__form">
            <label>Confirm New Password</label>
            <v-text-field
              class="input_field"
              variant="outlined"
              density="compact"
              v-model="userForm.confirmPassword"
              :rules="validation.confirmPassword"
              label="Confirm New Password"
              :append-inner-icon="
                isShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
              "
              @click:append-inner="
                () => (isShowConfirmPassword = !isShowConfirmPassword)
              "
              :type="isShowConfirmPassword ? 'text' : 'password'"
            />
          </div>
        </v-form>
        <div class="checkbox__wrapper">
          <v-checkbox
            v-model="userForm.tcOptIn"
            :rules="[() => nameValidationSchema('Check', userForm.tcOptIn)]"
          >
            <template v-slot:label>
              <div
                class="tw-grid tw-grid-cols-[18px_1fr] tw-items-center tw-gap-2"
              >
                <v-icon v-if="userForm.tcOptIn" color="#FFA500">
                  mdi-check-circle
                </v-icon>
                <v-icon v-else color="#0C0F4A80"> mdi-circle-outline </v-icon>
                <p>
                  I have read & agree to the

                  <a
                    href="https://iknowa.com/pdf/MFSL-Introduced-Client-Terms.pdf"
                    class="tw-text-[#FFA500] tw-no-underline hover:tw-underline"
                    target="_blank"
                  >
                    Terms of Service</a
                  >
                  and
                  <a
                    href="https://iknowa.com/pdf/iknowa%20Privacy%20and%20Cookie%20Policy%20(Revised%202024).docx.pdf"
                    class="tw-text-[#FFA500] tw-no-underline hover:tw-underline"
                    target="_blank"
                    >Privacy Policy</a
                  >
                </p>
              </div>
            </template>
          </v-checkbox>

          <v-checkbox v-model="userForm.marketingOptIn">
            <template v-slot:label>
              <div
                class="tw-grid tw-grid-cols-[18px_1fr] tw-items-start tw-gap-2"
              >
                <v-icon v-if="userForm.marketingOptIn" color="#FFA500">
                  mdi-check-circle
                </v-icon>
                <v-icon v-else color="#0C0F4A80"> mdi-circle-outline </v-icon>
                <p>Please don’t send me any marketing material</p>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal_footer">
        <v-btn
          class="button button-purple-border"
          @click="onCloseModal"
          :disabled="isLoading"
          >Cancel
        </v-btn>
        <v-btn
          class="button button-purple"
          :loading="isLoading"
          :disabled="!nextButtonDisable"
          @click="savePassword"
          >Continue</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { nameValidationSchema } from "@/core/validation/validation";
import { ref } from "vue";
import CryptoJS from "crypto-js";
import {
  AUTH_SESSION_EXPIRY,
  AUTH_USER,
  CRYPTO_SECRET_KEY,
} from "@/core/constants";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { computed } from "vue";
import { setLocalStorageWithExpiry } from "@/core/utils/common";

const emits = defineEmits(["on-close", "onNext"]);

const isShowPassword = ref(false);
const isLoading = ref(false);
const isShowConfirmPassword = ref(false);
const store = useStore();
const userForm = ref({
  password: "",
  confirmPassword: "",
  marketingOptIn: false,
  tcOptIn: false,
});
const user = computed(() => store.getters[`${USER_STORE}/user`]);

const validation = ref({
  password: [
    (v) => !!v || "Password is required",
    (v) =>
      // eslint-disable-next-line
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/.test(
        v
      ) ||
      "Password must contain at least 8 characters including at least 1 letter, 1 number and 1 special character",
  ],
  confirmPassword: [
    (v) => !!v || "Confirm Password is required",
    (v) => v === userForm.value.password || "Confirm Password does not match",
  ],
});
const onCloseModal = () => {
  if (isLoading.value) return;
  emits("on-close");
};

const nextButtonDisable = computed(() => {
  return (
    userForm.value.password &&
    userForm.value.password === userForm.value.confirmPassword &&
    userForm.value.tcOptIn
  );
});

const savePassword = async () => {
  try {
    isLoading.value = true;
    const encryptPassword = CryptoJS.AES.encrypt(
      userForm.value.password ?? "",
      CRYPTO_SECRET_KEY ?? ""
    ).toString();

    await store.dispatch(`${AUTHENTICATION_STORE}/changePassword`, {
      email: user.value.email,
      password: encryptPassword,
      userId: user.value.id,
    });
    await setUser();
    await updateUserData();
    nextStep();
    console.log("after set user");
  } catch (error) {
    console.log("save password error", error);
  } finally {
    isLoading.value = false;
  }
};
const setUser = async () => {
  try {
    const encryptPassword = CryptoJS.AES.encrypt(
      userForm.value.password ?? "",
      CRYPTO_SECRET_KEY ?? ""
    ).toString();

    const loginUser = await store.dispatch(`${AUTHENTICATION_STORE}/login`, {
      username: user.value.email,
      password: encryptPassword,
    });

    const userResponse = await store.dispatch(
      `${USER_STORE}/getUser`,
      loginUser?.user?.id
    );

    await store.dispatch(`${USER_STORE}/saveUserState`, userResponse);
    setLocalStorageWithExpiry(
      {
        roleId: loginUser?.user?.role?.id,
        userId: loginUser?.user?.id,
        hasChosenRole: loginUser?.user?.hasChosenRole,
        hasAccountSetup: loginUser?.user?.hasAccountSetup,
        hasBusinessType: loginUser?.user?.hasBusinessType,
      },
      AUTH_USER,
      AUTH_SESSION_EXPIRY
    );
    await store.dispatch(`${USER_STORE}/selectRole`, {
      userId: user.value?.id,
      roleId: loginUser?.user?.role?.id,
      email: user.value?.email,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const updateUserData = async () => {
  try {
    await store.dispatch(`${USER_STORE}/saveUserProfile`, {
      userId: user.value?.id,
      profile: {
        profileImage: null,
      },
    });
    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: {
        tcOptIn: userForm.value.tcOptIn,
        marketingOptIn: userForm.value.marketingOptIn,
        hasAccountSetup: true,
      },
    });
  } catch (error) {
    console.log();
  }
};
const nextStep = () => {
  emits("onNext");
};
</script>

<style scoped lang="scss">
.raise_question_create_password_modal {
  .v-overlay__content {
    margin: 0 !important;
    .general-dialog {
      .general_dialog__content {
        .general_dialog__content__title {
          h5 {
            @include fluidFont(24, 24, 30px);
            font-weight: 600;
            color: rgba($blueDark, 1);
          }
          .v-form {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .input__form {
              label {
                @include fluidFont(12, 12, 2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                margin-bottom: 8px;
              }
              .input_field {
                margin: 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid rgba($bordercolor, 1);
                    border-radius: 8px;
                    overflow: hidden;
                    .v-field__field {
                      .v-field__input {
                        background-color: rgba($backgroundcolor, 1);
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        min-height: 42px;
                        padding: 0 16px;
                        top: 0;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
          }
          .checkbox__wrapper {
            margin-top: 24px;
            width: 335px;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
            :deep(.v-checkbox) {
              .v-input__control {
                .v-selection-control {
                  min-height: auto;
                  .v-selection-control__wrapper {
                    display: none;
                  }
                  .v-label {
                    p {
                      @include fluidFont(14, 14, 16px);
                      font-weight: 500;
                      color: rgba($blueDark, 1);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .modal_footer {
        padding-top: 24px;
        border-top: 1px dashed rgba($bordercolor, 1);
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: center;
        .button {
          text-transform: capitalize;
          @include fluidFont(16, 16, 20px);
          font-weight: 600;
        }
      }
    }
  }
}

// .v-btn {
//   width: 100%;
// }

// :deep(.v-checkbox-btn) {
//   min-height: 1px !important;

//   .v-selection-control__wrapper {
//     display: none;
//   }

//   label {
//     width: 100%;
//     font-size: 14px;
//   }

//   .v-icon {
//     font-size: 18px;
//   }
// }

// :deep(.v-text-field .v-field) {
//   height: 42px;
//   background-color: #fff;
//   color: #0c0f4a;
//   border-radius: 8px;
//   font-weight: 500;
// }

// :deep(.v-text-field .v-field .v-icon) {
//   font-size: 18px;
// }

// :deep(.v-text-field .v-field .v-field__outline) {
//   --v-field-border-width: 2px;
//   --v-field-border-opacity: 0.51;
// }

// :deep(
//     .v-text-field .v-field--variant-outlined .v-label.v-field-label--floating
//   ) {
//   background: linear-gradient(360deg, #ffffff 0%, #e7f3ff 95%);
// }
</style>
