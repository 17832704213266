<template>
  <div>
    <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
      <Text variant="h4" textWeight="700" textColor="#0C0F4A">
        Site Visit Requests
      </Text>
      <div class="tw-flex tw-w-[250px]">
        <v-select
          variant="outlined"
          class="custom_selector"
          v-model="selectStatus"
          :items="requestStatus"
          item-title="label"
          item-value="value"
          :menu-props="{ contentClass: 'custom__text__selector' }"
          placeholder="All"
          @update:model-value="onChangeStatus"
        ></v-select>
      </div>
    </div>

    <!-- Empty State -->
    <div
      class="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(300px,1fr))] tw-gap-4 tw-flex tw-items-center tw-justify-center tw-min-h-[calc(100vh_-_220px)] sm:tw-min-h-[calc(100vh_-_390px)]"
      v-if="!getSiteVisitRequest.length"
    >
      <div
        class="tw-w-[650px] tw-space-y-3 tw-flex tw-flex-col tw-items-center tw-justify-center text-center mx-auto"
      >
        <img src="@/assets/icons/sitevisitemptystate.svg" alt="Empty State" />
        <p class="tw-text-[24px] tw-font-semibold">
          You have not yet scheduled any site visits.
        </p>
        <p class="tw-text-gray-600">
          You have not yet scheduled any site visits. We recommend taking action
          soon to ensure timely planning and coordination.
        </p>
      </div>
    </div>

    <div
      class="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(300px,1fr))] tw-gap-4"
    >
      <SiteVisitCard
        v-for="data in getSiteVisitRequest"
        :key="data?.id"
        :visitData="data"
        typeData="Project"
        @get-all-request="getAllSiteRequests"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import SiteVisitCard from "@/core/uni-components/SiteVisitCard.vue";
import Text from "@/core/uni-components/Text.vue";
import InputSelect from "@/core/uni-components/InputSelect.vue";
import { USER_STORE } from "@/store/modules/user";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { SiteVisitStatus } from "@/core/enums/estimateEnum";
const store = useStore();
const getSiteVisitRequest = ref([]) as any;
const selectStatus = ref("") as any;
const filterSiteVisitRequest = ref([]) as any;

const props = defineProps({ projectId: { type: Number || String } });

const requestStatus = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Requested",
    value: SiteVisitStatus.DEFAULT.toString(),
  },
  {
    label: "Accepted",
    value: SiteVisitStatus.ACCEPTED.toString(),
  },
  {
    label: "Rejected",
    value: SiteVisitStatus.REJECTED.toString(),
  },
];
const getAllSiteRequests = async (status = "") => {
  try {
    getSiteVisitRequest.value = await store.dispatch(
      `${JOB_TEMPLATE_STORE}/getSiteRequestAll`,
      {
        projectId: props.projectId,
        status,
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const onChangeStatus = (value: any) => {
  getAllSiteRequests(value);
};

onMounted(async () => {
  await getAllSiteRequests();
});
</script>
<style lang="scss" scoped>
.custom_selector {
  width: 50%;
  max-width: 100%;
  :deep(.v-input__control) {
    .v-field {
      background-color: rgba($white, 1);
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 0;
      .v-field__field {
        .v-field__input {
          min-height: 40px;
          padding: 12px 16px;
          @include fluidFont(12, 12, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.4px;
          opacity: 1;
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
          input {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              opacity: 1;
            }
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.custom__text__selector {
  width: 226px !important;
}
</style>
