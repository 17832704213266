<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    :className="['hourly_rate_modal']"
    width="480px"
    height="auto"
  >
    <template v-slot:body>
      <div class="modal_content">
        <div class="header">
          <h4>Edit Hourly & Day Rate</h4>
          <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
        </div>
        <div class="modal_form">
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Hourly Rate</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.hourlyRate"
              v-model="formattedHourlyRate"
              @blur="formatHourlyRate"
              variant="outlined"
              placeholder="0.00"
              ><img src="../../../assets/icons/trade-network-currency.svg"
            /></v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Day Rate</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.dayRate"
              v-model="formattedDayRate"
              @blur="formatDayRate"
              variant="outlined"
              placeholder="0.00"
              ><img src="../../../assets/icons/trade-network-currency.svg"
            /></v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Emergency Call Out Fee</label>
              <div class="icon__wrapper">
                <img src="../../../assets/icons/info-icon-transparent.svg" />
                <span class="form_wrapper__tooltip_emergency"
                  >Including the 1st Hour</span
                >
              </div>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.emergencyCallOutFee"
              v-model="formattedEmergencyCallOutFee"
              @blur="formatEmergencyCallOutFee"
              variant="outlined"
              placeholder="0.00"
              ><img src="../../../assets/icons/trade-network-currency.svg" />
            </v-text-field>
            <!-- <v-radio-group
              class="input_field"
              inline
              v-model="userInformation.emergencyCallOutFeeType"
            >
              <v-radio label="Inclusive" value="inclusive"></v-radio>
              <v-radio label="Excluding" value="exclusive"></v-radio>
            </v-radio-group> -->
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Emergency Call Out Rate (Per Hour)</label>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.emergencyCallOutRate"
              v-model="formattedEmergencyCallOutRate"
              @blur="formatEmergencyCallOutRate"
              variant="outlined"
              placeholder="0.00"
              ><img src="../../../assets/icons/trade-network-currency.svg"
            /></v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>Video Consultation Fee (Per Hour)</label>
              <div class="icon__wrapper">
                <img src="../../../assets/icons/info-icon-transparent.svg" />
                <span class="form_wrapper__tooltip"
                  >Including the 1st Hour</span
                >
              </div>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.VideoConsultationFee"
              v-model="formattedVideoConsultationFee"
              @blur="formatVideoConsultationFee"
              variant="outlined"
              placeholder="0.00"
              ><img src="../../../assets/icons/trade-network-currency.svg" />
            </v-text-field>
          </div>
          <div class="form_wrapper">
            <div class="form_wrapper__title">
              <label>In person video rate</label>
              <div class="icon__wrapper">
                <img src="../../../assets/icons/info-icon-transparent.svg" />
                <span class="form_wrapper__tooltip_inperson"
                  >Including the 1st Hour</span
                >
              </div>
            </div>
            <v-text-field
              class="input_field"
              :rules="validation.inpersonVideoFees"
              v-model="formattedinpersonVideoFees"
              @blur="formatinpersonVideoFees"
              variant="outlined"
              placeholder="0.00"
              ><img src="../../../assets/icons/trade-network-currency.svg" />
            </v-text-field>
          </div>
        </div>
        <v-btn
          @click="onClickSaveButton"
          :disabled="isSaveButtonDisable"
          class="button button-orange"
          :loading="savingLoader"
          >SAVE</v-btn
        >
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import UserService from "@/core/services/user.service";
import { useStore } from "vuex";
import { computed, ref, onBeforeMount } from "vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";

export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const userRates = ref([]) as any;
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const savingLoader = ref(false);
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const isSaveButtonDisable = computed(() => {
      const values = [
        formattedDayRate.value,
        formattedHourlyRate.value,
        formattedEmergencyCallOutFee.value,
        formattedEmergencyCallOutRate.value,
        formattedVideoConsultationFee.value,
        formattedinpersonVideoFees.value,
      ];

      return values.some((val) => !val || parseFloat(val) === 0);
    });

    const validation = ref({
      hourlyRate: [
        (v: string) =>
          !v
            ? ""
            : parseFloat(v) === 0
            ? "Hourly rate must be greater than 0"
            : /^\d{1,6}(,\d{3})*(\.\d{1,2})?$/.test(v) ||
              "Please enter upto 6 digit valid number.",
      ],
      dayRate: [
        (v: string) =>
          !v
            ? ""
            : parseFloat(v) === 0
            ? "Day rate must be greater than 0"
            : /^\d{1,6}(,\d{3})*(\.\d{1,2})?$/.test(v) ||
              "Please enter upto 6 digit valid number.",
      ],
      emergencyCallOutFee: [
        (v: string) =>
          !v
            ? ""
            : parseFloat(v) === 0
            ? "Emergency call-out fee must be greater than 0"
            : /^\d{1,6}(,\d{3})*(\.\d{1,2})?$/.test(v) ||
              "Please enter upto 6 digit valid number.",
      ],
      emergencyCallOutRate: [
        (v: string) =>
          !v
            ? ""
            : parseFloat(v) === 0
            ? "Emergency call-out rate must be greater than 0"
            : /^\d{1,6}(,\d{3})*(\.\d{1,2})?$/.test(v) ||
              "Please enter upto 6 digit valid number.",
      ],
      VideoConsultationFee: [
        (v: string) =>
          !v
            ? ""
            : parseFloat(v) === 0
            ? "Video consultation fee must be greater than 0"
            : /^\d{1,6}(,\d{3})*(\.\d{1,2})?$/.test(v) ||
              "Please enter upto 6 digit valid number.",
      ],
      inpersonVideoFees: [
        (v: string) =>
          !v
            ? ""
            : parseFloat(v) === 0
            ? "In-person video fees must be greater than 0"
            : /^\d{1,6}(,\d{3})*(\.\d{1,2})?$/.test(v) ||
              "Please enter upto 6 digit valid number.",
      ],
    });

    const userInformation = ref({
      hourlyRate: activeUserWorkstation.value?.hourlyRate?.maxRate || null,
      dayRate: activeUserWorkstation.value?.dayRate?.maxRate || null,
      emergencyCallOutFee:
        activeUserWorkstation.value?.emergencyCallOutFee || null,
      emergencyCallOutRate:
        activeUserWorkstation.value?.emergencyCallOutRate || null,
      emergencyCallOutFeeType:
        activeUserWorkstation.value?.emergencyCallOutFeeType,
      videoConsultationFee:
        +activeUserWorkstation.value?.videoConsulationFee || null,
      inpersonVideoFees: activeUserWorkstation.value?.inpersonVideoFees || null,
    }) as any;

    const formattedHourlyRate = ref(
      userInformation.value.hourlyRate
        ? parseFloat(userInformation.value.hourlyRate).toFixed(2)
        : (null as any)
    );
    const formattedDayRate = ref(
      userInformation.value.dayRate
        ? parseFloat(userInformation.value.dayRate).toFixed(2)
        : (null as any)
    );
    const formattedEmergencyCallOutFee = ref(
      userInformation.value.emergencyCallOutFee
        ? parseFloat(userInformation.value.emergencyCallOutFee).toFixed(2)
        : (null as any)
    );
    const formattedEmergencyCallOutRate = ref(
      userInformation.value.emergencyCallOutRate
        ? parseFloat(userInformation.value.emergencyCallOutRate).toFixed(2)
        : (null as any)
    );

    const formattedVideoConsultationFee = ref(
      userInformation.value.videoConsultationFee
        ? parseFloat(userInformation.value.videoConsultationFee).toFixed(2)
        : (null as any)
    );

    const formattedinpersonVideoFees = ref(
      userInformation.value.inpersonVideoFees
        ? parseFloat(userInformation.value.inpersonVideoFees).toFixed(2)
        : (null as any)
    );

    const formatHourlyRate = () => {
      const numericValue =
        parseFloat(formattedHourlyRate.value.replace(/,/g, "")) || 0.0;

      userInformation.value.hourlyRate = numericValue;
      formattedHourlyRate.value = numericValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const formatDayRate = () => {
      const numericValue =
        parseFloat(formattedDayRate.value.replace(/,/g, "")) || 0.0;

      userInformation.value.dayRate = numericValue;
      formattedDayRate.value = numericValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const formatEmergencyCallOutFee = () => {
      const numericValue =
        parseFloat(formattedEmergencyCallOutFee.value.replace(/,/g, "")) || 0.0;
      userInformation.value.emergencyCallOutFee = numericValue;
      formattedEmergencyCallOutFee.value = numericValue.toLocaleString(
        "en-US",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );
    };

    const formatEmergencyCallOutRate = () => {
      const numericValue =
        parseFloat(formattedEmergencyCallOutRate.value.replace(/,/g, "")) ||
        0.0;
      userInformation.value.emergencyCallOutRate = numericValue;
      formattedEmergencyCallOutRate.value = numericValue.toLocaleString(
        "en-US",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );
    };

    const formatVideoConsultationFee = () => {
      const numericValue =
        parseFloat(formattedVideoConsultationFee.value.replace(/,/g, "")) ||
        0.0;
      userInformation.value.videoConsultationFee = numericValue;
      formattedVideoConsultationFee.value = numericValue.toLocaleString(
        "en-US",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );
    };

    const formatinpersonVideoFees = () => {
      const numericValue =
        parseFloat(formattedinpersonVideoFees.value.replace(/,/g, "")) || 0.0;
      userInformation.value.inpersonVideoFees = numericValue;
      formattedinpersonVideoFees.value = numericValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    const onClickSaveButton = async () => {
      try {
        savingLoader.value = true;
        let userRate = {
          dayRate: userInformation.value?.dayRate?.id,
          hourlyRate: userInformation.value?.hourlyRate?.id,
          emergencyCallOutFee: userInformation.value?.emergencyCallOutFee,
          emergencyCallOutRate: userInformation.value?.emergencyCallOutRate,
          emergencyCallOutFeeType:
            userInformation.value?.emergencyCallOutFeeType,
          videoConsulationFee: userInformation.value?.videoConsultationFee,
          inpersonVideoFees: userInformation.value?.inpersonVideoFees,
        };

        if (typeof userInformation.value?.hourlyRate !== "object") {
          const customRate = (await UserService.addUserTradeRates({
            rate: Number(userInformation.value?.hourlyRate),
          })) as any;
          userRate.hourlyRate = customRate?.id;
        }
        if (typeof userInformation.value?.dayRate !== "object") {
          const customRate = (await UserService.addUserTradeRates({
            rate: Number(userInformation.value?.dayRate),
          })) as any;
          userRate.dayRate = customRate?.id;
        }
        userRate.emergencyCallOutFee = parseInt(
          userInformation.value?.emergencyCallOutFee
        );
        userRate.emergencyCallOutRate = parseInt(
          userInformation.value?.emergencyCallOutRate
        );

        userRate.inpersonVideoFees = parseInt(
          userInformation.value?.inpersonVideoFees
        );

        userRate.emergencyCallOutFeeType =
          userInformation.value?.emergencyCallOutFeeType;

        userRate.emergencyCallOutFee = await UserService.updateUserWorkstation(
          user.value?.id,
          activeUserWorkstation.value?.id,
          userRate
        );

        await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
        ctx.emit("closeUpdateUserRatesModal");
        savingLoader.value = false;
      } catch (error) {
        savingLoader.value = false;
        console.log("error", error);
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("closeUpdateUserRatesModal");
    };

    onBeforeMount(async () => {
      userRates.value = await UserService.getUserTradeRates();
      if (userRates.value.length) {
        userRates.value = userRates.value.map((userRate: any) => {
          return {
            ...userRate,
            rateName: `${userRate?.minRate}-${userRate?.maxRate}`,
          };
        });
      }
      formatHourlyRate();
      formatDayRate();
      formatEmergencyCallOutFee();
      formatEmergencyCallOutRate();
    });

    return {
      userRates,
      onClickSaveButton,
      userInformation,
      onClickCloseButton,
      validation,
      formattedHourlyRate,
      formattedDayRate,
      formattedEmergencyCallOutFee,
      formattedEmergencyCallOutRate,
      formatHourlyRate,
      formatDayRate,
      formatEmergencyCallOutFee,
      formatEmergencyCallOutRate,
      formattedVideoConsultationFee,
      formatVideoConsultationFee,
      formattedinpersonVideoFees,
      formatinpersonVideoFees,
      isSaveButtonDisable,
      savingLoader,
    };
  },
};
</script>
