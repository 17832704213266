<template>
  <div
    class="estimates-received-main tw-h-full tw-w-full tw-py-8 tw-px-[38px] tw-box-border tw-bg-white"
  >
    <CommonLoader v-if="isLoading" :loading="isLoading" />
    <RaiseEstimatePreview
      v-if="getTradeEstimateDetails && !isLoading"
      :details="getTradeEstimateDetails"
      @start-registration="onStartRegistration"
    />
    <CreateAccountModal
      v-if="currentScreen === screenTypes.CREATE_ACCOUNT"
      @on-close="onCloseModal"
      @onNext="(value) => onNextClick(screenTypes.CREATE_ACCOUNT, value)"
    />
    <EmailVerificationModal
      v-if="currentScreen === screenTypes.VERIFY_EMAIL"
      @on-close="onCloseModal"
      @onNext="(value) => onNextClick(screenTypes.VERIFY_EMAIL, value)"
    />
    <CreatePasswordModal
      v-if="currentScreen === screenTypes.CREATE_PASSWORD"
      @on-close="onCloseModal"
      @onNext="(value) => onNextClick(screenTypes.CREATE_PASSWORD, value)"
    />
    <SelectClientTypeModal
      v-if="currentScreen === screenTypes.SELECT_TYPE"
      @on-close="onCloseModal"
      @onNext="(value) => onNextClick(screenTypes.SELECT_TYPE, value)"
    />
    <CreateWorkstationModal
      v-if="currentScreen === screenTypes.CREATE_WORKSTATION"
      @on-close="onCloseModal"
      @onNext="(value) => onNextClick(screenTypes.CREATE_WORKSTATION, value)"
    />
  </div>
</template>
<script setup>
import RaiseEstimatePreview from "@/modules/client-raise-estimate/components/RaiseEstimatePreview.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { ElMessage } from "element-plus";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import CreateAccountModal from "../components/CreateAccountModal.vue";
import EmailVerificationModal from "../components/EmailVerificationModal.vue";
import CreatePasswordModal from "../components/CreatePasswordModal.vue";
import SelectClientTypeModal from "../components/SelectClientTypeModal.vue";
import CreateWorkstationModal from "../components/CreateWorkstationModal.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  BusinessDetailTypeEnum,
  PropertyOwnerWorkstationType,
  WorkstationAccountTypeEnum,
} from "@/core/enums/RolesEnum";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { PropertyCategoryEnum } from "@/core/enums/PropertiesEnum";
import preferredContractorsService from "@/core/services/preferred-contractors.service";

const store = useStore();
const route = useRoute();
const router = useRouter();
const isLoading = ref(true);
const currentScreen = ref(null);
const workstationType = ref();
const screenTypes = {
  CREATE_ACCOUNT: "createAccount",
  VERIFY_EMAIL: "verifyEmail",
  CREATE_PASSWORD: "createPassword",
  SELECT_TYPE: "selectType",
  CREATE_WORKSTATION: "createWorkstation",
  SERVICE_AGREEMENT: "serviceAgreement",
};

const createdWorkstation = ref();
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const clientRaiseEstimateDetails = computed(() => {
  return store.getters[`${PREFERRED_CONTRACTORS}/getClientRaiseEstimate`];
});
const getTradeEstimateDetails = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/getTradeEstimateDetails`]
);
const retriveEstimateDetail = async () => {
  const redirectToken = route?.params.token;
  if (typeof redirectToken === "string") {
    try {
      await store.dispatch(
        `${PREFERRED_CONTRACTORS}/getRaiseProjectEstimateDetails`,
        encodeURIComponent(redirectToken)
      );
    } catch (error) {
      console.log();
      ElMessage.error("token is invalid.");

      router.push({ name: DASHBOARD_ROUTE });
    }
  } else {
    router.push({ name: DASHBOARD_ROUTE });
  }
};

const onStartRegistration = () => {
  console.log("start registraction flow");
  currentScreen.value = screenTypes.CREATE_ACCOUNT;
};

const onCloseModal = () => {
  currentScreen.value = null;
};
const onNextClick = (tab, value) => {
  if (tab === screenTypes.CREATE_ACCOUNT) {
    currentScreen.value = screenTypes.VERIFY_EMAIL;
  } else if (tab === screenTypes.VERIFY_EMAIL) {
    currentScreen.value = screenTypes.CREATE_PASSWORD;
  } else if (tab === screenTypes.CREATE_PASSWORD) {
    currentScreen.value = screenTypes.SELECT_TYPE;
  } else if (tab === screenTypes.SELECT_TYPE) {
    if (value) {
      workstationType.value = value;
      currentScreen.value = screenTypes.CREATE_WORKSTATION;
    }
  } else if (tab === screenTypes.CREATE_WORKSTATION) {
    if (value) createPropertyWorkstation(value);
  }
};

const createPropertyWorkstation = async (workstationName) => {
  try {
    isLoading.value = true;
    onCloseModal();
    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: { hasBusinessType: true },
    });

    createdWorkstation.value = await store.dispatch(
      `${WORKSTATION}/createUserWorkstation`,
      {
        userId: user.value?.id,
        userWorkstation: {
          name: workstationName,
          companyName: workstationName,
          businessType: BusinessDetailTypeEnum.PROPERTY_OWNER,
          workstationAccountType: WorkstationAccountTypeEnum.HOMEOWNER,
        },
      }
    );

    await store.dispatch(`${WORKSTATION}/updateUserWorkstation`, {
      userId: user.value?.id,
      userWorkstationId: createdWorkstation.value.id,
      payload: {
        profileImage: null,
      },
    });

    await store.dispatch(`${USER_STORE}/initializeUserState`);
    await store.dispatch(`${USER_STORE}/getAccountStatuses`);
    await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
      userId: user.value?.id,
      userWorkstationId: createdWorkstation.value?.id,
    });
    await store.dispatch(
      `${PREFERRED_CONTRACTORS}/acceptPreferredTradeUserConnection`,
      {
        userId: user.value?.id,
        tradeUserConnectionId:
          clientRaiseEstimateDetails.value?.preferredContractor?.id,
        data: {
          key: clientRaiseEstimateDetails.value?.preferredContractor?.token,
          workstationIds: [createdWorkstation.value.id],
        },
      }
    );

    await preferredContractorsService.clientRaiseEstiamteHandle(user.value.id, {
      clientEstimateRaiseId: clientRaiseEstimateDetails.value.id,
      status: "accepted",
    });
    setWorkStationType();
    await store.dispatch(`${WORKSTATION}/setIsShowKycModel`, false);
    localStorage.setItem("isClientRaiseEstimateFlow", true);
    await router.push({ name: DASHBOARD_ROUTE });
  } catch (error) {
    console.log("create property ws error", error);
  } finally {
    isLoading.value = false;
  }
};

const setWorkStationType = async () => {
  const selectedTypeData = {
    userId: user.value.id,
    userWorkstationId: activeUserWorkstation.value?.id,
    verificationType:
      workstationType.value === PropertyCategoryEnum.RESIDENTIAL
        ? PropertyOwnerWorkstationType.INDIVIDUAL
        : PropertyOwnerWorkstationType.COMPANY,
  };
  try {
    const response = await store.dispatch(
      `${USER_STORE}/updatePropertyOwnerWSType`,
      selectedTypeData
    );
  } catch (err) {
    console.log();
  }
};

onMounted(async () => {
  try {
    isLoading.value = true;
    await retriveEstimateDetail();
  } catch (error) {
    console.log();
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped></style>
