<template>
  <div class="historic_project" v-if="!isOpenNewProject">
    <div
      v-if="historicalDraftProjects?.length"
      class="historic_project__content"
    >
      <DraftHistoricalProjectCard
        v-for="item in historicalDraftProjects"
        :key="item.id"
        :job="item"
        @click="onClickProject(item)"
      />
    </div>
    <div
      class="empty-screen md:!tw-pt-[24px] md:!tw-mt-0"
      v-if="!historicalDraftProjects?.length && !loading"
    >
      <div class="empty-screen__inner">
        <div class="empty-screen__image md:!tw-ml-0 md:!tw-mb-[24px]">
          <img src="@/assets/images/project-request-empty.png" alt="" />
        </div>
        <div class="empty-screen__header">
          <h2>You currently don't have any Draft Historic project.</h2>
          <p>Kick off your historic project with unparalleled speed.</p>
        </div>
        <div class="empty-screen__content">
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p><strong>Dispute protection</strong> as standard</p>
          </div>
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p>Deposits secured <strong>in Escrow</strong></p>
          </div>
          <div class="empty-screen__content-item">
            <div class="mdi mdi-check-circle"></div>
            <p>
              Verified trade contractors <strong>in your local area</strong>
            </p>
          </div>
        </div>
        <div class="empty-screen__footer">
          <v-btn class="button button-purple" @click="openNewProject" block>
            Start a new Historic project
          </v-btn>
        </div>
      </div>
    </div>

    <Loader v-show="loading" class="tw-mt-[250px]" />
  </div>
  <PropertyListComponent
    @onClickBackButton="onClickBackButton"
    :isHistoricalDraftProjectFlow="isHistoricalDraftProjectFlow"
    v-if="isOpenNewProject"
  />
</template>
<script lang="ts" setup>
import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
import { WORKSTATION } from "@/store/modules/workstation";
import PropertyListComponent from "./PropertyListComponent.vue";
import Loader from "@/core/components/common/Loader.vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import DraftHistoricalProjectCard from "./DraftHistoricalProjectCard .vue";
import { parseJSON } from "@/core/utils/common";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { PROJECT_STORE } from "@/store/modules/project";
const store = useStore();
const loading = ref(false);

const isOpenNewProject = ref(false);
const isHistoricalDraftProjectFlow = ref(false);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const historicalDraftProjects = computed(
  () => store.getters[`${JOBS_STORE}/getHistoricalDraftProjects`]
);

const currentHistoricalProject = ref();
const onClickProject = (project: any) => {
  const draftHistoricalProjectData = parseJSON(
    project.draftHistoricalProjectData
  );
  if (draftHistoricalProjectData.formData) {
    store.dispatch(
      `${JOB_TEMPLATE_STORE}/setFormData`,
      draftHistoricalProjectData.formData
    );
  }
  if (draftHistoricalProjectData.estimateForm) {
    store.dispatch(
      `${JOB_TEMPLATE_STORE}/setEstimateForm`,
      draftHistoricalProjectData.estimateForm
    );
  }
  if (draftHistoricalProjectData.projectSpecialisms) {
    store.dispatch(
      `${PROJECT_STORE}/setProjectSpecialisms`,
      draftHistoricalProjectData.projectSpecialisms
    );
  }
  if (draftHistoricalProjectData?.historicalProjectForm?.attachments) {
    const attachments =
      draftHistoricalProjectData?.historicalProjectForm?.attachments;
    const uploadAttachments = draftHistoricalProjectData?.uploadedAttachment;

    attachments.forEach((item: any) => {
      if (!item.isUploaded) {
        const uploadAttachment = uploadAttachments.find(
          (attachment: any) =>
            attachment.originalName === item.uploadedImages.name
        );
        if (uploadAttachment) {
          item.uploadedImages.url =
            uploadAttachment.src || item.uploadedImages.url;
          item["isUploaded"] = true;
          item["attachment"] = uploadAttachment.attachment;
          item["originalName"] = uploadAttachment.originalName;
          item["publicUrl"] = uploadAttachment.publicUrl;
        }
      }
    });
    store.dispatch(`${PROJECT_STORE}/setProjectAttachments`, attachments);
  }
  currentHistoricalProject.value = project;
  isHistoricalDraftProjectFlow.value = true;
  store.commit(`${JOBS_STORE}/saveHistoricalDraftProject`, project);
  openNewProject();
};
onMounted(async () => {
  await store.dispatch(`${JOBS_STORE}/setDraftHistoricalProjects`);
});

const openNewProject = () => {
  isOpenNewProject.value = true;
};
const onClickBackButton = async () => {
  isOpenNewProject.value = false;
  await store.dispatch(`${JOBS_STORE}/setDraftHistoricalProjects`);
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
}

.close-banner {
  position: absolute;
  top: 10px;
  right: 10px;
}
.historic_project {
  .project_banner {
    width: 100%;
    padding: 16px;
    text-align: left;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    border-radius: 8px;
    margin: 24px 0 0;
    background-color: rgba($white, 1);
    position: relative;
    .project_banner__header {
      display: flex;
      gap: 8px;
      align-items: center;
      h5 {
        @include fluidFont(16, 16, 1.2);
        font-weight: 700;
        color: rgba($blueDark, 1);
        letter-spacing: 0.15px;
      }
    }
    .project_banner__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond(s720) {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 22px 0 0;
      }
      .left__block {
        @include respond(s720) {
          margin-top: 22px;
        }
        h4 {
          @include fluidFont(20, 20, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
        p {
          margin-top: 8px;
          @include fluidFont(14, 14, 1.2);
          font-weight: 500;
          color: rgba($blueDark, 0.5);
          letter-spacing: 0.15px;
          max-width: 500px;
        }
      }
      .right__block {
        margin-right: 150px;
        @include respond(md) {
          margin: 0;
        }
        @include respond(s720) {
          margin: 22px 0 0;
        }
        .close_icon {
          position: absolute;
          right: 16px;
          top: 16px;
          cursor: pointer;
          color: rgba($blueDark, 0.5);
        }
      }
    }
  }
  .content_field {
    margin: 24px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input_field {
      margin: 0;
      width: 100%;
      max-width: 216px;
      :deep(.v-input__control) {
        .v-field {
          border: 1px solid rgba($blueDark, 0.2);
          border-radius: 8px;
          padding: 12px 16px;
          .v-field__field {
            .v-field__input {
              background-color: transparent;
              border: 0;
              border-radius: 0;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              top: 0;
              padding: 0;
              min-height: auto;
              &::placeholder {
                @include fluidFont(12, 12, 1.2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
              }
            }
          }
          .v-field__prepend-inner {
            height: 16px;
            .v-icon {
              color: rgba($blueDark, 1);
              opacity: 1;
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
    }
    .sort__wrapper {
      .v-btn {
        padding: 0;
        height: auto;
        opacity: 1;
        :deep(.v-btn__content) {
          text-transform: capitalize;
          display: flex;
          gap: 10px;
          align-items: center;
          @include fluidFont(14, 14, 1.2);
          font-weight: 600;
          color: rgba($blueDark, 1);
          letter-spacing: normal;
          span {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: rgba($blueDark, 0.05);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        :deep(.v-ripple__container) {
          display: none !important;
        }
      }
    }
    .v-menu {
      :deep(.v-overlay__content) {
        background-color: rgba($white, 1);
        min-width: 185px !important;
        border-radius: 16px;
        box-shadow: 0px 0px 8px 0px #0c0f4a14;
        padding: 16px;
        // top: 222px !important;
        // left: auto !important;
        // right: 40px !important;
        // @include respond(xlg) {
        //   right: 10px !important;
        // }
        // &::before {
        //   content: "";
        //   position: absolute;
        //   width: 30px;
        //   height: 20px;
        //   background-image: url(../../../assets/icons/triangle.svg);
        //   right: 10px;
        //   top: -10px;
        // }
        .v-input {
          .v-input__control {
            .v-selection-control-group {
              gap: 10px;
              .v-selection-control {
                gap: 8px;
                .v-selection-control__wrapper {
                  width: auto;
                  height: auto;
                  .v-selection-control__input {
                    width: auto;
                    height: auto;
                  }
                }
                .v-label {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 600;
                  color: rgba($blueDark, 1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
  .historic_project__card_list {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    flex-wrap: wrap;
    .v-card {
      width: calc(33.33% - 11px);
      max-width: 100%;
      padding: 20px 16px 0;
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      border-radius: 8px;
      @include respond(s1024) {
        width: calc(50% - 8px);
      }
      @include respond(s720) {
        width: 100%;
      }
      .card__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        .card__header__img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .right__block {
          display: flex;
          gap: 16px;
          align-items: center;
          .heart_icon {
            width: 30px;
            height: 30px;
            box-shadow: 0px 0px 7.5px 0px #0c0f4a14;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .card__content {
        text-align: left;
        width: 100%;
        max-width: 100%;
        .card__content__header {
          padding-bottom: 8px;
          border-bottom: 1px solid rgba($cardBorder, 1);
          h5 {
            @include fluidFont(20, 20, 1.3);
            font-weight: 700;
            color: rgba($blueDark, 1);
          }
          .card__info {
            display: inline-flex;
            gap: 12px;
            align-items: center;
            .card__info__left {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              span {
                @include fluidFont(12, 12, 1.3);
                font-weight: 600;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.25px;
                .v-icon {
                  @include fluidFont(10, 10, 1.3);
                }
              }
            }
            .card__info__right {
              display: inline-flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
              .v-icon {
                @include fluidFont(16, 16, 1);
              }
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 400;
                color: rgba($blueDark, 1);
                letter-spacing: 0.25px;
              }
            }
          }
        }
        .card__info__tags {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 0;
          border-bottom: 1px solid rgba($cardBorder, 1);
          @include respond(s1800) {
            flex-wrap: wrap;
          }
          @include respond(s1024) {
            flex-wrap: nowrap;
          }
          @include respond(md) {
            flex-wrap: wrap;
          }
          @include respond(s720) {
            flex-wrap: nowrap;
          }
          @include respond(sm) {
            flex-wrap: wrap;
          }
          .card__info__tags__default {
            padding: 4px 8px;
            display: inline-flex;
            align-items: center;
            gap: 4px;
            background-color: rgba($blueDark, 0.04);
            border-radius: 8px;
            width: 100px;
            max-width: 100%;
            p {
              @include fluidFont(12, 12, 2);
              font-weight: 700;
              color: rgba($success, 1);
              letter-spacing: 0.15px;
              span {
                font-weight: 400;
                color: rgba($blueDark, 1);
              }
            }
          }
          .card__info__tags__custom {
            display: flex;
            gap: 8px;
            align-items: center;
            width: calc(100% - 100px);
            max-width: 100%;
            @include respond(s1800) {
              width: 100%;
              flex-wrap: wrap;
            }
            @include respond(s1024) {
              width: calc(100% - 100px);
              flex-wrap: nowrap;
            }
            @include respond(md) {
              width: 100%;
              flex-wrap: wrap;
            }
            @include respond(s720) {
              width: calc(100% - 100px);
            }
            @include respond(sm) {
              width: 100%;
              flex-wrap: nowrap;
            }
            .custom_tags {
              display: flex;
              gap: 4px;
              align-items: center;
              background-color: rgba($blueDark, 0.04);
              border-radius: 8px;
              padding: 4px 8px;
              // width: 33.33%;
              .v-icon {
                width: 16px;
                height: 16px;
                border-radius: 100%;
                overflow: hidden;
                background-color: rgba($blueDark, 1);
                img {
                  filter: invert(99%) sepia(0%) saturate(3735%)
                    hue-rotate(87deg) brightness(119%) contrast(100%);
                  width: 10px;
                }
              }
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                display: inline-block;
                width: calc(100% - 25px);
                max-width: 100px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @include respond(sm) {
                  max-width: 70px;
                }
              }
            }
            .custom_tags__more {
              display: inline-flex;
              gap: 4px;
              align-items: center;
              background-color: rgba($blueDark, 0.04);
              border-radius: 8px;
              padding: 4px 8px;
              span {
                @include fluidFont(12, 12, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
                display: inline-block;
                width: 100%;
                max-width: 100%;
                white-space: nowrap;
              }
            }
          }
        }
        .card_project_summary {
          padding: 8px 0;
          border-bottom: 1px solid rgba($cardBorder, 1);
          .card_project_summary__list {
            display: flex;
            align-items: center;
            .card_project_summary__list_item {
              width: 33.33%;
              max-width: 100%;
              text-align: center;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                right: 0;
                border-right: 1px solid rgba($cardBorder, 1);
                width: 1px;
                height: 100%;
              }
              &:last-child {
                &::before {
                  border: none;
                }
              }
              p {
                @include fluidFont(12, 12, 2);
                font-weight: 500;
                color: rgba($blueDark, 0.5);
                letter-spacing: 0.15px;
              }
              span {
                @include fluidFont(14, 14, 2);
                font-weight: 700;
                color: rgba($blueDark, 1);
                letter-spacing: 0.15px;
              }
            }
          }
        }
      }
      .card__footer {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.sort__wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  .sort__btn {
    padding: 0;
    background-color: rgba($blueDark, 0.05);
    border-radius: 100%;
    width: 24px;
    min-width: 24px;
    height: 24px !important;
    box-shadow: none;
    .v-ripple__container {
      display: none !important;
    }
  }
}

.sort_dropdown_wrapper {
  position: relative;
}

.v-menu > .v-overlay__content > .v-list.sort_dropdown_inner {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.0784313725);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  height: unset;
  overflow: visible;
  margin-top: 24px;
  width: 185px;
  max-width: 100%;
  margin-left: auto;

  &:before {
    right: 5px;
    top: -23px;
    height: 30px;
    width: 40px;
    position: absolute;
    pointer-events: none;
    margin-top: 0;
    content: "";
    background-image: url(../../../assets/icons/triangle.svg);
    // filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(219deg)
    //   brightness(102%) contrast(101%);
  }

  .v-list-item {
    padding: 0;
    border-radius: 8px !important;
    min-height: 38px;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;

    .v-list-item-title {
      @include fluidFont(14, 14, 1);
      color: #0c0f4a;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: background-color 0.3s ease, color 0.3s ease;

      .v-icon {
        color: #4f55f0;
        font-size: 16px;
      }
    }
  }
}

/* Empty Screen */

.empty-screen {
  padding: 50px 0;
  color: $blueDark;

  &__inner {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }

  &__image {
    max-width: 372px;
    width: 100%;
    margin: 0 auto 46px;

    @media (max-width: 767px) {
      max-width: 278px;
      margin: 0 0 24px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    @include respond(s720) {
      margin: 0 auto;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 36px;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.15px;
    font-weight: 500;
    color: rgba($blueDark, 0.5);

    @media (max-width: 767px) {
      text-align: left;
    }

    h2 {
      @include fluidFont(32, 28, 30px);
      color: $blueDark;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }

  &__content {
    padding: 0 28px;
    text-align: left;
    @include fluidFont(14, 14, 18px);
    letter-spacing: 0.4px;
    display: grid;
    grid-gap: 8px;

    @media (max-width: 767px) {
      padding: 0;
    }

    &-item {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 8px;
      align-items: center;

      .mdi {
        @include fluidFont(24, 24, 1);
        color: rgba($skyBlue, 1);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &__footer {
    margin-top: 36px;
  }
}

.historic_project__content {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  @include respond(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @include respond(s720) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}
</style>
