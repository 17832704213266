<template>
  <v-card
    class="estimate__card tw-py-[20px] tw-px-[16px] tw-bg-white tw-rounded-lg tw-max-w-full !tw-shadow-[0px_0px_8px_0px_#0C0F4A14]"
  >
    <div class="tw-flex tw-justify-between tw-items-center">
      <div
        class="tw-relative tw-h-[40px]"
        v-if="invitedContractors && invitedContractors.length"
      >
        <div
          v-for="(item, i) in invitedContractors"
          :key="item.id"
          class="tw-absolute tw-top-0 tw-rounded-full tw-w-[40px] tw-h-[40px]"
          :class="`tw-left-[${i * 15}px]`"
        >
          <img
            v-if="!contractorsImages[item.id]"
            class="tw-rounded-full tw-border-solid tw-border-white tw-w-full tw-h-full tw-object-cover"
            src="@/assets/images/user-placeholder.jpg"
          />
          <img
            v-if="contractorsImages[item.id]"
            class="tw-rounded-full tw-border-solid tw-border-white tw-w-full tw-h-full tw-object-cover"
            :src="contractorsImages[item.id]"
          />
        </div>
      </div>
      <div
        class="tw-relative tw-h-[40px]"
        v-else-if="!invitedContractors.length"
      >
        <div class="tw-ml-5">
          <ImageHolder
            v-if="workstationImage"
            class="tw-ml-[-20px] !tw-h-[40px] !tw-w-[40px]"
            :src="workstationImage"
            :isRounded="false"
          />
          <UserProfileLogo
            v-else
            class="tw-ml-[-20px] !tw-h-[40px] !tw-w-[40px]"
            :userName="estimateDetails?.workStation?.name"
            :isRounded="false"
          />
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-gap-[16px]">
        <EstimateBadge :badge="estimateBadge" bgColor="#6B5F4B" />
      </div>
    </div>

    <div class="tw-flex tw-items-center tw-gap-1 tw-mt-4">
      <span
        class="tw-text-[20px] tw-font-[700] tw-leading-[25.1px] tw-text-[#0c0f4a]"
      >
        {{ estimateDetails?.workStation?.name }}
      </span>
      <span
        class="tw-text-[10px] tw-font-[600] tw-leading-[22px]"
        v-if="estimateDetails?.inviteContractors?.length"
      >
        + {{ estimateDetails?.inviteContractors?.length }} more
      </span>
    </div>

    <div
      class="tw-flex tw-items-center tw-gap-1 tw-mt-1 tw-pb-2 tw-border-0 tw-border-b tw-border-solid tw-border-[#F1F6FB]"
    >
      <div
        class="tw-flex tw-items-center tw-font-[600] tw-text-[10px] tw-text-[#0C0F4A8a]"
      >
        <span
          >{{ businessType }}
          |
        </span>
        <span
          v-if="estimateDetails?.workStation?.user?.postcode"
          class="tw-flex tw-items-center tw-font-[600] tw-text-[10px] tw-text-[#0C0F4A8a]"
        >
          <v-icon icon="mdi-map-marker" size="10px" />
          {{ estimateDetails?.workStation?.user?.postcode }}
        </span>
      </div>
      <div class="tw-flex tw-items-center tw-gap">
        <v-icon icon="mdi-star" color="yellow" size="16px" />
        <span class="tw-font-[400] tw-text-[12px] tw-text-[#0C0F4A]"
          >{{ estimateDetails?.ratings }}/5 ({{
            estimateDetails?.jobcompleted
          }}
          Jobs)</span
        >
      </div>
    </div>

    <div class="tw-text-left tw-flex tw-items-center tw-mt-3">
      <div
        v-if="estimateDetails?.workStation?.experience"
        class="tw-flex tw-items-center tw-px-2 tw-py-1 tw-bg-[#F1F6FB]"
      >
        <v-icon
          class="tw-text-[12px]"
          icon="mdi-check-circle"
          color="green"
          size="16px"
        />
        <span
          class="tw-pl-[4px] tw-text-[12px] tw-font-bold tw-text-[#1FB27A] tw-tracking-[0.15px] tw-mr-1"
        >
          {{ estimateDetails?.workStation?.experience || "NA" }}
        </span>
        <span
          class="tw-text-[12px] tw-font-bold tw-font-[#0C0F4A] tw-tracking-[0.15px]"
        >
          Exp.
        </span>
      </div>
      <div
        v-if="displayedSkills && displayedSkills.length"
        class="tw-flex tw-gap-2"
      >
        <div
          v-for="skill in displaySubCategory.slice(0, 2)"
          :key="skill.id"
          class="tw-flex tw-gap-1 tw-items-center tw-bg-[#f6f6f6] tw-rounded-lg px-2 py-1"
        >
          <!-- <v-icon icon="mdi-check" color="green" /> -->
          <div
            class="category__icon tw-w-[16px] tw-h-[16px] tw-rounded-full tw-bg-[#0c0f4a] tw-flex tw-items-center tw-justify-center"
          >
            <v-icon
              v-if="skill?.iconName"
              class="specialisms_multiselect_modal_item_title_icon"
            >
              <img
                style="width: 10px"
                v-if="skill?.iconName"
                :src="
                  require('@/assets/category-icons/' + skill?.iconName + '.svg')
                "
                alt="icon"
              />
            </v-icon>
          </div>
          <span
            class="tw-font-bold tw-text-[#0C0F4A] tw-text-sm tw-truncate tw-max-w-[140px] tw-inline-block lg:tw-max-w-[100px]"
          >
            {{ skill.label }}
          </span>
        </div>
      </div>
      <span
        v-if="displaySubCategory.length > 2"
        class="tw-bg-[#0C0F4A] tw-rounded-full tw-text-white tw-text-[10px] tw-font-bold tw-w-[20px] tw-h-[20px] tw-leading-normal tw-flex tw-items-center tw-justify-center ml-2"
      >
        +{{ displaySubCategory.length - 2 }}
      </span>
    </div>

    <div class="tw-mt-4">
      <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mb-1" />
      <div class="tw-grid tw-grid-cols-3">
        <div
          class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
        >
          <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
            Start Date
          </span>
          <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
            {{ formattedDate }}
          </span>
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
        >
          <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
            Duration
          </span>
          <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
            {{ duration }}
          </span>
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center"
        >
          <span class="tw-font-[500] tw-text-[12px] tw-text-[#00000080]">
            Cost
          </span>
          <span class="tw-font-[700] tw-text-[14px] tw-text-[#0C0F4A]">
            {{
              formattedCurrency(
                estimateDetails?.totalEstimateCost,
                estimateDetails?.grantAmount
              )
            }}
          </span>
        </div>
      </div>
      <div class="tw-w-full tw-h-[1px] tw-bg-gray-200 tw-mt-1" />
    </div>

    <div class="tw-mt-4" />

    <Button
      variant="primary"
      label="VIEW ESTIMATE"
      activeFontColor="#FFF"
      @click="viewEstimateDetails"
    />
  </v-card>
</template>
<script lang="ts" setup>
import Button from "@/core/uni-components/Button.vue";
import ImageHolder from "@/core/uni-components/ImageHolder.vue";
import Text from "@/core/uni-components/Text.vue";
import EstimateBadge from "@/core/uni-components/EstimateBadge.vue";
import IconButton from "@/core/uni-components/IconButton.vue";
import CustomBadge from "@/core/uni-components/CustomBadge.vue";
import { computed } from "vue";
import moment from "moment";
import { EstimateTypeForDeposit } from "../enums/estimateEnum";
import { ref, onBeforeMount, onMounted } from "vue";
import {
  getImageApiUrl,
  getImageStringToImageURL,
  getUserSubSkillList,
  parseJSON,
} from "../utils/common";
import $axios from "@/core/utils/axios-api-config";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import UserProfileLogo from "../components/UserProfileLogo.vue";
import { BusinessDetailTypeEnum } from "../enums/RolesEnum";
const emits = defineEmits(["on-view-estimate-details"]);
const props = defineProps({
  estimateDetails: {
    type: Object,
  },
});
const viewEstimateDetails = () => {
  emits("on-view-estimate-details");
};
const store = useStore();
const invitedContractors = ref([]) as any;
const contractorsImages = ref({}) as any;
const workstationImage = ref(null) as any;

const duration = computed(() => {
  if (
    !props.estimateDetails?.suggestedStartDate ||
    !props.estimateDetails?.estimateEndDate
  ) {
    return 0;
  }

  const startDate = moment(props.estimateDetails?.suggestedStartDate) as any;
  const endDate = moment(props.estimateDetails?.estimateEndDate) as any;

  const totalDays = endDate.diff(startDate, "days");

  if (totalDays % 7 === 0) {
    const totalWeeks = totalDays / 7;
    return `${totalWeeks} week${totalWeeks > 1 ? "s" : ""}`;
  }

  return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
});
const formattedDate = computed(() => {
  if (!props.estimateDetails?.suggestedStartDate) {
    return "N/A";
  }
  const date = new Date(props.estimateDetails?.suggestedStartDate);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${day}-${month}-${year}`;
});

const displayedSkills = computed(() => {
  return props.estimateDetails?.workStation?.user?.userSkills.slice(0, 2);
});

const displaySubCategory = computed(() =>
  getUserSkillList(displayedSkills.value)
);
const totalPhaseAmount = (
  amount: number,
  vat: number,
  isFirstPhase: boolean
) => {
  const SERVICE_CHARGE = 0.03;
  const PHASE_FIRST_CHARGE = 5;
  let sum = 0;

  sum += amount + amount * vat;
  sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }

  return sum;
};

const estimateBadge = computed(() => {
  if (
    props.estimateDetails?.depositType ===
    EstimateTypeForDeposit.Initial_Estimate
  )
    return "Initial Estimate";
  else if (
    props.estimateDetails?.depositType ===
    EstimateTypeForDeposit.Fixed_Cost_Estimation
  )
    return "Fixed Cost Estimation";
  else if (
    props.estimateDetails?.depositType ===
    EstimateTypeForDeposit.Variable_Cost_Estimation
  )
    return "Variable Cost Estimation";
  else return "";
});

const formattedCurrency = (amount, grantAmount) => {
  let totalCost = amount;
  if (grantAmount) {
    totalCost -= grantAmount;
  }
  return CurrencyFilter.formatToCurrency(totalCost);
};

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);

const getWsImage = async () => {
  const wsImage = await getImageStringToImageURL(
    props.estimateDetails?.workStation?.profileImage
  );
  try {
    workstationImage.value = wsImage;
  } catch (error) {
    return null;
  }
};

const getUserSkillList = (userSkill: any) => {
  return getUserSubSkillList(userSkill, getAllSpecialism.value);
};

const businessType = computed(() => {
  const subscription = props.estimateDetails?.workStation?.subscription
    ? parseJSON(props.estimateDetails.workStation.subscription)
    : null;

  if (!subscription) {
    return "NA";
  }
  const businessType = subscription?.metadata?.businessType;

  if (businessType) {
    return businessType === BusinessDetailTypeEnum.SOLETRADER
      ? "Sole Trader"
      : businessType === BusinessDetailTypeEnum.LIMITED_COMPANY
      ? "Limited Company"
      : "NA";
  }
  return "NA";
});
onBeforeMount(async () => {
  if (!getAllSpecialism.value.length) {
    await store.dispatch(
      `${USER_STORE}/setAllSpecialismsCategory`,
      activeUserWorkstation.value.id
    );
  }
  const contractors = props?.estimateDetails?.inviteContractors;

  if (contractors && contractors.length) {
    const sliced = contractors.slice(0, 4);
    invitedContractors.value = sliced;
    sliced.map(async (el: any) => {
      if (el?.profileImage) {
        const imageApiUrl = getImageApiUrl(el?.profileImage, true);
        const encoded = (await $axios.get(imageApiUrl)) as any;
        contractorsImages.value[el.id] = encoded.publicUrl;
      }
    });
  }
});

onMounted(async () => {
  await getWsImage();
});
</script>

<style lang="scss" scoped>
.estimate__card {
  width: calc(25% - 18px);
  max-width: 100%;
  @include respond(xxl) {
    width: calc(33.33% - 12px);
  }
  @include respond(xl) {
    width: calc(50% - 8px);
  }
  @include respond(xlg) {
    width: calc(33.33% - 12px);
  }
  @include respond(s1024) {
    width: calc(50% - 8px);
  }
  @include respond(s720) {
    width: 100%;
  }
}
.specialisms_multiselect_modal_item_title_icon {
  img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(20deg)
      brightness(103%) contrast(101%);
  }
}
</style>
