<template>
  <div class="tw-relative">
    <div
      v-if="isStatusTypeChat"
      class="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-border tw-border-solid tw-border-[#FFF] tw-absolute tw-top-0 tw-right-[.2rem] tw-z-10"
      :class="{ 'tw-bg-[#3BC740]': isOnline, 'tw-bg-[#FFA500]': !isOnline }"
    ></div>

    <ChipState
      v-if="
        size !== 'sm' &&
        showVerificationIcon &&
        !isStatusTypeChat &&
        userKycStatus == 0
      "
      bgColor="#FFF"
      :hasShadow="true"
      class="tw-absolute tw-top-[-.5rem] tw-right-[-.5rem] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
    >
      <template #prefix>
        <div>
          <CheckGreenShield v-if="isVerified" />
          <ErrorRedIcon v-else />
        </div>
      </template>
    </ChipState>

    <ChipState
      v-if="
        size !== 'sm' &&
        showVerificationIcon &&
        !isStatusTypeChat &&
        userKycStatus != 0
      "
      bgColor="#FFF"
      :hasShadow="true"
      class="tw-absolute tw-top-[-.5rem] tw-right-[-.5rem] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
    >
      <template #prefix>
        <el-tooltip effect="light" placement="bottom-start">
          <template #content
            ><div class="tw-max-w-[200px]" v-html="toolTipProfile"></div
          ></template>
          <CheckGreenShield v-if="userKycStatus == 2" />
          <InfoIconRed v-else :color="`#faa500`" />
        </el-tooltip>
      </template>
    </ChipState>

    <!-- <div
      class="contractor-detail__img-wrapper tw-w-[32px] tw-h-[32px] tw-rounded-full tw-overflow-hidden"
    >
      <img
        v-if="!src"
        src="@/assets/images/user-placeholder.jpg"
        class="tw-h-full tw-w-full tw-object-cover"
      />
      <img v-if="src" :src="src" class="tw-h-full tw-w-full tw-object-cover" />
    </div> -->
    <div
      class="contractor-detail__img-wrapper !tw-rounded-full tw-overflow-hidden"
      :class="{
        'tw-max-h-[16px] tw-max-w-[16px] tw-min-h-[16px] tw-min-w-[16px] tw-h-[16px]':
          mobilSize === 'xsm',
        'tw-max-h-[24px] tw-max-w-[24px] tw-min-h-[24px] tw-min-w-[24px] tw-h-[24px]':
          mobilSize === 'sm',
        'tw-max-h-[32px] tw-max-w-[32px] tw-min-h-[32px] tw-min-w-[32px] tw-h-[32px]':
          mobilSize === 'mmd',
        'tw-max-h-[40px] tw-max-w-[40px] tw-min-h-[40px] tw-min-w-[40px] tw-h-[40px]':
          mobilSize === 'mmmd',
        'tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px]':
          mobilSize === 'md',
        'tw-max-h-[68px] tw-max-w-[68px] tw-min-h-[68px] tw-min-w-[68px] tw-h-[68px]':
          mobilSize === 'lg',
        'tw-max-h-[98px] tw-max-w-[98px] tw-min-h-[98px] tw-min-w-[98px] tw-h-[98px]':
          mobilSize === 'xlg',
        'tw-max-h-[104px] tw-max-w-[104px] tw-min-h-[104px] tw-min-w-[104px] tw-h-[104px]':
          mobilSize === 'xllg',

        'rsm:tw-max-h-[16px] rsm:tw-max-w-[16px] rsm:tw-min-h-[16px] rsm:tw-min-w-[16px] tw-h-[16px]':
          size === 'xsm',
        'rsm:tw-max-h-[24px] rsm:tw-max-w-[24px] rsm:tw-min-h-[24px] rsm:tw-min-w-[24px] tw-h-[24px]':
          size === 'sm',
        'rsm:tw-max-h-[32px] rsm:tw-max-w-[32px] rsm:tw-min-h-[32px] rsm:tw-min-w-[32px] tw-h-[32px]':
          size === 'mmd',
        'rsm:tw-max-h-[40px] rsm:tw-max-w-[40px] rsm:tw-min-h-[40px] rsm:tw-min-w-[40px] tw-h-[40px]':
          size === 'mmmd',
        'rsm:tw-max-h-[48px] rsm:tw-max-w-[48px] rsm:tw-min-h-[48px] rsm:tw-min-w-[48px] tw-h-[48px]':
          size === 'md',
        'rsm:tw-max-h-[68px] rsm:tw-max-w-[68px] rsm:tw-min-h-[68px] rsm:tw-min-w-[68px] tw-h-[68px]':
          size === 'lg',
        'rsm:tw-max-h-[98px] rsm:tw-max-w-[98px] rsm:tw-min-h-[98px] rsm:tw-min-w-[98px] tw-h-[98px]':
          size === 'xlg',
        'rsm:tw-max-h-[104px] rsm:tw-max-w-[104px] rsm:tw-min-h-[104px] rsm:tw-min-w-[104px] tw-h-[104px]':
          size === 'xllg',
        '!tw-rounded-md': !isRounded,
        '!tw-rounded-md': isRounded,
      }"
    >
      <img
        v-if="!src && !showNameLogo"
        src="@/assets/images/user-placeholder.jpg"
        class="tw-h-full tw-w-full tw-object-cover"
        :class="{ '!tw-rounded-lg': !isRounded, '!tw-rounded-full': isRounded }"
      />
      <UserProfileLogo
        :userName="showNameLogo"
        v-if="!src && showNameLogo"
        :isRounded="isRounded"
        :desktopFontSizeBig="desktopFontSizeBig"
      />
      <img v-if="src" :src="src" class="tw-h-full tw-w-full tw-object-cover" />
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import ChipState from "@/core/components/common/ChipState";
import CheckGreenShield from "@/core/components/icons/CheckGreenShield.vue";
import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
import UserProfileLogo from "../components/UserProfileLogo.vue";
import InfoIconRed from "../components/icons/InfoIconRed.vue";

const props = defineProps({
  size: {
    type: String,
    default: "md", // xsm, sm, mmd, mmmd, md, lg, xlg, xllg
  },
  mobilSize: {
    type: String,
    default: "md", // xsm, sm, mmd, md, lg, xlg, xllg
  },
  showVerificationIcon: {
    type: Boolean,
    default: false,
  },
  isOnline: Boolean,
  isVerified: Boolean,
  isRounded: { type: Boolean, default: true },
  isStatusTypeChat: Boolean,
  src: String,
  showNameLogo: String,
  desktopFontSizeBig: { type: Boolean, default: false },
  userKycStatus: String,
});

const toolTipProfile = computed(() => {
  if (props.userKycStatus == 2)
    return ` <h3 style='margin: 0; color: #40ae42;'>
              KYC Verified
          </h3>
            <p style='margin: 5px 0 0;'> Your identity has been successfully verified! Your account is now fully secure and ready to access all features. Thank you for completing the verification process. </p>
            `;
  else
    return `<h3 style='margin: 0; color: #faa500;'>
            KYC Pending
          </h3>
          <p style='margin: 5px 0 0;'>
            Your identity verification is pending. This step ensures the security of your account, protecting it from fraud and unauthorized access.
          </p>`;
});
</script>

<style lang="scss" scoped></style>
