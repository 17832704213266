<template>
  <div class="submit_estimate_modal">
    <div class="card" :class="{ 'card--success': step !== 0 }">
      <div class="content">
        <!-- <div class="card__super-title" v-if="step === 1">
          Congratulation! 🤗
        </div> -->
        <h4 :class="{ 'small-title': step !== 0 }">
          {{ step == 0 ? "Submitting Estimate" : "Estimate submitted." }}
        </h4>
        <div class="loader_icon">
          <img
            src="@/assets/icons/submitting-estimate.svg"
            alt=""
            v-if="step == 0"
          />
          <img
            src="@/assets/icons/check-blue.svg"
            alt=""
            class="checked"
            v-if="step == 1"
          />
        </div>
        <div class="loader_progress_bar">
          <div class="progress progress_complete"></div>
          <div class="progress"></div>
        </div>
        <div class="tw-mt-6">
          <v-btn
            v-if="step === 0"
            class="button button-orange"
            @click="onCancel"
            width="100%"
            >Cancel</v-btn
          >
          <v-btn
            v-if="step === 1"
            class="button button-orange"
            @click="onCancel"
            width="100%"
            >Okay</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Button from "@/core/components/ui/general/Button.vue";
const props = defineProps({ step: { type: Number } });
const emit = defineEmits(["onCancel"]);
const onCancel = () => {
  emit("onCancel");
};
</script>

<style lang="scss" scoped>
.submit_estimate_modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/overlay-map-image.png");
  z-index: 999;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    align-items: flex-end;
    height: calc(100dvh - 72px);
  }

  .card {
    width: 390px;
    max-width: 100%;
    padding: 32px 20px;
    border-radius: 8px;
    background-color: #f5faff;

    @media (max-width: 767px) {
      width: 100%;
    }

    &__super-title {
      @include fluidFont(24, 24, 30px);
      margin-bottom: 16px;
      color: #0c0f4a;
    }

    .content {
      height: 100%;
      h4 {
        @include fluidFont(24, 24, 30px);
        font-weight: 400;
        letter-spacing: 0.4px;
        padding: 15px 0;
        margin-bottom: 32px;
        color: rgba($PrimaryBlue, 1);

        &.small-title {
          @include fluidFont(16, 16, 22px);
          font-weight: 500;
          color: #0c0f4a;
          padding: 0;
          margin-bottom: 24px;
        }
      }
      .loader_icon {
        max-width: 232px;
        width: 100%;
        margin: 24px auto;
        max-width: 100%;
        width: 100px;
        margin: 24px auto;
        border-radius: 100%;
        background-color: white;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 60px;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }
      .loader_progress_bar {
        display: flex;
        gap: 8px;
        align-items: center;
        .progress {
          width: 100%;
          height: 6px;
          border-radius: 100px;
          background-color: rgba($blueDark, 0.5);
        }
        .progress_complete {
          background-color: rgba($blueDark, 1);
        }
      }
    }

    &--success {
      padding: 32px;

      .loader_icon {
        max-width: 200px;
      }
    }
  }
}
</style>
