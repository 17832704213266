<template>
  <GeneralDialog
    class="dispute-evidence-modal"
    @on-close="onClickBackButton"
    maxWidth="100%"
    headerTitle="View Offer Proposal"
    width="588px"
    :isTitleStrong="true"
    borderRadius="20px"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <div class="modal-body">
            <div class="modal-statement modal-content">
              <label>Statement</label>
              <p>
                {{ props.statement }}
              </p>
            </div>

            <div class="proof_work_modal_inner">
              <p>Attachments</p>
              <div class="proof_work_modal_doc_image_list">
                <div
                  class="proof_work_modal_doc_image_list_item"
                  v-for="attachment in props.userMessageAttachments?.slice(
                    0,
                    5
                  )"
                  :key="attachment?.id"
                >
                  <img
                    v-if="isImageType(attachment)"
                    :src="attachment?.publicURL"
                    alt="Image"
                    width="156"
                    height="156"
                  />

                  <img
                    v-else
                    class="tw-h-[85px]"
                    :src="require('@/assets/icons/sidebar-icons/documents.svg')"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="!props.isSenderUser">
            <v-btn
              class="button button-purple-border"
              @click="onClickBackButton"
              :disabled="isUploadImg"
            >
              Reject
            </v-btn>
            <v-btn
              class="button button-purple"
              @click="onAcceptOffer"
              :loading="isUploadImg"
            >
              Accept
            </v-btn>
          </div>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage, imageExtensions } from "@/core/utils/common";

const emits = defineEmits(["on-close", "on-submit"]);

const props = defineProps({
  statement: {
    type: String,
    default: null,
  },
  userMessageAttachments: {
    type: Array,
  },
  isSenderUser: {
    type: Boolean,
    default: true,
  },
});
const internalInstance = getCurrentInstance();
const isUploadImg = ref<boolean>(false);

const isImageType = (attachments: any) => {
  return imageExtensions.some((ext) =>
    attachments?.publicURL?.toLowerCase().endsWith(ext)
  );
};

const onAcceptOffer = async () => {
  emits("on-close");
  return;
};

const onClickBackButton = () => {
  return emits("on-close");
};
</script>

<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}
</style>
