import { JobsState } from "@/core/models/jobs";

export default {
  getJobs: (state: JobsState) => state.jobs,
  getMyJobs: (state: JobsState) => state.myJobs,
  getDraftJobs: (state: JobsState) => state.draftJobs,
  getDraftJobsByUser: (state: JobsState) => state.draftJobsByUser,
  getRejectedJobs: (state: JobsState) => state.rejectedJobs,
  getJobDetails: (state: JobsState) => state.jobDetails,
  getCompletedJobs: (state: JobsState) => state.completedJobs,
  getHistoricalJobs: (state: JobsState) => state.historicalJobs,
  getActiveHistoricalJob: (state: JobsState) => state.activeHistoricalJob,
  getVideoScheduleTime: (state: JobsState) => state.videoScheduleTime,
  getWorkFlowType: (state: any) => state.workFlowType,
  getJobStatusCountsList: (state: any) => state.jobStatusCountsList,
  getHistoricalDraftProjects: (state: any) => state.historicalDraftProjects,
  getCurrentHistoricalDraftProject: (state: any) => state.currentDraftProject,
  getJobRedirectTabName: (state: any) => state.jobRedirectTabName,
  getJobEmergencyCallOutDetails: (state: any) =>
    state.jobEmergencyCallOutDetails,
  getIsSiteVisitResponseNotification: (state: any) =>
    state.isSiteVisitResponseNotification,
  getTotalPages: (state: any) => state.totalPages,
  getTotalJobs: (state: any) => state.totalJobs,
  getTotalDraftJobs: (state: any) => state.totalDraftJobs,
  getTotalDraftPages: (state: any) => state.totalDraftPages,
  getTotalMyJobs: (state: any) => state.totalMyJobs,
  getTotalMyJobPages: (state: any) => state.totalMyDraftPages,
  getActiveTabInJobDetailsPage: (state: any) => state.activeTabInJobDetailsPage,
};
