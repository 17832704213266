<template>
  <ChipState
    :label="label"
    :isDense="isDense"
    :textColor="textColor"
    :bgColor="bgColor"
  >
    <template #prefix>
      <InfoIconWhite />
    </template>
  </ChipState>
</template>
<script setup>
import ChipState from "@/core/components/common/ChipState";
import InfoIconWhite from "@/core/components/icons/InfoIconWhite";

const props = defineProps({
  label: {
    type: String,
    default: "High Priority",
  },
  isDense: Boolean,
  textColor: {
    type: String,
    default: "#FFF",
  },
  bgColor: {
    type: String,
    default: "#F45454",
  },
});
</script>
<script>
export default {
  name: "StatusChip",
};
</script>
