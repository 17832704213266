<template>
  <div class="tw-w-full tw-text-secondary">
    <div>
      <div
        class="tw-min-h-[100dvh] tw-p-6 tw-py-8 rmd:p-8 tw-flex tw-flex-col"
        :class="
          currentSection === sectionList.CONTROL_CENTER
            ? '!tw-bg-white !tw-p-4 rmd:!tw-p-8'
            : 'tw-bg-on-boarding-bg'
        "
      >
        <div
          class="tw-tw-text-left"
          :class="
            currentSection === sectionList.CONTROL_CENTER
              ? '!tw-pb-8 tw-border-b tw-border-solid tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-b-[#C5D2F2] tw-mb-10 rsm:!tw-mb-11 '
              : 'tw-mb-8 rsm:tw-mb-10 '
          "
        >
          <a
            href="#"
            @click="gotoLoginPage"
            class="tw-max-w-[178px] tw-w-full tw-block"
          >
            <img
              src="@/assets/images/iknowa-logo.svg"
              alt="Logo"
              class="tw-w-full tw-h-auto tw-object-contain"
            />
          </a>
        </div>
        <div
          class="tw-flex tw-flex-auto tw-justify-center tw-w-full tw-text-[#0C0F4A]"
          :class="
            currentSection === sectionList.CONTROL_CENTER
              ? 'tw-items-start'
              : 'tw-items-center'
          "
        >
          <div
            v-if="loading"
            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-[calc(100dvh-221px)]"
          >
            <Loader :show="loading" />
          </div>
          <SingleInputField
            v-if="currentSection === sectionList.FIRST_NAME"
            v-model="queryParams.userName"
            heading="Let's get you started! 🚀
          Enter your first name below."
            label="First Name"
            @onNext="(value) => onNextClick(sectionList.FIRST_NAME, value)"
            :isShowLoginBtn="true"
            :rules="userNameRule"
          />
          <SingleInputField
            v-if="currentSection === sectionList.EMAIL"
            v-model="queryParams.email"
            heading="Please enter your email to create your workstation."
            label="Enter Your Email Address"
            :rules="emailRule"
            :isLoading="isLoading"
            @onNext="(value) => onNextClick(sectionList.EMAIL, value)"
          />
          <OtpFrom
            v-if="currentSection === sectionList.OTP"
            @onNext="(value) => onNextClick(sectionList.OTP, value)"
          />

          <CreatePasswordForm
            v-if="currentSection === sectionList.PASSWORD"
            @onNext="(value) => onNextClick(sectionList.PASSWORD, value)"
            :loading="isLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BoardingControlCenter from "../components/BoardingControlCenter.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  getCurrentInstance,
  ref,
  computed,
  onMounted,
  onBeforeMount,
} from "vue";
import {
  BusinessDetailTypeEnum,
  RolesEnum,
  UserRolesEnum,
} from "@/core/enums/RolesEnum";
import { GET_START_BOARDING_ROUTE } from "@/modules/get-start-boarding/routes";
import SingleInputField from "@/modules/get-start-boarding/components/SingleInputField.vue";
import TradeCompanySelect from "@/modules/get-start-boarding/components/TradeCompanySelect.vue";
import OnboardingTradesIcon from "@/core/components/icons/OnboardingTradesIcon.vue";
import OnboardingPropertyIcon from "@/core/components/icons/OnboardingPropertyIcon.vue";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import {
  deleteAllCookies,
  displayToastMessage,
  parseJSON,
} from "@/core/utils/common";
import { USER_STORE } from "@/store/modules/user";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import { COURSES_STORE } from "@/store/modules/courses";
import { WORKSTATION } from "@/store/modules/workstation";
import OtpFrom from "@/modules/get-start-boarding/components/OtpFrom.vue";
import CreatePasswordForm from "@/modules/get-start-boarding/components/CreatePasswordForm.vue";
import userService from "@/core/services/user.service";
import Loader from "@/core/components/common/Loader.vue";
const router = useRouter();
const route = useRoute();
const store = useStore();
const sectionList = {
  FIRST_NAME: "firstName",
  USER_TYPE: "userType",
  EMAIL: "email",
  CONTROL_CENTER: "controlCenter",
  OTP: "otp",
  PASSWORD: "password",
};
const isLoading = ref(false);
const loading = ref(false);
const internalInstance = getCurrentInstance();
const userTypes = [
  {
    id: 1,
    component: OnboardingPropertyIcon,
    title: "As a Property Owner",
    description:
      "Receive property insights & start retrofit projects with ease.",
    value: RolesEnum.PROJECT_OWNER,
  },
  {
    id: 1,
    component: OnboardingTradesIcon,
    title: "As a Trade Contractor",
    description: "Access up-skilling course & find new jobs",
    value: RolesEnum.TRADESPERSON,
  },
];
const userNameRule = [
  (v) => !!v?.trim() || "First Name is required",
  (v) =>
    /^[a-zA-Z]+[a-zA-Z0-9\s'.,-]*$/.test(v) ||
    "Invalid format. Must start with a letter and can include numbers (not at the beginning), spaces, and valid special characters.",
  (v) => v.length <= 20 || "First Name must be less than 20 characters",
];
const emailRule = [
  (v) => !!v?.trim() || "Email is required",
  (v) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    ) || "Invalid email. Try again.",
];
const defaultWorkstation = ref();

const user = ref();
const queryParams = ref({
  userName: "",
  role: RolesEnum.TRADESPERSON,
  email: "",
  otp: "",
  workstationType: BusinessDetailTypeEnum.SOLETRADER,
});

const inviteUserMemberEmail = computed(() => route.query.memberUserEmail);
const parsedCourseReservationDetails = ref({});
const onVerifyEmailId = async () => {
  try {
    isLoading.value = true;
    await store.dispatch(`${AUTHENTICATION_STORE}/verifyEmail`, {
      email: queryParams.value.email.toLowerCase(),
    });
    return true;
  } catch (error) {
    if (error?.response?.data?.statusCode == 409) {
      displayToastMessage(
        internalInstance,
        error?.response?.data?.message ||
          "something went wrong! please Try Again.",
        "error"
      );
    }
    return false;
  } finally {
    isLoading.value = false;
  }
};

const registerUser = async () => {
  try {
    isLoading.value = true;
    const userData = await store.dispatch(
      `${AUTHENTICATION_STORE}/newRegistration`,
      {
        firstName: queryParams.value.userName,
        lastName: " ",
        marketingOptIn: false,
        tcOptIn: false,
        email: queryParams.value.email,
        roleId: queryParams.value.role,
      }
    );
    user.value = userData;
    await store.dispatch(`${USER_STORE}/saveUserState`, userData);

    await store.dispatch(
      `${USER_STORE}/saveUserRole`,
      UserRolesEnum.TRADESPERSON
    );
    return true;
  } catch (error) {
    displayToastMessage(
      internalInstance,
      "something went wrong! please Try Again.",
      "error"
    );
    return false;
  } finally {
    isLoading.value = false;
  }
};

const createWorkstation = async () => {
  currentSection.value = "";
  loading.value = true;
  try {
    await store.dispatch(`${USER_STORE}/updateUser`, {
      userId: user.value?.id,
      userData: { hasBusinessType: true },
    });
    await store.dispatch(`${WORKSTATION}/getUserWorkstations`, user.value?.id);
    await store.dispatch(`${USER_STORE}/initializeUserState`);
    const courseReserveData =
      parsedCourseReservationDetails.value.selectedDates.map((date) => {
        return {
          courseId: parseInt(
            parsedCourseReservationDetails.value?.courseId,
            10
          ),
          courseScheduleId: date.id,
          selectedSpotCount: date.spots,
        };
      });
    const request = {
      userId: user.value?.id,
      courseReserveData,
    };

    const response = await store.dispatch(
      `${COURSES_STORE}/reserveSpot`,
      request
    );
    store.commit(`${USER_STORE}/setIsRegisteredForCourse`, true);
    store.commit(
      `${COURSES_STORE}/setCourseId`,
      parseInt(parsedCourseReservationDetails.value?.courseId, 10)
    );
    await router.push({ name: DASHBOARD_ROUTE });
  } catch (error) {
    console.log("error while adding workstation", error);
  } finally {
    loading.value = false;
    localStorage.setItem("isShowWelcomePopup", JSON.stringify(false));
    localStorage.removeItem("newAuthenticationFlowDetails");
  }
};
const currentSection = ref(sectionList.FIRST_NAME);
const onNextClick = async (type, value) => {
  if (type === sectionList.FIRST_NAME && queryParams.value.userName) {
    currentSection.value = sectionList.EMAIL;
  } else if (type === sectionList.EMAIL && queryParams.value.email) {
    console.log(queryParams.value.email);
    const goToNextStep = await onVerifyEmailId();
    if (goToNextStep) {
      const isRegister = await registerUser();
      if (isRegister) currentSection.value = sectionList.OTP;
    }
  } else if (type === sectionList.OTP) {
    currentSection.value = sectionList.PASSWORD;
  } else if (type === sectionList.PASSWORD) {
    if (inviteUserMemberEmail.value) {
      router.push({ name: DASHBOARD_ROUTE });
      localStorage.removeItem("newAuthenticationFlowDetails");
    } else {
      createWorkstation();
    }
  }
};
const gotoLoginPage = () => {
  router.push({ name: LOGIN_ROUTE });
};
onMounted(async () => {
  store.commit(`${COURSES_STORE}/setCourseDetailsFromLandingPage`);
  parsedCourseReservationDetails.value = JSON.parse(
    decodeURIComponent(route.query.courseReservation)
  );
});
</script>
