<template>
  <ProjectBanner @update-search="handleSearchUpdate" />
  <div class="tw-flex tw-gap-6 tw-items-start tw-mb-4 tw-flex-wrap">
    <!-- class="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(300px,1fr))] tw-gap-6" -->
    <CommonLoader v-if="isLoading" />
    <EstimateCard
      class=""
      v-for="estimate in filteredEstimates"
      :key="estimate.id"
      :estimateDetails="estimate"
      @on-view-estimate-details="redirectTo(estimate?.id)"
    />
    <div v-if="!filteredEstimates.length">No Estimates</div>
  </div>
</template>
<script lang="ts" setup>
import ProjectBanner from "@/core/uni-components/ProjectBanner.vue";
import EstimateCard from "@/core/uni-components/EstimateCard.vue";
import { useRouter } from "vue-router";
import { TRADE_ESTIMATES_ROUTE } from "../routes";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { ref } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";

const store = useStore();
const isLoading = ref(false);
const router = useRouter();
const searchQuery = ref("");

const tradeEstimateData = computed(() => {
  return store.getters[`${TRADE_ESTIMATE_STORE}/getTradeEstimate`] || [];
});
const filteredEstimates = computed(() =>
  tradeEstimateData.value.filter(
    (estimate: any) =>
      estimate &&
      estimate.workStation?.name &&
      estimate.workStation?.name
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase())
  )
);

const redirectTo = (value: string) => {
  router.push({ name: TRADE_ESTIMATES_ROUTE, params: { estimateId: value } });
};

const handleSearchUpdate = (query: string) => {
  searchQuery.value = query;
};
const props = defineProps({ projectId: { type: Number || String } });

onMounted(async () => {
  try {
    if (props.projectId) {
      isLoading.value = true;
      const res = await store.dispatch(
        `${TRADE_ESTIMATE_STORE}/getTradeEstimateData`,
        {
          projectId: props.projectId,
        }
      );
    } else {
      throw "Project id not found";
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
});
</script>
<style></style>
