import { getImageStringToImageURL } from "@/core/utils/common";

export default {
  setPropertyForm(state: { propertyForm: any }, value: any) {
    state.propertyForm.properties = value;
  },

  async setProperties(state: { properties: any }, value: any) {
    const propertiesWithPublicUrl = await Promise.all(
      value.map(async (item: any) => {
        item.publicUrl =
          item?.propertyImage &&
          (await getImageStringToImageURL(item?.propertyImage));
        return item;
      })
    );
    state.properties = propertiesWithPublicUrl;
  },

  setTotalpages(state: { totalpages: any }, value: any) {
    state.totalpages = value;
  },

  setTotalcount(state: { totalcount: any }, value: any) {
    state.totalcount = value;
  },

  async appendProperties(state: { properties: any }, value: any) {
    const propertiesWithPublicUrl = await Promise.all(
      value.map(async (item: any) => {
        item.publicUrl =
          item?.propertyImage &&
          (await getImageStringToImageURL(item?.propertyImage));
        return item;
      })
    );
    state.properties.push(...propertiesWithPublicUrl);
  },

  setPropertyDetails(state: { propertyDetails: any }, value: any) {
    state.propertyDetails = value;
  },

  setEPCCategoryDataList(state: { epcCategoryDataList: any }, value: any) {
    state.epcCategoryDataList = value;
  },

  setEPCDataListByCategoryId(
    state: { epcDataListByCategoryId: any },
    value: any
  ) {
    state.epcDataListByCategoryId = value;
  },

  setSelectedProperties(state: { selectedProperties: any }, value: any) {
    state.selectedProperties = value;
  },

  setAssetRegisterForm(state: { assetRegisterForm: any }, value: any) {
    state.assetRegisterForm = value;
  },

  setAssetRegisters(state: { assetRegisters: any }, value: any) {
    state.assetRegisters = value;
  },

  setAssetRegisterDetails(state: { assetRegisterDetails: any }, value: any) {
    state.assetRegisterDetails = value;
  },

  setSelectedAsset(state: { selectedAsset: any }, value: any) {
    state.selectedAsset = value;
  },

  setPropertyProjects(state: { propertyProjects: any }, value: any) {
    state.propertyProjects = value;
  },
  setPropertyMapLayers(state: { propertyMapLayers: any }, value: any) {
    state.propertyMapLayers = value;
  },
  setPropertySafetyCheckList(state: any, value: any) {
    state.propertySafetyCheckList = value;
  },
  setNewPropertyProjectData(state: any, value: any) {
    state.newPropertyProjectData.propertyId = value;
  },

  setPropertyLeasesData(state: { propertyLeasesData: any }, value: any) {
    state.propertyLeasesData = value;
  },
  setPropertyProjectSummaryList(
    state: { propertyProjectSummaryList: any },
    value: any
  ) {
    state.propertyProjectSummaryList = value;
  },
  setIsSiteVisitPropertyDetail(
    state: { isSiteVisitPropertyDetail: any },
    value: any
  ) {
    state.isSiteVisitPropertyDetail = value;
  },
  setSiteVisitProjectRefId(state: { siteVisitProjectRefId: any }, value: any) {
    state.siteVisitProjectRefId = value;
  },
  setClientUserDetail(state: { clientUserDetail: any }, value: any) {
    state.clientUserDetail = value;
  },
  setClientProperties(state: { clientProperties: any }, value: any) {
    state.clientProperties = value;
  },
  appendClientProperties(state: { clientProperties: any }, value: any) {
    state.clientProperties.push(...value);
  },
  setTotalClientPropertiesPages(
    state: { totalClientPropertiesPages: any },
    value: any
  ) {
    state.totalClientPropertiesPages = value;
  },
  setClientProjects(state: { clientProjects: any }, value: any) {
    state.clientProjects = value;
  },
  appendClientProjects(state: { clientProjects: any }, value: any) {
    state.clientProjects.push(...value);
  },
  setTotalClientProjectPages(
    state: { totalClientProjectPages: any },
    value: any
  ) {
    state.totalClientProjectPages = value;
  },
  setClientEstimates(state: { clientEstimates: any }, value: any) {
    state.clientEstimates = value;
  },
  appendClientEstimates(state: { clientEstimates: any }, value: any) {
    state.clientEstimates.push(...value);
  },
  setTotalClientEstimatePages(
    state: { totalClientEstimatePages: any },
    value: any
  ) {
    state.totalClientEstimatePages = value;
  },
  setClientUser(state: { clientUserDetail: any }, value: any) {
    if (state?.clientUserDetail?.clientInformation) {
      state.clientUserDetail.clientInformation.user.firstName =
        value?.firstName;

      state.clientUserDetail.clientInformation.user.lastName = value?.lastName;
    }
  },
};
