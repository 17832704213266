<template>
  <GeneralDialog
    width="570px"
    class="raise_question_client_type_modal"
    @on-close="onCloseModal"
    headerTitle="What type of client are you?"
    headerDescription="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
  >
    <template #body>
      <div class="selector">
        <div class="input__form">
          <label>Client Type</label>
          <v-select
            v-model="selectClient"
            :items="options"
            variant="outlined"
            class="input_field_selector"
            item-title="label"
            item-value="value"
            placeholder="Select"
            dense
            hide-details
            return-object
            :rules="[isRequired]"
            :menu-props="{ contentClass: 'tags_dropdown' }"
          >
          </v-select>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal_footer">
        <v-btn class="button button-purple-border" @click="onCloseModal"
          >Cancel</v-btn
        >
        <v-btn
          class="button button-purple"
          :disabled="!selectClient"
          @click="onConfirm"
          >Continue</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { PropertyCategoryEnum } from "@/core/enums/PropertiesEnum";
import { ref } from "vue";
const selectClient = ref(null);
const emits = defineEmits(["on-close", "onNext"]);

const options = [
  {
    label: "Residential - Individual home or apartment",
    value: PropertyCategoryEnum.RESIDENTIAL,
  },
  {
    label: "Commercial - Business or organization",
    value: PropertyCategoryEnum.COMMERCIAL,
  },
];
const isRequired = (value) => !!value || "This field is required";

const onConfirm = () => {
  emits("onNext", selectClient.value.value);
};

const onCloseModal = () => {
  emits("on-close");
};
</script>
<style scoped lang="scss">
.raise_question_client_type_modal {
  .v-overlay__content {
    margin: 0 !important;
    .general-dialog {
      .selector {
        .input__form {
          label {
            @include fluidFont(12, 12, 2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            margin-bottom: 8px;
          }

          .input_field_selector {
            :deep(.v-input__control) {
              .v-field {
                border: 1px solid rgba($bordercolor, 1);
                border-radius: 8px;
                overflow: hidden;
                .v-field__field {
                  .v-field__input {
                    background-color: rgba($backgroundcolor, 1);
                    border: 0;
                    border-radius: 0;
                    @include fluidFont(12, 12, 1.2);
                    font-weight: 400;
                    color: rgba($blueDark, 1);
                    letter-spacing: 0.4px;
                    min-height: 42px;
                    padding: 0 16px;
                    top: 0;
                  }
                }
                .v-field__outline {
                  display: none;
                }
              }
            }
          }
          .input_field_selector {
            :deep(.v-input__control) {
              .v-field {
                .v-field__field {
                  .v-field__input {
                    input {
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .modal_footer {
        padding-top: 24px;
        border-top: 1px dashed rgba($bordercolor, 1);
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: center;
        .button {
          text-transform: capitalize;
          @include fluidFont(16, 16, 20px);
          font-weight: 600;
        }
      }
    }
  }
}
</style>
