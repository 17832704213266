import {
  PreferredContractorsState,
  TradeUserConnectionState,
} from "@/core/models/preferred-contractors";

export default {
  setPreferredContractorsConnection(
    state: PreferredContractorsState,
    payload: Array<any>
  ) {
    state.contractorsConnectionList = payload;
  },
  setTradeUserConnection(state: TradeUserConnectionState, payload: Array<any>) {
    state.tradeUserConnectionList = payload;
  },
  setPreferredContractorsCategory(
    state: PreferredContractorsState,
    payload: Array<any>
  ) {
    state.contractorsCategoryList = payload;
  },
  setContractorsList(state: PreferredContractorsState, payload: Array<any>) {
    state.preferredContractorsList = payload;
  },
  setClientRaiseEstimate(state: PreferredContractorsState, payload: any) {
    state.clientRaiseEstimate = payload;
  },
};
