import { Commit } from "vuex";
import preferredContractorsService from "@/core/services/preferred-contractors.service";
import { InviteContractors } from "@/core/models/preferred-contractors";
import { getImageStringToImageURL } from "@/core/utils/common";
import { TRADE_ESTIMATE_STORE } from "../trade-estimate";

export default {
  async getPreferredContractorsConnection(
    { commit }: { commit: Commit },
    payload: { userId: number; params: any }
  ) {
    try {
      const contractorsConnectionList: any =
        await preferredContractorsService.getPreferredContractorsConnection(
          payload.userId,
          payload.params || {}
        );
      for (const contractor of contractorsConnectionList) {
        contractor.profileImageURL = await getImageStringToImageURL(
          contractor?.userWorkstation?.profileImage
        );
      }

      commit("setPreferredContractorsConnection", contractorsConnectionList);
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async inviteContractors(
    { state, commit }: any,
    payload: { members: InviteContractors; userId: number }
  ) {
    return preferredContractorsService
      .inviteContractors(payload.members, payload.userId)
      .then((response) => {
        return response;
      });
  },
  async getTradeUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.getTradeUserConnection(
          payload.userId
        );
      commit("setTradeUserConnection", contractorsConnectionList);
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async acceptTradeUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number; tradeUserConnectionId: number; key: any }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.acceptTradeUserConnection(
          payload.userId,
          payload.tradeUserConnectionId,
          payload.key
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async acceptPreferredTradeUserConnection(
    { commit }: { commit: Commit },
    payload: {
      userId: number;
      tradeUserConnectionId: number;
      data: any;
    }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.acceptPreferredTradeUserConnection(
          payload.userId,
          payload.tradeUserConnectionId,
          payload.data
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async rejectTradeUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number; tradeUserConnectionId: number }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.rejectTradeUserConnection(
          payload.userId,
          payload.tradeUserConnectionId
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async resendTradeUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number; tradeUserConnectionId: number; key: any }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.resendTradeUserConnection(
          payload.userId,
          payload.tradeUserConnectionId,
          payload.key
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async addPreferredContractorsCategory(
    { commit }: { commit: Commit },
    payload: { userId: number; data: any }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.addPreferredContractorsCategory(
          payload?.userId,
          payload?.data
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getPreferredContractorsCategory(
    { commit }: { commit: Commit },
    payload: { userId: number }
  ) {
    try {
      const contractorsCategoryList =
        await preferredContractorsService.getPreferredContractorsCategory(
          payload.userId
        );
      commit("setPreferredContractorsCategory", contractorsCategoryList);
      return contractorsCategoryList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async movePreferredContractorsCategory(
    { commit }: { commit: Commit },
    payload: { userId: number; tradeUserConnectionId: number; data: any }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.movePreferredContractorsCategory(
          payload.userId,
          payload.tradeUserConnectionId,
          payload.data
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },

  async fetchPreferredContractors(
    { commit }: { commit: Commit },
    payload: any
  ) {
    try {
      const response: any =
        await preferredContractorsService.fetchPreferredContractors(payload);

      for (const contractor of response) {
        contractor.userWorkstation["profileURL"] =
          await getImageStringToImageURL(
            contractor?.userWorkstation?.profileImage
          );
      }

      commit("setContractorsList", response);

      return response;
    } catch (error) {
      console.log("Error fetching preferred contractors:", error);
      commit("setContractorsList", []);
      return [];
    }
  },
  async rejectRequestTradeUserConnection(
    { commit }: { commit: Commit },
    payload: { userId: number; tradeUserConnectionId: number }
  ) {
    try {
      const contractorsConnectionList =
        await preferredContractorsService.rejectRequestTradeUserConnection(
          payload.userId,
          payload.tradeUserConnectionId
        );
      return contractorsConnectionList;
    } catch (error) {
      console.log("error", error);
    }
  },
  async createUpdateClientRaiseEstimate(
    { commit }: { commit: Commit },
    payload: {
      id?: number;
      projectId: number;
      estimateId?: number;
      preferredContractorId?: number;
    }
  ) {
    try {
      const clientRaiseEstimate =
        await preferredContractorsService.createUpdateClientRaiseEstimate(
          payload
        );
      commit("setClientRaiseEstimate", clientRaiseEstimate);
      return clientRaiseEstimate;
    } catch (error) {
      console.log("error", error);
    }
  },
  async getRaiseProjectEstimateDetails(
    { commit }: { commit: Commit },
    token: string
  ) {
    try {
      const response: any =
        await preferredContractorsService.getRaiseEstimateDetail(token);
      const estimateDetails = response.estimateDetails;

      for (const phase of estimateDetails.estimationPhase) {
        if (phase?.phaseNotes?.length) {
          for (const note of phase.phaseNotes) {
            note.user["profileImageUrl"] = await getImageStringToImageURL(
              note?.user?.userPublicProfile?.profileImage
            );
          }
        }
      }
      commit("setClientRaiseEstimate", response.clientRaiseDetails);

      commit(
        `${TRADE_ESTIMATE_STORE}/setTradeEstimateDetails`,
        estimateDetails,
        {
          root: true,
        }
      );
      return response;
    } catch (error) {
      commit("setClientRaiseEstimate", null);

      throw error;
    }
  },
  async setClientRaiseEstimate({ commit }: { commit: Commit }, payload: any) {
    commit("setClientRaiseEstimate", payload);
  },
};
