<template>
  <GeneralDialog
    class="payment-wallet-modal"
    @on-close="onCloseModal"
    width="390px"
    max-width="100%"
    :isShowCloseButton="false"
  >
    <template #body>
      <div class="main tw-text-center tw-space-y-4">
        <v-btn class="wallet_back_btn" variant="text" @click="onCloseModal"
          >Take Me Back</v-btn
        >
        <div class="verify_tag">
          <div class="verify_icon">
            <img src="@/assets/icons/verified.svg" />
          </div>
          <span>Verified</span>
        </div>
        <!-- Header Section -->
        <div
          class="payment-wallet-wrapper tw-flex tw-flex-col tw-items-center tw-space-y-2"
        >
          <div>
            <img
              class="workstation_img"
              :src="
                getWorkStationImage(data.userWorkstationDetails.profileImage)
              "
              loading="lazy"
              alt="workstation image"
            />
          </div>
          <div class="workstation_content">
            <h2 class="tw-text-lg tw-font-bold tw-text-gray-800">
              {{ data.userWorkstationDetails.name }}
            </h2>
            <p class="tw-text-sm tw-text-gray-500">
              {{
                workStationType(
                  data.userWorkstationDetails?.user?.userBusinessDetails?.type
                )
              }}
            </p>
          </div>
          <div class="tw-flex tw-items-center tw-space-x-1 rating__wrapper">
            <v-rating
              :length="5"
              :size="32"
              :value="getAverageOfAllProjectReview"
              active-color="orange"
              color="rgba(12, 15, 74, 0.26)"
              empty-icon="mdi-star"
              readonly
            ></v-rating>
            <p>{{ getAverageOfAllProjectReview }} stars</p>
          </div>
        </div>

        <div class="qr__code tw-flex tw-flex-col tw-items-center tw-space-y-2">
          <div class="tw-relative" v-if="qrVisible">
            <div ref="qrCanvas" class="tw-border tw-rounded"></div>
          </div>
          <p
            class="tw-text-base tw-text-[#4b4bff] tw-cursor-pointer tw-font-semibold tw-leading-[20.08px] tw-no-underline"
            @click="toggleQrVisibility"
          >
            {{ qrVisible ? "Hide QR Code" : "Show QR Code" }}
          </p>
          <v-btn
            @click="openWallet"
            v-if="detectDeviceType"
            class="button button-orange tw-w-full"
          >
            Open Google Wallet
          </v-btn>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="tw-flex tw-items-center tw-justify-center tw-gap-3"></div>
    </template>
  </GeneralDialog>
</template>

<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { ref, onMounted, nextTick } from "vue";
import QRCodeStyling from "qr-code-styling";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  displayToastMessage,
  getEnvironment,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import { getCurrentInstance } from "vue";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import { Environment } from "@/config";
import { computed } from "vue";
const internalInstance = getCurrentInstance();
const emits = defineEmits(["on-cancel", "on-save"]);

const props = defineProps({
  data: {
    default: null,
    type: Object,
  },
  getAverageOfAllProjectReview: {
    type: null,
  },
  linkData: {},
});
const loading = ref(false);
const store = useStore();
const qrCanvas = ref<null>(null);
const qrVisible = ref(true);
const walletImage = ref("");
const qrCode = ref<QRCodeStyling | null>(null);

const onCloseModal = () => {
  emits("on-cancel");
};

const toggleQrVisibility = () => {
  qrVisible.value = !qrVisible.value;

  if (qrVisible.value) {
    nextTick(() => {
      generateQRCode();
    });
  }
};

const generateQRCode = () => {
  let baseUrl = "http://localhost:3000";

  const environment = getEnvironment();

  if (environment === Environment.PRODUCTION) {
    baseUrl = "https://api.iknowa.com";
  } else if (environment === Environment.STAGING) {
    baseUrl = "https://api.lucythepalm.com";
  } else if (environment === Environment.DEVELOPMENT) {
    baseUrl = "https://api.barrythebonsai.com";
  }
  const url = `${baseUrl}/workstation-link-tree/payment/${props.data.workStationLinkId}`;

  if (!qrCode.value) {
    qrCode.value = new QRCodeStyling({
      type: "canvas",
      shape: "square",
      width: 250,
      height: 250,
      data: url,
      image: require("@/assets/images/iknowa-logo.svg"),
      margin: 10,
      qrOptions: {
        // typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        saveAsBlob: true,
        hideBackgroundDots: true,
        imageSize: 0.6,
        margin: 2,
      },
      dotsOptions: {
        type: "extra-rounded",
        color: "#000000",
      },
      backgroundOptions: {
        color: "#ffffff",
      },
      cornersSquareOptions: {
        type: "extra-rounded",
        color: "#000000",
      },
      cornersDotOptions: {
        // type: "",
        color: "#000000",
      },
    });
  } else {
    qrCode.value.update({ data: url });
  }

  if (qrCanvas.value) {
    qrCode.value.append(qrCanvas.value);
  }
};
const getWorkStationImage = (imgUrl: any) => {
  if (!imgUrl)
    return require("@/assets/images/workstation-trade-link-image-2.png");

  return setDirectStoragePathToImage(imgUrl);
};
const workStationType = (businessType: string) => {
  return businessType === BusinessDetailTypeEnum.LIMITED_COMPANY
    ? "LTD Company"
    : "Sole Trader";
};
const getWalletURl = () => {
  let baseUrl = "http://localhost:3000";

  const environment = getEnvironment();

  if (environment === Environment.PRODUCTION) {
    baseUrl = "https://api.iknowa.com";
  } else if (environment === Environment.STAGING) {
    baseUrl = "https://api.lucythepalm.com";
  } else if (environment === Environment.DEVELOPMENT) {
    baseUrl = "https://api.barrythebonsai.com";
  }
  const url = `${baseUrl}/workstation-link-tree/payment/${props.data.workStationLinkId}`;
  return url;
};
const detectDeviceType = computed(() => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;
});

const openWallet = async () => {
  const url = getWalletURl();
  window.open(url, "_blank");
};
onMounted(() => {
  try {
    if (!props.data?.workStationLinkId) {
      displayToastMessage(
        internalInstance,
        "WorkStation Link not found.",
        "error"
      );
    }

    generateQRCode();
  } catch (error: any) {
    if (error.response) {
      const errorMessage =
        error.response.data.message ||
        "Something went wrong, please try again later.";
      displayToastMessage(internalInstance, errorMessage, "error");
    } else if (error.request) {
      displayToastMessage(
        internalInstance,
        "No response from server. Please try again later.",
        "error"
      );
    } else {
      displayToastMessage(internalInstance, `Error: ${error.message}`, "error");
    }
  }
});
</script>

<style scoped lang="scss">
.payment-wallet-modal {
  .verify_tag {
    border: 2px solid #40ae42;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    position: absolute;
    right: 16px;
    top: 16px;
    .verify_icon {
      width: 14px;
      height: 14px;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }

    span {
      display: inline-block;
      margin-left: 4px;
      @include fluidFont(10, 10, 13.45px);
      color: rgba($blueDark, 1);
      letter-spacing: 0.09px;
    }
  }
  .wallet_back_btn {
    position: absolute;
    left: 16px;
    top: 16px;
    color: #4b4bff;
    @include fluidFont(12, 12, 15.06px);
    font-weight: 700;
    padding: 0;
    height: auto;
  }
  .payment-wallet-wrapper {
    padding-bottom: 40px;
    border-bottom: 0.66px dashed #8687a5;
    .workstation_img {
      width: 85px;
      height: 85px;
      border-radius: 8px;
    }
    .workstation_content {
      margin-top: 32px;
      h2 {
        @include fluidFont(23, 23, 28.87px);
        font-weight: 700;
        color: rgba($blueDark, 1);
      }
      p {
        @include fluidFont(12, 12, 15.06px);
        font-weight: 400;
        color: rgba($blueDark, 1);
      }
    }
  }
  .qr__code {
    padding-top: 48px;
  }
}
</style>
