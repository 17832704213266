import $axios from "../utils/axios-api-config";

export default {
  getProjectEstimationList(projectId: number) {
    return $axios.get(`/project-estimate/list/${projectId}`);
  },

  getProjectEstimationDetails(projectEstimateId: number) {
    return $axios.get(`/project-estimate/details/${projectEstimateId}`);
  },

  visitSiteRequest(
    projectEstimateId: number,
    projectId: number,
    formData: any
  ) {
    return $axios.post(
      `/site-visit/${projectEstimateId}/${projectId}`,
      formData
    );
  },

  visitSiteRequestWithoutEstimate(projectId: number, formData: any) {
    return $axios.post(`/site-visit/${projectId}`, formData);
  },

  getSiteRequest(projectId: number, status = 0, workStationId: number) {
    return $axios.get(
      `/site-visit/list/${projectId}?status=${status}&workstationId=${workStationId}`
    );
  },
  updateSiteRequest(siteVisitId: number, formData: any) {
    return $axios.patch(`/site-visit/${siteVisitId}`, formData);
  },

  getProjectEstimateStatus(projectEstimateId: number) {
    return $axios.get(`/project-estimate/estimate-status/${projectEstimateId}`);
  },
  getProjectEstimateWithEscrowStatus(projectEstimateId: number) {
    return $axios.get(`/project-estimate/summary/${projectEstimateId}`);
  },

  updateProjectEstimatePhaseStatus(
    projectEstimateId: number,
    data: {
      escrowStatusId: string;
    }
  ) {
    return $axios.put(
      `/project-estimate/phase/${projectEstimateId}/status`,
      data
    );
  },

  createEstimatePhaseTaskNote(payload: {
    phaseId: number;
    taskId: number;
    note: string;
    isEstimateNote?: boolean;
  }) {
    return $axios.post(`/phase-notes`, payload);
  },

  updateEstimatePhaseTaskNote(payload: {
    phaseId: number;
    taskId: number;
    noteId: number;
    note: string;
  }) {
    return $axios.put(`/phase-notes`, payload);
  },

  deleteEstimatePhaseTaskNote(noteId: number) {
    return $axios.delete(`/phase-notes/${noteId}`);
  },

  getEstimatePhaseTaskNote(phaseId: number) {
    return $axios.get(`/phase-notes/${phaseId}`);
  },

  getEstimatePhaseTaskProofOfWork(phaseId: number) {
    return $axios.get(`/proof-of-work/${phaseId}`);
  },
  createEstimatePhaseTaskProofOfWork(payload: {
    projectId: number;
    phaseId: number;
    attachments: Array<{
      id?: string;
      attachment: string;
      originalName: string;
      properties: Array<{
        id?: number;
        propertyId: number;
        isDeleted?: boolean;
      }>;
      categories: Array<{ id?: number; name: string; isDeleted?: boolean }>;
      isDeleted: boolean;
    }>;
  }) {
    return $axios.post(`/proof-of-work`, payload);
  },
  updateEstimatePhaseTaskProofOfWork(payload: {
    projectId: number;
    phaseId: number;
    attachments: Array<{
      id?: string;
      attachment: string;
      originalName: string;
      properties: Array<{
        id?: number;
        propertyId: number;
        isDeleted?: boolean;
      }>;
      categories: Array<{ id?: number; name: string; isDeleted?: boolean }>;
      isDeleted: boolean;
    }>;
  }) {
    return $axios.put(`/proof-of-work`, payload);
  },
  getProjectCategoryList(projectId: number) {
    return $axios.get(`/proof-of-work/category-list/${projectId}`);
  },
  getProjectEstimateTransactionList(projectId: number, page = 1, limit = 10) {
    return $axios.get(
      `/project-phase-deposits/transaction-list/${projectId}?page=${page}&limit=${limit}`
    );
  },

  updatePhaseCompletionStatus(payload: {
    estimateId: number;
    phaseId: number;
    data: {
      phaseStatus: boolean;
      phaseTask: Array<{
        phaseTaskId: number;
        taskStatus: boolean;
      }>;
    };
  }) {
    return $axios.put(
      `/project-estimate/summary/update-phase-with-task/${payload.estimateId}/${payload.phaseId}`,
      payload.data
    );
  },
  getEscrowBankProviders() {
    return $axios.get(`/payment/providers`);
  },

  createPayDeposit(payload: {
    projectId: number;
    aspspId: number;
    projectPhaseDeposits: Array<{ projectPhaseId: number }>;
  }) {
    return $axios.post(`/project-phase-deposits/pay-all`, payload);
  },

  getEscrowEWalletDetails(projectId: number) {
    return $axios.get(`/project-phase-deposits/account/${projectId}`);
  },
  getEscrowAccountBalance(projectId: number) {
    return $axios.get(`/project-phase-deposits/amount/${projectId}`);
  },
  verifyDepositsPayment(paymentInitiationId: string) {
    return $axios.post(
      `/project-phase-deposits/${paymentInitiationId}/verify-deposits-payment`
    );
  },
  phaseReleaseApprove(payload: { phaseIds: Array<number> }) {
    return $axios.post(
      `/project-phase-deposits/phases/approve-release`,
      payload
    );
  },
  getEstimateDigitalSignature(projectEstimateId: number) {
    return $axios.get(`/project-estimate/signature-data/${projectEstimateId}`);
  },

  getPropertyStatus(projectId: number) {
    return $axios.get(`/project-estimate/property-status/${projectId}`);
  },

  assignPhaseToTaskInEstimate(estimatePhaseTaskId: number, payload: any) {
    return $axios.put(
      `/project-estimate/assign-phase-to-task/${estimatePhaseTaskId}`,
      payload
    );
  },
  getEscrowTransactionList(projectId: number) {
    return $axios.get(
      `project-phase-deposits/transaction-list/download/${projectId}`,
      {
        responseType: "arraybuffer",
      }
    );
  },
  getContractorTradePassportDetail(workStationId: number, userId: number) {
    return $axios.get(
      `users/${userId}/workstations/trade-passport/${workStationId}`
    );
  },

  createEstimateAttachments(payload: {
    projectEstimationId: number;
    estimatePhaseId: number;
    attachments: Array<{
      id?: string;
      attachment: string;
      originalName: string;
      properties: Array<{
        id?: number;
        propertyId: number;
        isDeleted?: boolean;
      }>;
      categories: Array<{ id?: number; name: string; isDeleted?: boolean }>;
      isDeleted: boolean;
    }>;
  }) {
    return $axios.post(`project-estimate/phase-attachments/`, payload);
  },

  updateEstimateAttachments(payload: {
    projectEstimationId: number;
    estimatePhaseId: number;
    attachments: Array<{
      id?: string;
      attachment: string;
      originalName: string;
      properties: Array<{
        id?: number;
        propertyId: number;
        isDeleted?: boolean;
      }>;
      categories: Array<{ id?: number; name: string; isDeleted?: boolean }>;
      isDeleted: boolean;
    }>;
  }) {
    return $axios.put(`project-estimate/phase-attachments`, payload);
  },
  getEstimateAttachments(phaseId: number) {
    return $axios.get(`/project-estimate/phase-attachments/${phaseId}`);
  },
};
