<template>
  <GeneralDialog
    class="tw-w-[650px] tw-max-w-full md:tw-w-[340px]"
    @on-close="onCloseModal"
    width="650px"
    max-width="100%"
  >
    <template #body>
      <div class="tw-text-center tw-mt-20 md:tw-mt-0">
        <!-- <img :src="require('@/assets/icons/hand-icon-check.svg')" alt="" /> -->
        <h3
          class="tw-text-[34px] tw-tracking-[0.15px] tw-leading-6 tw-font-semibold tw-text-[#40AE42] md:tw-text-xl tw-mt-9"
        >
          Hey! Awesome
        </h3>

        <p
          class="tw-text-md tw-tracking-[0.4px] tw-leading-5 tw-font-normal tw-text-[#0C0F4A] tw-mt-5 md:tw-text-xs tw-m-auto md:tw-max-w-full"
        >
          You are closer to fill your escrow wallet
        </p>

        <div
          class="release__payment tw-bg-[#F1F6FB] tw-py-9 tw-px-[75px] tw-mt-[36px] md:tw-px-[24px]"
        >
          <h5
            class="tw-text-2xl tw-tracking-[0.15px] tw-leading-5 tw-font-bold tw-text-[#0C0F4A]"
          >
            Release Payment for Stage 1
          </h5>
          <p
            class="tw-text-base tw-tracking-[0.25px] tw-leading-5 tw-font-medium tw-text-[#0C0F4A] tw-max-w-[90%] tw-my-[36px] tw-mx-auto"
          >
            Once approved and released, your tradesperson will receive payment
            for Phase 1 and the Phase 1 will be marked as complete.
          </p>
          <h4
            class="tw-text-[38px] tw-tracking-[0.25px] tw-leading-10 tw-font-bold tw-text-[#0c0f4a] md:tw-text-3xl"
          >
            {{
              totalPhaseAmount(
                phaseDetails?.subTotal,
                phaseDetails?.vat,
                phaseDetails?.isOld
              )
            }}
          </h4>
        </div>

        <p
          class="tw-text-xs tw-tracking-[0.4px] tw-leading-5 tw-font-normal tw-text-[#0c0f4a80] tw-mt-7 tw-max-w-[82%] tw-m-auto md:tw-max-w-full"
        >
          By confirming you are agreeing to our
          <strong class="tw-text-[#0c0f4a]"> Terms & Conditions </strong>
          and
          <strong class="tw-text-[#0c0f4a]"> Privacy Policy.</strong>
        </p>

        <p
          class="tw-text-xs tw-tracking-[0.4px] tw-leading-5 tw-font-normal tw-text-[#0c0f4a80] tw-mt-7 tw-m-auto md:tw-max-w-full"
        >
          Additionally, by confirming you are permitting our partner Modulr FS
          Limited to initiate a payment from your bank account and also agree to
          their
          <strong class="tw-text-[#0c0f4a]">Terms & Conditions</strong> and
          <strong class="tw-text-[#0c0f4a]">Privacy Policy</strong>
        </p>
      </div>
      <CommonLoader
        v-if="loading"
        :loading="loading"
        class="loader-container"
      />
    </template>
    <template #footer>
      <div
        class="tw-w-full tw-grid tw-grid-cols-2 tw-items-center tw-gap-3 tw-mt-6 md:tw-grid-cols-1"
      >
        <Button
          variant="tertiary"
          :disabled="loading"
          label="Cancel"
          @click="onCloseModal"
          width="auto"
          class="button button-purple-border"
        />
        <Button
          variant="tertiary"
          label="Approve and release payment"
          :isActive="true"
          :loading="Loadingvalue"
          @click="onConfirm"
          width="auto"
          class="button button-orange"
        />
      </div>
      <!-- <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
        >
          <Text
            class="tw-max-w-[80%] tw-mt-6"
            variant="h6"
            textWeight="500"
            textColor="rgba(12, 15, 74, 0.5)"
            textAlign="center"
            >All estimates need to follow
            <span class="!tw-text-[orange]">our Terms & Conditions</span></Text
          >
        </div> -->
    </template>
  </GeneralDialog>
</template>
<script setup lang="ts">
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CurrencyFilter from "@/filters/currency.filter";
import { defineEmits, ref, defineProps } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import tradeEstimateService from "@/core/services/trade-estimate.service";
const props = defineProps({
  phaseDetails: {
    type: Object,
  },
  Loadingvalue: {
    type: Boolean,
    default: false,
  },
});
const SERVICE_CHARGE = 0.03;
const PHASE_FIRST_CHARGE = 5;
const loading = ref(false);
const formattedCurrency = (value: any) => {
  return CurrencyFilter.formatToCurrency(value);
};
const emits = defineEmits(["on-close", "on-confirm"]);
const onCloseModal = () => {
  emits("on-close");
};
const totalPhaseAmount = (
  amount: number,
  vat: number,
  isFirstPhase: boolean
) => {
  let sum = 0;

  sum += amount + amount * vat;
  sum += sum * SERVICE_CHARGE;
  if (isFirstPhase) {
    sum += PHASE_FIRST_CHARGE;
  }
  return CurrencyFilter.formatToCurrency(sum);
};
const onConfirm = () => {
  emits("on-confirm", false);
};
</script>
