<template>
  <div class="map-container tw-w-full tw-h-full tw-max-h-full">
    <div ref="googleMap" class="map tw-w-full tw-h-full"></div>
    <div
      ref="customMapHeader"
      :class="isFullScreen ? 'custom-header' : '!tw-hidden'"
    >
      <HeaderPage :isInMapView="true" />
    </div>
    <div
      ref="sidebar"
      :class="['map__content', { 'sidebar-hidden': !isSidebarVisible }]"
    >
      <!-- CONTROL PANEL -->
      <div
        :class="[
          isFullScreen ? 'sidebar-navigation' : '!tw-hidden',
          isSidebarVisible
            ? 'sidebar-navigation-open'
            : 'sidebar-navigation-close',
        ]"
        class="!tw-min-w-[328px] !tw-w-[328px] tw-flex"
      >
        <div
          class="control-panel tw-w-full tw-bg-white tw-px-4 tw-py-6 tw-box-border tw-flex tw-flex-col tw-gap-4 tw-items-start tw-overflow-y-auto"
        >
          <div
            class="tw-w-full tw-items-center tw-justify-start tw-mb-2 tw-flex tw-flex-col"
          >
            <div class="properties__info__list">
              <v-text-field
                prepend-inner-icon="mdi-magnify"
                class="input_field tw-w-full tw-max-w-full search_field"
                placeholder="Search"
                v-model="propertySeach"
                variant="outlined"
                density="compact"
              />
              <div class="properties__info__list_item">
                <div
                  class="properties_wrapper_info"
                  v-for="(property, index) in seachingPropertyList"
                  :key="property?.id"
                >
                  <div class="properties_wrapper">
                    <div class="prop_name">
                      <p class="pointer_events_none">
                        {{ index + 1 }}
                      </p>
                      <h6 class="pointer_events_none">
                        {{ property?.address }}
                      </h6>
                    </div>
                    <div class="property__type__wrapper">
                      <div class="location">
                        <v-icon
                          icon="mdi mdi-map-marker"
                          class="pointer_events_none"
                        ></v-icon>
                        {{ property?.postcode }}
                      </div>

                      <div class="property_type">
                        <ResidentialChip
                          v-if="
                            property?.propertyCategory ===
                            PropertyCategoryEnum.RESIDENTIAL
                          "
                        />
                        <CommercialChip
                          v-if="
                            property?.propertyCategory ===
                            PropertyCategoryEnum.COMMERCIAL
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          @click="toggleSidebar"
          class="panel-switch tw-min-w-[27px] tw-w-[27px] tw-bg-[#F1F6FB] tw-cursor-pointer tw-flex tw-items-center tw-justify-center"
        >
          <ArrowMenuLeft v-if="isSidebarVisible" />
          <ArrowMenuRight v-else />
        </div>
      </div>

      <div class="right__block" :class="{ '!tw-top-[89px]': isFullScreen }">
        <div class="map-settings !tw-min-w-[120px] !tw-w-[120px]">
          <div
            @click="onFullScreenRequest"
            class="tw-cursor-pointer tw-rounded-[6px] tw-bg-white tw-p-[.4rem] tw-box-border tw-w-full tw-h-[36px] tw-flex tw-items-center tw-justify-center tw-gap-2"
          >
            <Text variant="p" textWeight="400" whiteSpace="nowrap">{{
              isFullScreen ? "Close Map" : "Open Map "
            }}</Text>
            <v-icon
              icon="mdi-close"
              v-if="isFullScreen"
              color="#0C0F4A"
              class="!tw-text-[20px] !tw-font-bold !z-[-99999]"
            ></v-icon>
            <img src="@/assets/icons/zoom.svg" alt="" v-else />
          </div>
        </div>
      </div>

      <div class="bg__overlay" v-if="loading">
        <CommonLoader :loading="loading" color="white" class="loader" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, onUnmounted, computed } from "vue";
import { nextTick } from "vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import Text from "@/core/components/ui/general/Text.vue";
import ArrowMenuLeft from "@/core/components/icons/ArrowMenuLeft.vue";
import ArrowMenuRight from "@/core/components/icons/ArrowMenuRight.vue";
import HeaderPage from "@/core/components/HeaderPage.vue";
import createGoogleMapsLoader from "@/core/utils/google-map-loader";
import { parseJSON } from "@/core/utils/common";
import { PropertyCategoryEnum } from "@/core/enums/PropertiesEnum";
import ResidentialChip from "@/core/uni-components/Chips/ResidentialChip.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";

const sidebar = ref(null);
const customMapHeader = ref(null);
const googleMap = ref(null);
const googleMapInstance = ref(null);
const loading = ref(false);
const isSidebarVisible = ref(true);
const isFullScreen = ref(false);
const propertySeach = ref(null);
const emits = defineEmits(["close"]);
const props = defineProps({
  propertyList: {
    type: Array,
    default: () => [],
  },
  defaultFullScreen: {
    type: Boolean,
    default: true,
  },
});

const toggleSidebar = () => {
  isSidebarVisible.value = !isSidebarVisible.value;
};
const filterPropertyList = computed(() =>
  props.propertyList?.length
    ? props.propertyList.map((property) => property.property)
    : []
);
const seachingPropertyList = computed(() => {
  if (propertySeach.value) {
    return filterPropertyList.value.filter(
      (property) =>
        (property.address &&
          property.address
            .toLowerCase()
            .includes(propertySeach.value.toLowerCase())) ||
        (property.postcode &&
          property.postcode
            .toLowerCase()
            .includes(propertySeach.value.toLowerCase())) ||
        (property.propertyCategory &&
          property.propertyCategory
            .toLowerCase()
            .includes(propertySeach.value.toLowerCase()))
    );
  } else {
    return filterPropertyList.value;
  }
});
const loader = createGoogleMapsLoader();

const initializeMap = async () => {
  if (!window.google || !window.google.maps) {
    console.error("Google Maps API is not loaded");
    return;
  }

  const mapOptions = {
    center: { lat: 55.3781, lng: -3.436 }, // Default center (UK)
    zoom: 15,
    rotateControl: false,
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    fullscreenControlOptions: {
      position: window.google.maps.ControlPosition.TOP_LEFT,
    },
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_CENTER,
    },
  };

  googleMapInstance.value = new google.maps.Map(googleMap.value, mapOptions);

  const bounds = new google.maps.LatLngBounds();

  nextTick(() => {
    const sidebarDiv = sidebar.value;
    const customMapHeaderDiv = customMapHeader.value;
    googleMapInstance.value.controls[
      window.google.maps.ControlPosition.TOP_RIGHT
    ].push(sidebarDiv);
    googleMapInstance.value.controls[
      window.google.maps.ControlPosition.TOP
    ].push(customMapHeaderDiv);
  });

  filterPropertyList.value.forEach((property, index) => {
    const address = parseJSON(property.addressData);
    const lat = address.lat || address.LAT;
    const lng = address.lng || address.LNG;

    if (lat && lng) {
      const position = new google.maps.LatLng(lat, lng);
      const svgIcon = `
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 36.5C28.665 36.5 36.5 28.665 36.5 19C36.5 9.33502 28.665 1.5 19 1.5C9.33502 1.5 1.5 9.33502 1.5 19C1.5 28.665 9.33502 36.5 19 36.5Z" fill="#0c0f4a" stroke="#0c0f4a" stroke-width="2"/>
          <text x="20" y="25" font-size="16" text-anchor="middle" fill="#fff" font-weight="bold" font-family="Mulish, sans-serif">${
            index + 1
          }</text>
        </svg>
      `;

      const marker = new google.maps.Marker({
        position,
        map: googleMapInstance.value,
        icon: {
          url: `data:image/svg+xml;utf-8,${encodeURIComponent(svgIcon)}`,
          scaledSize: new google.maps.Size(32, 32),
        },
      });

      //   marker.addListener("click", () => {
      //     console.log("onClickMarker", { lat, lng });
      //   });

      bounds.extend(position);
    } else {
      console.warn(
        `Property at index ${index} does not have valid lat/lng values.`
      );
    }
  });

  if (filterPropertyList?.value?.length) {
    googleMapInstance.value.fitBounds(bounds);
  }

  const maxZoomLevel = 15;
  google.maps.event.addListenerOnce(
    googleMapInstance.value,
    "bounds_changed",
    () => {
      if (googleMapInstance.value.getZoom() > maxZoomLevel) {
        googleMapInstance.value.setZoom(maxZoomLevel);
      }
    }
  );
};
const onFullScreenRequest = () => {
  document.addEventListener("keydown", (event) => {
    if (isFullScreen.value && event.key === "Escape") {
      exitFullscreen();
      isFullScreen.value = false;
      return;
    }
  });

  if (isFullScreen.value) {
    exitFullscreen();
    if (props.defaultFullScreen) {
      emits("close");
    }
  } else {
    enterFullscreen();
  }
};

const enterFullscreen = () => {
  const mapContainer = googleMap.value;
  isFullScreen.value = true;
  isSidebarVisible.value = true;
  if (mapContainer.requestFullscreen) {
    mapContainer.requestFullscreen();
  } else if (mapContainer.mozRequestFullScreen) {
    // Firefox
    mapContainer.mozRequestFullScreen();
  } else if (mapContainer.webkitRequestFullscreen) {
    // Chrome, Safari and Opera
    mapContainer.webkitRequestFullscreen();
  } else if (mapContainer.msRequestFullscreen) {
    // IE/Edge
    mapContainer.msRequestFullscreen();
  }
};

const exitFullscreen = () => {
  isFullScreen.value = false;
  isSidebarVisible.value = false;
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    // Chrome, Safari and Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    // IE/Edge
    document.msExitFullscreen();
  }
};

onMounted(async () => {
  try {
    await loader.importLibrary("places");
    initializeMap();
    if (props.defaultFullScreen) {
      enterFullscreen();
    }
  } catch (error) {
    console.error("Failed to initialize Google Maps:", error);
  }
});

onUnmounted(() => {
  if (googleMapInstance.value) {
    google.maps.event.clearInstanceListeners(googleMapInstance.value);
    googleMapInstance.value = null;
  }
});
</script>
<style lang="scss" scoped>
:deep(.v-selection-control) {
  height: 20px;
  min-height: 20px !important;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.collapsible-property-map {
  background: #f1f6fb;

  :deep(.v-expansion-panel-title) {
    padding: 0 1rem;
    min-height: 60px;
  }
  :deep(.v-expansion-panel__shadow) {
    // box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
    box-shadow: none;
  }
  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
  }
}
.panel-control-wrapper {
  width: 100%;
  margin-top: 7px;
  .panel-control-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      @include fluidFont(12, 12, 1.3);
      font-weight: 500;
      color: rgba($blueDark, 1);
    }
  }
  :deep(.v-slider.v-input--horizontal) {
    margin: 0 !important;
  }
}
:deep(.panel-size-wrapper) {
  margin-top: 8px;
  .v-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    .input_field {
      width: 100%;
      max-width: 100%;
      .v-input__control {
        .v-field {
          padding: 0;
          border-radius: 8px;
          border: 1px solid rgba($buttonText, 0.4);
          background-color: transparent;
          .v-field__field {
            .v-field__input {
              column-gap: 7px;
              padding: 8px 16px;
              min-height: auto;
              @include fluidFont(12, 12, 1.3);
              font-weight: 500;
              color: rgba($blueDark, 1);
              &::placeholder {
                color: rgba($blueDark, 1);
                @include fluidFont(12, 12, 1.3);
                font-weight: 500;
              }
            }
            .v-text-field__suffix {
              padding: 8px 16px;
              min-height: auto;
              @include fluidFont(12, 12, 1.3);
              font-weight: 500;
              color: rgba($blueDark, 1);
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
    }
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: "Mulish", sans-serif !important;

  .custom-header {
    height: 66px; /* Adjust header height */
    background-color: #fff; /* Add header styling */
    padding: 16px 20px;
    box-sizing: border-box;
    z-index: 10; /* Ensure header is above the map */
    width: 100%;
    display: flex !important;
    // flex: 0 0 auto;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .map {
    width: 100%;
    height: 100%;
  }
  .map__content {
    padding: 20px;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: unset !important;

    .sidebar-navigation {
      position: relative;
      top: 46px;
      bottom: 0;
      height: calc(100dvh - 66px);
      overflow: hidden;
      transition: left 0.3s ease;
      z-index: 1;

      .control-panel {
        // overflow: hidden;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .panel-switch {
        height: 56px;
        margin-top: 1.5rem;
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
      }
    }

    .sidebar-navigation-close {
      left: -321px;
    }
    .sidebar-navigation-open {
      left: -20px;
    }

    .left__block {
      z-index: 9999;
      position: absolute;
      left: 20px;
      // top: 20px;
      bottom: 32px;
      z-index: 11;
      .data_layer__details {
        padding: 10px;
        box-shadow: 0px 0px 4px 2px #0000000f;
        border-radius: 8px;
        width: 195px;
        height: 100%;
        min-height: 150px;
        background-color: rgba($white, 1);
        display: flex;
        gap: 10px;
        flex-direction: column;
        .data_layer__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba($black, 0.1);
          h6 {
            @include fluidFont(12, 12, 1.2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            letter-spacing: 0.15px;
          }
        }
        .data_layer__description {
          p {
            @include fluidFont(10, 10, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.12px;
            text-align: left;
          }
        }
        .data_layer__footer {
          .modal__weather {
            height: 7px;
            width: 100%;
            background: linear-gradient(
              90deg,
              #3e52b0 0%,
              #7ecff7 24.01%,
              #71be6c 49.01%,
              #fadf81 76.01%,
              #e7473b 100%
            );
          }
          .modal__temperature {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              @include fluidFont(10, 10, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.15px;
            }
          }
        }
      }
    }
  }
  .right__block {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    @include respond(xs) {
      gap: 10px;
      right: 0;
    }

    .map-settings {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 30px;
      .v-icon {
        width: 27px;
        height: 27px;
        min-width: 27px;
        max-width: 27px;
        background-color: rgba($white, 1);
        box-shadow: 0px 0px 27px 0px #0c0f4a1a;
        border-radius: 6px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .hide-map-settings {
      right: 0;
      left: auto;
      position: absolute;
    }
    .custom-column {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      max-width: 100%;
      // height: calc(100dvh - 372px);
      max-height: 540px;
      overflow: auto;
      .custom__column {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .sidebar-content {
          height: auto;
          max-height: 400px;
          box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
          z-index: 10;
          transition: transform 0.3s ease;
          background-color: rgba($white, 1);
          border-radius: 8px;
          overflow-x: hidden;
          overflow-y: auto;
          width: 100%;
          max-width: 100%;
          position: relative;
          font-family: "Mulish", sans-serif !important;
        }
      }
    }
  }
}

.sidebar-content {
  height: 100%;
  max-height: 400px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: transform 0.3s ease;
  background-color: rgba($white, 1);
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  font-family: "Mulish", sans-serif !important;
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: rgba($cardBorder, 1);
    border-radius: 8px;
    h5 {
      @include fluidFont(12, 12, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      font-family: "Mulish", sans-serif !important;
      text-align: left;
    }
    .button {
      min-height: auto;
      height: auto;
      padding: 4px 10px;
      border-radius: 4px;
      &:hover {
        :deep(.v-btn__content) {
          color: rgba($orange, 1);
        }
      }
      :deep(.v-btn__content) {
        @include fluidFont(10, 10, 1.2);
        color: rgba($blueDark, 1);
        font-weight: 400;
        text-transform: capitalize;
        letter-spacing: normal;
      }
    }
    .v-icon {
      color: rgba($blueDark, 1);
      @include fluidFont(14, 14, 1.2);
    }
    .custom__chip {
      @include fluidFont(10, 10, 1.2);
      font-weight: 400;
      color: rgba($white, 1);
      background-color: rgba($orange, 1);
      padding: 4px 10px;
      border-radius: 4px;
    }
  }
  .content-wrapper {
    text-align: right;
    max-height: 220px;
    overflow: auto;
    .content-selector {
      .content-item {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 8px 16px;
        border-bottom: 1px solid rgba($blueDark, 0.1);
        text-align: left;
        .v-icon {
          @include fluidFont(14, 14, 1);
        }
        .v-input {
          :deep(.v-input__control) {
            .v-selection-control {
              min-height: auto;
              .v-selection-control__wrapper {
                min-height: auto;
                width: auto;
                height: auto;
                .v-switch__track {
                  height: 14px;
                  min-width: 26px;
                  padding: 0;
                  opacity: 1;
                }
                .v-selection-control__input {
                  width: 14px;
                  height: 14px;
                  .v-icon {
                    @include fluidFont(14, 14, 1);
                  }
                  .v-switch__thumb {
                    width: 11px;
                    height: 12px;
                    background-color: rgba($white, 1) !important;
                    left: 5px;
                    right: auto;
                  }
                }
              }
            }
            .v-selection-control--dirty {
              .v-selection-control__wrapper {
                .v-selection-control__input {
                  .v-switch__thumb {
                    left: -4px;
                  }
                }
              }
            }
          }
        }
        span {
          color: rgba($blueDark, 1);
          @include fluidFont(12, 12, 1.3);
          display: inline-block;
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .accordion-wrapper {
      :deep(.v-expansion-panels) {
        .v-expansion-panel {
          .v-expansion-panel__shadow {
            box-shadow: none;
          }
          .v-expansion-panel-title {
            @include fluidFont(12, 12, 1.2);
            font-weight: 700;
            color: rgba($blueDark, 1);
            padding: 16px;
            min-height: auto;
            .v-expansion-panel-title__overlay {
              display: none;
            }
          }
          .v-expansion-panel-text {
            padding: 0 16px 16px;
            .v-expansion-panel-text__wrapper {
              padding: 0;
              .panel-control-wrapper {
                .panel-control-list {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  span {
                    @include fluidFont(12, 12, 1.3);
                    font-weight: 500;
                    color: rgba($blueDark, 1);
                  }
                }
              }
              .panel-size-wrapper {
                margin-top: 24px;
                .v-form {
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                  align-items: flex-start;
                  .input_field {
                    width: 100%;
                    max-width: 100%;
                    .v-input__control {
                      .v-field {
                        padding: 0;
                        border-radius: 8px;
                        border: 1px solid rgba($buttonText, 0.4);
                        background-color: transparent;
                        .v-field__field {
                          .v-field__input {
                            column-gap: 7px;
                            padding: 8px 16px;
                            min-height: auto;
                            @include fluidFont(12, 12, 1.3);
                            font-weight: 500;
                            color: rgba($blueDark, 1);
                            &::placeholder {
                              color: rgba($blueDark, 1);
                              @include fluidFont(12, 12, 1.3);
                              font-weight: 500;
                            }
                          }
                          .v-text-field__suffix {
                            padding: 8px 16px;
                            min-height: auto;
                            @include fluidFont(12, 12, 1.3);
                            font-weight: 500;
                            color: rgba($blueDark, 1);
                          }
                        }
                        .v-field__outline {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidebar-hidden {
  // justify-content: end !important;
  justify-content: space-between;
  .overlay_bg {
    display: none !important;
  }
  .custom-column {
    display: none !important;
  }
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}
.tilt-button {
  position: absolute;
  top: 60%;
  left: 9px;
  padding: 10px;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 5;
  height: 40px;
  width: 40px;
}

.layer__wrapper {
  position: fixed;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba($white, 1);
  box-shadow: 0px 0px 4px 2px #0000000f;
  border-radius: 4px;
  width: 225px;
  z-index: 11;
  .layer__header {
    text-align: left;
    width: 100%;
    max-width: 100%;
    padding: 8px 10px;
    border-bottom: 1px solid rgba($blueDark, 0.1);
    h5 {
      @include fluidFont(12, 12, 1.3);
      font-weight: 600;
      letter-spacing: 0.15px;
      color: rgba($blueDark, 1);
    }
  }
  .layer__body {
    width: 100%;
    max-width: 100%;
    text-align: left;
    padding: 8px 10px;
    label {
      @include fluidFont(10, 10, 1.2);
      font-weight: 400;
      color: rgba($blueDark, 1);
      letter-spacing: 0.12px;
      display: inline-block;
      margin-bottom: 4px;
    }
    .input_field {
      margin: 0;
      --v-input-control-height: auto;
      :deep(.v-input__control) {
        .v-field {
          border: 1px solid rgba($blueDark, 0.2);
          border-radius: 4px;
          padding: 6px 12px;
          .v-field__field {
            .v-field__input {
              background-color: transparent;
              border: 0;
              border-radius: 0;
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              min-height: auto;
              padding: 0;
            }
          }
          .v-field__outline {
            display: none;
          }
        }
      }
    }
  }
  .layer__footer {
    padding: 8px 10px;
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    .button {
      padding: 4px 12px;
      min-height: auto;
      height: auto;
      border-radius: 5px;
      :deep(.v-btn__content) {
        @include fluidFont(10, 10, 1.2);
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}
.layer__wrapper.in-full-screen {
  left: 24% !important;
}
.footer-actions {
  position: absolute;
  bottom: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  .action-container {
    height: 100%;
    width: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.shape__btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  gap: 15px;
  align-items: center;
  .v-btn {
    width: 46px;
    height: 46px;
    border-radius: 4px;
    border: 1px solid rgba($blueDark, 0.1);
    box-shadow: none;
    padding: 0;
    :deep(.v-btn__content) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      span {
        @include fluidFont(6, 6, 1.2);
        color: rgba($blueDark, 1);
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: normal;
      }
    }
  }
  .button-orange,
  .button-purple-border {
    width: auto;
    height: auto;
    min-height: auto;
    padding: 8px 10px;
    :deep(.v-btn__content) {
      @include fluidFont(12, 12, 1.2);
      text-transform: capitalize;
      font-weight: 500;
    }
  }
}

.layer__details {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: rgba($white, 1);
  width: 230px;
  border-radius: 4px;
  margin: 0;
  .content-header {
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba($blueDark, 0.1);
    h5 {
      @include fluidFont(12, 12, 1.2);
      font-weight: 600;
      color: rgba($blueDark, 1);
    }
  }
  .content-wrapper {
    padding: 7px 10px;
    .inner__wrapper {
      .detail__content {
        display: flex;
        gap: 2px;
        align-items: center;
        span {
          @include fluidFont(10, 10, 1.2);
          font-weight: 400;
          color: rgba($blueDark, 1);
          letter-spacing: 0.12px;
        }
      }
    }
  }

  .v-input {
    :deep(.v-input__control) {
      .v-selection-control {
        min-height: auto;
        .v-selection-control__wrapper {
          min-height: auto;
          width: auto;
          height: auto;
          .v-switch__track {
            height: 14px;
            min-width: 26px;
            padding: 0;
            opacity: 1;
          }
          .v-selection-control__input {
            width: 14px;
            height: 14px;

            .v-icon {
              @include fluidFont(14, 14, 1);
            }
            .v-switch__thumb {
              width: 11px;
              height: 12px;
              background-color: rgba($white, 1) !important;
              left: 5px;
              right: auto;
            }
          }
        }
      }

      .v-selection-control--dirty {
        .v-selection-control__wrapper {
          .v-selection-control__input {
            .v-switch__thumb {
              left: -4px;
            }
          }
        }
      }
    }
  }
}

.layers__list {
  .v-select {
    --v-input-control-height: auto;
    :deep(.v-input__control) {
      .v-field {
        padding: 8px 12px;
        border: 1px solid rgba($blueDark, 0.2);
        align-items: center;
        .v-field__field {
          .v-field__input {
            padding: 0;
            min-height: auto;
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.12px;
            top: -5px;
            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.12px;
            }
            .v-select__selection {
              .v-select__selection-text {
                display: inline-block;
                width: 100%;
                max-width: 100%;
                position: absolute;
                top: -3px;
              }
            }
          }
        }
        .v-field__outline {
          display: none;
        }
        .v-field__append-inner {
          .v-icon {
            @include fluidFont(16, 16, 1);
          }
        }
      }
    }
  }
}
.toggle-button.disabled {
  background-color: rgba($white, 1);
}
.toggle-button.disabled.active {
  background-color: rgba($orange, 1);
  .v-btn__content {
    .v-icon {
      img {
        filter: invert(100%) sepia(60%) saturate(7228%) hue-rotate(179deg)
          brightness(124%) contrast(115%);
      }
    }
    span {
      color: rgba($white, 1);
    }
  }
}
.bg__overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($blueDark, 0.3);
  z-index: 11;
}
.custom_layer__wrapper {
  .custom_data_layers {
    z-index: 1;
    border-radius: 8px;
  }
}
.slider__wrapper {
  padding: 10px;
  background-color: rgba($white, 1);
  border-radius: 8px;
  margin-top: 10px;
  .data__slider {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
    margin: 0;
    :deep(.v-input__prepend) {
      margin-inline-end: 0;
      width: 25px;
      .v-label {
        margin: 0;
        @include fluidFont(9, 9, 1.2);
        font-weight: 400;
        color: rgba($blueDark, 1);
        letter-spacing: 0.12px;
      }
    }
    :deep(.v-input__control) {
      width: 100%;
      max-width: 100%;
      .v-slider__container {
        .v-slider-thumb {
          .v-slider-thumb__surface {
            background-color: rgba($orange, 1);
            border-radius: 2px;
            width: 11px;
            height: 11px;
            box-shadow: none;
          }
        }
        .v-slider-track {
          .v-slider-track__background {
            background-color: rgba($blueDark, 0.12) !important;
            height: 3px !important;
          }
          .v-slider-track__fill {
            background-color: rgba($blueDark, 0.12) !important;
            height: 3px !important;
          }
        }
      }
    }
  }
}
.custom__select {
  position: relative;
  .selection__text {
    border: 1px solid rgba($blueDark, 0.5);
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      @include fluidFont(12, 12, 1);
      color: rgba($blueDark, 1);
      letter-spacing: 0.25px;
      font-weight: 400;
    }
  }
  .open {
    .v-icon {
      transform: rotate(180deg);
    }
  }
  .options {
    position: absolute;
    width: 100%;
    border: 1px solid rgba($blueDark, 0.5);
    border-radius: 4px;
    top: 40px;
    background-color: #fff;
    z-index: 1;
    ul {
      max-height: 200px;
      overflow: auto;
      li {
        padding: 8px 16px;
        @include fluidFont(12, 12, 1);
        color: rgba($blueDark, 1);
        letter-spacing: 0.25px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}
.layer__wrapper_full {
  left: 50%;
}
.sidebar-content {
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba($blueDark, 1);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba($blueDark, 1);
  }
}

.panel-progress-bar {
  padding: 10px;

  .panel-control-list {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  :deep(.v-slider.v-input--horizontal) {
    margin: 8px 14px;
  }

  .button {
    min-height: 1px;
    height: auto;
    padding: 4px;
    font-size: 10px;
    text-transform: none;
    border-radius: 4px;
    margin-left: auto;
    margin-top: 10px;
  }
}
.properties__info__list {
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border: 1px solid rgba($bordercolor, 1);
  border-radius: 8px;
  background-color: rgba($backgroundcolor, 1);
  .search_field {
    background-color: rgba($white, 1);
  }
  .properties__info__list_item {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // height: calc(100dvh - 180px);
    // overflow: auto;
    .properties_wrapper_info {
      width: 100%;
      max-width: 100%;
      background-color: rgba($white, 1);
      border: 1px solid rgba($bordercolor, 1);
      padding: 10px 10px 10px 15px;
      border-radius: 7px;
      text-align: left;
      // display: flex;
      // gap: 16px;
      position: relative;
      .properties_wrapper {
        .prop_name {
          display: flex;
          gap: 5px;
          align-items: center;
          p {
            width: 16px;
            height: 16px;
            background-color: rgba($blueDark, 1);
            border-radius: 100%;
            color: rgba($white, 1);
            font-size: 10px;
            line-height: normal;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          h6 {
            width: calc(100% - 20px);
            max-width: 100%;
            @include fluidFont(12, 12, 1);
            font-weight: 500;
            color: rgba($blueDark, 1);
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .property__type__wrapper {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .location {
            display: flex;
            .v-icon {
              font-size: 12px;
              color: rgba($blueDark, 0.5);
            }
          }
        }
      }
    }
  }
}
</style>
