<template>
  <div class="tw-py-6 header-text">
    <h1
      class="tw-text-left tw-text-[22px] tw-font-normal tw-leading-6 tw-tracking-[0.15px] tw-text-[#0C0F4A]"
    >
      Estimate Overview
    </h1>
  </div>
  <v-tabs class="tab-list" align-tabs="center" stacked v-model="currentTab">
    <v-tab v-for="(item, index) in tabList" :key="index" :value="item.title">
      <v-icon>{{ item.icon }}</v-icon>
      {{ item.title }}
    </v-tab>
  </v-tabs>
  <main class="estimate-received-preview">
    <div class="estimate-received-preview__content">
      <div class="left_block">
        <div class="left_block__header">
          <div class="property__icon">
            <img
              v-if="contractorWorkStationUrl"
              :src="contractorWorkStationUrl"
              alt=""
            />
            <UserProfileLogo v-else :userName="contractorDetails?.name" />
            <img
              src="../../../assets/icons/verified.svg"
              alt=""
              class="verfied_icon"
            />
          </div>
          <div class="left_block__header__content">
            <h6>{{ contractorDetails?.name }}</h6>
            <p>{{ contractorDetails?.user?.userBusinessDetails?.type }}</p>
            <v-rating
              class="rating_wrapper"
              active-color="orange"
              color="rgba(12, 15, 74, 0.26)"
              empty-icon="mdi-star"
              readonly
              :model-value="getAverageOfAllProjectReview"
            ></v-rating>
            <div class="chips_wrapper">
              <span class="estimate_chip">{{ estimateBadge }}</span>
              <v-icon icon="mdi-information"></v-icon>
            </div>
          </div>
        </div>
        <div
          class="tw-pl-3 tw-pb-2 tw-box-border tw-flex tw-flex-col tw-gap-4 tw-w-full tw-max-w-full lg:tw-w-full lg:tw-pr-0 lg:tw-pl-0"
        >
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div class="tw-w-full tw-flex tw-items-center tw-justify-start">
              <Text variant="h3">{{ details?.project?.name }}</Text>
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-justify-start tw-gap-8"
            >
              <!-- <CommercialChip /> -->
              <LabelWithValue
                :justifyStart="true"
                :noBg="true"
                :data="details?.project?.refId"
                label="Project ID:"
              />
            </div>
          </div>

          <v-divider></v-divider>

          <EstimateReceivedSummaryCard
            :selectedEstimate="details"
            :isClientRaiseEstimate="true"
            class="estimate__received__card"
            @onToggleChart="onToggleChart"
            :isShowGanttChart="currentTab === 'Schedule'"
          />
        </div>
      </div>
      <div class="right_block">
        <div class="right_block__top">
          <v-btn
            class="button button-purple-border"
            :disabled="isProjectEstimateIdPresent"
            @click="onClickAcceptEstimate"
            width="100%"
            >Accept Estimate</v-btn
          >
          <v-btn
            class="button button-purple"
            :disabled="isProjectEstimateIdPresent"
            @click="onClickRejectEstimate"
            width="100%"
            >Raise Questions</v-btn
          >
        </div>

        <EstimateReceivedSummaryDetails
          :selectedEstimate="details"
          class="estimate_received__data"
        />
      </div>
    </div>
  </main>

  <main class="estimate-received-previ tw-w-full tw-flex tw-flex-col tw-gap-4">
    <div
      class="tw-w-full tw-flex tw-gap-3 tw-items-center tw-rounded-[10px] tw-px-3 tw-py-1 tw-box-border tw-bg-[#f454540d] tw-border-solid tw-border tw-border-[rgba(255,165,0,1)]"
      v-if="isProjectEstimateIdPresent"
    >
      <ErrorRedIcon class="tw-w-[30px] tw-h-[30px]" />
      <p
        class="tw-text-sm tw-border-[rgba(255,165,0,1)] tw-text-left tw-bg-[rgba(255,165,0,0.2) tw-w-[calc(100%-50px)] tw-max-w-full"
      >
        <span v-if="!isIdMatching">
          An estimate for the project "<strong>{{ projectName }}</strong
          >" has already been submitted by the
          <strong>{{ workStationName }}</strong> workstation.

          <a href="#" @click.prevent="goToDetailPage"> View Esimate</a>
        </span>

        <span v-else>
          An estimate for the project "<strong>{{ projectName }}</strong
          >" has already been accepted.
        </span>
      </p>
    </div>

    <section
      class="estimate-received-preview__container tw-flex tw-items-start tw-justify-between tw-py-4 tw-box-border tw-border-[0] tw-border-b-[1px] tw-border-[#C5D2F2] tw-border-solid md:!tw-pt-0"
    ></section>
    <section
      class="tw-gap-10 tw-flex xl:tw-gap-5 lg:tw-flex-col md:!tw-pb-28"
      v-if="details"
    >
      <div
        class="tw-flex tw-flex-col tw-gap-4 tw-w-[340px] tw-max-w-full lg:tw-w-full"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
          <SubContractorList
            v-if="
              details?.inviteContractors && details?.inviteContractors?.length
            "
            class="desktop__wrapper lg:tw-hidden"
            :subContractorDetailsList="details?.inviteContractors"
          />
        </div>
      </div>
      <div class="lg:tw-flex tw-items-center tw-gap-4 tw-hidden sm:tw-flex-col">
        <Button
          class="!tw-w-[200px] sm:!tw-w-full"
          variant="secondary"
          label="Accept Estimate"
          @click="onClickAcceptEstimate"
        />
        <Button
          class="!tw-w-[200px] sm:!tw-w-full"
          label="Raise Questions"
          @click="onClickRaiseQuestion"
        />
      </div>

      <SubContractorList
        class="mobile__wrapper tw-hidden lg:tw-block lg:tw-text-left"
        :subContractorDetailsList="details?.inviteContractors"
      />
    </section>

    <AcceptProjectEstimateModal
      v-if="isAcceptEstimate"
      :isLoading="acceptEstimateLoader"
      @on-save="onConfirmEstimate"
      @on-close="onCloseAcceptEstimate"
    />

    <ServiceAgreementModal
      v-if="isOpenServiceAgreementModal"
      :project="projectCreatorDetails"
      :projectEstimate="projectEstimate"
      type="property"
      @on-confirm="onConfirmServiceAgreement"
      @on-close="onCloseServiceAgreementModal"
    />
    <RejectProjectEstimateModal
      v-if="isRejectEstimate"
      :projectId="details?.project?.id"
      :projectEstimateId="details?.id"
      @on-confirm="onConfirmRejectEstimate"
      @on-cancel="onCloseRejectEstimate"
    />
    <EstimateDetailsModal
      v-if="isOpenEstimateDetailsModal"
      @on-close="onCloseEstimateDetailsModal"
      :projectEstimateId="details?.id"
    />

    <EstimateApproveModal
      v-if="isEstimateApproved"
      :estimateData="details"
      @on-back-project="onClickBackProject"
      @on-deposit="onClickBackProject"
    />

    <v-dialog
      v-if="isEstimateLoading"
      width="500px"
      v-model="loading"
      class="modal_box action-modal"
      persistent
    >
      <div class="modal_wrapper">
        <main class="main">
          <div class="loader__content">
            <p class="switching-text">{{ switchingText }}</p>
            <div class="loader__icon">
              <img
                :src="require('@/assets/icons/estimate-loader.svg')"
                alt=""
              />
            </div>

            <v-stepper
              class="onboard-stepper"
              v-model="currentSelectedTab.name"
            >
              <v-stepper-header class="onboard-stepper__header">
                <v-stepper-item
                  class="onboard-stepper__item"
                  v-for="(step, index) in tabListItems"
                  :key="index"
                  :value="step.name"
                ></v-stepper-item>
              </v-stepper-header>
            </v-stepper>
          </div>
        </main>
      </div>
    </v-dialog>
  </main>
</template>
<script lang="ts" setup>
import { reactive, computed, getCurrentInstance } from "vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import SubContractorList from "@/modules/project/components/common/SubContractorList.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import EstimateReceivedSummaryCard from "@/modules/project/components/common/EstimateReceivedSummaryCard.vue";
import EstimateReceivedSummaryDetails from "@/modules/project/components/common/EstimateReceivedSummaryDetails.vue";
import { useRouter } from "vue-router";

import { ref } from "vue";
import ServiceAgreementModal from "@/modules/jobs/components/modal/ServiceAgreementModal.vue";
import AcceptProjectEstimateModal from "@/modules/project/modal/AcceptProjectEstimateModal.vue";
import RejectProjectEstimateModal from "@/modules/project/modal/RejectProjectEstimateModal.vue";

import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import projectEstimationService from "@/core/services/project-estimation.service";
import {
  AgreeTermsConsumerDisclosure,
  EstimateTypeForDeposit,
} from "@/core/enums/estimateEnum";
import EstimateDetailsModal from "@/modules/project/modal/EstimateDetailsModal.vue";

import EstimateApproveModal from "@/modules/project/modal/EstimateApproveModal.vue";
import { PROJECT_STORE } from "@/store/modules/project";

import {
  getCombineSameProjectReview,
  setDirectStoragePathToImage,
} from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import ErrorRedIcon from "@/core/components/icons/ErrorRedIcon.vue";
import { useRoute } from "vue-router";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { reviewRatingState } from "@/core/models/project-rating";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
const props = defineProps({
  details: {
    type: Object,
  },
});
const emits = defineEmits(["start-registration"]);

const router = useRouter();
const route = useRoute();
const store = useStore();
const loading = ref(false);
const isAcceptEstimate = ref(false);
const isRejectEstimate = ref(false);
const isEstimateLoading = ref(false);
const isOpenServiceAgreementModal = ref(false);
const isOpenEstimateDetailsModal = ref(false);
const isEstimateApproved = ref(false);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const switchingText = ref("Loading...");
const currentSelectedTab = ref({ name: "tab1" });
const acceptEstimateLoader = ref(false);
const tabListItems = ref([
  { name: "tab1" },
  { name: "tab2" },
  { name: "tab3" },
]);
const currentTab = ref("Job Details");
const tabList = [
  {
    title: "Job Details",
    icon: "mdi-magnify",
  },

  {
    title: "Schedule",
    icon: "mdi-file-document",
  },
];

const onToggleChart = (isShowChart) => {
  if (isShowChart) {
    currentTab.value = "Schedule";
  } else {
    currentTab.value = "Job Details";
  }
};
const projectName = computed(() => props.details?.project?.name || "");
const workStationName = computed(
  () =>
    props.details?.project?.projectEstimateAcceptDetails?.workStation?.name ||
    ""
);
const projectEstimate = computed(() => {
  return {
    ...props.details,
    projectEstimateCreator: {
      ...props.details?.workStation?.user,
      workStation: props.details?.workStation,
    },
    projectStages: props.details?.estimationPhase,
  };
});
const internalInstance = getCurrentInstance();

const projectDetail = computed(() => {
  return {
    ...props.details?.project,
    projectCreator: {
      firstName: user.value?.firstName,
      lastName: user.value?.lastName,
    },
  };
});

const contractorDetails = computed(() => {
  return props.details?.workStation;
});

const isProjectEstimateIdPresent = computed(
  () => !!props.details?.project?.projectEstimateId
);
const isIdMatching = computed(() => {
  const routeId = route.params.estimateId;
  const projectEstimateId = props.details?.project?.projectEstimateId;
  return routeId == projectEstimateId;
});

const onClickBackEstimateButton = () => {
  router.push(`/project-details/${props?.details?.project?.refId}`);
};

const onClickRejectEstimate = () => {
  //   isRejectEstimate.value = true;
  emits("start-registration");
};

const onClickAcceptEstimate = () => {
  //   isAcceptEstimate.value = true;
  emits("start-registration");
};
const onClickRaiseQuestion = () => {
  //   isAcceptEstimate.value = true;
  emits("start-registration");
};

const onConfirmEstimate = async () => {
  isAcceptEstimate.value = false;

  isOpenServiceAgreementModal.value = true;
};

const onCloseRejectEstimate = () => {
  isRejectEstimate.value = false;
};

const onCloseAcceptEstimate = () => {
  isAcceptEstimate.value = false;
};

const onClickServiceAgreementModal = () => {
  isOpenServiceAgreementModal.value = true;
};
const onConfirmRejectEstimate = async (payload: any) => {
  try {
    const data = await projectEstimationService.rejectEstimate(
      props.details?.id,
      payload
    );
  } catch (error) {
    console.log("error", error);
  } finally {
    isRejectEstimate.value = false;
    router.push(`/project-details/${props?.details?.project?.refId}`);
  }
};
const onConfirmServiceAgreement = async () => {
  isOpenServiceAgreementModal.value = false;
  isOpenEstimateDetailsModal.value = true;
};

const onCloseServiceAgreementModal = () => {
  isOpenServiceAgreementModal.value = false;
};

const onCloseEstimateDetailsModal = async () => {
  isOpenEstimateDetailsModal.value = false;
  isEstimateLoading.value = true;

  try {
    loading.value = true;
    setTimeout(() => {
      switchingText.value = "Loading...";
      currentSelectedTab.value = tabListItems.value[0];
    }, 1000);
    const payload = { status: AgreeTermsConsumerDisclosure.ACCEPTED };
    (await projectEstimationService.submitStatusWithEstimateUpdate(
      props.details?.project?.id,
      props.details?.id,
      payload
    )) as any;

    isEstimateApproved.value = true;
  } catch (error) {
    console.log("error ", error);
    isEstimateApproved.value = false;
    router.go(-1);
  } finally {
    setTimeout(() => {
      switchingText.value = "Loading...";
      currentSelectedTab.value = tabListItems.value[1];
    }, 2000);

    setTimeout(() => {
      switchingText.value = "Loading...";
      currentSelectedTab.value = tabListItems.value[2];
      loading.value = false;
    }, 5000);
  }
};
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const projectCreatorDetails = computed(() => {
  return {
    ...projectDetail.value,
    userWorkstation: activeUserWorkstation.value,
  };
});
const getTradeEstimateDetails = computed(
  () => store.getters[`${TRADE_ESTIMATE_STORE}/getTradeEstimateDetails`]
);
const onClickBackProject = () => {
  // project refId
  isEstimateApproved.value = false;
  router.push(`/project-details/${props?.details?.project?.refId}`);

  store.dispatch(
    `${PROJECT_STORE}/setProjectRedirectTabName`,
    "project-overview"
  );
};
const goToDetailPage = () => {
  router.push(`/trade-estimates/${props.details?.project?.projectEstimateId}`);
};
const getAllProjectReviewList = computed<
  reviewRatingState["allProjectReviewList"]
>(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

const combineSameProjectReview = computed(() =>
  getCombineSameProjectReview(getAllProjectReviewList.value)
);
const getAverageOfAllProjectReview = computed(() => {
  const totalAvg = combineSameProjectReview.value.reduce(
    (totalRating: number, review: any) => {
      totalRating += review.totalAvg || 0;
      return totalRating;
    },
    0
  );
  return totalAvg
    ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) / 100
    : totalAvg;
});
const estimateBadge = computed(() => {
  if (props.details?.depositType === EstimateTypeForDeposit.Initial_Estimate)
    return "Initial Estimate";
  else if (
    props.details?.depositType === EstimateTypeForDeposit.Fixed_Cost_Estimation
  )
    return "Fixed Cost Estimation";
  else if (
    props.details?.depositType ===
    EstimateTypeForDeposit.Variable_Cost_Estimation
  )
    return "Variable Cost Estimation";
  else return "";
});

const contractorWorkStationUrl = computed(() => {
  return setDirectStoragePathToImage(contractorDetails.value?.profileImage);
});
</script>
<style lang="scss" scoped>
// .custom_selector {
//   :deep(.v-input__control) {
//     .v-field {
//       background-color: transparent;
//       border: 1px solid rgba($buttonText, 0.4);
//       border-radius: 8px;
//       padding: 0;
//       .v-field__field {
//         .v-field__input {
//           min-height: 40px;
//           padding: 12px 16px;
//           @include fluidFont(12, 12, 1.2);
//           font-weight: 400;
//           color: rgba($blueDark, 1);
//           letter-spacing: 0.4px;
//           opacity: 1;
//           .v-select__selection {
//             .v-select__selection-text {
//               @include fluidFont(12, 12, 1.2);
//               font-weight: 400;
//               color: rgba($blueDark, 1);
//               letter-spacing: 0.4px;
//             }
//           }
//           input {
//             @include fluidFont(12, 12, 1.2);
//             font-weight: 400;
//             color: rgba($blueDark, 1);
//             letter-spacing: 0.4px;
//             &::placeholder {
//               @include fluidFont(12, 12, 1.2);
//               font-weight: 400;
//               color: rgba($blueDark, 1);
//               letter-spacing: 0.4px;
//               opacity: 1;
//             }
//           }
//         }
//       }
//       .v-field__outline {
//         display: none;
//       }
//     }
//   }
// }

// .date_selector {
//   :deep(.dp__input_wrap) {
//     .dp__input {
//       min-height: 40px;
//       @include fluidFont(12, 12, 1.2);
//       font-weight: 400;
//       color: rgba($blueDark, 1);
//       letter-spacing: 0.4px;
//       padding: 12px 30px;
//       border-radius: 8px;
//     }
//     &::placeholder {
//       @include fluidFont(12, 12, 1.2);
//       font-weight: 400;
//       color: rgba($blueDark, 1);
//       letter-spacing: 0.4px;
//       opacity: 1;
//     }
//   }
// }

// .switching-text {
//   text-align: center;
//   @include fluidFont(18, 18, 1.3);
//   font-weight: 400;
//   color: rgba($PrimaryBlue, 1);
//   letter-spacing: 0.4px;
// }

// .progress-bar {
//   background-color: #ddd;
//   height: 20px;
//   border-radius: 10px;
//   overflow: hidden;
// }

// .progress-bar-fill {
//   background-color: #4caf50;
//   height: 100%;
//   width: 0;
//   transition: width 5s linear;
// }

// .action-modal .modal_wrapper {
//   width: 100%;
//   border-radius: 30px;
// }

// .action-modal .modal_wrapper {
//   background: linear-gradient(0deg, #ffffff, #ffffff),
//     linear-gradient(
//       111.5deg,
//       rgba(245, 250, 255, 0.3) 0%,
//       rgba(211, 233, 255, 0.3) 100%
//     ) !important;
// }
// .modal_wrapper {
//   .main {
//     padding: 32px;
//     min-height: 330px;
//     .loader__content {
//       display: flex;
//       justify-content: space-between;
//       flex-direction: column;
//       min-height: 250px;
//       .loader__icon {
//         width: 60px;
//         height: 60px;
//         margin: 0 auto;
//         img {
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }
//       }
//     }
//   }
// }
.estimate-received-preview {
  .estimate-received-preview__content {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    .left_block {
      width: calc(100% - 350px);
      max-width: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: flex-start;
      .left_block__header {
        border: 1px solid rgba($bordercolor, 1);
        border-radius: 8px;
        padding: 16px;
        background-color: rgba($backgroundcolor, 1);
        display: flex;
        gap: 16px;
        align-items: flex-start;
        .property__icon {
          width: 107px;
          max-width: 100%;
          height: 107px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .verfied_icon {
            width: 20px !important;
            height: 20px !important;
            background-color: rgba($backgroundcolor, 1);
            position: absolute;
            right: 0;
            top: 0;
            padding: 3px;
            border-radius: 2px;
            border: 1px solid rgba($bordercolor, 1);
            z-index: 1;
          }
        }
        .left_block__header__content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
          h6 {
            @include fluidFont(23, 23, 28.87px);
            color: rgba($blueDark, 1);
            font-weight: 700;
          }
          p {
            @include fluidFont(12, 12, 15.06px);
            color: rgba($blueDark, 1);
            font-weight: 400;
          }
          .rating_wrapper {
            padding: 0;
            @include fluidFont(16, 14, 20px);
            font-weight: 700;
            letter-spacing: 0.12px;
            display: flex;
            align-items: center;
            gap: 8px;
            justify-self: flex-start;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            :deep(.v-rating__wrapper) {
              .v-rating__item {
                label {
                  .v-btn {
                    height: auto;
                    width: auto;
                  }
                }
              }
            }
          }
          .chips_wrapper {
            .estimate_chip {
              padding: 4px 18px;
              border-radius: 4px;
              background-color: rgba(193, 159, 95, 1);
              color: rgba($white, 1);
              text-transform: uppercase;
              @include fluidFont(12, 12, 16px);
              font-weight: 700;
              letter-spacing: 1.25px;
            }
            .v-icon {
              @include fluidFont(12, 12, 16px);
              color: rgba($blueDark, 0.5);
              margin-left: 6px;
            }
          }
        }
      }
      .estimate__received__card {
        background-color: rgba($backgroundcolor, 1);
        border: 1px solid rgba($bordercolor, 1);
        border-radius: 8px;
        main {
          .estimate__content {
          }
        }
      }
    }
    .right_block {
      width: 350px;
      max-width: 100%;
      .right_block__top {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
        .button {
          width: 100%;
          max-width: 100%;
        }
      }
      .estimate_received__data {
        background-color: rgba($backgroundcolor, 1);
        border: 1px solid rgba($bordercolor, 1);
        border-radius: 8px;
      }
    }
  }
}
.header-text {
  border-bottom: 1px solid rgba($blueDark, 0.1);
  margin-bottom: 8px;
}
</style>
