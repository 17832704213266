import { UserState, User } from "@/core/models/user";

export default {
  setUser(state: UserState, payload: User) {
    state.user = payload;
  },
  setUserStripeCustomer(state: UserState, payload: any) {
    state.userStripeCustomer = payload;
  },
  setImage(state: UserState, payload: any) {
    state.userImage = payload;
  },
  setRole(state: UserState, payload: any) {
    state.userRole = payload;
  },
  setAccountStatuses(state: UserState, payload: any) {
    state.accountStatuses = payload;
  },
  setIsProfileVerifiable(state: UserState, value: any) {
    state.isProfileVerifiable = value;
  },
  setInsurances: (state: UserState, insurances: Array<any>) => {
    state.userInsurances = insurances;
  },
  setNewUserInsurance: (state: UserState, insurance: any) => {
    state.userInsurances = [...state.userInsurances, insurance];
  },
  updateUserInsurance: (state: UserState, userInsurance: any) => {
    const findIndex = state.userInsurances.findIndex(
      (insurance) => insurance.id === userInsurance.id
    );
    if (findIndex !== -1) {
      state.userInsurances.splice(findIndex, 1, userInsurance);
    }
  },
  removeUserInsurance: (state: UserState, userInsuranceId: number) => {
    if (userInsuranceId) {
      state.userInsurances = state.userInsurances.filter(
        (insurance) => insurance.id !== userInsuranceId
      );
    }
  },
  setCertificates: (state: UserState, certificates: any) => {
    state.userCertificates = certificates;
  },

  updateCertificate: (state: UserState, userCertificate: any) => {
    const findIndex = state.userCertificates.findIndex(
      (certificate) => certificate.id === userCertificate.id
    );
    if (findIndex !== -1) {
      state.userCertificates.splice(findIndex, 1, userCertificate);
    }
  },

  setNewUserCertificate: (state: UserState, certificate: any) => {
    state.userCertificates = [...state.userCertificates, certificate];
  },

  removeUserCertificates: (state: UserState, userCertificateId: number) => {
    if (userCertificateId) {
      state.userCertificates = state.userCertificates.filter(
        (certificate) => certificate.id !== userCertificateId
      );
    }
  },
  setUserSpecialisms: (state: UserState, specialisms: any) => {
    state.userSpecialisms = specialisms;
  },
  setSpecialismsCategory: (state: UserState, specialisms: any) => {
    state.specialismCategory = specialisms;
  },
  resetStore: (state: UserState) => {
    state.user = null;
    state.userStripeCustomer = [];
    state.userImage = null;
    state.accountStatuses = null;
    state.isProfileVerifiable = false;
    state.userInsurances = [];
    state.userRole = null;
    state.userCertificates = [];
    state.userSpecialisms = [];
    state.specialismCategory = null;
    state.userNotifications = [];
  },

  setUserNotifications: (state: UserState, notification: any) => {
    state.userNotifications.unshift(notification);
  },
  resetUserNotifications: (state: UserState, notification: any) => {
    state.userNotifications = [];
  },
  setUserWorkStationImage(state: UserState, payload: any) {
    state.userWorkStationImage = payload;
  },
  setAllSpecialism(state: UserState, payload: any) {
    state.allSpecialismList = payload;
  },
  removeNotification: (state: UserState, notificationId: number) => {
    state.userNotifications = state.userNotifications.filter(
      (notification) => notification.id !== notificationId
    );
  },
  setIsRegisteredForCourse: (state: any, payload: any) => {
    state.isRegisteredForCourse = payload;
  },
};
