<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="912px"
    max-width="100%"
    height="auto"
    class="property_owner_benefits_modal"
  >
    <!-- :className="[ 'property_owner_benefits_modal', 'md:!tw-h-auto
    md:!tw-overflow-[unset]', ]" -->
    <template v-slot:body>
      <div class="header md:!tw-right-[-13px]">
        <v-btn icon="mdi-close" @click="onClickCloseButton"></v-btn>
      </div>
      <v-carousel
        height="auto"
        class="md:!tw-h-auto"
        style="overflow: initial"
        hide-delimiter-background
        :show-arrows="false"
        :continuous="false"
        v-model="model"
      >
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <v-sheet>
            <div class="md:!tw-flex md:!tw-flex-col md:!tw-gap-[.5rem]">
              <h4 class="md:!tw-mb-0 md:!tw-text-[24px]">Why iknowa?</h4>
              <div
                class="carousel__img !tw-mb-0 tw-relative !tw-w-full tw-overflow-hidden md:!tw-min-h-[200px] rmd:!tw-h-[400px] rmd:!tw-m-0 rmd:!tw-pt-8 rmd:! rmd:!tw-mb-4"
              >
                <img
                  width="90"
                  class="tw-relative tw-z-10 !tw-object-contain"
                  :src="item.src"
                />
                <img
                  class="tw-w-full !tw-h-auto tw-absolute tw-z-0 tw-top-[-16px] tw-right-0 tw-object-contain rmd:!tw-top-[-27px]"
                  :src="require('@/assets/images/bg-property-1.png')"
                />
              </div>
              <div
                class="property_owner_benefits_modal_title md:!tw-mb-0 md:!tw-text-[18px]"
              >
                {{ item?.title }}
              </div>
              <div
                class="property_owner_benefits_modal_text md:!tw-mb-0 md:!tw-min-h-[unset]"
              >
                {{ item?.description }}
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <div class="carousel-controls">
        <v-btn
          variant="elevated"
          class="button button-orange"
          @click="prevSlide"
          :disabled="model === 0"
        >
          Previous
        </v-btn>
        <v-btn
          variant="elevated"
          class="button button-orange"
          @click="handleNextClick"
        >
          {{
            model === items.length - 1
              ? activeUserWorkstation.name === "Default"
                ? "Go to Control Center"
                : "Go to Dashboard"
              : "Next"
          }}
        </v-btn>
      </div>
    </template>
  </CommonDialog>
</template>

<script lang="ts">
import CommonDialog from "@/core/components/CommonDialog.vue";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    CommonDialog,
  },
  setup(props: any, ctx: any) {
    const store = useStore();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);

    const userName = computed(
      () =>
        `${user.value?.firstName || "user"} ${user.value?.lastName || "name"}`
    );
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const model = ref(0);

    const items = ref([
      {
        src: require("@/assets/images/property-1.png"),
        title: "Legal Protection with Ombudsman Services",
        description:
          "An embedded Ombudsman in property projects ensures fair dispute resolution, protecting property owners' rights and interests.",
      },
      {
        src: require("@/assets/images/trade-preview-2.png"),
        title: "Simplified Project Management and Communication",
        description:
          "iknowa provides collaboration tools that improve communication among property owners, contractors, and stakeholders, ensuring smooth project execution and fewer delays.",
      },
      {
        src: require("@/assets/images/property-3.png"),
        title: "Enhanced Investment Security with Verified Experts",
        description:
          "Partnering with iknowa connects property owners to a network of verified, experienced contractors, reducing construction risks and enhancing long-term investment value.",
      },
      {
        src: require("@/assets/images/property-4.png"),
        title: "Access to Reliable Property Data",
        description:
          "Property owners can make informed decisions with access to comprehensive data, maximizing value through strategic improvements.",
      },
    ]);

    const handleNextClick = () => {
      if (model.value === items.value.length - 1) {
        ctx.emit("onClickClosePreviewModalButton");
      } else {
        model.value++;
      }
    };
    const prevSlide = () => {
      if (model.value > 0) {
        model.value--;
      }
    };

    const onClickCloseButton = () => {
      ctx.emit("onClickClosePreviewModalButton");
    };

    return {
      userName,
      onClickCloseButton,
      items,
      model,
      handleNextClick,
      prevSlide,
      activeUserWorkstation,
    };
  },
};
</script>
