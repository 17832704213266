<template>
  <GeneralDialog
    class="dispute-evidence-modal"
    @on-close="onClickBackButton"
    :showBackButton="false"
    maxWidth="100%"
    :headerTitle="false"
    width="588px"
    :isTitleStrong="true"
    borderRadius="20px"
  >
    <template #body>
      <div class="modal-overlay">
        <div class="modal-container">
          <v-form ref="formRef" @submit.prevent :rules="validation">
            <div class="modal-header">
              <div class="title">
                <h2 class="modal-title">Make an Offer</h2>
              </div>
              <p>
                You may propose an offer to the counter party for the dispute
                resolution.
              </p>
            </div>
            <div class="modal-body">
              <div class="modal-statement modal-content">
                <label>Offer </label>
                <v-textarea
                  class="input_field"
                  placeholder="Type your offer here"
                  variant="solo"
                  rows="5"
                  bg-color="#f8fbfd"
                  autogrow
                  v-model="offerDescription"
                  :rules="validation.offer"
                />
              </div>
              <!-- evidence image upload -->
              <div class="modal-evidence-upload modal-content">
                <label>Upload Documents</label>
              </div>
              <div class="upload__section md:!tw-h-[100px]">
                <div
                  class="uploade-wrapper custom-file-upload !tw-h-[250px] md:!tw-h-full"
                >
                  <v-file-input
                    class="upload_file"
                    label="Drag and drop images & files, or Browse"
                    variant="solo-filled"
                    v-model="selectedDocuments"
                    :rules="validation.attachments"
                    :class="{
                      'added-img':
                        selectedDocuments && selectedDocuments?.length,
                    }"
                    @change="onFileChange"
                  >
                    <v-icon
                      icon="mdi mdi-file-image-box"
                      class="label-img"
                    ></v-icon>
                  </v-file-input>
                </div>
              </div>
              <div class="attachment-list">
                <div
                  v-for="(file, index) in selectedDocuments"
                  :key="index"
                  class="attachment-item"
                >
                  <span>{{ file.name }}</span>
                  <v-icon
                    class="remove-icon cursor-pointer"
                    icon="mdi-close"
                    @click="removeAttachment(index)"
                  ></v-icon>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <v-btn
                class="button button-purple-border"
                @click="onClickBackButton"
                :disabled="isUploadImg"
              >
                Cancel
              </v-btn>
              <v-btn
                class="button button-purple"
                @click="onSubmit"
                :loading="isUploadImg"
              >
                Submit Offer
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { getCurrentInstance } from "@vue/runtime-core";
import { displayToastMessage } from "@/core/utils/common";

const emits = defineEmits(["on-close", "on-submit"]);

const internalInstance = getCurrentInstance();
const formRef = ref<any>(null);
const isUploadImg = ref<boolean>(false);

const validation = ref({
  offer: [(v: string) => !!v || "Enter your offer required"],
  attachments: [
    (v: any[]) => v.length > 0 || "At least one attachment is required",
  ],
});

const offerDescription = ref<string>("");

const selectedDocuments = ref<any[]>([]);

const onFileChange = (event: any) => {
  const files = event.target.files;
  if (files && files.length > 0) {
    Array.from(files).forEach((file: any) => {
      if (!selectedDocuments.value.some((doc) => doc.name === file.name)) {
        selectedDocuments.value.push(file);
      }
    });
  }

  event.target.value = "";
};

const onSubmit = async () => {
  const isFormValid = await formRef.value.validate();

  if (!isFormValid.valid) return;

  try {
    isUploadImg.value = true;
    const attachedFile = selectedDocuments.value[0];
    const userMessageContent = offerDescription.value;
    emits("on-submit", { attachedFile, userMessageContent });
  } catch (error) {
    displayToastMessage(
      internalInstance,
      "Failed to upload one or more attachments. Please try again.",
      "error"
    );
  } finally {
    isUploadImg.value = false;
    selectedDocuments.value = [];
    offerDescription.value = "";
  }
};

const removeAttachment = (index: number) => {
  selectedDocuments.value.splice(index, 1);
};

const onClickBackButton = () => {
  return emits("on-close");
};
</script>

<style lang="scss" scoped>
.dispute-evidence-modal {
  .v-overlay__content {
    .general-dialog {
      padding: 24px !important;
      .general-dialog__header {
        display: none !important;
      }
      .modal-overlay {
        .modal-container {
          .modal-header {
            // padding-bottom: 24px;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .modal-title {
                @include fluidFont(24, 24, 30px);
                font-weight: 600;
                color: rgba($blueDark, 1);
              }
            }
            p {
              @include fluidFont(12, 12, 15.06px);
              font-weight: 400;
              color: rgba($blueDark, 0.5);
            }
          }
          .modal-body {
            padding: 0;
            border-bottom: 1px dashed rgba(134, 135, 165, 0.5);

            .modal-content {
              margin-top: 20px;
              label {
                @include fluidFont(16, 16, 24px);
                font-weight: 500;
                color: rgba($blueDark, 1);
              }
              .input_field {
                margin: 8px 0 0;
                :deep(.v-input__control) {
                  .v-field {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    // background-color: #f8fbfd;
                    box-shadow: none;
                    .v-field__field {
                      .v-field__input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        top: 0;
                        mask-image: unset;
                      }
                      textarea {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        @include fluidFont(12, 12, 1.2);
                        font-weight: 400;
                        color: rgba($blueDark, 1);
                        letter-spacing: 0.4px;
                        padding: 12px 16px;
                        mask-image: unset;
                      }
                    }
                    .v-field__outline {
                      display: none;
                    }
                  }
                }
              }
            }
            .dispute-terms-wrapper {
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                @include fluidFont(14, 14, 16px);
                font-weight: 500;
                color: rgba($blueDark, 1);
                letter-spacing: 0.4px;
                span {
                  color: rgba($orange, 1);
                }
              }
            }
            .signature-wrapper {
              .signature_field {
                // pointer-events: none;
                min-height: 42px;
              }
            }
          }
          .modal-footer {
            padding-top: 24px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            .button {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.attachment-list {
  margin-top: 1rem;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fbfd;
  border: 1px solid #dfe5ec;
  border-radius: 5px;
}
.remove-icon {
  color: #ff4d4f;
}

.custom-file-upload {
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff !important;
  border-radius: 6px !important;

  :deep(.upload_file) {
    background-color: #f8fbfd;
    border: 1px dashed #00000066;
    border-radius: 6px !important;

    .v-field {
      background-color: transparent !important;
      border-radius: 0 !important;
    }

    .v-field-label {
      margin: 0 !important;
      bottom: initial !important;
      font-weight: 400 !important;
      color: rgba($blueDark, 0.5) !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      z-index: -1;
      top: 50% !important;
      white-space: normal;
      text-align: center;
      transform: translate(-50%, -50%) !important;

      @media (max-width: 767px) {
        flex-direction: column;
        width: 70%;
        white-space: pre-wrap;
        text-align: center;
      }

      &::before {
        content: "";
        position: relative;
        display: inline-flex;
        left: 0;
        top: 0;
        width: 28px;
        height: 25px;
        background: url("@/assets/icons/file-upload.svg") no-repeat center /
          contain;
      }
    }

    .v-field__clearable {
      border-radius: 6px !important;
    }
  }

  .added-img {
    .v-field-label {
      display: none !important;
    }
  }

  .uploaded-img {
    width: 100% !important;
    border-radius: 6px !important;
  }

  .selectprop_dropdown {
    padding-right: 0;
  }
}
</style>
