import {
  VUE_APP_SILT_KYC_API_TOKEN,
  VUE_APP_SILT_KYB_COMPANY_APP_ID,
} from "@/config/index";
import $axios from "../utils/axios-api-config";
import axios from "axios";
import { userVerificationState } from "@/core/models/workstation";
import workstation from "@/store/modules/workstation";
import {
  RemoveSubCategoryDto,
  UpdateUserSkillsRequestDto,
} from "@/core/models/user";
export default {
  selectRole(userId: number, roleId: number, email: string) {
    return $axios.post("/users/select-role", { userId, roleId, email });
  },
  selectAccount(userId: number, roleId: number, email: string) {
    return $axios.post("/users/select-account", { userId, roleId, email });
  },
  uploadProfileImage(formData: any) {
    return $axios.post("/upload", formData);
  },
  uploadMultipleImage(formData: any) {
    return $axios.post("/upload/files", formData);
  },
  verifyPhoneNumber(contactNo: string) {
    return $axios.get(`/validate/phone-number/${contactNo}`);
  },
  createUserProfile(userId: number, payload: any) {
    return $axios.post(`/users/${userId}/public-profile`, payload);
  },
  updateUserProfile(userId: number, userPublicProfileId: number, payload: any) {
    return $axios.put(
      `/users/${userId}/public-profile/${userPublicProfileId}`,
      payload
    );
  },
  getCategories() {
    return $axios.get(`/categories`);
  },
  getCustomCategories(workstationId: number) {
    return $axios.get(`/categories/custom/${workstationId}`);
  },
  createCustomCategories(workstationId: number, payload: any) {
    return $axios.post(`categories/custom/${workstationId}`, payload);
  },
  createSubCategory(workstationId: number, payload: any) {
    return $axios.post(
      `categories/custom-sub-category/${workstationId}`,
      payload
    );
  },
  saveUserSkills(payload: UpdateUserSkillsRequestDto) {
    return $axios.post(`/users/${payload.userId}/skills`, payload.payload);
  },
  updateUser(userId: number, userData: any) {
    return $axios.put(`/users/${userId}`, userData);
  },
  updateClientUser(userId: number, userData: any) {
    return $axios.put(`/users/client/${userId}`, userData);
  },
  createUserWorkstation(userId: number, userWorkstation: any) {
    return $axios.post(`users/${userId}/workstations`, userWorkstation);
  },
  updateUserWorkstation(
    userId: number,
    userWorkstationId: number,
    payload: any
  ) {
    return $axios.put(
      `users/${userId}/workstations/${userWorkstationId}`,
      payload
    );
  },
  updateUserWorkstationAlias(
    userId: number,
    userWorkstationId: number,
    payload: any
  ) {
    return $axios.put(
      `users/${userId}/workstations/${userWorkstationId}/alias`,
      payload
    );
  },

  getUserWorkstations(userId: number) {
    return $axios.get(`/users/${userId}/workstations`);
  },
  getPropertyOwnerUserWorkstations(userId: number) {
    return $axios.get(`/users/${userId}/workstations/property-owner`);
  },
  getUserWorkstation(userId: number, userWorkstationId: number) {
    return $axios.get(`/users/${userId}/workstations/${userWorkstationId}`);
  },
  getUser(userId: number) {
    return $axios.get(`/users/${userId}`);
  },
  getAccountStatuses(userId: number): Promise<any> {
    return $axios.get(`/users/${userId}/statuses`);
  },
  createUserInsurance(createUserInsuranceRequest: any) {
    return $axios.post(
      `users/${createUserInsuranceRequest.userId}/user-insurances`,
      createUserInsuranceRequest
    );
  },
  getUserInsurances(userId: number) {
    return $axios.get(`users/${userId}/user-insurances`);
  },
  getContractorInsurances(userId: number, workStationId: number) {
    return $axios.get(
      `users/${userId}/user-insurances/trade-passport/${workStationId}`
    );
  },
  updateUserInsurance(
    userId: number,
    userInsuranceId: number,
    updateUserInsuranceRequest: any
  ) {
    return $axios.put(
      `users/${userId}/user-insurances/${userInsuranceId}`,
      updateUserInsuranceRequest
    );
  },
  deleteUserInsurance(
    userId: number,
    userInsuranceId: number
  ): Promise<number> {
    return $axios.delete(`users/${userId}/user-insurances/${userInsuranceId}`);
  },

  getUserCertificates(userId: number) {
    return $axios.get(`users/${userId}/user-certificates`);
  },
  getContractorCertificates(userId: number, workStationId: number) {
    return $axios.get(
      `users/${userId}/user-certificates/trade-passport/${workStationId}`
    );
  },
  createCertificate(createUserCertificateRequestDto: any) {
    return $axios.post(
      `users/${createUserCertificateRequestDto.userId}/user-certificates`,
      createUserCertificateRequestDto
    );
  },
  updateCertificate(
    userId: number,
    userCertificateId: number,
    updateUserCertificateRequestDto: any
  ) {
    return $axios.put(
      `users/${userId}/user-certificates/${userCertificateId}`,
      updateUserCertificateRequestDto
    );
  },
  addCertificateRelatedMembers(
    userId: number,
    certificateRecordId: number,
    teamMemberIds: any
  ): Promise<number> {
    return $axios.put(
      `users/${userId}/user-certificates/assigned-members/${certificateRecordId}`,
      teamMemberIds
    );
  },
  deleteCertificateRelatedMembers(
    userId: number,
    certificateRecordId: number,
    teamMemberIds: any
  ): Promise<number> {
    console.log("teamMemberIds", teamMemberIds);

    return $axios.put(
      `users/${userId}/user-certificates/remove-assigned-members/${certificateRecordId}`,
      teamMemberIds
    );
  },
  deleteCertificate(
    userId: number,
    userCertificateId: number
  ): Promise<number> {
    return $axios.delete(
      `users/${userId}/user-certificates/${userCertificateId}`
    );
  },
  updateUserWorkstationStatus(
    userId: number,
    workstationId: number,
    formData: any
  ) {
    return $axios.put(
      `/users/${userId}/workstations/${workstationId}/status`,
      formData
    );
  },
  getUserSkills(userId: number) {
    return $axios.get(`/users/${userId}/skills`);
  },
  getContractorSkills(userId: number, workStationId: number) {
    return $axios.get(
      `/users/${userId}/skills/trade-passport/${workStationId}`
    );
  },

  createUserWorkstationMembers(userId: number, userWorkstationMembers: any) {
    return $axios.post(
      `user-workstations/${userId}/members`,
      userWorkstationMembers
    );
  },
  deleteUserWorkstationMember(userId: number, id: number): Promise<number> {
    return $axios.delete(`user-workstations/${userId}/members/${id}`);
  },
  updateUserWorkstationMember(
    userId: number,
    id: number,
    userWorkstationMember: any
  ) {
    return $axios.put(
      `user-workstations/${userId}/members/${id}`,
      userWorkstationMember
    );
  },

  acceptUserWorkstationMember(userId: number, userWorkstationMember: any) {
    return $axios.put(
      `user-workstations/${userId}/members/accept`,
      userWorkstationMember
    );
  },
  getUserWorkstationMembers(userId: number, keyword: string) {
    return $axios.get(`user-workstations/${userId}/members?search=${keyword}`);
  },
  getMemberPermissions() {
    return $axios.get("/member-permissions");
  },
  activateWorkstation(userId: number, workstationId: number): Promise<[]> {
    return $axios.put(
      `/users/${userId}/workstations/${workstationId}/activate`
    );
  },
  createBusinessDetails(userId: number, createUserBusinessDetails: any) {
    return $axios.post(
      `/users/${userId}/business-details/`,
      createUserBusinessDetails
    );
  },
  updateBusinessDetails(
    userId: number,
    userBusinessDetailId: number,
    updateUserBusinessDetails: any
  ) {
    return $axios.put(
      `/users/${userId}/business-details/${userBusinessDetailId}`,
      updateUserBusinessDetails
    );
  },
  getWorkstationVerificationData(workstationId: number) {
    return $axios.get(`/silt/verification/${workstationId}`);
  },
  setWorkstationVerificationData(payload: any) {
    return $axios.post(`/silt/verification/kyc`, payload);
  },
  getCompanyKYBStatus(companyVerificationId: string) {
    return axios.get(
      `https://api.siltapp.com/v1/kyb/companies/${companyVerificationId}`,
      {
        headers: {
          "X-Company-App-Id": VUE_APP_SILT_KYB_COMPANY_APP_ID,
          "X-Company-App-API-Token": VUE_APP_SILT_KYC_API_TOKEN,
        },
      }
    );
  },
  getUserVerificationStatus(payload: userVerificationState) {
    return $axios.post(`/silt/verification/status`, payload);
  },
  sendUserKYBProcessMail(payload: any) {
    return $axios.post(`/silt/verification/invite-kyb`, payload);
  },
  sendUserKYCProcessMail(payload: any) {
    return $axios.post(`/didit/create-session`, payload);
  },
  activateWorkstationDescription(
    userId: number,
    workstationId: number,
    description: string
  ) {
    return $axios.put(
      `/users/${userId}/workstations/${workstationId}/description`,
      {
        description,
      }
    );
  },
  getUserTradeRates() {
    return $axios.get(`/trade-rates`);
  },
  addUserTradeRates(payload: any) {
    return $axios.post(`/trade-rates`, payload);
  },
  getWorkStationInvitation(userId: number) {
    return $axios.get(`/user-workstations/${userId}/members/invitation-list`);
  },
  acceptWorkStationInvitation(userId: number, key: string) {
    return $axios.put(`/user-workstations/${userId}/members/accept`, {
      key: key,
    });
  },
  rejectWorkStationInvitation(userId: number, workStationInvitationId: number) {
    return $axios.put(
      `/user-workstations/${userId}/members/${workStationInvitationId}/reject`
    );
  },
  updatePropertyOwnerWSType(
    userId: number,
    userWorkstationId: number,
    Type: any
  ) {
    return $axios.put(
      `users/${userId}/workstations/${userWorkstationId}/update-propertyowner-verification-type`,
      {
        verificationType: Type,
      }
    );
  },
  updateFreePlanWorkstation(userId: number, userWorkstationId: number) {
    return $axios.put(`users/${userId}/workstations/free/${userWorkstationId}`);
  },
  copyDataFromOtherWorkStation(userId: number, payload: any) {
    return $axios.post(`users/${userId}/workstations/copying`, payload);
  },
  assignWorkStationData(userId: number, workstationId: number, payload: any) {
    return $axios.post(
      `users/${userId}/workstations/${workstationId}/assign-data`,
      payload
    );
  },
  removeAssignedMembers(userId: number, teamMemberIds: number[]) {
    const payload = {
      teamMemberIds,
    } as any;
    return $axios.put(
      `/users/${userId}/skills/remove-assigned-members`,
      payload
    );
  },
  removeSubCategory(data: RemoveSubCategoryDto) {
    return $axios.put(
      `/users/${data.userId}/skills/remove-subCategory/${data.skillRecordId}`,
      data.payload
    );
  },
  deleteSkill(userId: number, skillRecordId: number) {
    return $axios.delete(`/users/${userId}/skills/${skillRecordId}`);
  },
  deleteWorkStationData(userId: number, workstationId: number) {
    return $axios.delete(`/users/${userId}/workstations/${workstationId}`);
  },
  fetchAddressFromPostCode(postCode: any) {
    return $axios.get(`/applications/search-places/${postCode}`);
  },
  inactivateWorkstations(userId: number, workstationIds: any) {
    return $axios.put(
      `/users/${userId}/workstations/invited/inactives`,
      workstationIds
    );
  },
  isFirstTimeLaunchWorkStation(userId: number, memberId: number) {
    return $axios.put(
      `/user-workstations/${userId}/members/${memberId}/isFirstTimeLaunch`
    );
  },
  getUserSkillVerificationStatus(userId: number) {
    return $axios.get(`/users/${userId}/skills/status`);
  },

  // Doesnt work
  getTradeUserRating(userId: number) {
    return $axios.get(`/users/${userId}/ratings/summary`);
  },

  getDefaultWorkstationByUserId(userId: number) {
    return $axios.get(`/users/${userId}/workstations/default`);
  },
};
