<template>
  <GeneralDialog
    width="570px"
    class="raise_question_create_account_modal"
    @on-close="onCloseModal"
    headerTitle="Create Your account"
    headerDescription="We have pre-filled your details using the information provided by the trade contractor. Please create your account to accept the estimate."
  >
    <template #body>
      <div class="selector">
        <v-form @submit.prevent :rules="validation" ref="createFormRef">
          <div class="input__form">
            <label>Full Name:</label>
            <v-text-field
              density="compact"
              :rules="validation.userName"
              placeholder="enter first name"
              v-model="createForm.userName"
              variant="outlined"
              class="input_field"
            ></v-text-field>
          </div>
          <div class="input__form">
            <label>Email Address:</label>
            <v-text-field
              density="compact"
              :rules="validation.email"
              placeholder="enter email address"
              v-model="createForm.email"
              :disabled="true"
              variant="outlined"
              class="input_field"
            ></v-text-field>
          </div>
        </v-form>
        <p class="error_text" v-if="errorMessage">{{ errorMessage }}</p>
        <div class="account_wrapper">
          <p class="common-title">Already have an account</p>
          <v-btn
            class="button button-transparent"
            variant="plain"
            @click="gotoLoginPage"
            append-icon="mdi-chevron-right"
            >Log In</v-btn
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal_footer">
        <v-btn
          class="button button-purple-border"
          :disabled="isLoading"
          @click="onCloseModal"
          >Cancel</v-btn
        >
        <v-btn
          class="button button-purple"
          @click="onConfirm"
          :loading="isLoading"
          >Create your account</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>

<script setup>
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import { RolesEnum, UserRolesEnum } from "@/core/enums/RolesEnum";
import { LOGIN_ROUTE } from "@/modules/login/routes";
import { AUTHENTICATION_STORE } from "@/store/modules/authentication";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { USER_STORE } from "@/store/modules/user";
import { computed, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const emits = defineEmits(["on-close", "onNext"]);
const createFormRef = ref(null);
const errorMessage = ref(null);
const isLoading = ref(false);
const createForm = ref({
  userName: "",
  email: "",
});
const store = useStore();

const validation = ref({
  userName: [(v) => !!v || "Fullname  is required"],
  email: [
    (v) => !!v || "Email is required",
    (v) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Invalid email. Try again.",
  ],
});
const clientRaiseEstimateDetails = computed(() => {
  return store.getters[`${PREFERRED_CONTRACTORS}/getClientRaiseEstimate`];
});

const onConfirm = async () => {
  errorMessage.value = null;
  const isFormValid = await createFormRef.value.validate();
  if (!isFormValid.valid) return;
  const isUserRegistered = await registerUser();
  if (isUserRegistered) {
    emits("onNext");
  }
};

const registerUser = async () => {
  try {
    isLoading.value = true;
    const userData = await store.dispatch(
      `${AUTHENTICATION_STORE}/newRegistration`,
      {
        firstName: createForm.value.userName,
        lastName: " ",
        marketingOptIn: false,
        tcOptIn: false,
        email: createForm.value.email,
        roleId: RolesEnum.PROJECT_OWNER,
      }
    );
    await store.dispatch(`${USER_STORE}/saveUserState`, userData);

    await store.dispatch(
      `${USER_STORE}/saveUserRole`,
      UserRolesEnum.PROPERTY_OWNER
    );
    return true;
  } catch (error) {
    errorMessage.value =
      error?.response?.data?.message ||
      "something went wrong! please Try Again.";
    return false;
  } finally {
    isLoading.value = false;
  }
};
const onCloseModal = () => {
  if (isLoading.value) return;
  emits("on-close");
};

const router = useRouter();
const gotoLoginPage = () => {
  router.push({ name: LOGIN_ROUTE });
};
onBeforeMount(() => {
  createForm.value.email =
    clientRaiseEstimateDetails.value?.preferredContractor?.email;
  createForm.value.userName = clientRaiseEstimateDetails.value?.userName;
});
</script>
<style lang="scss" scoped>
.raise_question_create_account_modal {
  .v-overlay__content {
    margin: 0 !important;
    .general-dialog {
      .selector {
        .v-form {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .input__form {
            label {
              @include fluidFont(12, 12, 2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              margin-bottom: 8px;
            }
            .input_field {
              margin: 0;
              :deep(.v-input__control) {
                .v-field {
                  border: 1px solid rgba($bordercolor, 1);
                  border-radius: 8px;
                  overflow: hidden;
                  .v-field__field {
                    .v-field__input {
                      background-color: rgba($backgroundcolor, 1);
                      border: 0;
                      border-radius: 0;
                      @include fluidFont(12, 12, 1.2);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                      letter-spacing: 0.4px;
                      min-height: 42px;
                      padding: 0 16px;
                      top: 0;
                    }
                  }
                  .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .modal_footer {
        padding-top: 24px;
        border-top: 1px dashed rgba($bordercolor, 1);
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: center;
        .button {
          text-transform: capitalize;
          @include fluidFont(16, 16, 20px);
          font-weight: 600;
        }
      }
    }
  }
}
.error_text {
  color: rgba($errorRed, 1);
  @include fluidFont(12, 12, 18px);
  margin: 4px 0 0;
  font-weight: 400;
}

.account_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  @include fluidFont(12, 12, 18px);
  p {
    margin-bottom: 0;
    font-weight: 400;
    color: #0c0f4a;
  }
  .button-transparent {
    height: auto !important;
    line-height: normal;
    letter-spacing: normal;
    color: rgba($orange, 1);
    opacity: 1;
  }
}
</style>
