<template>
  <div
    class="notification_wrapper"
    :class="{ 'is-in-default': isUserWorkstationIsDefault }"
  >
    <div class="notification_overlay"></div>
    <div
      class="notification_wrapper_body md:!tw-min-w-[300px] !tw-min-w-[545px] !tw-top-[.5rem] !tw-right-0 no-scrollbar md:!tw-max-h-[400px]"
      :class="{
        '!tw-right-[0] s540:!tw-top-[-9px] s540:!tw-right-[29px] s540:!tw-max-w-[315px] sm:!tw-right-[38px] sm:!tw-max-w-[400px]':
          isUserWorkstationIsDefault,
        'sm:!tw-right-[-5px]': !isUserWorkstationIsDefault,
      }"
    >
      <div class="notification_body_overlay" v-if="loading"></div>
      <Loader :show="loading" />
      <div class="notification_wrapper__header">
        <h5>Notifications</h5>
        <p
          v-if="hasUnreadNotifications"
          @click="onClickMarkAllNotificationAsRead"
        >
          Mark as read
        </p>
      </div>
      <!-- Notification List -->
      <div
        class="notification_wrapper__list"
        v-for="(group, date) in combinedNotifications"
        :key="date"
      >
        <div class="prefix">
          <span>{{ date }}</span>
        </div>
        <div
          @click="onNotificationClick(notification)"
          v-for="(notification, i) in group"
          :key="i"
          class="notification_wrapper__list_item"
        >
          <!-- <template> -->
          <div
            v-if="notification.state == NotificationStateEnum.UNREAD"
            class="notification_wrapper__new_item"
          ></div>

          <div class="notification_wrapper__list_item_content">
            <div class="notification_wrapper__img">
              <img
                v-if="notification?.profileImage"
                :src="notification?.profileImage"
                alt=""
              />

              <img
                v-else-if="
                  notification?.fromUser?.role?.id === 1 &&
                  notification?.fromUser?.role?.name === 'Administrator'
                "
                src="../../assets/images/iknowa-thumbnail.png"
                alt=""
              />

              <img v-else src="../../assets/images/user-placeholder.jpg" />
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.INSURANCEEXPIRATION
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p>
                  <strong>{{ notification.content }}</strong>
                </p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>

            <div
              v-if="
                notification.type ===
                UserNotificationType.DISPUTE_PROJECT_STATUS
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p>
                  <strong>{{ notification.content }}</strong>
                </p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>

            <div
              v-if="notification.type === UserNotificationType.INFORMATIVE"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="
                notification.type === UserNotificationType.WA_MEMBER_REQUEST
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p v-html="notification.content"></p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    :disable="isMemberRequestActionLoading"
                    :loading="
                      isMemberRequestActionLoading ===
                      UserNetworkRequestStatus.ACCEPTED
                    "
                    @click="
                      onClickMemberRequestAction(
                        UserNetworkRequestStatus.ACCEPTED,
                        notification
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    :loading="
                      isMemberRequestActionLoading ===
                      UserNetworkRequestStatus.REJECTED
                    "
                    :disable="isMemberRequestActionLoading"
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="
                      onClickMemberRequestAction(
                        UserNetworkRequestStatus.REJECTED,
                        notification
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>
            <div
              v-if="
                notification.type === UserNotificationType.INVITECALENDAREVENT
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="notification.type === UserNotificationType.PROJECTDELETED"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.TRADEUSERPROJECTACCEPTEDDIRECT
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="notification.type === UserNotificationType.ASSIGNEDCOURSE"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.ESTIMATE_ACCEPTED_BY_PO
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.REJECT_ESTIMATE_BY_PO_USER
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.VIDEO_CONSULTATION
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>

            <div
              v-if="notification.type === UserNotificationType.ESTIMATEARRIVALS"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.KYC_STATUS_UPDATION
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.INSURANCE_ARCHIVED
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.CLIENTORCONTRACTOR
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="notification.type === UserNotificationType.PHASE_REQUEST"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p>{{ notification.content }}</p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="notification.type === UserNotificationType.USER_NETWORK"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }} </strong> has sent you
                    trade network connection request
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? isAcceptingTradeNetworkRequest
                        : false
                    "
                    @click="
                      changeRequestStatus(
                        UserNetworkRequestStatus.ACCEPTED,
                        notification?.sourceId,
                        notification?.id,
                        notification
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? isRejectingTradeNetworkRequest
                        : false
                    "
                    @click="
                      changeRequestStatus(
                        UserNetworkRequestStatus.REJECTED,
                        notification?.sourceId,
                        notification?.id,
                        notification
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.CLIENT_HISTORICAL_PROJECT
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="
                      onClientHistoricalProjectStatus(
                        HistoricProjectStatusEnum.ACCEPTED,
                        notification
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="
                      onClientHistoricalProjectStatus(
                        HistoricProjectStatusEnum.REJECTED,
                        notification
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>
            <div
              v-if="
                notification.type === UserNotificationType.ASSIGN_TEAM_ESTIMATE
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="
                      onChangeAssignTeamEstimateStatus(
                        EstimatePhaseAssignMemberStatusEnum.ACCEPTED,
                        notification
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="
                      onChangeAssignTeamEstimateStatus(
                        EstimatePhaseAssignMemberStatusEnum.REJECTED,
                        notification
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>

            <div
              v-if="notification.type === UserNotificationType.USER_REQUEST"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="notification.type === UserNotificationType.RECOMMENDATION"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="
                notification.type === UserNotificationType.SITE_VISIT_REQUEST
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__text">
                <p v-html="notification.content"></p>
                <span>{{ notification?.timeAgo }}</span>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.ACCEPTWORKSTATIONMEMBERREQUEST
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p v-html="notification.content"></p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.ACCEPTTRADENETWORKREQUEST
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p v-html="notification.content"></p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.FIRSTTIMEWORKSTATIONLAUNCH
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>{{ notification.content }}</p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.TRADE_USER_CONNECTION
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong> wants to add you
                    to their preferred trade contractors list for future
                    projects.
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="
                      acceptInvitationStatus(
                        notification?.id,
                        notification?.token
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? invitationRejectLoader
                        : false
                    "
                    @click="
                      rejectInvitationStatus(notification?.id, notification?.id)
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>

            <div
              v-if="
                notification.type ===
                UserNotificationType.CONTRACTORINVITEREQUEST
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p v-html="notification.content"></p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="
                      acceptInvitationStatus(
                        notification?.sourceId,
                        notification?.metadata,
                        notification?.id
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? invitationRejectLoader
                        : false
                    "
                    @click="
                      rejectInvitationStatus(
                        notification?.sourceId,
                        notification?.id
                      )
                    "
                    >Reject
                  </v-btn>
                </div>
              </div>
            </div>

            <div
              v-if="
                notification.type ===
                  UserNotificationType.OUTERNETWORKREQUEST &&
                activeUserWorkstation?.verificationStatus ===
                  UserVerificationStatusEnum.VERIFIED
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p v-if="outerNetworkRequestStatus(notification?.metadata)">
                    <strong>{{ notification.content }}</strong> wants to add you
                    to their preferred trade contractors list for future
                    projects.
                  </p>
                  <p v-if="!outerNetworkRequestStatus(notification?.metadata)">
                    You have
                    {{ showOuterNetworkStatus(notification?.metadata) }} of
                    <strong>{{ notification.content }}'s</strong>
                    preferred trade contractors request
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div
                  class="notification_wrapper__buttons"
                  v-if="outerNetworkRequestStatus(notification?.metadata)"
                >
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? externalTradeNetworkRequestAcceptLoader
                        : false
                    "
                    @click="
                      acceptExternalTradeNetworkInvitationStatus(
                        notification?.id,
                        notification?.metadata
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? externalTradeNetworkRequestAcceptLoader
                        : false
                    "
                    @click="
                      rejectExternalTradeNetworkInvitationStatus(
                        notification?.id,
                        notification?.metadata
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>

            <div
              v-if="notification.type === UserNotificationType.PROJECT_CHAT"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>{{ notification.content }}</p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? chatRequestAcceptLoader
                        : false
                    "
                    @click="
                      acceptProjectInvitationStatus(
                        notification?.id,
                        notification?.sourceId
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    :loading="
                      notification?.id === loadingNotificationId
                        ? chatRequestRejectLoader
                        : false
                    "
                    @click="
                      rejectProjectInvitationStatus(
                        notification?.id,
                        notification?.sourceId
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>

            <div
              v-if="
                notification.type ===
                UserNotificationType.PROPERTY_USER_CONNECTION
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong> wants to add you
                    to their connection list for future projects.
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="
                      acceptPropertyInvitationStatus(
                        notification?.id,
                        notification?.token
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="rejectPropertyInvitationStatus(notification?.id)"
                    >Reject
                  </v-btn>
                </div>
              </div>
            </div>
            <div
              v-if="notification.type === UserNotificationType.VERIFY_JOBS"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>

            <div
              v-if="notification.type === UserNotificationType.VIDEO_CALL"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="onClickAcceptVideoCall(notification)"
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="onClickRejectVideoCall(notification)"
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>
            <!-- raise Project  -->
            <div
              v-if="
                notification.type === UserNotificationType.RAISE_PROJECT_TYPE
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="
                      updateRaiseProjectRequestStatus(
                        ProjectMetaDataStatusEnum.ACCEPTED,
                        Number(notification?.sourceId),
                        notification?.id,
                        notification?.metadata
                      )
                    "
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="
                      updateRaiseProjectRequestStatus(
                        ProjectMetaDataStatusEnum.REJECTED,
                        Number(notification?.sourceId),
                        notification?.id,
                        notification?.metadata
                      )
                    "
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.CLIENT_RAISE_ESTIMATE
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
                <div class="notification_wrapper__buttons">
                  <v-btn
                    class="notification_wrapper__accept"
                    variant="tonal"
                    @click="clientRaiseEstimateAction('accepted', notification)"
                    >Accept</v-btn
                  ><v-btn
                    class="notification_wrapper__reject"
                    variant="tonal"
                    @click="clientRaiseEstimateAction('rejected', notification)"
                    >Reject</v-btn
                  >
                </div>
              </div>
            </div>

            <div
              v-if="
                notification.type ===
                UserNotificationType.REJECTED_RAISE_PROJECT
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>

            <div
              v-if="
                notification.type === UserNotificationType.RAISE_NOTIFICATION
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                notification.type ===
                UserNotificationType.RAISE_ISSUE_BY_OCCUPIER
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>

            <div
              v-if="notification.type === UserNotificationType.ACCOUNT_VERIFIED"
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                [
                  UserNotificationType.PROJECT_COMPLETED,
                  UserNotificationType.ACCEPT_PROJECT_ESTIMATE,
                  UserNotificationType.UPDATE_PROJECT_ESTIMATE_SUB_CONTRACTOR,
                  UserNotificationType.START_PROJECT_RIGHT_AWAY,
                  UserNotificationType.UPDATE_PROJECT_ESTIMATE,
                ].includes(notification.type)
              "
              class="notification_wrapper__user_content"
            >
              <div class="notification_wrapper__user_content_info">
                <div class="notification_wrapper__text">
                  <p>
                    <strong>{{ notification.content }}</strong>
                  </p>
                  <span>{{ notification?.timeAgo }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                ![
                  UserNotificationType.WA_MEMBER_REQUEST,
                  UserNotificationType.USER_NETWORK,
                  UserNotificationType.CLIENT_HISTORICAL_PROJECT,
                  UserNotificationType.ASSIGN_TEAM_ESTIMATE,
                  UserNotificationType.TRADE_USER_CONNECTION,
                  UserNotificationType.CONTRACTORINVITEREQUEST,
                  UserNotificationType.OUTERNETWORKREQUEST,
                  UserNotificationType.PROJECT_CHAT,
                  UserNotificationType.PROPERTY_USER_CONNECTION,
                  UserNotificationType.VIDEO_CALL,
                  UserNotificationType.RAISE_PROJECT_TYPE,
                ].includes(notification.type)
              "
              class="notification_wrapper__more_button"
            >
              <v-menu
                :id="String(notification.id)"
                location="bottom"
                class="dropdown-card"
              >
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    <img src="@/assets/icons/dots.svg"
                  /></v-icon>
                </template>
                <v-list class="delete_wrapper">
                  <v-list-item @click.stop="onHandleAction(notification?.id)">
                    <TrashIcon />&nbsp;Delete
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <!-- </template> -->
        </div>
      </div>
    </div>
  </div>
  <SelectMultipleWorkstationModal
    v-if="isShowSelectMultipleWorkstationModal"
    @closeMultiSelectWorkstationModal="closeMultiSelectWorkstationModal"
    :acceptInvitationDetails="acceptInvitationDetails"
  />
  <VideoComponent
    v-show="videoConsultationStatus"
    :roomDetails="roomDetails"
    :isDirectJoinVideoCall="true"
    ref="videoComponent"
    @leaveCall="leaveCall"
  />
</template>
<script lang="ts">
import {
  computed,
  getCurrentInstance,
  ref,
  onBeforeMount,
  watch,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { JOBS_STORE } from "@/store/modules/jobs";
import {
  UserNotificationType,
  UserNetworkRequestStatus,
  ProjectMetaDataStatusEnum,
} from "@/core/enums/index";
import TradeNetwork from "@/core/services/trade-network.service";
import Notification from "@/core/services/notification.service";
import NotificationService from "@/core/services/notification.service";
import { CONNECTIONS } from "@/store/modules/connections";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { WORKSTATION } from "@/store/modules/workstation";
import moment from "moment";
import { NotificationStateEnum } from "@/core/enums/NotificationEnum";
import {
  MemberInvitationStatusEnum,
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { CHATMESSAGE } from "@/store/modules/chat-message";
import { UserMessagesRoomStatusEnum } from "@/core/enums/chatsEnums";
import SelectMultipleWorkstationModal from "@/modules/workstation/components/SelectMultipleWorkstationModal.vue";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import VideoComponent from "@/core/components/VideoComponent.vue";
import { parseJSON, setDirectStoragePathToImage } from "@/core/utils/common";
import VideoService from "@/core/services/video.service";
import { useRouter } from "vue-router";
import {
  PROJECT_DETAILS_ROUTE,
  PROJECT_REQUESTED_DETAILS_ROUTE,
  TRADE_ESTIMATES_ROUTE,
} from "@/modules/project/routes";
import { PROJECT_STORE } from "@/store/modules/project";
import { HistoricProjectStatusEnum } from "../enums/ProjectsEnum";
import projectEstimationService from "../services/project-estimation.service";
import {
  EstimatePhaseAssignMemberStatusEnum,
  permissionType,
} from "@/core/enums/estimateEnum";
import Loader from "./common/Loader.vue";
import { COURSES_STORE } from "@/store/modules/courses";
import { COURSE_ROUTE } from "@/modules/courses/routes";
import { JOB_DETAILS_ROUTE } from "@/modules/jobs/routes";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import { CLIENTS_ROUTE } from "@/modules/clients/routes";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import { TRADE_PASSPOSRT_ROUTE } from "@/modules/trade-passport/routes";
import estimationService from "@/core/services/estimation.service";
import { TRADE_NETWORK_ROUTE } from "@/modules/trade-network/routes";
import preferredContractorsService from "../services/preferred-contractors.service";

export default {
  components: {
    SelectMultipleWorkstationModal,
    VideoComponent,
    Loader,
    TrashIcon,
  },
  name: "NotificationComponent",
  inheritAttrs: false,
  emits: [
    "closeNotificationComponent",
    "onClickNotification",
    "openVerifyModal",
  ],
  setup(props: any, ctx: any) {
    const store = useStore();
    const router = useRouter();
    const internalInstance = getCurrentInstance();

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const isShowSelectMultipleWorkstationModal = ref(false);
    const acceptInvitationDetails = ref();
    const invitationAcceptLoader = ref(false);
    const invitationRejectLoader = ref(false);
    const loading = ref(false);
    const videoComponent = ref(null) as any;
    const userNotificationsWithTimeAgo = ref([]) as any;
    const isAcceptingTradeNetworkRequest = ref(false);
    const isRejectingTradeNetworkRequest = ref(false);
    const chatRequestAcceptLoader = ref(false);
    const chatRequestRejectLoader = ref(false);
    const userNotifications = computed(
      () => store.getters[`${USER_STORE}/userNotifications`]
    );

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const draftJobsList = computed(
      () => store.getters[`${JOBS_STORE}/getDraftJobs`]
    );
    const isUserWorkstationIsDefault = computed(
      () =>
        activeUserWorkstation.value?.name === "Default" &&
        activeUserWorkstation.value?.status === "active"
    );

    const videoConsultationStatus = ref(false);

    const roomDetails = ref({}) as any;

    const userFullName = (notification: any) => {
      return `${notification.fromUser?.firstName} ${notification.fromUser?.lastName}`;
    };

    const externalTradeNetworkRequestAcceptLoader = ref(false);
    const externalTradeNetworkRequestRejectLoader = ref(false);
    const loadingNotificationId = ref(null) as any;

    const formatTimeAgo = (dateString: any) => {
      const dateCreated = moment.utc(dateString);
      const currentTime = moment.utc();
      const diffMinutes = currentTime.diff(dateCreated, "minutes");
      const diffHours = Math.abs(Math.floor(diffMinutes / 60));

      if (diffMinutes < 60) {
        return `${diffMinutes} min ago`;
      } else {
        if (diffHours < 24) {
          return `${diffHours} hr ago`;
        } else {
          const diffDays = currentTime.diff(dateCreated, "days");
          return `${diffDays} days ago`;
        }
      }
    };

    interface Notification {
      id: number;
      dateCreated: string;
      dateUpdated: string;
      sourceId: any;
      sourceType: UserNotificationType;
      state: string;
      type: UserNotificationType;
      imageUrl: string;
      content: string;
      metadata: string;
      timeAgo: string;
      profileImage: string;
      token: any;
      fromUser: {
        role: {
          id: number;
          name: string;
        };
      };
    }

    interface CombinedNotifications {
      [date: string]: Notification[];
    }

    const combinedNotifications = computed(() => {
      const Notifications = [
        ...filterNotificationList.value,
        ...formattedTradeUserConnectionsList.value,
        ...formattedPropertyUserConnectionsList.value,
        ...formattedExternalTradeNetworkConnectionList.value,
      ];
      let notifications = Notifications.map((notification: Notification) => ({
        ...notification,
        profileImage: notification?.imageUrl,
        timeAgo: formatTimeAgo(notification.dateCreated),
      }));
      // filter out if sourceType matches
      const notificationTypesArray = Object.values(UserNotificationType);
      notifications = notifications.filter((notification: any) =>
        notificationTypesArray.includes(notification?.type)
      );

      const today = moment().format("DD-MM-YYYY");
      const yesterday = moment().subtract(1, "day").format("DD-MM-YYYY");

      const groupedByDate: CombinedNotifications = {};

      notifications.forEach((notification: Notification) => {
        const date = moment(notification.dateCreated).format("DD-MM-YYYY");
        if (!groupedByDate[date]) {
          groupedByDate[date] = [];
        }
        groupedByDate[date].push(notification);
      });

      const result: CombinedNotifications = {};

      if (groupedByDate[today]) {
        result["Today"] = groupedByDate[today];
      }

      if (groupedByDate[yesterday]) {
        result["Yesterday"] = groupedByDate[yesterday];
      }

      Object.keys(groupedByDate).forEach((date) => {
        if (date !== today && date !== yesterday) {
          result[date] = groupedByDate[date];
        }
        // result[date] = groupedByDate[date];
      });
      return result;
    });

    const onClickMarkAllNotificationAsRead = async () => {
      userNotifications.value.forEach(async (notification: any) => {
        if (notification.state == NotificationStateEnum.UNREAD) {
          await Notification.updateNotification(notification.id, {
            state: NotificationStateEnum.READ,
          });
        }
      });
      store.commit(`${USER_STORE}/resetUserNotifications`);
      const userNotificationsNew =
        (await NotificationService.getUserNotificationStatus()) as any;
      userNotificationsNew.forEach((userNotification: object) => {
        store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
      });
    };

    const hasUnreadNotifications = computed(() => {
      return Object.values(combinedNotifications.value).some(
        (group: Notification[]) =>
          group.some(
            (notification: Notification) =>
              notification.state === NotificationStateEnum.UNREAD
          )
      );
    });
    const changeRequestStatus = async (
      requestStatus: string,
      id: number,
      notificationId: number,
      notification: any
    ) => {
      try {
        loadingNotificationId.value = notificationId;
        if (requestStatus == "accepted") {
          isAcceptingTradeNetworkRequest.value = true;
          if (notification.project != null) {
            const isDraftJob = await isJobDraftJob(notification);
            if (isDraftJob) {
              const inviteContractor = {
                toContractorId: Number(notification.userWorkstationId),
                toUserId: notification.toUser.id,
                permissionType: permissionType[0].id,
              };
              await TradeNetwork.updateRequestToContractor(id, {
                status: requestStatus,
              });
              const response = await estimationService.inviteContractors(
                notification?.fromWorkstation?.id,
                {
                  inviteContractors: [inviteContractor],
                  projectId: notification.project.id,
                  description: "",
                  projectEstimateId:
                    notification.project.projectEstimation[0]?.id,
                }
              );
            } else {
              displayToast(
                "Sorry ! Project has entered in progress state ",
                "error"
              );
              await TradeNetwork.updateRequestToContractor(id, {
                status: "rejected",
              });
            }
          } else {
            await TradeNetwork.updateRequestToContractor(id, {
              status: requestStatus,
            });
            displayToast(
              `${notification.content} has successfully been added to trade network`,
              "success"
            );
            closeNotificationComponent();
            router.push({ name: TRADE_NETWORK_ROUTE });
          }
        } else {
          isRejectingTradeNetworkRequest.value = true;
          await TradeNetwork.updateRequestToContractor(id, {
            status: requestStatus,
          });
        }
        removeNotificationById(notificationId);
      } catch (error) {
        console.log("error", error);
      } finally {
        if (isAcceptingTradeNetworkRequest.value) {
          isAcceptingTradeNetworkRequest.value = false;
        }
        if (isRejectingTradeNetworkRequest.value) {
          isRejectingTradeNetworkRequest.value = false;
        }
      }
    };

    const updateRaiseProjectRequestStatus = async (
      requestStatus: ProjectMetaDataStatusEnum,
      id: number,
      notificationId: number,
      metaData: any
    ) => {
      try {
        if (requestStatus === ProjectMetaDataStatusEnum.REJECTED) {
          await store.dispatch(`${PROJECT_STORE}/updateRaiseProjectStatus`, {
            projectId: id,
            data: {
              status: requestStatus,
            },
          });
        }

        if (requestStatus === ProjectMetaDataStatusEnum.ACCEPTED) {
          const projectRefId = parseJSON(metaData)?.projectRefId;
          if (projectRefId) {
            router.push({
              name: PROJECT_REQUESTED_DETAILS_ROUTE,
              params: { refId: projectRefId },
            });
          }
        }
        removeNotificationById(notificationId);
      } catch (error) {
        console.log("error", error);
      }
    };

    const closeMultiSelectWorkstationModal = async (type: string) => {
      isShowSelectMultipleWorkstationModal.value = false;
      store.commit(`${USER_STORE}/resetUserNotifications`);
      const userNotifications =
        (await NotificationService.getUserNotificationStatus()) as any;
      userNotifications.forEach((userNotification: object) => {
        store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
      });

      if (
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
        activeUserWorkstation.value?.name !== "Default"
      ) {
        let params: any = {
          status: "accepted",
        };
        await store.dispatch(
          `${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`,
          {
            userId: user.value?.id,
            params: params,
          }
        );
      }
      if (user.value?.role?.name === UserRolesEnum.TRADESPERSON) {
        let params: any = {
          userId: user.value?.id,
          status: "accepted",
        };
        await store.dispatch(
          `${PREFERRED_CONTRACTORS}/fetchPreferredContractors`,
          params
        );
      }
      if (type === UserNotificationType.TRADE_USER_CONNECTION) {
        // getTradeUserConnection();
      } else if (type === UserNotificationType.PROPERTY_USER_CONNECTION) {
        getPropertyUserConnection();
      }
    };

    const acceptInvitationStatus = async (
      id: any,
      metaData: string,
      notificationId = 0
    ) => {
      const data = parseJSON(metaData);
      acceptInvitationDetails.value = {
        id,
        token: data.token,
        type: UserNotificationType.TRADE_USER_CONNECTION,
        notificationId: notificationId,
      };
      isShowSelectMultipleWorkstationModal.value = true;
    };

    const rejectInvitationStatus = async (id: any, notificationId: any) => {
      try {
        loadingNotificationId.value = notificationId;
        invitationRejectLoader.value = true;
        await store.dispatch(
          `${PREFERRED_CONTRACTORS}/rejectRequestTradeUserConnection`,
          {
            userId: user.value?.id,
            tradeUserConnectionId: +id,
          }
        );
        store.commit(`${USER_STORE}/resetUserNotifications`);
        const userNotifications =
          (await NotificationService.getUserNotificationStatus()) as any;
        userNotifications.forEach((userNotification: object) => {
          store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        });
        invitationRejectLoader.value = false;
      } catch (error) {
        console.log("error", error);
        invitationRejectLoader.value = false;
      }
    };

    const acceptProjectInvitationStatus = async (
      notificationId: number,
      id: string
    ) => {
      try {
        loading.value = true;
        loadingNotificationId.value = notificationId;
        chatRequestAcceptLoader.value = true;
        await store.dispatch(`${CHATMESSAGE}/acceptProjectChatStatus`, {
          userMessageId: parseInt(id),
          status: UserMessagesRoomStatusEnum.ACCEPTED,
        });

        store.commit(`${CHATMESSAGE}/setProjectChatRequests`, {
          userMessageId: parseInt(id),
        });
        removeNotificationById(notificationId);
      } catch (error) {
        console.log("error", error);
      } finally {
        loading.value = false;
        chatRequestAcceptLoader.value = false;
      }
    };

    const rejectProjectInvitationStatus = async (
      notificationId: number,
      id: string
    ) => {
      try {
        loadingNotificationId.value = notificationId;
        chatRequestRejectLoader.value = true;
        await store.dispatch(`${CHATMESSAGE}/rejectProjectChatStatus`, {
          userMessageId: parseInt(id),
        });

        removeNotificationById(notificationId);
      } catch (error) {
        console.log("error", error);
      } finally {
        chatRequestRejectLoader.value = false;
      }
    };

    const formattedTradeUserConnectionsList = ref([]);
    const getTradeUserConnection = async () => {
      try {
        const TradeUserConnection = await store.dispatch(
          `${PREFERRED_CONTRACTORS}/getTradeUserConnection`,
          {
            userId: user.value?.id,
          }
        );
        const formattedTradeUserConnections = TradeUserConnection.map(
          (connection: any) => ({
            id: connection?.id,
            dateCreated: connection?.dateCreated,
            dateUpdated: connection?.dateUpdated,
            sourceId: connection?.user?.id,
            token: connection?.token,
            sourceType: "TradeUserConnection",
            state: "unread",
            type: "TradeUserConnection",
            content: `${connection?.user?.firstName} ${connection?.user?.lastName}`,
            metadata: JSON.stringify({ id: connection?.id }),
            timeAgo: formatTimeAgo(connection?.dateCreated),
            preferred: true,
          })
        );

        formattedTradeUserConnectionsList.value = formattedTradeUserConnections;
      } catch (err) {
        console.log();
      }
    };

    const formattedExternalTradeNetworkConnectionList = ref([]);
    const getExternalTradeNetworkConnection = async () => {
      try {
        const externalTradeNetworkConnection = await store.dispatch(
          `${TRADE_NETWORK}/getExternalInvitedContractorForNotification`
        );
        const formattedExternalTradeNetworkConnection =
          externalTradeNetworkConnection.map((connection: any) => ({
            id: connection?.id,
            dateCreated: connection?.dateCreated,
            dateUpdated: connection?.dateUpdated,
            sourceId: connection?.user?.id,
            token: connection?.token,
            sourceType: "ExternalTradeNetwork",
            state: "unread",
            type: "ExternalTradeNetwork",
            content: `${connection?.user?.firstName} ${connection?.user?.lastName}`,
            metadata: JSON.stringify({ id: connection?.id }),
            timeAgo: formatTimeAgo(connection?.dateCreated),
          }));

        formattedExternalTradeNetworkConnectionList.value =
          formattedExternalTradeNetworkConnection;
      } catch (err) {
        console.log();
      }
    };

    const rejectExternalTradeNetworkInvitationStatus = async (
      id: number,
      metaData: any
    ) => {
      try {
        loadingNotificationId.value = id;
        externalTradeNetworkRequestRejectLoader.value = true;
        const data = parseJSON(metaData);
        const response = await store.dispatch(
          `${TRADE_NETWORK}/rejectExternalContractorInvitation`,
          {
            rowId: data.id,
          }
        );
        if (response?.data?.statusCode === 500) {
          displayToast(response?.data?.message, "error");
        } else {
          data.requestStatus = "rejected";
          const payLoad = {} as any;
          payLoad.metadata = JSON.stringify(data);
          await Notification.updateOuterNetworkNotificationStatus(id, payLoad);
          store.commit(`${USER_STORE}/resetUserNotifications`);
          const userNotifications =
            (await NotificationService.getUserNotificationStatus()) as any;
          userNotifications.forEach((userNotification: object) => {
            store.commit(
              `${USER_STORE}/setUserNotifications`,
              userNotification
            );
          });
        }
        externalTradeNetworkRequestRejectLoader.value = false;
      } catch (error) {
        console.log("error", error);
        externalTradeNetworkRequestRejectLoader.value = false;
      }
    };

    const displayToast = (message: string, type: string) => {
      if (
        internalInstance &&
        internalInstance.appContext.config.globalProperties.$notify
      ) {
        internalInstance.appContext.config.globalProperties.$notify[type]({
          message,
        });
      }
    };
    const acceptExternalTradeNetworkInvitationStatus = async (
      id: number,
      metaData: any
    ) => {
      try {
        loadingNotificationId.value = id;
        externalTradeNetworkRequestAcceptLoader.value = true;
        const data = parseJSON(metaData);

        const response = await store.dispatch(
          `${TRADE_NETWORK}/acceptExternalContractorInvitation`,
          {
            rowId: data.id,
            token: data.token,
          }
        );
        if (response?.data?.statusCode === 500) {
          displayToast(response?.data?.message, "error");
        } else {
          data.requestStatus = "accepted";
          const payLoad = {} as any;
          payLoad.metadata = JSON.stringify(data);
          await Notification.updateOuterNetworkNotificationStatus(id, payLoad);
          store.commit(`${USER_STORE}/resetUserNotifications`);
          const userNotifications =
            (await NotificationService.getUserNotificationStatus()) as any;
          userNotifications.forEach((userNotification: object) => {
            store.commit(
              `${USER_STORE}/setUserNotifications`,
              userNotification
            );
          });
        }
        externalTradeNetworkRequestAcceptLoader.value = false;
      } catch (error) {
        console.log(error);
        externalTradeNetworkRequestAcceptLoader.value = false;
      }
    };

    const formattedPropertyUserConnectionsList = ref([]);

    const getPropertyUserConnection = async () => {
      try {
        const TradeUserConnection = await store.dispatch(
          `${CONNECTIONS}/getPropertyUserConnection`,
          {
            userId: user.value?.id,
          }
        );
        const formattedPropertyUserConnections = TradeUserConnection.map(
          (connection: any) => ({
            id: connection?.id,
            dateCreated: connection?.dateCreated,
            dateUpdated: connection?.dateUpdated,
            sourceId: connection?.user?.id,
            token: connection?.token,
            sourceType: UserNotificationType.PROPERTY_USER_CONNECTION,
            state: "unread",
            type: UserNotificationType.PROPERTY_USER_CONNECTION,
            content: `${connection?.user?.firstName} ${connection?.user?.lastName}`,
            metadata: JSON.stringify({ id: connection?.id }),
            timeAgo: formatTimeAgo(connection?.dateCreated),
          })
        );

        formattedPropertyUserConnectionsList.value =
          formattedPropertyUserConnections;
      } catch (err) {
        console.log();
      }
    };

    const acceptPropertyInvitationStatus = async (
      id: number,
      token: string
    ) => {
      acceptInvitationDetails.value = {
        id,
        token,
        type: UserNotificationType.PROPERTY_USER_CONNECTION,
      };
      isShowSelectMultipleWorkstationModal.value = true;
    };

    const rejectPropertyInvitationStatus = async (id: number) => {
      try {
        await store.dispatch(`${CONNECTIONS}/rejectPropertyUserConnection`, {
          userId: user.value?.id,
          tradeUserConnectionId: id,
          type: "rejected",
        });
        getPropertyUserConnection();
      } catch (error) {
        console.log("error", error);
      }
    };

    const onClickAcceptVideoCall = async (notification: any) => {
      roomDetails.value["title"] = parseJSON(notification.metadata)?.id;
      roomDetails.value["url"] = parseJSON(notification.metadata)?.videoCallUrl;
      roomDetails.value["token"] = parseJSON(notification.metadata)?.token;

      videoConsultationStatus.value = true;
      videoComponent.value?.onClickVideoCallButton();
      removeNotificationById(notification.id);
      closeNotificationComponent();
    };

    const onClickRejectVideoCall = async (notification: any) => {
      const isGroupCall = parseJSON(notification.metadata)?.isGroup;
      if (!isGroupCall) {
        await VideoService.deleteRoom(parseJSON(notification.metadata)?.id);
      }
      removeNotificationById(notification.id);

      closeNotificationComponent();
    };

    const closeNotificationComponent = () => {
      ctx.emit("closeNotificationComponent");
    };
    const leaveCall = () => {
      videoConsultationStatus.value = false;
      roomDetails.value = {};
    };

    const onClientHistoricalProjectStatus = async (
      requestStatus: string,
      notification: any
    ) => {
      try {
        const metadata = parseJSON(notification.metadata);
        await store.dispatch(`${PROJECT_STORE}/updateHistoricalProjectStatus`, {
          projectId: metadata?.projectId,
          data: {
            historicalProjectStatus: requestStatus,
            projectEstimateId: metadata?.projectEstimateId,
          },
        });
        removeNotificationById(notification.id);
      } catch (error) {
        console.log("error", error);
      }
    };
    const clientRaiseEstimateAction = async (
      requestStatus: string,
      notification: any
    ) => {
      try {
        const metadata = parseJSON(notification.metadata);
        await preferredContractorsService.clientRaiseEstiamteHandle(
          user.value.id,
          {
            clientEstimateRaiseId: metadata.clientEstimateRaiseId,
            status: requestStatus,
          }
        );
        removeNotificationById(notification.id);
      } catch (error) {
        console.log("error", error);
      }
    };
    const onChangeAssignTeamEstimateStatus = async (
      requestStatus: EstimatePhaseAssignMemberStatusEnum,
      notification: any
    ) => {
      try {
        const metadata = parseJSON(notification.metadata);
        const res =
          await projectEstimationService.updateAssignTeamMemberRequestStatus(
            metadata.id,
            { status: requestStatus }
          );

        removeNotificationById(notification.id);
      } catch (error) {
        console.log("error", error);
      }
    };

    const outerNetworkRequestStatus = (data: any) => {
      const jsonData = parseJSON(data);
      const status = jsonData.requestStatus;
      if (status === "requested") {
        return true;
      } else {
        return false;
      }
    };

    const showOuterNetworkStatus = (data: any) => {
      const jsonData = parseJSON(data);
      const status = jsonData.requestStatus;
      return status;
    };
    const filterNotificationList = ref([]) as any;
    const setNotificationProfileImage = async () => {
      filterNotificationList.value = parseJSON(
        JSON.stringify(userNotifications.value)
      );
      // filterNotificationList.value.filter(async (notification: any) => {
      //   if (notification.project != null) {
      //     const draftJobList = await store.dispatch(
      //       `${JOBS_STORE}/fetchDraftJobsByUser`,
      //       {
      //         userId: notification.fromUser.id,
      //         workstationId: notification.fromWorkstation.id,
      //       }
      //     );
      //     console.log("draftJobList", draftJobList);
      //     return draftJobList.filter((job: any) => {
      //       return job.id === notification.project.id;
      //     });
      //   } else {
      //     return true;
      //   }
      // });
      for (const notification of filterNotificationList.value) {
        const metaData = parseJSON(notification?.metadata);
        notification.imageUrl = setDirectStoragePathToImage(
          metaData?.profileImage
        );
      }
    };

    watch(
      () => userNotifications.value,
      () => {
        setNotificationProfileImage();
      },
      { deep: true }
    );

    const isJobDraftJob = async (notification: any) => {
      const draftJobList = await store.dispatch(
        `${JOBS_STORE}/fetchDraftJobsByUser`,
        {
          userId: notification.fromUser.id,
          workstationId: notification.fromWorkstation.id,
        }
      );
      console.log("DrafJobList", draftJobList);

      return draftJobList.some((job: any) => {
        return job.id == notification.project.id;
      });
    };
    onBeforeMount(async () => {
      if (
        user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
        activeUserWorkstation.value?.name !== "Default"
      ) {
        getPropertyUserConnection();
        // getTradeUserConnection();
      }
      if (
        activeUserWorkstation.value?.verificationStatus ===
        UserVerificationStatusEnum.VERIFIED
      ) {
        // getTradeUserConnection();
        // getExternalTradeNetworkConnection();
      }

      setNotificationProfileImage();
    });

    const onNotificationClick = async (notification: any) => {
      if (notification?.type === UserNotificationType.ASSIGNEDCOURSE) {
        store.commit(`${COURSES_STORE}/setCourseModuleRedirect`, "tab-3");
        if (
          activeUserWorkstation.value?.id !== +notification?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +notification?.userWorkstationId,
            notificationType: notification?.type,
          });
        } else {
          router.push({ name: COURSE_ROUTE });
        }
        closeNotificationComponent();
      }

      if (notification?.type == UserNotificationType.RECOMMENDATION) {
        if (
          activeUserWorkstation.value?.id !== +notification?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +notification?.userWorkstationId,
            notificationType: notification?.type,
            propertyId: parseJSON(notification?.metadata)?.propertyId,
          });
        } else {
          await router.push({
            name: "propertyDetails",
            params: {
              propertyId: parseJSON(notification?.metadata)?.propertyId,
            },
          });
        }
        closeNotificationComponent();
      }
      if (notification?.type == UserNotificationType.SITE_VISIT_REQUEST) {
        await store.dispatch(
          `${PROJECT_STORE}/setProjectRedirectTabName`,
          "site-visit"
        );
        if (
          activeUserWorkstation.value?.id !== +notification?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +notification?.userWorkstationId,
            notificationType: notification?.type,
            projectRefId: parseJSON(notification?.metadata)?.projectRefId,
          });
        } else {
          router.push({
            name: PROJECT_DETAILS_ROUTE,
            params: { refId: parseJSON(notification?.metadata)?.projectRefId },
          });
        }
        closeNotificationComponent();
      }
      if (notification?.type === UserNotificationType.ESTIMATEARRIVALS) {
        if (
          activeUserWorkstation.value?.id !== +notification?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +notification?.userWorkstationId,
            notificationType: notification?.type,
            estimateId: parseJSON(notification?.metadata)?.estimateId,
          });
        } else {
          router.push({
            name: TRADE_ESTIMATES_ROUTE,
            params: {
              estimateId: parseJSON(notification?.metadata)?.estimateId,
            },
          });
        }
        closeNotificationComponent();
      }

      // preferred CLIENTORCONTRACTOR start
      if (notification?.type === UserNotificationType.CLIENTORCONTRACTOR) {
        let role = parseJSON(notification?.metadata)?.role;
        let redirectRoute =
          role === 3 ? CLIENTS_ROUTE : "preferred-contractors";
        if (
          activeUserWorkstation.value?.id !== +notification?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +notification?.userWorkstationId,
            notificationType: notification?.type,
            redirectRoute: redirectRoute,
          });
        } else {
          router.push({ name: redirectRoute });
        }
        closeNotificationComponent();
      }
      // preferred CLIENTORCONTRACTOR end

      if (notification?.type === UserNotificationType.VIDEO_CONSULTATION) {
        await store.dispatch(
          `${PROJECT_STORE}/setProjectRedirectTabName`,
          "video consultation"
        );
        if (
          activeUserWorkstation.value?.id !== +notification?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +notification?.userWorkstationId,
            notificationType: notification?.type,
            refId: parseJSON(notification?.metadata)?.roomTitle,
          });
        } else {
          router.push({
            name: PROJECT_DETAILS_ROUTE,
            params: { refId: parseJSON(notification?.metadata)?.roomTitle },
          });
        }
        closeNotificationComponent();
      }
      if (notification?.type === UserNotificationType.USER_REQUEST) {
        const metadata = parseJSON(notification.metadata);
        if (!metadata?.projectRefId) return;
        store.commit(`${JOBS_STORE}/setIsSiteVisitResponseNotification`, true);
        if (
          activeUserWorkstation.value?.id !== +metadata?.userWorkstationId &&
          metadata?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +metadata?.userWorkstationId,
            notificationType: notification?.type,
            verificationType: metadata?.verificationType,
          });
        } else {
          if (metadata.projectRefId) {
            router.push({
              name: JOB_DETAILS_ROUTE,
              params: { refId: metadata.projectRefId },
              hash: `#jobDetails`,
            });
          }
        }
        closeNotificationComponent();
      }

      if (notification?.type === UserNotificationType.ACCOUNT_VERIFIED) {
        const metadata = parseJSON(notification.metadata);
        if (
          activeUserWorkstation.value?.id !== +metadata?.userWorkstationId &&
          metadata?.userWorkstationId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +metadata?.userWorkstationId,
            notificationType: notification?.type,
            verificationType: metadata?.verificationType,
          });
        } else {
          if (
            ["insurance", "certificate", "skill"].includes(
              metadata.verificationType
            )
          )
            router.push({ name: TRADE_PASSPOSRT_ROUTE });
        }
        closeNotificationComponent();
      }
      if (
        [
          UserNotificationType.PROJECT_COMPLETED,
          UserNotificationType.ESTIMATE_ACCEPTED_BY_PO,
          UserNotificationType.REJECT_ESTIMATE_BY_PO_USER,
          UserNotificationType.ACCEPT_PROJECT_ESTIMATE,
          UserNotificationType.UPDATE_PROJECT_ESTIMATE_SUB_CONTRACTOR,
        ].includes(notification?.type)
      ) {
        const notificationMetaData = parseJSON(notification.metadata);
        if (
          activeUserWorkstation.value?.id !==
            +notificationMetaData?.userWorkstationId &&
          notificationMetaData?.userWorkstationId &&
          notificationMetaData?.projectRefId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +notificationMetaData?.userWorkstationId,
            notificationType: notification?.type,
            projectRefId: notificationMetaData?.projectRefId,
          });
        } else {
          if (notificationMetaData?.projectRefId) {
            router.push({
              name: JOB_DETAILS_ROUTE,
              params: { refId: notificationMetaData.projectRefId },
            });
          }
        }
        closeNotificationComponent();
      }
      if (
        [
          UserNotificationType.START_PROJECT_RIGHT_AWAY,
          UserNotificationType.UPDATE_PROJECT_ESTIMATE,
          UserNotificationType.PHASE_REQUEST,
        ].includes(notification?.type)
      ) {
        const metadata = parseJSON(notification.metadata);
        if (
          activeUserWorkstation.value?.id !== +metadata?.userWorkstationId &&
          metadata?.userWorkstationId &&
          metadata?.projectRefId
        ) {
          ctx.emit("onClickNotification", {
            workstationId: +metadata?.userWorkstationId,
            notificationType: notification?.type,
            projectRefId: metadata?.projectRefId,
          });
        } else {
          if (metadata?.projectRefId)
            router.push({
              name: PROJECT_DETAILS_ROUTE,
              params: { refId: metadata.projectRefId },
            });
        }
        closeNotificationComponent();
      }
    };
    const isMemberRequestActionLoading = ref(null) as any;
    const onClickMemberRequestAction = async (
      requestStatus: string,
      notification: any
    ) => {
      try {
        const metadata = parseJSON(notification.metadata);
        if (metadata?.requestStatus !== MemberInvitationStatusEnum.PENDING)
          return;
        if (requestStatus === UserNetworkRequestStatus.ACCEPTED) {
          if (
            user.value?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.VERIFIED
          ) {
            isMemberRequestActionLoading.value =
              UserNetworkRequestStatus.ACCEPTED;
            await store.dispatch(`${USER_STORE}/acceptWorkStationInvitation`, {
              userId: user.value?.id,
              key: metadata.token,
            });
            await store.dispatch(
              `${WORKSTATION}/getUserWorkstations`,
              user.value?.id
            );
            removeNotificationById(notification.id);
            displayToast("Workstation member request accepted", "success");
          } else {
            ctx.emit("openVerifyModal");
          }
        } else if (requestStatus === UserNetworkRequestStatus.REJECTED) {
          isMemberRequestActionLoading.value =
            UserNetworkRequestStatus.REJECTED;
          const response = await store.dispatch(
            `${MEMBER_STORE}/deleteMemberDetails`,
            {
              userId: user.value?.id,
              userMemberId: metadata.id,
            }
          );
          await store.dispatch(
            `${WORKSTATION}/getUserWorkstations`,
            user.value?.id
          );
          removeNotificationById(notification.id);
          displayToast("Workstation member request rejected", "success");
        }
      } catch (err) {
        console.log();
      } finally {
        isMemberRequestActionLoading.value = null;
      }
    };
    const removeNotificationById = async (notificationId: number) => {
      try {
        await store.dispatch(
          `${USER_STORE}/removeNotification`,
          notificationId
        );
        // await Notification.deleteNotification(notificationId);
        // store.commit(`${USER_STORE}/resetUserNotifications`);
        // const userNotifications =
        //   (await NotificationService.getUserNotificationStatus()) as any;
        // userNotifications.forEach((userNotification: object) => {
        //   store.commit(`${USER_STORE}/setUserNotifications`, userNotification);
        // });
      } catch (error) {
        console.log("");
      }
    };

    const onHandleAction = (notificationId: number) => {
      if (notificationId) removeNotificationById(notificationId);
    };

    return {
      user,
      userNotifications,
      UserNotificationType,
      userFullName,
      changeRequestStatus,
      UserNetworkRequestStatus,
      userNotificationsWithTimeAgo,
      combinedNotifications,
      NotificationStateEnum,
      onClickMarkAllNotificationAsRead,
      acceptInvitationStatus,
      rejectInvitationStatus,
      hasUnreadNotifications,
      acceptProjectInvitationStatus,
      rejectProjectInvitationStatus,
      isShowSelectMultipleWorkstationModal,
      closeMultiSelectWorkstationModal,
      acceptInvitationDetails,
      acceptPropertyInvitationStatus,
      rejectPropertyInvitationStatus,
      rejectExternalTradeNetworkInvitationStatus,
      acceptExternalTradeNetworkInvitationStatus,
      videoConsultationStatus,
      onClickAcceptVideoCall,
      onClickRejectVideoCall,
      roomDetails,
      videoComponent,
      leaveCall,
      updateRaiseProjectRequestStatus,
      ProjectMetaDataStatusEnum,
      onClientHistoricalProjectStatus,
      HistoricProjectStatusEnum,
      activeUserWorkstation,
      UserVerificationStatusEnum,
      outerNetworkRequestStatus,
      showOuterNetworkStatus,
      UserRolesEnum,
      onChangeAssignTeamEstimateStatus,
      EstimatePhaseAssignMemberStatusEnum,
      isUserWorkstationIsDefault,
      loading,
      onNotificationClick,
      onClickMemberRequestAction,
      isMemberRequestActionLoading,
      filterNotificationList,
      onHandleAction,
      isAcceptingTradeNetworkRequest,
      isRejectingTradeNetworkRequest,
      loadingNotificationId,
      invitationRejectLoader,
      chatRequestAcceptLoader,
      chatRequestRejectLoader,
      externalTradeNetworkRequestAcceptLoader,
      externalTradeNetworkRequestRejectLoader,
      clientRaiseEstimateAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification_wrapper {
  position: relative;
  z-index: 999;

  .notification_wrapper_body {
    padding: 24px 16px;
    background-color: white;
    box-shadow: 0px 0px 8px 0px #0c0f4a14;
    border-radius: 16px;
    max-width: 545px;
    width: 100%;
    position: absolute;
    right: -20px;
    z-index: 4;
    top: 0;
    max-height: 700px;
    overflow: auto;

    .notification_wrapper__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        @include fluidFont(20, 14, 1.2);
        font-weight: 700;
        letter-spacing: 0.15px;
        color: rgba($blueDark, 1);
      }
      p {
        @include fluidFont(14, 14, 1.2);
        font-weight: 600;
        letter-spacing: 0.15px;
        color: rgba($PrimaryBlue, 1);
        cursor: pointer;
      }
    }
    .prefix {
      margin-top: 16px;
      text-align: left;
      span {
        @include fluidFont(14, 14, 1.2);
        font-weight: 700;
        letter-spacing: 0.15px;
        color: rgba($buttonText, 1);
      }
    }
    .notification_wrapper__list {
      margin-top: 16px;
      text-align: left;
      .notification_wrapper__list_item {
        padding: 16px 0;
        border-bottom: 1px solid #f2f2f2;
        border-radius: 7px;
        position: relative;
        cursor: pointer;
        .notification_wrapper__new_item {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: rgba($PrimaryBlue, 1);
          position: absolute;
          top: 35px;
        }
        .notification_wrapper__list_item_content {
          display: flex;
          gap: 16px;
          align-items: flex-start;
          max-width: 490px;
          margin-left: auto;
          @include respond(s720) {
            max-width: calc(100% - 20px);
          }
          .notification_wrapper__img {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .notification_wrapper__user_content {
            width: calc(100% - 84px);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .notification_wrapper__user_content_info {
              .notification_wrapper__text {
                p {
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 400;
                  letter-spacing: 0.15px;
                  color: rgba($blueDark, 1);
                  margin-bottom: 4px;
                }
                span {
                  @include fluidFont(14, 14, 1.3);
                  font-weight: 400;
                  letter-spacing: 0.15px;
                  color: rgba($buttonText, 1);
                }
              }
              .notification_wrapper__buttons {
                margin-top: 10px;
                display: flex;
                gap: 16px;
                align-items: center;
                .notification_wrapper__accept {
                  background-color: rgba($PrimaryGreen, 1);
                  border-radius: 8px;
                  color: rgba($white, 1);
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 700;
                  letter-spacing: 1.25px;
                }
                .notification_wrapper__reject {
                  background-color: rgba($PrimaryRed, 1);
                  border-radius: 8px;
                  color: rgba($white, 1);
                  @include fluidFont(14, 14, 1.2);
                  font-weight: 700;
                  letter-spacing: 1.25px;
                }
              }
            }
            .notification_wrapper__more_button {
              img {
                filter: invert(86%) sepia(10%) saturate(352%) hue-rotate(199deg)
                  brightness(86%) contrast(87%);
              }
            }
          }
        }
      }
    }
  }
}

.notification_body_overlay {
  position: absolute;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($blueDark, 0.1);
  z-index: 1;
  border-radius: 16px;
}
.loader {
  z-index: 1;
}
.delete_wrapper {
  padding: 0;
  .v-list-item {
    min-height: auto;
    @include fluidFont(14, 14, 1.2);
    color: rgba($blueDark, 0.5);
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
